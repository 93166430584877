const dependencies = {
    addSelf    : true,
    deepResolve: false,
    optional   : {
        list : {
            reference: 'classId',
            location : 'columns',
            sub      : 'lists',
            subIndex : 0,
            index    : 0,
            type     : 'list',
            attribute: 'filterBy',
            filter   : {
                key  : 'filter',
                value: 'class'
            }
        },
        note : { reference: 'localId', attribute: 'studentReference' },
        todo : { reference: 'localId', attribute: 'studentReference' },
        date : { reference: 'localId', attribute: 'studentReference' },
        media: { reference: 'localId', attribute: 'studentReference' }
    },
    required   : {
        class: { attribute: 'classId', type: 'class', contained: true, skipAfter: true }
    }
}
export default dependencies