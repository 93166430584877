<template>
    <div v-if="undefined !== buttons" class="form-row buttons" :id="'button-row-'+eventKey">
        <div class="form-caption buttons">
            <button v-if="false !== buttons.left"
                    :class="buttons.left.type+( buttons.left.isCancel ? ' cancelbutton' : '' )"
                    :value="buttons.left.caption"
                    @click="buttons.left.callback">{{ buttons.left.caption }}
            </button>
        </div>
        <div class="form-component buttons">
            <template v-if="undefined !== buttons.middle">
                <button class="cancel has-middle"
                        :value="buttons.middle.caption"
                        @click="buttons.middle.callback">{{ buttons.middle.caption }}
                </button>
            </template>
            <template v-if="buttons.right.type === 'login'">
                <template v-if="$store.getters.online !== true">
                    <button v-if="false !== buttons.right"
                            :class="'cancel'+( undefined !== buttons.middle ? ' has-middle' : '' )"
                            :value="buttons.right.caption"
                            @click="$emit( 'validate', $event, buttons.right.callback )">{{ buttons.right.caption }}
                    </button>
                </template>
                <template v-else>
                    <button v-if="false !== buttons.right"
                            :class="'submit'+( undefined !== buttons.middle ? ' has-middle' : '' )"
                            :value="buttons.right.caption"
                            @click="$emit( 'validate', $event, buttons.right.callback )">{{ buttons.right.caption }}
                    </button>
                </template>
            </template>
            <template v-else>
                <template v-if="submitVisible">
                    <button v-if="false !== buttons.right"
                            :id="'button-'+buttons.right.id"
                            :class="buttons.right.type+( undefined !== buttons.middle ? ' has-middle' : '' )"
                            :value="buttons.right.caption"
                            @click="$emit( 'validate', $event, buttons.right.callback )">{{ buttons.right.caption }}
                    </button>
                </template>
                <template v-else>
                    <button v-if="false !== buttons.right"
                            :id="'button-'+buttons.right.id"
                            :class="'cancel'+( undefined !== buttons.middle ? ' has-middle' : '' )"
                            :value="buttons.right.caption">
                        Augenblickchen...
                    </button>
                </template>
            </template>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
export default {

    name : 'FormButtons',
    props: {
        buttons      : { Type: Object, required: true },
        eventKey     : { Type: String, required: true },
        submitVisible: { Type: Boolean, required: true }
    },

    emits: [ 'validate' ]

}
</script>