<template>
    <transition appear :name="$core.settings().getTransition( 'zoom')" mode="out-in">
        <div class="recall-list-definition" v-if="!$core.f().isset( list.doneFields ) || recallVisible">
            <h3 class="error" v-if="!checked"><strong>Erledigungskriterien fehlen</strong></h3>
            <h3 v-else><strong>Erledigungskriterien festlegen</strong></h3>
            <p><strong>Bitte lege hier die Felder fest</strong>, die das Erledigungskriterium
                für diese Rücklaufliste darstellen.<br/><br/>
                <strong>Hinweis:</strong> Wenn Du 2 Checkboxen (oder mehr) als Erledigungskriterium auswählst, gilt die
                Liste als erledigt, sobald eine der Checkboxen je Zeile abgehakt wurde.<br/>
                Alle weiteren Feld-Typen gelten als erledigt, sofern sie ausgefüllt worden sind.
            </p>
            <table class="list">
                <thead>
                <th class="no-rotate">Feld</th>
                <th class="no-rotate"><strong>Für Erledigung berücksichtigen</strong></th>
                </thead>
                <tbody>
                <tr v-for="column in list.columns" v-bind:key="'key_rclTblDef_'+column.id">
                    <td v-if="column.type != 'fixed'">{{ column.caption }}</td>
                    <td v-if="column.type != 'fixed' && column.type != 'threewaytoggle'">
                        <input :checked="-1 < definition.indexOf(column.id)"
                               type="checkbox"
                               class="checkbox"
                               :id="'check_'+column.id"
                               @click="handleChange( $event, column.id )"/>
                    </td>
                    <td v-if="column.type == 'threewaytoggle'"><em>"Ja/Nein/keine Angabe"-Schalter können nicht als
                        Erledigungskriterium genutzt werden</em></td>
                </tr>
                <tr v-if="checked === true">
                    <td>&nbsp;</td>
                    <td>
                        <button class="submit" @click="storeRecallDoneDefinition">speichern
                            &#10004;
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </transition>
</template>

<script>
export default {
    
    name: 'RecallListDefinition',
    
    props: {
        list         : { type: Object, required: true },
        recallVisible: { type: Boolean, required: false }
    },
    
    emits: [ 'storeRecallDoneDefinition' ],
    
    data()
    {
        return {
            checked   : false,
            definition: []
        }
    },
    
    mounted()
    {
        this.definition = this.$core.f().valid( this.$props.list.doneFields ) ? this.$props.list.doneFields : []
        this.checked = undefined !== this.$props.list.doneFields && 0 < this.definition.length
    },
    
    methods: {
        /*eslint-disable*/
        handleChange( event, id )
        {
            
            event.stopPropagation()
            let index = this.definition.indexOf( id )
            if( -1 < index )
            {
                this.definition.splice( index, 1 )
            }
            else
            {
                this.definition.push( id )
            }
            
            this.checked = 0 < this.definition.length

        },
        
        storeRecallDoneDefinition()
        {
            this.$emit( 'storeRecallDoneDefinition', this.definition )
        }
    }
    
}
</script>