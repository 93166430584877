/*eslint-disable*/
export default class WebWorkerFactory
{

    constructor( core )
    {

        if( !WebWorkerFactory.instance )
        {

            this.logger = core.getLogger()
            this.eventManager = core.getEventManager()
            this.uuid = core.getUuid()
            this.baseClassHelper = core.getBaseClassHelper()

            this.logger.clog( 'WebWorkerFactory', 'initializing' );

            this.workerSources = {
                Crypto      : '/WebWorkers/CryptoWorker.js',
                ObjectHasher: '/WebWorkers/ObjectHasher.js'
            }

            this.workers = {}
            this.events = {}

            this.hookWorkers()

            WebWorkerFactory.instance = this

        }

        return WebWorkerFactory.instance

    }

    hookWorkers()
    {

        for( let s in this.workerSources )
        {

            this.logger.clog( 'WebWorkerFactory::hookWorkers', 'hooking worker ' + s );
            this.workers[ s ] = new Worker( this.workerSources[ s ] ) //, { type: 'module' } )
            this.workers[ s ].onmessage = ( event ) =>
            {
                this.handleWorkerEvent( event )
            }
        }

    }

    handleWorkerEvent( event )
    {

        let module = event.data.module,
            action = event.data.action,
            args   = event.data.arguments,
            type   = event.data.type

        switch( type )
        {
            case 'response':
                if( undefined !== this.events[ event.data.messageKey ] )
                {
                    let callback = this.events[ event.data.messageKey ]
                    delete this.events[ event.data.messageKey ]
                    callback( event.data.result )
                }
                break
            default:
                switch( action )
                {
                    default:
                        if( typeof this[ module ][ action ] === 'function' )
                        {
                            this[ module ][ action ]( ...args )
                        }
                        break
                }
                break
        }

    }

    /*eslint-disable*/
    call( module, method, args )
    {

        return new Promise( ( resolve, reject ) =>
        {

            try
            {

                let call = {
                    messageKey: this.uuid.generate(),
                    method    : method,
                    args      : args
                }

                this.events[ call.messageKey ] = ( result ) =>
                {
                    return resolve( result )
                }
                this.workers[ module ].postMessage( call )

            }
            catch( e )
            {
                return reject( 'WORKER_FACTORY_ERROR: ' + e )
            }

        } )

    }

}