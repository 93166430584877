<template>
    <div :key="'colleague-general-'+viewKey" v-if="initialized" class="padded-bottom">
        <h2>{{ $core.t( 'headline-colleague-general-rights' ) }} (Lizenzweit)</h2>
        <p><strong>Hinweis</strong>: Diese Rechte gelten für den gesamten Schulverbund.<br/>
            Sobald hier Änderungen erfolgen, hat das individuelle Setzen genereller Berechtigungen keinen Einfluss mehr
            auf die effektiven Berechtigungen.<br/>
            Individuelle Berechtigungen auf Element-Ebene bleiben hiervon unberührt.
        </p>
        <div class="rights">
            <div class="rights-switches">
                <div class="half rights-pad">
                    <div class="switch">
                        <Toggle id="all"
                                refName="all-but-delete"
                                :value="false"
                                @update="handleToggleAll"/>
                    </div>
                    <div class="caption">
                        <span class="strong">alle Schalter (ausser löschen)</span>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="spacer"></div>
        <div v-for="set in setters"
             class="rights switchboard"
             :key="set.id">
            <div class="rights-headline">
                <h3>{{ $core.t( 'object-type-' + set.caption ) }}</h3>
            </div>
            <div class="rights-switches">
                <template v-for="( s, index ) in set.switches"
                          :key="set.id+'-'+s.value">
                    <div class="half rights-pad">
                        <div class="switch">
                            <Toggle :id="'general-'+set.id+'-'+s.value"
                                    :refName="'general-'+set.id+'-'+s.value"
                                    :value="values['general-'+set.id+'-'+s.value]"
                                    @update="handleToggle"/>
                        </div>
                        <div class="caption"><span :class="'strong'+( 'delete' === s.value ? ' error' : '' )">
                            {{ $core.f().ucFirst( $core.t( s.value ) ) }}</span>
                        </div>
                        <div v-if="index%2 == 1" class="clearfix"></div>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <PreheatingIndicator v-else/>
</template>

<script>
import Toggle              from '@/components/form/elements/Toggle'
import PreheatingIndicator from "@/components/elements/indicators/PreheatingIndicator";

export default {
    name      : 'AdminGeneralRights',
    components: { PreheatingIndicator, Toggle },

    data()
    {
        return {
            viewKey    : this.$core.getUuid().generate(),
            updateTimer: false,
            initialized: false,
            itemClone  : false,
            setGlobal  : false,
            values     : {},
            adminRights: {},
            setters    : [
                {
                    caption : 'classes',
                    id      : 'classElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'groups',
                    id      : 'groupElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'yeargroups',
                    id      : 'yeargroupElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'students',
                    id      : 'studentElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'readMeta'
                        },
                        {
                            value: 'changeMeta'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'notes',
                    id      : 'noteElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'todos',
                    id      : 'todoElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'markDone'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'dates',
                    id      : 'dateElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'lists',
                    id      : 'listElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'fill'
                        },
                        {
                            value: 'clone'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                }
            ]
        }
    },

    created()
    {

        this.prepare()
        this.initialized = true

    },

    methods: {

        prepare()
        {
            this.initializeValues()
        },

        initializeValues()
        {

            let defaults = this.$core.r().getRights( 'basic' )
            this.adminRights = this.$core.r().adminRights

            for( let s in this.setters )
            {

                let set     = this.setters[ s ],
                    scope   = 'general',
                    section = set.id

                for( let sw in set.switches )
                {
                    let swkey = set.switches[ sw ]

                    let value = this.$core.f().isset( this.adminRights.rights )
                                && this.$core.f().isset( this.adminRights.rights[ scope ] )
                                && this.$core.f().isset( this.adminRights.rights[ scope ][ section ] )
                                && this.$core.f().isset( this.adminRights.rights[ scope ][ section ][ swkey.value ] ) ? this.adminRights.rights[ scope ][ section ][ swkey.value ] : defaults.rights[ section ][ swkey.value ]

                    this.setRight( scope, section, swkey.value, value )
                    this.values[ scope + '-' + section + '-' + swkey.value ] = value

                }

            }

        },

        updateOnLeave()
        {
            if( false !== this.updateTimer )
            {
                clearTimeout( this.updateTimer )
                this.updateTimer = false
                this.performUpdate()
            }
        },

        lazyElementsRightsUpdate()
        {

            if( false !== this.updateTimer )
            {
                clearTimeout( this.updateTimer )
                this.updateTimer = false
            }

            this.updateTimer = setTimeout( () =>
            {

                this.performUpdate()

            }, 2000 )

        },

        performUpdate()
        {

            let rights  = this.$core.getCryptoHelper().encryptForServer( JSON.stringify( this.adminRights.rights ) ),
                message = {
                    method: 'network.storePoolRights',
                    rights: rights
                },
                jobId   = this.$core.getUuid().generate()

            this.$core.getQueueWorker().enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

            this.$store.commit( 'setPoolRights', this.adminRights.rights )

        },

        setRight( scope, section, key, value )
        {

            if( undefined === this.adminRights.rights[ scope ] )
            {
                this.adminRights.rights[ scope ] = {}
            }
            if( undefined === this.adminRights.rights[ scope ][ section ] )
            {
                this.adminRights.rights[ scope ][ section ] = {}
            }
            this.adminRights.rights[ scope ][ section ][ key ] = value

        },

        /*eslint-disable*/
        handleToggle( id, type, method, reference, value )
        {

            let temp    = id.split( '-' ),
                scope   = temp[ 0 ],
                section = temp[ 1 ],
                key     = temp[ 2 ]

            this.setRight( scope, section, key, value )
            this.lazyElementsRightsUpdate()

        },

        handleToggleAll( id, type, method, reference, value )
        {

            for( let s in this.setters )
            {
                for( let ss in this.setters[ s ].switches )
                {
                    if( 'delete' !== this.setters[ s ].switches[ ss ].value )
                    {
                        this.setRight( 'general', this.setters[ s ].id, this.setters[ s ].switches[ ss ].value, value )
                    }
                }
            }

            this.lazyElementsRightsUpdate()
            this.prepare()

        }

    }

}
</script>