export default class FixStudentAccesses
{
    constructor( parent )
    {

        this.parent = parent
        this.logger = parent.parent.logger
        this.database = parent.parent.database
        this.f = parent.parent.f

        this.studentAccesses = {}

        this.baseClassHelper = parent.baseClassHelper
        this.logSign = 'SyncShadowCopies::FixStudentAccesses'
        this.needsRun = true

        this.logger.cconstructed( this.logSign, 'initialized' )

        return this

    }

    resolveStudentAccessDeviceUuid( index, access )
    {
        return new Promise( resolve =>
        {

            this.parent.client.request( {
                    method  : 'users.resolveStudentDeviceUuid',
                    idDevice: access.idDevice
                } )
                .then( response =>
                {

                    this.studentAccesses[ index ].deviceUuid = response.result
                    return resolve( JSON.parse( JSON.stringify( this.studentAccesses[ index ] ) ) )

                } )
                .catch( () =>
                {
                    return resolve( false )
                } )

        } )
    }

    fixStudentAccesses()
    {
        return new Promise( resolve =>
        {

            let promises = []

            for( let a in this.studentAccesses )
            {

                if( undefined === this.studentAccesses[ a ].deviceUuid )
                {
                    promises.push( () =>
                    {
                        return this.resolveStudentAccessDeviceUuid( a, this.studentAccesses[ a ] )
                                   .then( access =>
                                   {

                                       this.baseClassHelper
                                           .get( 'studentAccess' )
                                           .update(
                                               access,
                                               access.localId,
                                               access.remoteId,
                                               access.timestamp,
                                               access.localKey,
                                               undefined,
                                               true,
                                               true
                                           )

                                   } )
                    } )
                }

            }

            this.f.promiseRunner( promises )
                .then( () =>
                {
                    return resolve()
                } )

        } )
    }

    /**
     * handle
     */
    handle()
    {

        return new Promise( resolve =>
        {

            if( this.needsRun )
            {

                let start = Date.now()
                this.fixStudentAccesses()
                    .then( () => {

                        this.needsRun = false
                        this.logger.cdebug( this.logSign + ':handle', 'process took ' + ( Date.now() - start ) + 'ms' )
                        return resolve()

                    })

            }
            else
            {
                this.logger.cdebug( this.logSign + ':handle', 'nothing to do.' )
                return resolve()
            }

        } )

    }

}