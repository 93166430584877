<script>
export default {

    name: "MixinColleagueBaseInfo",

    data()
    {
        return {
            avatarSync: false,
            avatar    : '',
            countKey  : this.$core.getUuid().generate(),
            counts    : {
                sharedFrom: {
                    total: 0
                },
                sharedTo  : {
                    total: 0
                }
            }
        }
    },

    methods: {

        getAvatar()
        {
            return new Promise( resolve =>
            {

                this.$core.getBaseClassHelper()
                    .get( 'avatar' )
                    .listAll()
                    .then( avatars =>
                    {

                        for( let a in avatars )
                        {
                            if( avatars[ a ].colleagueId === this.$props.item.colleagueId )
                            {
                                this.avatar = 'background-image:url( "' + avatars[ a ].dataUri + '" )!important;'
                                this.avatarSync = true
                                return resolve()
                            }
                        }

                        this.avatarSync = true
                        return resolve()

                    } )

            } )

        },

        prepareCounts()
        {
            return new Promise( resolve =>
            {

                this.$core.getShare()
                    .shareCount( this.$props.item )
                    .then( counts =>
                    {

                        this.countKey = this.$core.getUuid().generate()
                        this.initialized = true
                        this.counts = counts

                        return resolve()

                    } )

            } )
        }
    }

}
</script>