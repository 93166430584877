export default class SyncTriggerHandler
{

    constructor( core )
    {

        if( !SyncTriggerHandler.instance )
        {

            this.core = core
            this.eventManager = core.getEventManager()
            this.logger = core.getLogger()
            this.notificationHelper = false //core.getNotificationHelper()

            SyncTriggerHandler.instance = this

        }

        return SyncTriggerHandler.instance

    }

    dispatch( which )
    {
        this.logger.clog( 'SyncTriggerHandler:dispatch', 'dispatching sync-trigger "' + which + '"' )
        this.eventManager.dispatch( 'sync-trigger-' + which )
    }

    crud()
    {
        this.dispatch( 'crud' )
    }
    
    network()
    {
        this.dispatch( 'network' )
    }
    
    rights()
    {
        this.dispatch( 'rights' )
    }

}