<template>
    <transition appear :name="$core.settings().getTransition( 'next' )" mode="in-out">
        <div :class="'list-filters'+( editormode ? ' editor' : '' )">
            <div class="list-filters-filter no-float">
                <div class="list-filters-select" id="classes-filter-inner">
                    <select class="list-filter" @change="filter">
                        <option value="">{{ title || 'Klassen & Gruppen' }}</option>
                        <optgroup label="Klassen">
                            <option v-for="classItem in classes"
                                    v-bind:key="'key_option_'+classItem.localId"
                                    :value="classItem.localId">{{ classItem.className || classItem.classname }}
                            </option>
                        </optgroup>
                        <optgroup label="Gruppen">
                            <option v-for="groupItem in groups"
                                    v-bind:key="'key_option_'+groupItem.localId"
                                    :value="groupItem.localId">{{ groupItem.groupName || classItem.groupname }}
                            </option>
                        </optgroup>
                        <optgroup label="Jahrgänge" v-if="$core.getLicense().isAllowed( 'page_yeargroups' )">
                            <option v-for="groupItem in yeargroups"
                                    v-bind:key="'key_option_'+groupItem.localId"
                                    :value="groupItem.localId">{{ groupItem.yeargroupname || classItem.yeargroupname }}
                            </option>
                        </optgroup>
                    </select>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </transition>
</template>

<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {

    name  : 'ListFilter',
    mixins: [ MixinCachePreheater ],
    props : {
        title     : { type: String, required: false },
        archived  : { type: Boolean, require: false, default: false },
        own       : { type: Boolean, require: false, default: false },
        editormode: { type: Boolean, require: false, default: false }
    },

    emits: [ 'filter' ],

    data()
    {
        return {
            neededCaches: [ 'class', 'group', 'yeargroup' ],
            initialized : false,
            classes     : [],
            groups      : [],
            yeargroups  : []
        }
    },

    created()
    {
        this.awaitNeededCaches()
            .then( () =>
            {
                this.initialize()
            } )
    },

    methods: {

        filterByOwner( element, own )
        {
            let isOwn = this.$core.f().isOwn( element )
            return own ? isOwn : true
        },

        initialize()
        {

            this.initialized = false

            let isArchived = this.$props.archived === true,
                own        = this.$props.own === undefined ? true : this.$props.own,
                classes    = this.$core.getBaseClassHelper()
                                 .get( 'class' )
                                 .readCache( 'cache' ),
                groups     = this.$core.getBaseClassHelper()
                                 .get( 'group' )
                                 .readCache( 'cache' ),
                yeargroups = this.$core.getBaseClassHelper()
                                 .get( 'yeargroup' )
                                 .readCache( 'cache' )

                classes = this.$core.getSorter().sortObjects( classes, 'classname' )

                for( let c in classes )
                {
                    if( isArchived === classes[ c ].archived
                        && this.filterByOwner( classes[ c ], own ) )
                    {
                        this.classes.push( {
                            localId  : classes[ c ].localId,
                            className: classes[ c ].classname + ( isArchived ? ' ' + this.$core.getReformatter().timeForArchiveKey( classes[ c ] ) : '' )
                        } )
                    }
                }

                groups = this.$core.getSorter().sortObjects( groups, 'groupname' )

                for( let g in groups )
                {
                    if( isArchived === groups[ g ].archived
                        && this.filterByOwner( groups[ g ], own ) )
                    {
                        this.groups.push( {
                            localId  : groups[ g ].localId,
                            groupName: groups[ g ].groupname
                        } )
                    }
                }

                yeargroups = this.$core.getSorter().sortObjects( yeargroups, 'yeargroupname' )

                for( let g in yeargroups )
                {
                    if( isArchived === yeargroups[ g ].archived
                        && this.filterByOwner( yeargroups[ g ], own ) )
                    {
                        this.yeargroups.push( {
                            localId      : yeargroups[ g ].localId,
                            yeargroupname: yeargroups[ g ].yeargroupname
                        } )
                    }
                }

                this.initialized = true

        },

        filter( event )
        {

            let value = event.target.value
            this.emitValue( value )

        },

        emitValue( value )
        {
            if( value === '' )
            {
                this.$emit( 'filter', 'classes', false )
                return
            }

            for( let c in this.classes )
            {
                if( this.classes[ c ].localId === value )
                {
                    this.$emit( 'filter', 'classes', [ { 'attr': 'classId', value: this.classes[ c ].localId } ] )
                }
            }

            for( let g in this.groups )
            {
                if( this.groups[ g ].localId === value )
                {
                    this.$emit( 'filter', 'classes', [ { 'attr': 'groupId', value: this.groups[ g ].localId } ] )
                }
            }

            for( let g in this.yeargroups )
            {
                if( this.yeargroups[ g ].localId === value )
                {
                    this.$emit( 'filter', 'classes', [ {
                        'attr': 'yeargroupId',
                        value : this.yeargroups[ g ].localId
                    } ] )
                }
            }
        }

    }

}
</script>