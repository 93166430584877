import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class TodosObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !TodosObjectClass.instance )
        {

            let refList = [ 'title',
                            'duedate',
                            'duedate_friendly',
                            'done',
                            'done_timestamp',
                            'body',
                            'todos',
                            'todos_done',
                            'color',
                            'labels',
                            'studentReference',
                            'classReference',
                            'groupReference',
                            'yeargroupReference',
                            'pinned',
                            'hidden',
                            'timestamp',
                            'update' ]

            let personalAttributesStatesList = [
                {
                    storage: 'pinning',
                    state  : 'pinned',
                    list   : 'pinLists'
                },
                {
                    storage: 'hiding',
                    state  : 'hidden',
                    list   : 'hideLists'
                }
            ]

            super( core, 'todo', refList, personalAttributesStatesList )
            this.objectType = 'todos'
            TodosObjectClass.instance = this
        }

        return TodosObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'title',
                caption    : '<strong>Was</strong> musst Du erledigen?',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'color',
                styleUpdate: true
            },
            {
                ref        : 'duedate_friendly',
                caption    : '<strong>Bis wann?</strong>',
                type       : 'DateBox',
                reformatter: 'date',
                optional   : true,
                validator  : 'date',
                styleUpdate: true
            },
            {
                ref        : 'color',
                caption    : '<strong>Farbe</strong>',
                type       : 'ColorSelector',
                reformatter: null,
                optional   : false,
                validator  : 'color',
                styleUpdate: true
            },
            {
                ref          : 'studentReference',
                caption      : 'zu Schüler:in',
                type         : 'RowAdder',
                component    : 'StudentSelector',
                values       : [],
                allowForeign : true,
                reformatter  : null,
                optional     : true,
                validator    : null,
                styleUpdate  : false,
                extended     : true,
                asPlainList  : true,
                dragBlocked  : true,
                unique       : true,
                ignoreClassId: true
            },
            {
                ref         : 'classReference',
                caption     : 'zu <strong>Klasse</strong>',
                type        : 'ClassSelector',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false,
                extended    : true
            },
            {
                ref         : 'groupReference',
                caption     : 'zu <strong>Gruppe</strong>',
                type        : 'GroupSelector',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false,
                extended    : true,
                acl         : 'page_groups'
            },
            {
                ref         : 'yeargroupReference',
                caption     : 'zu <strong>Jahrgang</strong>',
                type        : 'YeargroupSelector',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false,
                extended    : true,
                acl         : 'page_yeargroups'
            },
            {
                ref        : 'labels',
                caption    : 'Labels',
                type       : 'LabelSelector',
                styleUpdate: false,
                reformatter: null,
                optional   : true,
                extended   : true,
                validator  : 'text'
            },
            {
                ref        : 'body',
                caption    : '<strong>Details</strong>',
                type       : 'TextArea',
                reformatter: null,
                optional   : true,
                validator  : 'freetext',
                styleUpdate: false
            },
            {
                ref        : 'todos',
                caption    : 'zu erledigende <strong>Teilaufgaben</strong>',
                type       : 'RowAdder',
                component  : 'TextBox',
                asPlainList: true,
                reformatter: null,
                optional   : true,
                validator  : 'freetext',
                extended   : true,
                unique     : true
            },
            {
                ref        : 'forceTimestamp',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'done',
                caption    : null,
                type       : 'Hidden',
                reformatter: 'boolean2int',
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'done_timestamp',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'todos_done',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'pinned',
                caption    : null,
                type       : 'Hidden',
                reformatter: 'boolean',
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'hidden',
                caption    : null,
                type       : 'Hidden',
                reformatter: 'boolean',
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'organizerSlot',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            }
        ]
    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return [ 'pin', 'hide', 'edit', 'share', 'delete' ]
    }

    /**
     * OVERRIDES
     */
    cleanTodoList( values )
    {
        if( undefined !== values.todos )
        {
            let newTodos = []
            for( let t in values.todos )
            {
                if( undefined !== values.todos[ t ]
                    && null !== values.todos[ t ]
                    && typeof values.todos[ t ] !== 'object'
                    && values.todos[ t ].trim() !== '' )
                {
                    newTodos.push( values.todos[ t ] )
                }
            }
            values.todos = newTodos
        }
    }

    create( values, additional, clone, forceTimestamp )
    {

        let friendly = values.duedate_friendly.split( '.' )
        let date = new Date( friendly[ 2 ], ( friendly[ 1 ] - 1 ), friendly[ 0 ], 12, 0, 0 )
        values.duedate = date.getTime()

        if( undefined === values.pinned )
        {
            values.pinned = false
        }

        this.cleanTodoList( values )
        return super.create( values, additional, clone, forceTimestamp )

    }

    update( values, localId, remoteId, timestamp, localKey, additional, noUpdateTimestamp )
    {

        if( undefined !== values.duedate_friendly )
        {

            let friendly = values.duedate_friendly.split( '.' )
            let date = new Date( friendly[ 2 ], ( friendly[ 1 ] - 1 ), friendly[ 0 ], 12, 0, 0 )
            values.duedate = date.getTime()

        }

        if( undefined === values.pinned )
        {
            values.pinned = false
        }

        this.cleanTodoList( values )
        return super.update( values, localId, remoteId, timestamp, localKey, additional, noUpdateTimestamp )

    }

}