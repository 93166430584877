<template>
    <div class="list-element textbox">
        <template v-if="$core.f().isset( value )">
            <span v-if="value.length > maxLength && !alwaysFulltext"
                  v-html="value.substr( 0, ( maxLength - 3 ) ) + '...'"></span>
            <span v-else v-html="value"></span>
        </template>
    </div>
</template>

<script>
export default {
    name : 'listElement-textarea',
    props: {
        value   : { type: String, required: false, default: undefined },
        fulltext: { type: Boolean, required: false, default: undefined }
    },
    data()
    {
        return {
            maxLength     : 25,
            alwaysFulltext: true
        }
    }
}
</script>
