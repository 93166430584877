import ChecklistDefinition  from '@/classes/TableCalculation/definitions/Checklist'
import RatinglistDefinition from '@/classes/TableCalculation/definitions/Ratinglist'
import FlexlistDefinition   from '@/classes/TableCalculation/definitions/FlexlistDefinition'
import RecalllistDefinition from '@/classes/TableCalculation/definitions/RecalllistDefinition'
import TestlistDefinition   from '@/classes/TableCalculation/definitions/TestlistDefinition'

export default class TableCalculation
{
    constructor( core )
    {
        if( !TableCalculation.instance )
        {

            this.logger = core.getLogger()
            this.sanitizers = core.getSanitizers()
            this.translation = core.getTranslation()
            this.reformatter = core.getReformatter()
            this.settings = core.settings()
            this.store = core.getStore()
            this.baseClassHelper = core.getBaseClassHelper()

            this.filterId = undefined
            this.filterList = false

            this.f = core.f()

            this.getObjectById = ( which ) =>
            {
                return core.getBaseClassHelper().getObjectById( which )
            }

            this.studentBaseClass = core.getBaseClassHelper().get( 'student' )

            this.getObjectRespectingTimestamp = ( which, timestamp ) =>
            {
                return core.getBaseClassHelper().getObjectRespectingTimestamp( which, timestamp )
            }

            this.getState = ( id =>
            {
                return core.getState( id )
            } )

            TableCalculation.instance = this
        }
        return TableCalculation.instance
    }

    destruct()
    {
        delete TableCalculation.instance
    }

    filterMatch( column, timestamp )
    {

        let student = this.getObjectById( column.localId )

        if( undefined === student
            || false === student
            || !this.f.archiveMatch( student, timestamp )
            || 'student' !== student.type )
        {
            return false
        }

        if( !this.filterList )
        {
            return true
        }

        let filterMatch = true

        for( let f in this.filterList )
        {
            let filter = this.filterList[ f ],
                group  = this.getObjectById( filter.value ),
                row    = this.getObjectById( column.localId )

            switch( filter.attr )
            {
                case 'groupId':
                    if( -1 === group.students.indexOf( row.localId ) )
                    {
                        filterMatch = false
                    }
                    break
                default:
                    if( row[ filter.attr ] !== filter.value )
                    {
                        filterMatch = false
                    }
                    break
            }

        }

        return filterMatch

    }

    getFieldDefinition( lists, type )
    {

        let fieldset

        switch( lists[ 0 ].listType )
        {
            case 'checklist':
                fieldset = ChecklistDefinition.fieldset[ type ]
                break
            case 'ratinglist':
                fieldset = RatinglistDefinition.fieldset[ type ]
                break
            case 'customFixed':
            case 'combilist':
                fieldset = FlexlistDefinition.fieldset[ type ]
                break
            case 'recallList':
                fieldset = RecalllistDefinition.fieldset[ type ]
                break
            case 'test':
                fieldset = TestlistDefinition.fieldset[ type ]
                break
            default:
                this.logger.cerror( 'TableCalculation:getFieldDefinition', 'unknown list type: ', lists[ 0 ].listType )
                break
        }
        return fieldset

    }

    getSummaryDefinition( lists, type )
    {

        let fieldset
        switch( lists[ 0 ].listType )
        {
            case 'checklist':
                fieldset = ChecklistDefinition.summary[ type ]
                break
            case 'ratinglist':
                fieldset = RatinglistDefinition.summary[ type ]
                break
            case 'customFixed':
            case 'combilist':
                fieldset = FlexlistDefinition.summary[ type ]
                break
            case 'recallList':
                fieldset = RecalllistDefinition.summary[ type ]
                break
            case 'test':
                fieldset = TestlistDefinition.summary[ type ]
                break
            default:
                this.logger.cerror( 'TableCalculation:getSummaryDefinition', 'unknown list type: ', lists[ 0 ].listType )
                break
        }
        return fieldset

    }

    getSummaryDisplay( lists )
    {
        let fieldset
        switch( lists[ 0 ].listType )
        {
            case 'checklist':
                fieldset = ChecklistDefinition.summaryDisplay
                break
            case 'ratinglist':
                fieldset = RatinglistDefinition.summaryDisplay
                break
            case 'customFixed':
            case 'combilist':
                fieldset = FlexlistDefinition.summaryDisplay
                break
            case 'recallList':
                fieldset = RecalllistDefinition.summaryDisplay
                break
            case 'test':
                fieldset = TestlistDefinition.summaryDisplay
                break
            default:
                this.logger.cerror( 'TableCalculation:getSummaryDisplay', 'unknown list type: ', lists[ 0 ].listType )
                break
        }

        if( undefined === fieldset )
        {
            return 'listElement-textbox'
        }
        return fieldset.component
    }

    getColumns( list, fieldset, noAverages )
    {

        let columns = []

        for( let c in list.columns )
        {
            let column = list.columns[ c ]
            if( column.type !== 'fixed' )
            {
                columns.push( {
                    caption : column.caption,
                    columnId: this.sanitizers.cleanId( column.caption ),
                    type    : column.type,
                    score   : column.score || undefined
                } )
            }
        }

        if( true !== noAverages )
        {
            for( let f in fieldset )
            {
                let columnId = '___' + fieldset[ f ]
                columns.push( {
                    caption : this.translation.translate( columnId ),
                    columnId: columnId
                } )
            }
        }

        return columns

    }

    getCalcType( type )
    {

        let calcType         = 'count',
            settingsOverride = this.settings.getSetting( 'listCalcType-' + type ) || null

        if( null !== settingsOverride
            && 'default' !== settingsOverride )
        {
            return settingsOverride
        }

        switch( type )
        {
            case 'checkbox':
            case 'testItem':
            case 'numberbox':
            case 'rateselector':
                calcType = 'addvalue'
                break
            case 'filebox':
            case 'image':
                calcType = 'countdefined'
                break
        }

        return calcType

    }

    getTotalsType( type )
    {

        let calcType = 'count'

        switch( type )
        {
            case 'checkbox':
            case 'testItem':
            case 'numberbox':
            case 'rateselector':
                calcType = 'addvalue'
                break
            case 'testcomment':
                calcType = 'ignore'
                break
        }

        return calcType

    }

    parseColumn( id, columns )
    {

        let temp     = id.split( '___' ),
            localId  = temp.shift(),
            column   = temp.pop(),
            columnId = temp.join( '___' ),
            type     = undefined

        for( let c in columns )
        {
            if( columns[ c ].columnId === columnId )
            {
                type = columns[ c ].type
            }
        }

        return {
            localId : localId,
            columnId: columnId,
            column  : column,
            type    : type,
            calcType: this.getCalcType( type ),
            fullId  : columnId + '___' + column
        }

    }

    prepareSkeleton( columns )
    {

        let byRow = {}
        let byColumn = {}

        for( let c in columns )
        {
            let columnId = columns[ c ].columnId + '___' + ( parseInt( c ) + 1 )
            byRow[ columnId ] = {}
            byColumn[ columnId ] = null
        }

        return {
            byRow   : byRow,
            byColumn: byColumn
        }

    }

    /*eslint-disable*/
    getStudentsForList( list, timestamp )
    {

        let filter      = list.columns[ 0 ].filterBy,
            tempList    = [],
            studentList = []

        if( 'all' === filter )
        {
            tempList = this.studentBaseClass.readCache()
        }
        else
        {
            let element = this.getObjectById( filter ),
                temp    = element ? element.students : []

            for( let t in temp )
            {
                tempList.push( this.studentBaseClass.getById( temp[ t ] ) )
            }
        }

        for( let t in tempList )
        {
            if( undefined !== tempList[ t ]
                && this.f.archiveMatch( tempList[ t ], timestamp ) )
            {
                studentList.push( tempList[ t ] )
            }
        }

        tempList = null
        return studentList

    }

    getPossibleRowCount( lists )
    {
        let studentList     = [],
            rowCounts       = {},
            lowestTimestamp = 9999999999999999

        for( let l in lists )
        {
            if( lists[ l ].timestamp < lowestTimestamp )
            {
                lowestTimestamp = lists[ l ].timestamp
            }
        }

        studentList = this.getStudentsForList( lists[ 0 ], lowestTimestamp )

        for( let l in lists )
        {
            let count = 0
            for( let s in studentList )
            {
                if( this.f.archiveMatch( studentList[ s ], lists[ l ].timestamp ) )
                {
                    count++
                }
            }
            rowCounts[ lists[ l ].localId ] = count
        }


        return rowCounts

    }

    prepareSummary( list, columns )
    {

        return new Promise( resolve =>
        {

            let summary  = this.prepareSkeleton( columns ),
                averages = this.prepareSkeleton( columns ),
                counts   = this.prepareSkeleton( columns ),
                distinct = this.prepareSkeleton( columns )

            for( let v in list.values )
            {

                let value      = list.values[ v ],
                    columnInfo = this.parseColumn( v, columns )

                if( ( this.filterId === undefined
                      || columnInfo.localId === this.filterId )
                    && this.filterMatch( columnInfo, list.timestamp ) )
                {

                    if( undefined === summary.byRow[ columnInfo.fullId ] )
                    {
                        summary.byRow[ columnInfo.fullId ] = {}
                        averages.byRow[ columnInfo.fullId ] = {}
                        distinct.byRow[ columnInfo.fullId ] = {}
                    }

                    if( undefined === summary.byRow[ columnInfo.fullId ][ columnInfo.localId ] )
                    {
                        summary.byRow[ columnInfo.fullId ][ columnInfo.localId ] = 0
                        averages.byRow[ columnInfo.fullId ][ columnInfo.localId ] = 0
                        distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ] = {}
                    }

                    if( null === summary.byColumn[ columnInfo.fullId ]
                        || undefined === summary.byColumn[ columnInfo.fullId ] )
                    {
                        summary.byColumn[ columnInfo.fullId ] = 0
                        averages.byColumn[ columnInfo.fullId ] = 0
                        counts.byColumn[ columnInfo.fullId ] = 0
                        distinct.byColumn[ columnInfo.fullId ] = {}
                    }

                    counts.byColumn[ columnInfo.fullId ]++

                    switch( columnInfo.calcType )
                    {
                        case 'addvalue':
                            summary.byRow[ columnInfo.fullId ][ columnInfo.localId ] += ( undefined !== value ? this.reformatter.reformat( value, 'float' ) : 0 )
                            summary.byColumn[ columnInfo.fullId ] += ( undefined !== value ? this.reformatter.reformat( value, 'float' ) : 0 )
                            break
                        case 'count':
                            summary.byRow[ columnInfo.fullId ][ columnInfo.localId ]++
                            summary.byColumn[ columnInfo.fullId ]++
                            break
                        case 'countdefined':
                            if( undefined !== value && false !== value )
                            {
                                summary.byRow[ columnInfo.fullId ][ columnInfo.localId ]++
                                summary.byColumn[ columnInfo.fullId ]++
                            }
                            break
                    }

                    distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ][ this.f.falseToUndef( value ) ] = !this.f.falseOrUndefined( distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ][ value ] ) ? distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ][ value ] + 1 : 1
                    distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ][ '__total' ] = !this.f.falseOrUndefined( distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ][ '__total' ] ) ? distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ][ '__total' ] + 1 : 1
                    distinct.byColumn[ columnInfo.fullId ][ this.f.falseToUndef( value ) ] = distinct.byColumn[ columnInfo.fullId ][ this.f.falseToUndef( value ) ] !== undefined ? distinct.byColumn[ columnInfo.fullId ][ this.f.falseToUndef( value ) ] + 1 : 1
                    distinct.byColumn[ columnInfo.fullId ][ '__total' ] = distinct.byColumn[ columnInfo.fullId ][ '__total' ] !== undefined ? distinct.byColumn[ columnInfo.fullId ][ '__total' ] + 1 : 1

                    averages.byColumn[ columnInfo.fullId ] = summary.byColumn[ columnInfo.fullId ] / counts.byColumn[ columnInfo.fullId ]

                }

            }

            this.getObjectRespectingTimestamp( 'student', list.timestamp )
                .then( students =>
                {

                    let studentsSet = false
                    for( let c in columns )
                    {

                        let col    = columns[ c ],
                            fullId = col.columnId + '___' + ( parseInt( c ) + 1 )

                        if( col.type === 'studentData' )
                        {
                            if( !studentsSet )
                            {
                                if( 'all' !== list.columns[ 0 ].filterBy )
                                {
                                    let newStudents = []
                                    for( let s in students )
                                    {
                                        if( students[ s ].classId === list.columns[ 0 ].filterBy )
                                        {
                                            newStudents.push( students[ s ] )
                                        }
                                    }
                                    students = newStudents
                                }
                                studentsSet = true
                            }

                            for( let s in students )
                            {
                                if( undefined !== students[ s ][ col.caption ]
                                    && students[ s ][ col.caption ].trim() !== ''
                                    && students[ s ][ col.caption ].trim() !== '<br/>'
                                    && students[ s ][ col.caption ].trim() !== '&nbsp;' )
                                {
                                    if( undefined === summary.byColumn[ fullId ] )
                                    {
                                        summary.byColumn[ fullId ] = 0
                                        counts.byColumn[ fullId ] = 0
                                    }
                                    if( undefined !== this.filterId )
                                    {
                                        list.values[ this.filterId + '___' + col.columnId + '___' + ( parseInt( c ) + 1 ) ] = this.filterId + '-' + col.columnId
                                    }
                                    else
                                    {
                                        if( undefined === list.values )
                                        {
                                            list.values = {}
                                        }
                                        list.values[ students[ s ].localId + '___' + col.columnId + '___' + ( parseInt( c ) + 1 ) ] = students[ s ].localId + '-' + col.columnId
                                    }
                                    summary.byColumn[ fullId ]++
                                    counts.byColumn[ fullId ]++
                                }
                            }
                        }

                    }

                    let hasData = {}
                    for( let s in students )
                    {
                        let student = students[ s ]
                        hasData[ student.localId ] = false
                        for( let c in summary.byRow )
                        {
                            let sum = summary.byRow[ c ]
                            if( undefined !== sum[ student.localId ] )
                            {
                                hasData[ student.localId ] = true
                            }
                        }
                    }

                    return resolve( {
                        hasData : hasData,
                        counts  : counts,
                        summary : summary,
                        averages: averages,
                        distinct: distinct
                    } )

                } )

        } )

    }

    addTotals( columns, summary, totals )
    {

        if( undefined === totals.grandTotals )
        {
            totals.grandTotals = {}
            totals.averages = {}
            totals.counts = {}
            totals.rowCounts = {}
            totals.distinct = {}
        }

        let counted = []

        for( let c in columns )
        {

            let column   = columns[ c ],
                columnId = column.columnId + '___' + ( parseInt( c ) + 1 ),
                value    = summary.summary.byColumn[ columnId ]

            if( undefined === totals[ columnId ] )
            {
                totals[ columnId ] = { byColumn: 0, byRow: {} }
            }

            switch( this.getTotalsType( column.type ) )
            {
                case 'addvalue':
                    totals[ columnId ].byColumn += this.reformatter.reformat( value, 'float' )
                    break
                case 'count':
                    totals[ columnId ].byColumn++
                    break
            }

            for( let key in summary.summary.byRow[ columnId ] )
            {

                let colValue = summary.summary.byRow[ columnId ][ key ]
                if( undefined === totals[ columnId ].byRow[ key ] )
                {
                    totals[ columnId ].byRow[ key ] = 0
                }

                if( undefined === totals.grandTotals[ key ] )
                {
                    totals.grandTotals[ key ] = 0
                }

                if( undefined === totals.counts[ key ] )
                {
                    totals.counts[ key ] = {}
                    totals.averages[ key ] = {}
                    totals.rowCounts[ key ] = 0
                }

                switch( this.getTotalsType( column.type ) )
                {
                    case 'addvalue':
                        totals[ columnId ].byRow[ key ] += this.reformatter.reformat( colValue, 'float' )
                        totals.grandTotals[ key ] += this.reformatter.reformat( colValue, 'float' )
                        break
                    case 'count':
                        totals[ columnId ].byRow[ key ]++
                        totals.grandTotals[ key ]++
                        break
                    case 'ignore':
                        break
                }

                totals[ columnId ].byRow[ key ] = this.reformatter.reformat( totals[ columnId ].byRow[ key ], 'float' )
                totals.grandTotals[ key ] = this.reformatter.reformat( totals.grandTotals[ key ], 'float' )

                if( -1 === counted.indexOf( key ) )
                {
                    totals.rowCounts[ key ] = undefined !== totals.rowCounts[ key ] ? totals.rowCounts[ key ] + 1 : 1
                    counted.push( key )
                }

                totals.counts[ key ][ columnId ] = undefined !== totals.counts[ key ][ columnId ] ? totals.counts[ key ][ columnId ] + 1 : 1
                totals.averages[ key ][ columnId ] = totals[ columnId ].byRow[ key ] / totals.counts[ key ][ columnId ]

            }

        }

        for( let columnId in summary.distinct.byRow )
        {

            if( undefined === totals.distinct[ columnId ] )
            {
                totals.distinct[ columnId ] = {}
            }

            for( let key in summary.distinct.byRow[ columnId ] )
            {

                if( undefined === totals.distinct[ columnId ][ key ] )
                {
                    totals.distinct[ columnId ][ key ] = {}
                }

                let set = summary.distinct.byRow[ columnId ][ key ]
                for( let v in set )
                {
                    totals.distinct[ columnId ][ key ][ v ] = undefined !== totals.distinct[ columnId ][ key ][ v ] ? totals.distinct[ columnId ][ key ][ v ] + set[ v ] : set[ v ]
                }

            }
        }

    }

    getHeadTypes( columns )
    {

        let headTypes = {}

        for( let c in columns )
        {
            let type = 'totals'
            switch( columns[ c ].type )
            {
                case 'rateselector':
                    type = 'averages'
                    break
                case 'threewaytoggle':
                    type = 'distinct'
                    break
                default:
                    break

            }
            headTypes[ columns[ c ].columnId ] = type
        }

        return headTypes

    }

    getMarkers( list, filterId )
    {

        for( let key in list.values )
        {
            if( -1 < key.indexOf( filterId ) )
            {
                return ''
            }
        }

        return 'empty'

    }

    resultResolver( lists, columns, filterId, totals, result )
    {
        return new Promise( resolve =>
        {

            result = undefined === result ? [] : result

            if( 0 < lists.length )
            {

                let list = lists.shift()
                this.prepareSummary( list, columns )
                    .then( summary =>
                    {

                        let timestamp = list.timestamp

                        result.push( {
                            timestamp: timestamp,
                            columns  : columns,
                            summary  : summary,
                            listItem : list,
                            marker   : filterId !== undefined ? this.getMarkers( list, filterId ) : ''
                        } )

                        this.addTotals( columns, summary, totals )

                        return resolve( this.resultResolver( lists, columns, filterId, totals, result ) )

                    } )
            }
            else
            {
                return resolve( result )
            }

        } )
    }

    getSummary( lists, noAverages, filterList )
    {

        return new Promise( resolve =>
        {

            let filterId = this.getState( 'detailViewFor' )

            if( undefined !== filterId )
            {
                let test = filterId.split( ':' )
                if( 'student' === test[ 0 ] )
                {
                    filterId = test[ 1 ]
                }
                else
                {
                    filterId = undefined
                }
            }

            this.filterId = filterId
            this.filterList = filterList || false

            let totals           = {},
                fieldset         = this.getFieldDefinition( lists, 'summary' ),
                columns          = this.getColumns( lists[ 0 ], fieldset, noAverages ),
                displayComponent = this.getSummaryDisplay( lists ),
                todo             = []

            for( let l in lists )
            {

                todo.push( lists[ l ] )

            }

            this.resultResolver( todo, columns, filterId, totals )
                .then( result =>
                {

                    return resolve( {
                        filterId         : filterId,
                        totals           : totals,
                        result           : result,
                        headTypes        : this.getHeadTypes( columns ),
                        displayComponent : displayComponent,
                        possibleRowCounts: this.getPossibleRowCount( lists )
                    } )

                } )

        } )

    }

}