<template>
    <div class="reloader-wrapper" @click="pageReload()">
        <transition appear mode="out-in" name="slide-fade-top">
            <div>
                <div class="reloader-spacer">&nbsp;</div>
                <div class="reloader">&#8635;</div>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    
    name   : 'Reloader',
    methods: {
        pageReload()
        {
            document.location.reload()
        }
    }
}
</script>