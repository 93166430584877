export default class StudentTranslations
{

    constructor()
    {
        return this
    }

    get()
    {
        return {
            '**************************** PAGES **********': '************************************************',
            'page-office'                                  : 'Feedback',
            '**************************** OBJECTS ********': '************************************************',
            'object-type-office'                           : 'Feedback aus Listen & Klassenarbeiten',
            '**************************** MESSAGES *******': '************************************************',
            'message-empty-own'                            : 'Sobald dir die ersten Einträge zur Verfügung gestellt worden sind, ändert sich das natürlich.',
            'message-empty-archive'                        : 'Das Archiv steht erst nach einem Schuljahreswechsel zur Verfügung: Halte durch!',
            'message-empty-foreign'                        : 'Sobald dir die ersten Einträge zur Verfügung gestellt worden sind, ändert sich das natürlich.',
            'message-empty-lists_not_test'                 : 'Hier sind noch keine Listen zu finden.',
            'message-empty-lists_only_test'                : 'Hier sind noch keine Klassenarbeiten oder Listen mit Benotung zu finden.',
            'message-empty-lists'                          : 'Hier sind noch keine Listen oder Klassenarbeiten zu finden.',
            'message-empty-notes'                          : 'Hier sind noch keine Notizen zu finden.',
            'message-empty-todos'                          : 'Hier sind noch keine Todos zu finden.',
            'message-empty-scorings'                       : 'Hier sind noch Listen mit Benotungen oder Klassenarbeiten zu finden.',
            '**************************** PROFILE ********': '************************************************',
            'delete_profile_html'                          : '<p>Das finden wir zwar sehr schade, doch heißt es auch "Reisende soll man nicht aufhalten".<br/>\n' +
                                                             'Um Dein Profil vollständig zu löschen, bestätige deinen Wunsch bitte durch einen Klick auf die\n' +
                                                             'Schaltfläche "Profil endgültig löschen".</p>\n' +
                                                             '<p><strong>Beachte bitte</strong>, dass dieser Schritt nicht rückgängig gemacht werden kann.<br/>' +
                                                             'Ein neues Profil kann jederzeit durch die Schulleitung angelegt und dir zur Verfügung gestellt werden.</p>',
            'profile_avatar_html'                          : '<p>Hier kannst du dein Profilbild, auch Avatar genannt, ändern. Dieses Bild ist nur für dich und deine\n' +
                                                             'Lehrerinnen und Lehrer sichtbar und kann selbst vom entzettelt-Support nicht entschlüsselt werden.</p>\n' +
                                                             '<p>Deinen Avatar kannst du entweder mit der App "frisch" aufnehmen oder ein auf deinem Gerät vorhandenes\n' +
                                                             'Bild verwenden:</p>',
            '**************************** PREHEATER ******': '************************************************',
            'preheating-captions'                          : [ 'denkt nach...',
                                                               'sortiert Farben...',
                                                               'bestellt schönes Wetter...',
                                                               'hat noch nicht fertig...',
                                                               'kalkuliert Schnittmengen...',
                                                               'teilt durch Null...',
                                                               'ist sicherererer...',
                                                               'zählt Erbsen...',
                                                               'grübelt noch...',
                                                               'freut sich auf die Ferien...' ]
        }
    }

}