export default class SyncCrudMissingUploads
{

    constructor( parent )
    {

        this.p = parent

        this.logSign = 'SyncWorker::SyncCrud::SyncCrudMissingUploads'

        this.parent = this.p.parent
        this.setState = parent.setState

        this.minAge = 3600 * 1000
        this.graceTime = 10000
        this.iteration = 0

        return this

    }

    /**
     * shouldSync
     * @returns {boolean}
     */
    shouldSync()
    {
        return true
    }

    /**
     * decryptAll
     * @param list
     */
    decryptAll( list, results )
    {
        return new Promise( resolve =>
        {

            results = results || []

            if( 0 < list.length )
            {

                let dbItem   = list.shift(),
                    localKey = this.parent.cryptoHelper.getOwnElementKey( dbItem.item )

                if( false !== localKey
                    && undefined !== dbItem
                    && undefined !== dbItem.item
                    && undefined !== dbItem.item.object )
                {

                    this.parent.cryptoHelper.decryptElement( localKey, dbItem.item.object )
                        .then( decrypted =>
                        {

                            if( false !== decrypted
                                && parseInt( this.parent.store.getters.idUser ) === parseInt( decrypted.idOwner ) )
                            {
                                results.push( {
                                    localId: dbItem.key,
                                    object : decrypted
                                } )
                            }

                            return resolve( this.decryptAll( list, results ) )

                        } )

                }
                else
                {
                    return resolve( this.decryptAll( list, results ) )
                }

            }
            else
            {
                return resolve( results )
            }

        } )
    }

    /**
     * listDiff
     * @param local
     * @param remote
     * @returns {Promise<unknown>}
     */
    listDiff( local, remote )
    {
        return new Promise( resolve =>
        {

            let matches = [],
                diffs   = {
                    notFound: []
                }

            while( local.length > 0 )
            {
                let foundMatch   = false,
                    localElement = local.shift()

                for( let r in remote )
                {
                    if( remote[ r ].localId === localElement.localId
                        && remote[ r ].remoteId === localElement.object.remoteId )
                    {
                        matches.push( {
                            remote: remote[ r ],
                            local : localElement
                        } )
                        foundMatch = true
                    }
                }

                if( !foundMatch )
                {
                    for( let r in remote )
                    {
                        if( remote[ r ].localId === localElement.localId
                            && remote[ r ].remoteId !== localElement.object.remoteId )
                        {
                            foundMatch = true
                        }
                    }
                }

                if( !foundMatch )
                {
                    diffs.notFound.push( {
                        local: localElement
                    } )
                }
            }

            return resolve( {
                matches: matches,
                diffs  : diffs
            } )

        } )
    }

    /*eslint-disable*/
    /**
     * fixDiff
     * @param diffList
     */
    fixDiff( diffList, types )
    {
        return new Promise( resolve =>
        {

            this.parent.logger.clog( this.logSign, 'fixing sync diff: ' + diffList.diffs.notFound.length + ' missing objects | checking ' + diffList.matches.length + ' updates...' )

            let uploadList   = [],
                fixedDiffs   = 0,
                fixedUploads = 0

            for( let m in diffList.matches )
            {

                let match         = diffList.matches[ m ],
                    testTimestamp = match.remote.timestamp + this.graceTime

                if( ( match.local.object.timestamp > testTimestamp
                      && match.local.object.timestamp < ( Date.now() - this.minAge )
                      && match.local.object.timestampForced !== true )
                    || match.local.object.update > testTimestamp
                    || ( match.local.object.update > 0 && match.remote.timestamp === null ) )
                {

                    fixedDiffs++
                    uploadList.push( {
                        type   : types.get( match.local.localId ),
                        localId: match.local.localId
                    } )

                }

            }

            for( let n in diffList.diffs.notFound )
            {
                fixedUploads++
                let match = diffList.diffs.notFound[ n ]
                uploadList.push( {
                    type   : types.get( match.local.localId ),
                    localId: match.local.localId
                } )
            }

            this.parent.logger.clog( this.logSign, 'sending ' + fixedUploads + ' missing uploads and ' + fixedDiffs + ' weird diffs into the queue...' )
            this.parent.database
                .writeUploadsList( uploadList )
                .then( () =>
                {

                    this.parent.logger.clog( this.logSign, 'done.' )
                    return resolve()

                } )

        } )

    }

    /**
     * checkLocalLeftovers
     * @param list
     */
    checkLocalLeftovers( list )
    {
        return new Promise( resolve =>
        {

            this.parent.database.readAllObjects( 'objects' )
                .then( dbList =>
                {

                    this.parent.database.readAllObjects( 'types' )
                        .then( typeList =>
                        {

                            let types = new Map()
                            for( let t in typeList )
                            {
                                types.set( typeList[ t ].key, typeList[ t ].item )
                            }

                            this.decryptAll( dbList )
                                .then( decryptedResult =>
                                {

                                    this.listDiff( decryptedResult, list )
                                        .then( diff =>
                                        {

                                            this.fixDiff( diff, types )
                                                .then( () =>
                                                {

                                                    return resolve()

                                                } )

                                        } )

                                } )

                        } )

                } )

        } )
    }

    /**
     * checkResults
     * @param list
     * @returns {Promise<unknown>}
     */
    checkResults( list )
    {
        return new Promise( resolve =>
        {

            let result = []

            for( let l in list )
            {
                if( null === list[ l ].tsmp_object_deleted
                    && null === list[ l ].tsmp_key_deleted )
                {

                    result.push( {
                        remoteId : list[ l ].id,
                        localId  : list[ l ].id_local,
                        timestamp: null !== list[ l ].datetime_updated
                                   ? this.parent.friendlyTimestamp.timestampFromMysql(
                                this.parent.friendlyTimestamp.convertServerTimestamp( list[ l ].datetime_updated ) )
                                   : null
                    } )

                }
            }

            this.checkLocalLeftovers( result )
                .then( () =>
                {
                    return resolve()
                } )

        } )
    }

    /**
     * prepareSyncables
     * @returns {Promise<unknown>}
     */
    prepareSyncables()
    {

        return new Promise( ( resolve, reject ) =>
        {

            let message = {
                method: 'objects.listAllKnownLocalIds'
            }

            this.parent.client.request( message )
                .then( response =>
                {

                    return resolve( response.result )

                } )
                .catch( () =>
                {
                    return reject()
                } )


        } )

    }

    /**
     * sync
     * @returns {Promise<unknown>}
     */
    sync()
    {

        return new Promise( resolve =>
        {

            this.iteration++

            if( 1 === this.iteration
                || 0 === this.iteration % 75 )
            {

                this.parent.logger.clog( this.logSign, 'performing sync run (approximately every 5 minutes and just after the initial sync)...' )
                this.prepareSyncables()
                    .then( list =>
                    {

                        this.checkResults( list )
                            .then( () =>
                            {

                                return resolve()

                            } )


                    } )
                    .catch( () =>
                    {

                        this.parent.logger.clog( this.logSign, 'sync run failed - starting over on next run' )
                        this.iteration = 0
                        return resolve()

                    } )

            }

        } )

    }

}