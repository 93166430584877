/*istanbul ignore next*/
export default class CoreTimerWorker
{
    
    constructor( logger )
    {
        
        if( !CoreTimerWorker.instance )
        {
            
            this.logger = logger
            
            this.workerCore = {
                worker      : this.createWorker(),
                idToCallback: {},
                currentId   : 0
            }
            
            this.workerCore.worker.onmessage = ( e ) =>
            {
                if( e.data.type === 'fire' )
                {

                    if( typeof( this.workerCore.idToCallback[ e.data.id ] ) === 'function' )
                    {
                        this.workerCore.idToCallback[ e.data.id ]()
                    }
                    else
                    {
                        this.logger.clog( 'CoreTimerWorker::worker.onmessage', 'firing event #'+e.data.id+' failed. no worker registered for this message.' )
                    }

                }
            }
            
            CoreTimerWorker.instance = this
        }
        
        return CoreTimerWorker.instance
        
    }
    
    stripContainerFunction( rawFunction )
    {
        
        let func = rawFunction.split( "\n" ),
            functionReturn = ''
        
        func.shift()
        func.pop()
        
        for( let f in func )
        {
            functionReturn += func[ f ].trim() + "\n"
        }
        
        return functionReturn
        
    }
    
    containerFunction()
    {
        
        return "var idMap = {};\n" +
               "\n" +
               "self.onmessage = function (e) {\n" +
               "if (e.data.type === 'setInterval') {\n" +
               "idMap[e.data.id] = setInterval(function () {\n" +
               "self.postMessage({\n" +
               "type: 'fire',\n" +
               "id: e.data.id\n" +
               "});\n" +
               "}, e.data.delay);\n" +
               "} else if (e.data.type === 'clearInterval') {\n" +
               "clearInterval(idMap[e.data.id]);\n" +
               "delete idMap[e.data.id];\n" +
               "} else if (e.data.type === 'setTimeout') {\n" +
               "idMap[e.data.id] = setTimeout(function () {\n" +
               "self.postMessage({\n" +
               "type: 'fire',\n" +
               "id: e.data.id\n" +
               "}); // remove reference to this timeout after is finished\n" +
               "\n" +
               "delete idMap[e.data.id];\n" +
               "}, e.data.delay);\n" +
               "} else if (e.data.type === 'clearCallback') {\n" +
               "clearTimeout(idMap[e.data.id]);\n" +
               "delete idMap[e.data.id];\n" +
               "}\n" +
               "};\n"
        
    }
    
    createWorker()
    {
        
        return new Worker( URL.createObjectURL( new Blob( [
            
            this.containerFunction()
        
        ], { type: 'application/javascript' } ) ) )
        
    }
    
    generateId()
    {
        return this.workerCore.currentId++;
    }
    
    doSetInterval( callback, delay )
    {
        
        let intervalId = this.generateId()
        
        this.workerCore.idToCallback[ intervalId ] = callback
        this.workerCore.worker.postMessage( {
            type : 'setInterval',
            delay: delay,
            id   : intervalId
        } )
        
        return intervalId
        
    }
    
    doClearInterval( intervalId )
    {
        
        this.workerCore.worker.postMessage( {
            type: 'clearInterval',
            id  : intervalId
        } )
        
        delete this.workerCore.idToCallback[ intervalId ];
        
    }
    
    doSetTimeout( callback, delay )
    {
        
        let intervalId = this.generateId()
        
        this.workerCore.idToCallback[ intervalId ] = () =>
        {
            callback();
            delete this.workerCore.idToCallback[ intervalId ];
        }
        
        this.workerCore.worker.postMessage( {
            type : 'setTimeout',
            delay: delay,
            id   : intervalId
        } )
        
        return intervalId
        
    }
    
    doClearTimeout( intervalId )
    {
        
        this.workerCore.worker.postMessage( {
            type: 'clearInterval',
            id  : intervalId
        } )
        
        delete this.workerCore.idToCallback[ intervalId ]
        
    }
    
}