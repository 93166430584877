import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class StudentsObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !StudentsObjectClass.instance )
        {

            let refList = [ 'timestamp',
                            'update',
                            'gender',
                            'color',
                            'firstname',
                            'lastname',
                            'address_1',
                            'address_2',
                            'zipcode',
                            'city',
                            'country',
                            'birthdate',
                            'confession',
                            'email',
                            'parent_1',
                            'parent_2',
                            'phone_1',
                            'phone_2',
                            'label_phone_1',
                            'label_phone_2' ]

            let personalAttributesStatesList = [
                {
                    storage: 'hiding',
                    state  : 'hidden',
                    list   : 'hideLists'
                }
            ]

            super( core, 'student', refList, personalAttributesStatesList )

            this.hasDetailView = true
            this.baseClassHelper = core.getBaseClassHelper()
            this.isStudentShareable = true
            this.t = ( key, params ) =>
            {
                return core.t( key, params )
            }

            this.objectType = 'students'

            this.multiEdit = [
                ':moveToClass',
                'color'
            ]

            StudentsObjectClass.instance = this

        }

        return StudentsObjectClass.instance

    }

    getFormFields()
    {

        return [
            {
                ref        : 'lastname',
                caption    : 'Name',
                placeholder: 'gib hier den Nachnamen ein',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'name',
                styleUpdate: false,
                checkExists: {
                    elements    : 'student',
                    checkForeign: true,
                    concatenate : 'firstname,lastname'
                }
            },
            {
                ref        : 'firstname',
                caption    : 'Vorname',
                placeholder: 'gib hier den Vornamen ein',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'name',
                styleUpdate: false,
                checkExists: {
                    elements    : 'student',
                    checkForeign: true,
                    concatenate : 'firstname,lastname'
                }
            },
            {
                ref        : 'gender',
                caption    : 'Geschlecht',
                type       : 'GenderSelector',
                reformatter: null,
                optional   : false,
                validator  : 'select',
                styleUpdate: true
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                reformatter: null,
                optional   : true,
                validator  : 'select',
                styleUpdate: true,
                setting    : 'studentColorModel:colors'
            },
            {
                ref        : 'birthdate',
                caption    : 'Geburtsdatum',
                placeholder: 'gib hier das Geburtsdatum ein',
                type       : 'TextBox',
                reformatter: 'date',
                optional   : true,
                validator  : 'date',
                styleUpdate: false
            },
            {
                ref        : 'address_1',
                caption    : 'Anschrift',
                placeholder: 'gib hier die Anschrift ein',
                type       : 'TextBox',
                reformatter: null,
                optional   : true,
                validator  : 'address',
                styleUpdate: false
            },
            {
                ref        : 'address_2',
                placeholder: ' ',
                caption    : '&nbsp;',
                type       : 'TextBox',
                reformatter: null,
                optional   : true,
                validator  : 'address',
                styleUpdate: false
            },
            {
                ref        : 'zipcode,city',
                caption    : 'PLZ, Ort',
                placeholder: 'PLZ,Ort',
                type       : 'ZipCity',
                reformatter: null,
                optional   : true,
                validator  : 'zipcity',
                styleUpdate: false
            },
            {
                ref        : 'country',
                caption    : 'Land',
                type       : 'CountrySelector',
                reformatter: null,
                optional   : true,
                validator  : 'select',
                styleUpdate: false
            },
            {
                ref        : 'confession',
                caption    : 'Konfession',
                type       : 'ConfessionSelector',
                reformatter: null,
                optional   : true,
                validator  : 'select',
                styleUpdate: false
            },
            {
                ref        : 'email',
                caption    : 'E-Mail-Adresse',
                type       : 'TextBox',
                reformatter: null,
                optional   : true,
                validator  : 'email',
                styleUpdate: false
            },
            {
                ref        : 'parent_1',
                caption    : 'Namen der Eltern',
                placeholder: 'Name des ersten Elternteils',
                type       : 'TextBox',
                reformatter: null,
                optional   : true,
                validator  : 'name',
                styleUpdate: false
            },
            {
                ref        : 'parent_2',
                caption    : '&nbsp;',
                placeholder: 'Name des zweiten Elternteils',
                type       : 'TextBox',
                reformatter: null,
                optional   : true,
                validator  : 'name',
                styleUpdate: false
            },
            {
                ref        : 'label_phone_1,phone_1',
                caption    : 'Telefonische Erreichbarkeit',
                placeholder: 'Bezeichnung,Rufnummer',
                type       : 'PhoneBox',
                reformatter: null,
                optional   : true,
                validator  : 'phonenumber',
                styleUpdate: false
            },
            {
                ref        : 'label_phone_2,phone_2',
                caption    : '&nbsp;',
                placeholder: 'Bezeichnung,Rufnummer',
                type       : 'PhoneBox',
                reformatter: null,
                optional   : true,
                validator  : 'phonenumber',
                styleUpdate: false
            }

        ]

    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return [ 'hide', 'edit', 'share', 'delete' ]
    }

}