<template>
    <AppReady v-if="translationsReady"/>
    <AppInitializing v-else/>
</template>

<script>
import AppInitializing from "@/components/app/AppInitializing";
import AppReady        from "@/components/app/AppReady";

export default {

    name: 'entzettelt',

    components: {
        AppReady,
        AppInitializing
    },

    created()
    {

        this.$core.getEventManager().add( 'on-translations-ready', () =>
        {
            this.translationsReady = true
        } )

    },

    data()
    {
        return {
            translationsReady: false
        }
    }

}
</script>
