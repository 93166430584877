import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class OrganizerContentObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !OrganizerContentObjectClass.instance )
        {

            let refList = [
                'title',
                'description',
                'useParentColor',
                'color',
                'organizerReference',
                'slotDisplay',
                'slots'
            ]

            let personalAttributesStatesList = []

            super( core, 'organizercontent', refList, personalAttributesStatesList )
            this.objectType = 'organizercontent'
            this.isStudentShareable = false

            OrganizerContentObjectClass.instance = this
        }

        return OrganizerContentObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'title',
                caption    : 'title',
                translate  : true,
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'freetext',
                styleUpdate: true
            },
            {
                ref        : 'description',
                caption    : 'description',
                translate  : true,
                type       : 'TextArea',
                reformatter: null,
                optional   : true,
                validator  : 'freetext',
                styleUpdate: true
            },
            {
                ref        : 'useParentColor',
                caption    : 'organizer-content-useParentColor',
                translate  : true,
                type       : 'Checkbox',
                reformatter: null,
                optional   : true,
                validator  : null
            },
            {
                ref        : 'color',
                caption    : 'color',
                translate  : true,
                type       : 'ColorSelector',
                reformatter: null,
                optional   : false,
                validator  : 'color',
                styleUpdate: true,
                hideIf     : 'useParentColor'
            },
            {
                ref             : 'slots',
                caption         : 'organizer-content-slots',
                translate       : true,
                type            : 'ContentSlotEditorBox',
                isRowAdder      : true,
                reformatter     : null,
                externalValidate: true,
                optional        : false,
                validator       : 'freetext',
                styleUpdate     : false
            },
            {
                ref        : 'organizerReference',
                caption    : 'in Planung',
                type       : 'OrganizerSelector',
                reformatter: null,
                optional   : false,
                validator  : 'select',
                styleUpdate: false
            },
            {
                ref        : 'slotDisplay',
                type       : 'Hidden',
                reformatter: null,
                validator  : null,
                styleUpdate: false
            }
        ]
    }

    getTabs()
    {
        return []
    }

    getFunctions()
    {
        return [ 'edit', 'share', 'delete' ]
    }

}