export default class SyncNetwork
{

    constructor( parent )
    {

        if( !SyncNetwork.instance )
        {

            this.ready = false
            this.parent = parent
            this.f = parent.f

            this.lastSync = 0
            this.lastAction = 0
            this.lastPoolRights = ''
            this.isSyncable = false

            this.states = {
                '1': 'INVITED',
                '2': 'CONNECTED',
                '3': 'DECLINED'
            }

            this.baseClassHelper = parent.getBaseClassHelper()

            if( undefined === this.baseClassHelper )
            {
                this.parent.eventManager.append( 'on-baseclasses-available', () => {
                    this.baseClassHelper = this.parent.getBaseClassHelper()
                    this.baseClass = this.baseClassHelper.get( 'colleague' )
                } )
            }
            else
            {
                this.baseClass = this.baseClassHelper.get( 'colleague' )
            }

            this.getState = ( key ) => {
                return this.parent.getState( key )
            }

            this.syncInterval = 30000
            this.lastSecretarySync = 0
            this.lastTeamSync = 0
            this.firstSync = true
            this.syncTimer = false

            this.doublesProcessed = false
            this.todo = 100
            this.done = 0
            this.deletions = 0

            this.processing = false
            this.pinSynced = false
            this.poolMasterSynced = false

            this.doubles = []

            this.cache = {
                iKeys : {},
                keys  : {},
                rights: {}
            }

            this.invitations = []
            this.rights = []
            this.colleagues = []
            this.teams = []
            this.changed = []

            this.changes = false

            SyncNetwork.instance = this

            this.parent.eventManager.add( 'sync-trigger-network', () =>
            {
                this.sync()
            } )

            this.parent.eventManager.add( 'sync-trigger-rights', () =>
            {
                this.syncRights()
            } )

            this.logSign = 'SyncWorker::SyncNetwork (NET)'
            
            this.parent.eventManager.append( 'on-core-components-reset', () =>
            {
                this.firstSync = true
                this.doublesProcessed = false
                this.lastPoolRights = ''
                this.doubles = []
                this.rights = []
                this.colleagues = []
                this.invitations = []
                this.teams = []
                this.changed = []
                this.reset()
            } )

            this.parent.logger.clog( this.logSign+'::construct :: Syncworker prepared.')

        }

        return SyncNetwork.instance

    }

    /*eslint-disable*/

    destruct()
    {

        this.colleagues = []
        this.invitations = []

        this.baseClass = null
        this.teamClass = null

        this.parent.eventManager.remove( 'sync-trigger-network' )
        this.parent.eventManager.remove( 'sync-trigger-rights' )
        this.lastSecretarySync = 0
        this.parent = null

        delete this.parent
        delete SyncNetwork.instance

    }

    colleagueExists( colleague )
    {

        for( let c in this.colleagues )
        {

            if( this.colleagues[ c ].colleagueId === colleague.colleagueId )
            {
                this.parent.logger.clog( this.logSign+'::colleagueExists', 'found colleague in local colleagues cache' )
                return true
            }
        }

        return false

    }

    preheat()
    {

        return new Promise( resolve =>
        {

            this.parent.logger.clog( this.logSign+'::preheat', 'preheating syncables...' )
            this.colleagues = []

            this.baseClassHelper
                .get( 'colleague' )
                .listAll()
                .then( colleagues =>
                {

                    for( let c in colleagues )
                    {

                        if( undefined !== colleagues[ c ].rights )
                        {
                            this.rights[ colleagues[ c ].colleagueId ] = colleagues[ c ].rights
                        }
                        else
                        {
                            this.rights[ colleagues[ c ].colleagueId ] = {
                                general : {},
                                elements: {}
                            }
                        }
                        if( this.colleagueExists( colleagues[ c ] ) )
                        {
                            this.doubles.push( colleagues[ c ] )
                        }
                        else
                        {
                            this.colleagues.push( colleagues[ c ] )
                        }

                    }

                    this.parent.logger.clog( this.logSign+'::preheat', 'found ' + this.colleagues.length + ' colleagues' )

                    this.teams = []

                    this.baseClassHelper
                        .get( 'team' )
                        .listAll()
                        .then( teams => {

                            for( let t in teams )
                            {
                                this.teams.push( teams[ t ] )
                            }

                            this.parent.logger.clog( this.logSign+'::preheat', 'found ' + this.teams.length + ' teams' )
                            return resolve()

                        })

                } )
                .catch( () =>
                {
                    this.parent.logger.clog( this.logSign+'::preheat', 'empty set: aborting.' )
                    return resolve()
                } )

        } )

    }

    isOwn( entry )
    {
        return parseInt( entry.id_colleague ) !== parseInt( this.parent.store.getters.idUser )
    }

    isNew( entry )
    {

        let colleagueId = this.isOwn( entry ) ? entry.id_colleague : entry.id

        for( let c in this.colleagues )
        {
            let colleague = this.colleagues[ c ]
            if( colleagueId === colleague.colleagueId )
            {
                return false
            }
        }

        return true
    }

    fetchColleague( colleagueId, inviteKey )
    {

        return new Promise( ( resolve, reject ) =>
        {

            let message = {
                method    : 'network.getColleagueDetails',
                deviceUuid: this.parent.store.getters.uuid,
                inviteKey : inviteKey,
                id        : colleagueId
            }

            this.parent.client.request( message )
                .then( requestResult =>
                {
                    return resolve( requestResult.result )
                } )
                .catch( error =>
                {
                    return reject( error )
                } )

        } )

    }

    triggerUiMessage( state, values )
    {

        let setup = {
            target: 'colleagues'
        }

        switch( this.states[ state ] )
        {
            case 'INVITED':
                setup.title = 'Neue Einladung'
                setup.message = '<strong>' + values.firstname + ' ' + values.lastname + '</strong> hat dich eingeladen, ihrem / seinem Kollegium beizutreten.'
                break
            case 'CONNECTED':
                setup.title = 'Einladung angenommen'
                setup.message = '<strong>' + values.firstname + ' ' + values.lastname + '</strong> hat deine Einladung angenommen und zählt nun zu deinem Kollegium. Ab sofort könnt ihr Elemente teilen und gemeinsam daran arbeiten.'
                break
            default:
                break
        }

        if( undefined !== setup.title )
        {
            this.parent.ui.showMessage( setup )
        }

    }

    showProgress( text, force )
    {
        if( !this.parent.silent || force )
        {
            this.parent.ui.blockerText( ( undefined === text ? '<strong>Kollegium</strong> wird synchronisiert...' : text ) )
            this.parent.ui.updateProgress( this.todo, this.done )
        }
    }

    prepareValueSet( entry )
    {

        return new Promise( ( resolve, reject ) =>
        {

            entry = this.parent.friendlyTimestamp.convertServerTimestamps( entry )

            let ownId,
                colleagueId,
                inviteKey

            switch( this.isOwn( entry ) )
            {
                case true:
                    ownId = entry.id
                    colleagueId = entry.id_colleague
                    inviteKey = entry.colleague_pin
                    break
                case false:
                    ownId = entry.id_colleague
                    colleagueId = entry.id
                    inviteKey = entry.own_pin
                    break

            }

            if( ownId === this.parent.store.getters.idUser )
            {

                this.fetchColleague( colleagueId, inviteKey )
                    .then( result =>
                    {

                        if( undefined !== result )
                        {

                            let values = {
                                firstname     : result.firstname,
                                lastname      : result.lastname,
                                school        : result.school,
                                school_zipcity: result.school_zipcity,
                                gender        : '',
                                color         : this.isOwn( entry ) ? entry.color : '',
                                inviteKey     : inviteKey,
                                publicKey     : '',
                                colleagueId   : colleagueId,
                                uuid          : '',
                                role          : '',
                                state         : entry.state,
                                initiator     : ( this.isOwn( entry ) ? ownId : colleagueId ),
                                forceTimestamp: this.parent.friendlyTimestamp.timestampFromMysql( entry.datetime_created )
                            }

                            return resolve( values )

                        }
                        else
                        {
                            this.parent.logger.cerror( 'SyncNetwork:createReference', 'undefined colleague-details: rejecting sync for now.' )
                            return reject()
                        }

                    } )
                    .catch( error =>
                    {

                        this.parent.logger.cerror( 'SyncNetwork:createReference', 'network error while fetching colleague: ' + error )
                        return reject()

                    } )

            }
            else
            {
                this.parent.logger.cerror( 'SyncNetwork:createReference', 'invitation mismatch? userId not own id' )
                return reject()
            }

        } )

    }

    createReference( entry )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.prepareValueSet( entry )
                .then( values =>
                {

                    let colleague = this.baseClassHelper
                                        .get( 'colleague' )
                    let jobId = colleague.create( values )

                    this.parent.eventManager.append( 'on-queue-done-' + jobId, () =>
                    {

                        /* only message if state is "invited" */
                        if( 1 === entry.state
                            && !this.isOwn( entry ) )
                        {
                            this.triggerUiMessage( entry.state, values )
                        }
                        return resolve( true )

                    } )

                } )
                .catch( () =>
                {
                    return reject()
                } )

        } )

    }

    fetchRemoteColleagueKeys( colleagues )
    {

        return new Promise( resolve =>
        {

            let requestColleagues = []
            for( let c in colleagues )
            {
                requestColleagues.push( {
                    inviteKey  : this.cache.iKeys[ colleagues[ c ] ],
                    colleagueId: colleagues[ c ]
                } )
            }

            let message = {
                method: 'network.getColleagueListKeys',
                list  : requestColleagues
            }

            this.parent.client.request( message )
                .then( result =>
                {

                    for( let l in result.result )
                    {

                        let row = result.result[ l ]
                        this.cache.keys[ parseInt( row.colleagueId ) ] = {
                            uuid     : row.uuid,
                            publicKey: row.publicKey
                        }

                    }

                    return resolve()

                } )
                .catch( () =>
                {
                    return resolve()
                } )

        } )

    }

    fetchRemoteColleagueRights( colleagues )
    {

        return new Promise( resolve =>
        {

            let requestColleagues = []
            for( let c in colleagues )
            {
                requestColleagues.push( {
                    inviteKey  : this.cache.iKeys[ colleagues[ c ] ],
                    colleagueId: colleagues[ c ]
                } )
            }

            let message  = {
                    method: 'network.getColleagueListRights',
                    list  : requestColleagues
                },
                promises = []

            this.parent.client.request( message )
                .then( result =>
                {

                    for( let l in result.result )
                    {

                        let row = result.result[ l ]
                        promises.push( () =>
                        {
                            return new Promise( resolve =>
                            {
                                try
                                {

                                    this.parent.cryptoHelper.decrypt( JSON.parse( atob( row.rights.rights ) ) )
                                        .then( setup =>
                                        {
                                            if( false !== setup )
                                            {
                                                this.cache.rights[ parseInt( row.colleagueId ) ] = {
                                                    setup: setup
                                                }
                                                return resolve()
                                            }
                                            else
                                            {
                                                this.cache.rights[ parseInt( row.colleagueId ) ] = 'init'
                                                return resolve()
                                            }
                                        } )

                                }
                                catch( e )
                                {
                                    this.cache.rights[ parseInt( row.colleagueId ) ] = 'init'
                                    return resolve()
                                }

                            } )
                        } )

                    }

                    this.f.promiseRunner( promises )
                        .then( () => {
                            return resolve()
                        })

                } )
                .catch( () =>
                {
                    return resolve()
                } )

        } )

    }

    /* eslint-disable */
    merge( values )
    {

        return new Promise( resolve =>
        {

            let changes = false,
                changedKeys = [],
                changedState = false,
                colleague = false,
                material = this.cache.keys[ values.colleagueId ]

            if( material !== 'init' )
            {
                let found = false

                for( let c in this.colleagues )
                {

                    colleague = this.colleagues[ c ]
                    if( colleague.colleagueId === values.colleagueId )
                    {

                        found = true

                        for( let m in material )
                        {
                            if( colleague[ m ] !== material[ m ] )
                            {
                                changedKeys.push( m )
                                colleague[ m ] = material[ m ]
                                changes = true
                            }
                        }

                        for( let k in values )
                        {
                            if( 'forceTimestamp' !== k
                                && 'publicKey' !== k
                                && 'uuid' !== k )
                            {
                                if( colleague[ k ] !== values[ k ] )
                                {
                                    changedKeys.push( k )
                                    colleague[ k ] = values[ k ]
                                    if( k === 'state' )
                                    {
                                        changedState = true
                                    }
                                    changes = true
                                }
                            }
                        }

                        return resolve( {
                            changes     : changes,
                            changedKeys : changedKeys,
                            changedState: changedState,
                            colleague   : colleague
                        } )

                    }

                }
            }
            else
            {
                return resolve( {
                    changes     : changes,
                    changedKeys : changedKeys,
                    changedState: changedState,
                    colleague   : colleague
                } )
            }

        } )

    }

    updateReference( entry )
    {
        return new Promise( ( resolve, reject ) =>
        {

            this.prepareValueSet( entry )
                .then( values =>
                {

                    for( let c in this.colleagues )
                    {
                        let colleague = this.colleagues[ c ]
                        if( colleague.colleagueId === values.colleagueId )
                        {
                            values.color = colleague.color
                        }
                    }

                    this.merge( values )
                        .then( merged =>
                        {

                            if( merged.changes === true )
                            {

                                let colleague = this.baseClassHelper
                                                    .get( 'colleague' )

                                let jobId = colleague.update(
                                    merged.colleague,
                                    merged.colleague.localId,
                                    merged.colleague.remoteId,
                                    merged.colleague.timestamp,
                                    merged.colleague.localKey )

                                this.parent.eventManager.append( 'on-queue-done-' + jobId, () =>
                                {

                                    if( merged.colleague.state === 2
                                        && merged.changedState === true
                                        && this.isOwn( entry ) )
                                    {
                                        this.triggerUiMessage( entry.state, values )
                                    }

                                    return resolve( true )

                                } )

                            }
                            else
                            {
                                return resolve( false )
                            }

                        } )

                } )

        } )
    }

    baseRights()
    {
        return {
            role  : '',
            rights: {}
        }
    }

    mergeColleagueRights( colleagueId )
    {

        return new Promise( resolve =>
        {

            let rights = this.baseRights()
            if( 'init' !== this.cache.rights[ colleagueId ]
                && this.parent.f.isset( this.cache.rights[ colleagueId ] )
                && this.parent.f.isset( this.cache.rights[ colleagueId ].setup ) )
            {
                rights = this.cache.rights[ colleagueId ].setup
            }

            let skeleton = JSON.parse( JSON.stringify( this.rights[ colleagueId ] ) ),
                target = { general: {}, elements: {} }

            skeleton = Object.assign( skeleton, rights )
            if( skeleton.general !== undefined )
            {
                target.general = skeleton.general
            }
            if( skeleton.elements !== undefined )
            {
                target.elements = skeleton.elements
            }

            let changed = JSON.stringify( target ) !== JSON.stringify( this.rights[ colleagueId ] )

            if( changed )
            {

                let found = false
                this.rights[ colleagueId ] = target

                for( let c in this.colleagues )
                {
                    if( parseInt( this.colleagues[ c ].colleagueId ) === parseInt( colleagueId ) )
                    {

                        found = true

                        let colleague = this.colleagues[ c ]
                        colleague.rights = this.rights[ colleagueId ]

                        let jobId = this.baseClass.update(
                            colleague,
                            colleague.localId,
                            colleague.remoteId,
                            colleague.timestamp,
                            colleague.localKey
                        )

                        this.parent.eventManager.append( 'on-queue-done-' + jobId, () =>
                        {
                            return resolve()
                        } )

                    }
                }

                if( !found )
                {
                    return resolve()
                }

            }
            else
            {
                return resolve()
            }

        } )

    }

    syncColleagueRights()
    {

        return new Promise( resolve =>
        {

            let queue = []

            for( let c in this.colleagues )
            {
                queue.push( this.colleagues[ c ].colleagueId )
            }

            let todo = []
            for( let q in queue )
            {
                todo.push( () =>
                {
                    return this.promiseFactory( 'mergeColleagueRights', queue[ q ] )
                } )
            }

            this.promiseFactoryRunner( todo )
                .then( () =>
                {
                    return resolve()
                } )

        } )
    }

    prefetchCache()
    {
        return new Promise( resolve =>
        {

            let colleagues = []

            for( let k in Object.keys( this.cache.keys ) )
            {
                let cId = Object.keys( this.cache.keys )[ k ]
                colleagues.push( cId )
            }

            this.fetchRemoteColleagueKeys( colleagues )
                .then( () =>
                {

                    this.fetchRemoteColleagueRights( colleagues )
                        .then( () =>
                        {

                            return resolve()

                        } )

                } )
                .catch( () =>
                {
                    return resolve()
                } )

        } )
    }

    removeFromCache( id )
    {

        let cache = {
            iKeys : {},
            keys  : {},
            rights: {}
        }

        for( let k in Object.keys( this.cache.keys ) )
        {

            let cId = Object.keys( this.cache.keys )[ k ]
            if( parseInt( cId ) !== parseInt( id ) )
            {
                cache.iKeys[ cId ] = this.cache.iKeys[ cId ]
                cache.keys[ cId ] = this.cache.keys[ cId ]
                cache.rights[ cId ] = this.cache.rights[ cId ]
            }

        }

        this.cache = cache

    }

    deleteColleague( id )
    {

        return new Promise( resolve =>
        {

            this.baseClass
                .listAll()
                .then( list =>
                {

                    let promises = []

                    for( let l in list )
                    {
                        if( parseInt( list[ l ].colleagueId ) === parseInt( id ) )
                        {

                            this.changes = true
                            promises.push( () =>
                            {
                                return this.baseClass.delete( list[ l ].localId, list[ l ].remoteId )
                            } )

                        }
                    }

                    this.parent.f.promiseRunner( promises )
                        .then( () =>
                        {
                            return resolve()
                        } )

                } )
                .catch( () =>
                {
                    return resolve()
                } )

        } )

    }

    cleanOrphaned( colleagues )
    {

        return new Promise( resolve => {
            this.baseClassHelper
                .get( 'colleague' )
                .listAll()
                .then( list => {

                    let deletion = []

                    for( let l in list )
                    {
                        if( -1 === colleagues.indexOf( ''+list[l].colleagueId )
                            && -1 === colleagues.indexOf( list[l].colleagueId )
                            && -1 === this.invitations.indexOf( ''+list[l].colleagueId )
                            && -1 === this.invitations.indexOf( list[l].colleagueId ) )
                        {

                            deletion.push( () => {
                                return this.baseClass
                                           .delete( list[l].localId, list[l].remoteId )
                            })

                        }
                    }

                    this.parent.f.promiseRunner( deletion )
                        .then( () => {
                            return resolve()
                        })

                })
        })

    }

    syncDeletedColleagues()
    {
        return new Promise( ( resolve, reject ) =>
        {

            let colleagues = []

            for( let k in Object.keys( this.cache.keys ) )
            {
                let cId = Object.keys( this.cache.keys )[ k ]
                colleagues.push( cId )
            }

            let message = {
                method      : 'network.getDeletedColleagueList',
                initialFetch: this.parent.getSyncParameters().mode === 'full',
                since       : this.parent.friendlyTimestamp.mysqlTimestamp( this.lastSync ),
                colleagues  : colleagues
            }

            this.parent.client.request( message )
                .then( syncResult =>
                {

                    if( undefined !== syncResult.list
                        && 0 < syncResult.list.length )
                    {

                        let promises = []

                        while( 0 < syncResult.list.length )
                        {

                            let row = syncResult.list.shift()

                            this.removeFromCache( row.id )
                            promises.push( () =>
                            {
                                return this.deleteColleague( row.id )
                            } )

                        }

                        this.parent.f.promiseRunner( promises )
                            .then( () =>
                            {
                                return resolve( this.cleanOrphaned( colleagues ) )
                            } )

                    }
                    else
                    {
                        return resolve( this.cleanOrphaned( colleagues ) )
                    }

                } )

        } )
    }

    syncColleagueList()
    {

        return new Promise( ( resolve, reject ) =>
        {

            let message = {
                method      : 'network.getColleagueList',
                initialFetch: this.parent.getSyncParameters().mode === 'full',
                since       : this.parent.friendlyTimestamp.mysqlTimestamp( this.lastSync )
            }

            this.parent.client.request( message )
                .then( syncResult =>
                {

                    for( let r in syncResult.result )
                    {
                        let c = syncResult.result[ r ]
                        if( 2 === c.state )
                        {

                            let colleagueId = this.isOwn( c ) ? c.id_colleague : c.id
                            let colleaguePin = this.isOwn( c ) ? c.colleague_pin : c.own_pin
                            if( undefined === this.cache.keys[ colleagueId ] )
                            {
                                this.cache.iKeys[ colleagueId ] = colleaguePin
                                this.cache.keys[ colleagueId ] = 'init'
                                this.cache.rights[ colleagueId ] = 'init'
                            }

                        }
                        if( 1 === c.state )
                        {
                            let colleagueId = this.isOwn( c ) ? c.id_colleague : c.id
                            if( -1 === this.invitations.indexOf( colleagueId ) )
                            {
                                this.invitations.push( colleagueId )
                            }
                        }
                    }

                    this.prefetchCache()
                        .then( () =>
                        {

                            this.syncDeletedColleagues()
                                .then( () =>
                                {

                                    this.todo = syncResult.result.length
                                    this.done = 0

                                    this.showProgress()

                                    if( 0 < syncResult.result.length )
                                    {

                                        let secretaries = this.parent.store.getters.secretaryUser
                                        for( let r in syncResult.result )
                                        {

                                            let entry = syncResult.result[ r ],
                                                method = false


                                            switch( this.isNew( entry ) )
                                            {
                                                case true:
                                                    method = 'createReference'
                                                    break
                                                case false:
                                                    method = 'updateReference'
                                                    break
                                            }

                                            this[ method ]( entry )
                                                .then( changed =>
                                                {

                                                    this.done++
                                                    this.showProgress()

                                                    if( changed )
                                                    {
                                                        this.changes = true
                                                        this.parent.eventManager.dispatch( 'refreshed-cache-colleagues' )
                                                        if( this.done === this.todo )
                                                        {
                                                            return resolve()
                                                        }
                                                    }
                                                    else
                                                    {
                                                        if( this.done === this.todo )
                                                        {
                                                            return resolve()
                                                        }
                                                    }

                                                } )
                                                .catch( () =>
                                                {
                                                    this.parent.logger.log( 'cerror', 'SyncNetwork::syncColleagueList->' + method, 'failed for entry', entry )
                                                    return reject()
                                                } )

                                        }

                                        this.parent.store.commit( 'setSecretaryUser', JSON.parse( JSON.stringify( secretaries ) ) )
                                    }
                                    else
                                    {
                                        return resolve()
                                    }

                                } )

                        } )

                } )
                .catch( () =>
                {
                    return reject()
                } )

        } )

    }

    syncDeletions()
    {

        return new Promise( resolve =>
        {

            if( this.done === this.todo )
            {

                let message = {
                    method: 'network.getColleagueList'
                }

                this.parent.client.request( message )
                    .then( syncResult =>
                    {

                        let notFound = []

                        for( let c in this.colleagues )
                        {
                            let colleague = this.colleagues[ c ]
                            let found = false
                            for( let r in syncResult.result )
                            {
                                let colleagueId = this.isOwn( syncResult.result[ r ] ) ? syncResult.result[ r ].id_colleague : syncResult.result[ r ].id
                                if( colleague.colleagueId === colleagueId )
                                {
                                    found = true
                                }
                            }

                            if( !found )
                            {
                                notFound.push( colleague )
                            }

                        }

                        this.deletions = notFound.length

                        for( let i in notFound )
                        {

                            let colleague = notFound[ i ]
                            this.baseClass.deleteObjectOnly( colleague.localId, colleague.remoteId )
                                .then( () =>
                                {

                                    this.changes = true
                                    this.deletions -= 1
                                    if( 0 === this.deletions )
                                    {
                                        this.parent.eventManager.dispatch( 'refreshed-cache-colleagues' )
                                    }

                                } )

                        }

                        return resolve()

                    } )
                    .catch( () =>
                    {
                        return resolve()
                    } )

            }
            else
            {
                setTimeout( () =>
                {

                    return resolve( this.syncDeletions() )

                }, 500 )
            }

        } )
    }

    syncAvatar()
    {
        return new Promise( resolve =>
        {

            this.parent.logger.clog( 'SyncNetwork::syncAvatar', 'synchronizing avatars...' )

            this.baseClassHelper
                .get( 'avatar' )
                .listAll()
                .then( allAvatars => {

                    for( let a in allAvatars )
                    {

                        let avatar = allAvatars[ a ]
                        if( avatar.colleagueId === this.parent.store.getters.idUser
                            && undefined !== avatar.remoteId )
                        {
                            for( let c in this.colleagues )
                            {
                                if( !this.parent.share.sharedWith( avatar, this.colleagues[ c ].uuid ) )
                                {
                                    this.changes = true
                                    this.parent.share.share( this.colleagues[ c ].localId, [ avatar ], [] )
                                }
                            }
                        }
                    }

                    this.parent.logger.clog( 'SyncNetwork::syncAvatar', 'synchronizing avatars: done.' )
                    return resolve()

                })

        } )
    }

    baseSync()
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.parent.logger.clog( 'SyncNetwork::baseSync', 'performing base sync...' )
            this.showProgress( '<strong>Kollegium wird synchronisiert</strong>' )

            if( true === this.parent.store.getters.authorized
                && !this.processing )
            {

                this.processing = true

                let queue = [
                    'syncColleagueList',
                    'syncDeletions',
                    'syncColleagueRights',
                    'syncAvatar'
                ]

                let todo = []
                for( let q in queue )
                {
                    todo.push( () =>
                    {
                        return this[ queue[ q ] ]()
                    } )
                }

                this.promiseFactoryRunner( todo )
                    .then( () =>
                    {

                        this.processing = false
                        this.parent.logger.clog( 'SyncNetwork::baseSync', 'done.' )
                        return resolve()

                    } )
                    .catch( () =>
                    {
                        this.parent.logger.clog( 'SyncNetwork::baseSync', 'done with errors' )
                        this.processing = false
                        return resolve()
                    } )

            }
            else
            {
                this.parent.logger.clog( 'SyncNetwork::baseSync', 'aborted: processing' )
                return reject()
            }

        } )
    }

    secretarySync()
    {

        return new Promise( resolve =>
        {

            if( true === this.parent.store.getters.authorized
                && ( this.parent.f.valid( this.parent.store.getters.flags )
                && -1 < this.parent.store.getters.flags.indexOf( 'flag_is_secretary' ) )
                && !this.processing )
            {

                if( ( Date.now() - 120000 ) > this.lastSecretarySync )
                {

                    this.lastSecretarySync = Date.now()

                    let message = {
                        method: 'secretary.getColleagues'
                    }

                    this.parent
                        .client
                        .request( message )
                        .then( response =>
                        {

                            let list = response.result
                            for( let l in list )
                            {

                                if( list[ l ].flag_is_secretary === 1 )
                                {

                                    let secretaries = this.parent.store.getters.secretaryUser
                                    if( -1 === secretaries.indexOf( list[ l ].id ) )
                                    {
                                        secretaries.push( list[ l ].id )
                                    }
                                    this.parent.store.commit( 'setSecretaryUser', JSON.parse( JSON.stringify( secretaries ) ) )

                                }

                                if( list[ l ].id !== this.parent.store.getters.idUser )
                                {

                                    let found = false
                                    let colleague = false

                                    for( let c in this.colleagues )
                                    {
                                        colleague = this.colleagues[ c ]
                                        if( colleague.colleagueId === list[ l ].id )
                                        {
                                            found = true
                                        }
                                    }

                                    if( !found )
                                    {

                                        let addMessage = {
                                            method: 'secretary.addColleague',
                                            id    : list[ l ].id
                                        }

                                        this.parent
                                            .client
                                            .request( addMessage )
                                            .then( () =>
                                            {

                                                this.parent.logger.log( 'added colleague entry' )

                                            } )

                                    }

                                }

                            }

                            return resolve()

                        } )
                        .catch( () =>
                        {
                            return resolve()
                        } )

                }
                else
                {

                    return resolve()

                }

            }
            else
            {
                return resolve()
            }
        } )

    }

    teamSync()
    {

        return new Promise( resolve =>
        {

            if( this.lastTeamSync < Date.now() - 90000 )
            {
                let promises = []
                for( let t in this.teams )
                {

                    let team = this.teams[ t ]
                    for( let c in team.colleagues )
                    {

                        let colleague = team.colleagues[ c ]
                        let found = false

                        for( let coll in this.colleagues )
                        {

                            let cached = this.colleagues[ coll ]
                            if( cached.uuid === colleague )
                            {
                                found = true
                            }

                        }

                    }

                }

                Promise.all( promises )
                       .then( () =>
                       {
                           this.lastTeamSync = Date.now()
                           return resolve()
                       } )

            }
            else
            {
                return resolve()
            }

        } )

    }

    syncRights()
    {

        return new Promise( resolve =>
        {

            let message = {
                method      : 'network.getColleagueRights',
                initialFetch: this.parent.getSyncParameters().mode === 'full',
                since       : this.parent.getSyncParameters().since
            }

            this.parent.client.request( message )
                .then( syncResult =>
                {

                    let list     = syncResult.result,
                        promises = [],
                        changes  = false

                    for( let l in list )
                    {

                        let entry = list[ l ]
                        promises.push( () =>
                        {
                            return this.parent.database.read( 'rights', entry.id_owner )
                                       .then( dbRights =>
                                       {
                                           if( dbRights !== entry.rights )
                                           {
                                               this.parent.database.write( 'rights', entry.id_owner, entry.rights )
                                                   .then( () =>
                                                   {
                                                       changes = true
                                                   } )
                                           }
                                       } )
                                       .catch( () =>
                                       {
                                           this.parent.database.write( 'rights', entry.id_owner, entry.rights )
                                               .then( () =>
                                               {
                                                   changes = true
                                               } )
                                       } )
                        } )

                    }

                    this.parent.f.promiseRunner( promises )
                        .then( () =>
                        {
                            if( changes
                                || this.firstSync )
                            {
                                this.parent.eventManager.dispatch( 'rights-refresh' )
                                this.parent.eventManager.dispatch( 'mx-rights-refresh' )
                                return resolve()
                            }
                            else
                            {
                                return resolve()
                            }
                        } )

                } )
                .catch( () =>
                {

                    return resolve()

                } )

        } )

    }

    syncPin()
    {

        return new Promise( resolve =>
        {

            if( !this.pinSynced )
            {

                this.changes = true

                let params = {
                    method: 'network.getPin'
                }
                this.parent
                    .client
                    .request( params )
                    .then( result =>
                    {

                        if( this.parent.f.valid( result.result ) )
                        {
                            this.parent.logger.clog( this.logSign+':::syncPin', 'networking PIN synchronized.' )
                            this.pinSynced = true
                            return resolve()
                        }
                        else
                        {
                            return resolve()
                        }

                    } )
                    .catch( () =>
                    {
                        this.parent.logger.clog( this.logSign+':::syncPin', 'networking PIN not synchronized: network error.' )
                        return resolve()
                    } )

            }
            else
            {
                return resolve()
            }

        } )

    }

    syncPoolRights()
    {
        return new Promise( resolve =>
        {

            if( this.poolMasterSynced )
            {

                let poolMaster = this.parent.store.getters.poolMaster
                if( null !== poolMaster
                    && -1 !== poolMaster )
                {

                    this.parent.logger.clog( this.logSign+':::syncPoolRights', 'getting pool rights...' )
                    let params = {
                        method        : 'network.getPoolRights',
                        id_pool_master: poolMaster
                    }
                    this.parent
                        .client
                        .request( params )
                        .then( response =>
                        {

                            if( this.lastPoolRights !== response.result )
                            {
                                this.parent.logger.clog( this.logSign+':::syncPoolRights', 'storing new pool rights' )
                                this.parent.store.commit( 'setPoolRights', JSON.parse( response.result ) )
                                this.parent.eventManager.dispatch( 'on-pool-rights-refresh' )
                                this.lastPoolRights = response.result
                            }

                            return resolve()

                        } )
                        .catch( e =>
                        {
                            this.parent.logger.clog( this.logSign+':::syncPoolRights', 'could not get rights:', e )
                            return resolve()
                        } )
                }
                else
                {
                    this.parent.logger.clog( this.logSign+':::syncPoolRights', 'no pool master set: skipping.' )
                    return resolve()
                }

            }

        } )
    }

    syncPoolMaster()
    {

        return new Promise( resolve =>
        {

            if( !this.poolMasterSynced )
            {

                this.changes = true

                let params = {
                    method: 'network.getPoolInformation'
                }
                this.parent
                    .client
                    .request( params )
                    .then( result =>
                    {

                        if( this.parent.f.valid( result.result ) )
                        {
                            this.parent.logger.clog( this.logSign+':::syncPoolMaster', 'poolMaster synchronized.' )
                            this.parent.store.commit( 'setPoolMaster', result.result )
                            this.poolMasterSynced = true
                            return resolve()
                        }
                        else
                        {
                            return resolve()
                        }

                    } )
                    .catch( e =>
                    {
                        if( 'ERROR_POOL' === e )
                        {
                            this.parent.logger.clog( this.logSign+':::syncPoolMaster', 'poolMaster synchronized: no pool given.' )
                            this.parent.store.commit( 'setPoolMaster', null )
                            this.poolMasterSynced = true
                            return resolve()
                        }
                        else
                        {
                            this.parent.logger.clog( this.logSign+':::syncPoolMaster', 'poolMaster not synchronized: network error.', e )
                            return resolve()
                        }
                    } )

            }
            else
            {
                return resolve()
            }

        } )

    }

    finalizeSync( state, changes )
    {
        return new Promise( resolve =>
        {

            if( true === state )
            {

                if( true === this.parent.core.getState( 'network-synced' )
                    && true === this.changes )
                {
                    this.parent.core.getEventManager().dispatch( 'on-mxregistry-full-refresh', 'colleagues' )
                    this.parent.core.getEventManager().append( 'mxRegistry-refresh-colleagues', () =>
                    {
                        this.parent.core.getEventManager().dispatchIndexed( 'after-sync' )
                    } )
                }

                this.parent.core.setState( 'network-synced', true )
                this.lastAction = Date.now()
                this.lastSync = Date.now()
                this.firstSync = false

                return resolve()

            }
            else
            {
                return resolve()
            }

        } )
    }

    cleanDoubles()
    {

        return new Promise( resolve =>
        {

            if( this.doublesProcessed === true )
            {
                return resolve()
            }

            let promises = []
            for( let d in this.doubles )
            {

                this.parent.logger.clog( this.logSign+':::cleanDoubles', 'identified ' + this.doubles[ d ].localId + ' as double entry: removing locally...' )
                promises.push( new Promise( rslv =>
                {

                    this.baseClass
                        .deleteDouble( this.doubles[ d ].localId, this.doubles[ d ].remoteId )
                        .then( () =>
                        {
                            return rslv()
                        } )

                } ) )

            }

            Promise.all( promises )
                   .then( () =>
                   {
                       this.doublesProcessed = true
                       return resolve()
                   } )

        } )
    }

    promiseFactoryRunner( factories )
    {

        let result = Promise.resolve()
        factories.forEach( ( factory ) =>
        {
            result = result.then( factory )
        } )
        return result

    }

    promiseFactory( prom, values )
    {
        return this[ prom ]( values )
    }

    sync()
    {

        this.changes = this.firstSync

        return new Promise( resolve =>
        {

            if( true !== this.getState( 'first-crud-sync-done' ) )
            {
                return resolve()
            }

            if( this.parent.flags.is( 'demouser' ) )
            {
                this.parent.logger.clog( this.logSign+'::sync', 'demouser won\'t sync netowrk.' )
                return resolve()
            }

            if( 'function' !== typeof this.parent.share.sharedWith )
            {
                this.parent.logger.clog( this.logSign+'::sync', 'skipped sync as share handler is not ready yet...' )
                return resolve( false )
            }

            if( this.parent.f.isOnlineSyncableState() )
            {
                this.parent.logger.clog( this.logSign+'::sync', 'performing sync run...' )
                if( this.lastAction < ( Date.now() - this.syncInterval ) )
                {

                    this.preheat()
                        .then( () =>
                        {

                            let queue = [
                                'syncPin',
                                'baseSync',
                                'cleanDoubles',
                                'secretarySync',
                                'syncRights',
                                'syncPoolMaster',
                                'syncPoolRights'
                            ]

                            let todo = []
                            for( let q in queue )
                            {
                                todo.push( () =>
                                {
                                    return this[ queue[ q ] ]()
                                } )
                            }

                            this.promiseFactoryRunner( todo )
                                .then( () =>
                                {

                                    this.parent.setState( 'network-synced', true )
                                    this.parent.eventManager.dispatch( 'on-network-synced' )
                                    this.parent.logger.clog( this.logSign+'::sync', 'synced network.' )
                                    return resolve( this.finalizeSync( true ) )

                                } )

                        } )

                }
                else
                {
                    this.parent.logger.clog( this.logSign+'::sync', 'skipped sync: idle for now.' )
                    this.parent.eventManager.dispatch( 'rights-refresh' )
                    return resolve( this.finalizeSync( false ) )
                }

            }
            else
            {
                this.parent.logger.clog( this.logSign+'::sync', 'skipped sync as user is offline' )
                this.parent.eventManager.dispatch( 'rights-refresh' )
                return resolve( false )
            }

        } )

    }

    reset()
    {
        this.colleagues = []
        this.doubles = []
        this.rights = []
        this.firstSync = true
        this.lastSync = 0
    }

}