<template>
    <div :class="'group hoverable '+item.color+( selected ? ' selected-item' : '' )"
         :key="'group-'+item.localId">
        <div class="groupname">
            {{ item.yeargroupname }}
        </div>
        <template v-if="initialized">
            <div class="groupcount">
                <strong>{{ studentCount }}</strong> {{ $core.t( 'generic-students' ) }}
            </div>
        </template>
        <template v-else>
            <div class="groupcount syncing" >
                {{ $core.t( 'generic-students' ) }} {{ $core.t( 'are-being-initialized' ) }}
            </div>
        </template>
    </div>
</template>

<script>
import MixinWatchElementKey from "@/mixins/MixinWatchElementKey";
import MixinHasStudentCount from "@/mixins/MixinHasStudentCount";

export default {

    name: 'Yeargroup',

    props: {
        item      : { type: Object, required: true },
        selected  : { type: Boolean, required: false, default: false },
        fakeCount : { type: Number, required: false, default: -1 },
        elementKey: { type: String, required: false, default: '' }
    },

    mixins: [ MixinWatchElementKey, MixinHasStudentCount ]

}
</script>