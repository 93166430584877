<template>
    <div class="organizer-contents-list">
        <p>Lege hier deine Planungs- bzw. Unterrichtsreihe an, die du in deinem Planungsraster hinterlegen möchtest.</p>
        <div class="form-row">
            <div class="form-caption"><strong>Bezeichnung</strong></div>
            <div class="form-component">
                <TextBox id="title"
                         refName="title"
                         :className="-1 < errors.indexOf( 'title' ) ? 'error' : ''"
                         placeholder="gib hier eine Bezeichnung ein"/>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="form-row">
            <div class="form-caption"><strong>Beschreibung</strong></div>
            <div class="form-component"><TextArea id="description"
                                                  refName="description"
                                                  placeholder="gib hier eine Beschreibung ein (optional)"/></div>
            <div class="clearfix"></div>
        </div>
        <div class="form-row">
            <div class="form-caption"><strong>Farbe</strong></div>
            <div class="form-component">
                <ColorSelector id="color"
                               refName="color"
                               undefCaption="wie Planungsraster"/>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="form-row">
            <div class="form-caption"><strong>Planungseinheiten</strong></div>
            <div class="form-component">
                <RowAdder id="slots"
                          refName="slots"
                          component="TextBox"
                          :hasErrors="-1 < errors.indexOf( 'slots' )"
                          placeholder="Bezeichnung der Planungs- / Unterrichtseinheit"/>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="form-row">
            <div class="form-caption">
                <button class="cancel" @click="$emit( 'close' )">abbrechen</button>
            </div>
            <div class="form-component">
                <button class="submit" @click="store()">Planungsreihe anlegen</button>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
import TextBox       from "@/components/form/elements/TextBox";
import TextArea      from "@/components/form/elements/TextArea";
import ColorSelector from "@/components/form/elements/ColorSelector";
import RowAdder      from "@/components/form/elements/RowAdder";

export default {

    name      : "OrganizerContentsForm",
    components: { RowAdder, ColorSelector, TextBox, TextArea },
    emits     : [ 'clicked', 'close', 'update' ],

    data()
    {
        return {
            errors           : [],
            organizerContents: {}
        }
    },

    /*eslint-disable*/

    methods: {

        validate( object )
        {

            this.errors = []

            for( let key in object )
            {
                switch( key )
                {
                    case 'title':
                        if( '' === object[ key ].trim() )
                        {
                            this.errors.push( key )
                        }
                        break
                    case 'slots':
                        if( 0 === object[ key ].length
                            || '' === object[ key ][ 0 ].title.trim() )
                        {
                            this.errors.push( 'slots' )
                        }
                        break
                }
            }

            return this.errors.length === 0

        },

        generateObjectFromForm()
        {

            let elmTitle       = document.querySelector( '#form-element-title' ),
                elmDescription = document.querySelector( '#form-element-description' ),
                elmColor       = document.querySelector( '#form-element-color' ),
                slots          = [],
                end            = false,
                row            = 0,
                object         = {}

            while( !end )
            {
                let element = document.querySelector( '#form-element-adder-slots-' + row )
                if( null !== element )
                {
                    slots.push( { title: element.value.trim(), slotIndex: null, date: null } )
                }
                else
                {
                    end = true
                }
                row++
            }

            object = {
                title      : elmTitle.value.trim(),
                description: 'undefined' !== elmDescription.value ? elmDescription.value.trim() : '',
                color      : elmColor.value || undefined,
                slots      : slots
            }

            return object

        },

        store()
        {

            let object = this.generateObjectFromForm()
            if( this.validate( object ) )
            {

                let localId = this.$core.getBaseClassHelper()
                                  .get( 'organizercontent' )
                                  .create( object )

                this.$core.getUi()
                    .showBlocker(
                        this.$core.t( 'generic-please-wait' ),
                        this.$core.t( 'organizer-contents-are-being-created' )
                    )

                this.$core.getEventManager()
                    .append( 'on-queue-done-' + localId, () => {

                        this.$core.getUi()
                            .delay( () =>
                            {

                                this.$core.getUi().hideBlocker()
                                this.$emit( 'update' )
                                this.$emit( 'close' )

                            }, 500 )

                    } )

            }
        }

    }

}
</script>