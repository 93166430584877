<template>
    <div class="close-button">
        <Button type="close" addClass="inverted tutor" :title="$core.t( 'tutorial-end' )" @clicked="$emit('close')"/>
    </div>
</template>

<script>
import Button from '@/components/elements/defaults/Button'

export default {

    name      : 'TutorialCloser',
    components: { Button },
    emits     : [ 'close' ]

}
</script>