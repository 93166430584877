<template>
    <div class="quickmarks" :key="'quickmarks-'+item.elementKey">
        <div :key="'pinned-'+item.localId" v-if="item.pinned === true" class="quickmark pinned"></div>
        <div :key="'hidden-'+item.localId" v-if="item.hidden === true" class="quickmark is-hidden"></div>
        <SyncStateIndicator v-if="$core.settings().getSetting( 'showSyncState' ) === true"
                            :key="'syncstate-'+item.localId"
                            :localId="item.localId"
                            :elementKey="item.elementKey"
                            :state="synced"
                            :syncable="item"/>
        <ShareIndicator v-if="false === noShareIndicator"
                        :key="'share-'+item.localId"
                        :scope="scope"
                        :item="item"/>
        <SharedWithStudentsIndicator v-if="false === noShareIndicator"
                                     :key="'sws-'+item.localId"
                                     :scope="scope"
                                     :item="item"/>
        <DemoContentIndicator v-if="true === item.isDemoContent" />
    </div>
</template>

<script>
/*eslint-disable*/
import SyncStateIndicator          from "@/components/elements/indicators/SyncStateIndicator";
import ShareIndicator              from "@/components/elements/indicators/ShareIndicator";
import SharedWithStudentsIndicator from "@/components/elements/indicators/SharedWithStudentsIndicator";
import DemoContentIndicator        from "@/components/elements/indicators/DemoContentIndicator";

export default {

    name      : "Quickmarks",
    components: { DemoContentIndicator, SharedWithStudentsIndicator, ShareIndicator, SyncStateIndicator },
    props     : {
        item            : { Type: Object, required: true },
        scope           : { Type: String, required: true },
        noShareIndicator: { Type: Boolean, required: false, default: false }
    },

    computed: {
        synced()
        {
            return undefined !== this.$props.item.remoteId && 0 < parseInt( this.$props.item.remoteId )
        }
    }
}
</script>