<template>
    <div>
        <div v-if="!isEditor()">
            <span v-html="display"></span>
        </div>
        <div v-if="isEditor()">
            <input type="text" class="label" :placeholder="placeholders.label" @change="updateValue()"
                   :id="getRefName(0)" :value="label"/>
            <input type="text" class="phone" :placeholder="placeholders.phone" @change="updateValue()"
                   :id="getRefName(1)" :value="phone"/>
        </div>
    </div>
</template>

<script>

export default {
    name : 'PhoneBox',
    props: {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        validator  : { Type: String, required: false },
        placeholder: { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        editor     : { Type: String, required: false },
        eventKey   : { Type: String, required: false }
    },

    emits: [ 'update' ],

    data()
    {
        return {
            label       : '',
            phone       : '',
            placeholders: {
                label: '',
                phone: ''
            }
        }
    },

    created()
    {
        if( undefined !== this.$props.value )
        {
            this.label = this.$props.value[ this.getRefName( 0, true ) ]
            this.phone = this.$props.value[ this.getRefName( 1, true ) ]
        }
        this.updateValue()
        this.setPlaceHolder()
    },

    methods: {

        setPlaceHolder()
        {
            if( undefined !== this.$props.placeholder )
            {
                let ph = this.$props.placeholder.split( /,/ )
                this.placeholders.label = ph[ 0 ]
                this.placeholders.phone = ph[ 1 ]
            }
        },

        updateValue()
        {
            let labelElm = document.querySelector( '#' + this.getRefName( 0 ) )
            let phoneElm = document.querySelector( '#' + this.getRefName( 1 ) )

            if( null !== labelElm )
            {
                this.label = labelElm.value
            }
            if( null !== phoneElm )
            {
                this.phone = phoneElm.value
            }

            let submit = {}
            submit[ this.getRefName( 0, true ) ] = this.label
            submit[ this.getRefName( 1, true ) ] = this.phone

            this.$emit( 'update', this.$props.refName, 'zipCity', 'update', undefined, submit )
        },
        getRefName( id, plain )
        {
            let ref = this.$props.refName.split( ',' )
            if( !plain )
            {
                return 'form-element-' + ref[ id ]
            }
            return ref[id]
        },
        isEditor()
        {
            return ( undefined === this.$props.display || this.$props.editor === true )
        },
        skip( event )
        {
            event.stopPropagation()
        },
        reformatInput()
        {
        }
    }

}
</script>