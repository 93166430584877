<template>
    <div :class="'labels-filter'+( '' !== filterValue && false !== filterValue? ' selection' : '')" id="labels-filter">
        <div :class="'select-arrow'+( '' !== filterValue  && false !== filterValue ? ' selection' : '')"
             id="labels-filter-inner"
             v-if="initialized">
            <div :class="'label-icon'+( '' !== filterValue  && false !== filterValue ? ' selection' : '')"></div>
            <select :class="'labels-filter'+( '' !== filterValue  && false !== filterValue ? ' selection' : '')"
                    name="label-filter"
                    id="labels-filter-select" @change="filter">
                <option value="">bitte wählen...</option>
                <option v-for="labelItem in labels"
                        v-bind:key="'key_option_'+labelItem.localId"
                        :selected="filterValue === labelItem.localId"
                        :value="labelItem.localId">{{ labelItem.caption }}
                </option>
            </select>
        </div>
        <div class="labels-filter-select syncing" v-else>
            ...
        </div>
    </div>
</template>

<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";
import MixinFilterState    from "@/mixins/MixinFilterState";

export default {

    name  : 'LabelsFilter',
    mixins: [ MixinCachePreheater, MixinFilterState ],

    props: {
        title   : { type: String, required: false },
        archived: { type: Boolean, required: false },
        own     : { type: Boolean, required: false },
        list    : { type: Object, required: false }
    },

    emits: [ 'filter', 'detach', 'attach' ],

    data()
    {
        return {
            labels      : [],
            filters     : [],
            filterValue : false,
            initialized : false,
            neededCaches: [ 'label' ]
        }
    },

    watch: {
        archived: {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && undefined === this.$props.list )
                {
                    this.labels = []
                    this.filters = []
                    this.initialize()
                    this.emitValue( '' )
                }
            },
            deep: true
        },
        own     : {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && undefined === this.$props.list )
                {
                    this.labels = []
                    this.filters = []
                    this.initialize()
                    this.emitValue( '' )
                }
            }
        }
    },

    created()
    {

        this.$nextTick()
            .then( () =>
            {

                this.$core.getEventManager().add( 'on-labelfilter-preselect', ( value ) =>
                {
                    this.emitValue( value )
                } )
                if( undefined === this.$props.list )
                {
                    this.initialize()
                }
                else
                {
                    this.labels = this.$props.list.labels || []
                    this.initialized = true
                }

            } )

    },

    beforeUnmount()
    {
        this.$core.getEventManager().remove( 'on-labelfilter-preselect' )
        this.$emit( 'detach' )
    },

    methods: {

        filterByOwner( element, own )
        {
            let isOwn = this.$core.f().isOwn( element )
            return own ? isOwn : true
        },

        initialize()
        {

            this.initialized = false

            let isArchived = this.$props.archived === true,
                own        = this.$props.own === undefined ? true : this.$props.own

            this.awaitNeededCaches()
                .then( () =>
                {

                    let labels = this.$core
                                     .getBaseClassHelper()
                                     .get( 'label' )
                                     .readCache()

                    labels = this.$core.getSorter().sortObjects( labels, 'caption' )

                    for( let l in labels )
                    {
                        if( isArchived === labels[ l ].archived
                            && this.filterByOwner( labels[ l ], own ) )
                        {
                            this.labels.push( {
                                localId: labels[ l ].localId,
                                caption: labels[ l ].caption + ( isArchived ? ' ' + this.$core.getReformatter().timeForArchiveKey( labels[ l ] ) : '' )
                            } )
                        }
                    }

                    this.$emit( 'attach', {
                        type  : 'label',
                        filter: this.filters
                    } )

                    this.initialized = true
                    this.restoreFilters()

                } )

        },

        filter( event )
        {

            this.filterValue = event.target.value
            this.emitValue()
            this.storeFilterState( this.filterValue )

        },

        emitValue()
        {

            let value = this.filterValue

            if( false === value || value === '' )
            {
                this.$emit( 'filter', 'labels', undefined )
                return
            }

            for( let l in this.labels )
            {
                if( this.labels[ l ].localId === value )
                {
                    this.$emit( 'filter', 'labels', [ { 'attr': 'labelId', value: this.labels[ l ].localId } ] )
                }
            }

        },


        resetFilters()
        {
            this.filterValue = false
            this.emitValue()
        },

        restoreFilters()
        {

            let filterValue = this.getStoredFilterState()

            if( ( Array.isArray( filterValue ) && 0 === filterValue.length )
                || false === filterValue )
            {
                return
            }

            this.filterValue = filterValue
            this.emitValue()
        }

    }
}
</script>