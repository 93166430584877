<template>
    <div :class="'tutorial-button '+className+( blocked ? ' blocked' : '' )" :id="'tutor-button-'+className" @click="$emit( 'clicked' )">
        {{ caption }}
    </div>
</template>

<script>
export default {
    name    : 'TutorialButton',
    props   : {
        className: { Type: String, optional: false },
        step     : { Type: Number, optional: false },
        last     : { Type: Number, required: true },
        blocked  : { Type: Boolean, required: false, default: false }
    },
    emits   : [ 'clicked' ],
    computed:
        {
            caption()
            {
                switch( this.$props.className )
                {
                    case 'next':
                        switch( this.$props.step )
                        {
                            case 1:
                                if( this.$props.step !== this.$props.last )
                                {
                                    return 'Einführung starten'
                                }
                                return 'Einführung beenden'
                            case this.$props.last:
                                return 'Einführung beenden'
                            default:
                                return 'weiter'
                        }
                    case 'back':
                        switch( this.$props.step )
                        {
                            case 1:
                                return 'abbrechen'
                            default:
                                return 'zurück'
                        }
                }
                return ''
            }
        }
}
</script>