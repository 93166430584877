<template>
    <div class="list-element filebox">
        <template v-if="true === $store.getters.online">
            <template v-if="false !== media">
                <template v-if="!processing">
                    <FileBox v-if="media"
                             :noDelete="true"
                             :value="media.fileId"
                             @mediaClicked="$core.f().skip( $event )"/>
                </template>
                <template v-else>
                    <div class="file-preview-processing-align">
                        <div class="file-preview-processing"></div>
                    </div>
                </template>
            </template>
        </template>
        <FileBoxOfflineMessage v-if="false === $store.getters.online && $core.f().valid( value )"/>
    </div>
</template>

<script>
/*eslint-disable*/
import FileBoxOfflineMessage from "@/components/form/elements/FileBoxOfflineMessage";
import MixinCachePreheater   from "@/mixins/MixinCachePreheater";
import FileBox               from "@/components/form/elements/FileBox";

export default {
    name      : 'listElement-image',
    components: { FileBox, FileBoxOfflineMessage },

    mixins: [ MixinCachePreheater ],

    props: {
        value   : { type: String, required: false, default: undefined },
        fulltext: { type: Boolean, required: false, default: undefined }
    },

    data()
    {
        return {
            media       : false,
            processing  : false,
            neededCaches: [ 'media' ]
        }
    },

    created()
    {
        this.processing = true
        this.awaitNeededCaches()
            .then( () =>
            {
                if( this.$core.f().valid( this.$props.value ) )
                {

                    let media = this.$core
                                    .getBaseClassHelper()
                                    .get( 'media' )
                                    .getById( this.$props.value )
                    this.media = media || false
                }
                this.processing = false
            } )
    }

}
</script>
