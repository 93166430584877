import Button              from '@/components/elements/defaults/Button'
import LabelsFilter        from '@/components/elements/filters/LabelsFilter';
import ClassesFilter       from '@/components/elements/filters/ClassesFilter'
import ColorFilter         from '@/components/elements/filters/ColorFilter'
import FunctionButton      from '@/components/elements/defaults/FunctionButton'
import StudentFilter       from '@/components/elements/filters/StudentFilter'
import Toggle              from '@/components/form/elements/Toggle'
import PreheatingIndicator from '@/components/elements/indicators/PreheatingIndicator'
import Form                from '@/components/form/Form'
import Touchable           from "@/components/defaults/Touchable";
import AdminGeneralRights  from "@/components/routes/secretary/AdminGeneralRights"
import AdminStudentAccess  from "@/components/routes/secretary/AdminStudentAccess"

const components = [
    Button,
    FunctionButton,
    LabelsFilter,
    ClassesFilter,
    ColorFilter,
    StudentFilter,
    Toggle,
    PreheatingIndicator,
    Form,
    AdminGeneralRights,
    AdminStudentAccess,
    Touchable
]

export default components