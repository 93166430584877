<template>
    <div>
        <template v-if="plain">
            <input type="text"
                   :id="'form-element-'+id"
                   :class="foreignHighlighted"
                   :v-model="vModel"
                   :name="refName"
                   :autocomplete="autocomplete"
                   :ref="refName"
                   :disabled="disabled"
                   :value="display"
                   :placeholder="placeholder ? placeholder : 'gib hier deinen Text ein'"
                   @blur="update"
                   @keyup="checkKey"/>
        </template>
        <template v-else>
            <input v-if="!$core.states.get( 'excel-exporting' ) === true"
                   :type="type || 'text'"
                   :steps="steps || undefined"
                   :class="foreignHighlighted"
                   :id="'form-element-'+id"
                   :name="refName"
                   :ref="refName"
                   :value="display"
                   :disabled="disabled"
                   :autocomplete="autocomplete ? autocomplete : undefined"
                   :placeholder="placeholder ? placeholder : 'gib hier deinen Text ein'"
                   @focusin="$emit( 'focussed', refName )"
                   @focusout="$emit( 'blurred', refName )"
                   @blur="update"
                   @keyup="checkKey"/>
            <span v-else>{{ display }}</span>
        </template>
    </div>
</template>

<script>
import MixinResettableComponent from '@/mixins/MixinResettableComponent'

export default {

    name: 'TextBox',

    mixins: [ MixinResettableComponent ],

    props: {
        id          : { Type: String, required: true },
        refName     : { Type: String, required: false },
        vModel      : { Type: String, required: false },
        value       : { Type: String | Object, required: false },
        reformatter : { Type: String, required: false },
        highlighted : { Type: Object, required: false, default: [] },
        eventKey    : { Type: String, required: false },
        disabled    : { Type: Boolean, required: false, default: false },
        placeholder : { Type: String, required: false },
        autocomplete: { Type: String, required: false },
        className   : { Type: String, required: false },
        next        : { Type: String, required: false },
        type        : { Type: String, required: false },
        steps       : { Type: Number, required: false },
        plain       : { Type: Boolean, required: false, default: false },
        allValues   : { Type: Object, required: false },
        unique      : { Type: Boolean, required: false }
    },

    emits: [ 'update', 'enterPressed', 'backspacePressed', 'focussed', 'blurred' ],

    data()
    {
        return {
            timer       : false,
            hasErrors   : false,
            display     : false,
            isProcessing: false,
            initialValue: false,
            emptyBefore : false,
            formElm     : false
        }
    },

    computed: {
        foreignHighlighted()
        {
            return this.$props.className + ( this.hasErrors ? ' error' : '' ) + ' '
                   + ( -1 < this.$props.highlighted.indexOf( this.$props.refName ) ? 'highlighted' : '' )
        }
    },

    created()
    {
        this.parseValue()
    },

    mounted()
    {
        this.initialValue = ( undefined !== this.$props.value ? '' + this.$props.value : '' )
        this.formElm = document.querySelector( '#form-element-' + this.$props.id )
    },

    watch: {
        value: {
            immediate: true,
            deep     : true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.parseValue()
                }
            }
        }
    },

    methods: {

        parseValue()
        {
            if( !this.$core.f().valid( this.$props.value ) )
            {
                this.display = ''
            }
            else
            {
                this.display = ( this.$core.f().isObject( this.$props.value ) ? this.$props.value.value : this.$props.value )
            }
        },

        checkKey( key )
        {
            if( 13 === key.keyCode
                || 13 === key.which
                || 'Enter' === key.key )
            {
                key.preventDefault()
                this.$emit( 'enterPressed', this.$props.id )
            }
            else
            {
                if( 8 === key.keyCode )
                {
                    if( '' === this.formElm.value.trim()
                        && this.emptyBefore )
                    {
                        this.$emit( 'backspacePressed', this.$props.id )
                    }
                }

                this.emptyBefore = '' === this.formElm.value.trim()

                if( this.$core.f().isset( this.$props.reformatter ) )
                {
                    if( this.timer )
                    {
                        clearTimeout( this.timer )
                    }
                    this.timer = setTimeout( () =>
                    {

                        this.reformatInput()

                    }, 5000 )
                }
                this.update()
            }
        },

        update()
        {
            if( !this.isProcessing )
            {
                this.isProcessing = true
                if( null !== this.formElm )
                {
                    if( this.initialValue !== this.formElm.value.trim() )
                    {

                        if( true === this.$props.unique )
                        {
                            this.hasErrors = false
                            let countval = 0
                            for( let v in this.$props.allValues )
                            {
                                if( undefined !== this.$props.allValues[ v ].value )
                                {
                                    let value = this.$props.allValues[ v ].value.trim()
                                    if( this.formElm.value.trim() === value )
                                    {
                                        countval++
                                    }
                                }
                            }
                            this.hasErrors = countval > 1
                        }

                        this.display = this.formElm.value
                        this.$emit( 'update', this.$props.refName, 'textBox', 'update', undefined, ( this.formElm.value.trim() !== '' ? this.formElm.value : undefined ) )

                    }
                }

                this.isProcessing = false
            }
        },

        reformatInput()
        {

            if( null !== this.formElm )
            {
                if( this.$core.f().isset( this.$props.reformatter ) )
                {
                    let value = this.$core.getReformatter().reformat( this.formElm.value, this.$props.reformatter )
                    this.display = value
                }

                this.formElm.value = this.display
                this.$emit( 'update', this.$props.refName, 'textBox', 'update', undefined, this.formElm.value )

            }

        }

    }

}
</script>