export default class SyncSlotTemplates
{

    constructor( parent )
    {

        this.parent = parent
        this.parent.logger.clog( 'SyncWorker::SyncSlotTemplates', 'initialized' )

        this.total = 0
        this.step = 0
        this.lastAction = 0
        this.minInterval = 600000

    }

    showProgress( force )
    {
        if( !this.parent.silent || force )
        {
            this.parent.ui.blockerText( '<strong>Planungsvorlagen</strong> werden synchronisiert...' )
            this.parent.ui.updateProgress( this.total, this.step )
        }
    }

    prepareSyncables()
    {

        return new Promise( ( resolve, reject ) =>
        {
            this.parent.logger.clog( 'SyncWorker::SyncSlotTemplates', 'fetching templates from remote...' )

            let message = {
                method: 'objects.getSlotTemplates'
            }

            this.parent.client.request( message )
                .then( response =>
                {

                    return resolve( response.templates )

                } )
                .catch( error =>
                {
                    return reject( error )
                } )

        } )
    }

    /*eslint-disable*/
    _processTemplateList( list )
    {

        let templates = {}
        let count = 0

        for( let l in list )
        {

            let item = list[ l ]
            if( undefined === templates[ item.templateIdentifier ] )
            {
                templates[ item.templateIdentifier ] = {
                    id        : item.id,
                    identifier: item.templateIdentifier,
                    listname  : item.templateName.trim(),
                    slots     : JSON.parse( item.slots )
                }
                count++
            }

        }

        this.total = count
        this.showProgress()

        return templates

    }

    _processQueue( queue )
    {
        return new Promise( resolve =>
        {

            if( 0 < queue.length )
            {

                let template = queue.shift()
                this.parent.database.write( 'slotTemplates', 'preset_' + template.identifier, template )
                    .then( () =>
                    {

                        this.step++
                        return resolve( this._processQueue( queue ) )

                    } )
            }
            else
            {
                this.parent.logger.clog( 'SyncWorker::SyncSlotTemplates:_processQueue', 'done synchronizing ' + this.total + ' templates' )
                this.showProgress()
                return resolve()
            }

        } )
    }

    storeTemplates( templates )
    {

        return new Promise( resolve =>
        {

            let queue = []
            for( let id in templates )
            {
                queue.push( templates[ id ] )
            }
            return resolve( this._processQueue( queue ) )

        } )

    }

    sync()
    {

        return new Promise( resolve =>
        {

            this.parent.logger.clog( 'SyncWorker::SyncSlotTemplates:sync', 'run' )

            this.step = 0
            this.total = 999
            this.showProgress()

            if( this.parent.f.isOnlineSyncableState()
                && undefined !== this.parent.license
                && undefined !== this.parent.license.license )
            {
                if( this.lastAction < Date.now() - this.minInterval )
                {
                    this.prepareSyncables()
                        .then( list =>
                        {

                            let templates = this._processTemplateList( list )
                            this.storeTemplates( templates )
                                .then( () =>
                                {

                                    this.lastAction = Date.now()
                                    this.parent.eventManager.dispatch( 'slot-templates-refreshed' )
                                    return resolve()

                                } )

                        } )
                        .catch( error =>
                        {
                            this.parent.logger.cerror( 'SyncWorker::SyncSlotTemplates:sync', error )
                            return resolve()
                        } )
                }
                else
                {
                    this.parent.logger.clog( 'SyncWorker::SyncSlotTemplates:sync', 'idle for at least 10 minutes.' )
                    return resolve()
                }
            }
            else
            {
                this.parent.logger.clog( 'SyncWorker::SyncSlotTemplates:sync', 'app not in syncable state for templates.' )
                return resolve()
            }
        } )

    }

}