import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class LabelsObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !LabelsObjectClass.instance )
        {

            let refList = [ 'caption',
                            'color',
                            'timestamp',
                            'update' ]

            let personalAttributesStatesList = []

            super( core, 'label', refList, personalAttributesStatesList )

            this.objectType = 'labels'

            LabelsObjectClass.instance = this
        }

        return LabelsObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'caption',
                caption    : 'Name',
                type       : 'TextBox',
                styleUpdate: false,
                reformatter: null,
                optional   : false,
                validator  : 'text'
            },
            {
                ref        : 'color',
                caption    : 'color',
                translate  : true,
                type       : 'ColorSelector',
                reformatter: null,
                optional   : false,
                validator  : 'color',
                styleUpdate: true
            }
        ]
    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return []
    }

}