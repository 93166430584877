<template>
    <span class="tick-bullet" v-bind:class="className">&bull;</span>
</template>

<script>

export default {
    
    name  : 'Tick',

    data()
    {
        return {
            className: 'dark'
        }
    },
    
    created()
    {
        this.$core.getEventManager()
            .add( 'tick-state', () =>
            {
                this.className = 'lit'
                setTimeout( () =>
                {
                    this.className = 'dark'
                }, 500 )
            } )
    },
    
    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'tick-state' )
    }
    
}
</script>