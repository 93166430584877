import FastBus from '@/classes/Core/FastBus'

const FastBusPlugin = {

    install: ( app, core ) => {

        let _FastBus = new FastBus( core )
        app.config.globalProperties.$bus = _FastBus

    }
};

export default FastBusPlugin;