import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class GroupsObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !GroupsObjectClass.instance )
        {

            let refList = [ 'groupname', 'color', 'students', 'historicStudents', 'timestamp', 'update' ]

            let personalAttributesStatesList = [
                {
                    storage: 'hiding',
                    state  : 'hidden',
                    list   : 'hideLists'
                }
            ]

            super( core, 'group', refList, personalAttributesStatesList )
            this.hasDetailView = true
            this.creationDependsOn = [ 'student' ]
            this.objectType = 'groups'
            GroupsObjectClass.instance = this

        }

        return GroupsObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'groupname',
                caption    : 'Name',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'classname',
                checkExists: {
                    elements    : 'group',
                    checkForeign: true
                }
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                styleUpdate: true,
                reformatter: null,
                optional   : false,
                validator  : 'color'
            },
            {
                ref           : 'students',
                caption       : 'Schüler:innen',
                type          : 'RowAdder',
                component     : 'StudentSelector',
                values        : [],
                reformatter   : null,
                optional      : false,
                asPlainList   : true,
                validator     : 'select',
                unique        : true,
                ignoreClassId : true,
                extendedFilter: true,
                allowForeign  : true,
                dragBlocked   : true
            },
            {
                ref         : 'historicStudents',
                caption     : 'historische Schüler:innen',
                type        : 'Hidden',
                component   : 'Hidden',
                values      : [],
                reformatter : null,
                optional    : true,
                validator   : null
            }
        ]
    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return [ 'hide', 'edit', 'share', 'delete' ]
    }

    listAll( attach, filter, skipArchived )
    {

        return new Promise( ( resolve, reject ) =>
        {

            super.listAll( attach, filter, skipArchived )
                 .then( result =>
                 {

                     for( let r in result )
                     {

                         result[ r ].displayName = 'Gruppe: ' + result[ r ].groupname

                     }

                     return resolve( result )

                 } )
                 .catch( error =>
                 {
                     return reject( error )
                 } )

        } )

    }

}