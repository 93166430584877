export default class Resolver
{

    constructor( database, functions )
    {

        this.database = database
        this.functions = functions

        return this

    }

    run( attach, target )
    {

        return new Promise( resolve =>
        {

            if( undefined === attach )
            {
                return resolve( target )
            }

            let promises = [],
                results  = []

            for( let t in target )
            {

                let row = target[ t ]

                if( 'all' === row[ attach.key ] )
                {

                    let neededObjectType = attach.resolve.split( '.' ).shift(),
                        neededKey        = attach.resolve.split( '.' ).pop()

                    this.database.readAllObjectsByType( neededObjectType )
                        .then( result =>
                        {

                            for( let i = 0; i < result.length; i++ )
                            {
                                let wanted = result[ i ][ neededKey ]
                                row[ attach.target ].push( wanted )
                            }

                            results.push( row )

                        } )

                }

            }

            this.functions.promiseRunner( promises )
                .then( () =>
                {

                    return resolve( results )

                } )

        } )

    }

}