<template>
    <div>
        <div class="select-wrapper" @click="openSelectBox">
            <select :id="'form-element-'+refName" @click="$core.f.skip" @change="select">
                <option value="">{{ $core.t( 'generic-please-select' ) }}</option>
                <option value="time"
                        :selected="selectedType === 'time'">{{ $core.t( 'time' ) }}
                </option>
                <option value="freetext"
                        :selected="selectedType === 'freetext'">{{ $core.t( 'freetext' ) }}
                </option>
            </select>
        </div>
        <TextBox @enterPressed="( 'time' === selectedType ? checkTime() : checkCaption() )"
                 @blur="( 'time' === selectedType ? checkTime() : checkCaption() )"
                 :id="( 'time' === selectedType ? 'time-' : 'caption-' )+refName"
                 :refName="( 'time' === selectedType ? 'time-' : 'caption-' )+refName"
                 :type="( 'time' === selectedType ? 'time' : 'text' )"
                 :steps="( 'time' === selectedType ? 300 : undefined )"
                 :value="value.value"
                 :placeholder="( 'time' !== selectedType ? $core.t( 'generic-enter-description' ) : '' )"/>
    </div>
</template>

<script>
import TextBox from '@/components/form/elements/TextBox'

export default {

    name      : 'PlannerSlotSetup',
    components: { TextBox },
    props     : {
        id              : { Type: String, required: true },
        value           : { Type: Object, required: false },
        refName         : { Type: String, required: false },
        vModel          : { Type: String, required: false },
        autocomplete    : { Type: String, required: false },
        eventKey        : { Type: String, required: false },
        noAddFormElement: { Type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            selectedType: false,
            times       : []
        }
    },

    emits: [ 'enterPressed', 'update' ],

    created()
    {

        if( undefined !== this.$core.getState( 'preselected-planner-type' ) )
        {
            this.selectedType = this.$core.getState( 'preselected-planner-type' )
        }

        let key = this.$props.id.split( /-/ )
        if( undefined !== key[ 1 ]
            && undefined !== this.$props.value[ key[ 1 ] ] )
        {
            this.selectedType = this.$props.value[ key[ 1 ] ]
        }
    },

    methods: {

        validate( elm )
        {
            return ( null !== elm
                     && undefined !== elm.value
                     && '' !== elm.value.trim() )
        },

        handleUpdateTime()
        {
            let elm = document.querySelector( '#time-' + this.$props.refName )
            if( this.validate( elm ) )
            {
                elm.classList.remove( 'error' )
                this.$emit( 'enterPressed', this.$props.refName )
                //this.update()
            }
            else
            {
                elm.classList.add( 'error' )
            }
        },

        checkTime( key )
        {

            if( 13 === key.keyCode
                || 13 === key.which
                || 'Enter' === key.key )
            {

                this.handleUpdateTime()
                this.$emit( 'enterPressed', this.$props.refName )

            }

        },

        checkCaption()
        {

            let elm = document.querySelector( '#form-element-caption-' + this.$props.refName )
            if( this.validate( elm ) )
            {
                elm.classList.remove( 'error' )
                this.$emit( 'enterPressed', this.$props.refName )
            }
            else
            {
                elm.classList.add( 'error' )
            }

        },

        select()
        {
            let value = document.querySelector( '#form-element-' + this.$props.refName ).value
            if( '' !== value )
            {
                this.selectedType = value
                this.$core.setState( 'preselected-planner-type', value )
            }
        },

        update()
        {
            let elm = document.querySelector( '#form-element-' + this.$props.id )
            if( null !== elm )
            {
                this.$emit( 'update', this.$props.refName, 'plannerSlotSetup', 'update', undefined, elm.value )
            }
        },

        openSelectBox()
        {
            let event = new MouseEvent( 'click' )
            let element = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== element )
            {
                element.dispatchEvent( event )
            }
        }

    }
}
</script>