<template>
    <template v-if="inline">
        <span class="rotated" v-html="preparedCaption"></span>
        <template v-if="score">
            <br/><span class="score-heading" @click="$emit('table-head-clicked')">{{
                $core.getReformatter().localizedFloat( score, 1 )
            }} Punkte</span>
        </template>
    </template>
    <template v-else>
        <th :class="( fixed ? 'fixed ' : '' )+color"
            :key="'tableHeadRotated-'+key"
            :id="'anchor-'+keyV"
            @click="$emit('table-head-clicked')">
            <span class="rotated" v-html="preparedCaption"></span>
            <template v-if="score">
                <br/><span class="score-heading">{{
                    $core.getReformatter().localizedFloat( score, 1 )
                }} Punkte</span>
            </template>
        </th>
    </template>
</template>

<script>
/*eslint-disable*/
import Opener from "@/components/elements/defaults/Opener";

export default {
    name      : "TableHeadRotated",
    components: { Opener },
    props     : {
        key              : { Type: String, required: true },
        keyV             : { Type: String, required: true },
        allKeys          : { Type: Number, required: false, default: 0 },
        caption          : { Type: String, required: true },
        score            : { Type: Number, required: false },
        inline           : { Type: Boolean, required: false, default: false },
        fixed            : { Type: Boolean, required: false, default: false },
        color            : { Type: String, required: false, default: '' },
        debug            : { Type: Object, required: false }
    },

    emits: [ 'table-head-clicked' ],

    data()
    {
        return {
            preparedCaption: '',
            ready          : false,
            width          : 0,
            maxCaptionWidth: 10
        }
    },
    mounted()
    {

        this.getMaxCaptionWidth()
        this.preparedCaption = this.prepareCaption( this.$props.caption )

    },

    methods: {

        getMaxCaptionWidth()
        {

            let style = getComputedStyle( document.body )
                .getPropertyValue( '--max-column-header-height' )

            if( null !== style )
            {
                this.maxCaptionWidth = parseInt( style )
            }
            else
            {
                this.maxCaptionWidth = 20
            }

        },

        prepareCaption( captionHtml )
        {

            let w = this.getCaptionWidth( captionHtml )

            if( this.maxCaptionWidth > w )
            {
                return captionHtml
            }
            else
            {

                let parts = captionHtml.split( / /g ),
                    rows  = [],
                    row   = [],
                    next  = false

                while( 0 < parts.length )
                {

                    let field      = parts.shift(),
                        widthRow   = this.getCaptionWidth( row.join( ' ' ).trim() ),
                        widthField = this.getCaptionWidth( field.trim() )

                    if( this.maxCaptionWidth >= widthRow
                        && this.maxCaptionWidth >= ( widthRow + widthField ) )
                    {
                        row.push( field )
                    }
                    else if( this.maxCaptionWidth >= widthRow
                        && this.maxCaptionWidth < ( widthRow + widthField ) )
                    {
                        if( 0 < row.length )
                        {
                            rows.push( row.join( ' ' ) )
                            row = []
                            row.push( field )
                        }
                        else
                        {
                            rows.push( field )
                        }
                    }
                    else if( this.maxCaptionWidth <= widthRow )
                    {
                        rows.push( row.join( ' ' ) )
                        row = []
                    }

                }

                if( 0 < row.length )
                {
                    rows.push( row.join( ' ' ) )
                }

                return rows.reverse().join( '<br/>' )

            }

        },

        getCaptionWidth( captionHtml )
        {

            let span = document.createElement( 'span' )
            span.innerHTML = captionHtml
            return ( '' + span.innerText ).length

        }

    }

}
</script>