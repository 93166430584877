<template>
    <div>
        <div class="listname">
            {{
                list.listname
            }}
        </div>
        <ListInfo :item="list"
                  :singleItems="false"
                  :doneInfo="false"/>
    </div>
</template>

<script>
import ListInfo from "@/components/elements/viewItems/listViews/parts/ListInfo";

export default {

    name      : 'ListSynchronizingPlaceholder',
    components: { ListInfo },
    props     : {

        list: { Type: Object, required: true }

    }
}
</script>