<template>
    <div class="online-status">
        <transition appear mode="out-in" name="slide-fade-top">
            <component @click="handleClick" :is="component" :counter="counter"/>
        </transition>
    </div>
</template>

<script>

import Initializing        from '@/components/layout/online-status/Initializing'
import Online              from '@/components/layout/online-status/Online'
import Offline             from '@/components/layout/online-status/Offline'
import Synchronizing       from '@/components/layout/online-status/Synchronizing'
import Updating            from '@/components/layout/online-status/Updating'
import Reconnecting        from '@/components/layout/online-status/Reconnecting'

export default {
    
    name      : 'OnlineStatus',

    components: {
        Reconnecting,
        Initializing,
        Online,
        Offline,
        Synchronizing,
        Updating
    },
    
    data()
    {
        return {
            component: 'Initializing',
            counter  : 0
        }
    },
    
    computed: {
        state()
        {
            return this.$store.getters.online
        }
    },
    
    watch: {
        state: {
            immediate: true,
            handler()
            {
                this.automaticSetOnlineStatus()
            }
        }
    },
    
    created()
    {
        
        this.$core.getEventManager()
            .appendNamed( 'on-websocket-connection-state-change', 'online-status',
                () =>
                {
                    this.automaticSetOnlineStatus()
                } )
        
        this.$core.getEventManager()
            .add( 'ui-set-online-status', ( values ) =>
            {
                this.setOnlineStatus( values )
            } )
        
    },
    
    beforeUnmount()
    {
        
        this.$core.getEventManager()
            .removeNamed( 'on-websocket-connection-state-change', 'online-status' )
        
        this.$core.getEventManager()
            .remove( 'ui-set-online-status' )
        
    },
    
    methods: {

        handleClick()
        {
            if( 'local' === this.$core.getConfig().branch
                || 'test' === this.$core.getConfig().branch )
            {
                //event.preventDefault()
                //event.stopPropagation()
                window.forceOffline = !window.forceOffline
            }
        },

        setOnlineStatus( values )
        {

            if( undefined !== values.counter )
            {
                this.counter = values.counter
            }

            this.component = values.status

        },
        
        automaticSetOnlineStatus()
        {
            
            switch( this.$core.getStore().getters.online )
            {
                case true:
                    this.component = 'Online'
                    break
                case false:
                    this.component = 'Offline'
                    break
                case null:
                    this.component = 'Reconnecting'
                    break
    
            }
            
        }
        
    }
    
}
</script>