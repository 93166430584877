<template>
    <span :class="'list-state-marker right '+stateClass">
        <template v-if="true === state.done">
            erledigt
        </template>
        <template v-else>
            <div v-if="0 < days">
                fällig in <strong>{{ days }}</strong> Tagen
            </div>
            <div v-else>
                überfällig seit <strong>{{ ( -1 * days ) }}</strong> Tagen
            </div>
        </template>
    </span>
</template>

<script>
export default {

    name : 'ListStateMarker',
    props: {
        state  : { Type: Object, required: true }
    },

    computed: {
        stateClass()
        {
            if( true === this.$props.state.done )
            {
                return 'done'
            }

            let days = this.days
            if( days > 3 )
            {
                return 'warn'
            }
            return 'error'

        },

        days()
        {
            let timestamp = this.$props.state.duedate
            if( isNaN( parseInt( this.$props.state.duedate ) )
                || '' + parseInt( this.$props.state.duedate ) !== '' + this.$props.state.duedate )
            {
                if( -1 < timestamp.indexOf( '.' ) )
                {

                    let temp = timestamp.split( /\./g )
                    let mysql = temp.reverse().join( '-' )
                    timestamp = this.$core.getFriendlyTimestamp().timestampFromMysql( mysql + ' 12:00:00' )
                }
            }

            return this.$core.getFriendlyTimestamp().dueDays( timestamp )

        }
    }

}
</script>