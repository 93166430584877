export default class afterAllStudent
{

    constructor( core )
    {
        this.baseClassHelper = core.getBaseClassHelper()
    }

    _combine( student )
    {
        student.address = student.address_1 + '<br/>' + student.address_2
        student.zipcode_city = student.zipcode + '&nbsp;' + student.city
        student.parents = student.parent_1 + '<br/>' + student.parent_2
        student.phone1 = ( undefined !== student.label_phone_1 ? student.label_phone_1 + ': ' : '' ) + student.phone_1
        student.phone2 = ( undefined !== student.label_phone_2 ? student.label_phone_2 + ': ' : '' ) + student.phone_2
    }

    _fetchDependencies( skipArchived )
    {

        return new Promise( resolve =>
        {

            this.classClass = this.classClass || this.baseClassHelper.get( 'class' )
            this.yeargroupClass = this.yeargroupClass || this.baseClassHelper.get( 'yeargroup' )

            this.classClass.listAll( undefined, undefined, skipArchived )
                .then( classes =>
                {

                    this.yeargroupClass.listAll( undefined, undefined, skipArchived )
                        .then( yeargroups =>
                        {

                            let studentToClass     = {},
                                studentToYearGroup = {}

                            for( let c in classes )
                            {

                                if( !classes[ c ].isShadowCopy )
                                {

                                    for( let s in classes[ c ].students )
                                    {
                                        studentToClass[ classes[ c ].students[ s ] ] = {
                                            classId  : classes[ c ].localId,
                                            classname: classes[ c ].classname
                                        }
                                    }

                                }

                            }

                            for( let y in yeargroups )
                            {

                                if( !yeargroups[ y ].isShadowCopy )
                                {
                                    for( let s in yeargroups[ y ].students )
                                    {
                                        studentToYearGroup[ yeargroups[ y ].students[ s ] ] = {
                                            yeargroupId  : yeargroups[ y ].localId,
                                            yeargroupname: yeargroups[ y ].yeargroupname
                                        }
                                    }
                                }
                            }

                            return resolve( {
                                classes           : classes,
                                yeargroups        : yeargroups,
                                studentToClass    : studentToClass,
                                studentToYearGroup: studentToYearGroup
                            } )
                        } )

                } )

        } )

    }

    afterFetch( students, skipArchived )
    {
        return new Promise( resolve =>
        {

            this._fetchDependencies( skipArchived )
                .then( dependencies =>
                {

                    for( let s in students )
                    {

                        this._combine( students[ s ] )

                        students[ s ].displayName = students[ s ].lastname + ', ' + students[ s ].firstname

                        if( undefined !== dependencies.studentToClass[ students[ s ].localId ] )
                        {

                            students[ s ].classname = dependencies.studentToClass[ students[ s ].localId ].classname
                            students[ s ].displayName += ' - Klasse ' + students[ s ].classname
                            students[ s ].classId = dependencies.studentToClass[ students[ s ].localId ].classId

                        }
                        else
                        {
                            students[ s ].classname = 'Z_FALSE'
                        }

                        if( undefined !== dependencies.studentToYearGroup[ students[ s ].localId ] )
                        {
                            students[ s ].yeargroupname = dependencies.studentToYearGroup[ students[ s ].localId ].yeargroupname
                            students[ s ].displayName += ' - Jahrgang ' + students[ s ].yeargroupname
                            students[ s ].yeargroupId = dependencies.studentToYearGroup[ students[ s ].localId ].yeargroupId
                        }

                        if( true === students[ s ].archived )
                        {
                            students[ s ].displayName = 'archiviert: ' + students[ s ].displayName
                        }

                    }

                    return resolve( students )


                } )
                .catch( () =>
                {

                    return resolve( students )

                } )

        } )
    }

    afterUpdate( students, cache, skipArchived )
    {

        return new Promise( resolve =>
        {

            this.afterFetch( students, skipArchived )
                .then( result => {

                    return resolve( result )

                })

        } )

    }

}