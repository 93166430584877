import afterFetchList           from "@/objects/stateMergers/list/afterFetch";
import afterUpdateList          from "@/objects/stateMergers/list/afterUpdate";
import afterFetchColleague      from "@/objects/stateMergers/colleague/afterFetch";
import afterAllStudent          from "@/objects/stateMergers/student/afterAll";
import afterFetchMedia          from "@/objects/stateMergers/media/afterFetch";
import afterAllOrganizerContent from "@/objects/stateMergers/organizercontent/afterAll";

export default class StateMergers
{

    constructor( core )
    {

        this.mergers = {
            student         : {
                afterFetch : new afterAllStudent( core ),
                afterUpdate: new afterAllStudent( core )
            },
            list            : {
                afterFetch : new afterFetchList( core ),
                afterUpdate: new afterUpdateList( core )
            },
            colleague       : {
                afterFetch: new afterFetchColleague( core )
            },
            media           : {
                afterFetch: new afterFetchMedia( core )
            },
            organizercontent: {
                afterFetch : new afterAllOrganizerContent( core ),
                afterUpdate: new afterAllOrganizerContent( core )
            }
        }

    }

    get( objectType, triggerWhen )
    {
        if( undefined !== this.mergers[ objectType ]
            && undefined !== this.mergers[ objectType ][ triggerWhen ] )
        {
            return this.mergers[ objectType ][ triggerWhen ]
        }
        return false
    }

}