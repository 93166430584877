<template>
    <div>
        <template v-if="'init' === state">
            <div class="audio-recorder"
                 @click="start">
            </div>
        </template>
        <template v-if="'recording' === state">
            <div class="audio-recorder active"
                 @click="stop">
                <span class="record-timer">{{ timeleft }}</span>
            </div>
        </template>
        <template v-if="'stopped' === state">
            <div class="audio-recorder play"
                 @click="play">
            </div>
        </template>
        <template v-if="'playing' === state">
            <div class="audio-recorder stop"
                 @click="stopPlayer">
                <span class="record-timer">{{ timeleft }}</span>
            </div>
        </template>
    </div>
</template>

<script>
export default {

    name: "AudioRecorder",

    emits: [ 'change' ],

    props: {
        emitChange: { type: Boolean, required: false }
    },

    data()
    {
        return {
            state        : 'init',
            timeleft     : 60,
            audiorecorder: null,
            audioplayer  : null,
            data         : null
        }
    },

    methods: {

        start()
        {
            this.state = 'recording'
            this.$core.getCoreTimer()
                .addInterval( 'record-timer', 1000, () =>
                {
                    if( 0 === this.timeleft )
                    {
                        this.stop()
                    }
                    else
                    {
                        this.timeleft -= 1
                    }
                } )

            this.audiorecorder = this.$core.getMediaHelper().getAudioRecorder()
            this.audiorecorder.start()

        },

        stop()
        {
            this.state = 'stopped'
            this.$core.getCoreTimer()
                .removeInterval( 'record-timer' )

            this.timeleft = 60

            this.audiorecorder
                .stop()
                .then( data =>
                {

                    this.data = data
                    if( this.$props.emitChange === true )
                    {
                        this.$emit( 'change', data )
                    }

                } )
        },

        play()
        {

            this.state = 'playing'

            this.$core.getEventManager().dispatch( 'on-priority-audio-pause' )
            this.audioplayer = new Audio( this.data )
            this.audioplayer.preload = 'metadata'
            this.audioplayer.onloadedmetadata = () =>
            {

                this.timeleft = parseInt( this.audioplayer.duration )

                this.$core.getCoreTimer()
                    .addInterval( 'play-timer', 1000, () =>
                    {
                        if( 0 === this.timeleft )
                        {
                            this.stopPlayer()
                        }
                        else
                        {
                            this.timeleft -= 1
                        }
                    } )

                this.audioplayer.play()

            }

        },

        stopPlayer()
        {

            this.state = 'stopped'
            this.$core.getCoreTimer()
                .removeInterval( 'play-timer' )
            this.audioplayer.pause()
            this.audioplayer.currentTime = 0
            this.$core.getEventManager().dispatch( 'on-priority-audio-resume' )

        }

    }
}
</script>