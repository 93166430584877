<template>
    <div class="select-wrapper" v-if="ready">
        <select :id="'form-element-'+refName" @change="select">
            <option value="">{{ $core.t( 'generic-please-select' ) }}</option>
            <template v-for="column in columns" :key="'key-'+refName+'-'+column">
                <template v-if="-1 === exclude.indexOf( column ) || column === value">
                    <option :value="column" :selected="column === value">
                        {{ column }}
                    </option>
                </template>
            </template>
        </select>
    </div>
</template>

<script>
export default {

    name : 'PlainSelectBox',
    emits: [ 'extendedFilterSelection', 'enterPressed', 'backspacePressed', 'update', 'selected', 'adderValid',
             'adderInvalid' ],

    props: {

        value       : { Type: String, required: false },
        set         : { Type: Object, required: false },
        allValues   : { Type: Object, required: false },
        valueSet    : { Type: Object, required: false, default: [] },
        refName     : { Type: String, required: true },
        exclude     : { Type: Array, required: true },
        validator   : { Type: String, required: false },
        reformatter : { Type: String, required: false },
        display     : { Type: String, required: false },
        undefBlocked: { Type: Boolean, required: false },
        configScope : { Type: Boolean, required: false },
        eventKey    : { Type: String, required: false },
        scope       : { Type: String, required: false },
        unique      : { Type: Boolean, required: false },

    },

    watch: {

        valueSet: {
            deep: true,
            handler()
            {
                if( this.$props.unique === true )
                {
                    this.filterUnique()
                }
            }
        }

    },

    data()
    {
        return {
            ready         : false,
            formElm       : false,
            columns       : [],
            selectedColumn: false
        }
    },

    mounted()
    {

        for( let v in this.$props.valueSet )
        {
            if( -1 === this.$props.exclude.indexOf( this.$props.valueSet[ v ] )
                || this.$props.value === this.$props.valueSet[ v ] )
            {
                this.columns.push( this.$props.valueSet[ v ] )
            }
        }

        this.ready = true

    },

    methods: {

        select( event )
        {

            if( undefined !== event.target.value
                && '' !== event.target.value.trim() )
            {
                this.selectedColumn = event.target.value
            }
            else
            {
                this.selectedColumn = false
            }

            this.$emit( 'update', this.$props.refName, 'plainSelectBox', 'update', undefined, this.selectedColumn )

        },

        /*eslint-disable*/
        filterUnique()
        {

            this.$nextTick()
                .then( () =>
                {


                    let excludes = []

                    for( let a in this.$props.allValues )
                    {
                        excludes.push( this.$props.allValues[ a ].value )
                    }

                    let newList = []

                    for( let s in this.$props.valueSet )
                    {
                        let column = this.$props.valueSet[ s ]
                        if( ( -1 === excludes.indexOf( column )
                              || column === this.$props.value )
                            && column !== this.$props.exclude )
                        {
                            newList.push( column )
                        }
                    }

                    this.columns = newList
                    this.ready = true

                } )

        }

    }

}
</script>