<template>
    <TutorialMessage :caption="$core.t( 'tutorial-caption-students' )"
                     :text="$core.t( 'tutorial-text-students-'+step )"
                     :step="step"
                     :last="last"
                     :backBlocked="backBlocked"
                     @back="handleBack()"
                     @next="handleNext()"/>
    <TutorialElement v-if="fakeElement" :fakeElement="fakeElement"/>
</template>

<script>
import TutorialMessage from '@/components/elements/tutorial/TutorialMessage'
import TutorialBase    from '@/components/elements/tutorial/TutorialBase'
import TutorialElement from '@/components/elements/tutorial/TutorialElement'

export default {

    name      : 'TutorialStudents',
    components: { TutorialElement, TutorialMessage },
    extends   : TutorialBase,

    data()
    {
        return {
            last: 14,
            fake: {
                type     : 'student',
                firstname: 'Fritz',
                lastname : 'Walter',
                gender   : 'male',
                idOwner  : this.$store.getters.idUser,
                classname: '2a',
                archived : false
            }
        }
    },

    methods: {

        prepareHighlights()
        {
            this.highlights = []
            switch( this.step )
            {
                case 1:
                    break
                case 2:
                    this.highlights = [
                        {
                            prepare    : {
                                action : 'click',
                                root   : '.tab-space',
                                element: '#own'
                            },
                            root       : '.head-functions',
                            elements   : [ '.button.round.add.large' ],
                            waitForNext: '.default-form-container'
                        }
                    ]
                    break
                case 3:
                    this.highlights = [
                        {
                            root        : '.head-functions',
                            elements    : [],
                            unblurred   : true,
                            backdisabled: true,
                            showFill    : {
                                element: this.fake,
                                fill   : [ {
                                    root  : '.default-form-wrapper',
                                    type  : 'animated',
                                    fields: [ 't:lastname', 't:firstname', 't:gender' ]
                                } ]
                            }
                        }
                    ]
                    break
                case 4:
                    this.highlights = [
                        {
                            prepare  : {
                                action : 'click',
                                root   : '.default-form-wrapper',
                                element: '.button.round.close.absolute.top.right.inverted'
                            },
                            stepsback: 2,
                            root     : '.head-functions',
                            elements : [ '.button.search' ]
                        }
                    ]
                    break
                case 5:
                    this.highlights = [
                        {
                            root    : '.head-functions',
                            elements: [ '.button.select' ]
                        }
                    ]
                    break
                case 6:
                    this.highlights = [
                        {
                            root    : '.head-functions',
                            elements: [ '#classes-filter' ],
                            raise   : true
                        }
                    ]
                    break
                case 7:
                    this.highlights = [
                        {
                            root    : '.head-functions',
                            elements: [ '#color-filters' ],
                            raise   : true
                        }
                    ]
                    break
                case 8:
                    this.highlights = [
                        {
                            root    : '.default-list-page',
                            elements: [ '.tab-container' ],
                            raise   : true
                        }
                    ]
                    break
                case 9:
                    this.highlights = [
                        {
                            root       : '.default-list-page',
                            elements   : [],
                            fakeElement: this.fake,
                            waitForNext: '.touchable-active'
                        }
                    ]
                    break
                case 10:
                    this.highlights = [
                        {
                            backdisabled: true,
                            fakeElement : this.fake
                        }
                    ]
                    break
                case 11:
                    this.highlights = [
                        {
                            root        : '.touchable-right',
                            elements    : [
                                '.button.edit'
                            ],
                            fakeElement : this.fake
                        }
                    ]
                    break
                case 12:
                    this.highlights = [
                        {
                            root       : '.touchable-right',
                            elements   : [
                                '.button.delete'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 13:
                    this.highlights = [
                        {
                            root       : '.touchable-right',
                            elements   : [
                                '.button.archive'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break

            }

            this.highlight()
        }

    }

}
</script>