<template>
    <Default2FAOverlay v-if="secondFactorOverlay"
                       :setup="setup"
                       @close="hideOverlay()"/>
    <DefaultFormOverlay v-if="formOverlay"
                        :objectType="objectType"
                        :viewItem="viewItem"
                        :method="formMethod"
                        :editItem="editItem"
                        :readyKey="readyKey"
                        :organizerSlot="organizerSlot"
                        @close="hideOverlay()"
                        @submit-form="handleFormSubmit"
                        @append-class-reference="handleAppendClassReference"/>
    <FreeFormOverlay v-if="freeFormOverlay"
                     :fields="editItem"
                     :title="title"
                     :titleKey="titleKey"
                     :readyKey="readyKey"
                     :trackChanges="trackChanges"
                     @close="hideOverlay()"
                     @submit-form="handleFormSubmit"/>
    <ExtendedStudentFilter v-if="extendedStudentFilter"
                           :onlyUnassigned="onlyUnassigned"
                           @close="extendedStudentFilter = false"/>
    <DefaultElementZoom v-if="elementZoom"
                        :changeKey="changeKey"
                        :item="zoomItem"
                        :zoomId="readyKey"
                        @requestUpdate="handleRequestUpdate()"
                        @close="hideOverlay()"/>
    <DefaultShareOverlay v-if="shareOverlay"
                         :objectList="objectList"
                         @close="hideOverlay()"/>
    <DefaultShareWithStudentsOverlay v-if="shareWithStudentsOverlay"
                                     :objectList="objectList"
                                     @close="hideOverlay()"/>
    <DefaultCameraOverlay v-if="cameraOverlay"
                          :readyKey="readyKey"
                          :setup="setup"
                          @close="hideOverlay()"/>
    <DefaultImageOverlay v-if="imageOverlay"
                         :readyKey="readyKey"
                         @close="hideOverlay()"/>
    <DefaultPinOverlay v-if="pinOverlay"
                       @close="hideOverlay()"/>
    <DefaultQROverlay v-if="qrOverlay"
                      :setup="setup"
                      @submit="handleOverlaySubmit"
                      @close="hideOverlay()"/>
    <DefaultImageZoom v-if="imageZoom"
                      :setup="setup"
                      :readyKey="readyKey"
                      @close="hideOverlay()"/>
    <DefaultLabelManager v-if="labelManager"
                         :setup="setup"
                         :readyKey="readyKey"
                         @closeNoReset="hideOverlayNoReset( 'labelManager' )"/>
    <StatisticsOverlay v-if="statsOverlay"
                       :setup="setup"
                       :preset="preset"
                       @preset="handlePreset"
                       @forceReset="handleStatsReset()"
                       @close="hideOverlay()"/>
    <DefaultMessageOverlay v-if="messageOverlay"
                           :message="messageItem"
                           @close="hideOverlay()"/>
    <TemplateGalleryOverlay v-if="templateGalleryOverlay"
                            :setup="setup"
                            @close="hideGalleryOverlay"/>
    <MediaGalleryOverlay v-if="mediaGalleryOverlay"
                         :setup="setup"
                         @close="hideGalleryOverlay"/>
    <OrganizerContentsOverlay v-if="organizerContentsOverlay"
                              :setup="setup"
                              @close="hideOverlay()"/>
    <Calendar v-if="calendarOverlay"
              :objects="editItem"
              :noClone="noClone"
              @calendarAction="handleEmit"/>
</template>

<script>
import DefaultFormOverlay              from '@/components/defaults/overlays/DefaultFormOverlay'
import Calendar                        from '@/components/form/elements/Calendar'
import DefaultShareOverlay             from '@/components/defaults/overlays/DefaultShareOverlay'
import DefaultPinOverlay               from '@/components/defaults/overlays/DefaultPinOverlay'
import DefaultMessageOverlay           from '@/components/defaults/overlays/DefaultMessageOverlay'
import DefaultCameraOverlay            from '@/components/defaults/overlays/DefaultCameraOverlay'
import ExtendedStudentFilter           from '@/components/defaults/overlays/ExtendedStudentFilter'
import DefaultElementZoom              from '@/components/defaults/overlays/DefaultElementZoom'
import DefaultImageOverlay             from '@/components/defaults/overlays/DefaultImageOverlay'
import DefaultQROverlay                from '@/components/defaults/overlays/DefaultQROverlay'
import StatisticsOverlay               from '@/components/defaults/overlays/StatisticsOverlay'
import Default2FAOverlay               from '@/components/defaults/overlays/Default2FAOverlay'
import MixinEvents                     from '@/mixins/MixinEvents'
import FreeFormOverlay                 from '@/components/defaults/overlays/FreeFormOverlay'
import DefaultShareWithStudentsOverlay from "@/components/defaults/overlays/DefaultShareWithStudentsOverlay";
import TemplateGalleryOverlay          from "@/components/defaults/overlays/TemplateGalleryOverlay";
import DefaultImageZoom                from "@/components/defaults/overlays/DefaultImageZoom";
import DefaultLabelManager             from "@/components/defaults/overlays/DefaultLabelManager";
import MediaGalleryOverlay             from "@/components/defaults/overlays/MediaGalleryOverlay";
import OrganizerContentsOverlay        from "@/components/defaults/overlays/OrganizerContentsOverlay";

export default {

    name: 'Overlays',

    components: {
        OrganizerContentsOverlay,
        MediaGalleryOverlay,
        DefaultLabelManager,
        DefaultImageZoom,
        TemplateGalleryOverlay,
        DefaultShareWithStudentsOverlay,
        FreeFormOverlay,
        Default2FAOverlay,
        StatisticsOverlay,
        DefaultQROverlay,
        DefaultImageOverlay,
        DefaultElementZoom,
        ExtendedStudentFilter,
        DefaultCameraOverlay,
        DefaultMessageOverlay,
        DefaultPinOverlay,
        DefaultShareOverlay,
        Calendar,
        DefaultFormOverlay
    },

    mixins: [ MixinEvents ],

    data()
    {
        return {
            baseClass               : false,
            viewItem                : false,
            objectType              : false,
            formOverlay             : false,
            freeFormOverlay         : false,
            shareOverlay            : false,
            shareWithStudentsOverlay: false,
            pinOverlay              : false,
            qrOverlay               : false,
            messageOverlay          : false,
            extendedStudentFilter   : false,
            messageItem             : false,
            objectList              : false,
            calendarOverlay         : false,
            cameraOverlay           : false,
            formMethod              : false,
            editItem                : false,
            elementZoom             : false,
            zoomItem                : false,
            readyKey                : false,
            lastReadyKey            : false,
            changeKey               : false,
            lastJobId               : false,
            appending               : false,
            imageOverlay            : false,
            imageZoom               : false,
            labelManager            : false,
            statsOverlay            : false,
            statsOpen               : false,
            setup                   : false,
            submitting              : false,
            secondFactorOverlay     : false,
            templateGalleryOverlay  : false,
            mediaGalleryOverlay     : false,
            noClone                 : false,
            trackChanges            : false,
            organizerContentsOverlay: false,
            preset                  : undefined,
            organizerSlot           : undefined,
            onlyUnassigned          : undefined,
            noCloseCall             : false
        }
    },

    created()
    {

        this.addEvent( 'ui-show-overlay', ( setup ) =>
        {
            this.showOverlay( setup )
        } )
        this.addEvent( 'ui-element-zoom', ( setup ) =>
        {
            this.showOverlay( setup )
        } )
        this.addEvent( 'ui-hide-overlay', () =>
        {
            this.hideOverlay()
        } )
        this.addEvent( 'ui-hide-calendar', () =>
        {
            this.hideCalendar()
        } )
        this.addEvent( 'ui-hide-extendedstudentfilter', () =>
        {
            this.hideOverlay()
        } )
        this.addEvent( 'ui-update-zoom', ( setup ) =>
        {
            this.handleUpdateZoom( setup )
        } )
        this.addEvent( 'ui-image-overlay', ( setup ) =>
        {
            this.handleImageOverlay( setup )
        } )
        this.addEvent( 'ui-reset-form-overlay', () =>
        {
            this.reset()
        } )
        this.addEvent( 'on-form-reset-type', ( type ) =>
        {
            this.resetForType( type )
        } )
    },

    watch: {
        $route: {
            immediate: true,
            handler( to, from )
            {
                if( undefined !== to && undefined !== from
                    && to.name !== from.name )
                {
                    this.hideOverlay()
                }
            }
        }
    },

    methods: {

        reset()
        {
            this.baseClass = false
            this.viewItem = false
            this.objectType = false
            this.formOverlay = false
            this.freeFormOverlay = false
            this.calendarOverlay = false
            this.extendedStudentFilter = false
            this.formMethod = false
            this.editItem = false
            this.readyKey = false
            this.changeKey = false
            this.replaceItem = false
            this.replaceKey = false
            this.shareOverlay = false
            this.shareWithStudentsOverlay = false
            this.objectList = false
            this.pinOverlay = false
            this.qrOverlay = false
            this.statsOverlay = false
            this.messageOverlay = false
            this.messageItem = false
            this.cameraOverlay = false
            this.elementZoom = false
            this.imageOverlay = false
            this.zoomItem = false
            this.setup = false
            this.noClone = false
            this.statsOpen = false
            this.imageZoom = false
            this.labelManager = false
            this.submitting = false
            this.secondFactorOverlay = false
            this.templateGalleryOverlay = false
            this.mediaGalleryOverlay = false
            this.trackChanges = false
            this.preset = undefined
            this.organizerSlot = undefined
            this.organizerContentsOverlay = undefined
            this.onlyUnassigned = undefined
            this.noCloseCall = false
        },

        resetForType( type )
        {
            switch( type )
            {
                case 'camera':
                    this.cameraOverlay = false
                    break
            }
            if( this.noCloseCall )
            {
                this.$core.getUi()
                    .delay( () =>
                    {
                        this.noCloseCall = false
                    }, 500 )
            }
        },

        showOverlay( setup )
        {

            this.replaceKey = this.readyKey
            this.readyKey = setup.readyKey
            this.lastReadyKey = setup.readyKey

            this.$core.getUi().isOverlay = true

            switch( setup.type )
            {
                case 'templateGallery':
                    this.templateGalleryOverlay = true
                    this.setup = setup
                    break
                case 'mediaGallery':
                    this.mediaGalleryOverlay = true
                    this.setup = setup
                    break
                case '2fa':
                    this.secondFactorOverlay = true
                    this.setup = setup
                    break
                case 'elementZoom':
                    this.elementZoom = true
                    this.changeKey = setup.changeKey
                    this.zoomItem = setup.item
                    break
                case 'form':
                    this.baseClass = this.$core.getBaseClassHelper().get( setup.viewItem.toLowerCase() )
                                     || this.$core.baseClass( setup.viewItem.toLowerCase() )
                    this.viewItem = setup.viewItem
                    this.objectType = setup.objectType
                    this.formOverlay = true
                    this.formMethod = setup.formMethod
                    this.editItem = this.$core.f().isset( setup.editItem ) ? setup.editItem : false
                    this.organizerSlot = setup.organizerSlot || undefined
                    break
                case 'freeform':
                    this.freeFormOverlay = true
                    this.title = setup.title
                    this.titleKey = setup.titleKey
                    this.editItem = setup.fields
                    this.readyKey = setup.readyKey
                    this.trackChanges = setup.trackChanges
                    break
                case 'extendedStudentFilter':
                    this.onlyUnassigned = setup.onlyUnassigned
                    this.extendedStudentFilter = true
                    break
                case 'organizerContentsOverlay':
                    this.setup = setup
                    this.organizerContentsOverlay = true
                    break
                case 'camera':
                    this.cameraOverlay = true
                    this.noCloseCall = setup.noCloseCall || false
                    this.setup = setup
                    break
                case 'share':
                    this.objectList = setup.objectList
                    this.shareOverlay = true
                    break
                case 'shareWithStudents':
                    this.objectList = setup.objectList
                    this.shareWithStudentsOverlay = true
                    break
                case 'pin':
                    this.pinOverlay = true
                    break
                case 'qr':
                    this.qrOverlay = true
                    this.setup = setup
                    break
                case 'labelManager':
                case 'imageZoom':
                    this.setup = setup
                    this[ setup.type ] = true
                    break
                case 'statistics':
                    this.statsOpen = true
                    this.statsOverlay = true
                    this.$core.setState( 'statsClosing', null )
                    this.setup = setup
                    break
                case 'message':
                    if( this.messageOverlay === true )
                    {
                        this.$core.getEventManager()
                            .dispatch( 'hide-message-overlay' )
                        setTimeout( () =>
                        {
                            this.hideOverlay()
                            setTimeout( () =>
                            {
                                this.showOverlay( setup )
                            }, 300 )
                        }, 300 )
                    }
                    else
                    {
                        this.messageItem = setup
                        this.messageOverlay = true
                    }
                    break
                case 'calendar':
                    if( this.formOverlay )
                    {
                        this.replaceItem = this.editItem
                    }
                    this.noClone = setup.noClone
                    this.editItem = setup.editItem
                    this.calendarOverlay = true
                    break
            }

        },

        /*eslint-disable*/
        handleFormSubmit( values, historic, baseClass, forceCreate, willAppend )
        {

            if( true === this.freeFormOverlay )
            {
                this.$core.getEventManager().dispatchAndRemove( 'on-freeform-submit-' + this.readyKey, values )
                this.afterSubmit( true )
                return
            }

            baseClass = undefined !== baseClass ? baseClass : this.baseClass
            this.submitting = true
            this.baseClass = baseClass
            let mode = false !== this.editItem ? 'update' : 'create'
            if( forceCreate === true )
            {
                mode = 'create'
            }

            let jobId = false
            switch( mode )
            {
                case 'create':
                    jobId = baseClass.create( values )
                    this.lastJobId = jobId
                    break
                case 'update':
                    if( 'list' === this.editItem.type
                        && undefined !== historic )
                    {
                        jobId = baseClass.updateStructure( values, historic, this.editItem )
                        break
                    }
                    else
                    {
                        jobId = baseClass.update(
                            values,
                            this.editItem.localId,
                            this.editItem.remoteId,
                            this.editItem.timestamp,
                            this.editItem.localKey
                        )
                        break
                    }
                    break
            }

            if( false !== jobId )
            {

                this.$core.getUi().showBlocker(
                    this.$core.getTranslation().translate( 'generic-please-wait' ),
                    this.$core.getTranslation().translate( 'object-type-' + this.objectType + '-single' ) + ' '
                    + this.$core.getTranslation().translate( 'is-being-' + mode )
                )

                this.appendEvent( 'on-queue-done-' + jobId, () =>
                {

                    this.finalizeFormSubmit( jobId, mode, willAppend, this.readyKey )

                } )

            }

        },

        finalizeFormSubmit( jobId, mode, willAppend, readyKey )
        {

            if( false === willAppend )
            {

                this.$core.getEventManager().dispatchAndRemove( 'submit-form-' + this.lastReadyKey )

                if( false !== readyKey )
                {
                    this.$core.getEventManager().dispatchAndRemove( 'submit-form-' + readyKey )
                }

                if( 'create' === mode || 'forward' === mode || 'answer' === mode )
                {
                    if( false !== readyKey )
                    {
                        this.$core.getEventManager().dispatchAndRemove( 'created-object-' + readyKey, jobId )
                        this.$core.getEventManager().dispatchAndRemove( 'created-object-type-' + readyKey, this.baseClass.storableObjectType )
                    }
                    this.$core.getEventManager().dispatchAndRemove( 'created-object-' + this.lastReadyKey, jobId )
                    this.$core.getEventManager().dispatchAndRemove( 'created-object-type-' + this.lastReadyKey, this.baseClass.storableObjectType )
                }

                this.afterSubmit()

            }

        },

        afterSubmit( skipHideBlocker )
        {

            this.$core.getUi().isOverlay = false

            setTimeout( () =>
            {

                this.lastJobId = false
                this.lastReadyKey = false
                this.hideOverlay()

                if( undefined === skipHideBlocker )
                {
                    this.$core.getUi().hideBlocker()
                }

            }, 300 )

        },

        handleAppendClassReference( classId )
        {

            this.appending = true
            let classClass = this.$core.baseClass( 'class' )
            classClass.load( classId, true )
                      .then( classObject =>
                      {

                          let leClass = classObject.object
                          if( -1 === leClass.students.indexOf( this.lastJobId ) )
                          {

                              leClass.students.push( this.lastJobId )
                              classClass.update(
                                  leClass,
                                  classObject.localId,
                                  classObject.remoteId,
                                  leClass.timestamp,
                                  leClass.localKey,
                                  undefined,
                                  false,
                                  false
                              )
                              setTimeout( () =>
                              {
                                  this.appending = false
                                  this.finalizeFormSubmit( this.lastJobId, 'create', false )
                                  this.lastJobId = false
                              }, 500 )

                          }
                          else
                          {
                              this.appending = false
                              this.finalizeFormSubmit( this.lastJobId, 'create', false )
                              this.lastJobId = false
                          }

                      } )
                      .catch( () =>
                      {
                          this.appending = false
                          this.finalizeFormSubmit( this.lastJobId, 'create', false )
                          this.lastJobId = false
                      } )

        },

        handleOverlaySubmit( values )
        {
            this.$core.getEventManager().dispatch( 'submit-overlay-' + this.readyKey, values )
            this.hideOverlay()
        },

        hideOverlay()
        {

            this.statsOpen = false

            this.$core.getUi().isOverlay = false
            if( !this.submitting
                && !this.noCloseCall )
            {
                this.$core.getEventManager().dispatchAndRemove( 'form-closed-' + this.readyKey )
                this.$core.getEventManager().remove( 'submit-form-' + this.readyKey )
                this.$core.getEventManager().remove( 'submit-overlay-' + this.readyKey )
            }

            if( !this.noCloseCall )
            {
                this.reset()
            }
            else
            {
                this.noCloseCall = false
            }

        },

        hideOverlayNoReset( which )
        {
            this[ which ] = false
        },

        hideGalleryOverlay()
        {
            this.templateGalleryOverlay = false
            this.mediaGalleryOverlay = false
            if( !this.formOverlay )
            {
                this.reset()
            }
        },

        hideCalendar()
        {
            this.$core.getUi().isOverlay = false
            if( !this.formOverlay )
            {
                this.reset()
            }
            else
            {
                this.calendarOverlay = false
                this.editItem = this.replaceItem
                this.readyKey = this.replaceKey
            }

        },

        handleEmit( action, params )
        {

            let message = {
                action: action,
                params: params
            }

            this.$core.getEventManager().dispatchAndRemove( this.readyKey, message )

        },

        handleUpdateZoom( setup )
        {

            if( setup.zoomId === this.readyKey )
            {
                this.zoomItem = setup.item
                this.changeKey = setup.changeKey
                this.$core.getEventManager().dispatchAndRemove( 'on-changekey-' + setup.changeKey + '-resolve' )
            }

        },

        handleImageOverlay( setup )
        {
            this.imageOverlay = true
            this.changeKey = setup.changeKey
        },

        handlePreset( preset )
        {
            this.preset = preset
        },

        handleStatsReset()
        {
            this.statsOverlay = false
            this.$nextTick()
                .then( () =>
                {
                    if( this.statsOpen
                        && true !== this.$core.getState( 'statsClosing' ) )
                    {
                        this.statsOverlay = true
                    }
                } )
        },

        handleRequestUpdate()
        {

            let referenceKey = this.zoomItem.referenceKey,
                localId      = this.zoomItem.localId,
                scope        = this.zoomItem.archived ? 'archive' : 'cache',
                baseClass    = this.$core.getBaseClassHelper()
                                   .get( this.zoomItem.type )

            switch( this.zoomItem.type )
            {
                case 'list':
                    this.zoomItem = baseClass.getListById( referenceKey, localId, scope )
                    break
            }

        }

    }

}
</script>