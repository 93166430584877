<template> <!-- -->
    <div v-if="prepared && itemIsShared"
         :key="'shareIndicator-'+item.localId+'-'+item.elementKey+'-'+registryCacheKey"
         :class="'quickmark '
                 + ( itemIsOwn ? 'shared' : 'foreign' )
                 + ( open ? ' open' : '' )
                 + ( '' !== sharedWithCount ? ' has-counter' : '')"
         @click="open = !open"
         v-html="open ? ( itemIsOwn ? 'von mir geteilt'+shareListOwn : 'empfangen'+shareListForeign ) : sharedWithCount"></div>
</template>
<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {
    name: 'ShareIndicator',
    mixins: [ MixinCachePreheater ],

    props: {
        scope: { Type: String, required: true },
        item : { Type: Object, required: true }
    },

    data()
    {
        return {
            neededCaches    : [ 'share' ],
            open            : false,
            prepared        : false,
            sharedWithCount : '',
            itemIsOwn       : false,
            itemIsShared    : false,
            registryCacheKey: false
        }
    },

    watch: {
        'item.elementKey': {
            immediate: true,
            handler( newKey, oldKey )
            {
                if( undefined !== oldKey
                    && newKey !== oldKey )
                {
                    this.prepared = false
                    this.prepare()
                }
            }
        }
    },

    created()
    {
        this.registryCacheKey = this.$core.getUuid().generate()
    },

    mounted()
    {

        this.eventIndex = this.$core.getEventManager()
                              .addIndexed( 'on-share-refresh-component', () =>
                              {
                                  this.registryCacheKey = this.$core.getUuid().generate()
                                  this.prepare()
                              } )

        this.$core.cc().awaitInitialized()
            .then( () =>
            {
                this.$core.getShare()
                    .awaitShareHelperReady()
                    .then( () =>
                    {
                        this.prepare()
                    } )
            } )

    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .removeIndexedCallback( 'on-share-refresh-component', this.eventIndex )
    },

    computed: {

        shareListOwn()
        {

            let shareList = [],
                list      = this.$core.getShare().shareList( this.$props.item ),
                listed    = []

            for( let l in list )
            {
                if( this.$core.f().isObject( list[ l ] ) )
                {
                    if( -1 === listed.indexOf( list[ l ].localId ) )
                    {
                        listed.push( list[ l ].localId )
                        shareList.push( list[ l ].firstname + ' ' + list[ l ].lastname )
                    }
                }
            }

            return ' mit ' + shareList.join( ', ' )

        },

        shareListForeign()
        {

            let coll = this.$core.getShare().getOwnerColleague( this.$props.item )
            if( this.$core.f().isObject( coll ) )
            {
                return ' von ' + coll.firstname + ' ' + coll.lastname
            }
            return ' von [unbekannt] ' + this.$props.item.idOwner

        }

    },

    methods: {

        prepare()
        {
            this.itemIsOwn = this.$core.f().isOwn( this.$props.item )
            this.itemIsShared = this.$core.getShare().isShared( this.$props.item )

            if( this.$core.settings().getSetting( 'showSharesByDefault' ) === true )
            {
                this.open = true
            }
            if( true === this.itemIsOwn && this.itemIsShared )
            {
                let shareList = this.$core.getShare().shareList( this.$props.item )
                this.sharedWithCount = Array.isArray( shareList ) ? shareList.length : ''
            }
            this.prepared = true
        }

    }
}
</script>