export default class TwoFactor
{
    
    constructor( core )
    {
        
        if( !TwoFactor.instance )
        {
            
            this.client = core.getClient()
            this.store = core.getStore()
            this.logger = core.getLogger()
            this.ui = core.getUi()
            this.crypto = core.getCryptoCore()
            this.uuid = core.getUuid()
            this.config = core.getConfig()
            
            this.lib = require( 'node-2fa' )
            
            this.logger.cconstructed( 'Core::Authentication::TwoFactor', 'initialized' )
            
            TwoFactor.instance = this
            
        }
        
        return TwoFactor.instance
        
    }
    
    destruct()
    {
        this.lib = null
        delete this.lib
        delete TwoFactor.instance
    }
    
    generateSecret( options )
    {
        return this.lib.generateSecret( options )
    }
    
    validate( secret, code )
    {
        
        return this.lib.verifyToken( secret, code )
        
    }
    
}