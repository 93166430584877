<script>
import MixinTypeTranslations from "@/mixins/MixinTypeTranslations";

export default {

    name  : "MixinDefaultDetailViewFiltering",
    mixins: [ MixinTypeTranslations ],

    data()
    {
        return {
            reference                : false,
            referenceObject          : false,
            referenceId              : false,
            studentInGroup           : {},
            studentInYeargroup       : {},
            viewItem                 : false,
            objectType               : false,
            objectList               : {},
            referenceClassId         : false,
            referenceGroupId         : [],
            referenceYeargroupId     : [],
            studentFileShowOnlyFilled: this.$core.settings().getSetting( 'studentFileShowOnlyFilled' )
        }
    },

    methods: {

        setupReferences()
        {

            return new Promise( resolve =>
            {

                let scope      = this.$props.object.archived ? 'archive' : 'cache',
                    groups     = this.$core.getBaseClassHelper()
                                     .get( 'group' )
                                     .readCache( scope ),
                    yeargroups = this.$core.getBaseClassHelper()
                                     .get( 'yeargroup' )
                                     .readCache( scope )

                this.referenceObject = this.$props.object

                switch( this.$props.object.type )
                {
                    case 'student':
                        if( this.$core.f().isset( this.$props.object.classId ) )
                        {

                            let classItem = this.$core.getBaseClassHelper()
                                                .get( 'class' )
                                                .getById( this.$props.object.classId )

                            this.referenceClassId = classItem.localId
                            for( let g in groups )
                            {
                                if( -1 < groups[ g ].students.indexOf( this.referenceId ) )
                                {
                                    this.referenceGroupId.push( groups[ g ].localId )
                                }
                            }

                            for( let g in yeargroups )
                            {
                                if( -1 < yeargroups[ g ].students.indexOf( this.referenceId ) )
                                {
                                    this.referenceYeargroupId.push( yeargroups[ g ].localId )
                                }
                            }

                            return resolve()
                        }
                        break
                    case 'class':
                        this.referenceClassId = this.referenceId
                        break
                    case 'group':
                        for( let g in groups )
                        {
                            this.studentInGroup[ groups[ g ].localId ] = groups[ g ].students
                        }
                        this.referenceGroupId.push( this.referenceId )
                        break
                    case 'yeargroup':
                        for( let g in yeargroups )
                        {
                            this.studentInYeargroup[ yeargroups[ g ].localId ] = yeargroups[ g ].students
                        }
                        this.referenceYeargroupId.push( this.referenceId )
                        break

                }

                return resolve()

            } )

        },

        setupFilter()
        {

            if( this.$core.f().valid( this.tab ) )
            {

                let objectFilter = this.tab.split( '_' )

                this.detailObjectType = objectFilter[ 0 ].type
                this.reference = this.$props.object.type
                this.referenceId = this.$props.object.localId

                if( 1 === objectFilter.length )
                {
                    this.objectList = {
                        type  : objectFilter[ 0 ],
                        filter: false,
                        except: [],
                        only  : []
                    }
                }
                else
                {
                    if( 3 === objectFilter.length )
                    {
                        this.objectList = {
                            type  : objectFilter[ 0 ],
                            filter: objectFilter[ 1 ],
                            except: [],
                            only  : []
                        }
                        if( objectFilter[ 1 ] === 'only' )
                        {
                            this.objectList.only.push( objectFilter[ 2 ] )
                        }
                        else
                        {
                            this.objectList.except.push( objectFilter[ 2 ] )
                        }
                    }
                }

            }

        },

        _fetchAll( type )
        {

            return new Promise( resolve =>
            {

                type = this.translateType( type )
                if( undefined !== type )
                {

                    this.viewItem = this.$core.f().ucFirst( type )
                    this.$core.getBaseClassHelper()
                        .get( type )
                        .listAll()
                        .then( list =>
                        {

                            return resolve( list )

                        } )

                }
                else
                {
                    return resolve( [] )
                }

            } )

        },

        hasScoreBox( object )
        {
            if( undefined === object.lists
                || undefined === object.lists[ 0 ] )
            {
                return false
            }
            for( let c in object.lists[ 0 ].columns )
            {
                let col = object.lists[ 0 ].columns[ c ]
                if( col.type.substr( 0, 8 ) === 'scoreBox' )
                {
                    return true
                }
            }
        },

        hasContentsForReference( object )
        {
            for( let l in object.lists )
            {
                let list = object.lists[ l ]
                if( this.$core.f().isset( list.values ) )
                {
                    for( const [ key, value ] of Object.entries( list.values ) )
                    {
                        if( key.substr( 0, this.referenceId.length ) === this.referenceId )
                        {
                            if( this.$core.f().isset( value ) )
                            {
                                return true
                            }
                        }
                    }
                }
            }

            return false
        },

        listRangeMatch( object )
        {

            if( this.referenceObject.archived !== true )
            {
                return true
            }

            let temp = this.referenceObject.archiveKey.split( /-/g )
            temp.shift()
            let timestamp = parseInt( temp[ 0 ] )

            if( undefined !== object.tsmpEnd )
            {
                if( parseInt( object.tsmpEnd ) < timestamp
                    && parseInt( object.tsmpStart ) > parseInt( this.referenceObject.timestamp ) )
                {
                    return true
                }
                else
                {
                    return false
                }
            }
            else
            {
                if( parseInt( object.timestamp ) < timestamp
                    && parseInt( object.timestamp ) > parseInt( this.referenceObject.timestamp ) )
                {
                    return true
                }
                return false
            }

        },

        filterMatch( object )
        {

            /**
             * do not show archived elements in non-archived scope
             */
            if( true === object.archived
                && true !== this.$props.object.archived )
            {
                return false
            }

            /**
             * list filters
             */
            if( 'list' === object.type )
            {

                /* filter only lists with existing entries for student */
                if( 'student' === this.reference
                    && this.studentFileShowOnlyFilled
                    && !this.hasContentsForReference( object ) )
                {
                    return false
                }

                /* list type not in except list */
                if( 'not' === this.objectList.filter
                    && this.$core.f().isObject( this.objectList.except )
                    && -1 < this.objectList.except.indexOf( object.listType ) )
                {
                    return false
                }

                /* list type not in only list AND list has no scorebox */
                if( 'only' === this.objectList.filter
                    && this.$core.f().isObject( this.objectList.only )
                    && ( -1 === this.objectList.only.indexOf( object.listType ) && !this.hasScoreBox( object ) ) )
                {
                    return false
                }

                /* list studentlist contains reference class (or all) or reference group id */
                if( this.$core.f().isObject( object.lists )
                    && this.$core.f().isObject( object.lists[ 0 ] )
                    && this.$core.f().isObject( object.lists[ 0 ].columns )
                    && ( ( 'student' === this.reference && ( object.lists[ 0 ].columns[ 0 ].filterBy === 'all' && this.listRangeMatch( object ) ) )
                         || object.lists[ 0 ].columns[ 0 ].filterBy === this.referenceClassId
                         || ( this.$core.f().isObject( this.referenceGroupId ) && -1 < this.referenceGroupId.indexOf( object.lists[ 0 ].columns[ 0 ].filterBy ) )
                         || ( this.$core.f().isObject( this.referenceYeargroupId ) && -1 < this.referenceYeargroupId.indexOf( object.lists[ 0 ].columns[ 0 ].filterBy ) ) ) )
                {
                    return true
                }

            }

            if( ( 'note' === object.type || 'todo' === object.type || 'media' === object.type )
                && ( 'group' !== this.reference && 'yeargroup' !== this.reference )
                && ( object.studentReference === this.referenceId
                     || ( this.$core.f().isObject( object.studentReference ) && -1 < object.studentReference.indexOf( this.referenceId ) )
                     || ( undefined !== object.classReference && object.classReference === this.referenceClassId )
                     || ( this.$core.f().isObject( this.referenceGroupId ) && -1 < this.referenceGroupId.indexOf( object.groupReference ) ) ) )
            {
                return true
            }

            if( ( 'note' === object.type || 'todo' === object.type || 'media' === object.type )
                && 'group' === this.reference
                && this.$core.f().isObject( this.referenceGroupId )
                && -1 < this.referenceGroupId.indexOf( object.groupReference ) )
            {
                return true
            }
            if( ( 'note' === object.type || 'todo' === object.type || 'media' === object.type )
                && 'yeargroup' === this.reference
                && this.$core.f().isObject( this.referenceGroupId )
                && -1 < this.referenceYeargroupId.indexOf( object.yeargroupReference ) )
            {
                return true
            }

            if( 'student' === object.type
                && 'class' === this.reference
                && object.classId === this.referenceId )
            {
                return true
            }

            if( 'student' === object.type
                && 'group' === this.reference
                && this.$core.f().isObject( this.studentInGroup )
                && this.$core.f().isObject( this.referenceGroupId )
                && this.$core.f().isObject( this.studentInGroup[ this.referenceGroupId[ 0 ] ] )
                && ( ( undefined !== this.referenceObject.students && -1 < this.referenceObject.students.indexOf( object.localId ) )
                     || -1 < this.studentInGroup[ this.referenceGroupId[ 0 ] ].indexOf( object.localId ) ) )
            {
                return true
            }

            if( 'student' === object.type
                && 'yeargroup' === this.reference
                && this.$core.f().isObject( this.studentInYeargroup )
                && this.$core.f().isObject( this.referenceYeargroupId )
                && this.$core.f().isObject( this.studentInYeargroup[ this.referenceYeargroupId[ 0 ] ] )
                && ( ( undefined !== this.referenceObject.students && -1 < this.referenceObject.students.indexOf( object.localId ) )
                     || -1 < this.studentInYeargroup[ this.referenceYeargroupId[ 0 ] ].indexOf( object.localId ) ) )
            {
                return true
            }

            return false

        }

    }
}
</script>