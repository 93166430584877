<template>
    <div class="planner-organizer-sub-title-grid" v-if="prepared">
        <div v-for="( child, index ) in setup"
             :class="'planner-organizer-sub-title '+child.color+' '+child.classes.join( ' ' )"
             :style="child.style"
             :key="'child-'+organizer.localId+'-'+index"
             v-html="child.displayTitle">
        </div>
    </div>
</template>

<script>
export default {

    name: "PlannerViewOrganizerTitleContents",

    props: {
        dates    : { Type: Object, required: true },
        organizer: { type: [ Object, Boolean ], required: false },
        content  : { type: [ Object, Boolean ], required: false },
        start    : { Type: Number, required: true },
        end      : { Type: Number, required: true }
    },

    data()
    {
        return {
            rows          : [],
            tsmpStart     : -1,
            tsmpEnd       : -1,
            dateStart     : -1,
            dateEnd       : -1,
            organizerStart: -1,
            organizerEnd  : -1,
            setup         : [],
            prepared      : false
        }
    },

    watch: {
        dates: {
            immediate: true,
            deep     : true,
            handler()
            {
                this.prepare()
            }
        }
    },

    created()
    {

        this.prepare()

    },

    methods: {

        prepare()
        {
            this.prepareTimeRange()
            this.prepareGrid()
        },

        prepareTimeRange()
        {

            let hadEntries = false

            for( let s in this.$props.content.slots )
            {

                let slot = this.$props.content.slots[ s ]

                if( null !== slot.date
                    && undefined !== slot.date
                    && ( -1 === this.tsmpStart || this.tsmpStart > slot.date ) )
                {
                    hadEntries = true
                    this.tsmpStart = slot.date
                }
                if( null !== slot.date
                    && undefined !== slot.date
                    && this.tsmpEnd < slot.date )
                {
                    hadEntries = true
                    this.tsmpEnd = slot.date
                }

            }

            for( let d in this.$props.dates )
            {

                if( !this.$props.dates[ d ].isWeekend )
                {

                    let dateElm = this.$props.dates[ d ]

                    if( -1 === this.dateStart || this.dateStart > dateElm.dateObject.getTime() )
                    {
                        hadEntries = true
                        this.dateStart = dateElm.dateObject.getTime()
                    }
                    if( this.dateEnd < dateElm.dateObject.getTime() )
                    {
                        hadEntries = true
                        this.dateEnd = dateElm.dateObject.getTime()
                    }

                }

            }

            if( this.$core.f().isTruely( this.$props.organizer.noTimeRange )
                || !hadEntries )
            {
                this.organizerStart = false
                this.organizerEnd = false
            }
            else
            {
                this.organizerStart = this.$props.start
                this.organizerEnd = this.$props.end
            }

        },

        inRange( tsmpStart, tsmpEnd )
        {

            if( ( this.dateEnd < tsmpStart
                  || this.dateStart > tsmpEnd ) )
            {
                return false
            }

            return true

        },
        /*eslint-disable*/
        prepareGrid()
        {

            if( -1 === this.dateStart || -1 === this.dateEnd )
            {
                return
            }

            let beforeCount = -1,
                afterCount  = -1,
                span        = 1,
                diffStart   = Math.floor( ( this.tsmpStart - this.dateStart ) / 86400000 ),
                diffEnd     = Math.floor( ( this.dateEnd - this.tsmpEnd ) / 86400000 ),
                setup       = [],
                display     = false

            if( 0 <= diffStart
                && 6 > diffStart )
            {
                beforeCount = diffStart
                display = true
            }
            if( 0 > diffStart
                && ( 0 <= diffEnd
                && 6 > diffEnd ) )
            {
                beforeCount = 0
                diffStart = 0
                display = true
            }
            if( 0 <= diffEnd
                && 6 > diffEnd )
            {
                afterCount = diffEnd
                display = true
            }
            if( 0 > diffStart && 0 > diffEnd )
            {
                beforeCount = 0
                afterCount = 0
                display = true
            }

            if( display )
            {

                span = 5 - afterCount - beforeCount

                if( 0 < beforeCount )
                {
                    setup.push( {
                        displayTitle: '&nbsp;',
                        color       : '',
                        classes     : [ 'clear' ],
                        style       : 'grid-column: 1 / span ' + beforeCount + ';'
                    } )
                }

                let classes = []

                if( this.tsmpStart >= this.dateStart )
                {
                    classes.push( 'is-first' )
                }
                if( this.tsmpEnd <= this.dateEnd )
                {
                    classes.push( 'is-last' )
                }

                setup.push( {
                    displayTitle: this.$props.content.title,
                    color       : this.$props.content.color || this.$props.organizer.color,
                    classes     : classes,
                    style       : 'grid-column: ' + ( 1 + beforeCount ) + ' / span ' + span + ';'
                } )

                if( 0 < afterCount )
                {
                    setup.push( {
                        displayTitle: '&nbsp;',
                        color       : '',
                        classes     : [ 'clear' ],
                        style       : 'grid-column: ' + ( 1 + beforeCount + span ) + ' / span ' + afterCount + ';'
                    } )
                }

                this.setup = setup

            }
            else
            {
                this.setup = []
            }

            this.prepared = true

        }

    }
}
</script>