let monthNames = [ 'Januar',
                   'Februar',
                   'März',
                   'April',
                   'Mai',
                   'Juni',
                   'Juli',
                   'August',
                   'September',
                   'Oktober',
                   'November',
                   'Dezember' ]

/**
 * _formatTime
 * @param date
 * @returns {string}
 * @private
 */
function _formatTime( date )
{

    if( isNaN( date.getMinutes() ) )
    {
        return '12:00 Uhr'
    }

    let minutesFormatted = ( 10 > date.getMinutes() ? '0' : '' ) + date.getMinutes()
    return date.getHours() + ':' + minutesFormatted + ' Uhr'

}

/**
 * _formatDate
 * @param date
 * @param noTime
 * @returns {string}
 * @private
 */
function _formatDate( date, noTime )
{

    let day              = date.getDate(),
        monthIndex       = date.getMonth(),
        year             = date.getFullYear(),
        minutesFormatted = ( 10 > date.getMinutes() ? '0' : '' ) + date.getMinutes()

    return day + '. ' + monthNames[ monthIndex ] + ' ' + year
           + ( noTime === undefined ? ' um ' + date.getHours() + ':' + minutesFormatted + ' Uhr' : '' )

}

/**
 * FriendlyTimestamp
 */
export default class FriendlyTimestamp
{

    /**
     * constructor
     * @returns {FriendlyTimestamp}
     */
    constructor()
    {

        if( !FriendlyTimestamp.instance )
        {
            FriendlyTimestamp.instance = this
        }

        return FriendlyTimestamp.instance

    }

    /**
     * destruct
     */
    destruct()
    {
        delete FriendlyTimestamp.instance
    }

    /**
     * timestampIsToday
     * @param timestamp
     * @param now (optional)
     * @returns {boolean}
     */
    timestampIsToday( timestamp, now )
    {

        now = now || Date.now()

        let rangeFrom  = new Date( Date.now( now ) ),
            rangeUntil = new Date( Date.now( now ) )

        rangeFrom.setHours( 0 )
        rangeFrom.setMinutes( 0 )
        rangeFrom.setSeconds( 0 )
        rangeFrom.setMilliseconds( 0 )

        rangeUntil.setHours( 23 )
        rangeUntil.setMinutes( 59 )
        rangeUntil.setSeconds( 59 )
        rangeUntil.setMilliseconds( 999 )

        return rangeFrom.getTime() <= timestamp && rangeUntil.getTime() >= timestamp

    }

    /**
     * timestampFromMysql
     * @param datetime
     * @param separator
     * @returns {number}
     */
    timestampFromMysql( datetime, separator )
    {

        separator = separator || ' '
        let parts = datetime.split( separator )
        let date = parts[ 0 ].split( '-' )
        let time = parts[ 1 ].split( ':' )

        if( -1 < time[ 2 ].indexOf( '.' ) )
        {
            let temp = time[ 2 ].split( '.' )
            time[ 2 ] = temp[ 0 ]
        }

        return new Date( date[ 0 ], ( parseInt( date[ 1 ] ) - 1 ), date[ 2 ], time[ 0 ], time[ 1 ], time[ 2 ] ).getTime()

    }

    /**
     * getMonday
     * @param d
     * @returns {number}
     */
    getMonday( d )
    {

        let day  = d.getDay(),
            diff = d.getDate() - day + ( day == 0 ? -6 : 1 )

        return new Date( d.setDate( diff ) ).getTime()

    }

    /**
     * mysqlTimestamp
     * @param timestamp
     * @returns {string}
     */
    mysqlTimestamp( timestamp )
    {

        let date = new Date()

        if( undefined !== timestamp )
        {
            date = new Date( timestamp )
        }

        let day     = date.getDate(),
            month   = date.getMonth() + 1,
            year    = date.getFullYear(),
            hours   = date.getHours(),
            minutes = date.getMinutes(),
            seconds = date.getSeconds()

        return year + '-' + ( 10 > month ? '0' : '' ) + month + '-' + ( 10 > day ? '0' : '' ) + day + ' ' +
               ( 10 > hours ? '0' : '' ) + hours + ':' + ( 10 > minutes ? '0' : '' ) + minutes + ':' + ( 10 > seconds ? '0' : '' ) + seconds

    }

    /**
     * timestampForDate
     * @param date
     * @param hour
     * @returns {number|*}
     */
    timestampForDate( date, hour )
    {

        hour = undefined === hour ? 12 : hour

        if( undefined === date )
        {
            return 0
        }

        let parts = date.split( '.' )
        if( 3 === parts.length )
        {
            let year  = parseInt( parts[ 2 ] ),
                month = parseInt( parts[ 1 ] ) - 1,
                day   = parseInt( parts[ 0 ] )

            return new Date( year, month, day, hour, 0, 0 ).getTime()
        }

        return date

    }

    /**
     * flatTimestamp
     * @param timestamp
     * @returns {*}
     */
    flatTimestamp( timestamp )
    {

        let date = new Date()

        if( undefined !== timestamp )
        {
            date = new Date( timestamp )
        }

        let day     = date.getDate(),
            month   = date.getMonth() + 1,
            year    = date.getFullYear(),
            hours   = date.getHours(),
            minutes = date.getMinutes()

        return year + ( 10 > month ? '0' : '' ) + month + ( 10 > day ? '0' : '' ) + day +
               ( 10 > hours ? '0' : '' ) + hours + ( 10 > minutes ? '0' : '' ) + minutes

    }

    /**
     * timestampForDateTime
     * @param datetime
     * @returns {null|number}
     */
    timestampForDateTime( datetime )
    {

        if( undefined === datetime )
        {
            return null
        }

        try
        {

            let temp  = datetime.split( ' ' ),
                parts = temp[ 0 ].split( '.' ),
                time  = ( '12:00:00' ).split( ':' )

            if( 2 == temp.length )
            {
                time = temp[ 1 ].split( ':' )
            }

            let year   = parseInt( parts[ 2 ] ),
                month  = parseInt( parts[ 1 ] ) - 1,
                day    = parseInt( parts[ 0 ] ),
                hour   = parseInt( time[ 0 ] ),
                minute = parseInt( time[ 1 ] ),
                second = parseInt( time[ 2 ] )

            let result = new Date( year, month, day, hour, minute, second ).getTime()
            return isNaN( result ) ? null : result

        }
        catch( e )
        {
            return null
        }

    }

    /**
     * toTimestamp (legacy - to be removed)
     * @param date
     * @param time
     * @returns {boolean|number}
     */
    toTimestamp( date, time )
    {

        if( undefined === date )
        {
            return false
        }

        try
        {

            let d = date.split( '.' ).reverse().join( '-' )

            if( undefined !== time
                && null !== time
                && '' !== time )
            {
                let t = time + ':00'
                return Date.parse( d + ' ' + t )
            }

            return Date.parse( d + ' 00:00:00' )

        }
        catch( e )
        {
            return false
        }

    }

    /**
     * now (legacy - to be removed)
     * @returns {number}
     */
    now()
    {
        return Date.now()
    }

    /**
     * getAge (needs refactoring: use language-keys instead)
     * @param timestamp
     * @returns {string}
     */
    getAge( timestamp )
    {

        if( undefined === timestamp
            || null === timestamp )
        {
            return 'n/a'
        }

        try
        {

            if( parseInt( timestamp ) !== timestamp )
            {
                timestamp = this.timestampForDate( timestamp )
                if( 0 === timestamp
                    || isNaN( timestamp ) )
                {
                    throw new Error( 'timestamp invalid' )
                }
            }

            let ageDiff  = Date.now() - timestamp,
                ageDate  = new Date( ageDiff ),
                ageYears = Math.abs( ageDate.getUTCFullYear() - 1970 ),
                appendix = ''

            if( 5 > ageYears )
            {
                appendix = ' (das Geburtsdatum scheint fehlerhaft zu sein)'
            }

            return ageYears + ' Jahre' + appendix

        }
        catch( e )
        {
            return 'n/a'
        }

    }

    /**
     * friendlyDate
     * @param timestamp
     * @returns {string}
     */
    friendlyDate( timestamp )
    {
        if( parseInt( timestamp ) != timestamp )
        {
            timestamp = this.timestampForDate( timestamp )
        }
        return _formatDate( new Date( timestamp ), true )
    }

    /**
     * friendlyTime
     * @param timestamp
     * @returns {string}
     */
    friendlyTime( timestamp )
    {
        return _formatTime( new Date( timestamp ) )
    }

    /**
     * friendlyMonth
     * @param month
     * @returns {string}
     */
    friendlyMonth( month )
    {
        return monthNames[ month ]
    }

    /**
     * friendlySortable
     * @param timestamp
     * @returns {string}
     */
    friendlySortable( timestamp )
    {

        if( undefined === timestamp )
        {
            timestamp = Date.now()
        }

        let date  = new Date( timestamp ),
            month = date.getMonth(),
            year  = date.getFullYear()

        if( isNaN( year ) )
        {
            return null
        }

        return this.friendlyMonth( month ) + ' ' + year

    }

    /**
     * shortSortable
     * @param timestamp
     * @returns {null|*}
     */
    shortSortable( timestamp )
    {

        if( undefined === timestamp )
        {
            timestamp = Date.now()
        }

        let date  = new Date( timestamp ),
            month = date.getMonth() + 1,
            year  = date.getFullYear()

        if( isNaN( year ) )
        {
            return null
        }

        return year + ( 10 > month ? '0' : '' ) + month

    }

    /**
     * formattedDate
     * @param timestamp
     * @returns {string}
     */
    formattedDate( timestamp )
    {

        if( undefined === timestamp )
        {
            timestamp = Date.now()
        }

        let date  = new Date( timestamp ),
            day   = date.getDate(),
            month = date.getMonth() + 1,
            year  = date.getFullYear()

        if( isNaN( year ) )
        {
            return null
        }

        return ( 10 > day ? '0' : '' ) + day + '.' + ( 10 > month ? '0' : '' ) + month + '.' + year

    }

    /**
     * formattedDateTime
     * @param timestamp
     * @returns {string|null}
     */
    formattedDateTime( timestamp )
    {

        if( undefined === timestamp )
        {
            timestamp = Date.now()
        }

        let date    = new Date( timestamp ),
            day     = date.getDate(),
            month   = date.getMonth() + 1,
            year    = date.getFullYear(),
            hours   = date.getHours(),
            minutes = date.getMinutes(),
            seconds = date.getSeconds()

        if( isNaN( year ) )
        {
            return null
        }

        return ( 10 > day ? '0' : '' ) + day + '.' + ( 10 > month ? '0' : '' ) + month + '.' + year + ' ' + ( 10 > hours ? '0' : '' ) + hours + ':' + ( 10 > minutes ? '0' : '' ) + minutes + ':' + ( 10 > seconds ? '0' : '' ) + seconds

    }

    /**
     * friendlyTimestamp
     * @param timestamp
     * @param isUpdate
     * @returns {string|string|null}
     */
    friendlyTimestamp( timestamp, isUpdate )
    {

        let prefix = ( true === isUpdate ? ', geändert ' : '' )

        if( 0 == timestamp || undefined === timestamp )
        {
            return '<strong>noch nie</strong>'
        }

        let date       = new Date( timestamp ),
            now        = new Date(),
            ageSeconds = Math.round( ( now.getTime() - date.getTime() ) / 1000 ),
            ageMinutes = Math.round( ageSeconds / 60 ),
            ageHours   = Math.round( ageMinutes / 60 ),
            ageDays    = Math.floor( ageHours / 24 ),
            ageWeeks   = Math.round( ageDays / 7 )

        if( isNaN( date.getFullYear() ) )
        {
            return null
        }

        if( 60 > ageSeconds )
        {
            return true === isUpdate ? ', <strong>eben erst</strong> aktualisiert' : '<strong>gerade eben</strong>'
        }

        if( 1 > ageHours )
        {
            return prefix + 'vor <strong>' + ( 1 < ageMinutes ? ageMinutes : 'einer' ) + ' Minute' + ( 1 < ageMinutes ? 'n' : '' ) + '</strong>'
        }

        if( 30 <= ageMinutes && 45 > ageMinutes )
        {
            return prefix + 'vor etwa <strong>einer halben Stunde</strong>'
        }

        if( 45 <= ageMinutes && 60 > ageMinutes )
        {
            return prefix + 'vor etwa <strong>einer dreiviertel Stunde</strong>'
        }

        if( 1 > ageDays )
        {
            return prefix + 'vor etwa <strong>' + ( 1 < ageHours ? ageHours : 'einer' ) + ' Stunde' + ( 1 < ageHours ? 'n' : '' ) + '</strong>'
        }

        if( 1 == ageDays && date.getHours() < 12 )
        {
            return prefix + '<strong>gestern Morgen</strong> um <strong>' + _formatTime( date ) + '</strong>'
        }

        if( 1 == ageDays && date.getHours() < 16 )
        {
            return prefix + '<strong>gestern Nachmittag</strong> um <strong>' + _formatTime( date ) + '</strong>'
        }

        if( 1 == ageDays && date.getHours() < 23 )
        {
            return prefix + '<strong>gestern Abend</strong> um <strong>' + _formatTime( date ) + '</strong>'
        }

        if( 7 > ageDays )
        {
            return prefix + 'vor etwa <strong>' + ageDays + ' Tagen</strong>, ' + _formatDate( date )
        }

        if( 4 > ageWeeks )
        {
            return prefix + 'vor etwa <strong>' + ( 1 < ageWeeks ? ageWeeks : 'einer' ) + ' Woche' + ( 1 < ageWeeks ? 'n' : '' ) + '</strong>, ' + _formatDate( date )
        }

        return prefix + 'am ' + _formatDate( date )

    }

    /**
     * friendlyDue
     * @param timestamp
     * @returns {string|null}
     */
    friendlyDue( timestamp )
    {

        if( 0 == timestamp || undefined === timestamp || null === timestamp )
        {
            return '<strong>niemals</strong>'
        }

        let date    = new Date( timestamp ),
            dueDays = this.dueDays( timestamp )

        if( null === dueDays
            || isNaN( dueDays ) )
        {
            return null
        }

        if( 0 == dueDays )
        {
            return '<strong>heute</strong>, ' + _formatDate( date, true )
        }

        if( 1 == dueDays )
        {
            return '<strong>morgen</strong>, ' + _formatDate( date, true )
        }

        if( 2 == dueDays )
        {
            return '<strong>übermorgen</strong>, ' + _formatDate( date, true )
        }

        if( 2 < dueDays && 7 > dueDays )
        {
            return 'in <strong>' + dueDays + ' Tagen</strong>, ' + _formatDate( date, true )
        }

        if( 7 <= dueDays )
        {
            return 'am <strong>' + _formatDate( date, true ) + '</strong>'
        }


        if( -1 == dueDays )
        {
            return '<strong>gestern schon</strong>, ' + _formatDate( date, true )
        }

        if( -2 == dueDays )
        {
            return '<strong>vorgestern schon</strong>, ' + _formatDate( date, true )
        }

        return '<strong>schon vor ' + ( -1 * dueDays ) + ' Tagen</strong>, ' + _formatDate( date, true )

    }

    /**
     * dueDays
     * @param timestamp
     * @returns {null|number}
     */
    dueDays( timestamp )
    {

        try
        {

            let timestampStart = this.timestampForDate( this.formattedDate( Date.now() ), 0 ),
                timestampCheck = this.timestampForDate( this.formattedDate( timestamp ), 0 ),
                days           = Math.ceil( ( ( ( timestampStart - timestampCheck ) * -1 ) / 86400000 ) )

            return days

        }
        catch( e )
        {
            return null
        }

    }

    /**
     * getCalendarWeek
     * @param date
     * @returns {number}
     */
    getCalendarWeek( date )
    {

        if( undefined === date )
        {
            date = new Date()
        }

        try
        {

            let calcDate = new Date( Date.UTC( date.getFullYear(), date.getMonth(), date.getDate() ) )
            calcDate.setUTCDate( calcDate.getUTCDate() + 4 - ( calcDate.getUTCDay() || 7 ) )

            let yearStart  = new Date( Date.UTC( calcDate.getUTCFullYear(), 0, 1 ) ),
                weekNumber = Math.ceil( ( ( ( calcDate - yearStart ) / 86400000 ) + 1 ) / 7 )

            return weekNumber

        }
        catch( e )
        {
            return null
        }

    }

    /**
     * convertServerTimestamp
     * @param data
     * @returns {string|null}
     */
    convertServerTimestamp( data )
    {

        if( null === data
            || undefined === data )
        {
            return null
        }

        return this.mysqlTimestamp( new Date( data ).getTime() )

    }

    /**
     * convertServerTimestamps
     * @param objects
     * @returns {*}
     */
    convertServerTimestamps( objects )
    {

        if( null === objects || undefined === objects || typeof objects !== 'object' )
        {
            return null
        }

        for( const [ key, value ] of Object.entries( objects ) )
        {
            if( -1 < key.indexOf( 'datetime' ) )
            {
                objects[ key ] = this.convertServerTimestamp( value )
            }
            if( Array.isArray( value ) )
            {
                let res = []
                for( let a in value )
                {
                    res.push( this.convertServerTimestamps( value[ a ], true ) )
                }
                objects[ key ] = res
            }
            if( null !== value && typeof value === 'object' )
            {
                objects[ key ] = this.convertServerTimestamps( value, true )
            }
        }

        return objects

    }

}