export default class SearchEngine
{

    /*eslint-disable */

    constructor( core )
    {

        if( !SearchEngine.instance )
        {

            this.logger = core.getLogger()
            this.eventManager = core.getEventManager()
            this.settings = core.settings()
            this.sorter = core.getSorter()
            this.baseClassHelper = core.getBaseClassHelper()
            this.f = core.f()

            this.t = ( key ) =>
            {
                return core.t( key )
            }

            this.logSign = 'SearchEngine::'
            this.searchables = [
                'students',
                'classes',
                'groups',
                'lists',
                'notes',
                'todos',
                'colleagues',
                'dates',
                'yeargroups',
                'media'
            ]

            this.keywords = {
                lists: {
                    listType: this.prepareListTypes()
                },
                types: {
                    'list': [ 'Liste', 'Listen' ],
                    'note': [ 'Notiz', 'Notizen' ],
                    // 'group'    : [ 'Gruppe', 'Gruppen' ],
                    // 'yeargroup': [ 'Jahrgang', 'Jahrgänge' ],
                    // 'class'    : [ 'Klasse', 'Klassen' ],
                    'todo'     : [ 'Todo', 'Todos' ],
                    'colleague': [ 'Kollege', 'Kollegin', 'Kollegen', 'Kolleginnen' ],
                    'date'     : [ 'Termin', 'Termine', 'Reminder', 'Erinnerung' ],
                    // 'student'  : [ 'Schüler', 'Schülerin', 'Schülerinnen' ],
                }
            }

            this.inners = {}
            this.definitions = {
                students  : {
                    objectType: 'student',
                    viewItem  : 'student'
                },
                classes   : {
                    objectType: 'class',
                    viewItem  : 'class'
                },
                groups    : {
                    objectType: 'group',
                    viewItem  : 'group'
                },
                yeargroups: {
                    objectType: 'yeargroup',
                    viewItem  : 'yeargroup'
                },
                lists     : {
                    objectType: 'list',
                    viewItem  : 'list'
                },
                notes     : {
                    objectType: 'note',
                    viewItem  : 'note'
                },
                todos     : {
                    objectType: 'todo',
                    viewItem  : 'todo'
                },
                colleagues: {
                    objectType: 'colleague',
                    viewItem  : 'colleague'
                },
                dates     : {
                    objectType: 'date',
                    viewItem  : 'date'
                },
                media     : {
                    objectType: 'media',
                    viewItem  : 'media'
                }
            }

            SearchEngine.instance = this

        }

        return SearchEngine.instance

    }

    log( caller, what )
    {
        this.logger.cdebug( this.logSign + caller, what )
    }

    prepareListTypes()
    {

        let types = [
            'customFixed',
            'ratinglist',
            'checklist',
            'test',
            'recallList',
            'referenceList',
            'combilist'
        ]

        let trans = {}
        for( let t in types )
        {
            trans[ types[ t ] ] = this.t( 'list-type-' + types[ t ] )
        }

        return trans

    }

    _typeMatch( value, element )
    {
        for( let t in this.keywords.types[ element.type ] )
        {
            let type = this.keywords.types[ element.type ][ t ]
            if( -1 < type.toLowerCase().indexOf( value.toLowerCase() ) )
            {
                return true
            }
        }
        return false
    }

    _match( value, queue, element )
    {

        let keys = [],
            sub  = false

        switch( queue )
        {
            case 'students':
                keys = [ 'displayName' ]
                break
            case 'classes':
                keys = [ 'classname' ]
                break
            case 'groups':
                keys = [ 'groupname' ]
                break
            case 'notes':
                keys = [ 'body' ]
                break
            case 'todos':
                keys = [ 'body', 'title' ]
                break
            case 'dates':
                keys = [ 'description', 'title' ]
                break
            case 'colleagues':
                keys = [ 'firstname', 'lastname' ]
                break
            case 'lists':
                keys = [ 'listname', 'columns', 'listType' ]
                sub = 'lists'
                break
            case 'yeargroups':
                keys = [ 'yeargroupname' ]
                break
            case 'media':
                keys = [ 'filename', 'label' ]
                break
        }

        if( this._typeMatch( value, element ) )
        {
            return true
        }

        if( 0 < keys.length )
        {

            for( let k in keys )
            {
                switch( sub )
                {
                    case 'lists':
                        for( let i in element[ sub ] )
                        {

                            if( undefined !== element[ sub ][ i ]
                                && undefined !== element[ sub ][ i ][ keys[ k ] ] )
                            {
                                if( 'columns' === keys[ k ] )
                                {
                                    let columns = element[ sub ][ i ].columns
                                    for( let c in columns )
                                    {
                                        if( columns[ c ].type !== 'fixed' )
                                        {
                                            let compare = columns[ c ].caption.toLowerCase()
                                            if( -1 < compare.indexOf( value.toLowerCase() ) )
                                            {
                                                return true
                                            }
                                        }
                                    }
                                }
                                else if( 'listType' === keys[ k ] )
                                {
                                    if( -1 < this.keywords.lists.listType[ element[ sub ][ i ].listType ].toLowerCase()
                                                                                                         .indexOf( value.toLowerCase() ) )
                                    {
                                        return true
                                    }
                                }
                                else
                                {
                                    let compare = element[ sub ][ i ][ keys[ k ] ].toLowerCase()
                                    if( -1 < compare.indexOf( value.toLowerCase() ) )
                                    {
                                        return true
                                    }
                                }
                            }
                        }
                        break
                    default:
                        if( undefined !== element[ keys[ k ] ] )
                        {
                            let compare = element[ keys[ k ] ].toLowerCase()
                            if( -1 < compare.indexOf( value.toLowerCase() ) )
                            {
                                return true
                            }
                        }
                }
            }

        }

        return false

    }

    _haveMatch( value, searchable, element )
    {

        let terms = value.split( / /g )

        for( let t in terms )
        {
            if( this._match( terms[ t ], searchable, element )
                || this._typeMatch( terms[ t ], element ) )
            {
                return true
            }
        }

        return false

    }

    _allMatch( value, searchable, element )
    {

        let terms = value.split( / /g )

        for( let t in terms )
        {
            if( !this._match( terms[ t ], searchable, element )
                && !this._typeMatch( terms[ t ], element ) )
            {
                return false
            }
        }

        return true

    }

    prepareInnerLookups()
    {

        return new Promise( resolve =>
        {

            this.inners = {
                'groups'    : {},
                'classes'   : {},
                'yeargroups': {},
                'students'  : {},
                'colleagues': {},
                'lists'     : {},
                'notes'     : {},
                'todos'     : {},
                'dates'     : {},
                'media'     : {}
            }

            let promises = []

            for( let queue in this.inners )
            {

                let objectType = this.definitions[ queue ].objectType

                promises.push( () =>
                {

                    return new Promise( resolve =>
                    {

                        this.baseClassHelper
                            .get( objectType )
                            .listAll()
                            .then( all =>
                            {


                                for( let a in all )
                                {
                                    if( true !== all[ a ].archived )
                                    {
                                        this.inners[ queue ][ all[ a ].localId ] = all[ a ]
                                    }
                                }

                                return resolve()

                            } )

                    } )

                } )

            }

            this.f.promiseRunner( promises )
                .then( () =>
                {
                    return resolve()
                } )

        } )

    }

    reduce( value, results )
    {

        let hits          = 0,
            haveFilters   = false,
            filters       = {},
            todo          = [ 'students', 'groups', 'classes', 'yeargroups' ],
            filterStudent = [ 'groups', 'yeargroups', 'classes' ],
            reduced       = {},
            done          = [],
            terms         = value.split( / /g )

        if( 1 < terms.length )
        {
            for( let t in todo )
            {
                if( undefined !== results[ todo[ t ] ] )
                {
                    filters[ todo[ t ] ] = []

                    for( let r in results[ todo[ t ] ] )
                    {
                        filters[ todo[ t ] ].push( results[ todo[ t ] ][ r ].localId )
                    }

                }

                haveFilters = true
            }
        }

        if( haveFilters )
        {

            if( undefined !== filters.students
                && 0 < filters.students.length )
            {
                for( let f in filters.students )
                {
                    for( let which in filterStudent )
                    {
                        let key = filterStudent[ which ]
                        for( let i in this.inners[ key ] )
                        {
                            if( -1 < this.inners[ key ][ i ].students.indexOf( filters.students[ f ] ) )
                            {
                                filters[ key ] = filters[ key ] || []
                                filters[ key ].push( this.inners[ key ][ i ] )
                            }
                        }
                    }
                }
            }

            reduced.students = []
            for( let r in results.students )
            {
                if( this._allMatch( value, 'students', results.students[ r ] ) )
                {
                    reduced.students.push( results.students[ r ] )
                    hits++
                }
            }

            if( undefined !== results.lists )
            {

                for( let l in results.lists )
                {

                    if( -1 === done.indexOf( results.lists[ l ].lists[ 0 ].localId ) )
                    {
                        done.push( results.lists[ l ].lists[ 0 ].localId )
                        let firstCol = results.lists[ l ].lists[ 0 ].columns[ 0 ]
                        if( undefined !== firstCol )
                        {
                            switch( firstCol.filter )
                            {
                                case 'class':
                                    if( 'all' === firstCol.filterBy )
                                    {
                                        reduced.lists = reduced.lists || []
                                        reduced.lists.push( results.lists[ l ] )
                                        hits++
                                    }
                                    else
                                    {
                                        for( let f in filters.classes )
                                        {
                                            if( filters.classes[ f ].localId === firstCol.filterBy )
                                            {
                                                reduced.lists = reduced.lists || []
                                                reduced.lists.push( results.lists[ l ] )
                                                hits++
                                                break
                                            }
                                        }
                                    }
                                    break
                                case 'group':
                                    for( let f in filters.groups )
                                    {
                                        if( filters.groups[ f ].localId === firstCol.filterBy )
                                        {
                                            reduced.lists = reduced.lists || []
                                            reduced.lists.push( results.lists[ l ] )
                                            hits++
                                            break
                                        }
                                    }
                                    break
                                case 'yeargroup':
                                    for( let f in filters.yeargroups )
                                    {
                                        if( filters.yeargroups[ f ].localId === firstCol.filterBy )
                                        {
                                            reduced.lists = reduced.lists || []
                                            reduced.lists.push( results.lists[ l ] )
                                            hits++
                                            break
                                        }
                                    }
                                    break
                            }

                        }

                    }

                }

            }

            if( undefined !== results.groups
                && 0 < filters.students.length )
            {
                let done = []
                for( let g in results.groups )
                {
                    if( -1 === done.indexOf( results.groups[ g ].localId ) )
                    {
                        done.push( results.groups[ g ].localId )
                        for( let s in filters.students )
                        {
                            if( -1 < results.groups[ g ].students.indexOf( filters.students[ s ] ) )
                            {
                                reduced.groups = reduced.groups || []
                                reduced.groups.push( results.groups[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }
            else
            {
                for( let g in results.groups )
                {
                    if( this._allMatch( value, 'groups', results.groups[ g ] ) )
                    {
                        reduced.groups = reduced.groups || []
                        reduced.groups.push( results.groups[ g ] )
                        hits++
                    }
                }
            }

            if( undefined !== results.yeargroups
                && 0 < filters.students.length )
            {
                let done = []
                for( let g in results.yeargroups )
                {
                    if( -1 === done.indexOf( results.yeargroups[ g ].localId ) )
                    {
                        done.push( results.yeargroups[ g ].localId )
                        for( let s in filters.students )
                        {
                            if( -1 < results.yeargroups[ g ].students.indexOf( filters.students[ s ] ) )
                            {
                                reduced.yeargroups = reduced.yeargroups || []
                                reduced.yeargroups.push( results.yeargroups[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }
            else
            {
                for( let g in results.yeargroups )
                {
                    if( this._allMatch( value, 'yeargroups', results.yeargroups[ g ] ) )
                    {
                        reduced.yeargroups = reduced.yeargroups || []
                        reduced.yeargroups.push( results.yeargroups[ g ] )
                        hits++
                    }
                }
            }

            if( undefined !== results.classes
                && 0 < filters.students.length )
            {
                let done = []
                for( let g in results.classes )
                {
                    if( -1 === done.indexOf( results.classes[ g ].localId ) )
                    {
                        done.push( results.classes[ g ].localId )
                        for( let s in filters.students )
                        {
                            if( -1 < results.classes[ g ].students.indexOf( filters.students[ s ] ) )
                            {
                                reduced.classes = reduced.classes || []
                                reduced.classes.push( results.classes[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }
            else
            {
                for( let g in results.classes )
                {
                    if( this._allMatch( value, 'classes', results.classes[ g ] ) )
                    {
                        reduced.classes = reduced.classes || []
                        reduced.classes.push( results.classes[ g ] )
                        hits++
                    }
                }
            }

            let parsedNotes = false

            if( undefined !== results.notes
                && 0 < filters.students.length )
            {
                parsedNotes = true
                let done = []
                for( let g in results.notes )
                {
                    if( -1 === done.indexOf( results.notes[ g ].localId ) )
                    {
                        done.push( results.notes[ g ].localId )
                        for( let s in filters.students )
                        {
                            let match = false
                            if( Array.isArray( results.notes[ g ].studentReference )
                                && -1 < results.notes[ g ].studentReference.indexOf( filters.students[ s ] ) )
                            {
                                match = true
                            }
                            else if( results.notes[ g ].studentReference === filters.students[ s ] )
                            {
                                match = true
                            }
                            if( match )
                            {
                                reduced.notes = reduced.notes || []
                                reduced.notes.push( results.notes[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }

            if( undefined !== results.notes
                && 0 < filters.groups.length )
            {
                parsedNotes = true
                let done = []
                for( let g in results.notes )
                {
                    if( -1 === done.indexOf( results.notes[ g ].localId ) )
                    {
                        done.push( results.notes[ g ].localId )
                        for( let s in filters.groups )
                        {
                            let match = false
                            if( results.notes[ g ].groupReference === filters.groups[ s ] )
                            {
                                match = true
                            }
                            if( match )
                            {
                                reduced.notes = reduced.notes || []
                                reduced.notes.push( results.notes[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }

            if( undefined !== results.notes
                && 0 < filters.classes.length )
            {
                parsedNotes = true
                let done = []
                for( let g in results.notes )
                {
                    if( -1 === done.indexOf( results.notes[ g ].localId ) )
                    {
                        done.push( results.notes[ g ].localId )
                        for( let s in filters.classes )
                        {
                            let match = false
                            if( results.notes[ g ].classReference === filters.classes[ s ] )
                            {
                                match = true
                            }
                            if( match )
                            {
                                reduced.notes = reduced.notes || []
                                reduced.notes.push( results.notes[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }

            if( undefined !== results.notes
                && 0 < filters.yeargroups.length )
            {
                parsedNotes = true
                let done = []
                for( let g in results.notes )
                {
                    if( -1 === done.indexOf( results.notes[ g ].localId ) )
                    {
                        done.push( results.notes[ g ].localId )
                        for( let s in filters.yeargroups )
                        {
                            let match = false
                            if( results.notes[ g ].yeargroupReference === filters.yeargroups[ s ] )
                            {
                                match = true
                            }
                            if( match )
                            {
                                reduced.notes = reduced.notes || []
                                reduced.notes.push( results.notes[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }

            if( !parsedNotes )
            {
                for( let g in results.notes )
                {
                    if( this._allMatch( value, 'notes', results.notes[ g ] ) )
                    {
                        reduced.notes = reduced.notes || []
                        reduced.notes.push( results.notes[ g ] )
                        hits++
                    }
                }
            }

            let parsedTodos = false

            if( undefined !== results.todos
                && 0 < filters.students.length )
            {
                parsedTodos = true
                let done = []
                for( let g in results.todos )
                {
                    if( -1 === done.indexOf( results.todos[ g ].localId ) )
                    {
                        done.push( results.todos[ g ].localId )
                        for( let s in filters.students )
                        {
                            let match = false
                            if( Array.isArray( results.todos[ g ].studentReference )
                                && -1 < results.todos[ g ].studentReference.indexOf( filters.students[ s ] ) )
                            {
                                match = true
                            }
                            else if( results.todos[ g ].studentReference === filters.students[ s ] )
                            {
                                match = true
                            }
                            if( match )
                            {
                                reduced.todos = reduced.todos || []
                                reduced.todos.push( results.todos[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }

            if( undefined !== results.todos
                && 0 < filters.groups.length )
            {
                parsedTodos = true
                let done = []
                for( let g in results.todos )
                {
                    if( -1 === done.indexOf( results.todos[ g ].localId ) )
                    {
                        done.push( results.todos[ g ].localId )
                        for( let s in filters.groups )
                        {
                            let match = false
                            if( results.todos[ g ].groupReference === filters.groups[ s ] )
                            {
                                match = true
                            }
                            if( match )
                            {
                                reduced.todos = reduced.todos || []
                                reduced.todos.push( results.todos[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }

            if( undefined !== results.todos
                && 0 < filters.classes.length )
            {
                parsedTodos = true
                let done = []
                for( let g in results.todos )
                {
                    if( -1 === done.indexOf( results.todos[ g ].localId ) )
                    {
                        done.push( results.todos[ g ].localId )
                        for( let s in filters.classes )
                        {
                            let match = false
                            if( results.todos[ g ].classReference === filters.classes[ s ] )
                            {
                                match = true
                            }
                            if( match )
                            {
                                reduced.todos = reduced.todos || []
                                reduced.todos.push( results.todos[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }

            if( undefined !== results.todos
                && 0 < filters.yeargroups.length )
            {
                parsedTodos = true
                let done = []
                for( let g in results.todos )
                {
                    if( -1 === done.indexOf( results.todos[ g ].localId ) )
                    {
                        done.push( results.todos[ g ].localId )
                        for( let s in filters.yeargroups )
                        {
                            let match = false
                            if( results.todos[ g ].yeargroupReference === filters.yeargroups[ s ] )
                            {
                                match = true
                            }
                            if( match )
                            {
                                reduced.todos = reduced.todos || []
                                reduced.todos.push( results.todos[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }

            if( !parsedTodos )
            {
                for( let g in results.todos )
                {
                    if( this._allMatch( value, 'todos', results.todos[ g ] ) )
                    {
                        reduced.todos = reduced.todos || []
                        reduced.todos.push( results.todos[ g ] )
                        hits++
                    }
                }
            }

            let parsedDates = false

            if( undefined !== results.dates
                && 0 < filters.students.length )
            {
                parsedDates = true
                let done = []
                for( let g in results.dates )
                {
                    if( -1 === done.indexOf( results.dates[ g ].localId ) )
                    {
                        done.push( results.dates[ g ].localId )
                        for( let s in filters.students )
                        {
                            let match = false
                            if( Array.isArray( results.dates[ g ].studentReference )
                                && -1 < results.dates[ g ].studentReference.indexOf( filters.students[ s ] ) )
                            {
                                match = true
                            }
                            else if( results.dates[ g ].studentReference === filters.students[ s ] )
                            {
                                match = true
                            }
                            if( match )
                            {
                                reduced.dates = reduced.dates || []
                                reduced.dates.push( results.dates[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }

            if( undefined !== results.dates
                && 0 < filters.groups.length )
            {
                parsedDates = true
                let done = []
                for( let g in results.dates )
                {
                    if( -1 === done.indexOf( results.dates[ g ].localId ) )
                    {
                        done.push( results.dates[ g ].localId )
                        for( let s in filters.groups )
                        {
                            let match = false
                            if( results.dates[ g ].groupReference === filters.groups[ s ] )
                            {
                                match = true
                            }
                            if( match )
                            {
                                reduced.dates = reduced.dates || []
                                reduced.dates.push( results.dates[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }

            if( undefined !== results.dates
                && 0 < filters.classes.length )
            {
                parsedDates = true
                let done = []
                for( let g in results.dates )
                {
                    if( -1 === done.indexOf( results.dates[ g ].localId ) )
                    {
                        done.push( results.dates[ g ].localId )
                        for( let s in filters.classes )
                        {
                            let match = false
                            if( results.dates[ g ].classReference === filters.classes[ s ] )
                            {
                                match = true
                            }
                            if( match )
                            {
                                reduced.dates = reduced.dates || []
                                reduced.dates.push( results.dates[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }

            if( undefined !== results.dates
                && 0 < filters.yeargroups.length )
            {
                parsedDates = true
                let done = []
                for( let g in results.dates )
                {
                    if( -1 === done.indexOf( results.dates[ g ].localId ) )
                    {
                        done.push( results.dates[ g ].localId )
                        for( let s in filters.yeargroups )
                        {
                            let match = false
                            if( results.dates[ g ].yeargroupReference === filters.yeargroups[ s ] )
                            {
                                match = true
                            }
                            if( match )
                            {
                                reduced.dates = reduced.dates || []
                                reduced.dates.push( results.dates[ g ] )
                                hits++
                                break
                            }
                        }
                    }
                }
            }

            if( !parsedDates )
            {
                for( let g in results.dates )
                {
                    if( this._allMatch( value, 'dates', results.dates[ g ] ) )
                    {
                        reduced.dates = reduced.dates || []
                        reduced.dates.push( results.dates[ g ] )
                        hits++
                    }
                }
            }

        }
        else
        {
            reduced = results
            for( let r in reduced )
            {
                hits += reduced[ r ].length
            }
        }

        return {
            result: reduced,
            hits  : hits
        }

    }

    lookup( value )
    {

        this.log( 'lookup', value )
        return new Promise( resolve =>
        {

            let sortrules = this.settings.getSetting( 'sortingDirections' ),
                results   = {}

            this.prepareInnerLookups()
                .then( () =>
                {

                    for( let s in this.searchables )
                    {

                        results[ this.searchables[ s ] ] = []

                        for( let a in this.inners[ this.searchables[ s ] ] )
                        {

                            let element = this.inners[ this.searchables[ s ] ][ a ]

                            if( undefined !== element
                                && this._haveMatch( value, this.searchables[ s ], element ) )
                            {
                                results[ this.searchables[ s ] ].push( element )
                            }

                        }

                        if( 0 < results[ this.searchables[ s ] ].length )
                        {
                            results[ this.searchables[ s ] ] = this.sorter
                                                                   .multiSortObjects( results[ this.searchables[ s ] ], sortrules[ this.searchables[ s ] ] )
                        }

                    }

                    let reducedResult = this.reduce( value, results )

                    return resolve( {
                        set : reducedResult.result,
                        def : this.definitions,
                        hits: reducedResult.hits
                    } )

                } )


        } )
    }

}