<template>
    <div class="headline">
        <strong>{{ list.listname }}</strong> <small> | vom
        {{ $core.getFriendlyTimestamp().friendlyDate( list.timestamp ) }}</small>
        <ListStoringIndicator :localId="list.localId"/>
        <EditAllIndicator v-show="editingAll"/>
        <Button type="close" addClass="marge-left" @clicked="$emit( 'close' )"/>
        <Button v-if="list.listType === 'recallList'" type="options" addClass="marge-left"
                @clicked="$emit( 'recallVisibleChange' )"/>
        <Button v-if="'vertical' === editorMode" type="vertical-off" addClass="marge-left"
                @clicked="$emit( 'modeSwitch', 'horizontal' )"/>
        <Button v-if="'horizontal' === editorMode" type="vertical-on" addClass="marge-left"
                @clicked="$emit( 'modeSwitch', 'vertical' )"/>
        <Button v-if="$core.getLicense().isAllowed( 'print' )" type="print" @clicked="$emit( 'print' )"/>
        <Button v-if="$core.getLicense().isAllowed( 'print' )" type="excel" @clicked="$emit( 'excel' )"/>
        <Button type="search" @clicked="$emit( 'filterVisibleChange' )"
                addClass="list-editor inhead marge-left"/>
        <template v-if="colleaguesReady">
            <Button type="history" v-if="snapshotsVisible"
                    @click="handleHistoryClick" :addClass="historyOpen ? 'clicked' : ''"/>
            <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="in-out">
                <div v-if="historyOpen" class="element-value-history">
                    <div class="select-wrapper value-history">
                        <select name="historic-values" @change="handleSelect">
                            <option value="">bitte wählen...</option>
                            <template v-for="( snapshot, index ) in snapshots"
                                      :key="'hist-dropdown-'+snapshot.id">
                                <option v-if="0 < index"
                                        :value="snapshot.id"
                                        v-html="snapshotTitle( snapshot )">
                                </option>
                            </template>
                        </select>
                    </div>
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
import ListStoringIndicator from "@/components/elements/indicators/ListStoringIndicator";
import EditAllIndicator     from "@/components/elements/indicators/EditAllIndicator";

export default {
    name      : "ListEditorHeadline",
    components: { EditAllIndicator, ListStoringIndicator },
    props     : {
        list             : { Type: Object, required: true },
        snapshots        : { Type: Array, required: false },
        editingAll       : { Type: Boolean, required: true },
        recallVisible    : { Type: Boolean, required: true },
        listFilterVisible: { Type: Boolean, required: true },
        editorMode       : { Type: String, required: true }
    },

    data()
    {
        return {
            historyOpen     : false,
            colleaguesReady : false,
            viewKey         : false,
            snapshotsVisible: false,
            timerIndex      : false
        }
    },

    emits: [ 'close', 'modeSwitch', 'print', 'excel', 'history', 'filterVisibleChange', 'recallVisibleChange' ],

    created()
    {
        this.$core.cc().awaitReadiness()
            .then( () =>
            {
                this.colleaguesReady = true
                this.timerIndex = setInterval( () => {
                    this.refreshSnapshots()
                }, 1000 )
            } )
    },

    beforeUnmount()
    {
        clearInterval( this.timerIndex )
    },

    methods: {
        refreshSnapshots()
        {
            this.snapshotsVisible = Array.isArray( this.$props.snapshots ) && 1 < this.$props.snapshots.length
        },
        handleHistoryClick()
        {
            this.historyOpen = !this.historyOpen
        },
        snapshotTitle( snapshot )
        {

            let tsmp          = this.$core.getFriendlyTimestamp()
                                    .timestampFromMysql( snapshot.datetime_snapshot ),
                timestamp     = this.$core.getFriendlyTimestamp()
                                    .formattedDateTime( tsmp ),
                colleague     = this.$core.cc().getById( snapshot.id_author ),
                colleagueName = 'unbekannt'

            if( undefined !== colleague )
            {
                colleagueName = colleague.firstname + ' ' + colleague.lastname
            }
            else
            {
                if( this.$store.getters.idUser === snapshot.id_author )
                {
                    colleagueName = 'von mir'
                }
                else
                {
                    colleagueName += ' (' + snapshot.id_author + ')'
                }
            }
            return timestamp + ': ' + colleagueName
        },
        handleSelect( event )
        {
            this.$emit( 'history', event.target.value )
        }
    }
}
</script>

<style scoped>

.element-value-history {
    float: right;
    margin-right: 25px;
    z-index: 999;
}

div.value-history {
    border-radius: 10px;
    background: #ffffff;
    font-size: 0.8em;
    padding-right: 15px;
}

</style>