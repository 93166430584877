<template>
    <div>
        <div class="status-text offline">offline</div>
        <div class="offline-checkmark"/>
    </div>
</template>

<script>
export default {
    name: 'Offline'
}
</script>