<template>
    <div class="colleague">
        <div class="colleague-avatar" :style="avatar"></div>
        <div class="colleague-body">
            <h2><strong>{{ item.lastname }}</strong>, {{ item.firstname }}</h2>
            <span v-if="-1 < $store.getters.secretaryUser.indexOf( item.colleagueId )"
                  class="reference-marker student divers">
                    <strong>{{ $core.t( 'admin' ) }}</strong>
                </span>
            <span>{{ item.school }}, {{ item.school_zipcity }}</span><br/><br/>
            <strong>{{ $core.t( 'added' ) }}</strong>&nbsp;
            <span v-html="$core.getFriendlyTimestamp().friendlyTimestamp( item.timestamp )"></span><br/>
            <div class="spacer"></div>
            <div class="form-row" :key="'countFrom-'+countKey">
                <div class="form-caption"><strong>{{ $core.t( 'elements-shared-with-me' ) }}</strong></div>
                <div class="form-component text"><strong>{{ counts.sharedFrom.total }}</strong></div>
                <div class="clearfix"></div>
                <div class="inline">
                    <template v-for="( sf, index ) in counts.sharedFrom"
                              :key="'template-from-'+index">
                        <div class="list-row" v-if="'total' !== index">
                            <div class="form-caption">&bull;
                                {{ $core.t( 'object-type-' + $core.getTranslation().translateObjectType( index ) ) }}
                            </div>
                            <div class="form-component text"><strong>{{ sf }}</strong></div>
                            <div class="clearfix"></div>
                        </div>

                    </template>
                </div>
            </div>
            <div class="spacer"></div>
            <div class="form-row" :key="'countTo-'+countKey">
                <div class="form-caption"><strong>{{ $core.t( 'elements-shared' ) }}</strong></div>
                <div class="form-component text"><strong>{{ counts.sharedTo.total }}</strong></div>
                <div class="clearfix"></div>
                <div class="inline">
                    <template v-for="( sf, index ) in counts.sharedTo"
                              :key="'template-with-'+index">
                        <div class="list-row" v-if="'total' !== index">
                            <div class="form-caption">&bull;
                                {{ $core.t( 'object-type-' + $core.getTranslation().translateObjectType( index ) ) }}
                            </div>
                            <div class="form-component text"><strong>{{ sf }}</strong></div>
                            <div class="clearfix"></div>
                        </div>

                    </template>
                </div>
            </div>
            <template v-if="counts.sharedTo.total > 0">
                <div class="spacer"></div>
                <div class="form-row">
                    <div class="form-caption"><strong>{{ $core.t( 'reset-shares' ) }}</strong></div>
                    <button class="delete" @click="handleUnshareAll()">{{
                            $core.t( 'reset-shares-button', [ item.firstname, item.lastname ] )
                        }}
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import MixinColleagueBaseInfo from "@/mixins/MixinColleagueBaseInfo";

export default {
    name  : 'ColleagueMeta',
    props : {
        item: { type: Object, required: true }
    },
    mixins: [ MixinColleagueBaseInfo ],

    data()
    {
        return {
            initialized   : false,
            openShared    : false,
            openSharedWith: false,
            hasMessage    : true
        }
    },

    created()
    {

        this.prepare()
            .then( () =>
            {
                this.initialized = true
            } )

    },

    beforeUnmount()
    {
        this.$core.getCoreTimer()
            .removeInterval( 'share-count-update' )
    },

    methods: {

        prepare()
        {
            return new Promise( resolve =>
            {

                this.getAvatar()
                    .then( () =>
                    {

                        this.prepareCounts()
                            .then( () =>
                            {

                                if( -1 < this.$store.getters.secretaryUser.indexOf( this.$props.item.colleagueId ) )
                                {
                                    this.$emit( 'blockFunction', 'delete' )
                                }

                                return resolve()

                            } )

                    } )

            } )
        },

        performUnshareAll()
        {
            this.$core.getShare().unshareAllForColleague( this.$props.item.uuid )
                .then( () =>
                {
                    this.$core.getCoreTimer()
                        .addInterval( 'share-count-update', 1000, () =>
                        {
                            this.prepareCounts()
                        } )
                } )
        },

        handleUnshareAll()
        {
            this.$core.getUi()
                .showModalDialog( 'delete', this.$core.t( 'unshare-all-title' ), this.$core.t( 'unshare-all-message' ),
                    [
                        'defaultCancel',
                        {
                            type    : 'delete',
                            title   : this.$core.t( 'unshare-all-confirm' ),
                            callback: () =>
                            {
                                this.performUnshareAll()
                            }
                        }
                    ] )
        }

    }
}
</script>