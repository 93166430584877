<script>
export default {
    name: "MixinPagination",

    data()
    {
        return {
            paginating   : false,
            displayedPage: 1,
            pages        : []
        }
    },

    emits: [ 'requestUpdate' ],

    computed: {
        watchKey()
        {
            return this.$props.filterKey + '-' + this.$props.viewKey + '-' + this.$props.hiddenVisible
        },
        cacheKey()
        {
            return this.$core.getBaseClassHelper().get( this.$props.viewItem.toLowerCase() ).registry[ this.$props.viewScope + 'Key' ]
        }
    },

    watch: {
        watchKey: {
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.preparePagination()
                }
            }
        },
        cacheKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.$emit( 'requestUpdate' )
                }
            }
        }
    },

    methods: {

        preparePagination()
        {

            this.paginating = true
            this.pages = []

            let max   = parseInt( this.$core.settings().getSetting( 'elementCount' ) ),
                count = 0,
                page  = []

            for( let i in this.$props.itemList )
            {

                let item = this.$props.itemList[ i ]
                if( ( item.hidden !== true || true === this.$props.hiddenVisible )
                    && ( -1 < this.$props.filtered.indexOf( item.localId ) || !this.$props.viewIsFiltered ) )
                {

                    page.push( item.localId )
                    count++
                    if( count === max )
                    {
                        this.pages.push( page )
                        page = []
                        count = 0
                    }

                }

            }

            if( page.length > 0 )
            {
                this.pages.push( page )
            }

            if( page.length === 0 && this.pages.length === 0 )
            {
                this.displayedPage = 1
                this.pages.push( [] )
            }

            this.displayedPage = false === this.displayedPage ? 1 : this.displayedPage
            this.paginating = false

        },

        handlePageChange( page )
        {
            this.displayedPage = page
        }

    }
}
</script>