<script>
export default {
    name: "MixinComponentCleanup",

    beforeUnmount()
    {

        for( let key in this.$data )
        {
            switch( typeof this.$data[key] )
            {
                case "array":
                case "undefined":
                    delete this.$data[ key ]
                    break
                case "boolean":
                    this.$data[key] = false
                    break
                case "number":
                case "object":
                case "string":
                    this.$data[key] = null
                    break
                default:
                    break
            }
        }

    }
}
</script>