export default class SyncCrudCleanRemote
{
    
    constructor( parent )
    {
        
        this.parent = parent.parent
        this.diff = parent.diff
        return this
        
    }
    
    shouldSync()
    {
        return this.parent.getSyncParameters().mode === 'full'
    }
    
    sync()
    {
        
        return new Promise( resolve => {
            
            this.parent.logger.cdebug( 'SyncWorker::SyncCrud::SyncCrudCleanRemote', 'performing sync run...' )
            let message = {
                method: 'objects.cleanObjectStore'
            }
    
            this.parent.client.request( message )
                .then( () =>
                {
    
                    this.parent.logger.cdebug( 'SyncWorker::SyncCrud::SyncCrudCleanRemote', 'done (success.)' )
                    return resolve()
            
                } )
                .catch( error =>
                {
    
                    this.parent.logger.cdebug( 'SyncWorker::SyncCrud::SyncCrudCleanRemote', 'done (faulty:', error, ')' )
                    return resolve()
            
                } )
            
        })
        
    }
    
}