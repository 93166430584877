if( undefined !== navigator.serviceWorker )
{

    navigator.serviceWorker.register( './service-worker.js' )
        .then( ( registration ) => {

            console.log( '%c -- serviceworker registered --', 'background:#cefece; color:#2c2c2c;' )
            registration.update()
                .then( () => {
                    console.log( '%c -- serviceworker registration updated --', 'background:#cefece; color:#2c2c2c;' )
                })

        })

}