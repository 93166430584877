<template>
    <div>
        <div class="status-text initializing">Verbindungsaufbau...</div>
        <div class="initializing-checkmark">&#8635;</div>
    </div>
</template>

<script>
export default {
    name : 'Reconnecting'
}
</script>