<template>
    <div class="todo-checkbox positioned-center-y">
        <div :class="'inner-checkbox '+( checkState === true ? 'checked' : 'xed' )"
             @touchstart="handleClick"
             @touchend="$core.f().skip"
             @click="handleClick">
        </div>
    </div>
</template>

<script>
export default {
    
    name  : 'TodoCheck',

    props : {
        item: { type: Object, required: true },
        done: { type: [ Number, Boolean, String ], required: true }
    },
    
    emits: [ 'stateChange' ],
    
    data()
    {
        return {
            checkState : null,
            changeTimer: null
        }
    },
    
    created()
    {
        this.checkState = this.$props.done === 1
    },
    
    watch: {
        done: {
            immediate: true,
            handler( oldVal, newVal )
            {
                if( oldVal !== newVal )
                {
                    this.checkState = this.$props.done === 1
                }
            }
        }
    },
    
    methods: {
        
        /*eslint-disable*/
        handleClick( event )
        {

            this.$core.f().skip( event )

            if( this.$core.r().isAllowed( this.$props.item, 'markDone' ) )
            {
                
                this.checkState = !this.checkState
                
                if( this.changeTimer )
                {
                    clearTimeout( this.changeTimer )
                }
                
                this.changeTimer = setTimeout( () =>
                {
                    this.emitState()
                }, 500 )
                
            }
            
        },
        
        emitState()
        {
            this.$emit( 'stateChange', this.checkState )
        }
        
    }
    
}
</script>