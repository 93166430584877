<template>
    <div>
        <template v-if="preheatReady">
            <template v-if="0 < refItems.length">
                <template v-for="( refItem, index ) in refItems"
                          :key="'refmarker-student-'+index">
                    <template v-if="refItem !== undefined">
                        <span v-if="$core.f().isset( refItem )"
                              :class="'reference-marker student '+refItem.gender+( additionalClass ? ' '+additionalClass : '' ) +' sensitive studentName'+( $core.settings().getSetting( 'supportMode' ) === true ? ' blurred' : '' )"
                              :key="'refmark-student-'+refItem.localId" @click="handleDetailViewAndSkip( $event, refItem )">
                            {{ refItem.displayName }}
                        </span>
                    </template>
                </template>
            </template>
        </template>
        <template v-else>
            <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
                <SyncingReferenceMarker/>
            </transition>
        </template>
    </div>
</template>

<script>
import SyncingReferenceMarker from "@/components/elements/markers/SyncingReferencesMarker";
import MixinCachePreheater    from "@/mixins/MixinCachePreheater";
import MixinDetailViews       from "@/mixins/MixinDetailViews";

export default {

    name      : 'StudentReferenceMarker',
    components: { SyncingReferenceMarker },
    mixins    : [ MixinCachePreheater, MixinDetailViews ],
    props     : {
        reference      : { Type: [ String | Object ], required: true },
        additionalClass: { Type: String, required: false }
    },

    data()
    {
        return {
            neededCaches: [ 'student' ],
            refItems    : []
        }
    },

    watch: {
        preheatReady: {
            immediate: true,
            handler( state )
            {
                if( true === state )
                {
                    this.fillReferences()
                }
            }
        }
    },

    beforeUnmount()
    {
        this.refItems = []
    },

    methods: {

        fillReferences()
        {
            for( let r in this.$props.reference )
            {
                this.refItems.push( this.$core.getBaseClassHelper()
                                        .get( 'student' )
                                        .getById( this.$props.reference[ r ] ) )
            }
        }

    }

}
</script>