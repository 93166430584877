<template>
    <div>
        <div class="select-wrapper" v-if="ready">
            <select name="templates" @change="handleSelect">
                <option>{{ $core.t( 'generic-please-select' ) }}</option>
                <option value="create_new">Neue Planungsvorlage anlegen</option>
                <option v-if="showUponStart" :selected="true">gespeichertes Layout verändern</option>
                <optgroup label="Deine Planungsvorlagen">
                    <template v-if="0 === stored.length">
                        <option disabled>noch keine Planungsvorlage angelegt</option>
                    </template>
                </optgroup>
                <optgroup label="Planungsvorlagen von entzettelt">
                    <template v-for="template in templates" :key="template.key">
                        <option :value="template.key">{{ template.item.listname }}</option>
                    </template>
                </optgroup>
            </select>
        </div>

        <div class="spacer"></div>
        <div class="clearfix"></div>

        <template v-if="selection">

            <RowAdder component="SlotEditorBoxAdder"
                      :id="refName"
                      :refName="refName"
                      :value="previewSetup.slots"
                      :asPlainList="true"
                      :showOptional="showOptional"
                      :changeKey="changeKey"
                      :externalValidate="true"
                      @update="handleUpdate"
                      @structureUpdate="handleStructureUpdate"/>

            <div class="clearfix"></div>

        </template>
        <SlotEditorPreview :slots="previewSetup.slots"/>
        <div class="clearfix"></div>

    </div>
</template>

<script>
import RowAdder          from "@/components/form/elements/RowAdder";
import SlotEditorPreview from "@/components/form/elements/SlotEditorPreview";

export default {

    name      : "SlotEditorBox",
    components: { SlotEditorPreview, RowAdder },
    data()
    {
        return {
            templates    : [],
            stored       : [],
            ready        : false,
            selection    : false,
            editorMode   : false,
            previewSetup : {
                slots: []
            },
            showUponStart: false,
            showOptional : false,
            changeKey    : this.$core.getUuid().generate()
        }
    },

    props: {

        value       : { Type: String, required: false },
        set         : { Type: Object, required: false },
        allValues   : { Type: Object, required: false },
        refName     : { Type: String, required: true },
        validator   : { Type: String, required: false },
        reformatter : { Type: String, required: false },
        display     : { Type: String, required: false },
        undefBlocked: { Type: Boolean, required: false },
        configScope : { Type: Boolean, required: false },
        eventKey    : { Type: String, required: false },
        scope       : { Type: String, required: false },
        unique      : { Type: Boolean, required: false },
        index       : { Type: Number, required: false }

    },

    created()
    {
        this.$core
            .getDatabase()
            .readAllObjects( 'slotTemplates' )
            .then( templates =>
            {

                this.templates = templates
                this.parseValue()

            } )
    },

    methods: {

        parseValue()
        {
            if( undefined !== this.$props.value
                && this.$core.f().isObject( this.$props.value ) )
            {
                let preview = []
                for( let v in this.$props.value )
                {
                    let val = this.$core.f().deref( this.$props.value[ v ] )
                    val.value = val.value || val.title
                    val.type = 'freetext'
                    preview.push( JSON.stringify( val ) )
                }

                this.previewSetup.slots = preview
                this.showUponStart = true
                this.selection = true
                this.showOptional = true

            }
            this.ready = true
        },

        handleSelect( event )
        {

            this.selection = false

            if( 'create_new' === event.target.value )
            {
                this.selection = true
                this.editorMode = true
                this.showOptional = true
                this.changeKey = this.$core.getUuid().generate()
                return
            }
            else
            {
                this.showOptional = false
                this.previewSetup.slots = []
                this.editorMode = false
            }

            for( let t in this.templates )
            {
                if( this.templates[ t ].key === event.target.value )
                {
                    this.selection = true
                    for( let s in this.templates[ t ].item.slots )
                    {
                        this.templates[ t ].item.slots[ s ].title = this.templates[ t ].item.slots[ s ].value
                    }
                    this.previewSetup.slots = this.templates[ t ].item.slots
                }
            }

            this.changeKey = this.$core.getUuid().generate()
        },

        /*eslint-disable*/

        handleUpdate( refName, type, method, target, value )
        {

            this.previewSetup.slots = []

            if( this.$core.f().isObject( value ) )
            {
                for( let i in value )
                {

                    if( this.$core.f().isObject( value[ i ].value ) )
                    {
                        this.previewSetup.slots[ i ] = value[ i ].value
                    }
                    else
                    {
                        if( 'string' === typeof value[i].value
                            && '' !== value[ i ].value.trim() )
                        {
                            try
                            {
                                this.previewSetup.slots[ i ] = JSON.parse( value[ i ].value )
                            }
                            catch( e )
                            {
                                this.previewSetup.slots[ i ] = {
                                    value : value[ i ].value,
                                    height: 30,
                                    type  : 'freetext'
                                }
                            }
                        }
                    }
                }

                this.$emit( 'update', this.$props.refName, 'SlotEditorBox', 'update', refName, this.previewSetup.slots )

            }
        },


        /**
         * this.previewSetup.slots = []
         *             if( this.$core.f().isObject( value ) )
         *             {
         *
         *                 for( let i in value )
         *                 {
         *
         *                     if( this.$core.f().isObject( value[ i ].value ) )
         *                     {
         *                         this.previewSetup.slots[ i ] = value[ i ].value
         *                     }
         *                     else
         *                     {
         *                         if( 'string' === typeof value[ i ].value
         *                             && '' !== value[ i ].value.trim() )
         *                         {
         *                             try
         *                             {
         *                                 this.previewSetup.slots[ i ] = JSON.parse( value[ i ].value )
         *                             }
         *                             catch( e )
         *                             {
         *                                 this.previewSetup.slots[ i ] = {
         *                                     title    : value[ i ].value,
         *                                     length   : 1,
         *                                     date     : undefined,
         *                                     slotIndex: undefined
         *                                 }
         *                             }
         *                         }
         *                     }
         *                 }
         *
         *                 this.$emit( 'update', this.$props.refName, 'ContentSlotEditorBox', 'update', refName, this.previewSetup.slots )
         *
         *             }
         */

        handleStructureUpdate()
        {
            this.changeKey = this.$core.getUuid().generate()
        }
    }
}
</script>