export default class License
{

    constructor( core )
    {

        if( !License.instance )
        {

            this.config = core.getConfig()
            this.logger = core.getLogger()
            this.store = core.getStore()
            this.client = core.getClient()
            this.friendlyTimestamp = core.getFriendlyTimestamp()
            this.eventManager = core.getEventManager()
            this.f = core.f()
            this.coreTimer = core.getCoreTimer()
            this.translation = core.getTranslation()

            this.setState = ( which, key ) =>
            {
                core.setState( which, key )
            }

            this.ready = false
            this.license = false

            this.eventManager.append( 'on-login-state-change', () =>
            {
                this.readLicense( true )
            } )
            this.eventManager.append( 'on-store-ready', () =>
            {
                this.readLicense( true )
            } )
            this.eventManager.append( 'on-license-updated', () =>
            {
                this.readLicense()
            } )
            this.eventManager.append( 'on-license-changed', () =>
            {
                this.readLicense( true )
            } )
            this.eventManager.append( 'on-coupon-used', () =>
            {
                this.readLicense( true )
            } )

            this.resetIndex = this.eventManager.addIndexed( 'core-component-reset', () =>
            {

                this.readLicense()

            } )

            License.instance = this

        }

        return License.instance

    }

    get()
    {
        return this.license
    }

    destruct()
    {
        this.eventManager.unregisterIndexedCallback( 'core-component-reset', this.resetIndex )
        delete License.instance
    }

    waitReady()
    {
        return new Promise( resolve =>
        {

            if( true === this.ready )
            {
                return resolve()
            }
            else
            {
                this.eventManager.append( 'on-license-read', () =>
                {
                    return resolve()
                } )
            }

        } )
    }

    hasOpenInvoices()
    {

        if( null !== this.license )
        {
            if( undefined !== this.license.count_open_invoices
                && 0 < this.license.count_open_invoices )
            {
                return true
            }
        }

        return false

    }

    valid()
    {
        return null !== this.license
    }

    inGracePeriod()
    {

        if( null !== this.license
            && null !== this.license.waiting_since )
        {
            let timestamp = this.friendlyTimestamp.timestampFromMysql( this.friendlyTimestamp.convertServerTimestamp( this.license.waiting_since ) )
            let diff = Math.floor( ( Date.now() - timestamp ) / 86400000 )
            return 15 > diff
        }

        return true

    }

    forceReadOnly()
    {
        return ( ( this.hasOpenInvoices() && !this.inGracePeriod() ) || !this.valid() )
    }

    fetchLicense()
    {
        return new Promise( ( resolve, reject ) =>
        {

            let message = {
                method: 'users.getLicense'
            }

            this.client.request( message, 10000 )
                .then( resultMessage =>
                {
                    let license = resultMessage.result

                    if( this.f.isset( license.days_expiring ) )
                    {
                        this.store.commit( 'setLicense', btoa( JSON.stringify( license ) ) )
                    }

                    return resolve()

                } )
                .catch( () =>
                {
                    return reject()
                } )

        } )

    }

    _validToday( license )
    {

        let tsmp    = Date.now(),
            tsmpEnd = this.f.isObject( license ) ? ( 1000 * license.end_timestamp ) : 0

        return tsmpEnd > tsmp

    }

    readLicense( fetch, retry )
    {

        if( !this.store.getters.authorized )
        {
            this.ready = false
            this.logger.cdebug( 'License::readLicense', 'unauthorized' )
        }

        if( fetch )
        {
            this.fetchLicense()
                .then( () =>
                {
                    this.readLicense()
                } )
                .catch( () =>
                {

                    if( !retry )
                    {
                        this.coreTimer.addTimeout( 'read-license', 3000, () =>
                        {
                            this.readLicense( true, true )
                        } )
                    }
                    else
                    {
                        this.readLicense( false )
                        this.logger.cerror( 'License::readLicense', 'failed to fetch license information from server: trying local version instead.' )
                    }

                } )
        }
        else
        {

            let licenseString = this.store.getters.license
            if( this.f.notFalse( licenseString ) )
            {
                this.license = JSON.parse( atob( this.store.getters.license ) )
                if( this._validToday( this.license ) )
                {
                    this.translation.setFeatureSet( this.license.feature_set )
                    this.setState( 'featureSet', this.license.feature_set )
                }
                else
                {
                    this.license = null
                }
            }
            else
            {
                this.license = null
            }

            this.ready = true
            this.eventManager.dispatch( 'on-license-read' )


        }

    }

    _optionName( key )
    {
        switch( key )
        {
            case 'Media Option':
            case 'Speicherplatz für Dokumente':
            case 'Speicherplatz für Dokumente (Demo)':
                return 'mediaHandling'
        }
        return false
    }

    isAllowed( key, tabKey )
    {

        if( undefined !== tabKey )
        {
            key = this.config.tabMap[ tabKey ]
        }

        let featureSet = 'lite'
        if( null !== this.license
            && false !== this.license )
        {
            featureSet = this.license.feature_set
        }

        if( true === this.config.features[ featureSet ][ key ]
            || ( undefined === this.config.features[ featureSet ][ key ]
                 && true === this.config.features[ featureSet ][ 'wildcard' ] ) )
        {
            return true
        }

        if( 'string' === typeof this.config.features[ featureSet ][ key ]
            && 'flag_' === this.config.features[ featureSet ][ key ].substr( 0, 5 ) )
        {

            let flags = this.store.getters.flags

            return false !== flags
                   && -1 !== flags.indexOf( this.config.features[ featureSet ][ key ] )

        }

        if( 'string' === typeof this.config.features[ featureSet ][ key ]
            && 'limitedToUpgrade_' === this.config.features[ featureSet ][ key ].substr( 0, 17 ) )
        {

            if( Array.isArray( this.license.booked_upgrades )
                && 0 < this.license.booked_upgrades.length )
            {
                for( let u in this.license.booked_upgrades )
                {

                    let upgrade    = this.license.booked_upgrades[ u ],
                        optionName = this._optionName( upgrade.name )

                    if( 'limitedToUpgrade_' + optionName === this.config.features[ featureSet ][ key ] )
                    {
                        return true
                    }

                }
            }

        }

        return false

    }

    isAllowedByOption( key )
    {

        if( Array.isArray( this.license.booked_upgrades )
            && 0 < this.license.booked_upgrades.length )
        {
            for( let u in this.license.booked_upgrades )
            {

                let upgrade    = this.license.booked_upgrades[ u ],
                    optionName = this._optionName( upgrade.name )

                return optionName === key

            }
        }

        return false

    }

    /* eslint-disable */
    getLicenseUpgrade( feature )
    {

        let key            = null,
            match          = null,
            reportedResult = null,
            result         = false

        switch( feature )
        {
            case 'mediaHandling':
                key = 'enable_media_handling'
                match = 1
                reportedResult = true
                break
        }

        if( null !== key
            && null !== this.license
            && false !== this.license
            && Array.isArray( this.license.booked_upgrades ) )
        {
            for( let u in this.license.booked_upgrades )
            {

                let upgrade = this.license.booked_upgrades[ u ]
                if( upgrade[ key ] === match )
                {
                    return reportedResult
                }

            }
        }

        return result

    }

    isUnrestricted( scope, key )
    {

        let featureSet = 'lite'
        if( null !== this.license
            && false !== this.license )
        {
            featureSet = this.license.feature_set
        }

        if( undefined === this.config.features[ featureSet ].restricted
            || undefined === this.config.features[ featureSet ].restricted[ scope ] )
        {
            return true
        }

        return -1 === this.config.features[ featureSet ].restricted[ scope ].indexOf( key )

    }

    buyLicense( values )
    {

        return new Promise( ( resolve, reject ) =>
        {

            let message = {
                method : 'license.order',
                details: values
            }

            this.client.request( message )
                .then( result =>
                {

                    if( undefined !== result.result
                        && 'success.' === result.result )
                    {
                        this.coreTimer.addTimeout( 'buy-license', 1000, () =>
                        {
                            this.readLicense()
                            return resolve()
                        } )
                    }
                    else
                    {
                        return reject()
                    }

                } )

        } )

    }

}