<script>
export default {
    
    name: "MixinWatchElementKey",

    emits: [ 'dataUpdate' ],

    watch: {
        'item.elementKey': {
            immediate: true,
            handler( newKey, oldKey )
            {
                if( undefined !== oldKey
                    && newKey !== oldKey )
                {
                    if( 'function' === typeof this.prepare )
                    {
                        this.prepare()
                    }
                    this.$emit( 'dataUpdate' )
                }
            }
        }
    },
    
    beforeUnmount()
    {
        if( 'function' === typeof this.updateOnLeave )
        {
            this.updateOnLeave()
        }
    }
    
}
</script>