function footer( doc, set, maxWidth, maxHeight, color )
{
    
    let y = maxHeight
    
    doc.setTextColor( color[ 0 ], color[ 1 ], color[ 2 ] )
    doc.setDrawColor( color[ 0 ], color[ 1 ], color[ 2 ] )
    
    let result = {
        lines: [
            { x: 10, y: y, x2: maxWidth, y2: y }
        ],
        texts: [],
        color: color
    }
    
    y += 5
    doc.setFontSize( 8 )
    if( '_default' === set.footer )
    {
        result.texts.push( {
            x: 10, y: y, text: 'erstellt mit', align: 'left', style: 'normal', size: 8
        } )
        let dim = doc.getTextDimensions( 'erstellt mit ' )
        result.texts.push( {
            x: ( 10 + dim.w ), y: y, text: 'entzettelt.de', align: 'left', style: 'bold', size: 8, link: 'https://www.entzettelt.de/'
        } )
    }
    else
    {
        result.texts.push( {
            x: 10, y: y, text: set.footer, align: 'left', style: 'normal', size: 8
        } )
    }
    
    doc.setFontSize( 10 )
    doc.setFont( 'quicksand', 'bold' )
    result.texts.push( {
        x: maxWidth, y: y, text: '' + set.pages[ 1 ], align: 'right', style: 'bold', size: 10
    } )
    
    let dimensions = doc.getTextDimensions( '' + set.pages[ 1 ] )
    let x = maxWidth - dimensions.w
    
    result.texts.push( {
        x: x, y: y, text: ' von ', align: 'right', style: 'normal', size: 10
    } )
    
    doc.setFont( 'quicksand', 'normal' )
    dimensions = doc.getTextDimensions( ' von ' )
    x -= dimensions.w
    
    result.texts.push( {
        x: x, y: y, text: '' + set.pages[ 0 ], align: 'right', style: 'bold', size: 10
    } )
    
    doc.setFont( 'quicksand', 'bold' )
    dimensions = doc.getTextDimensions( '' + set.pages[ 0 ] )
    x -= dimensions.w
    
    doc.setFont( 'quicksand', 'normal' )
    result.texts.push( {
        x: x, y: y, text: 'Seite ', align: 'right', style: 'normal', size: 10
    } )
    
    return result
    
}

export default footer