/*eslint-disable*/
export default class SyncMessages
{

    constructor( parent )
    {

        if( !SyncMessages.instance )
        {

            this.parent = parent
            this.client = parent.client
            this.f = parent.f

            this.messageClass = undefined
            this.parent.eventManager.append( 'on-baseclasses-available', () =>
            {
                this.messageClass = parent.getBaseClassHelper().get( 'message' )
            } )

            this.parent.logger.clog( 'SyncWorker::SyncMessages', 'initialized' )

            this.logger = this.parent.logger

            SyncMessages.instance = this

        }

        return SyncMessages.instance

    }

    destruct()
    {

        delete this.parent
        delete SyncMessages.instance

    }

    handleUnsent( messageKey )
    {
        return new Promise( resolve =>
        {

            this.messageClass
                .loadItem( messageKey, true )
                .then( message =>
                {

                    if( undefined !== message.remoteId )
                    {

                        this.parent
                            .share
                            .share( message.id_recipient, [ message ], [] )
                            .then( () =>
                            {

                                this.parent.database.write( 'messagespooler', message.localId, 1 )
                                return resolve()

                            } )
                    }
                    else
                    {
                        this.logger.cdebug( 'SyncWorker::SyncMessages', messageKey + ' not stored on remote yet...' )
                        return resolve()
                    }

                } )
                .catch( () =>
                {
                    return resolve()
                } )

        } )
    }

    handleRemoveFromSpooler( messageKey )
    {
        return new Promise( resolve =>
        {
            this.parent.database.delete( 'messagespooler', messageKey )
                .then( () =>
                {
                    return resolve()
                } )

        } )
    }

    checkWaitingMessages()
    {

        return new Promise( resolve =>
        {

            this.parent
                .database
                .readAllObjects( 'messagespooler' )
                .then( messages =>
                {

                    let promises = []

                    for( let i in messages )
                    {
                        let message = messages[ i ]
                        switch( message.item )
                        {
                            case 0:
                                promises.push( () =>
                                {
                                    return this.handleUnsent( message.key )
                                } )
                                break
                            case 1:
                                promises.push( () =>
                                {
                                    return this.handleRemoveFromSpooler( message.key )
                                } )
                                break
                        }
                    }

                    this.f.promiseRunner( promises )
                        .then( () =>
                        {

                            return resolve()

                        } )

                } )
                .catch( () =>
                {
                    return resolve()
                } )

        } )
    }

    triggerUiMessage( job )
    {

        let colleagues = this.parent.cc.getAll(),
            colleague  = false

        for( let c in colleagues )
        {
            if( colleagues[ c ].uuid === job.id_sender )
            {
                colleague = colleagues[ c ]
            }
        }

        if( false !== colleague )
        {

            let setup = {
                target : 'messages',
                title  : 'Neue Nachricht: "' + job.title + '"',
                message: '<strong>' + colleague.firstname + ' ' + colleague.lastname + '</strong> hat dir eine Nachricht geschickt.'
            }

            this.parent.ui.showMessage( setup )

        }

    }

    checkNewMessages()
    {

        return new Promise( resolve =>
        {

            this.parent
                .database
                .readAllObjectsFiltered( 'message' )
                .then( messages =>
                {

                    let found    = false,
                        promises = []

                    for( let i in messages )
                    {

                        let message = messages[ i ]

                        promises.push( () => {
                            return new Promise( resolve => {

                                this.parent.cryptoHelper.decrypt( message.object )
                                    .then( plain =>
                                    {

                                        if( !found
                                            && ( false !== plain && null !== plain )
                                            && true !== plain.recipientNotified
                                            && this.parent.store.getters.uuid === plain.id_recipient )
                                        {

                                            this.triggerUiMessage( plain )
                                            plain.recipientNotified = true
                                            this.messageClass.update( plain, message.id, message.object.remoteId, plain.timestamp, plain.localKey )
                                            found = true

                                            return resolve()

                                        }
                                        else
                                        {
                                            return resolve()
                                        }

                                    } )

                            })
                        })

                    }

                    this.f.promiseRunner( promises )
                        .then( () => {
                            return resolve()
                        })


                } )

        } )
    }

    sync()
    {
        return new Promise( resolve =>
        {

            if( this.f.isOnlineSyncableState() )
            {
                this.checkWaitingMessages()
                    .then( () =>
                    {
                        this.checkNewMessages()
                            .then( () =>
                            {
                                return resolve()
                            } )
                    } )


            }
            else
            {
                this.logger.cdebug( 'SyncWorker::SyncMessages', 'in unsyncable state right now...' )
                return resolve()
            }

        } )

    }
}