<template>
    <transition appear :name="$core.settings().getTransition( 'list' )" mode="out-in">
        <div v-if="active" :class="'inline-message '+classname">
            <div :class="'message-icon '+classname"> </div>
            <div class="message">
                <h2>{{ title }}</h2>
                <p v-html="message"></p>
            </div>
            <div class="clearfix"></div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'InlineMessage',

    props: {
        active   : { type: Boolean, required: true },
        title    : { type: String, required: true },
        message  : { type: String, required: true },
        classname: { type: String, required: true }
    }

}
</script>