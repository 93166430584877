<script>
export default {

    name: "MixinSelectHandler",

    props: {
        selectMode: { Type: Boolean, required: false, default: false }
    },

    emits: [ 'selected' ],

    data()
    {
        return {
            selected: false
        }
    },

    watch: {
        selected: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.$emit( 'selected', { localId: this.$props.item.localId, selected: this.selected } )
                }
            }
        }
    }

}
</script>