<template>
    <transition appear name="slide-fade">
        <div id="license-warning" :key="viewKey" v-if="true === showWarning && true !== noLicense" style="cursor:pointer;" @click="$router.push( {
                        name  : 'settings',
                        params: { tab: 'license', sub: 'invoices' }
                    })">
            <strong>Funktionalität eingeschränkt</strong>: Offenbar hast du übersehen, unsere Rechnung vom
            <strong>{{ invoiceDate }}</strong> zu begleichen. Die Funktionalität deiner <strong>entzettelt</strong>-Lizenz
            ist aus diesem Grund momentan auf das Lesen von Elementen beschränkt.
        </div>
    </transition>
    <transition appear name="slide-fade">
        <div id="license-warning" :key="viewKey" v-if="true === noLicense && true !== showWarning" style="cursor:pointer;" @click="$router.push( {
                        name  : 'settings',
                        params: { tab: 'license', sub: 'shop' }
                    })">
            <strong>Funktionalität eingeschränkt</strong>: Aktuell verfügst du über keine gültige Lizenz.<br/>
            Das kannst du jederzeit ändern, indem du im Bereich "Einstellungen -> Lizenz & Rechnungen" eine passende
            Lizenz erwirbst.
        </div>
    </transition>
</template>

<script>
export default {
    name: 'LicenseWarning',

    data()
    {
        return {
            showWarning: false,
            noLicense  : false,
            invoiceDate: false,
            viewKey    : 'init'
        }
    },

    created()
    {

        this.readLicense()
        this.$core.getEventManager().append( 'on-license-read', () =>
        {
            this.readLicense()
        } )

    },

    methods: {

        readLicense()
        {

            if( this.$store.getters.authorized )
            {

                this.$core.getLicense()
                    .waitReady()
                    .then( () =>
                    {

                        this.showWarning = ( this.$core.getLicense().valid() && this.$core.getLicense().forceReadOnly() )
                        if( this.showWarning )
                        {
                            this.$core.s().count( 'displayed-open-invoice-warning' )
                            this.invoiceDate = this.$core.getFriendlyTimestamp().formattedDate(
                                this.$core.getFriendlyTimestamp().convertServerTimestamp( this.$core.getLicense().license.waiting_since )
                            )
                        }
                        else
                        {
                            this.noLicense = !this.$core.getLicense().valid()
                        }

                        this.viewKey = this.$core.getUuid().generate()

                    } )

            }

        }

    }
}
</script>