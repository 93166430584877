<script>
export default {

    name: "MixinTypeTranslations",

    methods: {

        translateType( type )
        {
            switch( type )
            {
                case 'lists':
                    return 'list'
                case 'classes':
                    return 'class'
                case 'notes':
                    return 'note'
                case 'dates':
                    return 'date'
                case 'todos':
                    return 'todo'
                case 'students':
                    return 'student'
                case 'media':
                    return 'media'
                case 'groups':
                    return 'group'
                case 'yeargroups':
                    return 'yeargroup'
            }
        }

    }

}
</script>