<template>
    <div :class="'inner '+className">
        <h2 v-html="caption"></h2>
        <p v-html="text"></p>
        <TutorialButton :blocked="backBlocked" className="back" :step="step" :last="last" @clicked="$emit('back')"/>
        <TutorialButton className="next" :step="step" :last="last" @clicked="$emit('next')"/>
        <div class="clearfix"></div>
    </div>
</template>

<script>
import TutorialButton from '@/components/elements/tutorial/TutorialButton'

export default {

    name      : 'TutorialMessage',
    components: { TutorialButton },
    props     : {
        caption    : { Type: String, required: true },
        text       : { Type: String, required: true },
        step       : { Type: Number, required: true },
        last       : { Type: Number, required: true },
        backBlocked: { Type: Boolean, required: false, default: false },
        className  : { Type: String, required: false, default: 'top' }
    },

    emits: [ 'back', 'next' ]

}
</script>