<template>
    <div class="charts-container" id="stats-radar-container" :key="'radar-container-'+changeKey">
        <vue3-chart-js id="stats-radar"
                       :key="'radar-'+changeKey"
                       type="radar"
                       :data="chartdata"
                       :options="options"/>
    </div>
</template>
<script>

import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
    
    name      : "RadarDiagram",
    components: { Vue3ChartJs },
    
    emits: [ 'forceReset' ],
    
    props     : {
        changeKey   : {
            type    : String,
            required: true
        },
        chartdata   : {
            type   : Object,
            default: null
        },
        options     : {
            type   : Object,
            default: null
        },
        colorset    : {
            type   : Object,
            default: null
        },
        bordercolors: {
            type   : Object,
            default: null
        }
    },
    
    beforeUnmount()
    {
    
        let elm = document.querySelector( '#stats-radar' )

        let container  = document.querySelector( '#stats-radar-container' )
        if( container !== null
            && elm !== null )
        {
            let ctx = elm.getContext( '2d' )
            ctx.clearRect( 0, 0, elm.width, elm.height )
            elm.style.display = 'none'
            elm.remove()
            container.remove()
        }

        this.$emit( 'forceReset' )
        
    }
    
    
}

</script>