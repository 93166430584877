<template>
    <transition appear :name="$core.settings().getTransition( 'fade' )">
        <div class="storing-indicator">
            <span :class="'storing-indicator'+addClass">
                {{ label }}
            </span>
        </div>
    </transition>
</template>

<script>
export default {
    name : "EditAllIndicator",
    data()
    {
        return {
            storing : false,
            addClass: '',
            label   : ''
        }
    },
    created()
    {
        this.label = 'Änderungen werden übernommen...'
    }
}
</script>