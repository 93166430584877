<template>
    <div v-if="ready">
        <div v-if="filterVisible" class="select-filter">
            <input type="text" @keyup="this.filterUnique()" :id="'filter-select-'+refName"/>
        </div>
        <div class="select-with-button-wrapper">
            <div class="swb-left">
                <div class="select-wrapper">
                    <select :id="'form-element-'+refName" @change="select()">
                        <option value="">{{ $core.t( 'generic-please-select' ) }}</option>
                        <option v-for="list in lists" :key="refName+'-'+list.localId" :value="list.referenceKey"
                                :selected="list.referenceKey === selectedList">
                            {{ list.listname }}, {{ $core.t( 'list-type-' + list.type ) }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="swb-right">
                <Button type="search" addClass="smallest" @click="toggleFilterMode()"/>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
        <div v-if="selectedList">
            <SubRowAdder component="ColumnSelector"
                         :id="selectedList+'-columns'"
                         :refName="selectedList+'-columns'"
                         :key="'subrow-'+selectedList"
                         :unique=true
                         :set="columns[selectedList]"
                         :dragBlocked=true
                         :value="undefined !== value && undefined !== value.columns ? value.columns : undefined"
                         @update="handleUpdate"/>
        </div>
    </div>
</template>

<script>
import SubRowAdder from '@/components/form/elements/SubRowAdder'
import MixinEvents from '@/mixins/MixinEvents'

export default {

    name      : 'ListAndColumnSelector',
    components: { SubRowAdder },
    mixins    : [ MixinEvents ],
    props     : {

        value       : { Type: String, required: false },
        allValues   : { Type: Object, required: false },
        refName     : { Type: String, required: true },
        validator   : { Type: String, required: false },
        reformatter : { Type: String, required: false },
        display     : { Type: String, required: false },
        undefBlocked: { Type: Boolean, required: false },
        configScope : { Type: Boolean, required: false },
        eventKey    : { Type: String, required: false },
        scope       : { Type: String, required: false },
        unique      : { Type: Boolean, required: false }

    },

    emits: [ 'extendedFilterSelection', 'enterPressed', 'backspacePressed', 'update', 'selected', 'adderValid',
        'adderInvalid' ],

    watch: {

        allValues: {
            deep: true,
            handler()
            {
                if( this.$props.unique === true )
                {
                    this.filterUnique()
                }
            }
        }

    },

    data()
    {
        return {
            ownLists     : [],
            lists        : {},
            setup        : {},
            columns      : {},
            selectedClass: false,
            selectedList : false,
            ready        : false,
            idx          : false,
            filterVisible: false
        }
    },

    created()
    {
        this.idx = this.$core.getEventManager().addIndexed( 'student-group-select', ( classId ) =>
        {
            this.selectedClass = classId
            this.ready = false
            this.init()
        } )

    },

    mounted()
    {
        this.tryClass()
            .then( () =>
            {
                if( this.$props.unique === true
                    && this.ready )
                {
                    this.parseValue()
                    this.filterUnique()
                }
            } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager().removeIndexedCallback( 'student-group-select', this.idx )
    },

    methods: {

        toggleFilterMode()
        {
            this.filterVisible = !this.filterVisible
            this.filterUnique()
        },

        tryClass( retry )
        {
            return new Promise( resolve =>
            {
                let element = document.querySelector( '#form-element-selectedClass' )
                if( null !== element
                    && '' !== element.value.trim() )
                {
                    this.selectedClass = element.value
                    this.prepareLists()
                        .then( () =>
                        {
                            if( this.$props.unique === true )
                            {
                                this.filterUnique()
                            }
                            this.$nextTick()
                                .then( () =>
                                {
                                    this.ready = true
                                    return resolve()
                                } )
                        } )
                }
                else
                {
                    if( undefined !== retry )
                    {
                        this.ready = true
                        return resolve()
                    }
                    else
                    {
                        this.appendEvent( 'on-selectedClass-ready', () =>
                        {
                            return resolve( this.tryClass( true ) )
                        } )
                    }
                }
            } )
        },

        parseValue()
        {

            if( undefined !== this.$props.value && undefined !== this.$props.value.listId )
            {

                this.selectedList = this.$props.value.listId
                this.setup = {
                    listId : this.$props.value.listId,
                    columns: []
                }

                if( undefined !== this.$props.value.columns )
                {
                    this.setup.columns = JSON.parse( JSON.stringify( this.$props.value.columns ) )
                }

            }
        },

        init()
        {
            this.prepareLists()
                .then( () =>
                {
                    this.select()
                    if( this.$props.unique === true )
                    {
                        this.filterUnique()
                    }
                    this.ready = true
                } )
        },

        prepareLists()
        {

            return new Promise( resolve =>
            {
                this.$core.objectRegistryGet( 'lists' )
                    .then( lists =>
                    {

                        this.ownLists = []
                        this.columns = {}

                        let sorted = this.$core.sort().multiSortObjects( lists, [ [ 'listname', 'ascending' ] ], true )

                        for( let s in sorted )
                        {
                            if( this.$core.f().isObject( sorted[ s ].lists )
                                && this.$core.f().isObject( sorted[ s ].lists[ 0 ] )
                                && this.$core.f().valid( sorted[ s ].lists[ 0 ].columns )
                                && 'fixed' === sorted[ s ].lists[ 0 ].columns[ 0 ].type )
                            {
                                if( 'test' !== sorted[ s ].lists[ 0 ].listType
                                    && 'combilist' !== sorted[ s ].lists[ 0 ].listType )
                                {

                                    let identifier = sorted[ s ].lists[ 0 ].columns[ 0 ].filter + ':' + sorted[ s ].lists[ 0 ].columns[ 0 ].filterBy

                                    if( this.selectedClass === identifier
                                        || ( 'all' === sorted[ s ].lists[ 0 ].columns[ 0 ].filterBy && 'all' === this.selectedClass ) )
                                    {

                                        this.ownLists.push( {
                                            localId     : sorted[ s ].localId,
                                            referenceKey: sorted[ s ].referenceKey,
                                            listname    : sorted[ s ].listname,
                                            type        : sorted[ s ].lists[ 0 ].listType
                                        } )

                                        this.columns[ sorted[ s ].referenceKey ] = sorted[ s ].lists[ 0 ].columns

                                    }

                                }
                            }
                        }

                        return resolve()

                    } )
            } )
        },

        select()
        {

            let selectElement = document.querySelector( '#form-element-' + this.$props.refName )

            if( null !== selectElement )
            {
                this.selectedList = selectElement.value
                if( '' !== this.selectedList.trim() )
                {
                    this.setup = {
                        listId : this.selectedList.trim(),
                        columns: []
                    }
                    this.$emit( 'update', this.$props.refName, 'listAndColumnSelector', 'update', undefined, this.setup )
                    this.$emit( 'adderValid' )
                    return
                }
            }

            this.setup = {
                listId : undefined,
                columns: []
            }
            this.selectedList = false
            this.$emit( 'update', this.$props.refName, 'listAndColumnSelector', 'update', undefined, undefined )
            this.$emit( 'adderInvalid' )

        },

        filterUnique()
        {

            let filter    = document.querySelector( '#filter-select-' + this.$props.refName ),
                filterFor = undefined

            if( null !== filter )
            {
                filterFor = filter.value.trim() !== '' ? filter.value.trim() : undefined
            }

            this.$nextTick()
                .then( () =>
                {

                    let excludes = []

                    if( this.$core.f().valid( this.$props.allValues ) )
                    {
                        for( let a in this.$props.allValues )
                        {
                            excludes.push( this.$props.allValues[ a ].value.listId )
                        }
                    }

                    let newList = []

                    for( let s in this.ownLists )
                    {
                        let list = this.ownLists[ s ]
                        if( 'test' !== list.listType
                            && ( -1 === excludes.indexOf( list.localId ) || list.localId === this.selectedList ) )
                        {
                            if( filterFor === undefined
                                || -1 < list.listname.toLowerCase().indexOf( filterFor.toLowerCase() ) )
                            {
                                newList.push( {
                                    localId     : list.localId,
                                    referenceKey: list.referenceKey,
                                    listname    : list.listname,
                                    type        : list.type
                                } )
                            }
                        }
                    }

                    this.lists = newList
                    this.ready = true

                } )

        },

        /*eslint-disable*/
        handleUpdate( id, type, method, elmId, values )
        {

            if( undefined !== this.setup.columns )
            {

                let newSetup = []
                let gotValid = 0 < this.setup.columns.length

                for( let v in values )
                {
                    if( this.$core.f().valid( values[ v ] )
                        && undefined !== values[ v ].value.id
                        && '' !== values[ v ].value.id.trim() )
                    {
                        newSetup.push( values[ v ].value.value.trim() )
                        gotValid = true
                    }
                }

                this.setup.columns = newSetup

                if( gotValid )
                {
                    this.$emit( 'update', this.$props.refName, 'listAndColumnSelector', 'update', undefined, this.setup )
                    this.$emit( 'adderValid' )
                }
                else
                {
                    this.$emit( 'update', this.$props.refName, 'listAndColumnSelector', 'update', undefined, undefined )
                    this.$emit( 'adderInvalid' )
                }
            }
        }

    }

}
</script>