<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-if="visible" class="default-form-overlay secondFactor">
            <div class="default-form-container">
                <div class="default-form-wrapper">
                    <Button type="close"
                            @clicked="handleClose()"
                            addClass="absolute top right inverted"/>
                    
                    <h2>2-Faktor-Authentifizierung aktiv</h2>
                    <div class="form">
                        <p><strong>Für dieses Benutzerkonto wurde die 2-Faktor-Authentifizierung aktiviert!</strong></p>
                        <p>Bitte gib hier den Code aus deiner Authenticator-App ein und klicke anschließend auf
                            "überprüfen".</p>
                        <div class="form-row">
                            <div class="form-caption">
                                <strong>Code</strong>
                            </div>
                            <div class="form-component">
                                <TextBox id="code" type="text" pattern="[0-9]*" @enterPressed="validate()"/>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="form-row">
                            <div class="form-caption">
                                &nbsp;
                            </div>
                            <div class="form-component">
                                <button class="submit" @click="validate()">überprüfen</button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import TextBox             from '@/components/form/elements/TextBox'

export default {
    
    name      : 'Default2FAOverlay',
    components: { TextBox },
    emits     : [ 'close' ],
    
    props: {
        setup: { Type: Object, required: true }
    },
    
    data()
    {
        return {
            visible      : true,
            failedCounter: 0
        }
    },
    
    mounted()
    {
        let elm = document.querySelector( '#form-element-code' )
        if( null !== elm )
        {
            elm.focus()
        }
    },
    
    methods: {
        
        handleClose( eventName )
        {
            this.visible = false
            setTimeout( () =>
            {
                eventName = undefined !== eventName ? eventName : '2fa-failed'
                this.$core.getEventManager().dispatchAndRemove( eventName )
                this.$emit( 'close' )
            }, 300 )
        },
        
        validate()
        {
            
            let storedSecret = this.$store.getters.secondFactor
            if( undefined !== this.$props.setup.token && undefined !== this.$props.setup.token.secondFactor )
            {
                storedSecret = this.$props.setup.token.secondFactor
            }

            this.$core.getCryptoHelper()
                .decrypt( JSON.parse( atob( storedSecret ) ), undefined, this.$props.setup.result.privateKey )
                .then( decryptedSecret => {

                    if( false !== decryptedSecret )
                    {
                        let code = document.querySelector( '#form-element-code' ).value
                        if( this.$core.getTwoFactor().validate( decryptedSecret.token, code ) )
                        {
                            this.handleClose( '2fa-authorized' )
                            return
                        }
                    }

                    this.handleClose()

                })

        }
        
    }
}
</script>