<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-if="visible" class="modal-dialog">
            <div class="dialog-boundaries">
                <div class="dialog">
                    <div class="header">
                        {{ title }} <Button type="close small" @click="hideModal()"/>
                    </div>
                    <div class="message">
                        <p v-html="text"></p>
                    </div>
                    <div v-if="Array.isArray( multi )" class="multiple">
                        <table class="list">
                            <thead>
                            <tr class="sticky">
                                <th class="white flat-fixed clickable hoverable"
                                    colspan="2"
                                    @click="multiSelectAll">
                                    {{ !allSelected ? 'ALLE AUSWÄHLEN' : 'AUSWAHL AUFHEBEN' }}
                                </th>
                            </tr>
                            </thead>
                            <tr v-for="list in multi"
                                :key="'multi-delete-row-'+list.localId">
                                <td style="text-align:center;">
                                    <Checkbox @update="handleCheck"
                                              :refName="'multidelete-'+list.localId+'___'+list.remoteId"/>
                                </td>
                                <td style="text-align:left; font-weight:bold; cursor:pointer;"
                                    @click="markCheckBox( list.localId+'___'+list.remoteId )">
                                    {{ $core.getFriendlyTimestamp().friendlyDate( list.timestamp ) }}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="buttons">
                        <div v-for="( button, index ) in buttons"
                             class="half"
                             :key="'modal-button-'+index">
                            <button @click="button.callback( values )" :class="button.type">{{ button.title }}</button>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Checkbox from '@/components/form/elements/Checkbox'

export default {

    name      : 'ModalDialog',
    components: { Checkbox },

    data()
    {
        return {
            visible    : false,
            mode       : false,
            multi      : false,
            title      : false,
            text       : false,
            buttons    : false,
            allSelected: false,
            values     : []
        }
    },

    created()
    {
        this.$core.getEventManager()
            .add( 'ui-modal-dialog', ( setup ) =>
            {
                this.showModal( setup )
            } )
        this.$core.getEventManager()
            .add( 'ui-hide-modal-dialog', () =>
            {
                this.hideModal()
            } )
    },

    watch: {
        $route: {
            immediate: true,
            handler( to, from )
            {
                if( undefined !== to && undefined !== from
                    && to.name !== from.name )
                {
                    this.hideModal()
                }
            }
        }
    },

    methods: {

        showModal( setup )
        {
            this.title = setup.title
            this.text = setup.message
            this.multi = setup.multi
            this.buttons = this.parseButtons( setup.buttons )
            this.visible = true
        },

        parseButtons( buttons )
        {

            let parsed = []

            for( let b in buttons )
            {
                let but = buttons[ b ]
                if( 'string' == typeof ( but ) )
                {
                    switch( but )
                    {
                        case 'defaultCancel':
                            parsed.push( {
                                type    : 'cancel',
                                title   : 'abbrechen',
                                callback: () =>
                                {
                                    this.hideModal()
                                }
                            } )
                            break
                        case 'defaultOk':
                            parsed.push( {
                                type    : 'cancel',
                                title   : 'ok',
                                callback: () =>
                                {
                                    this.hideModal()
                                }
                            } )
                            break
                    }
                }
                else
                {
                    parsed.push( {
                        type    : but.type,
                        title   : but.title,
                        callback: () =>
                        {
                            but.callback( this.values )
                            this.hideModal()
                        }
                    } )
                }
            }

            return parsed

        },

        multiSelectAll()
        {

            let state = this.allSelected ? false : true

            for( let m in this.multi )
            {

                let list  = this.multi[ m ]
                this.markCheckBox( list.localId + '___' + list.remoteId, state )

            }

            this.allSelected = !this.allSelected

        },

        markCheckBox( id, state )
        {
            let elm = document.querySelector( '#form-element-multidelete-' + id )
            if( null !== elm )
            {
                if( undefined !== state )
                {
                    elm.checked = state
                    this.setValue( id, state )
                }
                else
                {
                    elm.click()
                }
            }
        },

        handleCheck( id )
        {

            let localId = id.replace( 'multidelete-', '' )
            this.setValue( localId )

        },

        setValue( id, state )
        {

            let index = this.values.indexOf( id )
            if( -1 < index && ( false === state || undefined === state ) )
            {
                this.values.splice( index, 1 )
            }
            else
            {
                if( -1 === index && ( true === state || undefined === state ) )
                {
                    this.values.push( id )
                }
            }

        },

        hideModal()
        {

            this.visible = false

            setTimeout( () =>
            {

                this.mode = false
                this.multi = false
                this.title = false
                this.text = false
                this.buttons = false
                this.values = []
                this.allSelected = false

            }, 350 )

        }

    }

}
</script>