import starActive            from '@/classes/Helpers/Printing/ImageHashes/starActive'
import starInactive          from '@/classes/Helpers/Printing/ImageHashes/starInactive'
import checkUnchecked        from '@/classes/Helpers/Printing/ImageHashes/checkUnchecked'
import checkChecked          from '@/classes/Helpers/Printing/ImageHashes/checkChecked'
import cutter                from '@/classes/Helpers/Printing/ImageHashes/cutter'
import icoRateplant0         from '@/classes/Helpers/Printing/ImageHashes/icoRateplant0'
import icoRateplant0Inactive from '@/classes/Helpers/Printing/ImageHashes/icoRateplant0Inactive'
import icoRateplant1         from '@/classes/Helpers/Printing/ImageHashes/icoRateplant1'
import icoRateplant1Inactive from '@/classes/Helpers/Printing/ImageHashes/icoRateplant1Inactive'
import icoRateplant2         from '@/classes/Helpers/Printing/ImageHashes/icoRateplant2'
import icoRateplant2Inactive from '@/classes/Helpers/Printing/ImageHashes/icoRateplant2Inactive'
import icoRateplant3         from '@/classes/Helpers/Printing/ImageHashes/icoRateplant3'
import icoRateplant3Inactive from '@/classes/Helpers/Printing/ImageHashes/icoRateplant3Inactive'
import smile0                from '@/classes/Helpers/Printing/ImageHashes/smile0'
import smile0Inactive        from '@/classes/Helpers/Printing/ImageHashes/smile0Inactive'
import smile1                from '@/classes/Helpers/Printing/ImageHashes/smile1'
import smile1Inactive        from '@/classes/Helpers/Printing/ImageHashes/smile1Inactive'
import smile2                from '@/classes/Helpers/Printing/ImageHashes/smile2'
import smile2Inactive        from '@/classes/Helpers/Printing/ImageHashes/smile2Inactive'
import smile3                from '@/classes/Helpers/Printing/ImageHashes/smile3'
import smile3Inactive        from '@/classes/Helpers/Printing/ImageHashes/smile3Inactive'
import smile4                from '@/classes/Helpers/Printing/ImageHashes/smile4'
import smile4Inactive        from '@/classes/Helpers/Printing/ImageHashes/smile4Inactive'
import todoUnchecked         from '@/classes/Helpers/Printing/ImageHashes/todoUnchecked'
import todoChecked           from '@/classes/Helpers/Printing/ImageHashes/todoChecked'

let imagesHashes = {
    'star_active.png'              : starActive,
    'star_inactive.png'            : starInactive,
    'check_unchecked.png'          : checkUnchecked,
    'check_checked.png'            : checkChecked,
    'cutter.png'                   : cutter,
    'ico-rate-plant-0.png'         : icoRateplant0,
    'ico-rate-plant-0-inactive.png': icoRateplant0Inactive,
    'ico-rate-plant-1.png'         : icoRateplant1,
    'ico-rate-plant-1-inactive.png': icoRateplant1Inactive,
    'ico-rate-plant-2.png'         : icoRateplant2,
    'ico-rate-plant-2-inactive.png': icoRateplant2Inactive,
    'ico-rate-plant-3.png'         : icoRateplant3,
    'ico-rate-plant-3-inactive.png': icoRateplant3Inactive,
    'smile-0-active.png'           : smile0,
    'smile-0-inactive.png'         : smile0Inactive,
    'smile-1-active.png'           : smile1,
    'smile-1-inactive.png'         : smile1Inactive,
    'smile-2-active.png'           : smile2,
    'smile-2-inactive.png'         : smile2Inactive,
    'smile-3-active.png'           : smile3,
    'smile-3-inactive.png'         : smile3Inactive,
    'smile-4-active.png'           : smile4,
    'smile-4-inactive.png'         : smile4Inactive,
    'todo-unchecked.png'           : todoUnchecked,
    'todo-checked.png'             : todoChecked
}

export default imagesHashes