<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-show="visible && pinInitialized" class="default-form-overlay">
            <div class="default-form-container">
                <div class="default-form-wrapper">
                    <Button type="close"
                            @clicked="handleClose()"
                            addClass="absolute top right inverted"/>

                    <h2>Dein Vernetzungs-PIN</h2>
                    <div class="form">
                        <div class="pin-left">
                            <QrcodeVue :value="pin" class="qrcode" />
                        </div>
                        <div class="pin-right">
                            <p>Wenn du deinem Kollegen oder deiner Kollegin erlauben möchtest, dich in sein / ihr
                                <strong>entzettelt</strong>-Kollegium mit aufzunehmen, gib einfach deinen PIN weiter:</p>
                            <h2>{{ pin }}</h2>
                            <p>Er kann mit der <strong>entzettelt</strong>-App auch einfach eingescannt werden.</p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import QrcodeVue           from 'qrcode.vue'
export default {

    name      : 'DefaultPinOverlay',
    emits     : [ 'close' ],
    components: {
        QrcodeVue
    },

    data()
    {
        return {
            visible       : true,
            pin           : false,
            pinInitialized: false
        }
    },

    created()
    {

        this.pin = this.$core.getStore().getters.pin
        if( this.$core.f().valid( this.pin ) )
        {
            this.pinInitialized = true
        } else
        {

            if( this.$core.getFlags().is( 'demouser' ) )
            {
                this.pin = 'Testaccount: keine Vernetzung möglich!'
                this.pinInitialized = true
                return
            }

            let params = {
                method: 'network.getPin'
            }
            this.$core.getClient()
                .request( params )
                .then( result =>
                {

                    if( this.$core.f().valid( result.result ))
                    {
                        this.pin = result.result
                        this.pinInitialized = true
                    }

                } )

        }

    },

    methods: {

        handleClose()
        {
            this.visible = false
            this.pinInitialized = false
            setTimeout( () =>
            {
                this.$emit( 'close' )
            }, 300 )
        }

    }
}
</script>