<template>
    <div class="relative">
        <input type="text"
               :id="'form-element-'+id"
               :value="display"
               :class="foreignHighlighted"
               :disabled="disabled"
               :placeholder="placeholder"
               @focusin="handleFocus()"
               @focusout="handleBlur()"
               @keyup="checkKey"/>
        <Button type="calendar"
                class="absolute solid small"
                v-if="!disabled"
                @clicked="handleCalendarClick()"/>
    </div>
</template>

<script>
import Button                   from '@/components/elements/defaults/Button'
import MixinResettableComponent from '@/mixins/MixinResettableComponent'

export default {

    name: 'DateBox',

    mixins: [ MixinResettableComponent ],

    components: { Button },
    props     : {
        id         : { Type: String, required: true },
        refName    : { Type: String, required: false },
        className  : { Type: String, required: false },
        highlighted: { Type: Object, required: false, default: [] },
        disabled   : { Type: Boolean, required: false, default: false },
        hasErrors  : { Type: Boolean, required: false, default: false },
        value      : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        eventKey   : { Type: String, required: false },
        placeholder: { Type: String, required: false },
    },

    data()
    {
        return {
            display    : undefined,
            timer      : false,
            formElm    : false
        }
    },

    created()
    {
        let value = this.$props.value
        if( parseInt( value ) == value )
        {
            this.display = this.$core.getFriendlyTimestamp().formattedDate( parseInt( value ) )
        }
        else
        {
            this.display = value
        }
    },

    computed: {
        foreignHighlighted()
        {
            return this.$props.className + ( this.$props.hasErrors ? ' error' : '' ) + ' '
                   + ( -1 < this.$props.highlighted.indexOf( this.$props.refName ) ? 'highlighted' : '' )
        }
    },

    mounted()
    {
        this.formElm = document.querySelector( '#form-element-' + this.$props.id )
    },

    watch: {
        value: {
            immediate: true,
            deep     : true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.display = newValue
                }
            }
        }
    },

    emits: [ 'update', 'enterPressed', 'focussed', 'blurred' ],

    methods: {

        checkKey( key )
        {
            if( 13 === key.keyCode )
            {
                this.$emit( 'enterPressed' )
            }
            else
            {

                if( this.timer )
                {
                    clearTimeout( this.timer )
                }
                this.timer = setTimeout( () =>
                {

                    this.reformatInput()

                }, 1000 )

            }
        },

        reformatInput()
        {

            if( null !== this.formElm )
            {

                if( '' !== this.formElm.value.trim() )
                {
                    let value = this.$core.getReformatter().reformat( this.formElm.value, 'date' )
                    this.display = value
                }
                else
                {
                    this.display = undefined
                }
                this.$emit( 'update', this.$props.refName, 'dateBox', 'update', undefined, this.display )

            }

        },

        handleCalendarClick()
        {

            let readyKey = this.$core.getUi().showCalendar( undefined, true )
            this.$core.getEventManager().add( readyKey, ( message ) =>
            {
                this.handleEventAction( message )
            } )
        },

        handleEventAction( message )
        {
            this.$core.getUi().hideCalendar()
            if( null !== this.formElm )
            {
                this.display = this.$core.getFriendlyTimestamp().formattedDate( message.params )
                this.$emit( 'update', this.$props.refName, 'dateBox', 'update', undefined, this.display )
            }
        },

        handleFocus()
        {
            this.focussed = true
            this.$emit( 'focussed', this.$props.refName )
        },

        handleBlur()
        {
            this.focussed = false
            this.$emit( 'blurred', this.$props.refName )
        }

    }

}
</script>