<template>
    <div class="login-status">
        <div v-if="$store.getters.authorized === false">
            Du bist nicht angemeldet
        </div>
        <div v-else>
            angemeldet als <strong>{{ $store.getters.username }}</strong> | <span class="clickable" @click="logout()">abmelden</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginStatus',
    methods: {
        logout()
        {
            this.$core.getAuthenticator().logout()
        }
    }
}
</script>