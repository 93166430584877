<template>
    <div @prefillAdder="prefillAdder" class="select-wrapper" v-if="initialized">
        <select :disabled="disabled"
                class="select_element" :id="'form-element-'+refName" @click="$core.f().skip"
                @change="checkSelect">
            <option value="">bitte wählen...</option>
            <template v-if="0 < classes.length">
                <optgroup label="Klassen">
                    <option v-for="classElm in classes" :value="'class:'+classElm.class.localId"
                            :selected="undefined !== value ? ( asPlainList ? value == 'class:'+classElm.class.localId : value.value == 'class:'+classElm.class.localId ) : false"
                            :key="'key_sharesel_'+classElm.localId">{{ classElm.class.classname }} ({{
                            classElm.count
                        }} {{ classElm.count !== 1 ? 'Zugänge' : 'Zugang' }} )
                    </option>
                </optgroup>
            </template>
            <template v-if="0 < groups.length">
                <optgroup label="Gruppen">
                    <option v-for="groupElm in groups" :value="'group:'+groupElm.group.localId"
                            :selected="undefined !== value ? ( asPlainList ? value == 'group:'+groupElm.group.localId : value.value == 'group:'+groupElm.group.localId ) : false"
                            :key="'key_sharesel_'+groupElm.localId">{{ groupElm.group.groupname }} ({{
                            groupElm.count
                        }} {{ groupElm.count !== 1 ? 'Zugänge' : 'Zugang' }} )
                    </option>
                </optgroup>
            </template>
            <template v-if="0 < yeargroups.length">
                <optgroup label="Jahrgänge">
                    <option v-for="yeargroupElm in yeargroups" :value="'yeargroup:'+yeargroupElm.yeargroup.localId"
                            :selected="undefined !== value ? ( asPlainList ? value == 'yeargroup:'+yeargroupElm.yeargroup.localId : value.value == 'yeargroup:'+yeargroupElm.yeargroup.localId ) : false"
                            :key="'key_sharesel_'+yeargroupElm.localId">{{ yeargroupElm.yeargroup.yeargroupname }}
                        {{ yeargroupElm.count }} ({{ yeargroupElm.count !== 1 ? 'Zugänge' : 'Zugang' }} )
                    </option>
                </optgroup>
            </template>
            <template v-if="0 < students.length">
                <optgroup label="einzelne Schüler:innen">
                    <option v-for="studentElm in students" :value="studentElm.student.localId"
                            :selected="undefined !== value ? ( asPlainList ? value === studentElm.student.localId : value.value === studentElm.student.localId ) : false"
                            :key="'key_sharesel_'+studentElm.localId">{{ studentElm.student.lastname }},
                        {{ studentElm.student.firstname }}
                    </option>
                </optgroup>
            </template>
        </select>
    </div>
</template>

<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {
    name: 'ShareWithStudentsSelector',

    props: {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        id         : { Type: String, required: false },
        idType     : { Type: String, required: false, default: 'localId' },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        disabled   : { Type: Boolean, required: false, default: false },
        editor     : { Type: String, required: false },
        flags      : { Type: String, required: false },
        allValues  : { Type: Object, required: false },
        unique     : { Type: Boolean, required: false },
        prefetch   : { Type: String, required: false },
        editItem   : { Type: Object, required: false },
        asPlainList: { Type: Boolean, required: false, default: false }
    },

    mixins: [ MixinCachePreheater ],

    emits: [ 'change', 'update' ],

    data()
    {
        return {
            neededCaches: [ 'studentAccess', 'student', 'class', 'group', 'yeargroup' ],
            colleagues  : false,
            teams       : false,
            students    : false,
            classes     : false,
            groups      : false,
            yeargroups  : false,
            data        : {},
            selected    : false,
            initialized : false,
            formElm     : false,
            filters     : [],
            haveFilters : false
        }
    },

    created()
    {
        this.awaitNeededCaches()
            .then( () =>
            {
                this.prepareFilters( this.$props.editItem )
            } )
    },

    mounted()
    {
        this.initialize()
            .then( () =>
            {

                this.formElm = document.querySelector( '#form-element-' + this.$props.refName )
                if( this.$props.unique === true )
                {
                    this.filterUnique()
                }
                this.select()

            } )
    },

    watch: {
        prefetch : {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.initialize()
                    this.select()
                }
            },
            deep: true
        },
        value    : {
            handler()
            {
                this.initialize()
            }
        },
        allValues: {
            deep: true,
            handler()
            {
                if( this.$props.unique === true )
                {
                    this.initialize()
                }
            }
        }
    },

    /* eslint-disable */
    methods: {

        prepareFilters( element )
        {

            if( undefined === element )
            {
                return
            }

            let filter,
                filterBy

            switch( element.type )
            {
                case 'list':
                    filter = undefined !== element.lists
                             ? element.lists[ 0 ].columns[ 0 ].filter
                             : element.columns[ 0 ].filter
                    filterBy = undefined !== element.lists
                               ? element.lists[ 0 ].columns[ 0 ].filterBy
                               : element.columns[ 0 ].filterBy
                    break
                case 'note':
                case 'todo':
                case 'date':
                    let studentFilters = []
                    if( 0 < element.studentReference.length )
                    {
                        studentFilters = [ ...studentFilters, ...element.studentReference ]
                    }
                    if( this.$core.f().valid( element.yeargroupReference ) )
                    {
                        let source = this.$core.getBaseClassHelper().get( 'yeargroup' ).getById( element.yeargroupReference )
                        studentFilters = [ ...studentFilters, ...source.students ]
                        this.filters.yeargroup = element.yeargroupReference
                    }
                    if( this.$core.f().valid( element.groupReference ) )
                    {
                        let source = this.$core.getBaseClassHelper().get( 'group' ).getById( element.groupReference )
                        studentFilters = [ ...studentFilters, ...source.students ]
                        this.filters.group = element.groupReference
                    }
                    if( this.$core.f().valid( element.classReference ) )
                    {
                        let source = this.$core.getBaseClassHelper().get( 'class' ).getById( element.classReference )
                        studentFilters = [ ...studentFilters, ...source.students ]
                        this.filters.class = element.classReference
                    }
                    this.filters.students = studentFilters
                    this.haveFilters = true
                    return
            }

            let source = this.$core.getBaseClassHelper().getObjectById( filterBy )
            if( undefined !== source )
            {

                let studentList = source.students
                this.filters[ filter ] = filterBy
                this.filters.students = studentList
                this.haveFilters = true

            }

        },

        _resolveGroupForUser( idLocal, type )
        {

            let groups = this.$core.getBaseClassHelper().get( type ).readCache( 'cache' ),
                result = []

            for( let g in groups )
            {
                if( -1 < groups[ g ].students.indexOf( idLocal ) )
                {
                    result.push( groups[ g ] )
                }
            }

            return result

        },

        resolveAll( idList, rules )
        {

            let accesses       = {},
                studentsList   = [],
                students       = [],
                classes        = [],
                classesTemp    = {},
                groups         = [],
                groupsTemp     = {},
                yeargroups     = [],
                yeargroupsTemp = {}

            for( let i in idList )
            {
                accesses[ idList[ i ].studentLocalId ] = idList[ i ]
            }

            for( let a in accesses )
            {
                studentsList.push( this.$core.getBaseClassHelper().get( 'student' ).getById( a ) )
            }

            studentsList = this.$core.getSorter().multiSortObjects( studentsList, rules[ 'students' ] )

            for( let s in studentsList )
            {
                students.push( {
                    student: studentsList[ s ],
                    access : accesses[ studentsList[ s ].localId ]
                } )
                if( undefined !== studentsList[ s ].classId )
                {
                    classesTemp[ studentsList[ s ].classId ] = classesTemp[ studentsList[ s ].classId ] || []
                    classesTemp[ studentsList[ s ].classId ].push( studentsList[ s ].localId )
                }

                let tempG = this._resolveGroupForUser( studentsList[ s ].localId, 'group' )
                if( 0 < tempG.length )
                {
                    for( let i in tempG )
                    {
                        groupsTemp[ tempG[ i ].localId ] = groupsTemp[ tempG[ i ].localId ] || []
                        groupsTemp[ tempG[ i ].localId ].push( studentsList[ s ].localId )
                    }
                }

                let tempYG = this._resolveGroupForUser( studentsList[ s ].localId, 'yeargroup' )
                if( 0 < tempYG.length )
                {
                    for( let i in tempYG )
                    {
                        yeargroupsTemp[ tempYG[ i ].localId ] = yeargroupsTemp[ tempYG[ i ].localId ] || []
                        yeargroupsTemp[ tempYG[ i ].localId ].push( studentsList[ s ].localId )
                    }
                }

            }

            for( let c in classesTemp )
            {
                classes.push( {
                    class: this.$core.getBaseClassHelper().get( 'class' ).getById( c ),
                    count: classesTemp[ c ].length
                } )
            }

            for( let g in groupsTemp )
            {
                groups.push( {
                    group: this.$core.getBaseClassHelper().get( 'group' ).getById( g ),
                    count: groupsTemp[ g ].length
                } )
            }

            for( let g in yeargroupsTemp )
            {
                yeargroups.push( {
                    yeargroup: this.$core.getBaseClassHelper().get( 'yeargroup' ).getById( g ),
                    count    : yeargroupsTemp[ g ].length
                } )
            }

            return {
                students  : students,
                classes   : classes,
                groups    : groups,
                yeargroups: yeargroups
            }

        },

        initialize()
        {

            return new Promise( resolve =>
            {

                let idList = this.$core.getBaseClassHelper().get( 'studentAccess' ).readCache( 'cache' ),
                    rules  = this.$core.settings().getSetting( 'sortingDirections' ),
                    data   = this.resolveAll( idList, rules )

                this.data = {}

                this.data.all = data
                this.students = []
                this.classes = []
                this.groups = []
                this.yeargroups = []

                for( let s in data.students )
                {
                    if( !this.haveFilters
                        || ( undefined !== this.filters.students
                             && -1 < this.filters.students.indexOf( data.students[ s ].student.localId ) ) )
                    {
                        this.students.push( data.students[ s ] )
                    }
                }

                for( let s in data.classes )
                {
                    if( !this.haveFilters
                        || ( undefined !== this.filters.class
                             && this.filters.class === data.classes[ s ].class.localId ) )
                    {
                        this.classes.push( data.classes[ s ] )
                    }
                }

                for( let s in data.groups )
                {
                    if( !this.haveFilters
                        || ( undefined !== this.filters.group
                             && this.filters.group === data.groups[ s ].group.localId ) )
                    {
                        this.groups.push( data.groups[ s ] )
                    }
                }

                for( let s in data.yeargroups )
                {
                    if( !this.haveFilters
                        || ( undefined !== this.filters.yeargroup
                             && this.filters.yeargroup === data.yeargroups[ s ].yeargroup.localId ) )
                    {
                        this.yeargroups.push( data.yeargroups[ s ] )
                    }
                }

                this.filterUnique()

                this.initialized = true
                return resolve()

            } )

        },

        select()
        {
            if( null !== this.formElm )
            {
                if( undefined !== this.$props.value
                    && null !== this.$props.value )
                {
                    if( this.$core.f().isObject( this.$props.value )
                        && this.$core.f().isset( this.$props.value.value ) )
                    {
                        this.formElm.value = this.$props.value.value
                    }
                    else
                    {
                        this.formElm.value = this.$props.value
                    }
                }
                else
                {
                    this.formElm.value = ''
                }
            }
        },

        checkSelect()
        {
            if( null !== this.formElm )
            {
                if( '' !== this.formElm.value.trim() )
                {
                    this.$emit( 'change', this.$props.id, this.formElm.value )
                    this.$emit( 'update', this.$props.refName, 'shareWithStudentsSelector', 'update', undefined, this.formElm.value )
                }
                else
                {
                    this.$emit( 'change', this.$props.id, undefined )
                    this.$emit( 'update', this.$props.refName, 'shareWithStudentsSelector', 'update', undefined, undefined )
                }
            }
        },

        prefillAdder( event )
        {

            let temp   = event.detail.elmId.split( /_/g ),
                id     = temp.pop(),
                lookup = '_' + id

            if( -1 < this.$props.refName.indexOf( lookup ) )
            {
                if( null !== this.formElm )
                {
                    this.formElm.value = event.detail.values
                    this.checkSelect()
                }
            }

        },

        filterUnique()
        {

            this.$nextTick()
                .then( () =>
                {

                    let excludes = []

                    for( let a in this.$props.allValues )
                    {
                        if( undefined !== this.$props.allValues[ a ].value.value )
                        {
                            excludes.push( this.$props.allValues[ a ].value.value )
                        }
                    }

                    let newList = []
                    for( let c in this.classes )
                    {
                        let classElm = this.classes[ c ].class
                        if( -1 === excludes.indexOf( 'class:' + classElm.localId )
                            || this.$props.value.value === 'class:' + classElm.localId )
                        {
                            newList.push( this.classes[ c ] )
                        }
                    }
                    this.classes = newList

                    let newGroups = []
                    for( let g in this.groups )
                    {
                        let groupElm = this.groups[ g ].group
                        if( -1 === excludes.indexOf( 'group:' + groupElm.localId )
                            || this.$props.value.value === 'group:' + groupElm.localId )
                        {
                            newGroups.push( this.groups[ g ] )
                        }
                    }
                    this.groups = newGroups

                    let newYeargroups = []
                    for( let g in this.yeargroups )
                    {
                        let yeargroupElm = this.yeargroups[ g ].yeargroup
                        if( -1 === excludes.indexOf( 'yeargroup:' + yeargroupElm.localId )
                            || this.$props.value.value === 'yeargroup:' + yeargroupElm.localId )
                        {
                            newYeargroups.push( this.yeargroups[ g ] )
                        }
                    }
                    this.yeargroups = newYeargroups

                    let newStudents = []
                    for( let s in this.students )
                    {
                        let studentElm = this.students[ s ].student
                        if( -1 === excludes.indexOf( studentElm.localId )
                            || this.$props.value.value === studentElm.localId )
                        {
                            newStudents.push( this.students[ s ] )
                        }
                    }
                    this.students = newStudents


                } )

        }

    }

}
</script>