<template>
    <transition appear name="slide-fade">
        <div class="info-screen" v-show="visible">
            <div class="headline">
                App-Status
                <transition name="fade">
                    <ProcessingIndicator v-if="processing"/>
                </transition>
                <Button type="close" @clicked="handleClose"/>
            </div>
            <div class="container">
                <div class="form-row">
                    <div class="form-caption"><strong>Verbindung</strong></div>
                    <div class="form-component text">
                        <span v-if="$store.getters.online === true" class="good">online</span>
                        <span v-if="$store.getters.online !== true" class="notgood">offline</span>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Softwareversion</strong></div>
                    <div class="form-component text"><strong>{{ $core.getConfig().versionNumber }}</strong></div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Build</strong></div>
                    <div class="form-component text"><strong>{{ $core.getConfig().build }}</strong></div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Plattform</strong></div>
                    <div class="form-component text"><strong>{{ $core.getConfig().branch }}</strong>
                        <p v-html="platformInfo"></p></div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Lizenz</strong></div>
                    <div class="form-component text"><strong>{{ $core.getLicense().license.name }}</strong><br/>
                        bis <strong>{{ $core.getLicense().license.end_formatted }}</strong></div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Lizenzerweiterungen</strong></div>
                    <div class="form-component text" v-html="getLicenseUpgrades()"></div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Server</strong></div>
                    <div class="form-component text"><strong>{{ $core.getConfig().server }}</strong></div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>letzter&nbsp;Kontakt</strong></div>
                    <div class="form-component text">vor <strong>{{ lastTick }}</strong>
                        Sekunde{{ lastTick !== 1 ? 'n' : '' }}
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Elemente&nbsp;gesamt</strong></div>
                    <div class="form-component text">{{ elementCount }}</div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>nicht&nbsp;synchronisiert</strong></div>
                    <div class="form-component text">{{ toSync }}</div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Crypto-Speed</strong></div>
                    <div class="form-component text">{{ cryptoSpeed / 1000 }}ms</div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Remote&nbsp;Debugging</strong></div>
                    <div class="form-component text"><span class="notgood">deaktiviert</span></div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Connspeed (HB)</strong></div>
                    <div class="form-component text">{{ connectionStats.hb.speed.toFixed( 2 ) }} MB/s</div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Connquality (HB)</strong></div>
                    <div class="form-component text">{{ connectionStats.hb.quality }}</div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Connspeed (LB)</strong></div>
                    <div class="form-component text">{{ connectionStats.lb.speed.toFixed( 2 ) }} MB/s</div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>Connquality (LB)</strong></div>
                    <div class="form-component text">{{ connectionStats.lb.quality }}</div>
                    <div class="clearfix"></div>
                </div>
                <div class="form-row">
                    <div class="form-caption"><strong>MX Cache-Key</strong></div>
                    <div class="form-component text">{{ $core.getState( 'mxRegistry.cacheKey' ) }}</div>
                    <div class="clearfix"></div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import ProcessingIndicator from '@/components/elements/indicators/ProcessingIndicator'
import Button              from '@/components/elements/defaults/Button'

export default {
    name      : 'InfoScreen',
    components: { Button, ProcessingIndicator },
    emits     : [ 'close' ],
    data()
    {
        return {
            processing     : false,
            visible        : false,
            lastTick       : 0,
            ticker         : null,
            elementCount   : 0,
            toSync         : 0,
            cryptoSpeed    : this.$core.s().statistics[ '_CryptoStatisticsMean' ],
            connectionStats: {
                hb: { speed: 0.00, quality: 'n/a' },
                lb: { speed: 0.00, quality: 'n/a' }
            }
        }
    },

    computed:
        {
            platformInfo()
            {
                let info = '-'
                switch( this.$core.config.branch )
                {
                    case 'local':
                        info = '<span class="error"><strong>Du bist mutig!</strong> Aktuell verwendest du eine frühe Entwicklungsversion der ' +
                               'App. Vermutlich weißt du das selbst, da du Entwickler bist?</span>'
                        break
                    case 'dev':
                        info = '<span class="error"><strong>Willkommen auf unserer Entwicklungsplattform</strong></span><br/>' +
                               'Wenn es keinen triftigen Grund für deine Anwesenheit gibt und dir deine Daten heilig sind, ' +
                               'solltest du nicht hier sein. Die Entwicklungsplattform von <strong>entzettelt</strong> ist ' +
                               'unsere Spielwiese für ganz frühe Entwicklungsergebnisse neuer Funktionen. Wenn du also nicht ' +
                               'ausdrücklich gebeten worden bist, dich hier aufzuhalten, wechsle doch bitte auf eine unserer ' +
                               'stabileren Plattformen:<br/><br/>' +
                               '&bull; <a href="https://beta.entzettelt.de" target="_blank">beta.entzettelt.de</a> (beta)<br/>' +
                               '&bull; <a href="https://app.entzettelt.de" target="_blank">app.entzettelt.de</a> (live)<br/>'
                        break
                    case 'beta':
                        info = '<p class="good"><strong>Willkommen auf unserer Beta-Testplattform</strong><br/>' +
                               'Hier kannst du die folgende Version von <strong>entzettelt</strong> auf Herz und Nieren testen, ' +
                               'bevor sie an die Öffentlichkeit ausgerollt wird.</p>'
                        break
                    default:
                        info = ''
                        break
                }
                return info
            }
        },

    created()
    {

        this.visible = true
        this.ticker = setInterval( () =>
        {

            this.lastTick = Math.floor( ( Date.now() - this.$core.getClient().lastTick ) / 1000 )
            this.getSpeeds()

        }, 1000 )

        this.count()

    },

    beforeUnmount()
    {

        clearInterval( this.ticker )

    },

    methods: {

        /*eslint-disable*/
        getLicenseUpgrades()
        {
            let upgrades = this.$core.getLicense().get().booked_upgrades,
                result   = false

            for( let u in upgrades )
            {
                if( false === result )
                {
                    result = '<ul style="margin:0; padding: 0 20px;">'
                }
                result+= '<li><strong>'+upgrades[u].name+'</strong></li>'
            }

            if( false === result )
            {
                return '- keine -'
            }
            else
            {
                return result + '</ul>'
            }
        },

        getSpeeds()
        {
            this.connectionStats = {
                hb: {
                    speed  : !isNaN( parseFloat( this.$core.getState( 'hb-transmit-speed' ) ) ) ? parseFloat( this.$core.getState( 'hb-transmit-speed' ) ) : 0.0,
                    quality: this.$core.getState( 'hb-transmit-quality' )
                },
                lb: {
                    speed  : !isNaN( parseFloat( this.$core.getState( 'lb-transmit-speed' ) ) ) ? parseFloat( this.$core.getState( 'lb-transmit-speed' ) ) : 0.0,
                    quality: this.$core.getState( 'lb-transmit-quality' )
                }
            }
        },

        handleClose()
        {
            this.visible = false
            setTimeout( () =>
            {
                this.$emit( 'close' )
            }, 400 )
        },

        count()
        {
            this.$core.getDatabase().readAllObjects( 'objects' )
                .then( list =>
                {

                    let unsynced = -2
                    this.elementCount = list.length
                    for( let l in list )
                    {
                        if( undefined === list[ l ].item.remoteId )
                        {
                            unsynced++
                        }
                    }

                    this.toSync = ( 0 > unsynced ? 0 : unsynced )

                } )
        }

    }
}
</script>