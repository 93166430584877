<template>
    <div>
        <span
            :class="'reference-marker todo '+( todos.length === doneCount ? 'done' : 'not-done' )+( additionalClass ? ' '+additionalClass : '' )">
            {{ doneCount }}/{{ todos.length }}
        </span>
    </div>
</template>

<script>

export default {

    name: 'TodoListMarker',

    props: {
        todos          : { Type: Object, required: true },
        done           : { Type: Object, required: true },
        additionalClass: { Type: String, required: false }
    },

    computed: {
        doneCount()
        {

            let doneCnt = 0
            if( this.$core.f().isObject( this.$props.done ) )
            {
                for( let k in Object.keys( this.$props.done ) )
                {
                    let key = Object.keys( this.$props.done )[ k ]
                    doneCnt += this.$props.done[ key ] === true ? 1 : 0
                }
            }

            return doneCnt
        }
    }

}

</script>