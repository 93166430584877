<template>
    <div class="list white">
        <div class="spacer-bottom no-margin">
            <button type="button" :class="'auto right '+(showComments ? 'cancel' : 'submit')"
                    @click="showComments = !showComments">
                {{ showComments ? 'Kommentare ausblenden' : 'Kommentare einblenden' }}
            </button>
            <button type="button" :class="'auto right '+(showPoints ? 'cancel' : 'submit')"
                    @click="showPoints = !showPoints">
                {{ showPoints ? 'Punkte ausblenden' : 'Punkte einblenden' }}
            </button>
            <button type="button" :class="'auto right '+(showListType ? 'cancel' : 'submit')"
                    @click="showListType = !showListType">
                {{ showListType ? 'Listentyp ausblenden' : 'Listentyp einblenden' }}
            </button>
        </div>
        <div class="list white" v-if="ready">
            <template v-if="undefined === filter">
                <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="out-in">
                    <table class="list" id="printable-scoreOverview">
                        <thead>
                        <tr class="list-header">
                            <th>&nbsp;</th>
                            <th v-for="( col, index ) in setup.columns"
                                :key="'th-col-'+index"
                                :class="col.color"
                                :colspan="'test' === col.rawListType ? ( colLayout[ index ] - ( !showComments ? 1 : 0 ) - ( !showPoints ? 1 : 0 ) ) : colLayout[index]">
                                <span v-if="showListType" class="rotated"
                                      v-html="(undefined !== totalScores[ col.key ]
                                            ? '<small>'+totalScores[ col.key ].value+' Punkte gesamt</small><br/>' : '' )
                                            + '<small>'+col.listType+'</small><br/>'+col.name"></span>
                                <span v-if="!showListType" class="rotated"
                                      v-html="(undefined !== totalScores[ col.key ]
                                            ? '<small>'+totalScores[ col.key ].value+' Punkte gesamt</small><br/>' : '' )
                                            +col.name"></span>
                            </th>
                            <th rowspan="2"><strong>&sum;</strong></th>
                            <th rowspan="2"><strong>&#216;</strong></th>
                        </tr>
                        <tr :class="'list-header'+( hideCaptions ? ' shrinked' : '' )">
                            <template v-if="!hideCaptions">
                                <th @click="hideCaptions = !hideCaptions">
                                    <div>
                                        <span>ausblenden</span>
                                        <!--                                        <Opener classname="listeditor-show-edit-all small"
                                                                                        id="hide-captions"
                                                                                        :state="!hideCaptions"
                                                                                        @click="$core.f().skip( $event ); hideCaptions = !hideCaptions"/>-->
                                    </div>
                                </th>
                                <template v-for="( col, index ) in setup.columns"
                                          :key="'tpl-col-name-'+index">
                                    <template v-if="'test' === col.rawListType">
                                        <th v-if="showPoints"
                                            :class="col.color"><span class="rotated">Punkte</span></th>
                                        <th :class="col.color"><span class="rotated">Note(n)</span></th>
                                        <th v-if="col.hasComments && showComments"
                                            :class="col.color"><span class="rotated">Kommentar / Notiz</span></th>
                                    </template>
                                    <template v-else>
                                        <template v-for="(head, idx) in col.scores.columns"
                                                  :key="'tpl-subcol-'+idx">
                                            <th :class="col.color"><span class="rotated">{{ head.raw.caption }}</span>
                                            </th>
                                        </template>
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                <th @click="hideCaptions = !hideCaptions">
                                    <span>einblenden</span>
                                </th>
                                <th colspan="99999"
                                    style="text-align: left;">
                                    Spaltenüberschriften ausgeblendet
                                </th>
                            </template>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="( row, index ) in preparedRows" :key="'tpl-'+index">
                            <tr v-if="undefined !== row[0].lastname && true !== row[0].archived">
                                <template v-for="( col, index ) in row" :key="'td-'+index">
                                    <td v-if="( 'comment' !== col.type || showComments )
                                            && ( 'points' !== col.type || showPoints )">
                                        <template v-if="'student' === col.type">
                                            <strong>{{ col.lastname }}</strong>, {{ col.firstname }}
                                        </template>
                                        <template v-if="'score' === col.type">
                                            {{ col.score }}
                                        </template>
                                        <template v-if="'comment' === col.type && showComments">
                                            <p v-html="col.comment"></p>
                                        </template>
                                        <template v-if="'points' === col.type && showPoints">
                                            {{ col.value }}
                                        </template>
                                        <template v-if="'count' === col.type">
                                            {{ col.value }}
                                        </template>
                                        <template v-if="'average' === col.type">
                                            {{ col.value }}
                                        </template>
                                    </td>
                                </template>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </transition>
            </template>
            <template v-else>
                <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="out-in">
                    <table class="list" id="printable-scoreOverview">
                        <thead>
                        <tr class="list-header">
                            <th>&nbsp;</th>
                            <th style="text-align: left;"><strong>Liste / Klassenarbeit</strong></th>
                            <th v-if="showPoints" style="text-align: right;"><strong>Punkte</strong></th>
                            <th style="text-align: right;"><strong>Note(n)</strong></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="( col, index ) in setup.columns"
                                  :key="'tr-col-'+index">
                            <tr :class="col.color">
                                <td :class="col.color" style="text-align: right;"><strong>{{
                                        col.rawTimestamp
                                    }}</strong><!--<pre>{{ col.values }}</pre>--></td>
                                <td :class="col.color" style="text-align: left;"><strong>{{ col.rawName }}</strong>
                                    <template v-if="showListType"><br/><small>{{
                                            col.listType
                                        }}</small></template>
                                </td>
                                <td :class="col.color" v-if="showPoints" style="text-align: right;">
                                    <strong>{{
                                            undefined !== preparedFlat[ col.key ].points ? preparedFlat[ col.key ].points.points : ''
                                        }}</strong>
                                </td>
                                <td :class="col.color" style="text-align: right;">
                                    <template v-if="'test' !== col.rawListType">
                                        <template v-for="(head, idx) in col.scores.columns"
                                                  :key="'tpl-subcol-'+idx">
                                            {{ head.raw.caption }}: <strong>{{ col.values[ $core.getSanitizers().fieldId( filter, head.raw.caption, head.id ) ] || '-' }}</strong><br/>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <strong>{{ preparedFlat[ col.key ].score.score }}</strong>
                                    </template>
                                </td>
                            </tr>
                            <tr v-if="showComments
                              && undefined !== preparedFlat[col.key].comment"
                                :class="col.color">
                                <td :class="col.color">&nbsp;</td>
                                <td :class="col.color" style="text-align: left;"
                                    v-html="preparedFlat[ col.key ].comment.comment || '- kein Kommentar'"></td>
                                <td :class="col.color" v-if="showPoints">&nbsp;</td>
                                <td :class="col.color">&nbsp;</td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </transition>
            </template>
        </div>
    </div>
</template>

<script>

export default {

    name: 'ListScoresOverview',

    props: {
        setup          : { type: Object, required: true },
        filter         : { type: String, required: false },
        changeKey      : { type: String, required: false, default: '' },
        objectReference: { type: Object, required: false },
    },

    data()
    {
        return {
            ready       : false,
            preparedRows: null,
            preparedFlat: null,
            colLayout   : null,
            hideCaptions: false,
            totalScores : {},
            showComments: this.$core.settings().getSetting( 'scoreOverviewShowComments' ),
            showPoints  : this.$core.settings().getSetting( 'scoreOverviewShowPoints' ),
            showListType: this.$core.settings().getSetting( 'scoreOverviewShowListType' )
        }
    },

    watch: {
        changeKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( oldValue !== undefined
                    && newValue !== oldValue )
                {
                    this.prepare()
                }
            }
        }
    },

    created()
    {
        this.prepare()
        this.$core.getEventManager().add( 'print-view', () =>
        {
            this.handleExport( 'pdf' )
        } )
        this.$core.getEventManager().add( 'excel-view', () =>
        {
            this.handleExport( 'xls' )
        } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager().remove( 'print-view' )
        this.$core.getEventManager().remove( 'excel-view' )
    },

    /*eslint-disable*/
    methods: {

        _hasCommentCol( cols )
        {
            for( let c in cols )
            {
                if( 'testcomment' === cols[ c ].raw.type )
                {
                    return true
                }
            }
            return false
        },

        _countScoresPossible( cols )
        {
            let possible = 0
            for( let c in cols )
            {
                if( 'testItem' === cols[ c ].raw.type )
                {
                    possible++
                }
            }
            return possible
        },

        _displayValue( model, score )
        {

            for( let m in model )
            {
                if( undefined === model[ m ].short )
                {
                    return score
                }
                if( model[ m ].number === score )
                {
                    return model[ m ].short
                }

            }

            return ''

        },

        prepare()
        {

            let prepared  = [],
                allModels = this.$core.t( 'scoreModels' )

            this.ready = false
            this.preparedRows = null
            this.preparedFlat = {}
            this.colLayout = []
            this.totalScores = {}

            for( let r in this.$props.setup.rows )
            {

                let row     = this.$props.setup.rows[ r ],
                    localId = row.localId,
                    prep    = [],
                    count   = 0,
                    total   = 0

                for( let c in this.$props.setup.columns )
                {

                    let col = this.$props.setup.columns[ c ]
                    if( undefined === this.totalScores[ col.key ] )
                    {
                        let total = 0
                        for( let s in col.scores.columns )
                        {
                            let scoreCol = col.scores.columns[ s ]
                            if( undefined !== scoreCol.raw
                                && undefined !== scoreCol.raw.score )
                            {
                                total += this.$core.getReformatter().float( scoreCol.raw.score )
                            }
                        }

                        if( 0 !== total )
                        {
                            this.totalScores[ col.key ] = {
                                type : 'total',
                                value: total
                            }
                        }
                    }
                }

                if( undefined === this.filter || localId === this.filter )
                {

                    prep.push( {
                        type     : 'student',
                        firstname: row.firstname,
                        lastname : row.lastname,
                        archived : row.archived,
                        localId  : localId
                    } )

                    for( let c in this.$props.setup.columns )
                    {

                        let col = this.$props.setup.columns[ c ]
                        this.preparedFlat[ col.key ] = {}
                        switch( col.scores.type )
                        {
                            case 'fixed':
                                for( let cc in col.scores.columns )
                                {

                                    let column   = col.scores.columns[ cc ],
                                        valueKey = localId + '___' + column.raw.key,
                                        values   = this.$core.f().isset( col.values ) ? col.values : {},
                                        value    = this.$core.f().isset( values[ valueKey ] ) ? this.$core.getReformatter().float( values[ valueKey ] ) : null

                                    prep.push( {
                                        type : 'score',
                                        score: value
                                    } )

                                    this.preparedFlat[ col.key ].score = {
                                        type : 'score',
                                        score: value
                                    }

                                    /** tbd
                                     if( undefined === this.totalScores[ col.key ] )
                                     {
                                    this.totalScores[ col.key ] = {
                                        type : 'max',
                                        value: 0
                                    }
                                }

                                     if( value > this.totalScores[ col.key ].value )
                                     {
                                    this.totalScores[ col.key ] = {
                                        type : 'max',
                                        value: value
                                    }
                                } **/

                                    if( null !== value )
                                    {
                                        count++
                                        total += value
                                    }
                                }
                                this.colLayout[ c ] = col.scores.columns.length
                                break
                            case 'test':
                                let colCount   = 1,
                                    valuesIn   = 0,
                                    points     = null,
                                    scoreCol   = null,
                                    commentCol = null

                                for( let cc in col.scores.columns )
                                {

                                    let testColumn = col.scores.columns[ cc ],
                                        valueKey   = localId + '___' + this.$core.getSanitizers().cleanId( testColumn.raw.caption ) + '___' + testColumn.id,
                                        values     = this.$core.f().isset( col.values ) ? col.values : {},
                                        value      = this.$core.f().isset( values[ valueKey ] ) ? this.$core.getReformatter().float( values[ valueKey ] ) : null

                                    if( null !== value
                                        && 'testItem' === testColumn.raw.type )
                                    {
                                        valuesIn++
                                        scoreCol = null !== scoreCol ? ( scoreCol + parseFloat( value ) ) : parseFloat( value )
                                    }
                                    if( null !== value
                                        && 'testcomment' === testColumn.raw.type )
                                    {
                                        commentCol = values[ valueKey ]
                                    }
                                    if( null !== value
                                        && 'testItem' === testColumn.raw.type )
                                    {
                                        points = null === points ? 0 : points
                                        points += this.$core.getReformatter().float( testColumn.raw.score )
                                    }

                                }

                                let scoreCalculated = this.$core.getTestScoreCalculation()
                                                          .calculateScoreByModel( scoreCol, col.distribution, col.scoreModel ),
                                    incomplete      = this._countScoresPossible( col.scores.columns ) !== valuesIn

                                if( !incomplete )
                                {
                                    count++
                                    total += parseFloat( scoreCalculated.number )
                                }

                                if( null !== points )
                                {
                                    this.preparedFlat[ col.key ].points = {
                                        type  : 'points',
                                        points: ( undefined !== scoreCol
                                                  && null !== scoreCol ? this.$core.getReformatter().localizedFloat( scoreCol, 1 ) + ' Punkt' + ( 1 < scoreCol ? 'e' : '' ) : '' )
                                    }
                                }

                                prep.push( {
                                    type : 'points',
                                    value: ( undefined !== scoreCol
                                             && null !== scoreCol ? this.$core.getReformatter().localizedFloat( scoreCol, 1 ) + ' Punkt' + ( 1 < scoreCol ? 'e' : '' ) : '' )
                                } )

                                prep.push( {
                                    type : 'score',
                                    score: incomplete ? null : this._displayValue( allModels[ col.scoreModel ], scoreCalculated.number )
                                } )

                                this.preparedFlat[ col.key ].score = {
                                    type : 'score',
                                    score: incomplete ? null : this._displayValue( allModels[ col.scoreModel ], scoreCalculated.number )
                                }

                                if( this._hasCommentCol( col.scores.columns ) )
                                {
                                    prep.push( {
                                        type   : 'comment',
                                        comment: commentCol
                                    } )
                                    this.preparedFlat[ col.key ].comment = {
                                        type   : 'comment',
                                        comment: commentCol
                                    }

                                    colCount++
                                }


                                colCount++

                                this.colLayout[ c ] = colCount
                                break

                        }

                    }

                    prep.push( {
                        type : 'count',
                        value: count
                    } )
                    prep.push( {
                        type : 'average',
                        value: 0 < count ? this.$core.getReformatter().reformat( ( total / count ), 'localizedFloat' ) : '---'
                    } )

                    prepared.push( prep )

                }

            }

            this.preparedRows = prepared
            this.ready = true

        },

        handleExport( type )
        {

            let titleAppend = ''

            if( undefined !== this.$props.objectReference
                && undefined !== this.$props.objectReference.displayName )
            {
                titleAppend = this.$props.objectReference.displayName
            }

            let title = 'Notenübersicht' + ( titleAppend ? ' - ' + titleAppend : '' )

            let fakeElm = {
                localId  : 'scoreOverview',
                type     : 'scoreOverview',
                listname : title,
                color    : 'white',
                timestamp: Date.now()
            }

            switch( type )
            {
                case 'pdf':
                    this.$core.getPrinting().printPlainElement( fakeElm, true )
                    break
                case 'xls':
                    this.$core.getExcel()
                        .export( fakeElm, 'printable-' + fakeElm.localId )
                    break
            }

        }

    }

}
</script>