<template>
    <div class="message-wrapper">
        <InlineMessage :active="errors !== false && true === elevated"
                       key="message-inline-form"
                       classname="form-error"
                       :title="$core.getTranslation().translate( 'form-error-generic-heading' )"
                       :message="$core.getTranslation().translate( 'form-error-generic-message' )"/>
        <InlineMessage v-if="false !== messages"
                       :active="messages !== false && true === elevated"
                       key="message-inline-form"
                       classname="form-warning"
                       :title="messages.title"
                       :message="messages.message"/>
    </div>
</template>

<script>
import InlineMessage from '@/components/messages/InlineMessage'
export default {

    name: 'FormMessages',
    components: { InlineMessage },
    props: {
        errors: { Type: Boolean, required: true },
        messages: { Type: Boolean|Object, required: true },
        elevated: { Type: Boolean, required: true }
    }
}
</script>