<script>
export default {

    name: "MixinPlannerValidation",
    /*eslint-disable*/
    methods: {

        organizerSlotHasLabel( slot, organizerReference )
        {

            let organizer = this.$core.getBaseClassHelper()
                                .get( 'organizer' )
                                .getById( organizerReference )

            if( this.$core.f().validObject( organizer ) )
            {

                let dayIndex = new Date( slot.date ).getDay()
                if( undefined !== organizer.labels[dayIndex][slot.slotIndex] )
                {
                    return organizer.labels[dayIndex][slot.slotIndex]
                }

            }

            return false

        }

    }

}
</script>