import AbstractShadowCopyClass
    from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/abstract/AbstractShadowCopyClass";

export default class CopyClass extends AbstractShadowCopyClass
{

    constructor( parent )
    {

        super( parent, false, 'class', 'CopyClass' )
        return this

    }

    create( job )
    {

        return new Promise( ( resolve, reject ) =>
        {

            if( undefined === job.access )
            {
                return reject()
            }

            this.prepareShadowCopyElement( job )
                .then( shadowCopy =>
                {

                    delete shadowCopy.students
                    shadowCopy.students = [ 'shad-' +
                                            job.access.studentLocalId + '-' +
                                            this.parent.f.hashCyrB53( job.access.studentLocalId ) ]

                    this.encryptShadowCopyElement( job, shadowCopy )
                        .then( result => {

                            return resolve( { plain: shadowCopy, db: result } )

                        })
                        .catch( () => {
                            return reject()
                        })

                } )

        } )

    }

}
