<template>
    <div class="list-element rateselector">
        <template v-if="$core.f().isset( value )">
            <span v-for="n in parseInt( $core.settings().getSetting( 'ratingStars' ) )"
                  :key="'key_rateSel_'+'_'+n"
                  :class="'star '+( n <= parseInt( value ) ? 'selected' : 'inactive' )+' disabled display'"
                  v-html="( n <= parseInt( value ) ? '&#9733;' : '&#9734;' )"></span>
        </template>
        <template v-else>
            <span v-for="n in parseInt( $core.settings().getSetting( 'ratingStars' ) )"
                  :key="'key_rateSel_'+'_'+n"
                  class="star inactive disabled display">&#9734;</span>
        </template>
    </div>
</template>

<script>
export default {
    name : 'listElement-rateselector',
    props: {
        value   : { type: [Number,String], required: false, default: 0 }
    }
}
</script>
