<template>
    <div>
        <div class="testitem left">
            <TextBox @update="handleUpdate"
                     @backspacePressed="$emit( 'backspacePressed', getRefName(1) )"
                     @enterPressed="scoreFocus()"
                     :disabled="disabled"
                     :id="'caption-'+getRefName(0)"
                     :value="item"
                     :placeholder="'Aufgabe'"/>
        </div>
        <div class="testitem right">
            <TextBox @update="handleUpdate"
                     @backspacePressed="captionFocus()"
                     @enterPressed="$emit( 'enterPressed', getRefName(1) )"
                     className="testpart"
                     :disabled="disabled"
                     :id="'score-'+getRefName(1)"
                     :value="score"
                     :placeholder="'Punkte'"/>
        </div>
        <input type="hidden" :id="'form-element-'+getRefName()" :value="hiddenValue"/>
    </div>
</template>

<script>

import TextBox from '@/components/form/elements/TextBox'

export default {
    name      : 'TestPart',
    components: { TextBox },

    props: {
        id            : { Type: String, required: true },
        component     : { Type: String, required: false },
        refName       : { Type: String, required: false },
        ruleSet       : { Type: String, required: false },
        allValues     : { Type: Object, required: false },
        value         : { Type: String, required: false },
        disabled      : { Type: Boolean, required: false, default: false },
        display       : { Type: String, required: false },
        reformatter   : { Type: String, required: false },
        validator     : { Type: String, required: false },
        eventKey      : { Type: String, required: false },
        idType        : { Type: String, required: false },
        unique        : { Type: Boolean, required: false },
        changeKey     : { Type: String, required: false },
        ignoreClassId : { Type: Boolean, required: false },
        extendedFilter: { Type: String, required: false },
        allowForeign  : { Type: Boolean, required: false },
        editItem      : { Type: Object, required: false }
    },

    emits: [ 'update', 'enterPressed', 'backspacePressed', 'selected', 'adderValid', 'adderInvalid', 'extendedFilterSelection' ],

    data()
    {
        return {
            item       : '',
            score      : '',
            changeTimer: false,
            hiddenValue: ''
        }
    },

    watch: {
        value: {
            handler( value )
            {
                if( this.$core.f().isset( value.type )
                    && this.$core.f().isset( value.value ) )
                {
                    let temp = value.value.split( '|||' )

                    if( 1 < temp.length )
                    {
                        let parsed = JSON.parse( temp[ 1 ] )
                        if( null !== parsed )
                        {
                            this.hiddenValue = value.type + '|||' + JSON.stringify( {
                                caption: parsed.caption,
                                score  : parsed.score
                            } )
                            this.item = parsed.caption
                            this.score = parsed.score
                        }
                    }
                }
            }
        }
    },

    created()
    {
        this.updateValue()
    },

    mounted()
    {
        this.handleUpdate()
    },

    methods: {

        /*eslint-disable*/
        handleUpdate( id, type, method, elmId, value, fillOnly )
        {

            let scoreElm = document.querySelector( '#form-element-score-' + this.getRefName( 1 ) )
            let captionElm = document.querySelector( '#form-element-caption-' + this.getRefName( 0 ) )

            if( null !== scoreElm && null !== captionElm )
            {
                this.hiddenValue = 'testPart|||' + JSON.stringify( {
                    caption: captionElm.value,
                    score  : scoreElm.value
                } )

            }
            else
            {
                this.hiddenValue = 'testPart|||null'
            }
            if( undefined === fillOnly )
            {
                this.$emit( 'update', this.$props.refName, 'testPart', 'update', undefined, {
                    type : 'testPart',
                    value: this.hiddenValue
                } )
            }
        },

        scoreFocus()
        {
            document.querySelector( '#form-element-score-' + this.getRefName( 1 ) ).focus()
        },

        captionFocus()
        {
            document.querySelector( '#form-element-caption-' + this.getRefName( 0 ) ).focus()
        },

        updateValue()
        {
            if( this.$core.f().isset( this.$props.value ) )
            {
                this.item = this.$props.value.caption
                this.score = this.$props.value.score
            }
        },

        getRefName( id )
        {

            let temp = this.$props.refName.split( '-' )
            let ref = temp[ 1 ].split( '___' )

            if( undefined !== id )
            {
                return temp[ 0 ] + '-' + ref[ id ] + '-' + temp[ 2 ]
            }

            return temp[ 0 ] + '-combined-' + temp[ 2 ]

        }

    }

}
</script>