import TestStructureUpdates from "@/objects/abstract/helpers/TestStructureUpdates";

export default class ListStructureUpdates
{

    constructor( parent, core )
    {

        this.parent = parent

        this.f = core.f()
        this.logger = core.getLogger()
        this.uuid = core.getUuid()
        this.sanitizers = core.getSanitizers()

        this.logSign = 'AbstractListClass::ListStructureUpdates::'
        this.testStructureUpdates = new TestStructureUpdates( parent, core )
        this.logger.clog( this.logSign + 'constructor', 'initialized' )

        return this

    }

    /**
     * getActualValues
     * - parse actual list versions for filled values
     * @param lists
     * @returns {{}}
     */
    getActualValues( lists )
    {

        let valueSets = {}
        for( let l in lists )
        {
            valueSets[ lists[ l ].localId ] = lists[ l ].values
        }
        return valueSets

    }

    /**
     * getFixedColumnType
     * - check the resulting column type for fixed list templates (checklist / ratinglist / ...)
     * @param object
     * @returns {boolean}
     */
    getFixedColumnType( object )
    {

        let formFields      = this.parent.listFormFields.get( object.listType ),
            fixedColumnType = undefined

        for( let f in formFields )
        {
            if( formFields[ f ].ref === 'columns'
                || true === formFields[ f ].isColumnsDefinition )
            {
                if( undefined !== formFields[ f ].resultingType )
                {
                    fixedColumnType = formFields[ f ].resultingType
                }
            }
        }

        return fixedColumnType

    }

    /**
     * getColumns
     * @param values
     */
    getColumns( values )
    {
        if( Array.isArray( values.columns ) )
        {
            return values.columns
        }
        return []
    }

    /**
     * prepareNewVersion
     * - prepares the new version of the lists structure
     * @param values
     * @param historic
     * @param fixedColumnType
     * @returns {{transmission: {}, newColumns: *[], fieldIds: *[]}}
     */
    prepareNewVersion( values, historic, fixedColumnType )
    {

        let transmission = {},
            fieldIds     = [],
            newColumns   = [],
            columns      = this.getColumns( values )

        for( let c in columns )
        {

            let column     = columns[ c ],
                columnId   = false,
                skip       = true,
                colObject  = false,
                columnType = false,
                caption    = undefined

            if( 'object' === typeof ( column )
                || '' !== column.trim() )
            {

                skip = false
                colObject = column
                columnType = fixedColumnType || colObject.type
                caption = colObject.value

                if( this.f.valid( caption ) && this.f.valid( columnType ) )
                {

                    columnId = c + '_' + this.sanitizers.cleanId( caption.trim() )
                    let history = undefined !== colObject._historic ? historic[ colObject._historic ] : undefined

                    if( undefined !== history
                        && this.f.isObject( history )
                        && 'NEW_ROW' !== history.set )
                    {

                        let oldIdentifier = this.sanitizers.cleanId( history.set.value ) + '___' + ( parseInt( history.id ) + 1 ),
                            newIdentifier = this.sanitizers.cleanId( caption.trim() ) + '___' + ( parseInt( c ) + 1 )

                        transmission[ oldIdentifier ] = newIdentifier
                        fieldIds.push( newIdentifier )

                    }
                    else
                    {
                        fieldIds.push( this.sanitizers.cleanId( caption.trim() ) + '___' + ( parseInt( c ) + 1 ) )
                    }

                }
                else
                {
                    skip = true
                }

            }

            if( !skip )
            {
                newColumns.push( {
                    type   : columnType,
                    id     : columnId,
                    caption: caption
                } )
            }

        }

        return {

            transmission: transmission,
            fieldIds    : fieldIds,
            newColumns  : newColumns

        }

    }

    /**
     * prependFixedFilterColumn
     * @param list
     * @param values
     * @param newColumns
     * @returns {boolean}
     */
    prependFixedFilterColumn( list, values, newColumns )
    {

        if( list.columns[ 0 ].type === 'fixed' )
        {

            let columnDef = values.selectedClass.split( ':' ),
                newColumn = JSON.parse( JSON.stringify( list.columns[ 0 ] ) )

            if( newColumn.values === 'students' )
            {
                if( 1 === columnDef.length )
                {
                    newColumn.filterBy = columnDef[ 0 ]
                    newColumn.filter = 'class'
                }
                else
                {
                    newColumn.filterBy = columnDef[ 1 ]
                    newColumn.filter = columnDef[ 0 ]
                }
            }

            newColumns.unshift( newColumn )

        }

    }

    /**
     * prepareValues
     * - prepares the new value sets for the updated lists
     * @param list
     * @param valueset
     * @param transmission
     * @param fieldIds
     * @returns {{}}
     */
    prepareValues( list, valueset, transmission, fieldIds )
    {

        let values = {},
            done   = []

        for( let t in transmission )
        {

            let target = transmission[ t ],
                source = t

            for( let v in valueset )
            {

                let temp = v.split( '___' ),
                    id   = temp.shift(),
                    key  = temp.join( '___' )

                if( key === source )
                {
                    values[ id + '___' + target ] = valueset[ v ]
                    done.push( v )
                }

            }

        }

        if( this.f.valid( valueset ) )
        {
            for( let vv in Object.keys( valueset ) )
            {

                let key = Object.keys( valueset )[ vv ]
                if( -1 === done.indexOf( key ) )
                {
                    let temp = key.split( '___' )
                    temp.shift()
                    let check = temp.join( '___' )
                    if( undefined !== fieldIds
                        && -1 < fieldIds.indexOf( check ) )
                    {
                        values[ key ] = valueset[ key ]
                    }
                }
            }
        }

        return values

    }

    /*eslint-disable*/
    /**
     * prepareLists
     * - prepares an array of listobjects for the resulting element updates
     * @param object
     * @param newColumns
     * @param valueSets
     * @param transmission
     * @param fieldIds
     * @returns {*[]}
     */
    prepareLists( values, object, newColumns, valueSets, transmission, fieldIds )
    {

        let preparedLists = []
        for( let l in object.lists )
        {

            let newList = JSON.parse( JSON.stringify( object.lists[ l ] ) )
            newList.columns = newColumns
            newList.values = this.prepareValues( newList, valueSets[ newList.localId ], transmission, fieldIds )

            for( let v in values )
            {
                switch( v )
                {
                    case 'storeAsTemplate':
                    case 'preSelectedClass':
                    case 'referenceKey':
                    case 'forceTimestamp':
                    case 'columns':
                    case 'values':
                    case 'item___score':
                        break
                    default:
                        newList[ v ] = values[ v ]
                        break
                }
            }

            delete newList.fieldHistory
            preparedLists.push( newList )

        }

        return preparedLists

    }

    /**
     * updateStructure
     * - outsourced structural updater
     * @param values
     * @param historic
     * @param object
     * @returns {*[]}
     */
    updateStructure( values, historic, object )
    {

        let listType  = object.listType,
            valueSets = this.getActualValues( object.lists ),
            lists

        this.logger.clog( this.logSign + listType + ':updateStructure', 'updating list structure of #' + object.localId )

        if( 'test' === listType )
        {

            let newVersion = this.testStructureUpdates
                                 .prepareNewVersion( values, historic, object.lists[0] )

            this.prependFixedFilterColumn( object.lists[ 0 ], values, newVersion.newColumns )

            lists = this.prepareLists(
                values,
                object,
                newVersion.newColumns,
                valueSets,
                newVersion.transmission,
                newVersion.fieldIds
            )

        }
        else
        {

            let fixedColumnType = this.getFixedColumnType( object ),
                newVersion      = this.prepareNewVersion( values, historic, fixedColumnType )

            this.prependFixedFilterColumn( object.lists[ 0 ], values, newVersion.newColumns )

            lists = this.prepareLists(
                values,
                object,
                newVersion.newColumns,
                valueSets,
                newVersion.transmission,
                newVersion.fieldIds
            )

        }

        return lists

    }

}