<template>
    <th v-for="( head, index ) in headers"
        :key="'th-calc-'+index+'-'+viewKey">
        <template v-if="'sum' === head.type">
            <span class="rotated">&sum; {{ head.label }}</span>
        </template>
        <template v-if="'count' === head.type">
            <span class="rotated">{{ head.label }}</span>
        </template>
        <template v-if="'average' === head.type">
            <span class="rotated">&oslash; {{ head.label }}</span>
        </template>
    </th>
</template>

<script>
export default {

    name : "CalculationTableHeaders",
    props: {
        list   : { Type: Object, required: true },
        viewKey: { Type: String, required: true }
    },

    emits: [ 'count' ],

    data()
    {
        return {
            headers: []
        }
    },

    mounted()
    {
        this.prepare()
    },

    watch: {
        viewKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.prepare()
                }
            }
        }
    },

    methods: {
        prepare()
        {

            this.headers = []
            let list = undefined !== this.$props.list.lists ? this.$props.list.lists[ 0 ] : this.$props.list
            if( this.$core.f().valid( list.calculatedColumns )
                && 'create' !== list.calculatedColumns )
            {

                for( let c in list.calculatedColumns )
                {
                    this.headers.push( {
                        type : list.calculatedColumns[ c ].calcType,
                        label: list.calculatedColumns[ c ].label
                    } )
                }

                this.$emit( 'count', list.calculatedColumns.length )

            }

        }
    }

}
</script>