<script>
export default {

    name: "MixinContainerUpdateTracker",

    data()
    {
        return {
            rollingKeys: {}
        }
    },

    watch: {
        elementKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {

                    console.log( 'SHR >> N', newValue, 'O', oldValue )

                    for( let i in this.$props.item.lists )
                    {

                        if( undefined !== this.rollingKeys[ this.$props.item.lists[ i ].localId ]
                            && this.rollingKeys[ this.$props.item.lists[ i ].localId ] !== this.$props.item.lists[ i ].elementKey )
                        {
                            this.setDataUpdate( i )
                        }
                        this.rollingKeys[ this.$props.item.lists[ i ].localId ] = this.$props.item.lists[ i ].elementKey

                    }
                }
            }
        }
    },

    methods: {

        setDataUpdate( index )
        {

            console.log( 'SHR >> DATAUPDATE FOR', index )
            let listItem    = this.$props.item.lists[ index ],
                localId     = listItem.localId,
                timestamp   = listItem.timestamp,
                listElement = document.querySelector( '#tr-' + localId + '-' + timestamp )

            if( null !== listElement )
            {

                listElement.classList.add( 'data-updated' )
                this.$core.getUi()
                    .delay( () =>
                    {
                        listElement.classList.remove( 'data-updated' )
                    }, 4000 )

            }
        }

    }

}
</script>