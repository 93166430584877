<template>
    <div key="distri-austria" class="distribox">
        <div v-for="k in distributionKeys" :key="'key_distri_'+k"
             class="scorebox at">
            <div class="ratinglabel">
                <strong>{{ ( parseInt( k ) + 1 ) }}</strong>
            </div>
            <div class="scoreinput">
                <input v-if="( k + 1 ) === distributionKeys.length" type="text" class="score"
                       placeholder="Pkt." :id="'ratio_'+k"
                       value="0" disabled="disabled" @keyup="checkKey( $event, k )"/>
                <input v-else type="text" class="score" placeholder="Pkt." :id="'ratio_'+k"
                       :value="distributionScores[k]" @keyup="checkKey( $event, k )"/>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="scorebox at scoretotal">
            <div class="ratinglabel scoretotal at">
                <strong>&#x2211;</strong>
            </div>
            <div class="scoreinput scoretotal">
                <strong>{{ $core.getReformatter().reformat( totalScore, 'localizedScore' ) }}</strong>
            </div>
        </div>
        <div class="clearfix"></div>
        <input type="hidden" :id="'form-element-'+refName" @prefill="prefill"/>
    </div>
</template>

<script>

import TestDistribution from '@/components/form/elements/tests/distributionKeys/TestDistribution'

export default {
    name   : 'TestDistributionAustria',
    extends: TestDistribution,
    props  : [ 'value', 'refName', 'reference', 'validator', 'reformatter', 'display', 'editor', 'scores' ],

    data()
    {
        return {
            distributionKeys   : [],
            distributionScores : [],
            refValue           : '',
            totalScore         : 0,
            distributionChanged: false,
            changeTimer        : false
        }
    },

    created()
    {
        this.initKeys()
        if( undefined !== this.$props.scores )
        {
            this.initScores()
        }
    },

    beforeUnmount()
    {
        this.$emit( 'componentChanged' )
    },

    methods: {

        prefill( event )
        {

            let newValue = event.target.value
            let test = newValue.split( ',' )

            if( 0 < test.length )
            {
                let noVals = false
                if( test[ 0 ] === 'RETRIGGER' )
                {
                    test.shift()
                    noVals = true
                }
                if( !noVals )
                {
                    this.$emit( 'values', test )
                }

                for( let t in test )
                {
                    let id = 'ratio_' + t
                    let elm = document.querySelector( '#' + id )
                    if( null !== elm )
                    {
                        this.distributionScores[ t ] = test[ t ]
                        elm.value = test[ t ]
                    }
                }
            }
        },

        getRefName( id )
        {
            let ref = this.$props.refName.split( ',' )
            return ref[ id ]
        },
        initKeys()
        {

            for( let i = 0; i < 5; i++ )
            {
                this.distributionKeys.push( i )
                this.distributionScores.push( i < 4 ? undefined : 0 )
            }

        },

        initScores()
        {

            for( let i = 0; i < 5; i++ )
            {
                this.distributionScores[ i ] = this.$props.scores[ i ]
            }

        }

    }

}
</script>