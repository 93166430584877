<template>
    <TutorialMessage :caption="$core.t( 'tutorial-caption-dashboard' )"
                     :text="$core.t( 'tutorial-text-dashboard-'+step )"
                     :step="step"
                     :last="last"
                     :backBlocked="backBlocked"
                     @back="handleBack()"
                     @next="handleNext()"/>
</template>

<script>
import TutorialMessage from '@/components/elements/tutorial/TutorialMessage'
import TutorialBase    from '@/components/elements/tutorial/TutorialBase'

export default {

    name      : 'TutorialDashboard',
    components: { TutorialMessage },
    extends   : TutorialBase,

    data()
    {
        return {
            last: 8
        }
    },

    methods: {

        prepareHighlights()
        {
            this.highlights = []
            switch( this.step )
            {
                case 1:
                    break
                case 2:
                    this.highlights = [
                        {
                            root    : '#icon-root',
                            elements: [ '#icon-outer-students' ],
                            scroll  : true
                        }
                    ]
                    break
                case 3:
                    this.highlights = [
                        {
                            root    : '#icon-root',
                            elements: [ '#icon-outer-classes' ],
                            scroll  : true
                        }
                    ]
                    break
                case 4:
                    this.highlights = [
                        {
                            root    : '#icon-root',
                            elements: [ '#icon-outer-office' ],
                            scroll  : true
                        }
                    ]
                    break
                case 5:
                    this.highlights = [
                        {
                            root    : '#icon-root',
                            elements: [ '#icon-outer-planner' ],
                            scroll  : true
                        }
                    ]
                    break
                case 6:
                    this.highlights = [
                        {
                            root    : '#icon-root',
                            elements: [ '#icon-outer-colleagues' ],
                            scroll  : true
                        }
                    ]
                    break
                case 7:
                    this.highlights = [
                        {
                            root    : '#icon-root',
                            elements: [ '#icon-outer-messages' ],
                            scroll  : true
                        }
                    ]
                    break
                case 8:
                    this.highlights = [
                        {
                            root    : '#icon-root',
                            elements: [ '#icon-outer-settings' ],
                            scroll  : true
                        }
                    ]
                    break
            }

        }

    }

}
</script>