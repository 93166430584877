export default class LazyObjectHandlerClass
{
    constructor( core )
    {

        if( !LazyObjectHandlerClass.instance )
        {

            this.logger = core.getLogger()
            this.eventManager = core.getEventManager()
            this.logger.clog( 'LazyObjectHandlerClass', 'initializing...' )
            this.webWorkers = core.ww()

            LazyObjectHandlerClass.instance = this

            this.eventManager.dispatch( 'onObjectHasherReady', this )

        }

        return LazyObjectHandlerClass.instance

    }

    objectHash( item )
    {
        return new Promise( resolve => {

            this.webWorkers.call( 'ObjectHasher', 'objectHash', JSON.parse( JSON.stringify( item ) ) )
                .then( result =>
                {

                    return resolve( result )

                } )

        })
    }

}
