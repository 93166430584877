export default class InbandTriggerHandler
{
    constructor( core )
    {
        
        if( !InbandTriggerHandler.instance )
        {
            
            this.core = core
            this.storeClass = core.getStore()
            this.eventManager = core.getEventManager()
            this.logger = core.getLogger()
            this.colleagues = {}
            
            InbandTriggerHandler.instance = this
            
        }
        
        return InbandTriggerHandler.instance
        
    }
    
    notifyEditorStart( data )
    {
        this.core.setState( 'colleague-editing-' + data.sender, data.payload )
        this.core.setState( 'colleague-editor-open-' + data.payload, data.sender )
        this.eventManager.dispatchDirect( 'notify-colleague-editor-start-' + data.payload, data.sender )
    }
    
    notifyEditorClose( data )
    {
        this.core.deleteState( 'colleague-editing-' + data.sender )
        this.core.deleteState( 'colleague-editor-open-' + data.payload )
        this.eventManager.dispatchDirect( 'notify-colleague-editor-end-' + data.payload, data.sender )
    }
    
    notifyEditorFocus( data )
    {
        this.eventManager.dispatchDirect( 'notify-colleague-editor-focus-' + data.payload, {
            sender    : data.sender,
            additional: data.additional
        } )
    }
    
    notifyEditorBlur( data )
    {
        this.eventManager.dispatchDirect( 'notify-colleague-editor-blur-' + data.payload, {
            sender    : data.sender,
            additional: data.additional
        } )
    }
    
    
    notifyEditorValue( data )
    {
        this.eventManager.dispatchDirect( 'notify-colleague-editor-value-' + data.payload, {
            sender    : data.sender,
            additional: data.additional
        } )
    }
    
    
}