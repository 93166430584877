<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-show="visible" class="default-form-overlay default-camera-overlay">
            <div class="default-form-container">
                <div class="default-form-wrapper">
                    <Button type="close"
                            @clicked="handleClose()"
                            addClass="absolute top right inverted"/>
                    <Button v-if="1 < cameras.length"
                            type="switch-camera absolute top right-60"
                            @click="switchCamera"/>
                    <h2>{{ setup.caption || 'Dein Avatar' }}</h2>
                    <div class="form relative">
                        <div class="camera-wrapper">
                            <div class="camera">
                                <video id="video">Video stream not available.</video>
                            </div>
                        </div>
                        <canvas id="output" class="hidden">
                        </canvas>
                        <button id="startbutton" @click="takePicture">Foto machen</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {

    name : 'DefaultCameraOverlay',
    emits: [ 'close' ],
    props: {
        readyKey: { type: String, required: true },
        setup   : { type: Object, required: true }
    },

    data()
    {
        return {
            cameras         : [],
            deviceIdx       : -1,
            visible         : true,
            streaming       : false,
            height          : 0,
            width           : 0,
            picture         : false,
            stream          : false,
            needsReEnumerate: true,
            maxLongSide     : 1920,
            maxShortSide    : 1440
        }
    },

    created()
    {

    },
    /*eslint-disable*/
    mounted()
    {

        if( navigator.mediaDevices.enumerateDevices )
        {
            this.enumerate()
        }
        else
        {
            this.init()
        }

    },

    beforeUnmount()
    {
        this.stopStream()
    },

    methods: {

        enumerate()
        {

            this.cameras = []

            navigator.mediaDevices.enumerateDevices()
                     .then( devices =>
                     {

                         devices.forEach( ( device ) =>
                         {
                             switch( device.kind )
                             {
                                 case 'videoinput':
                                     this.cameras.push( device.deviceId )
                                     break
                                 default:
                                     break
                             }

                         } )

                         this.deviceIdx = 0
                         this.init()

                     } )
                     .catch( () =>
                     {
                     } )
        },

        init()
        {

            let video          = document.querySelector( '#video' ),
                canvas         = document.querySelector( '#output' ),
                lastUsedCamera = this.$core.settings().getSetting( 'lastUsedCamera' )

            video.addEventListener( 'canplay', ( ev ) =>
            {

                if( !this.streaming )
                {

                    this.width = video.videoWidth
                    this.height = video.videoHeight / ( video.videoWidth / this.width )
                    let factor = this.width / this.height

                    if( this.width > this.height )
                    {
                        this.width = this.width > this.maxLongSide ? this.maxLongSide : this.width
                        this.height = this.width / factor
                    }
                    else
                    {
                        this.height = this.height > this.maxLongSide ? this.maxLongSide : this.height
                        this.width = this.height * factor
                    }

                    video.setAttribute( 'width', this.width )
                    video.setAttribute( 'height', this.height )
                    canvas.setAttribute( 'width', this.width )
                    canvas.setAttribute( 'height', this.height )

                    this.streaming = true

                }
            }, false )

            let constraints = {
                video: { facingMode: 'user', width: { ideal: this.maxLongSide }, height: { ideal: this.maxShortSide } },
                audio: false
            }

            if( -1 !== lastUsedCamera
                && undefined !== this.cameras[ lastUsedCamera ] )
            {
                this.deviceIdx = lastUsedCamera
            }

            if( -1 !== this.deviceIdx )
            {
                constraints.video = {
                    deviceId: this.cameras[ this.deviceIdx ],
                    width   : { ideal: this.maxLongSide },
                    height  : { ideal: this.maxShortSide }
                }
            }

            navigator.mediaDevices.getUserMedia( constraints )
                     .then( ( stream ) =>
                     {
                         if( this.needsReEnumerate )
                         {
                             this.needsReEnumerate = false
                             this.enumerate()
                             this.init()
                         }
                         else
                         {
                             this.stream = stream
                             video.srcObject = stream
                             video.play()
                         }
                     } )

        },

        handleClose()
        {
            this.visible = false
            this.pinInitialized = false

            setTimeout( () =>
            {
                this.$core.getEventManager()
                    .dispatch( 'on-cameraclose-' + this.$props.readyKey )
                this.$emit( 'close' )
            }, 300 )
        },

        stopStream()
        {

            let video = document.querySelector( '#video' )
            this.stream.getTracks().forEach( function( track )
            {
                track.stop()
            } )
            video.srcObject = null

            this.streaming = false

        },

        takePicture( event )
        {

            event.preventDefault()
            let video   = document.querySelector( '#video' ),
                canvas  = document.querySelector( '#output' ),
                context = canvas.getContext( '2d' )

            if( this.width && this.height )
            {

                canvas.width = this.width
                canvas.height = this.height
                context.drawImage( video, 0, 0, this.width, this.height )

                let data = canvas.toDataURL( 'image/png' )
                this.picture = data

                this.$core.getEventManager()
                    .dispatch( 'on-camera-' + this.$props.readyKey, this.picture )

                this.handleClose()

            }
            else
            {
                this.clearPicture()
            }
        },

        clearPicture()
        {

            let canvas = document.querySelector( '#output' )
            let context = canvas.getContext( '2d' )
            context.fillStyle = '#AAA'
            context.fillRect( 0, 0, canvas.width, canvas.height )

            let data = canvas.toDataURL( 'image/png' )
            this.picture = data
        },

        switchCamera()
        {

            this.stopStream()
            if( this.deviceIdx < ( this.cameras.length - 1 ) )
            {
                this.deviceIdx++
            }
            else
            {
                this.deviceIdx = 0
            }
            this.$core.settings().setSetting( 'lastUsedCamera', this.deviceIdx )
            this.init()

        }

    }
}
</script>

<style scoped>

.default-camera-overlay {
    z-index: 111000;
}

</style>