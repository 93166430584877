export default class SyncCrudPersonalAttributes
{

    constructor( parent )
    {

        this.p = parent
        this.objectHash = ( item ) =>
        {
            return this.p.f.objectHash( item, true )
        }

        this.parent = this.p.parent
        this.changes = false
        this.nthCall = 1
        this.callCount = 0
        this.runOnce = false

        this.pinHash = {
            pinlist : false,
            hidelist: false
        }

        return this

    }

    shouldSync()
    {
        return true
    }

    initialWalk( storage, elements, state )
    {

        this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudPersonalAttributes:initialWalk', 'initial object walk: setting ' + storage + '...' )

        let keys      = this.parent.getBaseClassHelper().shareableQueues,
            stateList = {
                checkPoint: [ 'always-' + state + '-item' ]
            }

        let promises = []

        for( let k in keys )
        {

            let type    = keys[ k ],
                refList = this.parent.getBaseClassHelper().get( type ).refList

            if( undefined !== refList
                && -1 < refList.indexOf( state ) )
            {
                promises.push( () =>
                {

                    if( 'filled' === this.parent.getBaseClassHelper().get( type ) )
                    {
                        return this.parent.getBaseClassHelper().get( type )
                                   .listAll()
                                   .then( list =>
                                   {

                                       stateList[ type ] = []

                                       for( let l in list )
                                       {
                                           if( list[ l ][ state ] === true )
                                           {
                                               stateList[ type ].push( list[ l ].localId )
                                           }
                                       }

                                   } )
                    }
                    else
                    {
                        return new Promise( resolve => {
                            return resolve()
                        })
                    }

                } )
            }

        }

        this.parent.f.promiseRunner( promises )
            .then( () =>
            {

                let keys = Object.keys( stateList )
                for( let k in keys )
                {
                    let key = keys[ k ]
                    if( undefined !== key
                        && undefined !== stateList[ key ] )
                    {
                        this.parent.database.write( storage, key, stateList[ key ] )
                        this.runOnce = true
                    }
                }

            } )

    }

    initialPush( storage, elements, tableName )
    {

        this.parent.cryptoHelper.encrypt( JSON.stringify( elements ) )
            .then( result =>
            {

                let stateString = btoa( JSON.stringify( result ) ),
                    message     = {
                        method   : 'objects.setPersonalAttributes',
                        tableName: tableName
                    }

                message[ tableName ] = stateString

                this.parent.queueWorker.enqueue( 'message', this.parent.uuid.generate(), 'socketMessage', JSON.stringify(
                    message
                ) )

            } )

    }

    replaceStates( storage, key, list )
    {
        return new Promise( resolve =>
        {
            if( '_tsmp' !== key
                && undefined !== key
                && undefined !== list )
            {
                this.parent.database.write( storage, key, list )
                    .then( () =>
                    {
                        return resolve()
                    } )
            }
            else
            {
                return resolve()
            }
        } )
    }

    mergeStates( storage, key, list )
    {
        return new Promise( resolve =>
        {

            this.parent.database.read( storage, key )
                .then( dbStates =>
                {

                    let changes = false

                    for( let l in list )
                    {
                        if( -1 === dbStates.indexOf( list[ l ] ) )
                        {
                            changes = true
                            dbStates.push( list[ l ] )
                        }
                    }

                    if( changes
                        && undefined !== key
                        && undefined !== dbStates )
                    {
                        this.parent.database.write( storage, key, dbStates )
                            .then( () =>
                            {
                                return resolve()
                            } )
                    }
                    else
                    {
                        return resolve()
                    }

                } )
                .catch( error =>
                {

                    if( 'ERROR_NOT_FOUND' === error
                        && undefined !== key
                        && undefined !== list )
                    {
                        this.parent.database.write( storage, key, list )
                            .then( () =>
                            {
                                return resolve()
                            } )
                    }
                    else
                    {
                        return resolve()
                    }

                } )

        } )
    }

    handleSync( storage, stateList, tsmpLocal, tsmpCompare )
    {

        return new Promise( resolve =>
        {

            let keys     = Object.keys( stateList ),
                promises = []

            if( tsmpLocal < tsmpCompare )
            {

                for( let k in keys )
                {
                    let key = keys[ k ]
                    if( 'localKey' !== key )
                    {
                        promises.push( () =>
                        {
                            return this.replaceStates( storage, stateList[ key ].key, stateList[ key ].item )
                        } )
                    }
                }

                promises.push( () =>
                {
                    return this.parent.database.write( storage, '_tsmp', tsmpCompare )
                } )

            }
            else if( tsmpLocal > tsmpCompare )
            {

                for( let k in keys )
                {
                    let key = keys[ k ]
                    if( 'localKey' !== key )
                    {
                        promises.push( () =>
                        {
                            return this.mergeStates( storage, stateList[ key ].key, stateList[ key ].item )
                        } )
                    }
                }
                promises.push( () =>
                {
                    return this.parent.database.write( storage, '_tsmp', tsmpLocal )
                } )

            }

            this.parent.f.promiseRunner( promises )
                .then( () =>
                {
                    return resolve()
                } )

        } )

    }

    performSyncAttributes( which )
    {
        return new Promise( resolve =>
        {

            this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudPersonalAttributes', 'performing sync run for ' + which.storage + '...' )

            this.parent.client.request( {
                    method   : 'objects.getPersonalAttributes',
                    tableName: which.tableName
                } )
                .then( response =>
                {

                    let hash = this.objectHash( response.result )
                    if( hash !== this.pinHash[ which.tableName ] )
                    {
                        this.pinHash[ which.tableName ] = hash
                        this.changes = true
                    }

                    if( 0 === response.result.length )
                    {

                        this.parent.database.readAllObjects( which.storage )
                            .then( elements =>
                            {

                                if( 0 === elements.length )
                                {
                                    this.initialWalk( which.storage, elements, which.state )
                                }
                                else
                                {
                                    this.initialPush( which.storage, elements, which.tableName )
                                }
                                return resolve()

                            } )

                    }
                    else
                    {

                        this.parent.cryptoHelper.decrypt( JSON.parse( atob( response.result[ which.tableName ] ) ) )
                            .then( stateList =>
                            {

                                let tsmpCompare = this.parent.friendlyTimestamp.timestampFromMysql(
                                        this.parent.friendlyTimestamp.convertServerTimestamp( response.result.datetime_created )
                                    ),
                                    tsmpLocal   = 0

                                this.parent.database.read( which.storage, '_tsmp' )
                                    .then( tsmpDb =>
                                    {
                                        tsmpLocal = tsmpDb
                                    } )
                                    .catch( () =>
                                    {
                                        tsmpLocal = 0
                                    } )
                                    .finally( () =>
                                    {
                                        this.handleSync( which.storage, stateList, tsmpLocal, tsmpCompare )
                                            .then( () =>
                                            {

                                                return resolve()

                                            } )
                                    } )

                            } )

                    }

                } )
                .catch( () =>
                {
                    return resolve()
                } )
        } )
    }

    pushPins( pins )
    {

        this.parent.cryptoHelper.encrypt( JSON.stringify( pins ) )
            .then( result =>
            {
                let pinString = btoa( JSON.stringify( result ) )
                this.parent.queueWorker.enqueue( 'message', this.parent.uuid.generate(), 'socketMessage', JSON.stringify(
                    {
                        method   : 'objects.setPersonalAttributes',
                        tableName: 'pinlist',
                        pinlist  : pinString
                    }
                ) )

            } )

    }

    pushHides( hidden )
    {

        this.parent.cryptoHelper.encrypt( JSON.stringify( hidden ) )
            .then( result =>
            {
                let hideString = btoa( JSON.stringify( result ) )
                this.parent.queueWorker.enqueue( 'message', this.parent.uuid.generate(), 'socketMessage', JSON.stringify(
                    {
                        method   : 'objects.setPersonalAttributes',
                        tableName: 'hidelist',
                        hidelist : hideString
                    }
                ) )

            } )

    }

    triggerPushPins()
    {

        this.parent.database.readAllObjects( 'pinning' )
            .then( pins =>
            {

                if( 0 < pins.length )
                {
                    this.pushPins( pins )
                }

                this.parent.database.readAllObjects( 'hiding' )
                    .then( hidden =>
                    {

                        if( 0 < hidden.length )
                        {
                            this.pushHides( hidden )
                        }

                    } )

            } )
    }

    sync()
    {

        return new Promise( resolve =>
        {

            if( 0 === this.callCount
                || 0 === this.callCount % this.nthCall
                || !this.runOnce )
            {

                this.changes = false

                let promises = [],
                    todo     = [
                        {
                            storage  : 'pinning',
                            tableName: 'pinlist',
                            state    : 'pinned'
                        },
                        {
                            storage  : 'hiding',
                            tableName: 'hidelist',
                            state    : 'hidden'
                        }
                    ]

                for( let t in todo )
                {
                    promises.push( () =>
                    {
                        return this.performSyncAttributes( todo[ t ] )
                    } )
                }

                this.parent.f.promiseRunner( promises )
                    .then( () =>
                    {
                        this.parent.eventManager.dispatch( 'on-prepare-statelists' )
                        this.callCount++
                        this.runOnce = true
                        return resolve( 0 )
                    } )
                    .catch( error =>
                    {

                        this.callCount++
                        this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudPersonalAttributes', 'done (faulty:', error, ')' )
                        return resolve()

                    } )

            }
            else
            {
                this.callCount++
                if( 100000 < this.callCount )
                {
                    this.callCount = 0
                }
                return resolve( 0 )
            }

        } )

    }

}