export default class Statistics
{

    constructor( core )
    {

        if( !Statistics.instance )
        {

            this.logger = core.getLogger()
            this.db = core.getDatabase()
            this.eventManager = core.getEventManager()
            
            this.flush()
            
            Statistics.instance = this

            this.eventManager.append( 'on-login-state-change', () => {
                this.flush()
            })
            
            this.logger.cconstructed( 'Statistics', 'initialized' )

        }

        return Statistics.instance

    }

    destruct()
    {

        delete Statistics.instance

    }

    flush()
    {

        this.statistics = {}
        this.initialize()
    
    }
    
    initialize()
    {

        this.logger.clog( 'Statistics:initialize', 'initializing statistics engine...' )

        this.db.readAllObjects( 'statistics' )
            .then( result =>
            {
                for( let i = 0; i < result.length; i++ )
                {

                    this.statistics[ result[ i ].key ] = result[ i ].item

                }
            } )
        
    }

    register( key, value )
    {
        this.logger.clog( 'Statistics:register', key + ' is now ' + value )
        this.statistics[ key ] = value
        this.db.write( 'statistics', key, value )
    }

    count( key )
    {

        let value = this.statistics[ key ] === undefined ? 1 : ( parseInt( this.statistics[ key ] ) + 1 )
        this.register( key, value )

    }
    
    get( key )
    {
        return new Promise( resolve => {
            
            this.db.read( 'statistics', key )
                .then( value => {
                    return resolve( value )
                })
                .catch( () => {
                    return resolve( 0 )
                })
            
        })
    }

}