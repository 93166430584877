<template>
    <div :class="'markers-label-space'+( additionalClass ? ' '+additionalClass : '' )">
        <LabelMarker v-for="label in labels"
                     :reference="label"
                     :additionalClass="additionalClass"
                     :selectable="selectable"
                     :key="'label-display-'+label"/>
    </div>
</template>

<script>
import LabelMarker from "@/components/elements/markers/LabelMarker";

export default {
    name      : "LabelMarkers",
    components: { LabelMarker },
    props     : {
        item           : { type: Object, required: true },
        selectable     : { type: Boolean, required: false },
        additionalClass: { Type: String, required: false }
    },
    data()
    {
        return {
            labels: []
        }
    },
    created()
    {
        if( undefined !== this.$props.item.labels
            && !this.$core.f().isObject( this.$props.item.labels )
            && !Array.isArray( this.$props.item.labels ) )
        {
            this.labels = this.$props.item.labels.split( /,/g )
        }
    }
}
</script>