export default class TestlistDefinition
{
    static fieldset = {
        summary : [ 'averageScoreCalculation', 'total' ],
        fieldDef: 'count'
    }
    
    static summary = {
        firstColumn: 'translate-average',
        columns: 'averages',
    }
}