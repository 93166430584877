<template>
    <div @prefillAdder="prefillAdder" class="select-wrapper" v-if="initialized">
        <select :disabled="disabled"
                class="select_element" :id="'form-element-'+refName" @click="$core.f().skip"
                @change="checkSelect">
            <option value="">bitte wählen...</option>
            <option v-for="organizer in organizers" :value="organizer[idType]"
                    :selected="undefined !== value ? ( asPlainList ? value == organizer[idType] : value.value == organizer[idType] ) : false"
                    :key="'key_orgsel_'+organizer[idType]" v-html="organizer.displayTitle">
            </option>
        </select>
    </div>
</template>

<script>

import MixinOrganizerResolver from "@/components/elements/viewItems/plannerView/mixins/MixinOrganizerResolver";

export default {
    name: 'OrganizerSelector',

    props: {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        id         : { Type: String, required: false },
        idType     : { Type: String, required: false, default: 'localId' },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        disabled   : { Type: Boolean, required: false, default: false },
        editor     : { Type: String, required: false },
        flags      : { Type: String, required: false },
        allValues  : { Type: Object, required: false },
        unique     : { Type: Boolean, required: false },
        prefetch   : { Type: String, required: false },
        editItem   : { Type: Object, required: false },
        asPlainList: { Type: Boolean, required: false, default: false }
    },

    emits : [ 'change', 'update' ],
    mixins: [ MixinOrganizerResolver ],

    created()
    {
        //this.select()
    },

    mounted()
    {
        this.initialize()
            .then( () =>
            {

                this.formElm = document.querySelector( '#form-element-' + this.$props.refName )
                if( this.$props.unique === true )
                {
                    this.filterUnique()
                }
                this.select()

            } )
    },

    data()
    {
        return {
            organizers : false,
            data       : {},
            selected   : false,
            initialized: false,
            formElm    : false
        }
    },

    watch: {
        prefetch : {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.initialize()
                    this.select()
                }
            },
            deep: true
        },
        value    : {
            handler()
            {
                this.initialize()
            }
        },
        allValues: {
            deep: true,
            handler()
            {
                if( this.$props.unique === true )
                {
                    this.filterUnique()
                }
            }
        }
    },

    methods: {

        /*eslint-disable*/
        initialize()
        {

            return new Promise( resolve =>
            {

                this.$core
                    .getBaseClassHelper()
                    .get( 'organizer' )
                    .listAll()
                    .then( organizers =>
                    {

                        let list  = this.$core.f().deref( organizers ),
                            rules = this.$core.settings().getSetting( 'sortingDirections' ),
                            data  = []

                        for( let l in list )
                        {
                            if( !list[ l ].hidden )
                            {
                                this.resolveOrganizerTitle( list[ l ] )
                                list[ l ].title = list[ l ].displayTitle
                                data.push( list[ l ] )
                            }
                        }

                        this.data = {}
                        this.data.all = this.$core.getSorter().multiSortObjects( data, rules[ 'organizer' ] )
                        this.organizers = this.data.all

                        this.initialized = true
                        return resolve()

                    } )

            } )

        },

        select()
        {
            if( null !== this.formElm )
            {
                if( undefined !== this.$props.value
                    && null !== this.$props.value )
                {
                    if( this.$core.f().isObject( this.$props.value )
                        && this.$core.f().isset( this.$props.value.value ) )
                    {
                        this.formElm.value = this.$props.value.value
                    }
                    else
                    {
                        this.formElm.value = this.$props.value
                    }
                }
                else
                {
                    this.formElm.value = ''
                }
            }
        },

        checkSelect()
        {
            if( null !== this.formElm )
            {
                if( '' !== this.formElm.value.trim() )
                {
                    this.$emit( 'change', this.$props.id, this.formElm.value )
                    this.$emit( 'update', this.$props.refName, 'colleagueSelector', 'update', undefined, this.formElm.value )
                }
                else
                {
                    this.$emit( 'change', this.$props.id, undefined )
                    this.$emit( 'update', this.$props.refName, 'colleagueSelector', 'update', undefined, undefined )
                }
            }
        },

        prefillAdder( event )
        {

            let temp   = event.detail.elmId.split( /_/g ),
                id     = temp.pop(),
                lookup = '_' + id

            if( -1 < this.$props.refName.indexOf( lookup ) )
            {
                if( null !== this.formElm )
                {
                    this.formElm.value = event.detail.values
                    this.checkSelect()
                }
            }

        }

    }

}
</script>