const dependencies = {
    addSelf : true,
    optional: {
        list : {
            reference: 'localId',
            location : 'columns',
            sub      : 'lists',
            subIndex : 0,
            index    : 0,
            type     : 'list',
            attribute: 'filterBy',
            filter   : {
                key  : 'filter',
                value: 'yeargroup'
            }
        },
        note : { reference: 'localId', attribute: 'yeargroupReference' },
        todo : { reference: 'localId', attribute: 'yeargroupReference' },
        date : { reference: 'localId', attribute: 'yeargroupReference' },
        media: { reference: 'localId', attribute: 'yeargroupReference' }
    },
    required: {
        student: { attribute: 'students', type: 'student', contained: true }
    }
}
export default dependencies