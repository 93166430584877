<template>
    <div :key="'list-'+item.referenceKey">
        <ListEditor v-if="false !== editItem
                          && true !== editItem.archived
                          && ( $core.getLicense().isUnrestricted( 'listFunctions', 'listEditor' ) || item.isStudentEditable )"
                    :students="getStudentsForList(editItem)"
                    :list="editItem"
                    :escapeNested="escapeNested"
                    :openVertical="'vertical' === mode"
                    :listFilter="listFilter"
                    @definitionUpdate="handleDefinitionUpdate"
                    @close="handleClose"
                    @update="handleUpdate"/>
        <template v-if="prepared">
            <div
                :class="'list '+( mode !== 'vertical' ? 'hoverable ' : '' )+item.color+( selected ? ' selected-item' : '' )"
                :key="'list-inner-'+item.referenceKey">
                <div class="listname" @click="handleOpen">
                    <ListDoneMark :doneInfo="doneInfo"/>
                    {{
                        item.listname
                    }}
                    <ListFunctions v-if="!clickDisabled"
                                   :open="open"
                                   :item="item"
                                   :mode="mode"
                                   :elementKey="functionsKey"
                                   :singleItems="singleItems"
                                   @excel="handleExcel"
                                   @print="handlePrint"
                                   @openElement="handleOpenElement"
                                   @createElement="handleCreateElement"
                                   @showForToday="handleShowForToday()"
                                   @cloneForToday="handleClone( true )"
                                   @showScores="handleScores"
                                   @showDetails="handleDetails()"
                                   @showVertical="handleVertical()"
                                   @toggleopen="handleOpen"
                                   @toggleListFilter="showListFilters = !showListFilters"/>
                </div>
                <ListFilter v-if="showListFilters"
                            @filter="handleListFilter"/>
                <ListInfo :item="item"
                          :singleItems="singleItems"
                          :doneInfo="doneInfo"
                          @open="handleOpen"
                          @detailView="handleDetailView"/>
                <div v-if="open" class="list-summary-wrapper"
                     @scroll="$emit( 'scrolling' )"
                     :key="'sumwrapper-'+item.localId+'-'+( undefined !== item.lists ? item.lists.length : '1' )">
                    <ListSummary v-if="mode === 'summary'"
                                 :item="isSingleItem ? listSingleItem : item"
                                 :elementKey="item.elementKey"
                                 :listFilter="listFilter"
                                 @dataUpdate="handleDataUpdate()"
                                 @rowClick="handleOpenElement"/>
                    <ListScores v-if="mode === 'scores'"
                                :item="isSingleItem ? listSingleItem : item"
                                :elementKey="item.elementKey"
                                :listFilter="listFilter"
                                @dataUpdate="handleDataUpdate()"
                                @rowClick="handleOpenElement"/>
                    <ListDetails v-if="mode === 'details'"
                                 :item="isSingleItem ? listSingleItem : item"
                                 :elementKey="item.elementKey"
                                 :listFilter="listFilter"
                                 @dataUpdate="handleDataUpdate()"
                                 @rowClick="handleOpenElement"/>
                    <ListVertical v-if="mode === 'vertical'"
                                  :item="isSingleItem ? listSingleItem : item"
                                  :elementKey="item.elementKey"
                                  :listFilter="listFilter"
                                  @dataUpdate="handleDataUpdate()"
                                  @rowClick="handleOpenElement"/>
                    <ScoreDistribution v-if="( 'test' === item.listType || hasScoreBox === true )
                                              && 1 !== $store.getters.isStudent"
                                       :lists="isSingleItem ? [ item ] : item.lists"
                                       :elementKey="item.elementKey"/>
                </div>
            </div>
        </template>
        <template v-else>
            <div
                :class="'list synchronizing '+( mode !== 'vertical' ? 'hoverable ' : '' )+item.color"
                :key="'list-inner-'+item.referenceKey+'-synchronizing'">
                <ListSynchronizingPlaceholder :list="item"/>
            </div>
        </template>
    </div>
</template>

<script>
/*eslint-disable*/
import ListFunctions                from '@/components/elements/defaults/ListFunctions'
import ListSummary                  from '@/components/elements/viewItems/listViews/ListSummary'
import ClassReferenceMarker         from '@/components/elements/markers/ClassReferenceMarker'
import GroupReferenceMarker         from '@/components/elements/markers/GroupReferenceMarker'
import ListEditor                   from '@/components/elements/viewItems/listEditor/ListEditor'
import ListStateMarker              from '@/components/elements/markers/ListStateMarker'
import ViewItemHandler              from '@/components/elements/viewItems/viewHandler/ViewItemHandler'
import ScoreDistribution            from '@/components/form/elements/tests/ScoreDistribution'
import ListScores                   from '@/components/elements/viewItems/listViews/ListScores'
import ListDetails                  from '@/components/elements/viewItems/listViews/ListDetails'
import ListVertical                 from '@/components/elements/viewItems/listViews/ListVertical'
import LastEditor                   from '@/components/elements/markers/LastEditor'
import MixinEvents                  from '@/mixins/MixinEvents'
import YeargroupReferenceMarker     from '@/components/elements/markers/YeargroupReferenceMarker'
import ListFilter                   from "@/components/elements/filters/ListFilter";
import ListDoneMark                 from "@/components/elements/viewItems/listViews/parts/ListDoneMark";
import ListInfo                     from "@/components/elements/viewItems/listViews/parts/ListInfo";
import MixinCachePreheater          from "@/mixins/MixinCachePreheater";
import ListSynchronizingPlaceholder from "@/components/elements/viewItems/listViews/ListSynchronizingPlaceHolder";

export default {
    name: 'List',

    components: {
        ListSynchronizingPlaceholder,
        ListInfo,
        ListDoneMark,
        ListFilter,
        YeargroupReferenceMarker,
        LastEditor,
        ListVertical,
        ListDetails,
        ListScores,
        ScoreDistribution,
        ListStateMarker,
        ListEditor,
        GroupReferenceMarker,
        ClassReferenceMarker,
        ListSummary,
        ListFunctions
    },

    extends: ViewItemHandler,

    mixins: [ MixinEvents, MixinCachePreheater ],
    emits : [ 'refresh', 'detailView', 'click', 'open', 'scrolling', 'dataUpdate' ],

    props: {
        item         : { type: Object, required: true },
        clickDisabled: { type: Boolean, required: false },
        elementKey   : { Type: String, required: false },
        singleItems  : { Type: Boolean, required: false },
        swiped       : { Type: Boolean, required: false },
        remoteOpen   : { type: String, required: false },
        escapeNested : { Type: Boolean, required: false, default: false },
        startOpen    : { Type: Boolean, required: false, default: false },
        selected     : { type: Boolean, required: false, default: false },
        selectMode   : { type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            neededCaches   : [ 'student' ],
            open           : false,
            mode           : 'summary',
            editItem       : false,
            lastEdited     : false,
            recoveryTimer  : null,
            isFiltered     : false,
            filterCount    : 0,
            listFilter     : false,
            updateTimer    : null,
            changed        : false,
            doneInfo       : false,
            triggerOpen    : false,
            hasScoreBox    : false,
            valueHistory   : false,
            changeTimer    : false,
            filterBy       : false,
            listSingleItem : false,
            prepared       : false,
            showListFilters: false,
            initialLookup  : true,
            triggerIds     : [],
            functionsKey   : false,
        }
    },

    watch: {
        'item.elementKey'                : {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( true !== this.$core.getState( 'listEditorOpen' )
                    && undefined !== newValue
                    && undefined !== oldValue
                    && newValue !== oldValue )
                {

                    this.checkListInfo()
                    this.checkScoreBox()
                    this.functionsKey = this.$props.item.lists[ this.$props.item.lists.length - 1 ].elementKey
                    if( 'list' === this.$bus.triggers.lastObjectType
                        && null !== this.$bus.triggers.lastObjectCreated )
                    {
                        this.triggerListOpen( this.$bus.triggers.lastObjectCreated )
                    }

                }
            }
        },
        '$bus.triggers.lastObjectCreated': {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( !this.initialLookup )
                {
                    let start = Date.now()
                    if( true === this.$core.settings().getSetting( 'openAfterCreate' )
                        && undefined !== newValue
                        && newValue !== oldValue
                        && 'list' === this.$bus.triggers.lastObjectType )
                    {
                        this.triggerListOpen( newValue )
                    }
                }
                else
                {
                    this.initialLookup = false
                }
            }
        }
    },

    computed: {
        isSingleItem()
        {
            return !Array.isArray( this.$props.item.lists )
        }
    },

    created()
    {

        this.awaitNeededCaches()
            .then( () =>
            {

                this.functionsKey = this.$props.item.lists ? this.$props.item.lists[ 0 ].elementKey : this.$props.item.elementKey

                if( this.isSingleItem )
                {
                    this.prepareListSingleItem()
                        .then( () =>
                        {
                            this.prepared = true
                        } )
                }
                else
                {
                    this.prepared = true
                }

            } )

    },

    mounted()
    {

        let tmp = this.$core.getState( 'detailViewFor' )
        if( undefined !== tmp )
        {
            let temp = tmp.split( ':' )
            this.filterBy = temp[ 0 ]
        }

        if( 1 === this.$core.getStore().getters.isStudent )
        {
            this.mode = 'test' === this.$props.item.listType ? 'scores' : 'details'
        }
        else
        {
            this.mode = 'test' === this.$props.item.listType ? 'scores' : 'summary'
        }

        this.open = this.$core.getState( 'list-open-' + this.$props.item.referenceKey ) || false
        this.checkScoreBox()
        this.checkListInfo()
        this.checkRemoteOpener()
        let lastTrigger = this.$core.getState( 'last-trigger-open' )
        if( null !== lastTrigger && undefined !== lastTrigger )
        {
            this.triggerListOpen( lastTrigger )
        }
    },

    methods: {

        prepareListSingleItem()
        {

            return new Promise( resolve =>
            {

                let referenceKey = this.$props.item.referenceKey,
                    localId      = this.$props.item.localId,
                    item         = false,
                    test         = this.$core.getBaseClassHelper()
                                       .get( 'list' )
                                       .getListById( referenceKey, localId, 'cache' )

                if( this.$core.f().valid( test ) || 'combilist' === this.$props.item.listType )
                {

                    item = 'combilist' !== this.$props.item.listType ? JSON.parse( JSON.stringify( test ) )
                                                                     : JSON.parse( JSON.stringify( this.$props.item ) )

                    let itemCopy = {}
                    for( let key in Object.keys( item ) )
                    {
                        let k = Object.keys( item )[ key ]
                        if( 'lists' !== k )
                        {
                            itemCopy[ k ] = item[ k ]
                        }
                    }

                    let listCopy = JSON.parse( JSON.stringify( this.$props.item ) )
                    if( undefined === listCopy.values )
                    {
                        listCopy.values = {}
                    }
                    itemCopy.lists = []
                    itemCopy.lists.push( listCopy )
                    this.listSingleItem = itemCopy

                    return resolve()

                }

            } )

        },

        triggerListOpen( localId )
        {

            this.$core.setState( 'last-trigger-open', localId )
            this.$core.getCoreTimer().addTimeout( 'last-trigger-reset', 5000, () =>
            {
                this.$core.setState( 'last-trigger-open', null )
            } )

            for( let l in this.$props.item.lists )
            {
                let list = this.$props.item.lists[ l ]
                if( list.localId === localId )
                {

                    if( this.$core.getState( 'listEditorOpen' ) !== true )
                    {
                        this.valueHistory = undefined !== list.values ? JSON.parse( JSON.stringify( list.values ) ) : {}
                        this.$core.setState( 'last-trigger-open', null )
                        this.editItem = list
                    }
                }
            }

        },

        checkRemoteOpener()
        {
            for( let l in this.$props.item.lists )
            {
                let list = this.$props.item.lists[ l ]
                if( list.localId === this.$props.remoteOpen )
                {
                    if( this.$core.getState( 'listEditorOpen' ) !== true )
                    {
                        this.valueHistory = undefined !== list.values ? JSON.parse( JSON.stringify( list.values ) ) : {}
                        this.editItem = list
                    }
                }
            }
        },

        checkScoreBox()
        {

            let listItem    = this.isSingleItem ? this.$props.item : this.$props.item.lists[ 0 ],
                hasScoreBox = false
            if( undefined !== listItem
                && undefined !== listItem.columns )
            {
                for( let c in listItem.columns )
                {
                    let col = listItem.columns[ c ]
                    if( undefined !== col.type
                        && col.type.substr( 0, 8 ) === 'scoreBox' )
                    {
                        if( !hasScoreBox )
                        {
                            hasScoreBox = true
                        }
                        else
                        {
                            hasScoreBox = false
                            break
                        }
                    }
                }
                this.hasScoreBox = hasScoreBox
            }
        },

        checkListInfo()
        {
            this.$nextTick()
                .then( () =>
                {
                    if( 'recallList' === this.$props.item.listType )
                    {
                        this.doneInfo = {
                            done   : this.$props.item.isDone,
                            duedate: this.$props.item.duedate,
                            duemark: this.$core.getFriendlyTimestamp().friendlyDue( this.$props.item.duedate )
                        }
                    }
                } )
        },

        handleOpen( event )
        {

            if( this.$props.clickDisabled )
            {
                return
            }

            if( undefined !== event )
            {
                this.$core.f().skip( event )
            }
            if( true === this.$props.selectMode )
            {
                this.$emit( 'click' )
            }
            else
            {
                this.open = !this.open
                if( !this.open )
                {
                    this.showListFilters = false
                }
                this.$core.setState( 'list-open-' + this.$props.item.referenceKey, this.open )
            }
        },

        stripEmptyHistory( history )
        {

            let stripped = []

            for( let idx in history )
            {
                if( 0 < Object.keys( history[ idx ].updates ).length )
                {
                    stripped.push( history[ idx ] )
                }
            }

            return stripped
        },

        /*eslint-disable*/

        triggerUpdate()
        {

            if( false !== this.editItem )
            {

                this.$core.getEventManager()
                    .dispatch( 'storing-' + this.editItem.localId )

                this.editItem.fieldHistory = this.$core.getFieldHistoryHelper().reduce( this.editItem.fieldHistory )

                let item = JSON.parse( JSON.stringify( this.editItem ) )
                this.valueHistory = undefined !== item.values ? JSON.parse( JSON.stringify( item.values ) ) : undefined

                return this.$core.getBaseClassHelper()
                           .get( 'list', item.listType )
                           .update(
                               item,
                               item.localId,
                               item.remoteId,
                               item.timestamp,
                               item.localKey
                           )

            }

        },

        handleClose()
        {

            this.$core.getEventManager().dispatch( 'editor-closed' )

            if( this.changed )
            {

                let jobId = this.triggerUpdate()
                if( false !== jobId )
                {

                    this.$core.getEventManager()
                        .append( 'on-upload-written-' + jobId, () =>
                        {
                            this.lastEdited = false
                            this.valueHistory = false
                            this.editItem = false
                            this.changed = false

                            this.$core.getUi().delay( () =>
                            {
                                this.$core.getEventManager().dispatch( 'after-handle-close-' + this.$props.item.localId )
                            }, 500 )

                        } )

                }
                else
                {
                    this.valueHistory = false
                    this.editItem = false
                    this.changed = false
                }

            }
            else
            {
                this.valueHistory = false
                this.$core.getUi().hideBlocker()
                this.editItem = false
            }
        },

        getListForToday()
        {

            if( undefined !== this.$props.item.lists )
            {
                for( let l in this.$props.item.lists )
                {
                    if( this.$core.getFriendlyTimestamp().timestampIsToday( this.$props.item.lists[l].timestamp ) )
                    {
                        return this.$props.item.lists[l]
                    }
                }
            }
            else
            {
                if( this.$core.getFriendlyTimestamp().timestampIsToday( this.$props.item ) )
                {
                    return this.$props.item
                }
            }

            return undefined

        },

        getListValueHistoryForToday()
        {

            let values = undefined

            if( undefined !== this.$props.item.lists )
            {
                for( let l in this.$props.item.lists )
                {
                    if( this.$core.getFriendlyTimestamp().timestampIsToday( this.$props.item.lists[l].timestamp ) )
                    {
                        values = this.$core.f().deref( this.$props.item.lists[l].values )
                    }
                }
            }
            else
            {
                if( this.$core.getFriendlyTimestamp().timestampIsToday( this.$props.item ) )
                {
                    values = this.$core.f().deref( this.$props.item.values )
                }
            }

            return values

        },

        handleShowForToday()
        {
            this.valueHistory = undefined !== this.$props.item.listForToday
                                && undefined !== this.$props.item.listForToday.values
                                ? JSON.parse( JSON.stringify( this.$props.item.listForToday.values ) )
                                : this.getListValueHistoryForToday()
            this.editItem = this.$props.item.listForToday || this.getListForToday()
        },

        handleClone( today, timestamp )
        {

            this.openList()
            let baseClass = this.$core.getBaseClassHelper().get( this.$props.item.type )
            if( today )
            {
                baseClass.clone( this.$props.item.localId, Date.now() )
            }
            else
            {
                baseClass.clone( this.$props.item.localId, timestamp )
            }

        },

        _archiveMatch( student, timestamp )
        {

            if( undefined !== student )
            {

                if( student.archived !== true )
                {
                    return true
                }

                let temp = student.archiveKey.split( /-/g )
                temp.shift()
                let archiveTimestamp = parseInt( temp.join( '' ) )
                if( archiveTimestamp >= parseInt( timestamp ) )
                {
                    return true
                }

            }
            return false

        },

        getStudentsForList( item )
        {

            let refs  = [],
                temp  = [],
                scope = item.archived === true ? 'archive' : 'cache'

            switch( item.columns[ 0 ].filterBy )
            {
                case 'all':
                    temp = this.$core.getBaseClassHelper()
                               .get( 'student' )
                               .readCache()
                    refs = []
                    for( let t in temp )
                    {
                        let st = this.$core.getBaseClassHelper().get( 'student' )
                                     .getById( temp[ t ].localId )
                        if( undefined !== st )
                        {
                            let archiveMatch = this._archiveMatch( st, item.timestamp )
                            if( ( 'archive' === scope && archiveMatch )
                                || archiveMatch )
                            {
                                refs.push( st )
                            }
                        }
                    }
                    break
                default:
                    temp = this.$core.getBaseClassHelper().getObjectById( item.columns[ 0 ].filterBy )
                    if( undefined === temp )
                    {
                        temp = this.$core.getBaseClassHelper()
                                   .getObjectById( this.$core.getBaseClassHelper()
                                                       .getLocalIdByShadowedId( item.columns[ 0 ].filterBy ) )

                    }
                    if( undefined !== temp )
                    {
                        refs = []
                        for( let t in temp.students )
                        {
                            let ref = this.$core.getBaseClassHelper().get( 'student' )
                                          .getById( temp.students[ t ] )
                            if( undefined === ref )
                            {
                                ref = this.$core.getBaseClassHelper()
                                          .getObjectById( this.$core.getBaseClassHelper()
                                                              .getLocalIdByShadowedId( temp.students[ t ] ) )
                            }
                            if( this.$core.f().isset( ref )
                                && ( 'archive' === scope || this._archiveMatch( ref, item.timestamp ) ) )
                            {
                                refs.push( ref )
                            }
                        }
                    }
                    break
            }

            return refs

        },

        openList()
        {
            if( !this.open )
            {
                this.open = true
            }
        },

        handleScores( which )
        {
            this.openList()
            this.mode = which //'scores' === this.mode ? 'summary' : 'scores'
        },

        handleDetails()
        {
            this.openList()
            this.mode = 'details' === this.mode ? 'summary' : 'details'
        },

        handleVertical()
        {
            this.openList()
            this.mode = 'vertical' !== this.mode ? 'vertical' : 'summary'
        },

        handleUpdate( values, valueHistory )
        {

            if( false === this.editItem )
            {
                return
            }

            if( this.editItem.listType !== 'combilist' )
            {

                this.editItem.values = values
                this.editItem.fieldHistory = this.editItem.fieldHistory || {}

                if( Array.isArray( this.editItem.fieldHistory )
                    || 'create' === this.editItem.fieldHistory )
                {
                    this.editItem.fieldHistory = {}
                }

                if( undefined !== valueHistory )
                {
                    this.editItem.fieldHistory[ valueHistory.id_author + '-' + valueHistory.timestamp ] = valueHistory
                }

                this.lastEdited = JSON.parse( JSON.stringify( this.editItem ) )
                this.changed = true

                this.$core.getCoreTimer()
                    .removeTimeout( 'list-updatetimer' )

                this.$core.getCoreTimer()
                    .addTimeout( 'list-updatetimer', 2000, () =>
                    {
                        this.triggerUpdate()
                    } )

            }

        },

        handleDefinitionUpdate( listObject )
        {

            this.valueHistory = undefined !== listObject.values ? JSON.parse( JSON.stringify( listObject.values ) ) : {}
            this.editItem = listObject
            this.changed = true

            this.addEvent( 'after-handle-close-' + this.$props.item.localId, () =>
            {
                this.$nextTick()
                    .then( () =>
                    {
                        this.$core.getUi().hideBlocker()
                        this.valueHistory = undefined !== listObject.values ? JSON.parse( JSON.stringify( listObject.values ) ) : {}
                        this.editItem = listObject
                    } )
            } )

            this.handleClose()

        },

        handleOpenElement( element )
        {

            if( true === this.$props.selectMode )
            {
                this.$emit( 'click' )
            }
            else
            {
                if( true !== this.$props.swiped )
                {
                    this.valueHistory = undefined !== element.values ? JSON.parse( JSON.stringify( element.values ) ) : {}
                    this.editItem = element
                }
            }
        },

        handleCreateElement( timestamp )
        {
            this.handleClone( false, timestamp )
        },

        checkVertical()
        {
            if( this.mode === 'vertical' )
            {
                let opener = document.querySelector( '.list-detail-opener' )
                if( null !== opener )
                {
                    if( opener.classList.contains( 'list-closer' ) )
                    {
                        return false
                    }
                    else
                    {
                        return true
                    }
                }
            }

            return false

        },

        handlePrint( print )
        {

            if( !this.open )
            {
                this.handleOpen()
                this.$core.getUi().delay( () =>
                {
                    this.handlePrint( true )
                }, 300 )
            }
            else
            {
                if( !print && 'vertical' === this.mode )
                {
                    this.$core.getEventManager()
                        .dispatch( 'on-toggle-show-all' )
                    this.$core.getUi().delay( () =>
                    {
                        this.handlePrint( true )
                    }, 300 )
                }
                else
                {
                    print = true
                }
            }

            if( print === true )
            {

                let listItem    = this.isSingleItem ? this.listSingleItem : this.$props.item.lists[ 0 ],
                    appendTitle = undefined

                if( undefined !== listItem.columns[ 0 ].filterBy )
                {

                    let element = this.$core.getBaseClassHelper()
                                      .getObjectById( listItem.columns[ 0 ].filterBy )

                    if( 'all' === listItem.columns[ 0 ].filterBy )
                    {
                        appendTitle = ' ' + this.$core.t( 'generic-all-students' )
                    }
                    else
                    {
                        appendTitle = this.$core.t( 'object-type-' + element.type )
                        switch( element.type )
                        {
                            case 'class':
                                appendTitle += ' ' + element.classname
                                break
                            case 'group':
                                appendTitle += ' ' + element.groupname
                                break
                        }
                    }

                    this.$core.getPrinting()
                        .print( listItem, this.mode, undefined, { appendTitle: appendTitle } )

                }
                else
                {
                    this.$core.getPrinting()
                        .print( listItem, this.mode, undefined, { appendTitle: appendTitle } )
                }
            }

        },

        handleExcel()
        {
            if( !this.open )
            {
                this.handleOpen()
            }
            let listItem = this.isSingleItem ? this.$props.item : this.$props.item.lists[ 0 ]
            this.$core.getExcel()
                .export( listItem, 'printable-' + this.$props.item.localId )
        },

        handleDataUpdate()
        {
            this.$emit( 'dataUpdate' )
        },

        handleListFilter( filter, value )
        {
            this.listFilter = value
        }

    }

}
</script>