<template>
    <div v-if="inline"
         :class="'opener-wrapper'+( true === selfState ? ' open' : ' closed' )+( undefined !== classname ? ' '+classname : '' )"
         @click="toggle">
        <div :class="'opener-caption'+( true === selfState ? ' open' : ' closed' )">
            <h2 @click="toggle">{{ caption }}</h2>
            <div :class="'button opener-button hoverable inline'+( true === selfState ? ' open' : ' closed' )"
                 v-bind:id="id" @click="toggle" :title="caption" v-html="content">
            </div>
        </div>
    </div>
    <div v-else :class="'button opener-button hoverable '+classname" v-bind:id="id" @click="toggle" :title="caption"
         v-html="content">
    </div>
</template>

<script>
export default {
    
    name  : 'Opener',

    props : {
        inline   : { Type: Boolean, required: false },
        id       : { Type: String, required: true },
        caption  : { Type: String, required: false },
        classname: { Type: String, required: false },
        state    : { Type: Boolean, required: false }
    },
    
    data()
    {
        return {
            selfState: false
        }
    },
    
    watch: {
        state: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.selfState = newValue
                }
            }
        }
    },
    
    created()
    {
        this.selfState = this.$props.state
    },
    
    computed: {
        content()
        {
            return this.selfState ? '&#x25b4;' : '&#x25be;'
        }
    },
    
    methods: {
        
        toggle( event )
        {
            this.$core.f().skip( event )
            this.selfState = !this.selfState
            this.$emit( 'clicked' )
        }
        
    }
    
}
</script>