<template>
    <div v-bind:id="getDivId()">
        <div class="select-wrapper">
            <select v-bind:id="'form-element-'+refName" @change="handleUpdate()" @click="skip">
                <option value="">bitte wählen...</option>
                <option v-for="option in dataFields" v-bind:value="option.value"
                        v-bind:key="'key_studentData_'+option.value"
                        :selected="option.value === selectedValue">{{ option.caption }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>

export default {

    name : 'StudentDataSelector',

    props     : {

        value       : { Type: String, required: false },
        allValues   : { Type: Object, required: false },
        refName     : { Type: String, required: true },
        validator   : { Type: String, required: false },
        reformatter : { Type: String, required: false },
        display     : { Type: String, required: false },
        eventKey    : { Type: String, required: false },
        scope       : { Type: String, required: false }

    },

    emits: [ 'change', 'update' ],

    watch: {

        allValues: {
            deep: true,
            handler()
            {
                this.filterUnique()
            }
        }

    },

    created()
    {
        for( let a in this.allFields )
        {
            this.dataFields.push( this.allFields[a] )
        }
        this.filterUnique()
        this.selectedValue = ( undefined !== this.$props.value && undefined !== this.$props.value.value ) ? '' + this.$props.value.value : ''
    },

    data()
    {
        return {
            selectedValue: '',
            allFields   : [
                {
                    value  : 'gender',
                    caption: 'Geschlecht'
                },
                {
                    value  : 'address',
                    caption: 'Anschrift'
                },
                {
                    value  : 'zipcode_city',
                    caption: 'PLZ, Ort'
                },
                {
                    value  : 'birthdate',
                    caption: 'Geburtsdatum'
                },
                {
                    value  : 'confession',
                    caption: 'Konfession'
                },
                {
                    value  : 'parents',
                    caption: 'Eltern'
                },
                {
                    value  : 'phone1',
                    caption: 'erste Telefonnummer'
                },
                {
                    value  : 'phone2',
                    caption: 'zweite Telefonnummer'
                }
            ],
            dataFields: []
        }
    },
    methods: {

        getDivId()
        {
            return this.$props.refName.replace( 'selected_', 'wrapper_' )
        },
        skip( event )
        {
            event.stopPropagation()
        },
        checkSelect( event )
        {

            let value = event.target.value
            let element = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== element )
            {
                this.$emit( 'changed', value )
            }
        },
        filterUnique()
        {

            this.$nextTick()
                .then( () =>
                {

                    let excludes = []

                    if( this.$core.f().valid( this.$props.allValues ) )
                    {
                        for( let a in this.$props.allValues )
                        {
                            excludes.push( this.$props.allValues[ a ].value.value )
                        }
                    }

                    let newList = []

                    for( let s in this.allFields )
                    {
                        let list = this.allFields[ s ]
                        if( -1 === excludes.indexOf( list.value ) || list.value === this.$props.value.value )
                        {
                            newList.push( this.allFields[s] )
                        }
                    }

                    this.dataFields = newList
                    this.ready = true

                } )

        },
        handleUpdate()
        {

            let elm = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== elm )
            {
                this.$emit( 'update', this.$props.refName, 'studentDataSelector', 'update', undefined, elm.value )
            }

        }
    }

}
</script>
