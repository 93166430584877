import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class OrganizerLabelsObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !OrganizerLabelsObjectClass.instance )
        {

            let refList = [ 'caption',
                            'color',
                            'timestamp',
                            'update' ]

            let personalAttributesStatesList = []

            super( core, 'organizerlabel', refList, personalAttributesStatesList )

            this.objectType = 'organizerlabels'

            OrganizerLabelsObjectClass.instance = this
        }

        return OrganizerLabelsObjectClass.instance

    }

    getFormFields()
    {

        return [
            {
                ref        : 'caption',
                caption    : 'Name',
                type       : 'TextBox',
                styleUpdate: false,
                reformatter: null,
                optional   : false,
                validator  : 'text'
            },
            {
                ref        : 'color',
                caption    : 'color',
                translate  : true,
                type       : 'ColorSelector',
                reformatter: null,
                optional   : false,
                validator  : 'color',
                styleUpdate: true
            }
        ]

    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return []
    }

}