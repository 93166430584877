<template>
    <div v-if="ready">
        <DefaultViewItemList :itemList="prepared"
                             :viewKey="controlKey"
                             :viewItem="viewItem"
                             :filtered="filtered"
                             :filterKey="viewKey"
                             :galleryMode="false"
                             :hiddenVisible="hiddenVisible"
                             :viewIsFiltered="viewIsFiltered"
                             :noShareIndicator="noShareIndicator"
                             :selectMode="selectMode"
                             :scope="scope"
                             @selected="handleSelect"/>
    </div>
</template>

<script>
import DefaultViewItemList from "@/components/defaults/DefaultViewItemList";
import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {

    name      : "OrganizerContentsList",
    components: { DefaultViewItemList },
    emits     : [ 'clicked' ],
    mixins    : [ MixinCachePreheater ],

    props: {},

    computed: {
        viewKey: {
            cache: false,
            get()
            {
                if( !this.$core.f().valid( this.baseClass )
                    || 'filled' !== this.baseClass.state
                    || false === this.baseClass.registry.cacheKey
                    || false === this.baseClass.registry.archiveKey )
                {
                    return undefined
                }
                return this.baseClass.registry.cacheKey + '-' + this.baseClass.registry.archiveKey
            }
        }
    },

    watch: {
        viewKey: {
            immediate: true,
            handler( newKey, oldKey )
            {

                if( undefined !== oldKey
                    && undefined !== newKey
                    && newKey !== oldKey )
                {
                    this.prepare()
                }
            }
        }
    },

    data()
    {
        return {
            ready           : false,
            prepared        : [],
            viewItem        : 'OrganizerContent',
            controlKey      : false,
            filtered        : [],
            filterKey       : false,
            hiddenVisible   : false,
            viewIsFiltered  : false,
            noShareIndicator: false,
            selectMode      : false,
            scope           : 'organizer-content',
            neededCaches    : [ 'organizercontent' ],
            eventIndex      : false,
            baseClass       : this.$core.getBaseClassHelper().get( 'organizercontent' )
        }
    },

    mounted()
    {
        this.eventIndex = this.$core.getEventManager().addIndexed( 'on-organizer-update', () =>
        {
            this.prepare()
        } )
        this.awaitNeededCaches()
            .then( () =>
            {
                this.prepare()
            } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager().removeIndexedCallback( 'on-organizer-update', this.eventIndex )
    },

    methods: {

        prepare()
        {
            this.$core.getBaseClassHelper()
                .get( 'organizercontent' )
                .listAll()
                .then( list =>
                {
                    let sortingDirections = this.$core.settings().getSetting( 'sortingDirections' )
                    this.prepared = []
                    this.prepared = this.$core.getSorter().multiSortObjects( list, sortingDirections[ 'organizercontent' ] )
                    this.controlKey = this.$core.getUuid().generate()
                    this.ready = true
                } )
        },

        handleSelect()
        {

        }

    }

}
</script>