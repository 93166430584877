<template :key="'dffb-tpl-'+item.localId+'-'+type">
    <div v-show="visible"
         :key="'dffb-'+item.localId+'-'+type"
         class="button round vertical" v-bind:class="type+' '+addClass+state" @click="handleClick">
    </div>
</template>

<script>

export default {
    
    name  : 'FunctionButton',

    props : {
        item    : { Type: Object, required: true },
        type    : { Type: String, required: true },
        addClass: { Type: String, required: false }
    },
    
    data()
    {
        return {
            visible: true,
            state  : ''
        }
    },
    
    created()
    {
        switch( this.$props.type )
        {
            case 'pin':
                if( this.$props.item.pinned )
                {
                    this.state = ' active'
                }
                break
        }
    },
    
    beforeUnmount()
    {
        this.visible = false
    },
    
    methods: {
        handleClick( event )
        {
            event.preventDefault()
            event.stopPropagation()
            this.$emit( 'clicked' )
        }
    }
    
}
</script>