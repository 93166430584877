<template>
    <div class="list-element textbox">
        <template v-if="$core.f().isset( value )">
            <span v-if="value.length > maxLength && !fulltext">
                {{ value.substr( 0, ( maxLength - 3 ) ) + '...' }}
            </span>
            <span v-else>
                {{ value }}
            </span>
        </template>
    </div>
</template>

<script>
export default {
    name : 'listElement-textbox',
    props: {
        value   : { type: String, required: false, default: undefined },
        fulltext: { type: Boolean, required: false, default: undefined }
    },
    data()
    {
        return {
            maxLength: 25
        }
    }
}
</script>
