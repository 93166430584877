import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class MediaObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !MediaObjectClass.instance )
        {

            let refList = [ 'label',
                            'labels',
                            'studentReference',
                            'classReference',
                            'groupReference',
                            'yeargroupReference',
                            'blurry',
                            'mimetype',
                            'filename',
                            'fileId',
                            'fileSize',
                            'pinned',
                            'hidden',
                            'timestamp',
                            'update' ]

            let personalAttributesStatesList = [
                {
                    storage: 'pinning',
                    state  : 'pinned',
                    list   : 'pinLists'
                },
                {
                    storage: 'hiding',
                    state  : 'hidden',
                    list   : 'hideLists'
                }
            ]

            super( core, 'media', refList, personalAttributesStatesList, true )

            this.objectType = 'media'
            this.mediaHelper = core.getMediaHelper()

            this.isStudentShareable = true

            if( undefined === this.mediaHelper )
            {
                core.getEventManager()
                    .append( 'on-media-ready', () => {

                        this.mediaHelper = core.getMediaHelper()

                    })
            }

            MediaObjectClass.instance = this

        }

        return MediaObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'file',
                caption    : 'Datei / Aufnahme',
                type       : 'FileBox',
                styleUpdate: false,
                reformatter: null,
                optional   : false,
                validator  : null
            },
            {
                ref        : 'label',
                caption    : 'Name',
                type       : 'TextBox',
                styleUpdate: false,
                reformatter: null,
                optional   : false,
                validator  : 'text'
            },
            {
                ref        : 'labels',
                caption    : 'Labels',
                type       : 'LabelSelector',
                styleUpdate: false,
                reformatter: null,
                optional   : true,
                validator  : 'text'
            },
            {
                ref          : 'studentReference',
                caption      : 'zu Schüler:in',
                type         : 'RowAdder',
                component    : 'StudentSelector',
                values       : [],
                allowForeign : true,
                reformatter  : null,
                optional     : true,
                validator    : null,
                styleUpdate  : false,
                extended     : true,
                asPlainList  : true,
                dragBlocked  : true,
                unique       : true,
                ignoreClassId: true
            },
            {
                ref         : 'classReference',
                caption     : 'zu Klasse',
                type        : 'ClassSelector',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false,
                extended    : true
            },
            {
                ref         : 'groupReference',
                caption     : 'zu Gruppe',
                type        : 'GroupSelector',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false,
                extended    : true,
                acl         : 'page_groups'
            },
            {
                ref         : 'yeargroupReference',
                caption     : 'zu Jahrgang',
                type        : 'YeargroupSelector',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false,
                extended    : true,
                acl         : 'page_yeargroups'
            },
            {
                ref        : 'blurry',
                caption    : 'geschützt',
                type       : 'Checkbox',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false,
                extended   : true
            },
            {
                ref        : 'mimetype',
                caption    : 'Typ',
                type       : 'Hidden',
                styleUpdate: false,
                reformatter: null,
                optional   : false,
                validator  : 'text'
            },
            {
                ref        : 'filename',
                caption    : false,
                type       : 'Hidden',
                styleUpdate: false,
                reformatter: null,
                optional   : false,
                validator  : 'text'
            },
            {
                ref        : 'fileId',
                caption    : false,
                type       : 'Hidden',
                styleUpdate: false,
                reformatter: null,
                optional   : false,
                validator  : 'text'
            },
            {
                ref        : 'fileSize',
                caption    : false,
                type       : 'Hidden',
                styleUpdate: false,
                reformatter: null,
                optional   : false,
                validator  : 'text'
            }
        ]
    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return [ 'pin', 'hide', 'edit', 'share', 'delete' ]
    }

    delete( localId, remoteId, forceRemote )
    {

        return new Promise( resolve =>
        {

            let file = this.getById( localId )
            if( undefined !== file )
            {
                this.mediaHelper
                    .deleteFileById( file.fileId )
            }

            this.mediaHelper
                .removeMediaFromLists( localId )
                .then( () => {

                    return resolve( super.delete( localId, remoteId, forceRemote ) )

                })

        })

    }

    create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
    {

        let localId = super.create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )

        this.mediaHelper
            .linkFileIdToMediaId( values.fileId, localId )

        return localId

    }
}