export default class CleanOrphanedCopies
{
    constructor( parent )
    {

        this.parent = parent
        this.logger = parent.parent.logger
        this.database = parent.parent.database
        this.f = parent.parent.f

        this.baseClassHelper = parent.baseClassHelper

        this.logSign = 'SyncShadowCopies::CleanOrphanedCopies'

        this.logger.cconstructed( this.logSign, 'initialized' )

        return this

    }

    /**
     * removeShadowCopies
     * - removes obsolete copies like doubles or orphaned
     * @param copies
     * @returns {Promise<unknown>}
     */
    removeShadowCopies( obsoleteCopies )
    {
        return new Promise( resolve =>
        {

            this.logger.clog( this.logSign + ':removeShadowCopies', 'found', obsoleteCopies.length, 'obsolete copies' )

            this.baseClassHelper
                .get( 'shadowCopy' )
                .deleteList( obsoleteCopies )
                .then( () =>
                {

                    this.logger.clog( this.logSign + ':removeShadowCopies', 'removal complete.' )
                    return resolve()

                } )

        } )
    }

    /**
     * checkStoredShadowCopies
     * - check all stored shadow copies for doubles or orphaned elements
     * @returns {Promise<unknown>}
     */
    checkStoredShadowCopies()
    {

        return new Promise( resolve =>
        {

            let orphanedCopies = [],
                doubleCopies   = [],
                relations      = {},
                promises       = []

            this.baseClassHelper
                .get( 'studentAccess' )
                .listAll()
                .then( studentAccessList =>
                {

                    for( let a in this.parent.shadowCopyCache )
                    {

                        let copy                = this.parent.shadowCopyCache[ a ],
                            foundMatchingAccess = false

                        if( undefined === relations[ copy.studentLocalId ]
                            || -1 === relations[ copy.studentLocalId ].indexOf( copy.referenceLocalId ) )
                        {
                            relations[ copy.studentLocalId ] = []
                            relations[ copy.studentLocalId ].push( copy.referenceLocalId )
                        }
                        else
                        {
                            doubleCopies.push( copy.localId )
                        }

                        for( let s in studentAccessList )
                        {

                            let access = studentAccessList[ s ]
                            if( access.studentLocalId === copy.studentLocalId )
                            {
                                foundMatchingAccess = true
                            }

                        }

                        if( !foundMatchingAccess )
                        {
                            orphanedCopies.push( copy.localId )
                        }

                    }

                    promises.push( () =>
                    {

                        return this.removeShadowCopies( [ ...orphanedCopies, ...doubleCopies ] )

                    } )

                    this.f.promiseRunner( promises )
                        .then( () =>
                        {

                            return resolve()

                        } )

                } )
                .catch( () =>
                {
                    return resolve()
                } )

        } )
    }

    /*eslint-disable*/
    checkShadowedObjects()
    {

        return new Promise( resolve =>
        {

            let localIds     = [],
                shadowCopies = {},
                copyMap      = new Map(),
                dropCopies   = []

            for( let a in this.parent.shadowCopyCache )
            {

                if( undefined === this.parent.shadowCopyCache[ a ].referenceLocalId )
                {
                    dropCopies.push( this.parent.shadowCopyCache[ a ].localId )
                }
                else
                {
                    if( undefined === shadowCopies[ this.parent.shadowCopyCache[ a ].studentLocalId ] )
                    {
                        shadowCopies[ this.parent.shadowCopyCache[ a ].studentLocalId ] = []
                    }
                    if( undefined === copyMap.get( this.parent.shadowCopyCache[ a ].referenceLocalId ) )
                    {
                        copyMap.set( this.parent.shadowCopyCache[ a ].referenceLocalId, [] )
                    }

                    let arr = copyMap.get( this.parent.shadowCopyCache[ a ].referenceLocalId )
                    arr.push( this.parent.shadowCopyCache[ a ].localId )
                    copyMap.set( this.parent.shadowCopyCache[ a ].referenceLocalId, arr )

                    if( -1 === shadowCopies[ this.parent.shadowCopyCache[ a ].studentLocalId ].indexOf( this.parent.shadowCopyCache[ a ].referenceLocalId ) )
                    {
                        shadowCopies[ this.parent.shadowCopyCache[ a ].studentLocalId ].push( this.parent.shadowCopyCache[ a ].referenceLocalId )
                        localIds.push( this.parent.shadowCopyCache[ a ].referenceLocalId )
                    }
                    else
                    {
                        dropCopies.push( this.parent.shadowCopyCache[ a ].localId )
                    }
                }

            }

            this.database
                .verifyObjectIdsByIdList( localIds )
                .then( validIds =>
                {

                    if( validIds.length !== localIds.length )
                    {
                        for( let l in localIds )
                        {
                            if( -1 === validIds.indexOf( localIds[ l ] ) )
                            {
                                let arr = copyMap.get( localIds[ l ] )
                                if( Array.isArray( arr ) )
                                {
                                    for( let a in arr )
                                    {
                                        dropCopies.push( arr[ a ] )
                                    }
                                }
                            }
                        }
                    }

                    this.removeShadowCopies( dropCopies )
                        .then( () =>
                        {

                            localIds = null
                            shadowCopies = null
                            copyMap = null
                            dropCopies = null

                            return resolve()

                        } )

                } )
                .catch( () =>
                {
                    return resolve()
                } )

        } )

    }

    dropShadowCopy( shadow )
    {

        this.baseClassHelper
            .get( 'shadowCopy' )
            .delete( shadow.localId )

    }

    deleteObsoleteReferencedShadows( copies )
    {

        for( let [ key, copy ] of copies )
        {
            if( undefined !== copy.lists )
            {
                for( let l in copy.lists )
                {

                    let referenceKey = copy.referenceKey.replace( 'shadowRef-', '' ),
                        original = this.baseClassHelper
                                       .get( copy.type )
                                       .getListById( referenceKey, copy.lists[l].shadowedId, 'cache' )

                    if( undefined === original )
                    {

                        this.baseClassHelper
                            .get( copy.type )
                            .delete( copy.localId )

                    }

                }
            }
            else
            {

                let original = this.baseClassHelper
                                   .get( copy.type )
                                   .getById( copy.shadowedId )

                if( undefined === original )
                {

                    this.baseClassHelper
                        .get( copy.type )
                        .delete( copy.localId )

                }

            }

        }

    }

    checkDeletedReferences()
    {

        return new Promise( resolve =>
        {

            let allShadows = this.baseClassHelper
                                 .get( 'shadowCopy' )
                                 .readCache()

            for( let a in allShadows )
            {
                let shadow = allShadows[ a ]

                if( 'filled' === this.baseClassHelper
                                     .get( shadow.elementType ).state )
                {

                    let original = this.baseClassHelper
                                       .get( shadow.elementType )
                                       .getById( shadow.referenceLocalId )

                    if( undefined === original
                        && shadow.elementType === 'list' )
                    {

                        original = this.baseClassHelper
                                       .get( shadow.elementType )
                                       .getListById( shadow.referenceKey, shadow.referenceLocalId, 'cache' )

                    }

                    if( undefined === original )
                    {
                        this.dropShadowCopy( shadow )
                    }

                }

            }

            let queue = [ 'list',
                          'note',
                          'todo',
                          'date' ]

            for( let q in queue )
            {

                let type         = queue[ q ],
                    shadowCopies = this.baseClassHelper.get( type ).registry.shadowCopies

                this.deleteObsoleteReferencedShadows( shadowCopies )
            }

            return resolve()

        } )

    }

    /**
     * handle
     */
    handle()
    {

        return new Promise( resolve =>
        {

            let start = Date.now()

            this.checkShadowedObjects()
                .then( () =>
                {

                    this.checkStoredShadowCopies()
                        .then( () =>
                        {

                            this.checkDeletedReferences()
                                .then( () =>
                                {

                                    this.logger.cdebug( this.logSign + ':handle', 'process took ' + ( Date.now() - start ) + 'ms' )
                                    return resolve()

                                } )

                        } )

                } )
        } )

    }

}