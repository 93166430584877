export default class Config
{

    constructor()
    {

        if( !Config.instance )
        {

            this.setConfig()
            this.setFeatureSet()

            Config.instance = this

        }

        return Config.instance

    }

    destruct()
    {
        delete Config.instance
    }

    setConfig()
    {

        this.product = 'entzettelt'
        this.versionNumber = '2.4.8'
        this.codeName = false

        this.build = process.env.VERSION.substring( 0, 8 )
        this.branch = process.env.BRANCH

        this.version = this.versionNumber + '-'
                       + ( this.codeName !== false ? this.codeName + '-' : '' )
                       + this.branch + ' (' + this.build + ')'

        this.commitHash = process.env.COMMIT_HASH
        this.verbosity = 99
        this.server = 'localhost'

        this.baseUri = ''
        this.endpoint = 'wss'

        this.maxTickTimeout = ( 60 * 60000 )
        this.configureBranch()

        this.demoUser = 'Testnutzer'
        this.demoPass = 'miexo-iy0ahp7eed9vi7Pahjae3eopho'

        Config.instance = this

    }

    configureBranch()
    {
        switch( this.branch )
        {
            case 'local':
            case 'build-240':
                this.baseUri = 'http://localhost:8080/#/'
                this.server = 'test2.entzettelt.de'
                this.verbosity = 9
                break
            case 'master':
            case 'dev':
            case 'beta2':
                this.server = 'test2.entzettelt.de'
                this.verbosity = 9
                break
            case 'beta':
                this.server = 'beta.entzettelt.de'
                this.verbosity = 3
                break
            case 'live':
                this.server = 'app.entzettelt.de'
                this.verbosity = 1
                break
            case 'live2':
                this.baseUri = 'https://live.entzettelt.de/#/'
                this.server = 'app.entzettelt.de'
                this.verbosity = 1
                break
        }

        this.baseUri = this.baseUri || 'https://' + this.server + '/#/'
        this.endpoint = 'cwss'

        this.socketUrl = 'wss://' + this.server + '/' + this.endpoint + '/'
    }

    setFeatureSet()
    {

        this.tabMap = {
            'lists'              : 'page_lists',
            'lists_not_test'     : 'page_lists',
            'lists_test'         : 'listtype_test',
            'notes'              : 'page_notes',
            'planner'            : 'page_planner',
            'students'           : 'page_students',
            'note'               : 'page_notes',
            'list'               : 'page_lists',
            'izel'               : 'page_izel',
            'todo'               : 'page_todos',
            'date'               : 'page_planner',
            'quick'              : 'view_student_quick',
            'coll_meta'          : 'page_colleagues',
            'coll_rights_general': 'page_colleagues',
            'coll_rights_objects': 'page_colleagues',
            'coll_sharedwithme'  : 'page_colleagues'
        }

        this.features = {
            student  : {
                page_students           : false,
                page_classes            : false,
                page_groups             : false,
                page_yeargroups         : false,
                page_office             : true,
                page_lists              : true,
                page_dates              : false,
                page_notes              : true,
                page_todos              : true,
                page_izel               : 'flag_has_izel',
                page_planner            : false,
                page_colleagues         : false,
                page_secretary          : false,
                page_teams              : false,
                page_messages           : false,
                page_scorings           : true,
                page_settings           : true,
                view_student_quick      : false,
                planner_organizer       : false,
                page_organizer          : false,
                listtype_checklist      : false,
                listtype_ratinglist     : false,
                listtype_referencelist  : false,
                listtype_recalllist     : false,
                listtype_customfixed    : false,
                listtype_customfreestyle: false,
                listtype_test           : false,
                listtype_combilist      : false,
                view_listTemplates      : false,
                edit_list_templates     : false,
                change_backgrounds      : true,
                ical_export             : false,
                print                   : true,
                push_notifications      : false,
                wildcard                : false,
                personal_background     : false,
                show_intro              : false,
                show_recommend          : false,
                share_with_students     : false,
                restricted              : {
                    has             : [
                        'select',
                        'add',
                        'toggleHiddenVisible',
                        'excel'
                    ],
                    filters         : [
                        'ClassesFilter'
                    ],
                    settings        : [
                        'ui-extended',
                        'ui-print',
                        'ui-file',
                        'ui-scoremodel',
                        'ui-scoreoverview',
                        'ui-listsummary',
                        'ui-calendar',
                        'ui-colorsort',
                        'ui-colorlegend',
                        'ui-sortrules',
                        'profile-delete',
                        'profile-meta'
                    ],
                    tab             : [
                        'license',
                        'tools',
                        'foreign'
                    ],
                    listFunctions   : [
                        'listForToday',
                        'listEditor',
                        'showTodaysList',
                        'toggleListMode',
                        'showCalendar',
                        'excel'
                    ],
                    elementFunctions: [
                        'hide',
                        'edit',
                        'delete',
                        'archive',
                        'share'
                    ]
                }
            },
            lite     : {
                page_students           : true,
                page_classes            : true,
                page_groups             : false,
                page_yeargroups         : false,
                page_office             : true,
                page_lists              : true,
                page_dates              : true,
                page_notes              : false,
                page_todos              : false,
                page_izel               : 'flag_has_izel',
                page_planner            : true,
                page_colleagues         : true,
                page_documents          : false,
                page_secretary          : 'flag_is_secretary',
                page_teams              : 'flag_is_secretary',
                page_messages           : false,
                page_scorings           : true,
                page_settings           : true,
                view_student_quick      : false,
                planner_organizer       : false,
                page_organizer          : false,
                listtype_checklist      : true,
                listtype_ratinglist     : true,
                listtype_referencelist  : false,
                listtype_recalllist     : true,
                listtype_customfixed    : false,
                listtype_customfreestyle: false,
                listtype_test           : true,
                listtype_combilist      : false,
                view_listTemplates      : true,
                edit_list_templates     : false,
                change_backgrounds      : false,
                ical_export             : false,
                print                   : false,
                push_notifications      : false,
                wildcard                : false,
                personal_background     : false,
                show_intro              : true,
                show_recommend          : true,
                share_with_students     : false
            },
            full     : {
                page_students           : true,
                page_classes            : true,
                page_groups             : true,
                page_yeargroups         : true,
                page_office             : true,
                page_lists              : true,
                page_dates              : true,
                page_notes              : true,
                page_todos              : true,
                page_izel               : 'flag_has_izel',
                page_planner            : true,
                page_colleagues         : true,
                page_documents          : 'limitedToUpgrade_mediaHandling',
                page_media              : 'limitedToUpgrade_mediaHandling',
                page_secretary          : 'flag_is_secretary',
                page_teams              : 'flag_is_secretary',
                page_messages           : true,
                page_scorings           : true,
                page_settings           : true,
                view_student_quick      : true,
                planner_organizer       : 'flag_experiments_allowed',
                page_organizer          : 'flag_experiments_allowed',
                listtype_checklist      : true,
                listtype_ratinglist     : true,
                listtype_referencelist  : true,
                listtype_recalllist     : true,
                listtype_customfixed    : true,
                listtype_customfreestyle: false,
                listtype_test           : true,
                listtype_combilist      : true,
                view_listTemplates      : true,
                edit_list_templates     : true,
                change_backgrounds      : true,
                ical_export             : true,
                print                   : true,
                push_notifications      : true,
                wildcard                : false,
                personal_background     : true,
                show_intro              : true,
                show_recommend          : true,
                share_with_students     : true
            },
            networked: {
                page_izel         : 'flag_has_izel',
                page_secretary    : 'flag_is_secretary',
                page_teams        : 'flag_is_secretary',
                view_listTemplates: true,
                page_documents    : 'limitedToUpgrade_mediaHandling',
                planner_organizer : 'flag_experiments_allowed',
                page_organizer    : 'flag_experiments_allowed',
                wildcard          : true,
                show_intro        : true,
                show_recommend    : true
            }
        }

    }

    isDevBranch()
    {
        return ( 'local' === this.branch || 'dev' === this.branch )
    }

    getBuild()
    {
        return this.build
    }

    refresh()
    {
        this.setConfig()
    }

}