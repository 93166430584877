<template>
    <transition appear :name="$core.settings().getTransition( 'list' )" mode="out-in">
        <div v-if="show" :class="'listeditor-message '+classname" @click="$emit( 'clicked' )">
            <div class="message">
                <p v-html="message"></p>
            </div>
            <div class="clearfix"></div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ListEditorMessage',

    emits: [ 'clicked' ],

    props: {
        active   : { type: Boolean, required: true },
        message  : { type: String, required: true },
        classname: { type: String, required: true }
    },

    data()
    {
        return {
            show: this.$props.active ? true : false
        }
    }

}
</script>