<template>
    <div v-if="undefined !== object
               && undefined !== selectors[object.type]">
        <div v-for="selector in selectors[object.type]"
             :key="'key_'+selector.id"
             class="half">
            <Toggle :id="'share_'+selector.id"
                    :refName="'share_'+selector.id"
                    :caption="selector.caption"
                    :value="selector.value"
                    @update="handleUpdate"/>
        </div>
        <template v-if="studentMode">
            <div class="half">
                <Toggle v-if="studentMode && $core.getBaseClassHelper().get( object.type ).studentEditable.get( object.listType ) === true"
                        id="isStudentEditable"
                        refName="isStudentEditable"
                        caption="ausfüllbar"
                        value="0"
                        @update="handleUpdate"/>
            </div>
        </template>
    </div>
    <div v-else>
        <div class="half">
            <template v-if="!studentMode">
                <span class="comment">{{ $core.t( 'no-options-for-share-with-students' ) }}</span>
            </template>
            <template v-else>
                <Toggle v-if="studentMode && $core.getBaseClassHelper().get( object.type ).studentEditable.get( object.listType ) === true"
                        id="isStudentEditable"
                        refName="isStudentEditable"
                        caption="ausfüllbar"
                        value="0"
                        @update="handleUpdate"/>
                <span v-else class="comment">{{ $core.t( 'no-options-for-share-with-students' ) }}</span>
            </template>
        </div>
    </div>
</template>

<script>

import Toggle from '@/components/form/elements/Toggle'

export default {

    name      : 'ShareSelectors',
    components: { Toggle },

    props: {
        object     : { type: Object, required: false },
        studentMode: { type: Boolean, required: false, default: false }
    },

    emits: [ 'select' ],

    data()
    {
        return {
            selectors: {
                student  : [
                    {
                        id     : 'note',
                        caption: 'Notizen',
                        value  : 0
                    },
                    {
                        id     : 'list',
                        caption: 'Listen',
                        value  : 0
                    },
                    {
                        id     : 'todo',
                        caption: 'Todos',
                        value  : 0
                    },
                    {
                        id     : 'date',
                        caption: 'Termine',
                        value  : 0
                    },
                    {
                        id     : 'media',
                        caption: 'Dokumente',
                        value  : 0
                    }
                ],
                class    : [
                    {
                        id     : 'note',
                        caption: 'Notizen',
                        value  : 0
                    },
                    {
                        id     : 'list',
                        caption: 'Listen',
                        value  : 0
                    },
                    {
                        id     : 'todo',
                        caption: 'Todos',
                        value  : 0
                    },
                    {
                        id     : 'date',
                        caption: 'Termine',
                        value  : 0
                    },
                    {
                        id     : 'media',
                        caption: 'Dokumente',
                        value  : 0
                    }
                ],
                group    : [
                    {
                        id     : 'note',
                        caption: 'Notizen',
                        value  : 0
                    },
                    {
                        id     : 'list',
                        caption: 'Listen',
                        value  : 0
                    },
                    {
                        id     : 'todo',
                        caption: 'Todos',
                        value  : 0
                    },
                    {
                        id     : 'dates',
                        caption: 'Termine',
                        value  : 0
                    },
                    {
                        id     : 'media',
                        caption: 'Dokumente',
                        value  : 0
                    }
                ],
                yeargroup: [
                    {
                        id     : 'note',
                        caption: 'Notizen',
                        value  : 0
                    },
                    {
                        id     : 'list',
                        caption: 'Listen',
                        value  : 0
                    },
                    {
                        id     : 'todo',
                        caption: 'Todos',
                        value  : 0
                    },
                    {
                        id     : 'date',
                        caption: 'Termine',
                        value  : 0
                    },
                    {
                        id     : 'media',
                        caption: 'Dokumente',
                        value  : 0
                    }
                ]
            }
        }
    },

    methods: {
        handleUpdate( id, type, method, referenceId, value )
        {
            this.$emit( 'select', id, value )
        }

    }
}
</script>