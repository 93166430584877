export default class SyncCrudDeletions
{
    
    constructor( parent )
    {
        
        this.p = parent
        this.parent = this.p.parent

        this.getState = ( key ) => {
            return this.parent.getState( key )
        }

        this.lastSync = Date.now()
        this.timeZoneOffset = ( new Date() ).getTimezoneOffset() * 60000
        
        return this
        
    }
    
    shouldSync()
    {
        return true
    }
    
    countUpdatedTypes( type )
    {
        if( 'full' !== this.parent.getSyncParameters().mode )
        {
            if( -1 === this.p.updatedTypes.indexOf( type ) )
            {
                this.p.updatedTypes.push( type )
            }
        }
    }
    
    performDelete( list )
    {
        return new Promise( resolve =>
        {
            
            if( list.length > 0 )
            {
                
                let job = list.shift()
                this.parent.database.readObject( job.id_local )
                    .then( item =>
                    {
                        
                        let keys = []
                        for( let k in item.object.keys )
                        {
                            let key = item.object.keys[ k ]
                            key.secret = key.key
                            keys.push( key )
                        }
                        
                        let localKey = this.parent._getLocalKey( keys )
                        if( false !== localKey )
                        {

                            this.countUpdatedTypes( job.type )
                            this.parent.cryptoHelper.decryptElement( localKey, item.object.object )
                                .then( object => {

                                    if( false !== object
                                        && null !== object )
                                    {
                                        let storable = this.parent.getBaseClassHelper()
                                            .get( job.type )
                                        storable.delete( job.id_local, job.id )
                                                .then( () =>
                                                {

                                                    this.parent.logger.cdebug( 'SyncWorker::SyncCrud::SyncCrudDeletions:performDelete', 'deleted object', job.id_local )
                                                    return resolve( this.performDelete( list ) )

                                                } )
                                    }
                                    else
                                    {
                                        return resolve( this.performDelete( list ) )
                                    }

                                })
                            
                        }
                        else
                        {
                            return resolve( this.performDelete( list ) )
                        }
                        
                    } )
                
            }
            else
            {
                return resolve()
            }
            
        } )
    }
    
    performCleanup( list )
    {
        
        return new Promise( resolve =>
        {
            
            if( list.length > 0 )
            {
                
                let objectIds = []
                for( let l in list )
                {
                    objectIds.push( list[ l ].id )
                }
                
                this.parent.client.request( {
                        method: 'objects.finalizeDelete',
                        idList: objectIds
                    } )
                    .then( () =>
                    {
                        return resolve()
                        
                    } )
                
            }
            else
            {
                return resolve()
            }
            
        } )
    }
    
    syncShareDeletions( tsmpSince )
    {
        return new Promise( resolve =>
        {
            
            let message = {
                method      : 'objects.getDeletedSharesList',
                initialFetch: this.parent.getSyncParameters().mode === 'full',
                since       : tsmpSince
            }
            
            this.parent.client.request( message )
                .then( response =>
                {
       
                    let list = response.result
                    let listArray = []
                    
                    for( let key in list )
                    {
                        let set = list[ key ]
                        listArray.push( set )
                    }
                    
                    this.resolveDeletions( listArray )
                        .then( result =>
                        {
                            return resolve( this.performDeletions( result.cleanups, result.deletions ) )
                        } )
                    
                } )
                .catch( e =>
                {
                    this.parent.logger.cerror( 'SyncWorker::SyncCrud::SyncCrudDeletions:syncShareDeletions', 'failed to fetch deletion list.', e )
                    return resolve()
                } )
            
        } )
    }
    
    performDeletions( cleanupList, deletionList )
    {
        return new Promise( resolve =>
        {
            
            this.performDelete( deletionList )
                .then( () =>
                {
                    this.performCleanup( cleanupList )
                        .then( () =>
                        {
                            
                            return resolve()
                            
                        } )
                } )
            
        } )
    }
    
    resolveDeletions( list, resolved )
    {
        
        resolved = undefined === resolved ? { deletions: [], cleanups: [] } : resolved
        
        return new Promise( resolve =>
        {
            
            if( 0 < list.length )
            {
                
                let deletion = list.shift()
                this.parent.database.readObject( deletion.id_local )
                    .then( () =>
                    {
                        resolved.deletions.push( deletion )
                        return resolve( this.resolveDeletions( list, resolved ) )
                    } )
                    .catch( () =>
                    {
                        resolved.cleanups.push( deletion )
                        return resolve( this.resolveDeletions( list, resolved ) )
                    } )
                
            }
            else
            {
                return resolve( resolved )
            }
        } )
        
    }
    
    sync()
    {
        
        return new Promise( resolve =>
        {

            if( this.getState( 'listEditorOpen' ) )
            {

                this.parent.logger.cdebug( 'SyncWorker::SyncCrud::SyncCrudDeletions', 'list editor open: avoiding conflicts and awaiting next run...' )
                return resolve()

            }

            this.parent.logger.cdebug( 'SyncWorker::SyncCrud::SyncCrudDeletions', 'performing sync run...' )
            
            let tsmpSince = new Date( this.lastSync + this.p.diff - this.timeZoneOffset )
                .toISOString().slice( 0, 19 ).replace( 'T', ' ' )
            
            let message = {
                method      : 'objects.getDeletedObjectsList',
                initialFetch: this.parent.getSyncParameters().mode === 'full',
                since       : tsmpSince
            }

            this.p.showProgress( '<strong>Löschungen</strong> werden synchronisiert...' )

            this.parent.client.request( message )
                .then( response =>
                {
                    
                    let list = response.result
                    let listArray = []
                    this.lastSync = Date.now()
                    
                    for( let key in list )
                    {
                        let set = list[ key ]
                        listArray.push( set )
                    }
                    
                    this.resolveDeletions( listArray )
                        .then( result =>
                        {
                            this.performDeletions( result.cleanups, result.deletions )
                                .then( () =>
                                {

                                    this.syncShareDeletions( tsmpSince )
                                        .then( () => {
                                            return resolve( result.deletions.length )
                                        })
                                    
                                } )
                        } )
                    
                } )
                .catch( error =>
                {
                    
                    this.parent.logger.cerror( 'SyncWorker::SyncCrud::SyncCrudDeletions', 'done (faulty:', error, ')' )
                    return resolve()
                    
                } )
            
        } )
        
    }
    
}