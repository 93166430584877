<template>
    <transition appear :name="$core.settings().getTransition( 'list-vert' )" mode="out-in">
        <div class="bubbles-boundaries" v-if="0 < count && haveBubbles === true" :key="changeKey">
            <div class="bubbles-indicator" @click="handleBubbleInfo()">
                {{ count }}
            </div>
        </div>
    </transition>
    <transition appear :name="$core.settings().getTransition( 'next' )" mode="out-in">
        <div v-if="bubbleInfo" class="default-zoom-overlay">
            <div class="default-form-container">
                <div class="default-zoom-wrapper">
                    <h2>Updates aus deinem Kollegium</h2>
                    <h3><strong>{{ count }}</strong> Aktualisierungen insgesamt</h3>
                    <div class="spacer"></div>
                    <Button type="close"
                            @click="handleBubbleInfo()"
                            addClass="absolute top right inverted"/>
                    <div class="bubbles-updates">
                        <template v-if="0 < count">
                            <TabbedContent :tabs="tabs" :active="tab" additionalClass="marge-top" @switched="setTab"/>
                            <template v-for="t in tabs">
                                <div class="tabbed-content" v-if="t.id === tab" :key="'tab-'+t.id">
                                    <div v-for="u in updates[ t.id ].objects"
                                         :key="'updates-'+u.localId">
                                        <template v-if="u.type !== undefined">
                                            <Touchable :item="u"
                                                       scope="own"
                                                       :viewItem="u.type"/>
                                            <div class="clearfix"></div>
                                            <div :class="'change-protocol '+u.color">
                                                <h3 @click="switchVisible( u.localId )" style="cursor:pointer;"
                                                    v-html="'<strong>Änderungsprotokoll '+( undefined !== visibles[u.localId] ? 'ausblenden' : 'anzeigen' )+'</strong>'"></h3>
                                                <ul v-if="undefined !== visibles[u.localId]">
                                                    <transition-group appear name="slide-fade" mode="out-in">
                                                        <li v-for="( a, index ) in updates[ t.id ].actions[u.localId]"
                                                            :key="'actions-'+u.localId+'-'+index">
                                                            <strong>{{
                                                                    types[ a.notificationType ]
                                                                }}</strong>&nbsp;<span
                                                            v-html="$core.getFriendlyTimestamp().friendlyTimestamp( a.datetime_created )"></span>
                                                            von
                                                            <strong>{{ getColleague( a.idTriggeredBy ).name }}</strong>
                                                        </li>
                                                    </transition-group>
                                                </ul>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </template>
                        <InlineMessage v-else
                                       :active="true"
                                       classname="empty"
                                       title="Keine Aktualisierungen verfügbar"
                                       message=""/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
/*eslint-disable*/
import Button           from '@/components/elements/defaults/Button'
import InlineMessage    from '@/components/messages/InlineMessage'
import TabbedContent    from '@/components/layout/tabs/TabbedContent'
import Touchable        from "@/components/defaults/Touchable";

export default {

    name      : "Bubbles",
    components: { Touchable, TabbedContent, InlineMessage, Button },

    data()
    {
        return {
            changeKey    : 'init',
            viewId       : 'init',
            ready        : false,
            haveBubbles  : false,
            count        : 0,
            bubbleInfo   : false,
            firstSwitch  : true,
            updateOnClose: false,
            visibles     : {},
            updates      : {},
            objectCounts : {},
            tabs         : [],
            colleagues   : [],
            types        : {
                update       : 'aktualisiert',
                delete_object: 'gelöscht'
            },
            tab          : false
        }
    },

    created()
    {
        this.$core.awaitMxRegistry()
            .then( () =>
            {
                this.$core.cc().awaitInitialized()
                    .then( () =>
                    {
                        this.$core.getEventManager().add( 'on-bubble-details', () =>
                        {
                            this.colleagues = this.$core.cc().getAll()
                            this.viewId = this.$core.getUuid().generate()
                            this.updateCounter()
                            this.ready = true
                        } )
                    } )
            } )

    },

    beforeUnmount()
    {
        this.$core.getEventManager().remove( 'on-bubble-details' )
    },

    methods: {

        getColleague( id )
        {
            for( let c in this.colleagues )
            {
                if( parseInt( this.colleagues[ c ].colleagueId ) === parseInt( id ) )
                {
                    return {
                        name: this.colleagues[ c ].firstname + ' ' + this.colleagues[ c ].lastname
                    }
                }
            }
        },

        switchVisible( localId )
        {
            if( undefined === this.visibles[ localId ] )
            {
                this.visibles[ localId ] = true
            }
            else
            {
                delete this.visibles[ localId ]
            }
        },

        updateCounter()
        {
            if( !this.bubbleInfo )
            {
                let count = this.$core.getBubbles().getNewDetailCount()
                if( count !== this.count )
                {
                    this.count = count
                    this.haveBubbles = 0 < this.count
                    this.changeKey = this.$core.getUuid().generate()
                }
            }
        },

        getObject( id )
        {
            return new Promise( resolve =>
            {

                this.$core.objectRegistryGetById( id )
                    .then( object =>
                    {
                        if( this.$core.f().isObject( object )
                            && 'list' !== object.type )
                        {
                            return resolve( object )
                        }
                        else
                        {
                            this.$core.objectRegistryGetListById( id )
                                .then( object =>
                                {
                                    return resolve( object )
                                } )
                        }
                    } )

            } )
        },

        handleBubbleInfo()
        {

            let newState = !this.bubbleInfo

            if( false === newState )
            {
                if( this.firstSwitch )
                {
                    this.firstSwitch = false
                    this.setRead( this.tab )
                }
                this.updates = {}
                this.bubbleInfo = false
                this.updateCounter()
            }
            else
            {

                let updates = this.$core.getBubbles().getBubbleDetails()
                this.firstSwitch = true
                this.updates = {}
                this.objectCounts = {}

                let promises = []
                for( let u in updates )
                {

                    if( updates[ u ].notificationType !== 'delete_object' )
                    {

                        promises.push( () =>
                        {
                            return this.getObject( updates[ u ].idObject )
                                       .then( object =>
                                       {
                                           if( 'message' !== object.type
                                               && undefined !== object.type )
                                           {

                                               if( undefined === this.updates[ object.type ] )
                                               {

                                                   this.updates[ object.type ] = {
                                                       count  : 1,
                                                       objects: {},
                                                       actions: {}
                                                   }

                                               }
                                               else
                                               {
                                                   this.updates[ object.type ].count++
                                               }

                                               if( undefined === this.updates[ object.type ].objects[ object.localId ] )
                                               {
                                                   this.updates[ object.type ].objects[ object.localId ] = object
                                                   this.updates[ object.type ].actions[ object.localId ] = []
                                                   this.objectCounts[ object.localId ] = 1
                                                   this.updates[ object.type ].actions[ object.localId ].push( updates[ u ] )
                                               }
                                               else
                                               {
                                                   this.objectCounts[ object.localId ]++
                                                   this.updates[ object.type ].actions[ object.localId ].push( updates[ u ] )
                                               }

                                           }

                                       } )
                        } )

                    }

                }

                this.$core.f().promiseRunner( promises )
                    .then( () =>
                    {

                        if( 0 < updates.length )
                        {
                            this.prepareTabs()
                        }
                        this.bubbleInfo = true

                    } )

            }

        },

        /*eslint-disable*/
        setRead( tabId )
        {

            let objectList = this.updates[ tabId ].objects
            let keys = Object.keys( objectList )
            for( let k in keys )
            {

                for( let t in this.tabs )
                {
                    if( this.tabs[ t ].id === tabId )
                    {
                        this.tabs[ t ].bubble = 0
                    }
                }
                let localId = objectList[ keys[ k ] ].localId
                this.$core.getBubbles().setBubbleDetailRead( localId )

            }

        },

        prepareTabs()
        {
            this.tabs = []
            this.tab = false

            let tabs = []
            for( let e in Object.keys( this.updates ) )
            {

                let tabId = Object.keys( this.updates )[ e ]
                if( !this.tab )
                {
                    this.tab = tabId
                    setTimeout( () =>
                    {
                        this.setRead( tabId )
                    }, 3000 )
                }
                tabs.push( tabId )

            }

            for( let t in tabs )
            {
                this.tabs.push( {
                    id     : tabs[ t ],
                    bubble : this.updates[ tabs[ t ] ].count,
                    caption: this.$core.getTranslation().translate( 'object-type-' + tabs[ t ] + 's' )
                } )
            }

        },

        setTab( which )
        {
            if( this.firstSwitch )
            {
                this.firstSwitch = false
                this.setRead( this.tab )
            }
            this.tab = which
            this.setRead( which )
        },

        handleRefresh()
        {
            this.$core.getUi().hideBlocker()
        }

    }

}
</script>