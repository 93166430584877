export default class TimerControl
{
    
    constructor( core )
    {
        
        if( !TimerControl.instance )
        {
            
            this.logger = core.getLogger()
            this.eventManager = core.getEventManager()
            this.coreTimer = core.getCoreTimer()
            
            this.interval = 1000
            this.maxmean = 5000
            this.last = Date.now()
            
            this.coreTimer.addInterval( 'timerControl_Tick', this.interval, () => {
                this.tick()
            } )
            
            this.states = []
    
            this.eventIndex = this.eventManager.addIndexed( 'core-reset', () => {
        
                this.reset()

            })
    
            TimerControl.instance = this
    
        }
        
        return TimerControl.instance
        
    }
    
    destruct()
    {
        this.eventManager.removeIndexedCallback( 'core-reset', this.eventIndex )
        this.coreTimer.removeInterval( 'timerControl_Tick' )
        delete this.eventManager
        this.eventManager = null
        delete this.timer
        this.timer = null
        delete this.logger
        this.logger = null
    }
    
    reset()
    {
    
        this.coreTimer.removeInterval( this.timer )
        while( this.states.length > 0 )
        {
            this.states.splice( 0, 1 )
        }
        this.logger.cdebug( 'TimerControl::tick', 'mean stats reset to zero: ', this.states.length, JSON.stringify( this.states ) )
    
        this.coreTimer.addInterval( 'timerControl_Tick', this.interval, () => {
            this.tick()
        } )
        
        this.eventManager.dispatch( 'debugger-out', '* RESET TRIGGERED' )
        this.eventManager.dispatchIndexed( 'core-component-reset' )
    
    }
    
    getMean()
    {
        let c = 0
        let count = 0
        for( let s in this.states )
        {
            c+= this.states[s]
            count++
        }

        return Math.floor( c / count )

    }
    
    tick()
    {

        let diff = Date.now() - this.last
        if( this.states.length > 10 )
        {
            this.states.shift()
        }
        
        this.states.push( diff )
        let mean = this.getMean()

        if( 10 <= this.states.length
            && mean > this.maxmean )
        {

            this.logger.cdebug( 'TimerControl::tick', 'mean ticktimer has run away: ', mean, 'ms - expected a maximum of', this.maxmean, 'ms... core most likely needs a reset to restore functionality' )
            //this.reset()
            
        }

        this.last = Date.now()
        
    }
    
}