export default class Flags
{
    
    constructor( core )
    {
        
        if( !Flags.instance )
        {
            
            this.store = core.getStore()
            this.eventManager = core.getEventManager()
            this.logger = core.getLogger()
            
            this.logger.cconstructed( 'Flags:constructor', 'Flags instance constructed' )
            
            this.flags = false
            this.ready = false
            this.init()
            
            Flags.instance = this
            
        }
        
        return Flags.instance
        
    }
    
    destruct()
    {
        delete Flags.instance
    }
    
    awaitReadiness()
    {
        return new Promise( resolve => {
            if( this.ready )
            {
                return resolve()
            }
            else
            {
                setTimeout( () => {
                    return resolve( this.awaitReadiness() )
                }, 500 )
            }
        })
    }
    
    init()
    {
        
        if( false === this.store.getters.flags
            || null === this.store.getters.flags )
        {
            this.eventManager.append( 'flags-initialized', () =>
            {
                this.init()
            } )
        } else
        {
            if( false !== this.store.getters.flags
                && null !== this.store.getters.flags
                && 0 < this.store.getters.flags.length )
            {
                this.logger.clog( 'Flags:init', 'user flags initialized' )
                this.ready = true
            } else
            {
                this.eventManager.append( 'flags-initialized', () =>
                {
                    this.init()
                } )
            }
        }
        
    }
    
    is( key )
    {
        return ( -1 < this.store.getters.flags.indexOf( 'flag_is_' + key ) )
    }
    
}
