<template>
    <input type="hidden" :id="'printables-'+list.localId" :value="'list-'+list.localId"/>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-if="visible" :class="'default-form-overlay'+( escapeNested ? ' z-99000' : '')"
             @contextmenu="$core.f().skip"
             :id="'overlay-'+list.localId">
            <template v-if="initialized">
                <div :class="'detail-view colorized '+list.color" @contextmenu="$core.f().skip">
                    <ListEditorHeadline :list="list"
                                        :editorMode="editorMode"
                                        :editingAll="editingAll"
                                        :snapshots="snapshots"
                                        :recallVisible="recallVisible"
                                        :listFilterVisible="listFilterVisible"
                                        @close="handleClose( true )"
                                        @recallVisibleChange="recallVisible = !recallVisible"
                                        @filterVisibleChange="listFilterVisible = !listFilterVisible"
                                        @history="handleHistory"
                                        @print="handlePrint"
                                        @excel="handleExcel"
                                        @modeSwitch="modeSwitch"/>
                    <ListFilter v-if="listFilterVisible"
                                :editormode=true
                                @filter="handleListFilter"/>
                    <ListEditorMessage v-if="true !== $store.getters.online
                                             && true === $core.settings().getSetting( 'showOfflineWarnings' )"
                                       :active="true"
                                       classname="warning"
                                       message="Du bist zur Zeit offline und siehst eventuell eine alte Version dieser Liste: Nicht synchronisierte Inhalte können dabei verlorengehen!"/>
                    <ListEditorMessage v-if="!notificationActive && showMerge"
                                       :active="true"
                                       @clicked="showMerge = false; remoteDiffers = false; tdmarks = []; lastLocalChange = Date.now()"
                                       classname="differs"
                                       message="<h3>Eine neue Version dieser Liste liegt vor!</h3><br/>Änderungen werden Dir angezeigt: <span class='added'>neue</span>, <span class='changed'>geänderte</span> und <span class='deleted'>gelöschte</span> Inhalte werden farbig hinterlegt.<br/>Als gelöscht markierte Inhalte musst du selbst löschen, wenn sie wirklich gelöscht werden sollen."/>

                    <transition appear name="fade">
                        <div v-if="showEditAll && !hideEditAllWarning" class="edit-all-warning"
                             @click="hideEditAllWarning = true">
                            <strong>Wichtig</strong>: Über die Zeile "<strong>Alle Zeilen</strong>" ersetzt du den
                            Inhalt aller Zeilen der jeweiligen Spalte mit dem in "Alle Zeilen" enthaltenen, neuen Wert.
                            <strong>Versehentlich überschriebene Werte können nicht wiederhergestellt werden</strong>.
                        </div>
                    </transition>
                    <template v-if="ccReady || !ccReady">
                        <CollabEditingNotification :active="notificationActive" :html="notificationText"
                                                   :count="notificationCount"/>
                        <div class="list fullscreen" :id="'list-container-'+list.localId">
                            <RecallListDefinition v-if="list.listType === 'recallList'" :list="list"
                                                  :recallVisible="recallVisible"
                                                  @storeRecallDoneDefinition="handleRecallDoneDefinition"/>
                            <template v-if="'horizontal' === editorMode">
                                <table :class="'list fullscreen '+list.listType" :id="'list-'+list.localId"
                                       :key="localChangeKey">
                                    <thead :class="headClass" @click="toggleHeadDimensions">
                                    <tr class="list-header">
                                        <template v-for="( col, index ) in list.columns"
                                                  :key="'th-col-'+col.columnId+'-'+index">
                                            <TableHeadFixed v-if="'fixed' === col.type"
                                                            :caption="col.caption"
                                                            :color="list.color"
                                                            :values="col.values"
                                                            @show-edit-all="toggleShowEditAll"/>
                                            <TableHeadRotated v-if="'fixed' !== col.type"
                                                              :color="list.color"
                                                              :score="col.score"
                                                              :fixed="true"
                                                              :key="'table-head-rotated-'+index+'-'+col.type"
                                                              :keyV="index"
                                                              :allKeys="list.columns.length"
                                                              :caption="(col.type !== 'studentData' ? col.caption : $core.t( 'caption_'+col.caption ))"/>
                                        </template>
                                        <th v-if="list.listType === 'test'"
                                            :class="'fixed '+list.color"
                                            :key="'th-col-total'">
                                            <span class="rotated">Gesamt</span>
                                            <br/><span class="score-heading">&sum; Punkte</span>
                                        </th>
                                        <th v-if="list.listType === 'test'"
                                            :class="'fixed '+list.color"
                                            :key="'th-col-note'">
                                            <span class="rotated">Note</span>
                                            <br/><span class="score-heading">{{
                                                $core.getReformatter().localizedFloat( totalScore, 1 )
                                            }} Punkte</span>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-if="showEditAll" class="editall">
                                        <td v-for="field in lines[0]"
                                            :class="( -1 < foreignHighlighted.indexOf( field.id ) ? 'foreign-highlighted ' : ' ' )
                                            +( undefined !== tdmarks[field.id] ? tdmarks[field.id] + ' ' : '' )
                                            +(false !== field.component ? 'td-'+field.component.toLowerCase() : '')
                                            +( field.disabled ? ' disabled' : '' )"
                                            :id="'nonswipeable-all-'+field.id"
                                            :key="'td-all-'+field.name">
                                            <component v-if="field.component !== false"
                                                       cssClass="list"
                                                       :refName="'all-'+field.id"
                                                       :id="'all-'+field.id"
                                                       :tdAncestor="'nonswipeable-all-'+field.id"
                                                       :placeholder="field.placeholder"
                                                       :is="field.component"
                                                       :highlighted="foreignHighlighted"
                                                       :disabled="readOnly"
                                                       :value="editingAllValues[ 'all-'+field.id ]"
                                                       @focussed="handleFocus"
                                                       @blurred="handleBlur"
                                                       @update="handleAllUpdate"/>
                                            <template v-else>
                                                <span class="editall">alle Zeilen</span>
                                            </template>
                                        </td>
                                        <td v-if="'test' === list.listType"
                                            class="td-total" :key="'td-all-total'"
                                            :id="'total-all'">
                                            &bull;
                                        </td>
                                        <td v-if="'test' === list.listType"
                                            class="td-note" :key="'td-all-note'"
                                            :id="'rating-all'">
                                            &bull;
                                        </td>
                                    </tr>
                                    <template v-for="( line, index ) in lines"
                                              :key="'tpl-tr-'+index">
                                        <tr v-show="isVisible.get( studentList[index] )"
                                            :id="'tr-'+list.localId+'-'+index">
                                            <td v-for="( field, fieldIndex ) in line"
                                                :class="( -1 < foreignHighlighted.indexOf( field.id ) ? 'foreign-highlighted ' : ' ' )
                                            +( undefined !== tdmarks[field.id] ? tdmarks[field.id] + ' ' : '' )
                                            +(false !== field.component ? 'td-'+field.component.toLowerCase() : '')
                                            +( field.disabled ? ' disabled' : '' )"
                                                :id="( false === field.component ? 'swipeable-'+index+'-'+fieldIndex : 'nonswipeable-'+index+'-'+field.id )"
                                                :key="'td-'+index+'-'+field.name">
                                                <template v-if="field.component !== false">
                                                    <CollabEditingFocusBox :colleague="focussedBy[ field.id ]"/>
                                                    <component cssClass="list"
                                                               :refName="field.id"
                                                               :id="field.id"
                                                               :tdAncestor="( false === field.component ? 'swipeable-'+index+'-'+fieldIndex : 'nonswipeable-'+index+'-'+field.id )"
                                                               :placeholder="field.placeholder"
                                                               :is="field.component"
                                                               :highlighted="foreignHighlighted"
                                                               :value="field.display"
                                                               :static="false"
                                                               :disabled="readOnly"
                                                               @focussed="handleFocus"
                                                               @blurred="handleBlur"
                                                               @update="handleFieldUpdate"/>
                                                </template>
                                                <template v-else>
                                                    <FunctionButton :item="field"
                                                                    v-if="index === swiped"
                                                                    type="delete"
                                                                    addClass="absolute-center"
                                                                    @clicked="handleRowDelete( field )"/>
                                                    <span v-html="field.display"></span>
                                                </template>
                                            </td>
                                            <td v-if="'test' === list.listType"
                                                class="td-total" :key="'td-'+index+'-total'"
                                                :id="'total-'+line[0].id">
                                                &bull;
                                            </td>
                                            <td v-if="'test' === list.listType"
                                                class="td-note" :key="'td-'+index+'-note'"
                                                :id="'rating-'+line[0].id">
                                                &bull;
                                            </td>
                                        </tr>
                                        <tr v-if="selectedHistory"
                                            class="history-line">
                                            <td v-for="( field, fieldIndex ) in line"
                                                :class="historyDiffClass( field.id, field.display )"
                                                :key="'td-history-'+index+'-'+field.name">
                                                <template v-if="field.component !== false && hasHistory( field.id )">
                                                    <component cssClass="list"
                                                               :refName="'hist-'+selectedHistory.id+'-'+field.id"
                                                               :id="'hist-'+selectedHistory.id+'-'+field.id"
                                                               :tdAncestor="'nonswipeable-'+fieldIndex+'-'+field.id"
                                                               :placeholder="field.placeholder"
                                                               :is="field.component"
                                                               :highlighted="foreignHighlighted"
                                                               :value="historicValue( field.id )"
                                                               :static="false"
                                                               :disabled="true"/>
                                                </template>
                                                <template v-else>
                                                    <span>&nbsp;</span>
                                                </template>
                                                <div class="history-overlay"
                                                     @click="useHistory( field.id, historicValue( field.id ) )"></div>
                                            </td>
                                            <td v-if="'test' === list.listType"
                                                class="td-total" :key="'td-'+index+'-total'"
                                                :id="'hist-total-'+line[0].id">
                                                &bull;
                                            </td>
                                            <td v-if="'test' === list.listType"
                                                class="td-note" :key="'td-'+index+'-note'"
                                                :id="'hist-rating-'+line[0].id">
                                                &bull;
                                            </td>
                                        </tr>
                                    </template>
                                    <tr :id="'filler-'+list.localId">
                                        <td v-for="field in lines[0]"
                                            :key="'filler-'+field.name">
                                            &nbsp;
                                        </td>
                                        <td v-if="list.listType === 'test'">&nbsp;</td>
                                        <td v-if="list.listType === 'test'">&nbsp;</td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <td v-for="col in list.columns"
                                        :id="'foot-'+$core.getSanitizers().cleanId( col.caption )"
                                        :key="'foot-'+$core.getSanitizers().cleanId( col.caption )"
                                        class="table-footer-space">
                                    </td>
                                    <td v-if="list.listType === 'test'"
                                        class="table-footer-space">&nbsp;
                                    </td>
                                    <td v-if="list.listType === 'test'"
                                        class="table-footer-space">&nbsp;
                                    </td>
                                    </tfoot>
                                </table>
                            </template>
                            <!--- VERTICAL --->
                            <template v-if="'vertical' === editorMode">
                                <table :class="'list fullscreen vertical '+list.listType" :id="'list-'+list.localId">
                                    <thead>
                                    <tr class="list-header" style="display:none;">
                                        <th :class="'fixed first-vertical '+list.color">&nbsp;</th>
                                        <th :class="'fixed align-left '+list.color">&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-for="( line, index ) in lines" :key="'template-'+index">
                                        <template v-for="( field, col ) in line"
                                                  :key="'template-tr-'+field.id+'-'+index">
                                            <template v-if="0 === col">
                                                <tr class="vert-break">
                                                    <td class="align-left vert-break td-vert-header" colspan="2">
                                                        <component v-if="field.component !== false"
                                                                   cssClass="list"
                                                                   :refName="field.id"
                                                                   :id="field.id"
                                                                   :is="field.component"
                                                                   :placeholder="field.placeholder"
                                                                   :value="field.display"
                                                                   :disabled="readOnly"
                                                                   @focussed="handleFocus"
                                                                   @blurred="handleBlur"
                                                                   @update="handleFieldUpdate"/>
                                                        <span v-else v-html="field.display"></span>
                                                    </td>
                                                </tr>
                                            </template>
                                            <template v-else>
                                                <tr>
                                                    <TableDetailSplitted :caption="list.columns[ col ].caption"
                                                                         :vertical="true"
                                                                         :score="list.columns[ col ].score"/>
                                                    <td :class="'align-left td-vert-component '+( undefined !== tdmarks[field.id] ? tdmarks[field.id] + ' ' : '' )+(false !== field.component ? 'td-'+field.component.toLowerCase() : '')+( field.disabled ? ' disabled' : '' )+( index %2 === 1 ? ' even' : ' odd' )">
                                                        <component v-if="field.component !== false"
                                                                   cssClass="list"
                                                                   :refName="field.id"
                                                                   :id="field.id"
                                                                   :is="field.component"
                                                                   :placeholder="field.placeholder"
                                                                   :value="field.display"
                                                                   :disabled="readOnly"
                                                                   @focussed="handleFocus"
                                                                   @blurred="handleBlur"
                                                                   @update="handleFieldUpdate"/>
                                                        <span v-else v-html="field.display"></span>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-if="'test' === list.listType">
                                            <tr>
                                                <td class="align-left">
                                                    Gesamt<br/><span class="score-heading">&sum; Punkte</span>
                                                </td>
                                                <td class="align-left"
                                                    :id="'total-'+line[0].id">
                                                    &bull;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="align-left">
                                                    Note
                                                </td>
                                                <td class="align-left"
                                                    :id="'rating-'+line[0].id">
                                                    &bull;
                                                </td>
                                            </tr>
                                        </template>
                                        <tr :class="'printable-cutline cutline-'+list.localId" style="display: none;"
                                            :id="'printable-cutline-'+index">
                                            <td colspan="2" class="printable-cutline">
                                                <div class="printable-cutline"></div>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                    <tfoot>
                                    <td class="table-footer-space foot-vertical"></td>
                                    <td class="table-footer-space"></td>
                                    </tfoot>
                                </table>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <PreheatingIndicator/>
                    </template>
                </div>
            </template>
            <template v-else>
                <div :class="'detail-view colorized '+list.color" @click="$core.f().skip" @contextmenu="$core.f().skip">
                    <PreheatingIndicator caption="Aktuelle Listenversion wird überprüft..."/>
                </div>
            </template>
        </div>
    </transition>

</template>

<script>
/*eslint-disable*/
import Checkbox                  from '@/components/form/elements/Checkbox'
import DateBox                   from '@/components/form/elements/DateBox'
import RateSelector              from '@/components/form/elements/RateSelector'
import RatePlantSelector         from '@/components/form/elements/RatePlantSelector'
import TextBox                   from '@/components/form/elements/TextBox'
import MediaBox                  from "@/components/form/elements/MediaBox";
import NumberBox                 from '@/components/form/elements/NumberBox'
import SmallNumberBox            from '@/components/form/elements/SmallNumberBox';
import TextArea                  from '@/components/form/elements/TextArea'
import ThreeWayToggle            from '@/components/form/elements/ThreeWayToggle'
import ScoreBox                  from '@/components/form/elements/scoreBoxes/ScoreBox'
import ScoreBoxAustria           from '@/components/form/elements/scoreBoxes/ScoreBoxAustria'
import ScoreBoxBremen            from '@/components/form/elements/scoreBoxes/ScoreBoxBremen'
import ScoreBoxExtended          from '@/components/form/elements/scoreBoxes/ScoreBoxExtended'
import ScoreBoxPoints            from '@/components/form/elements/scoreBoxes/ScoreBoxPoints'
import ScoreBoxQuarters          from '@/components/form/elements/scoreBoxes/ScoreBoxQuarters'
import StudentData               from '@/components/form/elements/StudentData'
import FieldSelector             from '@/components/form/elements/FieldSelector'
import RecallListDefinition      from '@/components/elements/viewItems/listEditor/RecallListDefinition'
import PreheatingIndicator       from '@/components/elements/indicators/PreheatingIndicator'
import RateSmileSelector         from '@/components/form/elements/RateSmileSelector'
import RateSmileSelectorReverse  from '@/components/form/elements/RateSmileSelectorReverse'
import RateFlexSelector          from '@/components/form/elements/RateFlexSelector';
import TableDetailSplitted       from '@/components/elements/viewItems/listViews/tableElements/TableDetailSplitted'
import TableHeadRotated          from '@/components/elements/viewItems/listViews/tableElements/TableHeadRotated'
import FunctionButton            from '@/components/elements/defaults/FunctionButton'
import PageMessage               from '@/components/messages/PageMessage';
import ListEditorMessage         from '@/components/messages/ListEditorMessage'
import ListFilterable            from '@/components/elements/viewItems/listViews/ListFilterable';
import ListFilter                from '@/components/elements/filters/ListFilter';
import Opener                    from '@/components/elements/defaults/Opener';
import TableHeadFixed            from '@/components/elements/viewItems/listViews/tableElements/TableHeadFixed';
import MixinEvents               from '@/mixins/MixinEvents'
import MixinListValueHistory     from '@/mixins/MixinListValueHistory';
import MixinLinkedColumns        from '@/mixins/MixinLinkedColumns';
import FieldTypeToComponent      from '@/classes/Helpers/FieldTypeToComponent'
import ListEditorHeadline        from '@/components/elements/viewItems/listEditor/parts/ListEditorHeadline';
import MixinComponentCleanup     from "@/mixins/MixinComponentCleanup";
import MixinLiveCollab           from "@/mixins/MixinLiveCollab";
import CollabEditingNotification from "@/components/elements/indicators/CollabEditingNotification";
import CollabEditingFocusBox     from "@/components/elements/indicators/CollabEditingFocusBox";

export default {

    name: 'ListEditor',

    extends: ListFilterable,
    mixins : [ MixinEvents, MixinLinkedColumns, MixinListValueHistory, MixinComponentCleanup, MixinLiveCollab ],

    components: {
        CollabEditingFocusBox,
        CollabEditingNotification,
        SmallNumberBox,
        ListEditorHeadline,
        TableHeadFixed,
        Opener,
        ListFilter,
        ListEditorMessage,
        PageMessage,
        FunctionButton,
        TableHeadRotated,
        TableDetailSplitted,
        PreheatingIndicator,
        RecallListDefinition,
        FieldSelector,
        DateBox,
        TextArea,
        TextBox,
        MediaBox,
        NumberBox,
        ThreeWayToggle,
        RateSelector,
        RatePlantSelector,
        RateSmileSelector,
        RateSmileSelectorReverse,
        RateFlexSelector,
        Checkbox,
        StudentData,
        ScoreBox, ScoreBoxAustria, ScoreBoxExtended, ScoreBoxPoints, ScoreBoxQuarters, ScoreBoxBremen
    },

    emits: [ 'update', 'close', 'definitionUpdate' ],

    props: {
        list        : { Type: Object, required: true },
        students    : { Type: Object, required: true },
        escapeNested: { Type: Boolean, required: false, default: false },
        openVertical: { Type: Boolean, required: false, default: false }
    },

    watch: {
        initialized: {
            immediate: true,
            handler( newState, oldState )
            {
                if( newState !== oldState
                    && newState === true )
                {
                    if( this.$props.list.listType === 'test' )
                    {
                        this.updateRatings()
                    }
                }
            }
        }
    },

    data()
    {
        return {
            localClone          : {},
            ccReady             : false,
            valueClone          : undefined,
            recallVisible       : false,
            visible             : true,
            lines               : [],
            totalScore          : 0,
            fieldTypeToComponent: false,
            readOnly            : false,
            editorMode          : false,
            notificationActive  : false,
            notificationText    : '',
            notificationCount   : 0,
            touchDistance       : 0,
            touchDistanceY      : 0,
            swiped              : false,
            swipedRight         : false,
            initialized         : false,
            localTimestamp      : 0,
            remoteValues        : {},
            remoteDiffers       : false,
            showMerge           : false,
            tdmarks             : {},
            syncRemote          : null,
            waitedForCrypto     : false,
            lastLocalChange     : 0,
            localChangeThreshold: 5000,
            updatedOnce         : false,
            listFilterInline    : false,
            listFilterVisible   : false,
            studentList         : [],
            storing             : false,
            showEditAll         : false,
            hideEditAllWarning  : false,
            editAllTimer        : false,
            editingAll          : false,
            editingAllValues    : {},
            headClass           : '',
            localChangeKey      : this.$core.getUuid().generate(),
            snapshots           : [],
            prepared            : false,
            selectedHistory     : undefined
        }
    },

    created()
    {

        this.$core.setState( 'listEditorOpen', true )
        this.localTimestamp = parseInt( this.$props.list.remoteUpdateTimestamp )

        this.$core.cc().awaitReadiness()
            .then( () =>
            {
                this.ccReady = true
            } )

        this.getSnapshots()

        /*this.triggerNetwork( 'notifyEditorStart' )*/

        this.preSync()
            .then( () =>
            {

                this.editorMode = true === this.$props.openVertical ? 'vertical' : 'horizontal'
                if( this.$core.f().isset( this.$props.list.values ) )
                {
                    this.valueClone = JSON.parse( JSON.stringify( this.$props.list.values ) )
                }

                if( this.$core.getLicense().forceReadOnly() )
                {
                    this.$core.setState( 'listEditorOpen', false )
                    this.handleClose()
                }
                else
                {

                    this.readOnly = ( this.$props.list.listType === 'combilist' || this.$props.list.archived || !this.$core.r().isAllowed( this.$props.list, 'fill' ) )
                    if( -1 < this.$props.list.localId.indexOf( 'demo-' ) )
                    {
                        this.readOnly = true
                    }

                    this.$core.setState( 'editing-object', this.$props.list.localId )
                    this.fieldTypeToComponent = new FieldTypeToComponent()
                    this.prepare()

                }

                if( !this.initialized )
                {
                    this.initialized = true
                }

            } )

    },

    beforeUnmount()
    {

        this.$core.getCoreTimer()
            .removeInterval( 'listeditor-remotesync' )

        this.$core.deleteState( 'editing-object' )
        this.$core.setState( 'list-editor-active', false )

        this.$core.setState( 'listEditorOpen', false )
        this.lines = null
        this.fieldTypeToComponent = null

        this.initialized = false
        this.remoteDiffers = false
        this.localTimestamp = 0

        delete this.lines
        delete this.fieldTypeToComponent
        this.lines = []

//        this.triggerNetwork( 'notifyEditorClose' )

    },

    mounted()
    {
        this.$core.setState( 'list-editor-active', true )
        this.$core.cc().awaitReadiness()
            .then( () =>
            {

                this.setModeByResolution()
                this.afterMount()

            } )
    },

    methods: {

        prepare()
        {

            let filter   = this.$core.getState( 'detailViewFor' ),
                filterId = undefined

            if( undefined !== filter )
            {
                let temp = filter.split( ':' )
                if( 'student' === temp[ 0 ] )
                {
                    filterId = temp[ 1 ]
                }
            }

            this.prepareLines( filterId )

        },

        getSnapshots()
        {

            if( true !== this.$store.getters.online
                || this.$core.getFlags().is( 'demouser' ) )
            {
                return
            }

            this.$core.getListSnapshotHelper()
                .getAvailableSnapshots( this.$props.list.localId )
                .then( result =>
                {

                    this.snapshots = result

                } )

        },

        hasHistory( id )
        {
            let values = this.selectedHistory.values.values
            return ( undefined !== values[ id ] )
        },

        historicValue( id )
        {
            return this.selectedHistory.values.values[ id ]
        },

        historyDiffClass( id, value )
        {

            if( -1 < id.indexOf( '___' ) )
            {

                let historic = this.historicValue( id )

                if( ( historic === undefined || historic === '' ) && value !== undefined && value !== '' )
                {
                    return 'historic-undef'
                }
                if( ( historic !== undefined && historic !== '' ) && ( value === '' || value === undefined ) )
                {
                    return 'historic-def'
                }
                if( historic !== value )
                {
                    return 'historic-changed'
                }

            }

            return 'historic-nothing'

        },

        useHistory( id, value )
        {
            this.handleFieldUpdate( id, '', 'update', id, value )
        },

        toggleHeadDimensions()
        {
            this.headClass = '' === this.headClass ? 'minimized' : ''
        },

        toggleShowEditAll()
        {
            this.showEditAll = !this.showEditAll
        },

        setNotification( id )
        {

            let coll = this.$core.cc().getById( id )
            if( undefined !== coll )
            {
                this.notificationActive = true
                this.notificationText = '<strong>' + coll.firstname + ' ' + coll.lastname + '</strong> hat dieses Element geöffnet.'
            }

        },

        setModeByResolution()
        {
            if( true === this.$props.openVertical )
            {
                return
            }
            if( 700 > document.body.offsetWidth )
            {
                this.editorMode = 'vertical'
            }
            else
            {
                this.editorMode = 'horizontal'
            }
        },

        afterMount()
        {

            this.resizeFooter()
            this.totalScore = 0

            if( this.$props.list.listType === 'test' )
            {
                for( let i in this.$props.list.columns )
                {
                    if( undefined !== this.$props.list.columns[ i ].score )
                    {
                        this.totalScore += this.$core.getReformatter().float( this.$props.list.columns[ i ].score )
                    }
                }

                this.$nextTick()
                    .then( () =>
                    {
                        this.updateRatings()
                        this.prepared = true
                    } )

            }
            else
            {
                this.$nextTick()
                    .then( () =>
                    {
                        this.$core.getUi().delay( () =>
                        {
                            this.prepared = true
                        }, 1000 )
                    } )
            }

            if( this.$props.escapeNested )
            {

                let elm      = document.querySelector( '#overlay-' + this.$props.list.localId ),
                    attachTo = document.querySelector( '#app' )

                attachTo.appendChild( elm )

            }

        },

        _archiveMatch( student, timestamp )
        {

            if( student.archived !== true )
            {
                return true
            }

            let temp = student.archiveKey.split( /-/g )
            temp.shift()
            let archiveTimestamp = parseInt( temp.join( '' ) )
            if( archiveTimestamp >= parseInt( timestamp ) )
            {
                return true
            }

            return false

        },

        prepareLines( filterId )
        {

            let refs   = JSON.parse( JSON.stringify( this.$props.students ) ),
                rules  = this.$core.settings().getSetting( 'sortingDirections' ),
                fields = []

            refs = this.$core.getSorter().multiSortObjects( refs, rules.students )

            for( let c in this.$props.list.columns )
            {
                if( 0 < c )
                {
                    let field = this.$props.list.columns[ c ]
                    fields.push( field )
                }
            }

            for( let r in refs )
            {

                if( true !== refs[ r ].archived
                    || this._archiveMatch( refs[ r ], this.$props.list.timestamp ) )
                {

                    let line = []

                    this.studentList.push( refs[ r ].localId )

                    line.push( {
                        name     : 'student',
                        display  : '<div class="sensitive studentName ' + ( this.$core.settings().getSetting( 'supportMode' ) === true ? ' blurred' : '' ) + '">' +
                                   '    <strong>' + refs[ r ].lastname + '</strong>, ' + refs[ r ].firstname +
                                   '</div>',
                        id       : refs[ r ].localId,
                        component: false,
                        disabled : ( filterId !== undefined && refs[ r ].localId !== filterId )
                    } )

                    for( let f in fields )
                    {

                        let valueId = [
                            refs[ r ].localId,
                            this.$core.getSanitizers().cleanId( fields[ f ].caption ),
                            ( 1 + parseInt( f ) )
                        ].join( '___' )

                        let comp = this.fieldTypeToComponent.getComponent( fields[ f ].type )

                        line.push( {
                            name       : fields[ f ].caption,
                            id         : valueId,
                            display    : this.$core.f().isset( this.valueClone ) ? this.valueClone[ valueId ] : undefined,
                            type       : fields[ f ].type,
                            component  : comp.editor,
                            placeholder: comp.placeholder,
                            disabled   : ( filterId !== undefined && refs[ r ].localId !== filterId )
                        } )

                    }

                    this.lines.push( line )

                }

            }

            this.$nextTick()
                .then( () =>
                {
                    if( !this.swipeHandlers )
                    {
                        this.swipeHandlers = true
                        setTimeout( () =>
                        {

                            this.$core.getEventManager().dispatchIndexed( 'on-autosize' )
                            this.setEventHandlers()

                        }, 500 )
                    }
                } )

        },

        resizeFooter()
        {


            let id        = this.$core.getSanitizers().cleanId( this.$props.list.columns[ 0 ].caption ),
                elm       = document.querySelector( '#foot-' + id ),
                container = document.querySelector( '#list-container-' + this.$props.list.localId ),
                table     = document.querySelector( '#list-' + this.$props.list.localId )

            if( null !== container && null !== table && null !== elm )
            {

                let containerHeight = container.offsetHeight,
                    tableHeight     = table.offsetHeight,
                    diff            = containerHeight - tableHeight

                if( 0 < diff )
                {
                    elm.style.height = diff + 'px'
                }
            }

        },

        updateRatings( retry )
        {

            let scores    = this.$core.getTestScoreCalculation().calculate( this.$props.list ),
                foundElms = false

            retry = retry || 0

            for( let s in scores.scores )
            {

                let scoreElm  = document.querySelector( '#rating-' + s ),
                    pointsElm = document.querySelector( '#total-' + s )

                if( null !== pointsElm
                    && null !== scoreElm )
                {

                    foundElms = true

                    if( null !== scoreElm )
                    {
                        if( scores.scores[ s ].complete )
                        {
                            scoreElm.innerHTML = scores.scores[ s ].label || scores.scores[ s ].number
                        }
                        else
                        {
                            scoreElm.innerHTML = '<span class="ignore">&bull;</span>'
                        }
                    }

                    if( null !== pointsElm )
                    {
                        if( scores.scores[ s ].complete )
                        {
                            pointsElm.innerHTML = scores.scores[ s ].total
                        }
                        else
                        {
                            pointsElm.innerHTML = '<span class="ignore">&bull;</span>'
                        }
                    }

                }

            }

            if( !foundElms )
            {

                if( retry >= 10 )
                {
                    this.$core.getLogger().cerror( 'ListEditor::updateRatings', 'failed to update scores as elements did not appear withing a given time...' )
                }
                else
                {

                    retry++
                    this.$core.getCoreTimer()
                        .addTimeout( 'listeditor-updateratings', 200, () =>
                        {
                            this.updateRatings( retry )
                        } )

                }

            }

        },

        _fieldInfo( id )
        {
            let temp    = id.split( /___/g ),
                localId = temp.shift(),
                index   = temp.pop()

            return {
                localId: localId,
                name   : temp.join( '___' ),
                index  : index
            }
        },

        _allColumns()
        {

            let columns = {}
            for( let c in this.$props.list.columns )
            {

                if( undefined !== this.$props.list.columns[ c ].id )
                {

                    let id       = this.$props.list.columns[ c ].id,
                        idx      = parseInt( c ) - 1,
                        repl     = idx + '_',
                        columnId = id.replace( repl, '' )

                    columns[ columnId ] = c

                }

            }
            return columns

        },

        handleFieldUpdate( id, type, method, elmId, value, recursive )
        {

            this.handleCollabData( id, type, method, elmId, value )
            this.updateHistory( id, value )
            this._finalizeFieldUpdate( id, type, method, elmId, value, undefined, recursive )

        },

        handleFieldUpdateCollab( id, type, method, elmId, value, idAuthor, recursive )
        {

            this.updateHistory( id, value, idAuthor )
            this._finalizeFieldUpdate( id, type, method, elmId, value, idAuthor, recursive )

        },

        _finalizeFieldUpdate( id, type, method, elmId, value, idAuthor, recursive )
        {

            if( undefined !== this.$props.list.linkedColumns
                && undefined === recursive )
            {
                this.resolveLinkedColumns( this._fieldInfo( id ), id, value )
            }

            this.valueClone = this.$core.f().isset( this.valueClone ) ? this.valueClone : {}
            this.valueClone[ id ] = value

            if( this.prepared )
            {
                this.$emit( 'update', this.valueClone, this.getHistory( idAuthor ) )
                this.lastLocalChange = Date.now()
            }

            this._handleLineUpdate( id, value, value === undefined )

            if( this.$props.list.listType === 'test' )
            {
                this.$nextTick()
                    .then( () =>
                    {
                        this.updateRatings()
                    } )
            }

            if( -1 < id.indexOf( 'shad-' ) )
            {
                setTimeout( () =>
                {
                    this.originalIdUpdate( id, type, method, elmId, value, recursive )
                }, 300 )
            }

        },

        originalIdUpdate( id, type, method, elmId, value, recursive )
        {

            try
            {
                let temp      = id.split( '___' ),
                    studentId = temp.shift(),
                    fieldId   = temp.join( '___' )

                let studentTest = studentId.split( '-' )
                studentTest.shift()
                studentTest.pop()

                let originalLocalId = studentTest.join( '-' )
                if( this.$core.getUuid().validate( originalLocalId ) )
                {
                    let originalFieldId = originalLocalId + '___' + fieldId
                    this.handleFieldUpdate( originalFieldId, type, method, elmId, value, recursive )
                }

            }
            catch( e )
            {
                return
            }

        },

        _handleLineUpdate( id, value, unset )
        {

            if( 'all-' === id.substring( 0, 4 ) )
            {
                this.editingAllValues[ id ] = value
                return
            }

            value = value || ''
            if( unset )
            {
                value = undefined
            }
            for( let l in this.lines )
            {

                for( let f in this.lines[ l ] )
                {

                    if( this.lines[ l ][ f ].id === id )
                    {
                        this.lines[ l ][ f ].display = value
                    }

                }

            }

        },

        _performAllUpdate( id, type, method, elmId, value )
        {

            this.editingAll = true
            let temp = id.split( /___/g )
            temp.shift()
            let targetId = temp.join( '___' )

            this.valueClone = this.$core.f().isset( this.valueClone ) ? this.valueClone : {}
            for( let s in this.students )
            {
                let id = this.students[ s ].localId + '___' + targetId
                this.valueClone[ id ] = value
                this._handleLineUpdate( id, value )
                this.resolveLinkedColumns( this._fieldInfo( id ), id, value )
            }

            setTimeout( () =>
            {

                this.editingAll = false
                this.lastLocalChange = Date.now()
                this.$emit( 'update', this.valueClone, this.getHistory() )
                if( this.$props.list.listType === 'test' )
                {
                    this.updateRatings()
                }

            }, 500 )

        },

        handleAllUpdate( id, type, method, elmId, value )
        {

            clearTimeout( this.editAllTimer )
            this.resolveLinkedColumns( this._fieldInfo( id ), id, value )

            this.editAllTimer = setTimeout( () =>
            {
                this._performAllUpdate( id, type, method, elmId, value )
            }, 3000 )

        },

        handleRecallDoneDefinition( values )
        {

            let objectClone = JSON.parse( JSON.stringify( this.$props.list ) )
            objectClone.doneFields = values

            this.$emit( 'definitionUpdate', objectClone )
            this.visible = false

        },

        handleClose( clicked )
        {

            this.preSync()
                .then( () =>
                {

                    if( !this.remoteDiffers )
                    {

                        this.visible = false
                        if( clicked )
                        {
                            this.$bus.setTrigger( 'lastObjectType', null )
                            this.$bus.setTrigger( 'lastObjectCreated', null )
                        }

                        if( 0 < this.lastLocalChange )
                        {
                            this.$core.getListSnapshotHelper()
                                .createSnapshot( this.$props.list.localId, this.$props.list.values, this.$props.list._keys )
                        }

                        this.$core.getCoreTimer()
                            .addTimeout( 'listeditor-handleClose', 300, () =>
                            {

                                this.$core.setState( 'editing-object', false )
                                this.$core.setState( 'listEditorOpen', false )
                                this.$emit( 'close' )

                            } )

                    }

                } )

        },

        printTestCutmarked()
        {

            this.$nextTick()
                .then( () =>
                {

                    this.handlePrint( [ 'printTestCutmarked', true ] )
                    this.$core.getUi().delay( () =>
                    {
                        this.modeSwitch( 'vertical' )
                    }, 1000 )

                } )

        },

        handlePrintTestCutmarked()
        {

            this.addEvent( 'after-editor-mode-switched', () =>
            {
                this.printTestCutmarked()
            } )

            this.modeSwitch( 'horizontal' )

        },

        handleHistory( value )
        {

            let found = false

            for( let h in this.snapshots )
            {
                if( this.snapshots[ h ].id === parseInt( value ) )
                {
                    this.selectedHistory = this.snapshots[ h ]
                    found = true
                }
            }

            if( !found )
            {
                this.selectedHistory = undefined
            }

        },

        handlePrint( options )
        {

            let listItem = this.$props.list

            if( listItem.listType === 'test' && 'vertical' === this.editorMode )
            {
                this.handlePrintTestCutmarked()
                return
            }

            let appendTitle = undefined
            if( undefined !== listItem.columns[ 0 ].filterBy )
            {

                let element = this.$core.getBaseClassHelper()
                                  .getObjectById( listItem.columns[ 0 ].filterBy )
                if( 'all' === listItem.columns[ 0 ].filterBy )
                {
                    appendTitle = ' ' + this.$core.t( 'generic-all-students' )
                }
                else
                {
                    appendTitle = this.$core.t( 'object-type-' + element.type )
                    switch( element.type )
                    {
                        case 'class':
                            appendTitle += ' ' + element.classname
                            break
                        case 'group':
                            appendTitle += ' ' + element.groupname
                            break
                        case 'yeargroup':
                            appendTitle += ' ' + element.yeargroupname
                            break
                    }
                }
                let opt = { appendTitle: appendTitle }
                if( undefined !== options )
                {
                    opt[ options[ 0 ] ] = options[ 1 ]
                }

                this.$core.getPrinting()
                    .print( listItem, this.editorMode, undefined, opt )
            }
            else
            {
                let opt = { appendTitle: appendTitle }
                if( undefined !== options )
                {
                    opt[ options[ 0 ] ] = options[ 1 ]
                }

                this.$core.getPrinting()
                    .print( listItem, this.editorMode, undefined, opt )
            }

        },

        handleExcel()
        {
            this.$core.getExcel()
                .export( this.$props.list, 'list-container-' + this.$props.list.localId )
        },

        modeSwitch( which )
        {

            this.valueClone = this.$core.f().isset( this.valueClone ) ? this.valueClone : {}

            this.$nextTick()
                .then( () =>
                {

                    this.editorMode = which
                    this.lines = []
                    this.$nextTick()
                        .then( () =>
                        {

                            this.prepare()
                            this.afterMount()
                            this.$core.getEventManager().dispatchAndRemove( 'after-editor-mode-switched' )

                        } )

                } )

        },

        setEventHandlers()
        {
            for( let l in this.lines )
            {
                this.setTouchEventHandler( l )
            }
        },

        handleSwipe( bareEvent, direction, row )
        {

            if( this.$core.getLicense().forceReadOnly() )
            {
                return
            }

            if( false !== bareEvent
                && undefined !== bareEvent.button
                && 0 === bareEvent.button )
            {
                return
            }

            if( false !== this.swiped
                && parseInt( row ) === this.swiped )
            {
                this.swiped = false
                return
            }

            if( false !== bareEvent
                && undefined !== bareEvent.button
                && 2 === bareEvent.button )
            {
                bareEvent.preventDefault()
            }

            if( undefined === direction && !this.rightOnce )
            {
                direction = 'left'
                this.rightOnce = true
            }

            if( undefined === direction && this.rightOnce )
            {
                direction = 'right'
                this.rightOnce = false
            }

            if( 'left' === direction )
            {

                this.swiped = parseInt( row )
                this.$core.getCoreTimer()
                    .addTimeout( 'listeditor-set-swipe-right', 400, () =>
                    {

                        this.swipedRight = true

                    } )
            }
            else
            {
                this.swipedRight = false
                this.$core.getCoreTimer()
                    .addTimeout( 'listeditor-reset-swipe', 400, () =>
                    {
                        this.swiped = false
                    } )
            }

        },

        setTouchEventHandler( row, retries )
        {

            retries = retries || 0
            let elm = document.querySelector(
                '#' + 'swipeable-' + row + '-' + 0
            )

            if( null !== elm )
            {

                elm.addEventListener( 'mousedown', ( event ) =>
                {
                    this.handleSwipe( event, 'left', row )
                } )

                elm.addEventListener( 'touchstart', ( event ) =>
                {
                    this.touchStartX = event.changedTouches[ 0 ].screenX
                    this.touchStartY = event.changedTouches[ 0 ].screenY
                }, { passive: true } )

                elm.addEventListener( 'touchend', ( event ) =>
                {
                    this.touchEndX = event.changedTouches[ 0 ].screenX
                    this.touchEndY = event.changedTouches[ 0 ].screenY
                    this.handleGesture( elm, row )
                }, { passive: true } )

            }
            else
            {

                retries++
                if( 10 > retries )
                {
                    this.$core.getCoreTimer()
                        .addTimeout( 'listeditor-reset-toucheventhandler-' + row, 250, () =>
                        {

                            this.setTouchEventHandler( row, retries )
                        } )
                }
            }

        },

        handleGesture( element, row )
        {

            let touchDistance = this.touchStartX - this.touchEndX
            if( 0 > touchDistance )
            {
                touchDistance = touchDistance * -1
            }

            let touchDistanceY = this.touchStartY - this.touchEndY
            if( 0 > touchDistanceY )
            {
                touchDistanceY = touchDistanceY * -1
            }

            this.touchDistance = touchDistance
            this.touchDistanceY = touchDistanceY

            if( touchDistanceY > 50 )
            {
                this.$core.getLogger().clog( 'DefaultTouchable:handleGesture', 'assuming scroll event...' )
                return
            }

            if( touchDistance < 60 )
            {

                this.$core.getLogger().clog( 'DefaultTouchable:handleGesture', 'distance too short: assuming click event...' )

            }
            else
            {

                if( this.touchEndX < this.touchStartX )
                {
                    this.handleSwipe( false, 'left', row )
                }

                if( this.touchEndX > this.touchStartX )
                {
                    this.handleSwipe( false, 'right', row )
                }

            }

        },

        handleRowDelete( field )
        {

            this.$core.getUi().showBlocker(
                this.$core.t( 'generic-please-wait' ),
                this.$core.t( 'list-row-is-being-removed' )
            )

            this.$core.getUi().delay( () =>
            {

                this.valueClone = this.$core.f().isset( this.valueClone ) ? this.valueClone : {}
                for( let key in this.valueClone )
                {
                    if( -1 < key.indexOf( field.id ) )
                    {
                        this.updateHistory( key, undefined )
                        delete this.valueClone[ key ]
                    }
                }

                this.lastLocalChange = Date.now()
                this.$emit( 'update', this.valueClone, this.getHistory() )
                this.$core.getEventManager().dispatchIndexed( 'on-reset-input-for', field.id )
                this.swiped = false

                this.$core.getUi().delay( () =>
                {

                    this.$core.getUi().hideBlocker()

                }, 500 )

            }, 500 )

        },

        checkDiffs( original, copy )
        {

            let differences = false

            original = original || {}
            copy = copy || {}

            for( let o in original )
            {
                if( undefined === copy[ o ] )
                {
                    differences = true
                }
                if( original[ o ] !== copy[ o ] )
                {
                    differences = true
                }
            }

            for( let c in copy )
            {
                if( undefined === original[ c ] )
                {
                    differences = true
                }
                if( original[ c ] !== copy[ c ] )
                {
                    differences = true
                }
            }

            return differences

        },

        preSync()
        {
            return new Promise( resolve =>
            {

                return resolve()

                if( true !== this.$store.getters.online
                    || this.$core.getFlags().is( 'demouser' ) )
                {
                    return resolve()
                }

                let start = Date.now()
                this.$core.getCryptoHelper()
                    .waitReady()
                    .then( () =>
                    {

                        this.$core.getSyncWorker()
                            .singleObjectSync( this.$props.list.localId )
                            .then( list =>
                            {

                                if( 0 === Object.keys( list.result ).length )
                                {
                                    return resolve()
                                }
                                for( let i in list.result )
                                {

                                    let element     = list.result[ i ],
                                        tsmpRemote  = new Date( element.datetime_updated ).getTime(),
                                        differences = false,
                                        localKey    = this.$core.getCryptoHelper().getLocalKey( element )

                                    this.$core.getCryptoHelper()
                                        .decryptElement( localKey, element.object )
                                        .then( decrypted =>
                                        {

                                            if( tsmpRemote > this.localTimestamp
                                                && tsmpRemote > ( this.lastLocalChange + this.localChangeThreshold ) )
                                            {
                                                differences = true
                                            }

                                            if( false !== decrypted )
                                            {
                                                this.remoteValues = decrypted.values

                                                if( differences
                                                    && undefined !== decrypted.values
                                                    && this.checkDiffs( this.remoteValues, this.$props.list.values ) )
                                                {
                                                    this.localTimestamp = tsmpRemote
                                                    this.remoteDiffers = true
                                                    this.showMerge = false
                                                    this.mergeRemote()
                                                    return resolve()
                                                }
                                                else
                                                {
                                                    return resolve()
                                                }
                                            }

                                        } )

                                }

                            } )
                            .catch( e =>
                            {
                                return resolve()
                            } )

                    } )

            } )
        },

        showDiff( id, state )
        {
            this.tdmarks[ id ] = state
        },

        mergeRemote()
        {

            if( undefined === this.remoteValues )
            {
                return
            }

            this.remoteDiffers = false
            this.showMerge = true

            this.$nextTick()
                .then( () =>
                {

                    if( undefined !== this.$props.list
                        && undefined !== this.$props.list.values )
                    {

                        for( let v in this.remoteValues )
                        {

                            let value = this.remoteValues[ v ]
                            if( undefined === this.$props.list.values[ v ] )
                            {
                                this.showDiff( v, 'added' )
                                this.placeValue( v, value )
                            }
                            if( value !== this.$props.list.values[ v ] )
                            {
                                this.showDiff( v, 'changed' )
                                this.placeValue( v, value )
                            }

                        }

                        for( let v in this.$props.list.values )
                        {

                            if( undefined === this.remoteValues[ v ] )
                            {
                                this.showDiff( v, 'deleted' )
                            }

                        }

                    }

                } )

        },

        handleListFilter( filter, value )
        {
            this.initialized = false
            this.listFilterInline = value
            this.initialized = true
        }

    }

}
</script>