<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-if="visible" class="default-form-overlay template-gallery">
            <div class="default-form-container">
                <div class="default-form-wrapper">
                    <Button type="close"
                            @clicked="handleClose()"
                            addClass="absolute top right inverted"/>
                    <h2>{{ $core.t( 'media-gallery' ) }}</h2>

                    <div class="form">
                        <div v-if="!processing
                               && 0 < media.length"
                             key="media-gallery">
                            <Pagination :noMargin="true" :page="page" :pages="pages"
                                        @pageChange="page = $event"/>
                            <div class="clearfix"></div>
                            <div class="list-template-box">
                                <div v-for="( media, index ) in media"
                                     :key="'media-'+index">
                                    <template v-if="-1 < pages[( page - 1 )].indexOf( media.localId )">
                                        <div class="list-template-selector gallery">
                                            <Media :item="media"
                                                   :emitClick="true"
                                                   @clicked="mediaSelected( media.localId ) "/>
                                        </div>
                                    </template>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <Pagination :noMargin="true" :page="page" :pages="pages"
                                        @pageChange="page = $event"/>
                            <div class="clearfix"></div>

                            <InlineMessage v-if="0 === media.length
                                             && !processing"
                                           :title="$core.t( 'default-caption-empty' )"
                                           :message="$core.t( 'default-message-empty-templates' )"
                                           classname="nothing"
                                           :active="true"/>
                            <PreheatingIndicator v-if="processing"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
/*eslint-disable*/
import Pagination          from "@/components/elements/defaults/Pagination";
import PreheatingIndicator from "@/components/elements/indicators/PreheatingIndicator";
import InlineMessage       from "@/components/messages/InlineMessage";
import Media               from "@/components/elements/viewItems/Media";

export default {

    name      : 'MediaGalleryOverlay',
    components: { Media, InlineMessage, PreheatingIndicator, Pagination },
    emits     : [ 'close' ],

    props: {
        setup: { Type: Object, required: true }
    },

    data()
    {
        return {
            visible   : false,
            processing: false,
            media     : false,
            pages     : [],
            page      : 1
        }
    },

    mounted()
    {

        this.$nextTick()
            .then( () =>
            {

                this.visible = true
                setTimeout( () =>
                {

                    this.$core.getUi()
                        .hideBlocker()

                }, 500 )

            } )

    },

    created()
    {

        this.$core
            .getBaseClassHelper()
            .get( 'media' )
            .listAll()
            .then( media =>
            {

                this.media = media
                this.preparePagination()

            } )

    },

    methods: {

        preparePagination()
        {

            this.pages = []

            let idx = 0,
                set = []

            for( let i in this.media )
            {
                set.push( this.media[ i ].localId )
                idx++
                if( 9 === idx )
                {
                    idx = 0
                    this.pages.push( JSON.parse( JSON.stringify( set ) ) )
                    set = []
                }
            }

            if( 0 < set.length )
            {
                this.pages.push( JSON.parse( JSON.stringify( set ) ) )
            }

        },

        mediaSelected( localId )
        {
            this.$core.getEventManager()
                .dispatch( 'on-media-gallery-select-' + this.$props.setup.id, localId )
            this.handleClose()
        },

        handleClose()
        {
            this.visible = false
            setTimeout( () =>
            {
                this.$emit( 'close' )
            }, 300 )
        }

    }
}
</script>