<template>
    <div :class="'indicator processing '+className">&nbsp;</div>
</template>

<script>
export default {
    name : 'ProcessingIndicator',
    props: {
        className: { Type: String, required: false, default: 'totop' }
    }
}
</script>