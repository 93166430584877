import { compressToBase64, decompressFromBase64 } from 'lz-string'

export default class CompressionHelper
{

    constructor( core )
    {

        if( !CompressionHelper.instance )
        {

            this.logger = core.getLogger()
            this.logSign = 'WSLARGE Core::CompressionHelper'
            CompressionHelper.instance = this

        }

        return CompressionHelper.instance

    }

    compress( string )
    {

        let result = compressToBase64( string )
        this.logger.cdebug( this.logSign, 'compressed', string.length, 'characters to', result.length )
        return result

    }

    decompress( string )
    {

        let result = decompressFromBase64( string )
        this.logger.cdebug( this.logSign, 'decompressed', result.length, 'characters from', string.length )
        return result

    }

}