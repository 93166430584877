import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class TeamsObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !TeamsObjectClass.instance )
        {

            let refList = [ 'teamname', 'color', 'colleagues', 'timestamp', 'update' ]

            let personalAttributesStatesList = []

            super( core, 'team', refList, personalAttributesStatesList )
            this.hasDetailView = true
            this.objectType = 'teams'

            TeamsObjectClass.instance = this
        }

        return TeamsObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'teamname',
                caption    : 'Name',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'classname'
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                styleUpdate: true,
                reformatter: null,
                optional   : false,
                validator  : 'color'
            },
            {
                ref         : 'colleagues',
                caption     : 'Kolleg:innen',
                type        : 'RowAdder',
                component   : 'ColleagueSelector',
                values      : [],
                idType      : 'uuid',
                asPlainList : true,
                reformatter : null,
                optional    : false,
                validator   : 'select',
                allowForeign: false,
                dragBlocked : true,
                unique      : true
            }
        ]
    }

    getTabs()
    {
        return [ 'own', 'foreign' ]
    }

    getFunctions()
    {
        return [ 'edit', 'share', 'delete' ]
    }

}