<template>
    <div class="due-wrap">
        <div :class="'due-box positioned-center-y '+dueClass">
            {{ dueDays }}T
        </div>
    </div>
</template>

<script>
export default {
    
    name : 'DueBox',
    props: {
        dueDays: { type: Number, required: true }
    },
    
    computed: {
        dueClass()
        {
            
            if( this.$props.dueDays < 0 )
            {
                return 'overdue'
            }
            
            if( this.$props.dueDays < 4 )
            {
                return 'dueshortly'
            }
            
            return 'due'
            
        }
    }
    
}
</script>