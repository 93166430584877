import QueueUploadWorker   from '@/classes/Core/Workers/QueueWorker/QueueUploadWorker'
import QueueDeletionWorker from '@/classes/Core/Workers/QueueWorker/QueueDeletionWorker'
import QueueMessageWorker  from '@/classes/Core/Workers/QueueWorker/QueueMessageWorker'

export default class QueueWorker
{

    constructor( core )
    {
        if( !QueueWorker.instance )
        {

            this.core = core
            this.f = core.f()
            this.logger = core.getLogger()
            this.uuid = core.getUuid()
            this.store = core.getStore()
            this.timer = core.getCoreTimer()
            this.eventManager = core.getEventManager()
            this.crypto = core.getCryptoHelper()
            this.database = core.getDatabase()
            this.flags = core.getFlags()

            this.lastRun = 0
            this.interval = 1000

            this.setState = ( key, value ) =>
            {
                core.setState( key, value )
            }

            this.publicKey = false
            this.jobQueue = []

            this.ready = true

            this.logger.cconstructed( 'QueueWorker:constructor', 'queueworker setting up...' )

            this.eventManager.add( 'after-deletion-done', ( jobId ) =>
            {
                this.handleDeletionDone( jobId )
            } )
            this.resetIndex = this.eventManager.addIndexed( 'core-component-reset', () =>
            {
                this.reset()
            } )

            this.deletions = []
            this.updates = []

            this.setup( core )

            QueueWorker.instance = this

        }
        return QueueWorker.instance
    }

    reset()
    {

        this.logger.clog( 'QueueWorker:reset', 'resetting queueworker to state zero after core components reset trigger' )
        this.uploadWorker.destruct()
        this.deletionWorker.destruct()
        this.messagesWorker.destruct()

        this.timer.removeInterval( 'queueworker-main-thread' )

        this.performSetup( this.core )

    }

    setup( core )
    {
        this.eventManager.append( 'on-first-sync-done', () =>
        {
            this.performSetup( core )
        } )
    }

    handleDeletionDone( jobId )
    {
        setTimeout( () =>
        {

            let index = this.deletions.indexOf( jobId )
            if( -1 < index )
            {
                this.deletions.splice( index, 1 )
            }

        }, ( 180 * 1000 ) )
    }

    performSetup( core, skipEvents )
    {

        this.logger.clog( 'QueueWorker:performSetup', 'setting up queues for uploads, deletions, shares and messages...' )

        this.timer.addInterval( 'queueworker-main-thread', this.interval, () =>
        {
            this.run()
        } )

        this.publicKey = this.store.getters.publicKey
        this.uploadWorker = new QueueUploadWorker( core )
        this.deletionWorker = new QueueDeletionWorker( core )
        this.messagesWorker = new QueueMessageWorker( core )

        if( this.store.getters.authorized )
        {
            this.run()
        }
        else
        {
            if( undefined === skipEvents )
            {
                this.eventManager.append( 'on-login-state-change', () =>
                {
                    this.reset()
                } )
            }
        }

        this.eventManager.dispatch( 'on-queueworker-ready' )
    }

    /*eslint-disable*/
    _getAdditionalKeys( job, object )
    {

        return new Promise( resolve =>
        {

            let localId = ( undefined === object.localId ? job.id : object.localId );

            if( undefined === job.additionalKeys
                || false === job.additionalKeys
                || ( Array.isArray( job.additionalKeys ) && 0 === job.additionalKeys.length ) )
            {

                this.database.readObject( localId )
                    .then( dbObject =>
                    {

                        let additional = []
                        for( let k in dbObject.object.keys )
                        {
                            let uuid = dbObject.object.keys[ k ].uuid
                            if( uuid !== this.store.getters.uuid )
                            {
                                additional.push( uuid )
                            }
                        }

                        return resolve( additional )

                    } )
                    .catch( () =>
                    {
                        return resolve( [] )
                    } )

            }
            else
            {
                return resolve( job.additionalKeys )
            }

        } )

    }

    prepareForStorage( job )
    {

        return new Promise( resolve =>
        {

            let object = JSON.parse( job.objectJson )

            this._getAdditionalKeys( job, object )
                .then( additionalKeys =>
                {

                    job.additionalKeys = additionalKeys

                    this.crypto.encryptObject(
                            this.publicKey,
                            job.objectJson,
                            job.localKey,
                            undefined,
                            job.additionalKeys )
                        .then( encryptedObject =>
                        {

                            if( false === encryptedObject )
                            {
                                return false
                            }

                            if( undefined !== object.remoteId )
                            {
                                encryptedObject.remoteId = object.remoteId
                            }

                            return resolve( {
                                localId     : ( undefined === object.localId ? job.id : object.localId ),
                                crypted     : encryptedObject,
                                referenceKey: object.referenceKey
                            } )

                        } )

                } )

        } )

    }

    setupReshareHandler( isUpdateJob, jobId, objectType )
    {

        if( !isUpdateJob )
        {
            return
        }
        this.eventManager.append( 'on-queue-done-' + jobId, ( localId =>
        {
            this.eventManager.dispatch( 'share-process-update', [ localId, objectType ] )
        } ) )

    }

    handleMassUpdate( job )
    {

        return new Promise( resolve =>
        {

            let objectList = JSON.parse( job.objectJson )
            let promises = []
            let stores = []

            for( let o in objectList )
            {

                let storeJob = {
                    objectJson: JSON.stringify( objectList[ o ].raw ),
                    localKey  : objectList[ o ].raw.localKey
                }

                let prepared = this.prepareForStorage( storeJob )
                stores.push( objectList[ o ].raw.localId )
                promises.push( () =>
                {
                    return this.database.writeObject( prepared.crypted, prepared.localId, job.objectType )
                } )

            }

            this.eventManager.dispatch( 'on-mxregistry-full-refresh', job.objectType + 's' )

            this.f.promiseRunner( promises )
                .then( () =>
                {

                    setTimeout( () =>
                    {

                        let proms = []
                        for( let s in stores )
                        {
                            this.setupUpload( stores[ s ] )
                            proms.push( () =>
                            {
                                return this.database.write( 'uploads', Date.now() + '-' + stores[ s ], job.objectType )
                            } )
                        }

                        this.f.promiseRunner( proms )

                    }, 500 )

                } )

        } )
    }

    process( job )
    {

        return new Promise( resolve =>
        {

            if( undefined === job )
            {
                return resolve()
            }

            let isUpdateJob = false

            switch( job.type )
            {
                case 'update':
                    isUpdateJob = true
                // falls through
                case 'store':
                case 'localupdate':
                case 'localcreate':
                    this.prepareForStorage( job )
                        .then( prepared =>
                        {
                            if( -1 === this.deletions.indexOf( prepared.localId ) )
                            {

                                this.database.writeObject( prepared.crypted, prepared.localId, job.objectType )
                                    .then( () =>
                                    {

                                        this.logger.log( 'QueueWorker:process', 'job ' + job.id + ': database update done for #' + prepared.localId )

                                        this.eventManager.dispatch( 'on-object-creation', [ job.objectType, prepared.localId ] )
                                        this.eventManager.dispatch( 'on-mxregistry-update', {
                                            type   : job.objectType,
                                            localId: prepared.localId
                                        } )

                                        this.eventManager.dispatchAndRemove( 'share-resolve-' + prepared.localId )
                                        this.eventManager.dispatchAndRemove( 'storable-after-update-' + prepared.localId )

                                        if( 'local' !== job.type.substring( 0, 5 ) )
                                        {

                                            if( !this.flags.is( 'demouser' ) )
                                            {

                                                this.database.write( 'uploads', prepared.localId, job.objectType )
                                                    .then( () =>
                                                    {

                                                        this.setupReshareHandler( isUpdateJob, job.id, job.objectType )
                                                        this.logger.log( 'QueueWorker:process', 'job ' + job.id + ' written to database and enqueued for upload...' )
                                                        if( !isUpdateJob )
                                                        {
                                                            this.eventManager.dispatch( 'set-bus-trigger', [ 'lastObjectType', job.objectType ] )
                                                            this.eventManager.dispatch( 'set-bus-trigger', [ 'lastObjectCreated', prepared.localId ] )
                                                        }
                                                        this.eventManager.dispatchAndRemove( 'on-queue-done-' + job.id, prepared.localId )
                                                        this.ready = true
                                                        return resolve()

                                                    } )

                                            }
                                            else
                                            {
                                                if( !isUpdateJob )
                                                {
                                                    this.eventManager.dispatch( 'set-bus-trigger', [ 'lastObjectType', job.objectType ] )
                                                    this.eventManager.dispatch( 'set-bus-trigger', [ 'lastObjectCreated', prepared.localId ] )
                                                }

                                                this.eventManager.dispatchAndRemove( 'on-queue-done-' + job.id, prepared.localId )
                                                this.ready = true
                                                return resolve()
                                            }

                                        }
                                        else
                                        {
                                            this.logger.log( 'QueueWorker:process', 'job ' + job.id + ' written to database (local update).' )
                                            this.eventManager.dispatchAndRemove( 'on-queue-done-' + job.id, prepared.localId )
                                            this.ready = true
                                            return resolve()
                                        }

                                    } )
                                    .catch( error =>
                                    {
                                        this.logger.clog( 'QueueWorker:process', 'job ' + job.id + ' failed: ' + error )
                                        this.eventManager.dispatchAndRemove( 'on-queue-done-' + job.id, prepared.localId )
                                        this.ready = true
                                        return resolve()
                                    } )
                            }
                            else
                            {
                                this.logger.cerror( 'QueueWorker:process', 'job ' + job.id + ' in deletion queue: skipped for now.' )
                                this.eventManager.dispatchAndRemove( 'on-queue-done-' + job.id, prepared.localId )
                                this.ready = true
                                return resolve()
                            }

                        } )
                    break
                case 'massupdate':
                    this.handleMassUpdate( job )
                        .then( () =>
                        {
                            this.ready = true
                            return resolve()
                        } )
                    break
                case 'message':
                    this.database.write( 'socketmessages', job.id, job )
                        .then( () =>
                        {
                            this.ready = true
                            return resolve()
                        } )
                    break
                case 'delete':
                    this.deletions.push( job.id )
                    this.database.write( 'deletions', job.id, job.objectType )
                        .then( () =>
                        {

                            this.ready = true
                            return resolve()

                        } )
                    break
                case 'share':
                    this.database.write( 'shares', job.id, JSON.stringify( job.colleagues ) )
                        .then( () => {
                            this.database.write( 'sharequeue', job.id, job.objectType )
                            this.ready = true
                            return resolve()
                        })
                    break
                default:
                    this.logger.cerror( 'QueueWorker:process', 'job ' + job.id + ' of unknown type "' + job.type + '" --> skipped.' )
                    this.ready = true
                    return resolve()
            }

        } )

    }

    getKeyUuids( localId )
    {
        return new Promise( resolve =>
        {

            let keys = []
            let uuids = []

            this.database.readObject( localId )
                .then( result =>
                {

                    for( let k in result.object.keys )
                    {
                        let key = result.object.keys[ k ]
                        if( key.uuid !== this.store.getters.uuid
                            && -1 === uuids.indexOf( key.uuid ) )
                        {

                            uuids.push( key.uuid )
                            keys.push( key.uuid )

                        }
                    }

                    return resolve( keys )

                } )
                .catch( () =>
                {
                    return resolve( keys )
                } )

        } )
    }

    cleanKeys( additionalKeys )
    {
        let newKeys = []
        for( let k in additionalKeys )
        {
            if( -1 === newKeys.indexOf( additionalKeys[ k ] ) )
            {
                newKeys.push( additionalKeys[ k ] )
            }
        }
        return newKeys
    }

    enqueue( jobType, jobId, objectType, objectJson, additionalKeys, localKey, trigger )
    {

        if( undefined !== additionalKeys )
        {
            additionalKeys = this.cleanKeys( additionalKeys )
        }

        if( undefined === additionalKeys )
        {

            this.getKeyUuids( objectJson )
                .then( keys =>
                {

                    let dbJob = {
                        id            : jobId,
                        type          : jobType,
                        objectType    : objectType,
                        objectJson    : objectJson,
                        additionalKeys: keys,
                        localKey      : localKey
                    }

                    this.database.write( 'queuedJobs', jobId, dbJob )
                        .then( () =>
                        {
                            this.logger.clog( 'QueueWorker:enqueue', 'enqueued job of type "' + jobType + '" with id ' + jobId + ', containing ' + keys.length + ' additional keys' )
                        } )

                } )
        }
        else
        {

            let dbJob = {
                id            : jobId,
                type          : jobType,
                objectType    : objectType,
                objectJson    : objectJson,
                additionalKeys: additionalKeys,
                localKey      : localKey
            }

            this.database.write( 'queuedJobs', jobId, dbJob )
                .then( () =>
                {
                    this.logger.clog( 'QueueWorker:enqueue', 'enqueued job of type "' + jobType + '" with id ' + jobId + ', containing ' + additionalKeys.length + ' additional keys' )
                } )

        }

        if( trigger )
        {
            this.run()
        }

    }

    enqueueSocketMessage( message )
    {

        let jobId   = this.uuid.generate()
        this.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

        return jobId

    }

    run()
    {

        this.lastRun = Date.now()

        if( this.store.getters.authorized )
        {

            if( this.ready )
            {

                this.database.readAllObjects( 'queuedJobs' )
                    .then( jobs =>
                    {

                        if( 0 < jobs.length )
                        {

                            this.ready = false

                            let dbJob = jobs.shift(),
                                job   = dbJob.item

                            this.process( job )
                                .then( () =>
                                {

                                    this.ready = true
                                    this.database.delete( 'queuedJobs', dbJob.key )
                                    if( 0 < jobs.length )
                                    {
                                        this.run()
                                    }
                                } )

                        }

                    } )
                    .catch( e =>
                    {

                        this.ready = true
                        this.logger.clog( 'QueueWorker:run', 'failed: ' + e )

                    } )

            }

        }

    }

    destruct()
    {
        this.eventManager.removeIndexedCallback( 'core-component-reset', this.resetIndex )
        this.uploadWorker.destruct()
        this.deletionWorker.destruct()
        this.messagesWorker.destruct()
        delete QueueWorker.instance
    }

}