<template>
    <div v-if="!$core.getState( 'excel-exporting' )">
        <div :class="'textarea '+( cssClass || 'bare' )+' '+foreignHighlighted"
             :id="'display_elm_'+refName"
             :data-placeholder="placeholder !== '' ? placeholder : 'gib hier deinen Text ein'"
             :disabled="disabled"
             :contenteditable="!disabled"
             :style="'width:'+this.elementWidth+'px!important; height:'+elementHeight+'px'"
             @click="$core.f().skip"
             @focusin="handleFocus()"
             @focusout="handleBlur()"
             @blur="handleChange( true )"
             @input="handleChange( false )"
             @change="handleChange( false )"
             @keyup="handleChange( false )"
             v-html="displayValue"></div>
        <div class="clearfix"></div>
        <input type="text" class="hidden" :id="'form-element-'+refName" :value="emittedValue"
               @change="handleHiddenChange"/>
        <div :class="'textarea '+cssClass+' ghost'"
             :id="'ghost_elm_'+refName"
             :style="'width:'+$core.settings().getSetting( 'textAreaWidth' )+'px!important'"
             v-html="displayValue"></div>
    </div>
    <p v-else>
        {{ $core.f().toPlain( displayValue ) }}
    </p>
</template>

<script>

import MixinResettableComponent from '@/mixins/MixinResettableComponent'

export default {
    name  : 'TextArea',
    mixins: [ MixinResettableComponent ],

    props: {
        id         : { Type: String, required: true },
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        highlighted: { Type: Object, required: false, default: [] },
        display    : { Type: String, required: false },
        disabled   : { Type: Boolean, required: false, default: false },
        eventKey   : { Type: String, required: false },
        cssClass   : { Type: String, required: false },
        static     : { Type: Boolean, required: false, default: true },
        placeholder: { Type: String, required: false, default: '' },
        tdAncestor : { Type: String, required: false, default: '' }
    },

    emits: [ 'update', 'change', 'focussed', 'blurred' ],

    data()
    {
        return {
            focussed         : false,
            handledHiddenOnce: false,
            changeTimer      : false,
            valueChanged     : false,
            initializing     : false,
            initialValue     : false,
            displayValue     : '',
            emittedValue     : '',
            mounted          : false,
            hiddenElement    : null,
            divElement       : null,
            elementWidth     : 0,
            elementHeight    : 119,
            startup          : Date.now()
        }
    },

    watch: {
        value       : {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( true === this.$props.static
                    || this.focussed )
                {
                    return
                }
                if( oldValue !== undefined
                    && ( newValue !== oldValue || newValue !== this.displayValue ) )
                {
                    this.displayValue = undefined !== this.$props.value ? this.$core.f().toHtml( this.$props.value ) : ''
                }
            }
        },
        displayValue: {
            immediate: true,
            handler( newValue, oldValue )
            {

                if( oldValue !== undefined
                    && newValue !== oldValue
                    && oldValue !== '' )
                {
                    this.valueChanged = true
                }
            }
        }
    },

    computed: {
        foreignHighlighted()
        {
            return ( -1 < this.$props.highlighted.indexOf( this.$props.refName ) ? 'highlighted' : '' )
        }
    },

    created()
    {
        this.elementWidth = this.$core.settings().getSetting( 'textAreaWidth' )

    },

    mounted()
    {

        this.emittedValue = '' + this.$props.value
        this.initialValue = ( undefined !== this.$props.value ? '' + this.$props.value : '' )
        this.displayValue = this.$core.f().toHtml( this.$props.value )
        this.addEventListener()
        this.$core.getEventManager()
            .add( 'on-value-change-' + this.$props.refName, ( value ) =>
            {
                this.displayValue = this.$core.f().toHtml( value )
            } )

        this.hiddenElement = document.querySelector( '#form-element-' + this.$props.refName )
        this.divElement = document.querySelector( '#display_elm_' + this.$props.refName )
        this.mounted = true

        this.$nextTick()
            .then( () =>
            {
                this.autoSize()
            } )

    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .removeIndexedCallback( 'on-autosize', this.eventIndex )
        this.$core.getEventManager()
            .remove( 'on-value-change-' + this.$props.refName )
    },

    methods: {
        /*eslint-disable*/
        autoSize()
        {

            if( 'list' !== this.$props.cssClass
                || '' === this.$props.tdAncestor )
            {
                return
            }

            let ghost     = document.querySelector( '#ghost_elm_' + this.$props.refName ),
                ancestor  = document.querySelector( '#' + this.$props.tdAncestor ),
                newHeight = ghost.scrollHeight + 20

            if( 119 < newHeight )
            {
                this.elementHeight = newHeight
            }

            if( null !== ancestor
                && ancestor.scrollWidth > this.elementWidth )
            {
                this.elementWidth = ancestor.scrollWidth
            }

        },

        emitValue()
        {
            this.$emit( 'change', this.$props.id, this.emittedValue )
            if( !this.initializing )
            {
                this.$emit( 'update', this.$props.refName, 'textArea', 'update', undefined, ( '' !== this.emittedValue.trim() ? this.emittedValue : undefined ) )
            }
        },

        reformatLineBreaks( value )
        {
            if( undefined !== value )
            {

                value = value.replace( /<\/div>/g, '' )
                value = value.replace( /<div>/g, '\n' )
                return this.$core.f().toHtml( value )

            }
            return ''
        },

        handleHiddenChange( event )
        {
            this.divElement.innerHTML = this.hiddenElement.value
            this.handleChange( event )
            this.handledHiddenOnce = true
        },

        resetTimer()
        {
            clearTimeout( this.changeTimer )
            this.changeTimer = false
        },

        handleChange( immediate )
        {

            let innerHTML = JSON.parse( JSON.stringify( this.divElement.innerHTML ) )

            if( innerHTML === this.$props.value )
            {
                return
            }

            let div       = document.createElement( 'div' ),
                text      = this.$core
                                .f()
                                .toPlain( innerHTML )

            div.innerHTML = text

            this.$nextTick()
                .then( () => {

                    this.hiddenElement.value = this.reformatLineBreaks( div.innerHTML )
                    this.emittedValue = this.hiddenElement.value

                    if( this.emittedValue !== this.initialValue
                        || Date.now() > this.startup + 500 )
                    {

                        if( false !== this.changeTimer )
                        {
                            this.resetTimer()
                        }

                        if( !immediate )
                        {
                            this.changeTimer = setTimeout( () =>
                            {

                                this.resetTimer()
                                this.emitValue()

                            }, 100 )
                        }
                        else
                        {
                            this.emitValue()
                        }

                    }

                })

        },

        addEventListener()
        {

            return /*
            if( null !== this.divElement )
            {
                this.divElement.addEventListener( 'paste', ( event ) =>
                {
                    event.preventDefault()
                    let text = event.clipboardData.getData( 'text/plain' )
                    document.execCommand( 'insertHTML', false, text )
                } )
            }*/

        },

        handleFocus()
        {
            this.focussed = true
            this.$emit( 'focussed', this.$props.refName )
        },

        handleBlur()
        {
            this.focussed = false
            this.$emit( 'blurred', this.$props.refName )
        }
    }

}
</script>