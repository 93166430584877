<template>
    <div class="list-element ratesmileselector">
        <div v-for="rate in rates" :key="'plant-'+rate">
            <div class="ratesmile-display"
                 v-bind:class="'rate-'+rate+( rate === value ? ' active' : '' )" v-html="imgs[rate]">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'listElement-ratesmileselector',
    props: {
        value: { type: Number, required: false, default: undefined }
    },
    data()
    {
        return {
            rates        : [ 0, 1, 2, 3, 4 ],
            imgs         : [ '&#x1f641;', '&#x1f615;', '&#x1f610;', '&#x1f642;', '&#x1f600;' ],
        }
    }
    
}
</script>
