function _emptyCell( x, y )
{
    return {
        x    : x,
        y    : y,
        text : '',
        style: 'normal'
    }
}

function _fromHtml( text )
{

    if( text === 'undefined' )
    {
        return '- kein Text vorhanden -'
    }
    text = text.replace( /&nbsp;/g, ' ' )
    text = text.replace( /<span class="bold">/g, "" )
    text = text.replace( /<(u|em|ul)>/g, "" )
    text = text.replace( /<\/(u|span|ul|em)>/g, "" )
    text = text.replace( /<\/li>/g, "<br/>" )
    text = text.replace( /<li>/g, '• ' )

    return text

}

function _getDef( doc, body, maxWidth )
{

    let maxTextWidth = maxWidth - 4
    let textOut = _fromHtml( body.replace( /<br>/g, '<br/>' ) )
    let lines = textOut.split( '<br/>' )
    let toSplit = lines.join( "\n" )
    let splitText = doc.splitTextToSize( toSplit, maxTextWidth )

    return {
        def   : 'text',
        type  : 'text',
        width : maxTextWidth,
        images: undefined,
        text  : splitText
    }

}

function _getRows( element, doc, maxWidth )
{

    let rows = [],
        defs = [],
        img  = [],
        row  = [],
        body = element.body,
        def  = _getDef( doc, body, maxWidth )

    defs.push( [ def ] )

    row.push( def.text )
    rows.push( row )

    let dimensions = []
    for( let r in rows )
    {
        let dim = []
        for( let f in rows[ r ] )
        {
            let dims = doc.getTextDimensions( rows[ r ][ f ] )
            dim.push( { w: dims.w, h: dims.h } )
        }
        dimensions.push( dim )
    }

    return {
        rows      : rows,
        defs      : defs,
        images    : img,
        dimensions: dimensions
    }

}

function _getReferenceMarkers( element )
{

    let list = []
    let printable = document.querySelector( '#printable-' + element.localId )
    if( null !== printable )
    {
        let markers = printable.querySelectorAll( '.reference-marker' )
        if( 0 < markers.length )
        {
            for( let m in markers )
            {
                let marker = markers[ m ]
                if( marker instanceof HTMLElement )
                {

                    let col   = window.getComputedStyle( marker ).backgroundColor,
                        color = [],
                        labelText

                    labelText = marker.innerText
                    if( null !== col && undefined !== col )
                    {
                        col = col.replace( /(rgb\(|\)| )/g, '' )
                        let colString = col.split( /,/ )
                        for( let c in colString )
                        {
                            color.push( parseInt( colString[ c ] ) )
                        }
                    }
                    else
                    {
                        color = [ 224, 224, 224 ]
                    }
                    list.push( {
                        label: labelText,
                        color: color
                    } )
                }
            }
        }
    }

    return list

}

function _getEmbeddedTodoList( doc, element, y )
{

    let embedded = null

    if( undefined !== element.todos
        && element.todos instanceof Array )
    {

        let todos = element.todos
        if( 0 < todos.length )
        {

            embedded = []

            let text = 'Dieses Todo enthält zu erledigende Teilaufgaben:'
            let dims = doc.getTextDimensions( text )
            let height = dims.h + 1
            embedded.push( [ {
                x     : 12,
                y     : y,
                text  : text,
                style : 'bold',
                height: height,
                type  : 'text'
            } ] )

            y += height

            for( let t in todos )
            {

                let e = []
                let dims = doc.getTextDimensions( todos[ t ] )
                let height = dims.h + 1

                e.push( {
                    x     : 17,
                    y     : y,
                    text  : todos[ t ],
                    height: height,
                    type  : 'text',
                    style : 'normal'
                } )

                let image = element.todos_done[ todos[ t ] ] === true ? 'todo-checked.png' : 'todo-unchecked.png'
                e.push( {
                    x      : 12,
                    y      : y,
                    height : 3,
                    size   : 3,
                    padding: 0,
                    img    : image,
                    type   : 'image'
                } )

                y += height
                embedded.push( e )

            }

        }

    }

    return {
        embedded: embedded,
        y       : y
    }

}

function _getTopTodoImage( element, maxWidth, pmAddX )
{
    if( undefined !== element.done )
    {
        let image = 1 === parseInt( element.done ) ? 'todo-checked.png' : 'todo-unchecked.png'
        return {
            x      : ( pmAddX + maxWidth - 8 ),
            y      : 10,
            height : 8,
            size   : 8,
            padding: 0,
            img    : image,
            type   : 'image'
        }
    }
}

function _appendElementTypeHeaders( doc, element, topRowCells, topRowDefs, topRowImages, maxWidth, rects, markerX,
                                    friendlyTimestamp, pmAddX )
{

    let label,
        fillcolor,
        emb,
        dims,
        startX

    switch( element.type )
    {
        case 'todo':

            label = 'fällig am ' + element.duedate_friendly
            fillcolor = '#ffcece'
            if( 1 === parseInt( element.done ) )
            {
                label = 'erledigt am ' + friendlyTimestamp.friendlyDate( element.done_timestamp )
                fillcolor = '#ceffce'
            }

            topRowCells.push( _emptyCell( maxWidth - 12, 30 ) )

            emb = _getTopTodoImage( element, maxWidth, pmAddX )
            topRowDefs.push( {
                def   : 'image',
                type  : 'image',
                width : 8,
                y     : 0,
                x     : ( pmAddX + emb.x ),
                images: [ emb.img ]
            } )
            topRowImages.push( [ emb ] )

            dims = doc.getTextDimensions( label )
            startX = markerX - dims.w - 1

            rects.push( {
                fillcolor: fillcolor,
                label    : {
                    text: label,
                    dims: dims
                },
                rect     : {
                    x: startX, y: 10, x2: ( dims.w + 0.5 ), y2: ( dims.h + 0.5 ), type: 'F'
                }
            } )
            break

    }
}

function _preparePlainAsList( doc, element, y, color, maxWidth, friendlyTimestamp, punchMarks, landscape )
{

    let listSetup = {
            rects     : [],
            lines     : [],
            cells     : [],
            rowHeights: []
        },
        pmAddX    = ( punchMarks && !landscape ? 10 : 0 ),
        images    = [],
        defs      = []

    let headerSet = [
        'erstellt am ' + friendlyTimestamp.friendlyDate( element.timestamp ) + ', ' + friendlyTimestamp.friendlyTime( element.timestamp )
    ]
    if( undefined !== element.update && null !== element.update )
    {
        headerSet.push(
            'zuletzt aktualisiert am ' + friendlyTimestamp.friendlyDate( element.update ) + ', ' + friendlyTimestamp.friendlyTime( element.update )
        )
    }

    let cells = [],
        lines = [],
        rects = [],
        headY = y

    let markers      = _getReferenceMarkers( element ),
        topRowDefs   = [],
        topRowCells  = [],
        topRowImages = [],
        rowHeight    = 0

    for( let h in headerSet )
    {
        let head = headerSet[ h ]
        topRowCells.push( {
            x   : ( 12 + pmAddX ),
            y   : headY,
            text: head
        } )
        topRowDefs.push( _getDef( doc, head, maxWidth ) )
        topRowImages.push( null )
        let dims = doc.getTextDimensions( head )
        rowHeight += ( dims.h + 1 )
        headY += dims.h + 1
    }

    lines.push( {
        x: ( 10 + pmAddX ), y: headY, x2: maxWidth, y2: headY
    } )

    let markerX = maxWidth

    for( let m in markers )
    {

        let marker = markers[ m ]
        let dims = doc.getTextDimensions( marker.label )

        let startX = ( markerX - dims.w - 1 )
        rects.push( {
            fillcolor: marker.color,
            label    : {
                text: marker.label,
                dims: dims
            },
            rect     : {
                x: startX, y: 10, x2: ( dims.w + 0.5 ), y2: ( dims.h + 0.5 ), type: 'F'
            }
        } )

        markerX = startX - 1

    }

    let boxy1 = headY
    headY += 5

    let t = _getDef( doc, element.body, maxWidth )
    let text = t.text

    let dims = doc.getTextDimensions( text )

    let boxy2 = dims.h + 5

    topRowCells.push( {
        x   : pmAddX + 12,
        y   : headY,
        text: text
    } )

    rowHeight += ( dims.h + 5 )

    listSetup.rowHeights.push( rowHeight )
    topRowImages.push( null )
    topRowDefs.push( t )

    _appendElementTypeHeaders( doc, element, topRowCells, topRowDefs, topRowImages, maxWidth, rects, markerX, friendlyTimestamp, pmAddX )

    defs.push( topRowDefs )
    cells.push( topRowCells )
    images.push( topRowImages )

    headY += dims.h

    let embedded = _getEmbeddedTodoList( doc, element, ( headY + dims.h ) )
    if( null !== embedded.embedded )
    {

        if( embedded.embedded instanceof Array
            && 0 < embedded.embedded.length )
        {
            for( let e in embedded.embedded )
            {

                let emCells = []
                let emDefs = []
                let imList = []
                let em = embedded.embedded[ e ]
                let height = 0
                for( let ee in em )
                {
                    let emb = em[ ee ]
                    switch( emb.type )
                    {
                        case 'text':
                            emCells.push( {
                                x    : pmAddX + emb.x,
                                y    : emb.y,
                                text : emb.text,
                                style: emb.style
                            } )
                            imList.push( null )
                            emDefs.push( _getDef( doc, emb.text, ( maxWidth - emb.x ) ) )
                            break
                        case 'image':
                            emCells.push( _emptyCell( ( emb.x + pmAddX ), emb.y ) )
                            imList.push( [ emb ] )
                            emDefs.push( {
                                def   : 'image',
                                type  : 'image',
                                width : 3,
                                y     : emb.y,
                                x     : pmAddX + emb.x,
                                images: [ emb.image ]
                            } )
                            break
                    }
                    if( emb.height > height )
                    {
                        height = emb.height
                    }

                }

                cells.push( emCells )
                defs.push( emDefs )
                images.push( imList )
                headY += height
                boxy2 += height
                listSetup.rowHeights.push( height )
            }

            headY += 5
            boxy2 += 5

        }

    }

    lines.push( {
        x: ( pmAddX + 10 ), y: headY, x2: maxWidth, y2: headY
    } )

    listSetup.lines.push( lines )

    rects.push( {
        fillcolor: color,
        rect     : {
            x: ( pmAddX + 10 ), y: boxy1, x2: ( maxWidth - 10 ), y2: boxy2, type: 'F'
        }
    } )

    listSetup.cells = cells
    listSetup.rects.push( rects )


    return {
        y     : headY,
        setup : listSetup,
        defs  : defs,
        images: images
    }

}

function preparePlain( element, doc, y, maxHeight, maxWidth, color, friendlyTimestamp, settings, landscape )
{

    let result,
        list,
        punchMarks = settings.printingWithPunchMarks === true

    doc.setFont( 'quicksand', 'bold' )
    doc.setFontSize( 10 )

    list = {
        headers: [],
        rows   : _getRows( element, doc, maxWidth )
    }

    let prepared = _preparePlainAsList( doc, element, y, color, maxWidth, friendlyTimestamp, punchMarks, landscape )
    list.rows.defs = prepared.defs
    list.rows.images = prepared.images

    return {
        headers: result,
        rows   : prepared,
        raw    : list
    }
}

export default preparePlain