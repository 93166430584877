<script>
export default {
    name: "MixinHasStudentCount",

    data()
    {
        return {
            initialized: false,
            students   : {}
        }
    },

    computed: {
        studentCount()
        {

            if( -1 < this.$props.fakeCount )
            {
                return this.$props.fakeCount
            }

            let count = 0
            for( let s in this.$props.item.students )
            {
                if( true === this.students[ this.$props.item.students[ s ] ] )
                {
                    count++
                }
            }
            return count
        }
    },

    created()
    {
        this.$core.getBaseClassHelper()
            .get( 'student' )
            .listAll()
            .then( list =>
            {

                for( let l in list )
                {
                    if( list[ l ].archived !== true || ( list[ l ].archived === true && this.$props.item.archived ) )
                    {
                        this.students[ list[ l ].localId ] = true
                    }
                }
                this.initialized = true

            } )
    }

}
</script>