<template>
    <li class="tab" :id="id">
        <div :class="'tab-inner'+( active ? ' active' : '' )">
            {{ caption }}
            <div :class="'bubble tab'+( active ? ' active' : '' )" v-if="0 < bubble">{{ bubble }}</div>
            <div :class="'tab-bar'+( active ? ' active' : '' )"> </div>
        </div>
    </li>
</template>

<script>
export default {
    name : 'Tab',
    props: [ 'id', 'caption', 'active', 'bubble' ],
}
</script>