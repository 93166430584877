<script>
export default {
    name    : "MixinStructureKey",
    computed: {
        structureKey()
        {

            let keyset = [],
                key = ''

            if( Array.isArray( this.$props.item.lists ) )
            {
                for( let c in this.$props.item.lists[ 0 ].columns )
                {
                    keyset.push( this.$props.item.lists[ 0 ].columns[ c ].id )
                }
            }
            else
            {
                for( let c in this.$props.item.columns )
                {
                    keyset.push( this.$props.item.columns[ c ].id )
                }
            }

            key = keyset.join( '-' )
            if( this.$props.previewMode )
            {
                key+= this.$core.getUuid().generate()
            }

            return key

        }
    },
    watch   : {
        structureKey: {
            immediate: true,
            handler( newValue, oldValue )
            {

                if( oldValue !== undefined
                    && newValue !== oldValue )
                {
                    this.$nextTick()
                        .then( () =>
                        {
                            this.prepare()
                        } )
                }
            }
        }
    }
}
</script>