export default class ShareHelper
{

    constructor( parent )
    {

        if( !ShareHelper.instance )
        {

            this.logger = parent.logger
            this.f = parent.f
            this.parent = parent
            this.baseClassHelper = parent.baseClassHelper
            this.ready = false
            this.eventManager = parent.eventManager
            this.store = parent.store

            this.eventManager.append( 'on-store-ready', () =>
            {
                if( undefined === this.baseClassHelper )
                {
                    this.eventManager.append( 'on-baseclasses-available', () =>
                    {

                        this.baseClassHelper = parent.baseClassHelper
                        this.preheat()

                    } )
                }
                else
                {
                    this.preheat()
                }
            })

            ShareHelper.instance = this

        }

        return ShareHelper.instance

    }

    preheat()
    {

        if( true === this.store.getters.authorized )
        {
            if( undefined !== this.baseClassHelper.get( 'share' ).state )
            {
                this.baseClassHelper
                    .get( 'share' )
                    .listAll( undefined, undefined, undefined, true )
                    .then( () =>
                    {
                        this.ready = true
                    } )
            }
            else
            {
                setTimeout( () =>
                {
                    this.preheat()
                }, 300 )
            }
        }
        else
        {
            this.eventManager.append( 'on-login-state-change', () => {
                if( true === this.store.getters.authorized )
                {
                    this.preheat()
                }
            })
        }
    }

    hasColleagueKeys( keys )
    {

        for( let k in keys )
        {
            for( let c in this.parent.colleagues )
            {
                if( this.parent.colleagues[ c ].uuid === keys[ k ].uuid )
                {
                    return true
                }
            }
        }
        return false
    }

    _count( counters, target, queue, count )
    {

        switch( target )
        {
            case 'from':
                if( !this.f.isset( counters.sharedFrom[ queue ] ) )
                {
                    counters.sharedFrom[ queue ] = 0
                }
                counters.sharedFrom[ queue ] += count
                counters.sharedFrom.total += count
                break
            case 'to':
                if( !this.f.isset( counters.sharedTo[ queue ] ) )
                {
                    counters.sharedTo[ queue ] = 0
                }
                counters.sharedTo[ queue ] += count
                counters.sharedTo.total += count
                break
        }

    }

    /*eslint-disable*/
    checkAvailableCaches( shareables )
    {

        return new Promise( resolve =>
        {

            let allDone = true

            for( let s in shareables )
            {
                let shareableClassState = this.baseClassHelper.get( shareables[ s ] ).state
                if( 'filled' !== shareableClassState )
                {
                    if( 'filling' !== shareableClassState )
                    {
                        this.baseClassHelper
                            .get( shareables[ s ] )
                            .cacheHeatup()
                    }
                    allDone = false
                }
            }

            if( allDone )
            {
                return resolve()
            }
            else
            {
                setTimeout( () =>
                {
                    return resolve( this.checkAvailableCaches( shareables ) )
                }, 300 )
            }

        } )

    }

    shareCount( colleague )
    {

        return new Promise( resolve =>
        {

            let counters   = {
                    sharedFrom: {
                        total: 0
                    },
                    sharedTo  : {
                        total: 0
                    }
                },
                counted    = [],
                shareables = this.baseClassHelper.shareableQueues

            this.checkAvailableCaches( shareables )
                .then( () =>
                {

                    for( let q in shareables )
                    {

                        let queue    = shareables[ q ],
                            elemlist = this.baseClassHelper
                                           .get( queue )
                                           .readCache( 'cache' )

                        for( let e in elemlist )
                        {

                            let item
                            if( 'list' === elemlist[ e ].type )
                            {
                                item = this.baseClassHelper.get( 'list' )
                                           .getListContainer( elemlist[ e ].referenceKey )
                            }
                            else
                            {
                                item = this.baseClassHelper.getObjectById( elemlist[ e ].localId )
                            }

                            if( undefined === item
                                || true === item.archived )
                            {
                                continue
                            }

                            if( -1 === counted.indexOf( item.localId ) )
                            {

                                if( !this.f.isOwn( item ) )
                                {
                                    if( parseInt( item.idOwner ) === parseInt( colleague.colleagueId ) )
                                    {
                                        this._count( counters, 'from', queue, ( 'list' === queue ? item.lists.length : 1 ) )
                                    }
                                }
                                else
                                {

                                    if( 'list' !== queue )
                                    {
                                        if( this.f.isset( item._keys )
                                            && 1 < item._keys.length )
                                        {
                                            let ct = false
                                            for( let k in item._keys )
                                            {
                                                if( !ct && item._keys[ k ].uuid === colleague.uuid )
                                                {
                                                    ct = true
                                                    this._count( counters, 'to', queue, 1 )
                                                }
                                            }
                                        }

                                    }
                                    else
                                    {
                                        for( let l in item.lists )
                                        {

                                            let list = item.lists[ l ]

                                            if( this.f.isset( list._keys )
                                                && 1 < list._keys.length )
                                            {
                                                let ct = false
                                                for( let k in list._keys )
                                                {

                                                    if( !ct && list._keys[ k ].uuid === colleague.uuid )
                                                    {
                                                        ct = true
                                                        this._count( counters, 'to', queue, 1 )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                            }

                            counted.push( item.localId )

                        }

                    }

                    return resolve( counters )

                } )

        } )

    }

    isShared( object )
    {

        if( !this.f.isOwn( object ) )
        {
            return true
        }

        let share = this.baseClassHelper
                        .get( 'share' )
                        .registry
                        .byReference
                        .get( object.localId )

        if( undefined !== share )
        {
            let shareElement = this.baseClassHelper
                                   .get( 'share' )
                                   .getById( share )

            if( undefined !== shareElement
                && Array.isArray( shareElement.sharedWith )
                && 0 < shareElement.sharedWith.length )
            {
                return true
            }
        }
        else if( undefined !== object.referenceKey )
        {

            let share = this.baseClassHelper
                            .get( 'share' )
                            .registry
                            .byReference
                            .get( object.referenceKey )

            if( undefined !== share )
            {
                let shareElement = this.baseClassHelper
                                       .get( 'share' )
                                       .getById( share )

                if( undefined !== shareElement
                    && Array.isArray( shareElement.sharedWith )
                    && 0 < shareElement.sharedWith.length )
                {
                    return true
                }
            }

        }


        return false

    }

    shareList( object )
    {

        let list = []

        if( !this.f.isObject( object ) )
        {
            return list
        }

        let share = this.baseClassHelper
                        .get( 'share' )
                        .registry
                        .byReference.get( object.localId )

        if( undefined === share && undefined !== object.referenceKey )
        {
            share = this.baseClassHelper
                        .get( 'share' )
                        .registry
                        .byReference
                        .get( object.referenceKey )
        }

        if( undefined !== share )
        {

            let shareElement = this.baseClassHelper
                                   .get( 'share' )
                                   .getById( share )

            if( undefined !== shareElement )
            {

                let colleagues = shareElement.sharedWith
                for( let i in colleagues )
                {
                    let uuid = colleagues[ i ]
                    for( let c in this.parent.colleagues )
                    {
                        if( this.parent.colleagues[ c ].uuid === uuid )
                        {
                            list.push( this.parent.colleagues[ c ] )
                        }
                    }
                }

            }

        }

        return list

    }

    getOwnerColleague( object )
    {
        for( let c in this.parent.colleagues )
        {
            if( this.parent.colleagues[ c ].colleagueId === parseInt( object.idOwner ) )
            {
                return JSON.parse( JSON.stringify( this.parent.colleagues[ c ] ) )
            }
        }
    }

    /*eslint-disable*/
    _toColleagueList( list )
    {

        let result = []

        for( let l in list )
        {
            for( let c in this.parent.colleagues )
            {

                if( this.parent.colleagues[ c ].uuid === list[ l ]
                    || this.parent.colleagues[ c ].localId === list[ l ] )
                {
                    result.push( JSON.parse( JSON.stringify( this.parent.colleagues[ c ] ) ) )
                }

            }

        }

        return result

    }

    _toStudentList( list )
    {

        let result            = [],
            studentAccesses   = {},
            studentAccessList = this.baseClassHelper.get( 'studentAccess' ).readCache( 'cache' )

        for( let l in studentAccessList )
        {

            let access = studentAccessList[ l ]
            if( undefined !== access )
            {
                studentAccesses[ access.studentLocalId ] = access
            }

        }

        for( let l in list )
        {
            if( undefined !== studentAccesses[ list[ l ] ] )
            {
                result.push( studentAccesses[ list[ l ] ] )
            }
        }

        return result

    }

    getColleague( localId )
    {

        for( let c in this.parent.colleagues )
        {
            if( this.parent.colleagues[ c ].localId === localId )
            {

                return this.parent.colleagues[ c ]

            }
        }

        return false

    }

    _shareWithListResolver( list )
    {
        return new Promise( resolve =>
        {

            let result = []
            for( let l in list )
            {

                let colleague = this.getColleague( list[ l ] )
                if( false !== colleague )
                {
                    result.push( colleague )
                }
            }

            return resolve( JSON.parse( JSON.stringify( result ) ) )

        } )
    }

    shareWithListResolver( list, result )
    {
        return new Promise( resolve =>
        {

            result = result || []
            if( 0 < list.length )
            {
                let colleague = list.shift()
                this.resolveShareWith( colleague )
                    .then( res =>
                    {
                        for( let r in res )
                        {
                            result.push( res[ r ] )
                        }
                        return resolve( this.shareWithListResolver( list, result ) )
                    } )
            }
            else
            {
                return resolve( result )
            }

        } )
    }

    resolveShareWith( colleagueUuid )
    {

        return new Promise( resolve =>
        {

            let shareWith = []

            if( Array.isArray( colleagueUuid ) )
            {
                return resolve( this.shareWithListResolver( colleagueUuid ) )
            }

            if( undefined !== colleagueUuid
                && -1 < colleagueUuid.indexOf( ':' ) )
            {

                let registryKey = colleagueUuid.split( ':' )
                if( 1 < registryKey.length )
                {

                    let key   = registryKey[ 0 ],
                        value = registryKey[ 1 ]

                    if( this.f.isset( key ) )
                    {

                        let colleagueGroup = this.baseClassHelper.getObjectById( value )
                        if( Array.isArray( colleagueGroup.colleagues ) )
                        {
                            for( let c in colleagueGroup.colleagues )
                            {
                                shareWith.push( colleagueGroup.colleagues[ c ] )
                            }
                            return resolve( this._toColleagueList( shareWith ) )
                        }
                        else
                        {
                            shareWith.push( colleagueUuid )
                            return resolve( this._toColleagueList( shareWith ) )
                        }

                    }

                }
            }
            else
            {

                if( undefined !== colleagueUuid )
                {
                    shareWith.push( colleagueUuid )
                }

                return resolve( this._toColleagueList( shareWith ) )

            }

        } )

    }

    shareWithStudentListResolver( list, result )
    {
        return new Promise( resolve =>
        {

            result = result || []
            if( 0 < list.length )
            {
                let student = list.shift()
                this.resolveShareWithStudent( student )
                    .then( res =>
                    {
                        for( let r in res )
                        {
                            result.push( res[ r ] )
                        }
                        return resolve( this.shareWithStudentListResolver( list, result ) )
                    } )
            }
            else
            {
                return resolve( result )
            }

        } )
    }

    resolveShareWithStudent( studentId )
    {

        return new Promise( resolve =>
        {

            let shareWith = []

            if( Array.isArray( studentId ) )
            {
                return resolve( this.shareWithStudentListResolver( studentId ) )
            }

            if( undefined !== studentId
                && -1 < studentId.indexOf( ':' ) )
            {

                let registryKey = studentId.split( ':' )
                if( 1 < registryKey.length )
                {

                    let key   = registryKey[ 0 ],
                        value = registryKey[ 1 ],
                        queue = this.parent.queueForType[ key ]

                    if( this.f.isset( queue ) )
                    {

                        let colleagueGroup = this.baseClassHelper.getObjectById( value )
                        if( this.f.isObject( colleagueGroup.colleagues ) )
                        {
                            for( let c in colleagueGroup.colleagues )
                            {
                                shareWith.push( colleagueGroup.colleagues[ c ] )
                            }
                            return resolve( this._toColleagueList( shareWith ) )
                        }
                        else
                        {
                            shareWith.push( studentId )
                            return resolve( this._toColleagueList( shareWith ) )
                        }

                    }

                }
            }
            else
            {

                if( undefined !== studentId )
                {
                    shareWith.push( studentId )
                }

                return resolve( this._toStudentList( shareWith ) )

            }

        } )

    }

}