<template>
    <div class="padded-bottom">

        <h2>{{ $core.t( 'headline-colleague-element-rights' ) }}</h2>

        <template v-if="undefined !== shared[tab] && 0 < shared[tab].length">
            <div class="spacer">
            </div>
            <button @click="flush( tab )">
                {{ $core.t( 'caption-colleague-reset-element-rights', $core.t( 'object-type-' + tab + 's' ) ) }}
            </button>
        </template>

        <TabbedContent :tabs="tabs" :active="tab"
                       additionalClass="marge-top"
                       @switched="setTab"/>

        <template v-for="t in tabs" :key="'tabbed-'+t.id">
            <div v-if="t.id === tab">
                <div v-for="e in shared[t.id]" :key="'shareable-'+t.id+'-'+e.localId"
                     :class="'element-rights-switch'+( t.id === 'media' ? ' gallery' : '' )">
                    <Touchable :item="e"
                               :viewItem="t.id"
                               :galleryMode="t.id === 'media'"
                               :clickDisabled="true"
                               :noDetailView="true"
                               :noShareIndicator="true"
                               :singleItems="e.lists === undefined"
                               scope="own"/>
                    <div class="rights switchboard">
                        <div class="rights-switches">
                            <template v-for="( s, index ) in getSwitchesFor( t.id )"
                                      :key="'rights-switches-'+index">
                                <div class="half rights-pad">
                                    <div class="switch">
                                        <Toggle :id="'elements-'+e.localId+'-'+s.value"
                                                :refName="'elements-'+e.localId+'-'+s.value"
                                                :value="undefined !== values['elements-'+e.localId+'-'+s.value] ? values['elements-'+e.localId+'-'+s.value] : defaultValue( e.type, s.value )"
                                                @update="handleToggle"/>
                                    </div>
                                    <div class="caption"><span
                                        :class="'strong'+( 'delete' === s.value ? ' error' : '' )">
                                        {{ $core.f().ucFirst( $core.t( s.value ) ) }}</span> <span class="comment">{{
                                            undefined !== values[ 'elements-' + e.localId + '-' + s.value ] ? '' : $core.t( 'general-right-marker' )
                                        }}</span>
                                    </div>
                                </div>
                                <div v-if="index%2 == 1" class="clearfix"></div>
                            </template>
                        </div>
                    </div>
                    <div class="spacer"></div>
                </div>
            </div>
        </template>
        <template v-if="0 === tabs.length">
            <InlineMessage :active="initialized"
                           key="'message-inline-shares"
                           classname="empty"
                           :title="$core.t( 'default-caption-empty' )"
                           :message="$core.t( 'message-empty-shares', item.firstname+' '+item.lastname )"/>
        </template>
    </div>
</template>

<script>
import Toggle               from '@/components/form/elements/Toggle'
import TabbedContent        from '@/components/layout/tabs/TabbedContent'
import MixinWatchElementKey from '@/mixins/MixinWatchElementKey'
import InlineMessage        from '@/components/messages/InlineMessage'
import Touchable            from "@/components/defaults/Touchable";

/*eslint-disable*/
export default {
    name      : 'ColleagueObjectRights',
    components: { Touchable, InlineMessage, TabbedContent, Toggle },
    mixins    : [ MixinWatchElementKey ],
    props     : {
        item: { type: Object, required: true }
    },

    data()
    {
        return {
            updateTimer: false,
            initialized: false,
            itemClone  : false,
            values     : {},
            tabs       : [],
            shared     : {},
            tab        : false,
            setters    : [
                {
                    id      : 'classElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'groupElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'yeargroupElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'studentElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'readMeta'
                        },
                        {
                            value: 'changeMeta'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'noteElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'todoElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'markDone'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'dateElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    id      : 'listElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'fill'
                        },
                        {
                            value: 'clone'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'media',
                    id      : 'mediaElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                }
            ]
        }
    },

    created()
    {

        this.prepare()

    },

    methods: {

        prepare()
        {
            this.shared = {}
            this.values = {}
            this.tabs = []
            this.itemClone = JSON.parse( JSON.stringify( this.$props.item ) )
            this.initializeShares()
                .then( () =>
                {
                    this.initializeValues()
                    this.initialized = true
                } )
        },

        defaultValue( type, key )
        {

            if( undefined !== this.itemClone.rights
                && undefined !== this.itemClone.rights.general
                && undefined !== this.itemClone.rights.general[ type + 'Elements' ]
                && undefined !== this.itemClone.rights.general[ type + 'Elements' ][ key ] )
            {
                return this.itemClone.rights.general[ type + 'Elements' ][ key ]
            }
            let defaults = this.$core.r().getRights( 'basic' )
            if( undefined !== defaults.rights[ type + 'Elements' ][ key ] )
            {
                return defaults.rights[ type + 'Elements' ][ key ]
            }

            return false

        },

        setTab( which )
        {
            this.tab = which
        },

        getSwitchesFor( tab )
        {
            for( let s in this.setters )
            {
                if( this.setters[ s ].id === tab + 'Elements' )
                {
                    return this.setters[ s ].switches
                }
            }
        },

        isInShares( key )
        {
            for( let s in this.setters )
            {

                let setter = this.setters[ s ].id,
                    type   = setter.replace( 'Elements', '' )

                if( type === key )
                {
                    return true
                }

            }

            return false

        },

        _findByReference( type, referenceKey )
        {
            if( undefined === referenceKey )
            {
                return null
            }
            for( let s in this.shared[ type ] )
            {
                if( this.shared[ type ][ s ].referenceKey === referenceKey )
                {
                    return s
                }
            }
            return null
        },

        _appendShare( object )
        {

            if( !this.$core.f().isset( this.shared[ object.type ] ) )
            {
                this.shared[ object.type ] = []
                this.tabs.push( {
                    id     : object.type,
                    caption: this.$core.getTranslation().translate( 'object-type-' + object.type + ( 'class' === object.type ? 'es' : 's' ) )
                } )
                if( !this.tab )
                {
                    this.tab = object.type
                }
            }

            object.editLocked = true
            let idx = this._findByReference( object.type, object.referenceKey )
            if( null === idx )
            {
                this.shared[ object.type ].push( object )
            }
        },

        initializeShares()
        {
            return new Promise( resolve =>
            {

                let shareables = this.$core.getBaseClassHelper().shareableQueues

                for( let q in shareables )
                {

                    let queue = shareables[ q ]
                    if( this.isInShares( queue ) )
                    {

                        let list = this.$core.getBaseClassHelper()
                                       .get( queue )
                                       .readCache( 'cache' )

                        for( let e in list )
                        {

                            let elm
                            if( list[ e ].type === 'list' )
                            {
                                elm = this.$core.getBaseClassHelper()
                                          .get( queue )
                                          .getListContainer( list[ e ].referenceKey )
                            }
                            else
                            {
                                elm = this.$core.getBaseClassHelper()
                                          .get( queue )
                                          .getById( list[ e ].localId )
                            }

                            if( undefined === elm )
                            {
                                continue
                            }

                            if( this.$core.f().isOwn( elm )
                                && this.$core.getShare().sharedWith( elm, this.$props.item.uuid ) )
                            {
                                this._appendShare( elm )
                            }

                        }

                        this.shared[ queue ] = this.$core.getSorter()
                                                   .sortListByType( this.shared[ queue ],
                                                       this.$core.getTranslation().translateObjectType( queue ) )

                    }

                }

                return resolve()

            } )

        },

        initializeValues()
        {

            if( undefined !== this.itemClone.rights
                && undefined !== this.itemClone.rights.elements )
            {

                for( let e in this.itemClone.rights.elements )
                {
                    let eRights = this.itemClone.rights.elements[ e ]
                    for( let r in eRights )
                    {
                        this.values[ 'elements-' + e + '-' + r ] = eRights[ r ]
                    }
                }

            }
        },

        updateOnLeave()
        {
            if( false !== this.updateTimer )
            {
                clearTimeout( this.updateTimer )
                this.updateTimer = false
                this.performUpdate()
            }
        },

        performUpdate()
        {

            let setup            = {
                    role  : this.itemClone.role,
                    rights: this.itemClone.rights
                },
                cryptedRights    = this.$core.getCryptoCore().encryptWithPublicKey(
                    this.itemClone.publicKey,
                    JSON.stringify( setup )
                ),
                ownCryptedRights = btoa( JSON.stringify( this.$core.getCryptoHelper().encrypt( JSON.stringify( setup ) ) ) ),
                params           = {
                    method     : 'network.storeColleagueRights',
                    colleagueId: this.itemClone.colleagueId,
                    rights     : cryptedRights,
                    ownRights  : ownCryptedRights
                },
                jobId            = this.$core.getUuid().generate()

            this.$core.getQueueWorker().enqueue( 'message', jobId, 'socketMessage', JSON.stringify( params ) )

            this.$core.getBaseClassHelper()
                .get( 'colleague' )
                .update(
                    this.itemClone,
                    this.itemClone.localId,
                    this.itemClone.remoteId,
                    this.itemClone.timestamp,
                    this.itemClone.localKey
                )

        },

        lazyElementsRightsUpdate()
        {

            if( false !== this.updateTimer )
            {
                clearTimeout( this.updateTimer )
                this.updateTimer = false
            }

            this.updateTimer = setTimeout( () =>
            {

                this.performUpdate()

            }, 3000 )

        },

        _allIds( localId )
        {

            let allIds = []

            for( let t in this.shared )
            {
                for( let s in this.shared[ t ] )
                {
                    if( localId === this.shared[ t ][ s ].localId )
                    {
                        if( undefined !== this.shared[ t ][ s ].lists )
                        {
                            for( let l in this.shared[ t ][ s ].lists )
                            {
                                allIds.push( this.shared[ t ][ s ].lists[ l ].localId )
                            }
                            allIds.push( this.shared[ t ][ s ].lists[ 0 ].referenceKey )
                        }
                        else
                        {
                            allIds.push( localId )
                        }

                        return allIds

                    }
                }
            }

            return null

        },

        handleToggle( id, type, method, reference, value )
        {

            let temp    = id.split( '-' ),
                scope   = temp.shift(),
                localId = '',
                idT     = []

            for( let i = 0; i < 5; i++ )
            {
                let t = temp.shift()
                idT.push( t )
            }
            localId = idT.join( '-' )

            let key = temp.shift()

            if( 'changeMeta' === key
                && value === true )
            {
                let nId = id.replace( key, 'readMeta' )
                this.handleToggle( nId, type, method, reference, value )
            }

            if( 'readMeta' === key
                && value === false )
            {
                let nId = id.replace( key, 'changeMeta' )
                this.handleToggle( nId, type, method, reference, value )
            }

            this.itemClone.rights = this.itemClone.rights || {}
            if( !this.$core.f().isset( this.itemClone.rights[ scope ] ) )
            {
                this.itemClone.rights[ scope ] = {}
            }

            let allIds = this._allIds( localId )
            for( let a in allIds )
            {

                let id = allIds[ a ]
                if( !this.$core.f().isset( this.itemClone.rights[ scope ][ id ] ) )
                {
                    this.itemClone.rights[ scope ][ id ] = {}
                }
                this.itemClone.rights[ scope ][ id ][ key ] = value

            }

            this.lazyElementsRightsUpdate()
            this.initializeValues()

        },

        flush( tab )
        {

            this.$core.getUi().showBlocker( this.$core.t( 'generic-please-wait' ), this.$core.t( 'rights-are-being-reset' ) )

            setTimeout( () =>
            {

                if( undefined === this.itemClone.rights
                    || undefined === this.itemClone.rights.elements )
                {

                    this.lazyElementsRightsUpdate()
                    this.initializeValues()

                    setTimeout( () =>
                    {
                        this.$core.getUi().hideBlocker()
                    }, 2000 )

                    return

                }
                this.$core.objectRegistryQueueForType()
                    .then( queues =>
                    {

                        this.$core.objectRegistryGet( queues[ tab ] )
                            .then( list =>
                            {

                                for( let l in list )
                                {
                                    if( 'list' === tab )
                                    {
                                        for( let ll in list[ l ].lists )
                                        {
                                            let elm = list[ l ].lists[ ll ]
                                            if( undefined !== this.itemClone.rights[ 'elements' ][ elm.localId ] )
                                            {
                                                delete this.itemClone.rights[ 'elements' ][ elm.localId ]
                                            }
                                        }
                                    }
                                    else
                                    {
                                        let elm = list[ l ]
                                        if( undefined !== this.itemClone.rights[ 'elements' ][ elm.localId ] )
                                        {
                                            delete this.itemClone.rights[ 'elements' ][ elm.localId ]
                                        }
                                    }
                                }

                                this.lazyElementsRightsUpdate()
                                this.initializeValues()

                                setTimeout( () =>
                                {
                                    this.$core.getUi().hideBlocker()
                                }, 2000 )

                            } )

                    } )

            }, 1000 )

        }


    }


}
</script>