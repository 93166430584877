<template>
    <div class="score-distribution"
         :id="'append-printable-'+lists[0].localId">
        <h3><strong>Notenspiegel</strong></h3>
        <TabbedContent :tabs="distriTabs" :active="activeTab" additionalClass="marge-top" @switched="setDistriTab"
                       v-if="lists.length > 1" :inline="true"/>
        <template v-for="t in distriTabs"
                  :key="t.id">
            <transition appear :name="$core.settings().getTransition( 'crossfade' )">
                <div v-if="t.id === activeTab">
                <template v-for="( distribution, index ) in distributions[t.id]"
                          :key="'distri-'+t.id+'-'+index">
                    <div :class="'distribution-box '+( lists.length > 1 ? 'inline ' : '' )+item.scoreModel">
                        <template v-if="'points' !== item.scoreModel
                            && 'extended' !== item.scoreModel
                            && 'quarters' !== item.scoreModel">
                            <div v-for="summary in distribution.summary"
                                 :class="'box-'+item.scoreModel"
                                 :key="'score-box-'+item.scoreModel+'-'+summary.number">
                                <div class="box-header"><strong>{{ summary.translation }}</strong> ({{ summary.number }})
                                </div>
                                <div class="box-count">{{ summary.count }}</div>
                            </div>
                        </template>
                        <template v-else-if="'quarters' === item.scoreModel">
                            <div v-for="i in [0,4,8,12,16,20]"
                                 :class="'box-wrap-'+item.scoreModel"
                                 :key="'box-wrap-'+i">
                                <div class="box-model-header"><strong>{{ quartersHead[ i ].caption }}</strong></div>
                                <template v-for="s in quartersHead[ i ].fields"
                                          :key="'box-wrap-'+i+'-'+s">
                                    <div v-if="undefined !== distribution.summary[ s ]">
                                        <div :class="'box-'+item.scoreModel"
                                             :key="'score-box-'+item.scoreModel+'-'+s">
                                            <div class="box-header" v-if="'extended' === item.scoreModel">
                                                <strong>{{ distribution.summary[ s ].number }}</strong></div>
                                            <div class="box-header" v-else><strong>{{
                                                    distribution.summary[ s ].number
                                                }}</strong>
                                            </div>
                                            <div class="box-count">{{ distribution.summary[ s ].count }}</div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div v-for="i in [0,3,6,9,12,15]"
                                 :class="'box-wrap-'+item.scoreModel"
                                 :key="'box-wrap-'+i">
                                <div class="box-model-header"><strong>{{ extendedHead[ i ].caption }}</strong></div>
                                <template v-for="s in extendedHead[ i ].fields"
                                          :key="'box-wrap-'+i+'-'+s">
                                    <div v-if="undefined !== distribution.summary[ s ]">
                                        <div :class="'box-'+item.scoreModel"
                                             :key="'score-box-'+item.scoreModel+'-'+s">
                                            <div class="box-header" v-if="'extended' === item.scoreModel">
                                                <strong>{{ distribution.summary[ s ].number }}</strong></div>
                                            <div class="box-header" v-else><strong>{{ distribution.summary[ s ].number }}
                                                P</strong>
                                            </div>
                                            <div class="box-count">{{ distribution.summary[ s ].count }}</div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div class="clearfix"></div>
                        <div :class="'box-totals'+( undefined === distribution.averageScore ? ' half' : '' )">
                            <div class="box-header-totals"><strong>&oslash; Note</strong></div>
                            <div class="box-count" v-if="!distribution.avgByValue">{{
                                    $core.getReformatter().reformat( distribution.average, 'localizedFloat' )
                                }}
                            </div>
                            <div class="box-count" v-else>{{ distribution.average }}</div>
                        </div>
                        <div class="box-totals" v-if="undefined !== distribution.averageScore">
                            <div class="box-header-totals"><strong>&oslash; Punkte</strong></div>
                            <div class="box-count">{{
                                    $core.getReformatter().reformat( distribution.averageScore, 'localizedFloat' )
                                }}
                            </div>
                        </div>
                        <div :class="'box-totals'+( undefined === distribution.averageScore ? ' half' : '' )">
                            <div class="box-header-totals"><strong>&sum; Summe</strong></div>
                            <div class="box-count">{{ distribution.count }}</div>
                        </div>
                    </div>
                </template>
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
import TabbedContent from '@/components/layout/tabs/TabbedContent'

export default {
    
    name      : 'ScoreDistribution',
    components: { TabbedContent },
    props     : {
        lists     : { type: Object, required: true },
        elementKey: { type: String, required: true }
    },
    
    watch: {
        elementKey: {
            immediate: true,
            handler( oldKey, newKey )
            {
                if( oldKey !== newKey )
                {
                    this.calculate()
                }
            }
        }
    },
    
    data()
    {
        return {
            viewId       : this.$core.getUuid().generate(),
            distributions: {},
            activeTab    : false,
            distriTabs   : [],
            sortable     : [],
            item         : false,
            extendedHead : {
                0 : { caption: 'sehr gut', fields: [ 0, 1, 2 ] },
                3 : { caption: 'gut', fields: [ 3, 4, 5 ] },
                6 : { caption: 'befriedigend', fields: [ 6, 7, 8 ] },
                9 : { caption: 'ausreichend', fields: [ 9, 10, 11 ] },
                12: { caption: 'mangelhaft', fields: [ 12, 13, 14 ] },
                15: { caption: 'ungenügend', fields: [ 15, 16, 17 ] }
            },
            quartersHead : {
                0 : { caption: 'sehr gut', fields: [ 0, 1, 2, 3 ] },
                4 : { caption: 'gut', fields: [ 4, 5, 6, 7 ] },
                8 : { caption: 'befriedigend', fields: [ 8, 9, 10, 11 ] },
                12: { caption: 'ausreichend', fields: [ 12, 13, 14, 15 ] },
                16: { caption: 'mangelhaft', fields: [ 16, 17, 18, 19 ] },
                20: { caption: 'ungenügend', fields: [ 20, 21 ] }
            }
        }
    },
    
    created()
    {
        
        this.calculate()
        
    },
    
    methods: {
        calculate()
        {
            
            this.distriTabs = []
            this.sortable = []
            
            for( let l in this.$props.lists )
            {
                
                let item = this.$props.lists[ l ]
                let distribution = this.$core.getTestScoreCalculation().calculate( item )
                if( item.scoreModel === 'points' )
                {
                    let summary = distribution.summary
                    let newSummary = {}
                    for( let i = 15; i >= 0; i -= 1 )
                    {
                        newSummary[ 15 - i ] = summary[ i ]
                    }
                    distribution.summary = newSummary
                }
                
                this.distriTabs.push( {
                    id       : item.localId,
                    caption  : this.$core.getFriendlyTimestamp().friendlyDate( item.timestamp ),
                    timestamp: item.timestamp,
                    list     : item
                } )
                
                this.sortable.push( {
                    timestamp: item.timestamp,
                    localId  : item.localId
                } )
                
                this.distributions[ item.localId ] = [ distribution ]
                
            }
            
            this.distriTabs = this.$core.sort().sortObjects( this.distriTabs, 'timestamp', 'descending' )
            if( !this.activeTab )
            {
                this.item = this.distriTabs[ 0 ].list
                this.activeTab = this.distriTabs[ 0 ].id
            }
            
        },
        
        setDistriTab( tab )
        {
            this.activeTab = tab
        }
    }
}
</script>