<template>
    <div class="list-element ratesplantselector">
        <template v-if="initialized">
            <div v-for="rate in rates" :key="'plant-'+rate">
                <div class="rateplant-display"
                     v-bind:class="'rate-'+rate+( rate === value ? ' active' : '' )">
                    <img :src="imgs[rate][ rate === value ? 'active' : 'inactive' ]" style="width:100%; height:100%;"/>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name : 'listElement-rateplantselector',
    props: {
        value: { type: [ Number, String ], required: false, default: undefined }
    },
    data()
    {
        return {
            rates      : [ 0, 1, 2, 3 ],
            imgs       : [],
            initialized: false
        }
    },

    created()
    {
        for( let r in this.rates )
        {
            let rate = this.rates[ r ]
            this.imgs.push( {
                inactive: require( '@/assets/img/icons/ui/ico-rate-plant-' + rate + '-inactive.png' ),
                active  : require( '@/assets/img/icons/ui/ico-rate-plant-' + rate + '.png' )
            } )
        }
        this.initialized = true
    }

}
</script>
