<template>
    <div>
        <div class="status-text updating">entzettelt wird aktualisiert...</div>
        <div class="updating-checkmark">&#8635;</div>
    </div>
</template>

<script>
export default {
    name : 'Updating'
}
</script>