/*eslint-disable*/
export default class SyncShares
{

    constructor( parent )
    {

        this.parent = parent
        this.client = parent.client
        this.store = parent.store
        this.share = parent.share
        this.rights = parent.rights
        this.rIndex = null
        this.prepared = false

        this.inUnsharemode = true

        if( undefined === this.rights )
        {
            this.rIndex = this.parent.eventManager.addIndexed( 'on-rights-ready', ( rights ) =>
            {
                if( undefined !== rights )
                {
                    this.rights = rights
                    this.parent.eventManager.removeIndexedCallback( 'on-rights-ready', this.rIndex )
                }
            } )
        }

        this.baseClassHelper = parent.getBaseClassHelper()
        this.mediaHelper = parent.getMediaHelper()
        this.cryptoHelper = parent.cryptoHelper

        this.remoteKeys = {}
        this.remoteKeysFetched = false

        this.getState = ( key ) =>
        {
            return this.parent.getState( key )
        }

        if( undefined === this.baseClassHelper )
        {
            this.parent.eventManager.append( 'on-baseclasses-available', () =>
            {
                this.baseClassHelper = this.parent.getBaseClassHelper()
                this.preheatColleagues()
            } )
        }
        else
        {
            this.preheatColleagues()
        }

        if( undefined === this.mediaHelper )
        {
            this.parent.eventManager.append( 'on-mediahelper-available', () =>
            {
                this.mediaHelper = this.parent.getMediaHelper()
            } )
        }

        if( false === this.share )
        {
            this.parent.eventManager.append( 'on-share-ready', ( share ) =>
            {
                this.share = share
            } )
        }

        this.f = parent.f

        this.step = 0
        this.total = 0
        this.lastHash = false
        this.lastRemoteHash = false

        this.ownUuid = this.parent.store.getters.uuid
        this.ownUserId = this.parent.store.getters.idUser
        if( null === this.ownUuid )
        {
            this.parent.eventManager.append( 'on-store-ready', () =>
            {
                this.ownUuid = this.parent.store.getters.uuid
                this.ownUserId = this.parent.store.getters.idUser
            } )
        }
        this.syncing = false
        this.logSign = 'SyncWorker::SyncShares (SHR)'
        this.logger = this.parent.logger
        this.logger.clog( this.logSign, 'initialized' )

    }

    destruct()
    {
        this.parent = null
        delete this.parent
    }

    showProgress( text, force )
    {
        if( !this.parent.silent || force )
        {
            this.parent.ui.blockerText( ( undefined === text ? '<strong>Geteilte Elemente</strong> werden synchronisiert...' : text ) )
            this.parent.ui.updateProgress( this.total, this.step )
        }
    }

    preheatColleagues()
    {
        if( undefined !== this.baseClassHelper )
        {
            this.baseClassHelper
                .get( 'colleague' )
                .listAll( undefined, undefined, true )
        }
    }

    getColleague( uuid )
    {
        let colleagues = this.baseClassHelper
                             .get( 'colleague' )
                             .readCache( 'cache' )

        for( let c in colleagues )
        {
            if( colleagues[ c ].uuid === uuid )
            {
                return colleagues[ c ]
            }
        }
    }

    _rekey( element, sharedWith )
    {

        for( let s in sharedWith )
        {
            let colleague = this.getColleague( sharedWith[ s ] )
            if( undefined !== colleague )
            {
                let found = false
                for( let k in element._keys )
                {
                    if( element._keys[ k ].uuid === colleague.uuid
                        && ( undefined !== this.remoteKeys[ element.remoteId ]
                        && undefined !== this.remoteKeys[ element.remoteId ][ colleague.colleagueId ] ) )
                    {
                        found = true
                    }
                }
                if( !found )
                {
                    let newKey = this.cryptoHelper.rekey( element.localKey, colleague.publicKey )
                    element._keys.push( {
                        uuid: colleague.uuid,
                        key : newKey
                    } )
                }
            }
        }

    }

    hasMissingKeys( share, sharedElement )
    {

        for( let s in share.sharedWith )
        {

            let colleague = this.getColleague( share.sharedWith[ s ] )
            if( undefined !== colleague
                && ( undefined === this.remoteKeys[ sharedElement.remoteId ]
                     || ( undefined !== this.remoteKeys[ sharedElement.remoteId ] && undefined === this.remoteKeys[ sharedElement.remoteId ][ colleague.colleagueId ] ) ) )
            {
                return true
            }

        }

        for( let s in share.sharedWith )
        {
            let found = false
            for( let k in sharedElement._keys )
            {
                if( sharedElement._keys[ k ].uuid === share.sharedWith[ s ] )
                {
                    found = true
                }
            }
            if( !found )
            {
                return true
            }
        }

        return false

    }

    checkSharedElement( share, sharedElement )
    {

        if( undefined === sharedElement )
        {
            sharedElement = this.baseClassHelper
                                .get( share.objectType )
                                .getById( share.idReference )
        }

        if( undefined === sharedElement
            || true === sharedElement.archived )
        {
            return false
        }

        if( this.hasMissingKeys( share, sharedElement ) )
        {

            this._rekey( sharedElement, share.sharedWith )
            return [ {
                localId : sharedElement.localId,
                remoteId: sharedElement.remoteId,
                keys    : sharedElement._keys
            } ]

        }

        return false

    }

    checkSharedList( share )
    {

        let list    = this.baseClassHelper
                          .get( 'list' )
                          .getListContainer( share.idReference ),
            newKeys = []

        for( let l in list.lists )
        {
            let newKey = this.checkSharedElement( share, list.lists[ l ] )
            if( false !== newKey )
            {
                newKeys = [ ...newKeys, ...newKey ]
            }
        }

        if( 0 < newKeys.length )
        {
            return newKeys
        }

        return false

    }

    _addKeyForColleague( sharedElement, ownKey, colleague )
    {
        return new Promise( resolve =>
        {

            let colleagueKey = this.cryptoHelper.rekey( ownKey, colleague.publicKey )
            if( false !== colleagueKey )
            {

                this.client
                    .request( {
                        method     : 'media.storeMediaKey',
                        id         : sharedElement.fileId,
                        idColleague: colleague.colleagueId,
                        key        : colleagueKey
                    } )
                    .then( () =>
                    {
                        this.logger.clog( this.logSign, 'added key for colleague #' + colleague.colleagueId + ', file #' + sharedElement.fileId )
                        return resolve()
                    } )
                    .catch( () =>
                    {
                        this.logger.clog( this.logSign, 'failed to add key for colleague #' + colleague.colleagueId + ', file #' + sharedElement.fileId )
                        return resolve()
                    } )

            }
            return resolve()

        } )
    }

    _checkKeysForSharedMedia( share, sharedElement, ownKey, foreignKeys )
    {
        return new Promise( resolve =>
        {

            let keyUpdates = []

            for( let s in share.sharedWith )
            {

                let sharedWith   = share.sharedWith[ s ],
                    colleague    = this.getColleague( sharedWith ),
                    colleagueKey = null

                if( undefined !== colleague )
                {

                    for( let f in foreignKeys )
                    {
                        if( foreignKeys[ f ].idUser === colleague.colleagueId )
                        {
                            colleagueKey = foreignKeys[ f ].key
                        }
                    }

                    if( null === colleagueKey )
                    {
                        keyUpdates.push( () =>
                        {
                            return new Promise( resolve =>
                            {

                                this._addKeyForColleague( sharedElement, ownKey, colleague )
                                    .then( () =>
                                    {
                                        return resolve()
                                    } )

                            } )
                        } )
                    }

                }

            }

            this.parent.f.promiseRunner( keyUpdates )
                .then( () =>
                {
                    return resolve()
                } )

        } )

    }

    processSingleSharedMedia( share )
    {

        return new Promise( resolve =>
        {

            let sharedElement = this.baseClassHelper
                                    .get( 'media' )
                                    .getById( share.idReference )

            if( undefined !== sharedElement
                && null !== sharedElement
                && false !== sharedElement )
            {

                this.client
                    .request( {
                        method: 'media.listKeysForId',
                        id    : sharedElement.fileId
                    } )
                    .then( response =>
                    {

                        let ownKey      = null,
                            foreignKeys = []

                        for( let k in response.keys )
                        {
                            if( response.keys[ k ].id_user = this.store.getters.idUser )
                            {
                                ownKey = response.keys[ k ].secret
                            }
                            else
                            {
                                foreignKeys.push( {
                                    idUser: response.keys[ k ].id_user,
                                    key   : response.keys[ k ].secret
                                } )
                            }
                        }

                        this._checkKeysForSharedMedia( share, sharedElement, ownKey, foreignKeys )
                            .then( () =>
                            {
                                return resolve()
                            } )

                    } )
                    .catch( error =>
                    {
                        return resolve()
                    } )

            }
            else
            {
                return resolve()
            }

        } )

    }

    prepareMediaCache()
    {

        let cacheState = this.baseClassHelper
                             .get( 'media' ).state

        switch( cacheState )
        {
            case 'initial':
                this.baseClassHelper
                    .get( 'media' )
                    .cacheHeatup()
                return false
            case 'filled':
                return true
            default:
                return false
        }

    }

    processSharedMedia( shares )
    {
        return new Promise( resolve =>
        {

            if( 0 < shares.length )
            {
                if( this.prepareMediaCache() )
                {
                    let share = shares.shift()
                    this.processSingleSharedMedia( share )
                        .then( () =>
                        {
                            return resolve( this.processSharedMedia( shares ) )
                        } )
                }
                else
                {
                    setTimeout( () =>
                    {
                        return resolve( this.processSharedMedia( shares ) )
                    }, 300 )
                }
            }
            else
            {
                return resolve()
            }

        } )
    }

    getMediaFromLists( lists )
    {

        let mediaIds = {}

        for( let l in lists )
        {

            let listElm = this.baseClassHelper
                              .get( 'list' )
                              .getById( lists[ l ].idReference )

            if( undefined === listElm )
            {
                listElm = this.baseClassHelper
                              .get( 'list' )
                              .getContainer( lists[ l ].idReference )
            }

            if( undefined !== listElm
                && undefined !== listElm.lists )
            {
                for( let ll in listElm.lists )
                {
                    let list = listElm.lists[ ll ]
                    for( let c in list.columns )
                    {
                        if( list.columns[ c ].type === 'image' )
                        {

                            let valueKey = this.parent.sanitizers.cleanId( list.columns[ c ].caption ) + '___' + c
                            for( let v in list.values )
                            {

                                if( -1 < v.indexOf( valueKey ) )
                                {

                                    if( undefined === mediaIds[ valueKey ] )
                                    {
                                        mediaIds[ list.values[ v ] ] = []
                                    }

                                    for( let k in list._keys )
                                    {
                                        if( list._keys[ k ].uuid !== this.store.getters.uuid
                                            && -1 === mediaIds[ list.values[ v ] ].indexOf( list._keys[ k ].uuid ) )
                                        {
                                            mediaIds[ list.values[ v ] ].push( list._keys[ k ].uuid )
                                        }
                                    }

                                }

                            }
                        }
                    }
                }
            }

        }

        return mediaIds

    }

    processSharedMediaLists( shares, media, lists )
    {
        return new Promise( resolve =>
        {

            let mediaIds = this.getMediaFromLists( lists )
            this.logger.clog( this.logSign, 'shared media in lists found', Object.keys( mediaIds ).length, 'objects to be checked' )
            for( let m in mediaIds )
            {

                let found = false
                for( let mm in media )
                {
                    if( media[ mm ].idReference === m )
                    {

                        found = true

                    }
                }

                if( !found )
                {
                    let element = this.baseClassHelper
                                      .get( 'media' )
                                      .getById( m )

                    if( undefined !== element )
                    {

                        this.share.share( mediaIds[ m ], [ element ] )

                    }
                }

            }

            return resolve()

        } )
    }

    processSharedMediaInSharedLists( media )
    {
        return new Promise( resolve =>
        {

            this.baseClassHelper
                .get( 'list' )
                .listAll( undefined, undefined, true )
                .then( lists =>
                {

                    let todo = []
                    for( let l in lists )
                    {
                        if( undefined !== lists[ l ].lists )
                        {
                            for( let ll in lists[ l ].lists )
                            {
                                if( !this.f.isOwn( lists[ l ].lists[ ll ] )
                                    && this.share.isShared( lists[ l ].lists[ ll ] ) )
                                {
                                    todo.push( { idReference: lists[ l ].lists[ ll ].localId } )
                                }
                            }
                        }
                    }

                    let mediaIds = this.getMediaFromLists( todo )
                    this.logger.clog( this.logSign, 'shared media in shared lists found', Object.keys( mediaIds ).length, 'objects to be checked' )

                    for( let m in mediaIds )
                    {

                        let found = false
                        for( let mm in media )
                        {
                            if( media[ mm ].idReference === m )
                            {

                                found = true

                            }
                        }

                        if( !found )
                        {
                            let element = this.baseClassHelper
                                              .get( 'media' )
                                              .getById( m )

                            if( undefined !== element )
                            {

                                this.share.share( mediaIds[ m ], [ element ] )

                            }
                        }

                    }

                    return resolve()

                } )

        } )
    }

    processSharedMediaInLists()
    {
        return new Promise( resolve =>
        {

            this.baseClassHelper
                .get( 'share' )
                .listAll( undefined, undefined, true )
                .then( shares =>
                {

                    let lists = [],
                        media = []
                    for( let s in shares )
                    {
                        if( shares[ s ].objectType === 'media' )
                        {
                            media.push( shares[ s ] )
                        }
                        if( shares[ s ].objectType === 'list' )
                        {
                            lists.push( shares[ s ] )
                        }
                    }

                    if( 0 < lists.length )
                    {
                        this.processSharedMediaLists( shares, media, lists )
                            .then( () =>
                            {
                                return resolve( this.processSharedMediaInSharedLists( media ) )
                            } )
                    }
                    else
                    {
                        return resolve( this.processSharedMediaInSharedLists( media ) )
                    }

                } )

        } )
    }

    checkSharedMedia()
    {

        return new Promise( resolve =>
        {

            this.baseClassHelper
                .get( 'share' )
                .listAll( undefined, undefined, true )
                .then( shares =>
                {

                    let todo = []
                    for( let s in shares )
                    {
                        if( shares[ s ].objectType === 'media' )
                        {
                            todo.push( shares[ s ] )
                        }
                    }

                    if( 0 < todo.length )
                    {

                        this.processSharedMedia( todo )
                            .then( () =>
                            {
                                return resolve( this.processSharedMediaInLists() )
                            } )
                    }
                    else
                    {
                        return resolve( this.processSharedMediaInLists() )
                    }

                } )

        } )

    }

    checkRemoteKeys()
    {

        return new Promise( resolve =>
        {

            let remoteList = []

            this.baseClassHelper
                .getAllObjects()
                .then( list =>
                {

                    for( let l in list )
                    {
                        if( list[ l ].type === 'list' && Array.isArray( list[ l ].lists ) )
                        {
                            for( let ll in list[ l ].lists )
                            {
                                if( undefined !== list[ l ].lists[ ll ].remoteId
                                    && null !== list[ l ].lists[ ll ].remoteId
                                    && '' !== list[ l ].lists[ ll ].remoteId )
                                {
                                    remoteList.push( list[ l ].lists[ ll ].remoteId )
                                }
                            }
                        }
                        else
                        {
                            remoteList.push( list[ l ].remoteId )
                        }
                    }

                    this.client.request( {
                            method: 'objects.getRemoteKeyList',
                            list  : remoteList
                        } )
                        .then( response =>
                        {

                            this.remoteKeys = null
                            this.remoteKeys = {}

                            for( let l in response.list )
                            {
                                this.remoteKeys[ response.list[ l ].id_object ] = this.remoteKeys[ response.list[ l ].id_object ] || {}
                                this.remoteKeys[ response.list[ l ].id_object ][ response.list[ l ].id_user ] = response.list[ l ].secret
                            }

                            this.remoteKeysFetched = true
                            return resolve()

                        } )
                        .catch( () =>
                        {

                            this.remoteKeysFetched = false
                            return resolve()

                        } )

                } )

        } )

    }

    _keyCleanup( updatedKeys, keyUpdates )
    {

        let fixed = []

        for( let u in updatedKeys )
        {

            let found     = [],
                inUpdates = false,
                key       = updatedKeys[ u ],
                clone     = {}

            clone.localId = key.localId
            clone.remoteId = key.remoteId
            clone.keys = []

            for( let k in key.keys )
            {
                for( let u in keyUpdates )
                {
                    if( keyUpdates[ u ].localId === clone.localId )
                    {
                        for( let kk in keyUpdates[ u ].keys )
                        {
                            if( keyUpdates[ u ].keys[ kk ].uuid === key.keys[ k ].uuid )
                            {
                                inUpdates = true
                            }
                        }
                    }
                }
                if( !inUpdates && -1 === found.indexOf( key.keys[ k ].uuid ) )
                {
                    found.push( key.keys[ k ].uuid )
                    clone.keys.push( key.keys[ k ] )
                }
            }

            fixed.push( clone )

        }

        return fixed

    }

    checkShares( shares, keyUpdates )
    {

        return new Promise( resolve =>
        {

            keyUpdates = keyUpdates || []

            if( 0 < shares.length )
            {

                let share      = shares.shift(),
                    cacheState = this.baseClassHelper
                                     .get( share.objectType ).state

                if( 'filled' === cacheState )
                {
                    let updatedKeys = false
                    switch( share.objectType )
                    {
                        case 'list':
                            updatedKeys = this.checkSharedList( share )
                            break
                        default:
                            updatedKeys = this.checkSharedElement( share )
                            break
                    }

                    if( false !== updatedKeys )
                    {
                        keyUpdates = [ ...keyUpdates, ...this._keyCleanup( updatedKeys, keyUpdates ) ]
                    }

                    this.step++
                    this.showProgress()
                    return resolve( this.checkShares( shares, keyUpdates ) )

                }
                else
                {

                    this.baseClassHelper
                        .get( share.objectType )
                        .cacheHeatup()

                    this.step++
                    this.showProgress()
                    return resolve( this.checkShares( shares, keyUpdates ) )

                }

            }
            else
            {
                return resolve( keyUpdates )
            }

        } )

    }

    transformKeyUpdates( updates )
    {

        let keyUpdates = {}

        for( let u in updates )
        {

            if( undefined !== updates[ u ] )
            {

                let update = updates[ u ]
                if( undefined !== update.localId )
                {

                    if( 0 < update.keys.length )
                    {

                        if( undefined === keyUpdates[ update.localId ] )
                        {
                            keyUpdates[ update.localId ] = []
                        }

                        for( let k in update.keys )
                        {
                            let colleague = this.getColleague( update.keys[ k ].uuid )
                            if( undefined !== colleague )
                            {

                                keyUpdates[ update.localId ].push( {
                                    idColleague  : colleague.colleagueId,
                                    colleagueUuid: update.keys[ k ].uuid,
                                    key          : update.keys[ k ].key,
                                    remoteId     : update.remoteId
                                } )

                            }
                        }

                    }

                    if( 0 === keyUpdates[ update.localId ].length )
                    {
                        delete ( keyUpdates[ update.localId ] )
                    }

                }

            }
        }

        return keyUpdates

    }

    remoteKeyUpdates( keyUpdates )
    {

        let list    = keyUpdates,
            keyList = Object.keys( keyUpdates ),
            counter = 0,
            updates = {}

        while( 0 < keyList.length )
        {

            let itemKey = keyList.shift()
            updates[ itemKey ] = list[ itemKey ]
            if( 10 === counter )
            {

                let message = {
                    method: 'objects.updateKeyList',
                    list  : updates
                }

                let jobId = this.parent.uuid.generate()
                this.parent.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

                counter = 0
                updates = {}

            }

            counter++

        }

        if( 0 < Object.keys( updates ).length )
        {
            let message = {
                method: 'objects.updateKeyList',
                list  : updates
            }
            let jobId = this.parent.uuid.generate()
            this.parent.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )
        }

    }

    remoteKeysRemove( remoteUpdates )
    {

        let message = {
            method: 'objects.dropKeys',
            list  : remoteUpdates
        }

        let jobId = this.parent.uuid.generate()
        this.parent.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

    }

    processKeyUpdates( keyUpdates )
    {
        return new Promise( resolve =>
        {

            this.parent.database
                .appendObjectKeys( keyUpdates, this.parent.store.getters.uuid )
                .then( () =>
                {

                    this.remoteKeyUpdates( keyUpdates )
                    return resolve()

                } )

        } )
    }

    checkForeignShare( element )
    {
        if( undefined !== this.remoteKeys[ element.remoteId ] )
        {
            if( undefined === this.remoteKeys[ element.remoteId ][ this.store.getters.idUser ] )
            {
                this.baseClassHelper
                    .get( element.type )
                    .delete( element.localId, element.remoteId )
            }
        }
    }

    checkForeignDeletions()
    {
        return new Promise( resolve =>
        {

            if( this.remoteKeysFetched === true
                && 0 < Object.keys( this.remoteKeys ).length )
            {

                this.baseClassHelper
                    .getAllObjects()
                    .then( list =>
                    {

                        for( let l in list )
                        {
                            if( Array.isArray( list[ l ].lists ) )
                            {
                                for( let ll in list[ l ].lists )
                                {
                                    if( !this.rights.isOwner( list[ l ].lists[ ll ] ) )
                                    {
                                        this.checkForeignShare( list[ l ].lists[ ll ] )
                                    }
                                }
                            }
                            else
                            {
                                if( !this.rights.isOwner( list[ l ] ) )
                                {
                                    this.checkForeignShare( list[ l ] )
                                }
                            }

                        }

                        return resolve()

                    } )

            }
            else
            {
                this.logger.clog( this.logSign, 'no remote keys found - skipping...' )
                return resolve()
            }

        } )
    }

    resolveUnshare( unshare )
    {

        let reference = [],
            item

        switch( unshare.objectType )
        {
            case 'list':
                item = this.baseClassHelper
                           .get( 'list' )
                           .getContainer( unshare.idReference )
                if( Array.isArray( item ) )
                {
                    for( let i in item )
                    {
                        reference.push( item[ i ] )
                    }
                }
                break
            default:
                item = this.baseClassHelper
                           .getObjectById( unshare.idReference )
                if( undefined !== item )
                {
                    reference.push( item )
                }
                break
        }

        return reference

    }

    _processDbRemoveKey( removal )
    {
        return new Promise( resolve =>
        {

            this.logger.clog( this.logSign, 'processing database key removal for #' + removal.idLocal )

            this.parent.database
                .readObject( removal.idLocal )
                .then( result =>
                {

                    if( undefined !== result )
                    {
                        let keys  = [],
                            found = false
                        for( let k in result.object.keys )
                        {
                            if( result.object.keys[ k ].uuid !== removal.colleagueUuid )
                            {
                                keys.push( result.object.keys[ k ] )
                            }
                            else
                            {
                                found = true
                            }
                        }

                        if( found )
                        {
                            let newDbObject = this.f.deref( result.object )
                            newDbObject.keys = keys

                            this.parent.database
                                .writeObject( newDbObject, removal.idLocal, removal.type )
                                .then( () =>
                                {
                                    this.logger.clog( this.logSign, 'rewritten object with', keys.length, 'keys.' )
                                    return resolve()
                                } )
                        }
                        else
                        {
                            this.logger.clog( this.logSign, 'object stays unchanged' )
                            return resolve()
                        }

                    }
                    else
                    {
                        this.logger.clog( this.logSign, 'object not found: skipping database update.' )
                        return resolve()
                    }

                } )
        } )
    }

    finalizeUnshares( dropList, todoList, dbRemovals )
    {

        return new Promise( resolve =>
        {

            if( 0 < dbRemovals.length )
            {
                let removal = dbRemovals.shift()
                this._processDbRemoveKey( removal )
                    .then( () =>
                    {
                        return resolve( this.finalizeUnshares( dropList, todoList, dbRemovals ) )
                    } )
            }
            else
            {

                this.remoteKeysRemove( dropList )
                for( let t in todoList )
                {
                    this.baseClassHelper
                        .get( 'unshare' )
                        .delete( todoList[ t ].localId, todoList[ t ].remoteId )
                }

                return resolve()

            }

        } )

    }

    processUnshares( unshares )
    {
        return new Promise( resolve =>
        {

            let dropList   = {},
                todoList   = [],
                dbRemovals = []

            if( false === this.inUnsharemode )
            {
                return resolve()
            }

            this.logger.clog( this.logSign, 'processing', unshares.length, 'elements that need to be unshared...' )

            for( let u in unshares )
            {

                let unshare   = unshares[ u ],
                    reference = this.resolveUnshare( unshare )

                if( undefined !== reference )
                {
                    todoList.push( unshares[ u ] )
                    for( let r in reference )
                    {
                        dropList[ reference[ r ].localId ] = []
                        for( let s in unshare.sharedWith )
                        {
                            dbRemovals.push( {
                                idLocal      : reference[ r ].localId,
                                type         : reference[ r ].type,
                                colleagueUuid: unshare.sharedWith[ s ].uuid,
                                colleagueId  : unshare.sharedWith[ s ].colleagueId
                            } )
                        }
                        for( let s in unshare.sharedWith )
                        {
                            dropList[ reference[ r ].localId ].push( {
                                idColleague: unshare.sharedWith[ s ].colleagueId,
                                remoteId   : reference[ r ].remoteId
                            } )
                        }

                    }
                }

            }

            this.finalizeUnshares( dropList, todoList, dbRemovals )
                .then( () =>
                {
                    this.logger.clog( this.logSign, 'processed', todoList.length, 'unshares' )
                    return resolve()
                } )

        } )
    }

    hasOnlyOwnKey( remoteKey )
    {
        return ( 1 === Object.keys( remoteKey ).length
                 && undefined !== remoteKey[ this.ownUserId ] )
    }

    cleanOrphanedShares()
    {
        return new Promise( resolve =>
        {

            this.baseClassHelper
                .get( 'share' )
                .listAll( undefined, undefined, true )
                .then( shares =>
                {

                    let referenced = {},
                        doubles    = [],
                        orphans    = [],
                        changesIn  = []

                    for( let s in shares )
                    {

                        if( ( 1 === shares[ s ].sharedWith.length && shares[ s ].sharedWith[ 0 ] === this.ownUuid )
                            || ( 1 === shares[ s ].sharedWith.length && shares[ s ].sharedWith[ 0 ] === '' )
                            || undefined === shares[ s ].objectType )
                        {
                            orphans.push( shares[ s ] )
                        }
                        else if( 0 === shares[ s ].sharedWith.length )
                        {
                            orphans.push( shares[ s ] )
                        }
                        else
                        {

                            if( undefined === referenced[ shares[ s ].idReference ] )
                            {
                                referenced[ shares[ s ].idReference ] = shares[ s ]
                                let newList = [],
                                    changed = false
                                for( let w in referenced[ shares[ s ].idReference ].sharedWith )
                                {
                                    if( '' !== referenced[ shares[ s ].idReference ].sharedWith[ w ] )
                                    {
                                        newList.push( referenced[ shares[ s ].idReference ].sharedWith[ w ] )
                                    }
                                    else
                                    {
                                        changed = true
                                    }
                                }
                                if( changed )
                                {
                                    referenced[ shares[ s ].idReference ].sharedWith = newList
                                    changesIn.push( referenced[ shares[ s ].idReference ].localId )
                                }
                            }
                            else
                            {
                                for( let w in shares[ s ].sharedWith )
                                {
                                    if( -1 === referenced[ shares[ s ].idReference ].sharedWith.indexOf( shares[ s ].sharedWith[ w ] ) )
                                    {
                                        referenced[ shares[ s ].idReference ].sharedWith.push( shares[ s ].sharedWith[ w ] )
                                        changesIn.push( referenced[ shares[ s ].idReference ].localId )
                                    }
                                }
                                doubles.push( {
                                    localId : shares[ s ].localId,
                                    remoteId: shares[ s ].remoteId
                                } )
                            }

                        }

                    }

                    let updated = 0

                    for( let r in referenced )
                    {
                        if( -1 < changesIn.indexOf( referenced[ r ].localId ) )
                        {
                            this.baseClassHelper
                                .get( 'share' )
                                .update( referenced[ r ], referenced[ r ].localId, referenced[ r ].remoteId, referenced[ r ].timestamp, referenced[ r ].localKey )
                            updated++
                        }
                    }

                    this.logger.clog( this.logSign, 'cleanOrphanedShares', 'cleaned', updated, 'shares...' )

                    for( let d in doubles )
                    {
                        this.baseClassHelper
                            .get( 'share' )
                            .delete( doubles[ d ].localId, doubles[ d ].remoteId )
                    }
                    this.logger.clog( this.logSign, 'cleanOrphanedShares', 'deleted', doubles.length, 'double shares...' )

                    for( let o in orphans )
                    {
                        this.baseClassHelper
                            .get( 'share' )
                            .delete( orphans[ o ].localId, orphans[ o ].remoteId )
                    }
                    this.logger.clog( this.logSign, 'cleanOrphanedShares', 'deleted', orphans.length, 'orphaned shares...' )

                    return resolve()

                } )

        } )
    }

    firstRun()
    {
        return new Promise( resolve =>
        {
            this.checkRemoteKeys()
                .then( () =>
                {

                    this.cleanOrphanedShares()
                        .then( () =>
                        {

                            this.prepared = true
                            return resolve()

                        } )

                } )
        } )
    }

    sync()
    {

        return new Promise( resolve =>
        {

            if( undefined === this.rights )
            {
                this.rights = this.parent.core.r()
            }

            if( true !== this.getState( 'first-crud-sync-done' )
                || undefined === this.share )
            {
                if( undefined === this.share )
                {
                    this.logger.clog( this.logSign, 'shares helper not (yet) initialized...' )
                }
                return resolve()
            }

            if( this.f.isOnlineSyncableState()
                && !this.parent.paused
                && !this.syncing )
            {

                if( !this.prepared )
                {
                    this.firstRun()
                        .then( () =>
                        {
                            this.parent.logger.clog( this.logSign, 'prepared share sync...' )
                            return resolve()
                        } )
                }
                else
                {

                    this.parent.logger.clog( this.logSign, 'checking shares sync state right now...' )
                    this.showProgress()

                    this.checkRemoteKeys()
                        .then( () =>
                        {
                            this.baseClassHelper
                                .get( 'unshare' )
                                .listAll( undefined, undefined, true )
                                .then( unshares =>
                                {

                                    this.processUnshares( unshares )
                                        .then( () =>
                                        {

                                            if( this.inUnsharemode === true )
                                            {
                                                this.parent.logger.clog( this.logSign, 'unshares are in sync right now: nothing to do.' )
                                                this.inUnsharemode = false
                                                return resolve()
                                            }

                                            this.inUnsharemode = true

                                            this.baseClassHelper
                                                .get( 'share' )
                                                .listAll( undefined, undefined, true )
                                                .then( shares =>
                                                {

                                                    this.checkForeignDeletions()
                                                        .then( () =>
                                                        {

                                                            let hash       = this.parent.f.objectHash( shares, true ),
                                                                remoteHash = this.parent.f.objectHash( this.remoteKeys, true )

                                                            if( hash !== this.lastHash
                                                                || remoteHash !== this.lastRemoteHash )
                                                            {

                                                                this.lastHash = hash
                                                                this.lastRemoteHash = remoteHash
                                                                this.step = 0
                                                                this.total = shares.length
                                                                this.showProgress()
                                                                this.checkShares( shares )
                                                                    .then( keyUpdates =>
                                                                    {

                                                                        this.checkSharedMedia()
                                                                            .then( () =>
                                                                            {

                                                                                if( 0 < Object.keys( keyUpdates ).length )
                                                                                {

                                                                                    this.processKeyUpdates( this.transformKeyUpdates( keyUpdates ) )
                                                                                        .then( () =>
                                                                                        {

                                                                                            this.parent.logger.clog( this.logSign, 'synchronized', Object.keys( keyUpdates ).length, 'key updates' )
                                                                                            return resolve()

                                                                                        } )
                                                                                }
                                                                                else
                                                                                {
                                                                                    this.parent.logger.clog( this.logSign, 'shares are in sync right now: nothing to do.' )
                                                                                    return resolve()
                                                                                }

                                                                            } )

                                                                    } )

                                                            }
                                                            else
                                                            {
                                                                this.checkSharedMedia()
                                                                    .then( () =>
                                                                    {
                                                                        this.parent.logger.clog( this.logSign, 'shares unchanged: nothing to do.' )
                                                                        return resolve()
                                                                    } )
                                                            }

                                                        } )

                                                } )

                                        } )


                                } )
                                .catch( e =>
                                {

                                    this.parent.logger.cdebug( this.logSign, 'strange ' + e + ' caught in between...' )
                                    return resolve()

                                } )

                        } )

                }
            }
            else
            {
                this.parent.logger.cdebug( this.logSign, 'in unsyncable state right now...' )
                return resolve()
            }

        } )

    }


}