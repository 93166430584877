import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class OrganizerObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !OrganizerObjectClass.instance )
        {

            let refList = [
                'title',
                'color',
                'classReference',
                'groupReference',
                'yeargroupReference',
                'dateStart',
                'dateEnd',
                'noTimeRange',
                'global',
                'slots',
                'labels'
            ]

            let personalAttributesStatesList = [
                {
                    storage: 'hiding',
                    state  : 'hidden',
                    list   : 'hideLists'
                }
            ]

            super( core, 'organizer', refList, personalAttributesStatesList )
            this.objectType = 'organizer'
            this.isStudentShareable = true

            OrganizerObjectClass.instance = this
        }

        return OrganizerObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'title',
                caption    : 'title',
                translate  : true,
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'freetext',
                styleUpdate: true
            },
            {
                ref        : 'color',
                caption    : 'color',
                translate  : true,
                type       : 'ColorSelector',
                reformatter: null,
                optional   : false,
                validator  : 'color',
                styleUpdate: true
            },
            {
                ref        : 'global',
                caption    : 'organizer-global',
                translate  : true,
                type       : 'Checkbox',
                optional   : true,
                default    : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref         : 'classReference',
                caption     : 'classReference',
                translate   : true,
                type        : 'ClassSelector',
                foreign     : true,
                reformatter : null,
                optional    : false,
                oneOf       : [ 'classReference', 'groupReference', 'yeargroupReference' ],
                validator   : 'select',
                allowForeign: true,
                styleUpdate : true,
                hideIf      : 'global'
            },
            {
                ref         : 'groupReference',
                caption     : 'groupReference',
                translate   : true,
                type        : 'GroupSelector',
                foreign     : true,
                reformatter : null,
                optional    : false,
                oneOf       : [ 'classReference', 'groupReference', 'yeargroupReference' ],
                validator   : 'select',
                allowForeign: true,
                styleUpdate : true,
                hideIf      : 'global'
            },
            {
                ref         : 'yeargroupReference',
                caption     : 'yeargroupReference',
                translate   : true,
                type        : 'YeargroupSelector',
                foreign     : true,
                reformatter : null,
                optional    : false,
                oneOf       : [ 'classReference', 'groupReference', 'yeargroupReference' ],
                validator   : 'select',
                allowForeign: true,
                styleUpdate : true,
                hideIf      : 'global'
            },
            {
                ref        : 'noTimeRange',
                caption    : 'organizer-notimerange',
                translate  : true,
                type       : 'Checkbox',
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'dateStart',
                caption    : 'organizerstart',
                translate  : true,
                type       : 'DateBox',
                reformatter: 'date',
                optional   : false,
                validator  : 'date',
                styleUpdate: false,
                hideIf     : 'noTimeRange'
            },
            {
                ref        : 'dateEnd',
                caption    : 'organizerend',
                translate  : true,
                type       : 'DateBox',
                reformatter: 'date',
                optional   : true,
                validator  : 'date',
                styleUpdate: false,
                hideIf     : 'noTimeRange'
            },
            {
                ref        : 'slots',
                caption    : 'organizer-slots',
                translate  : true,
                type       : 'SlotEditorBox',
                reformatter: null,
                isRowAdder : true,
                optional   : false,
                validator  : 'freetext',
                styleUpdate: false
            },
            {
                ref        : 'labels',
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : 'freetext',
                styleUpdate: false
            }
        ]
    }

    getTabs()
    {
        return []
    }

    getFunctions()
    {
        return [ 'edit', 'share', 'delete' ]
    }

}