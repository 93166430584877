<template>
    <div key="distri-quarters" class="distribox">
        <div v-for="k in distributionKeys" :key="'key_distri_'+k"
             class="scorebox quarters">
            <div class="ratinglabel quarters">
                <strong>{{ k }}</strong>
            </div>
            <div class="scoreinput">
                <input v-if="k === 0" type="text" class="score"
                       placeholder="Pkt." :id="'ratio_'+k"
                       value="0" disabled="disabled" @keyup="checkKey( $event, k )"/>
                <input v-else type="text" class="score" placeholder="Pkt." :id="'ratio_'+k"
                       :value="distributionScores[k]" @keyup="checkKey( $event, k )"/>
            </div>
        </div>
        <div class="scorebox quarters scoretotal">
            <div class="ratinglabel scoretotal quarters">
                <strong>&#x2211;</strong>
            </div>
            <div class="scoreinput scoretotal">
                <strong>{{ $core.getReformatter().reformat( totalScore, 'localizedScore' ) }}</strong>
            </div>
        </div>
        <div class="clearfix"></div>
        <input type="hidden" :id="'form-element-'+refName" />
    </div>
</template>

<script>
import TestDistribution from '@/components/form/elements/tests/distributionKeys/TestDistribution'

export default {

    name: 'TestDistributionPoints',

    extends: TestDistribution,

    props: [
        'value',
        'refName',
        'reference',
        'validator',
        'reformatter',
        'display',
        'editor',
        'scores',
        'scoreModel'
    ],

    data()
    {
        return {
            stepsInversion          : true,
            distributionKeys        : [],
            distributionScores      : [],
            distributionTranslations: {
                15: '1+',
                14: '1',
                13: '1-',
                12: '2+',
                11: '2',
                10: '2-',
                9 : '3+',
                8 : '3',
                7 : '3-',
                6 : '4+',
                5 : '4',
                4 : '4-',
                3 : '5+',
                2 : '5',
                1 : '5-',
                0 : '6'
            },
            rows                    : {},
            refValue                : '',
            totalScore              : 0,
            distributionChanged     : false,
            changeTimer             : false,
            initialized             : false
        }
    },

    created()
    {

        this.initKeys()

        if( undefined !== this.$props.scores )
        {
            this.initScores()
        }

    },

    beforeUnmount()
    {
        this.$emit( 'componentChanged' )
    },

    methods: {

        initKeys()
        {

            let row = 0
            for( let i = 15; i >= 0; i -= 1 )
            {

                if( i < 13 )
                {
                    row = 1
                }
                if( i < 10 )
                {
                    row = 2
                }
                if( i < 7 )
                {
                    row = 3
                }
                if( i < 4 )
                {
                    row = 4
                }
                if( i < 1 )
                {
                    row = 5
                }

                if( undefined === this.rows[ row ] )
                {
                    this.rows[ row ] = []
                }

                this.distributionKeys.push( i )
                this.distributionScores.push( i < 17 ? undefined : 0 )
                this.rows[ row ].push( i )

            }

            this.initialized = true

        },
        initScores()
        {

            for( let i = 0; i < 17; i++ )
            {
                this.distributionScores[ i ] = this.$props.scores[ i ]
            }

        }
    }
}
</script>