export default class SyncSettings
{

    constructor( parent )
    {

        this.parent = parent
        this.client = parent.client
        this.parent.logger.clog( 'SyncWorker::SyncSettings', 'initialized' )

        this.logger = this.parent.logger
        this.firstRun = true

    }

    destruct()
    {
        this.parent = null
        delete this.parent
    }

    /**
     * prepareSyncables
     */
    prepareSyncables()
    {

        return new Promise( ( resolve, reject ) =>
        {

            let message = {
                method      : 'objects.getSettingsList',
                initialFetch: this.parent.initialFetch,
                since       : this.parent.getSyncParameters().since
            }

            this.client.request( message )
                .then( list =>
                {

                    return resolve( list.result )

                } )
                .catch( () =>
                {
                    return reject()
                } )

        } )

    }

    checkNewUser()
    {

        let isNewUser = this.parent.settings.getSetting( 'isNewUser' ),
            license   = this.parent.license.get()

        this.parent.s
            .get( 'count_login_online' )
            .then( count =>
            {

                let tsmpStart = null !== license ?
                                ( undefined !== license.start_timestamp ? license.start_timestamp : 0 ) : 0
                if( Date.now() > ( tsmpStart + ( 86400 * 1000 * 14 ) )
                    || count > 10 )
                {
                    if( isNewUser )
                    {
                        this.parent.settings.setSetting( 'isNewUser', false )
                    }
                }

            } )

    }

    sync()
    {

        return new Promise( resolve =>
        {

            if( this.parent.f.isOnlineSyncableState()
                && true !== this.parent.getState( 'settings-open' ) )
            {

                let background  = this.parent.settings.getSetting( 'backgroundStyle' ),
                    styleUpdate = false

                if( false === this.parent.silent )
                {
                    this.parent.ui.showBlocker( 'Synchronisiere', '<strong>Einstellungen</strong> werden synchronisiert...' )
                }

                this.prepareSyncables()
                    .then( resultString =>
                    {

                        let result = JSON.parse( resultString )

                        if( false !== result )
                        {
                            for( let s in result )
                            {

                                let setting = result[ s ]

                                if( s === 'backgroundStyle'
                                    && setting !== background )
                                {
                                    styleUpdate = setting
                                }

                                if( 'dashboardSequence' === s )
                                {
                                    setting = this.parent.settings.dashboardSequenceFix( 'dashboardSequence', setting )
                                }

                                this.parent.settings.setSetting( s, setting, true )

                            }
                        }

                        this.checkNewUser()

                        if( false !== styleUpdate
                            || true === this.firstRun )
                        {
                            this.parent.eventManager.dispatch( 'after-settings-synced' )
                            this.parent.syncBackground.sync()
                            setTimeout( () =>
                            {

                                this.firstRun = false
                                this.parent.logger.clog( 'SyncWorker::SyncSettings', 'dispatching background style update...' )
                                this.parent.eventManager.dispatch( 'update-background-style' )
                                this.parent.logger.clog( 'SyncWorker::SyncSettings', 'dispatching selected theme update...' )
                                this.parent.eventManager.dispatch( 'update-selected-theme' )
                                return resolve()

                            }, 2000 )
                        }
                        else
                        {
                            return resolve()
                        }

                    } )
                    .catch( () =>
                    {
                        this.parent.logger.cerror( 'SyncWorker::SyncSettings', 'no sync due to errors: retrying in next sync run.' )
                        return resolve()
                    } )
            }
            else
            {
                this.parent.logger.cdebug( 'SyncWorker::SyncSettings', 'in unsyncable state right now...' )
                return resolve()
            }
        } )

    }


}