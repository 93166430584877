<template>
    <div class="sortrules-selector">
        <div class="select-wrapper">
            <select :id="'sort-by-'+refName" @change="setSortBy()" @click="$core.f().skip">
                <option value="">bitte wählen...</option>
                <option v-for="option in availableOptions"
                        :key="'key_option_'+option.value"
                        :selected="sortBy === option.value"
                        :value="option.value">{{ option.caption }}
                </option>
            </select>
        </div>
        <div class="select-wrapper" v-if="sortBy !== false">
            <select :id="'sort-direction-'+refName" @change="setSortBy()" @click="$core.f().skip">
                <option v-for="option in availableDirections"
                        :key="'key_dir_option_'+option.value"
                        :selected="sortDirection === option.value"
                        :value="option.value">{{ translateOrder( option.value, sortBy ) }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
import RowAdderFunctions from '@/components/form/elements/RowAdderFunctions'

export default {

    name   : 'SortRulesSelector',
    extends: RowAdderFunctions,

    props: {
        id       : { Type: String, required: false },
        value    : { Type: String, required: false },
        ruleSet  : { Type: String, required: false },
        refName  : { Type: String, required: true },
        allValues: { Type: Object, required: false },
        eventKey : { Type: String, required: false }
    },

    watch: {
        allValues: {
            deep: true,
            handler()
            {
                this.$nextTick()
                    .then( () =>
                    {
                        this.prepareAvailableOptions()
                    } )

            }
        }
    },

    created()
    {
        this.setSortOptions()
    },

    mounted()
    {
        if( this.$props.value.sortBy )
        {
            this.sortBy = this.$props.value.sortBy
        }
        if( this.$props.value.direction )
        {
            this.direction = this.$props.value.direction
        }

        this.prepareAvailableOptions()
    },

    data()
    {
        return {
            sortBy             : false,
            sortDirection      : 'ascending',
            allOptions         : [],
            availableOptions   : [],
            availableDirections: [
                {
                    value  : 'ascending',
                    caption: 'aufsteigend'
                },
                {
                    value  : 'descending',
                    caption: 'absteigend'
                }
            ]
        }
    },

    methods: {

        prepareAvailableOptions()
        {

            let newSet = []

            for( let o in this.allOptions )
            {

                let found = this.allOptions[ o ].value === this.$props.value.sortBy
                let addable = true
                if( !found )
                {
                    for( let a in this.$props.allValues )
                    {
                        if( this.$props.allValues[ a ].value.sortBy === this.allOptions[ o ].value )
                        {
                            addable = false
                        }
                    }
                }

                if( found || addable )
                {
                    newSet.push( this.allOptions[ o ] )
                }

            }

            this.availableOptions = newSet
            this.$nextTick()
                .then( () =>
                {
                    this.select()
                } )

        },

        select()
        {

            let elm = document.querySelector( '#sort-by-' + this.$props.refName )
            let dir = document.querySelector( '#sort-direction-' + this.$props.refName )
            if( this.$core.f().isset( elm ) )
            {
                elm.value = this.$core.f().isset( this.$props.value.sortBy ) ? this.$props.value.sortBy : ''
            }
            if( this.$core.f().isset( dir ) )
            {
                dir.value = this.$core.f().isset( this.$props.value.direction ) ? this.$props.value.direction : 'ascending'
            }
            if( '' === elm.value.trim() )
            {
                this.$emit( 'adderInvalid' )
            } else
            {
                this.$emit( 'adderValid' )
            }
        },

        setSortBy()
        {

            let elm = document.querySelector( '#sort-by-' + this.$props.refName )
            let dir = document.querySelector( '#sort-direction-' + this.$props.refName )

            if( this.$core.f().isset( elm ) && '' !== elm.value.trim() )
            {
                this.sortBy = elm.value.trim()
                this.$emit( 'adderValid' )
            } else
            {
                this.sortBy = false
                this.$emit( 'adderInvalid' )
            }

            if( this.$core.f().isset( dir ) )
            {
                this.sortDirection = dir.value
            }

            this.$emit( 'update', this.$props.refName, 'sortRulesSelector', 'update', undefined, { type: 'custom', sortBy: this.sortBy, direction: this.sortDirection } )

        },

        translateOrder( which, key )
        {
            switch( which )
            {
                case 'ascending':
                    return this.translateAscending( key )
                case 'descending':
                    return this.translateDescending( key )
            }
        },

        translateAscending( which )
        {
            switch( which )
            {
                case 'classname':
                case 'groupname':
                case 'firstname':
                case 'lastname':
                    return 'alphabetisch aufsteigend (A bis Z)'
                case 'color':
                    return 'gewählte Farbreihenfolge vorwärts'
                case 'pinned':
                    return 'angepinnt zuletzt'
                case 'done':
                    return 'zu erledigende zuletzt'
                case 'duedate':
                    return 'bald fällige zuletzt'
                case 'tsmpEnd':
                case 'timestamp':
                case 'done_timestamp':
                    return 'aufsteigend (älteste Elemente zuerst)'
                case 'update':
                    return 'aufsteigend (älteste Aktualisierung zuerst)'
                default:
                    return 'aufsteigend'
            }
        },

        translateDescending( which )
        {
            switch( which )
            {
                case 'classname':
                case 'groupname':
                case 'firstname':
                case 'lastname':
                    return 'alphabetisch absteigend (Z bis A)'
                case 'color':
                    return 'gewählte Farbreihenfolge rückwärts'
                case 'pinned':
                    return 'angepinnt zuerst'
                case 'done':
                    return 'zu erledigende zuerst'
                case 'duedate':
                    return 'bald fällige zuerst'
                case 'tsmpEnd':
                case 'timestamp':
                case 'done_timestamp':
                    return 'absteigend (neueste Elemente zuerst)'
                case 'update':
                    return 'absteigend (neueste Aktualisierung zuerst)'
                default:
                    return 'absteigend'
            }
        },
        
        setSortOptions()
        {

            switch( this.ruleSet )
            {
                case 'notes':
                    this.allOptions = [
                        {
                            value  : 'color',
                            caption: 'Farbe'
                        },
                        {
                            value  : 'timestamp',
                            caption: 'Erstellungszeitpunkt'
                        },
                        {
                            value  : 'update',
                            caption: 'Zuletzt aktualisiert'
                        }
                    ]
                    break
                case 'todos':
                    this.allOptions = [
                        {
                            value  : 'done',
                            caption: 'Zu erledigende Todos'
                        },
                        {
                            value  : 'color',
                            caption: 'Farbe'
                        },
                        {
                            value  : 'duedate',
                            caption: 'Fälligkeit'
                        },
                        {
                            value  : 'done_timestamp',
                            caption: 'Erledigungszeitpunkt'
                        }
                    ]
                    break
                case 'office':
                    this.allOptions = [
                        {
                            value  : 'color',
                            caption: 'Farbe'
                        },
                        {
                            value  : 'listname',
                            caption: 'Name'
                        },
                        {
                            value  : 'timestamp',
                            caption: 'Erstellungszeitpunkt'
                        },
                        {
                            value  : 'tsmpEnd',
                            caption: 'Version / Datum'
                        },
                        {
                            value  : 'update',
                            caption: 'letzte Aktualisierung'
                        }
                    ]
                    break
                case 'lists':
                    this.allOptions = [
                        {
                            value  : 'color',
                            caption: 'Farbe'
                        },
                        {
                            value  : 'timestamp',
                            caption: 'Erstellungszeitpunkt'
                        },
                        {
                            value  : 'update',
                            caption: 'letzte Aktualisierung'
                        }
                    ]
                    break
                case 'students':
                    this.allOptions = [
                        {
                            value  : 'firstname',
                            caption: 'Vorname'
                        },
                        {
                            value  : 'lastname',
                            caption: 'Nachname'
                        },
                        {
                            value  : 'gender',
                            caption: 'Geschlecht'
                        },
                        {
                            value  : 'classname',
                            caption: 'Klassenname'
                        }
                    ]
                    break
                case 'classes':
                    this.allOptions = [
                        {
                            value  : 'classname',
                            caption: 'Klassenname'
                        },
                        {
                            value  : 'color',
                            caption: 'Farbe'
                        }
                    ]
                    break
                case 'groups':
                    this.allOptions = [
                        {
                            value  : 'groupname',
                            caption: 'Gruppenname'
                        },
                        {
                            value  : 'color',
                            caption: 'Farbe'
                        }
                    ]
                    break
            }
        }
    }

}
</script>
