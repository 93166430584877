<template>
    <div class="page-topbuttons-boundaries">
        <div class="page-topbuttons-container">
            <template v-if="$core.getLicense().isAllowed( 'show_intro' )">
                <div class="page-topbutton large" @click="showHelp()">
                    {{ $core.t( 'help' ) }}
                </div>
                <div v-if="$store.getters.authorized === true"
                     class="page-topbutton small" @click="handleTutorial()">
                    {{ $core.t( 'tutorial' ) }}
                </div>
            </template>
            <div v-if="$store.getters.authorized === true && $store.getters.online === true"
                 class="page-topbutton small" @click="handleFeedback()">
                {{ $core.t( 'feedback' ) }}
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
export default {

    name: 'HelpButton',

    data()
    {
        return {
            links  : {
                students  : '-schuelerinnen_und_schueler',
                classes   : '-klassen-anlegen-und-verwalten',
                groups    : '-gruppen-anlegen-und-verwalten',
                yeargroups: '-jahrgaenge-anlegen-und-verwalten',
                office    : '-buero',
                notes     : '-notizen',
                todos     : '-todos',
                planner   : '-kalender',
                colleagues: '-kollegium',
                messages  : '-postfach',
                admin     : '-admin',
                settings  : '-einstellungen'
            },
            baseUri: 'https://www.entzettelt.de/faq-hilfe',
        }
    },

    methods: {

        showHelp()
        {
            if( this.$store.getters.online === true )
            {

                let link      = this.links[ this.$route.name ],
                    targetUri = this.baseUri + ( undefined !== link ? link : '' )
                window.open( targetUri, '_blank' )
            }
            else
            {
                this.$core.getUi().setPageMessage( this.$core.t( 'error-caption-offline' ),
                    this.$core.t( 'error-message-offline-help' ),
                    'error' )
            }

        },

        handleRecommend()
        {
            this.$router.push( { name: 'promotion', params: { scope: this.$route.name } } )
        },

        handleFeedback()
        {
            this.$router.push( { name: 'feedback', params: { scope: this.$route.name } } )
        },

        handleTutorial()
        {
            this.$core.getEventManager().dispatch( 'start-tutorial', this.$route.name )
        }

    }

}
</script>