<template>
    <div>
        <template v-if="ready">
            <span v-if="$core.f().isset( refItem )"
                  :class="'reference-marker label '+( selected ? 'selected ' : ''  )+refItem.color+( additionalClass ? ' '+additionalClass : '' )"
                  :key="'refmark-label-'+refItem.localId"
                  @click="handleClick()">
                <span class="label-icon"></span> {{ refItem.caption }}
                <template v-if="deletionAllowed">
                    <Button type="delete labelsize" @clicked="handleLabelDelete"/>
                </template>
            </span>
        </template>
        <template v-else>
            <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
                <SyncingReferenceMarker/>
            </transition>
        </template>
    </div>
</template>

<script>

import SyncingReferenceMarker  from "@/components/elements/markers/SyncingReferencesMarker";
import MixinCacheStateReporter from "@/mixins/MixinCacheStateReporter";

export default {

    name      : 'LabelMarker',
    components: { SyncingReferenceMarker },
    mixins    : [ MixinCacheStateReporter ],
    props     : {
        reference      : { Type: String, required: true },
        deletionAllowed: { Type: Boolean, required: false },
        additionalClass: { Type: String, required: false },
        isSelected     : { Type: Boolean, required: false },
        selectable     : { Type: Boolean, required: false },
        emitClick      : { Type: Boolean, required: false }
    },

    emits: [ 'selected', 'deselected', 'deleted', 'clicked' ],

    data()
    {
        return {
            ready       : false,
            cacheState  : 'label',
            refItem     : false,
            clickBlocked: true,
            selected    : false
        }
    },

    created()
    {
        this.selected = this.$props.isSelected || false
    },

    methods: {

        performDelete()
        {
            this.$core.getBaseClassHelper()
                .get( 'label' )
                .delete( this.reference )
                .then( () =>
                {
                    this.$emit( 'deleted' )
                } )
        },

        handleClick()
        {

            if( this.$props.emitClick === true )
            {
                this.$emit( 'clicked' )
                return
            }

            if( true === this.$props.selectable )
            {
                this.selected = !this.selected
                if( this.selected )
                {
                    this.$emit( 'selected' )
                }
                else
                {
                    this.$emit( 'deselected' )
                }
            }

        },

        handleLabelDelete( event )
        {

            this.$core.f().skip( event )

            let buttons = [
                'defaultCancel',
                {
                    type    : 'submit',
                    title   : 'Ja',
                    callback: () =>
                    {
                        this.performDelete()
                    }
                }
            ]

            this.$core.getUi()
                .showModalDialog( 'delete', 'Label löschen', 'Bist du sicher, dass du das Label "' + this.refItem.caption + '" löschen möchtes?', buttons )

        }
    }
}
</script>