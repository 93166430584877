export default class SyncBubbles
{

    constructor( parent )
    {
        if( !SyncBubbles.instance )
        {

            this.parent = parent
            this.objectRegistry = parent.objectRegistry
            this.parent.logger.cconstructed( 'SyncWorker:SyncBubbles', 'bubble sync constructed...' )

            this.lastId = 0
            this.grace = 60000
            this.lastSuccess = 0
            this.lastHash = false

            SyncBubbles.instance = this

            this.initLastId()

        }
        return SyncBubbles.instance

    }

    initLastId()
    {
        this.parent.database.readAllObjects( 'bubble_details' )
            .then( list =>
            {
                for( let l in list )
                {
                    if( list[ l ].key > this.lastId )
                    {
                        this.lastId = list[ l ].key
                    }
                }
            } )
            .catch( () =>
            {
                this.lastId = 0
            } )
    }

    _decryptDetails( details, result )
    {
        return new Promise( resolve =>
        {

            result = result || []

            if( 0 < details.length )
            {
                let row = details.shift()
                if( row.id > this.lastId )
                {
                    this.lastId = row.id
                }
                this.parent.cryptoHelper.encrypt( JSON.stringify( row ) )
                    .then( crypted =>
                    {
                        result.push( {
                            id     : row.id,
                            crypted: crypted
                        } )

                        return resolve( this._decryptDetails( details, result ) )
                    } )
            }
            else
            {
                return resolve( details )
            }

        } )
    }

    /*eslint-disable*/
    detailSync()
    {

        return new Promise( resolve =>
        {

            if( this.lastSuccess > ( Date.now() - this.grace ) )
            {
                return resolve()
            }

            this.parent.client.request( {
                method: 'network.getBubbleDetails',
                lastId: this.lastId
            } ).then( response =>
            {

                let promises = []
                this._decryptDetails( response.details )
                    .then( list =>
                    {

                        for( let l in list )
                        {
                            if( false !== list[ l ].crypted )
                            {

                                promises.push( new Promise( resolve =>
                                {

                                    this.parent.database.write( 'bubble_details', list[ l ].id, list[ l ].crypted )
                                        .then( () =>
                                        {
                                            return resolve()
                                        } )
                                        .catch( () =>
                                        {
                                            return resolve()
                                        } )

                                } ) )
                            }
                        }

                        if( 0 < promises.length )
                        {
                            Promise.all( promises )
                                   .then( () =>
                                   {
                                       this.lastSuccess = Date.now()
                                       this.parent.eventManager.dispatch( 'on-new-bubbles' )
                                       return resolve()
                                   } )
                        }
                        else
                        {
                            this.lastSuccess = Date.now()
                            this.parent.eventManager.dispatch( 'on-new-bubbles' )
                            return resolve()
                        }

                    } )


            } ).catch( () =>
            {
                return resolve()
            } )

        } )
    }

    sync()
    {

        return new Promise( resolve =>
        {

            if( this.parent.f.isOnlineSyncableState() )
            {

                let colleagues = this.parent.cc.getAll()
                if( 0 < colleagues.length )
                {

                    this.parent.logger.clog( 'SyncWorker:SyncBubbles', 'synchronizing bubbles' )
                    this.parent.client.request( {
                            method: 'network.getBubbles'
                        } )
                        .then( result =>
                        {

                            let promises = [],
                                bubbles  = result.bubbles,
                                hash     = this.parent.f.objectHash( result.bubbles, true )

                            if( this.lastHash !== hash )
                            {

                                this.lastHash = hash

                                for( let k in Object.keys( bubbles ) )
                                {

                                    let key = Object.keys( bubbles )[ k ]
                                    let value = bubbles[ key ]

                                    promises.push( new Promise( resolve =>
                                    {
                                        this.parent.database.write( 'bubbles', key, value )
                                            .then( () =>
                                            {
                                                return resolve()
                                            } )

                                    } ) )

                                }

                                Promise.all( promises )
                                       .then( () =>
                                       {
                                           this.parent.eventManager.dispatch( 'on-bubble-sync' )
                                           return resolve( this.detailSync() )
                                       } )

                            }
                            else
                            {
                                this.parent.logger.clog( 'SyncWorker:SyncBubbles', 'bubbles unchanged.' )
                                return resolve()
                            }

                        } )
                        .catch( () =>
                        {
                            this.parent.logger.clog( 'SyncWorker:SyncBubbles', 'no bubbles found.' )
                            return resolve()
                        } )

                }
                else
                {
                    this.parent.logger.clog( 'SyncWorker:SyncBubbles', 'no colleagues found: skipping sync.' )
                    return resolve()
                }

            }
            else
            {
                return resolve()
            }

        } )

    }

}