/*eslint-disable*/
export default class afterUpdateList
{

    constructor( core )
    {
        this.baseClassHelper = core.getBaseClassHelper()
        this.f = core.f()
    }

    _createContainer( list )
    {

        let container = {}

        for( let key in list )
        {
            if( key !== 'values' )
            {
                container[ key ] = list[ key ]
            }
        }

        container.isDemoContent = list.isDemoContent
        container.tsmpEnd = list.timestamp
        container.tsmpStart = list.timestamp
        container.labels = list.labels
        container.lasteditor = list.lasteditor
        container.lists = []
        container.lists.push( list )

        return container

    }

    fixMeta( container, list )
    {
        if( container.labels !== list.labels )
        {
            container.labels = list.labels
        }
        if( container.tsmpEnd < list.timestamp )
        {
            container.tsmpEnd = list.timestamp
        }
        if( container.tsmpStart > list.timestamp )
        {
            container.tsmpStart = list.timestamp
        }
        if( container.update < list.update )
        {
            container.update = list.update
            container.lasteditor = list.lasteditor
        }
    }

    updateBaseMeta( container, list )
    {

        let baseMeta = [
            'archiveKey', 'archived', 'color', 'distribution', 'listname', 'scoreModel'
        ]

        for( let b in baseMeta )
        {
            let key = baseMeta[ b ]
            if( container[ key ] !== list[ key ] )
            {
                container[ key ] = list[ key ]
            }
        }

    }

    _appendRemoteId( list, container )
    {
        return undefined !== list.remoteId && undefined === container.remoteId
    }

    isListForToday( timestamp )
    {

        let now  = new Date(),
            then = new Date( timestamp )

        return ( now.getFullYear() === then.getFullYear()
                 && now.getMonth() === then.getMonth()
                 && now.getDate() === then.getDate() )

    }

    afterUpdate( lists, cache, skipArchived )
    {

        return new Promise( ( resolve, reject ) =>
        {

            let containers = {},
                result     = []

            for( let c of cache.keys() )
            {
                containers[ cache.get( c ).referenceKey ] = cache.get( c )
            }

            for( let c in containers )
            {
                for( let l in lists )
                {
                    if( lists[ l ].referenceKey === c )
                    {
                        this.updateBaseMeta( containers[ c ], lists[ l ] )
                    }
                }
            }

            for( let l in lists )
            {
                if( undefined !== lists[ l ].lists )
                {
                    containers[ lists[ l ].referenceKey ] = lists[ l ]
                    for( let ll in lists[ l ].lists )
                    {
                        this.fixMeta( containers[ lists[ l ].referenceKey ], lists[ l ].lists[ ll ] )
                    }
                }
                else
                {

                    if( undefined === containers[ lists[ l ].referenceKey ] )
                    {
                        containers[ lists[ l ].referenceKey ] = this._createContainer( lists[ l ] )
                    }
                    else
                    {
                        let found = false
                        for( let ll in containers[ lists[ l ].referenceKey ].lists )
                        {
                            if( containers[ lists[ l ].referenceKey ].lists[ ll ].localId === lists[ l ].localId )
                            {
                                found = true
                                if( true === this._appendRemoteId( lists[ l ], containers[ lists[ l ].referenceKey ] ) )
                                {
                                    containers[ lists[ l ].referenceKey ].remoteId = lists[ l ].remoteId
                                }
                                containers[ lists[ l ].referenceKey ].lists[ ll ] = lists[ l ]
                                this.fixMeta( containers[ lists[ l ].referenceKey ], lists[ l ] )
                                this.f.objectHash( containers[ lists[ l ].referenceKey ] )
                            }
                        }
                        if( !found )
                        {
                            if( true === this._appendRemoteId( lists[ l ], containers[ lists[ l ].referenceKey ] ) )
                            {
                                containers[ lists[ l ].referenceKey ].remoteId = lists[ l ].remoteId
                            }
                            containers[ lists[ l ].referenceKey ].lists.push( lists[ l ] )
                            this.fixMeta( containers[ lists[ l ].referenceKey ], lists[ l ] )
                            if( this.isListForToday( lists[ l ].timestamp ) )
                            {
                                containers[ lists[ l ].referenceKey ].listForToday = lists[ l ]
                            }
                            this.f.objectHash( containers[ lists[ l ].referenceKey ] )
                        }
                    }

                }
            }

            for( let key in containers )
            {
                result.push( containers[ key ] )
            }

            return resolve( result )

        } )
    }

}