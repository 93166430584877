import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class TemplatesObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !TemplatesObjectClass.instance )
        {

            let refList = [ 'templatename',
                            'color',
                            'identifier',
                            'columns',
                            'listType',
                            'referenceList',
                            'requiredFeatureSet',
                            'flatDefinition',
                            'idAuthor',
                            'remoteCode',
                            'timestamp',
                            'update' ]

            let personalAttributesStatesList = [
                {
                    storage: 'pinning',
                    state  : 'pinned',
                    list   : 'pinLists'
                }
            ]


            super( core, 'template', refList, personalAttributesStatesList )

            this.sanitizer = core.getSanitizers()
            this.friendlyTimestamp = core.getFriendlyTimestamp()
            this.objectType = 'templates'

            TemplatesObjectClass.instance = this
        }

        return TemplatesObjectClass.instance

    }

    getTabs()
    {
        return []
    }

    getFunctions()
    {
        return [ 'pin', 'share', 'delete' ]
    }

}