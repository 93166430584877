import StudentTranslations from "@/classes/Helpers/Translations/StudentTranslations";
import FullTranslations    from "@/classes/Helpers/Translations/FullTranslations";

export default class Translation
{

    constructor( featureSet, languageKey )
    {

        if( !Translation.instance )
        {

            this.translations = this.fillTranslations()
            this.lastKey = ''
            this.languageKey = languageKey || 'de-DE'
            this.featureSet = featureSet || 'basic'
            this.translateByFeatureSet = {
                full   : new FullTranslations(),
                student: new StudentTranslations()
            }
            this.allInitialized = false
            this.eventManager = null

            this.setFeatureSet( this.featureSet )

            Translation.instance = this

        }

        return Translation.instance

    }

    injectEventManager( eventManager )
    {
        this.eventManager = eventManager
        this.eventManager.add( 'on-core-state-change-featureSet', ( which ) =>
        {
            this.setFeatureSet( which )
        } )
    }

    setFeatureSet( featureSet )
    {

        if( ( undefined !== this.translateByFeatureSet[ featureSet ] || 'basic' === this.featureSet )
            && ( !this.allInitialized || this.featureSet !== featureSet ) )
        {

            this.featureSet = featureSet === 'basic' ? 'full' : featureSet
            this.translations = this.fillTranslations()
            let full = this.translateByFeatureSet[ 'full' ].get()
            for( let f in full )
            {
                delete this.translations[ f ]
                this.translations[ f ] = full[ f ]
            }

            if( 'full' !== this.featureSet )
            {
                let setup = this.translateByFeatureSet[ this.featureSet ].get()
                for( let k in setup )
                {
                    delete this.translations[ k ]
                    this.translations[ k ] = setup[ k ]
                }
            }

            this.allInitialized = true
            this.dispatchReady()

        }

    }

    dispatchReady()
    {
        if( null !== this.eventManager )
        {
            this.eventManager.dispatchAndRemove( 'on-translations-ready' )
        }
        else
        {
            setTimeout( () =>
            {
                this.dispatchReady()
            }, 2000 )
        }
    }

    translate( key, params, noKeyWarning )
    {

        if( undefined !== this.translations[ key ] )
        {
            let translation = this.translations[ key ]
            if( undefined !== params )
            {
                if( 'string' === typeof params )
                {
                    translation = translation.replace( '%%param0%%', params )
                }
                else if( Array.isArray( params ) )
                {
                    for( let i = 0; i < params.length; i++ )
                    {
                        translation = translation.replace( '%%param' + i + '%%', params[ i ] )
                    }
                }
            }
            this.lastKey = key

            if( 'string' === typeof translation )
            {
                let ez_html = this.translations[ 'entzettelt_html' ]
                translation = translation.replace( '%%ez%%', ez_html )
                if( 't:' === translation.substring( 0, 2 ) )
                {
                    let newKey = translation.replace( 't:', '' )
                    return this.translate( newKey, params, noKeyWarning )
                }
            }

            return translation
        }

        if( noKeyWarning )
        {
            return key
        }

        return '[[ MISSING KEY: ' + key + ' ]]'

    }

    numberWord( numberString )
    {

        let specialNumbers = []
        specialNumbers[ 11 ] = 'elf'
        specialNumbers[ 12 ] = 'zwölf'
        specialNumbers[ 16 ] = 'sechzehn'
        specialNumbers[ 17 ] = 'siebzehn'

        let numbers = []
        numbers[ 1 ] = 'ein'
        numbers[ 2 ] = 'zwei'
        numbers[ 3 ] = 'drei'
        numbers[ 4 ] = 'vier'
        numbers[ 5 ] = 'fünf'
        numbers[ 6 ] = 'sechs'
        numbers[ 7 ] = 'sieben'
        numbers[ 8 ] = 'acht'
        numbers[ 9 ] = 'neun'
        numbers[ 10 ] = 'zehn'
        numbers[ 20 ] = 'zwanzig'
        numbers[ 30 ] = 'dreißig'
        numbers[ 40 ] = 'vierzig'
        numbers[ 50 ] = 'fünfzig'
        numbers[ 60 ] = 'sechzig'
        numbers[ 70 ] = 'siebzig'
        numbers[ 80 ] = 'achtzig'
        numbers[ 90 ] = 'neunzig'

        let blocks = [ '', 'tausend', 'Million', 'Milliarde', 'Billion' ]
        let divider = 1000
        let numberInWords = ''

        if( numberString === '0' )
        {
            numberInWords = 'null'
        }

        for( let i = 0; i < Math.ceil( numberString.length / 3 ); i++ )
        {

            let number = parseInt( numberString )
            let numberBlock
            let ones
            let tens
            let hundreds
            let blockEnding

            if( i > blocks.length - 1 )
            {
                return 'Zahl nicht unterstützt'
            }
            if( i == 0 )
            {
                numberBlock = number % divider
            }
            else
            {
                numberBlock = ( ( number % divider ) - ( number % ( divider / 1000 ) ) ) / ( divider / 1000 )
            }
            ones = numberBlock % 10
            tens = numberBlock % 100
            hundreds = ( numberBlock - ( numberBlock % 100 ) ) / 100
            blockEnding = blocks[ i ].substr( blocks[ i ].length - 1, 1 )

            if( numberBlock > 0 )
            {
                if( numberBlock > 1 && blockEnding == 'n' )
                {
                    numberInWords = ' ' + blocks[ i ] + 'en ' + numberInWords
                }
                else if( numberBlock > 1 && blockEnding == 'e' )
                {
                    numberInWords = ' ' + blocks[ i ] + 'n ' + numberInWords
                }
                else if( numberBlock > 0 && i == 1 )
                {
                    numberInWords = blocks[ i ] + numberInWords
                }
                else
                {
                    numberInWords = ' ' + blocks[ i ] + ' ' + numberInWords
                }
            }

            if( tens > 0 )
            {
                if( tens == 1 && i == 0 )
                {
                    numberInWords = 'eins' + numberInWords
                }
                else if( tens == 1 && i == 1 )
                {
                    numberInWords = 'ein' + numberInWords
                }
                else if( tens == 1 && i > 1 )
                {
                    numberInWords = 'eine' + numberInWords
                }
                else if( specialNumbers[ tens ] )
                {
                    numberInWords = specialNumbers[ tens ] + numberInWords
                }
                else
                {
                    if( tens > 9 )
                    {
                        numberInWords = numbers[ tens - ones ] + numberInWords
                    }
                    if( tens > 20 && ones > 0 )
                    {
                        numberInWords = 'und' + numberInWords
                    }
                    if( ones > 0 )
                    {
                        numberInWords = numbers[ ones ] + numberInWords
                    }
                }
            }

            if( hundreds > 0 )
            {
                numberInWords = numbers[ hundreds ] + 'hundert' + numberInWords
            }

            divider *= 1000
        }

        return numberInWords.trim()

    }

    capitalize( s )
    {

        if( typeof s !== 'string' )
        {
            return ''
        }

        return s.charAt( 0 ).toUpperCase() + s.slice( 1 )

    }

    initializeScoreModels()
    {

        return {
            basic        : {
                0: {
                    number     : 1,
                    translation: 'sehr gut'
                },
                1: {
                    number     : 2,
                    translation: 'gut'
                },
                2: {
                    number     : 3,
                    translation: 'befriedigend'
                },
                3: {
                    number     : 4,
                    translation: 'ausreichend'
                },
                4: {
                    number     : 5,
                    translation: 'mangelhaft'
                },
                5: {
                    number     : 6,
                    translation: 'ungenügend'
                }
            },
            bremen       : {
                0: {
                    number     : 1,
                    translation: 'sicher',
                    short      : 'sicher'
                },
                1: {
                    number     : 2,
                    translation: 'überwiegend sicher',
                    short      : 'üw. sicher'
                },
                2: {
                    number     : 3,
                    translation: 'teilweise sicher',
                    short      : 'tw. sicher'
                },
                3: {
                    number     : 4,
                    translation: 'unsicher',
                    short      : 'unsicher'
                },
            },
            austria      : {
                0: {
                    number     : 1,
                    translation: 'sehr gut'
                },
                1: {
                    number     : 2,
                    translation: 'gut'
                },
                2: {
                    number     : 3,
                    translation: 'befriedigend'
                },
                3: {
                    number     : 4,
                    translation: 'genügend'
                },
                4: {
                    number     : 5,
                    translation: 'nicht genügend'
                }
            },
            extended     : {
                0 : {
                    number     : '1+',
                    calc       : 1,
                    translation: 'sehr gut (+)'
                },
                1 : {
                    number     : '1',
                    calc       : 1,
                    translation: 'sehr gut'
                },
                2 : {
                    number     : '1-',
                    calc       : 1,
                    translation: 'sehr gut (-)'
                },
                3 : {
                    number     : '2+',
                    calc       : 2,
                    translation: 'gut (+)'
                },
                4 : {
                    number     : '2',
                    calc       : 2,
                    translation: 'gut'
                },
                5 : {
                    number     : '2-',
                    calc       : 2,
                    translation: 'gut (-)'
                },
                6 : {
                    number     : '3+',
                    calc       : 3,
                    translation: 'befriedigend (+)'
                },
                7 : {
                    number     : '3',
                    calc       : 3,
                    translation: 'befriedigend'
                },
                8 : {
                    number     : '3-',
                    calc       : 3,
                    translation: 'befriedigend (-)'
                },
                9 : {
                    number     : '4+',
                    calc       : 4,
                    translation: 'ausreichend (+)'
                },
                10: {
                    number     : '4',
                    calc       : 4,
                    translation: 'ausreichend'
                },
                11: {
                    number     : '4-',
                    calc       : 4,
                    translation: 'ausreichend (-)'
                },
                12: {
                    number     : '5+',
                    calc       : 5,
                    translation: 'mangelhaft (+)'
                },
                13: {
                    number     : '5',
                    calc       : 5,
                    translation: 'mangelhaft'
                },
                14: {
                    number     : '5-',
                    calc       : 5,
                    translation: 'mangelhaft (-)'
                },
                15: {
                    number     : '6+',
                    calc       : 6,
                    translation: 'ungenügend (+)'
                },
                16: {
                    number     : '6',
                    calc       : 6,
                    translation: 'ungenügend'
                }
            },
            quarters     : {
                0 : {
                    number     : '1+',
                    calc       : 0.75,
                    translation: 'sehr gut (+)'
                },
                1 : {
                    number     : '1',
                    calc       : 1,
                    translation: 'sehr gut'
                },
                2 : {
                    number     : '1-',
                    calc       : 1.25,
                    translation: 'sehr gut (-)'
                },
                3 : {
                    number     : '1-2',
                    calc       : 1.5,
                    translation: 'sehr gut (1-2)'
                },
                4 : {
                    number     : '2+',
                    calc       : 1.75,
                    translation: 'gut (+)'
                },
                5 : {
                    number     : '2',
                    calc       : 2,
                    translation: 'gut'
                },
                6 : {
                    number     : '2-',
                    calc       : 2.25,
                    translation: 'gut (-)'
                },
                7 : {
                    number     : '2-3',
                    calc       : 2.5,
                    translation: 'gut (2-3)'
                },
                8 : {
                    number     : '3+',
                    calc       : 2.75,
                    translation: 'befriedigend (+)'
                },
                9 : {
                    number     : '3',
                    calc       : 3,
                    translation: 'befriedigend'
                },
                10: {
                    number     : '3-',
                    calc       : 3.25,
                    translation: 'befriedigend (-)'
                },
                11: {
                    number     : '3-4',
                    calc       : 3.5,
                    translation: 'befriedigend (3-4)'
                },
                12: {
                    number     : '4+',
                    calc       : 3.75,
                    translation: 'ausreichend (+)'
                },
                13: {
                    number     : '4',
                    calc       : 4,
                    translation: 'ausreichend'
                },
                14: {
                    number     : '4-',
                    calc       : 4.25,
                    translation: 'ausreichend (-)'
                },
                15: {
                    number     : '4-5',
                    calc       : 4.5,
                    translation: 'ausreichend (4-5)'
                },
                16: {
                    number     : '5+',
                    calc       : 4.75,
                    translation: 'mangelhaft (+)'
                },
                17: {
                    number     : '5',
                    calc       : 5,
                    translation: 'mangelhaft'
                },
                18: {
                    number     : '5-',
                    calc       : 5.25,
                    translation: 'mangelhaft (-)'
                },
                19: {
                    number     : '5-6',
                    calc       : 5.5,
                    translation: 'mangelhaft (5-6)'
                },
                20: {
                    number     : '6',
                    calc       : 6,
                    translation: 'ungenügend'
                }
            },
            hamburgEG    : {
                calc: {
                    average: 'byValue'
                },
                0   : {
                    number     : 'E1',
                    translation: 'E1'
                },
                1   : {
                    number     : 'E2',
                    translation: 'E2'
                },
                2   : {
                    number     : 'E3',
                    translation: 'E3'
                },
                3   : {
                    number     : 'E4/G1',
                    translation: 'E4/G1'
                },
                4   : {
                    number     : 'G2',
                    translation: 'G2'
                },
                5   : {
                    number     : 'G3',
                    translation: 'G3'
                },
                6   : {
                    number     : 'G4',
                    translation: 'G4'
                },
                7   : {
                    number     : 'G5',
                    translation: 'G5'
                },
                8   : {
                    number     : 'G6',
                    translation: 'G6'
                }
            },
            hamburgEGplus: {
                calc: {
                    average: 'byValue'
                },
                0   : {
                    number     : 'E1+',
                    translation: 'E1+'
                },
                1   : {
                    number     : 'E1',
                    translation: 'E1'
                },
                2   : {
                    number     : 'E1-',
                    translation: 'E1-'
                },
                3   : {
                    number     : 'E2+',
                    translation: 'E2+'
                },
                4   : {
                    number     : 'E2',
                    translation: 'E2'
                },
                5   : {
                    number     : 'E2-',
                    translation: 'E2-'
                },
                6   : {
                    number     : 'E3+',
                    translation: 'E3+'
                },
                7   : {
                    number     : 'E3',
                    translation: 'E3'
                },
                8   : {
                    number     : 'E3-',
                    translation: 'E3-'
                },
                9   : {
                    number     : 'E4+/G1+',
                    translation: 'E4+/G1+'
                },
                10  : {
                    number     : 'E4/G1',
                    translation: 'E4/G1'
                },
                11  : {
                    number     : 'E4-/G1-',
                    translation: 'E4-/G1-'
                },
                12  : {
                    number     : 'G2+',
                    translation: 'G2+'
                },
                13  : {
                    number     : 'G2',
                    translation: 'G2'
                },
                14  : {
                    number     : 'G2-',
                    translation: 'G2-'
                },
                15  : {
                    number     : 'G3+',
                    translation: 'G3+'
                },
                16  : {
                    number     : 'G3',
                    translation: 'G3'
                },
                17  : {
                    number     : 'G3-',
                    translation: 'G3-'
                },
                18  : {
                    number     : 'G4+',
                    translation: 'G4+'
                },
                19  : {
                    number     : 'G4',
                    translation: 'G4'
                },
                20  : {
                    number     : 'G4-',
                    translation: 'G4-'
                },
                21  : {
                    number     : 'G5+',
                    translation: 'G5+'
                },
                22  : {
                    number     : 'G5',
                    translation: 'G5'
                },
                23  : {
                    number     : 'G5-',
                    translation: 'G5-'
                },
                24  : {
                    number     : 'G6+',
                    translation: 'G6+'
                },
                25  : {
                    number     : 'G6',
                    translation: 'G6'
                }
            },
            points       : {
                15: {
                    number     : '15',
                    translation: 'sehr gut (+)'
                },
                14: {
                    number     : '14',
                    translation: 'sehr gut'
                },
                13: {
                    number     : '13',
                    translation: 'sehr gut (-)'
                },
                12: {
                    number     : '12',
                    translation: 'gut (+)'
                },
                11: {
                    number     : '11',
                    translation: 'gut'
                },
                10: {
                    number     : '10',
                    translation: 'gut (-)'
                },
                9 : {
                    number     : '9',
                    translation: 'befriedigend (+)'
                },
                8 : {
                    number     : '8',
                    translation: 'befriedigend'
                },
                7 : {
                    number     : '7',
                    translation: 'befriedigend (-)'
                },
                6 : {
                    number     : '6',
                    translation: 'ausreichend (+)'
                },
                5 : {
                    number     : '5',
                    translation: 'ausreichend'
                },
                4 : {
                    number     : '4',
                    translation: 'ausreichend (-)'
                },
                3 : {
                    number     : '3',
                    translation: 'mangelhaft (+)'
                },
                2 : {
                    number     : '2',
                    translation: 'mangelhaft'
                },
                1 : {
                    number     : '1',
                    translation: 'mangelhaft (-)'
                },
                0 : {
                    number     : '0',
                    translation: 'ungenügend'
                }
            }
        }

    }

    initializeScoreModelValidationDirections()
    {

        return {
            basic        : 'ascending',
            bremen       : 'ascending',
            austria      : 'ascending',
            extended     : 'ascending',
            quarters     : 'ascending',
            hamburgEG    : 'ascending',
            hamburgEGplus: 'ascending',
            points       : 'descending'
        }

    }

    fillTranslations()
    {

        let translations = {
            'scoreModels'                  : this.initializeScoreModels(),
            'scoreModelValidationDirection': this.initializeScoreModelValidationDirections()
        }

        for( let i = 0; i < 1000; i++ )
        {
            translations[ 'count_' + i ] = this.numberWord( i.toString() ).trim()
        }

        return translations

    }

    translateObjectType( key )
    {

        switch( key )
        {
            case 'class':
                return 'classes'
            case 'note':
                return 'notes'
            case 'student':
                return 'students'
            case 'list':
                return 'lists'
            case 'avatar':
                return 'avatars'
            case 'date':
                return 'dates'
            case 'todo':
                return 'todos'
            case 'group':
                return 'groups'
            case 'yeargroup':
                return 'yeargroups'
            default:
                return 'MISSING-OT-' + key
        }

    }

}