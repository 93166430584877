<template>
    <transition appear name="slide-fade">
        <div id="license-warning" style="cursor:pointer;" @click="$router.push( {
                        name  : 'settings',
                        params: { tab: 'profile' }
                    })" v-if="true === showWarning">
            <strong>Ist deine E-Mail-Adresse korrekt?</strong> Unsere E-Mails kommen aktuell nicht bei dir an.<br/>
            Überprüfe doch bitte, ob deine Adresse (noch) aktuell ist.
        </div>
    </transition>
</template>

<script>

export default {
    name  : 'BounceWarning',

    data()
    {
        return {
            showWarning: false
        }
    },
    
    created()
    {
        setTimeout( () =>
        {
            this.getBounces()
        }, 5000 )
    },
    
    methods: {
        
        getBounces()
        {
            
            if( this.$store.getters.authorized
                && true === this.$store.getters.online
                && null !== this.$store.getters.metadata )
            {
                this.$core.getCryptoHelper().decrypt( this.$store.getters.metadata )
                    .then( profile => {

                        if( this.$core.f().valid( profile )
                            && this.$core.f().valid( profile.email ) )
                        {

                            this.$core.getClient()
                                .request( {

                                    method: 'users.getBounces',
                                    email : this.$core.getCryptoHelper().encryptForServer( profile.email )

                                } )
                                .then( response =>
                                {

                                    if( 0 < response.result )
                                    {
                                        this.showWarning = true
                                    }
                                    else
                                    {
                                        this.showWarning = false
                                    }

                                } )

                        }

                    })

            }
            else
            {
                setTimeout( () =>
                {
                    this.getBounces()
                }, 10000 )
            }
            
        }
        
    }
}
</script>