<template>
    <PlannerDay v-if="showPlannerDay"
                :date="showDate"
                :changeKey="changeKey"
                @navigate="handleChangePlannerDay"
                @close="handleClosePlannerDay()"
                @refresh="handleRefresh"/>
    <div class="planner-wrapper" v-if="registryReady">
        <div class="planner-header">
            <div class="planner-button">
                <Button type="navigate last larger inverted" @dragover="dragChange( dragging, 'last' )"
                        @clicked="changeDate( 'last' )"/>
            </div>
            <div class="planner-title" v-html="title"></div>
            <div class="planner-button">
                <Button type="navigate next larger inverted" @dragover="dragChange( dragging, 'next' )"
                        @clicked="changeDate( 'next' )"/>
            </div>
        </div>
        <div :class="'planner-view planner-mode-'+this.mode"
             v-if="visibleStart !== undefined && visibleEnd !== undefined">
            <div v-for="n in 6"
                 :key="'day-title-'+n"
                 :class="'planner-day-title'+( n == 6 ? ' weekend' : '' )+' day-'+n">
                {{ $core.getTranslation().translate( 'day-title-' + n ) }}
                <template v-if="'week' === this.mode">
                    <span class="day-bubble">{{ dates[ ( n - 1 ) ].day }}</span>
                    <template v-if="6 === n">
                        <span class="day-bubble">{{ dates[ ( n ) ].day }}</span>
                    </template>
                </template>
            </div>
            <!--<transition-group appear :name="$core.settings().getTransition( 'listmove' )">-->
            <template v-for="( date, index ) in dates"
                      :key="'tpl-'+date.timestamp">
                <div :class="'planner-day' + ' day-box-'+ dayIndex( index ) + ' ' +
                         ( dragging ? ' dragging' : '' ) +
                         date.twoRowVis +
                         ( date.dateRange.from === targetDay ? ' selected' : '' ) +
                         ( isWeekend( date ) ? ' weekend' : '' ) +
                         ( isFiller( date ) ? fillerType( date ) : '' ) +
                         ( isToday( date ) ? ' today' : '' )"
                     :style="( visibleStart <= date.timestamp && visibleEnd >= date.timestamp ) ? 'display: block;' : 'display: none!important; --index: '+index"
                     @click="handleTargetDayClick( date.dateRange.from )">
                    <div :class="'planner-day-inner-title' +
                     ' day-num-'+date.dateObject.getDay()+
                     ( isWeekend( date ) ? ' weekend' : '' ) +
                     ( isFiller( date ) ? fillerType( date ) : '' ) +
                     ( isToday( date ) ? ' today' : '' )">
                        {{ $core.getTranslation().translate( 'day-num-' + date.dateObject.getDay() ) }}
                    </div>
                    <div :class="'planner-day-index' +
                     ( isWeekend( date ) ? ' weekend' : '' ) +
                     ( isFiller( date ) ? fillerType( date ) : '' ) +
                     ( isToday( date )? ' today' : '' )">{{ date.day }}
                    </div>
                    <div class="planner-day-element-wrapper">
                        <PlannerElements :timestamp="date.dateRange.from"
                                         :elements="date.elements"
                                         :changeKey="changeKey"
                                         :selectMode="selectMode"
                                         :filterList="filterList"
                                         @dragging="dragging = $event"
                                         @refresh="handleRefresh"
                                         @clicked="$emit( 'showForm', date.dateRange.from )"
                                         @showPlannerDay="handleShowPlannerDay( date )"/>
                    </div>
                </div>
                <!--<div :key="'day-'+index" class="planner-day" v-else>{{ date.dateObject.getTime() }} {{ visibleStart }} {{ visibleEnd }}</div>-->
            </template>
            <!--</transition-group>-->
        </div>

        <div class="clearfix"></div>

        <div class="planner-footer">
            <div class="planner-button">
                <Button type="navigate last larger inverted" @dragover="dragChange( dragging, 'last' )"
                        @clicked="changeDate( 'last' )"/>
            </div>
            <div class="planner-title" v-html="title"></div>
            <div class="planner-button">
                <Button type="navigate next larger inverted" @dragover="dragChange( dragging, 'next' )"
                        @clicked="changeDate( 'next' )"/>
            </div>
        </div>
    </div>
    <PreheatingIndicator v-else/>
</template>

<script>
import PlannerViewFunctions from '@/components/elements/viewItems/plannerView/abstract/PlannerViewFunctions'
import MixinDraggable       from '@/mixins/MixinDraggable'
import PreheatingIndicator  from "@/components/elements/indicators/PreheatingIndicator";

export default {

    name      : 'PlannerViewDefault',
    components: { PreheatingIndicator },
    extends   : PlannerViewFunctions,
    mixins    : [ MixinDraggable ],
    emits     : [ 'refresh', 'showForm', 'add' ],

    props: {
        filterList    : { type: Object, required: false, default: undefined },
        selectedMode  : { type: String, required: false, default: 'month' },
        selectMode    : { type: Boolean, required: false, default: false },
        selectionState: { type: Boolean, required: false, default: false },
        reference     : { type: Object, required: false, default: undefined }
    },

    data()
    {
        return {
            plannerMode   : 'default',
            mode          : 'week',
            today         : false,
            date          : false,
            month         : false,
            year          : false,
            changeKey     : false,
            registryReady : false,
            birthdays     : [],
            dates         : [],
            organizerDates: [],
            holidays      : [],
            schoolHolidays: [],
            visibleStart  : false,
            visibleEnd    : false,
            realStart     : false,
            realEnd       : false,
            prepared      : false,
            showPlannerDay: false,
            showDate      : false,
            mounted       : false,
            rangeFrom     : false,
            rangeUntil    : false,
            filterId      : false,
            filterBy      : false,
            targetDay     : false,
            references    : {},
            dragging      : false
        }
    },

    methods: {
        inRange( timestamp )
        {

            if( timestamp < this.visibleStart
                || timestamp > this.visibleEnd )
            {
                return false
            }

            return true

        }
    }

}
</script>