<template>
    <div class="list-element">
        <template v-if="value === 2">
            <span class="twt yes"><strong>J</strong></span>
        </template>
        <template v-else-if="value === 1">
            <span class="twt no"><strong>N</strong></span>
        </template>
        <template v-else>
            <span class="twt nv"><strong>-</strong></span>
        </template>
    </div>
</template>

<script>
export default {
    name : 'listElement-threewaytoggle',
    props: {
        value   : { type: Number, required: false, default: undefined }
    }
}
</script>
