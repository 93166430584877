<template>
    <header id="header">
        <div class="status-header">
            <LoginStatus/>
            <Tick/>
            <Reloader v-if="$core.f().isFullscreen()"/>
            <ActiveLayout v-if="true === $core.settings().map.get( 'debugInfo' )"/>
            <OnlineStatus @click="$store.getters.authorized === true ? infoscreenVisible = true : $core.f().skip"/>
            <InfoScreen v-if="infoscreenVisible" @close="infoscreenVisible = false"/>
        </div>
        <!--<Bubbles />-->
    </header>
</template>

<script>
/*eslint-disable*/
import LoginStatus  from '@/components/layout/LoginStatus'
import OnlineStatus from '@/components/layout/OnlineStatus'
import Tick         from '@/components/layout/Tick'
import InfoScreen   from '@/components/layout/online-status/InfoScreen'
import Reloader     from '@/components/layout/Reloader'
import Bubbles      from '@/components/layout/Bubbles'
import ActiveLayout from "@/components/layout/ActiveLayout";

export default {
    name      : 'StatusHeader',
    components: { ActiveLayout, Bubbles, Reloader, InfoScreen, Tick, OnlineStatus, LoginStatus },

    data()
    {
        return {
            infoscreenVisible: false,
            forceOffline     : false
        }
    },

    mounted()
    {

        this.$core.getEventManager().append( 'on-core-state-change-criticalErrors', ( error =>
        {
            if( undefined !== error.reason
                && undefined !== error.reason.stack )
            {
                if( -1 === error.reason.stack.indexOf( 'isTrusted' )
                    && -1 === error.reason.stack.indexOf( 'audioPlayback' ) )
                {
                    this.$core.getUi()
                        .setPageMessage( 'Kritischer Fehler: ' + ( undefined !== error.reason ? error.reason.name : '' ),
                            '<strong>Sorry, aber leider ist ein kritischer Fehler aufgetreten</strong>:<br/>' + ( error.reason ? '<br/><pre>' + error.reason.stack + '</pre>' : '' ) + '<br/><br/>' +
                            'Möglicherweise funktioniert entzettelt jetzt nicht mehr richtig: Lad die Seite am besten bei nächster Gelegenheit neu.<br/><strong>Sollte der Fehler weiterhin bestehen, schreib uns bitte eine E-Mail an <a href="mailto:kontakt@entzettelt.de">kontakt@entzettelt.de</a></strong>',
                            'error', true )
                }
            }

        } ) )

    },

    methods: {
        toggle()
        {
            this.forceOffline = !this.forceOffline
            window.forceOffline = this.forceOffline
        }
    }
}
</script>