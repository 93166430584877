/* eslint-disable */
import { stringify, parse }                       from 'zipson'
import { compressToBase64, decompressFromBase64 } from 'lz-string'
import pdf                                        from 'pdfvuer'
import AudioRecorder                              from "@/classes/Helpers/Media/AudioRecorder";

export default class Media
{

    constructor( core )
    {

        if( !Media.instance )
        {

            this.logger = core.getLogger()
            this.f = core.f()
            this.ui = core.getUi()
            this.s = core.s()
            this.settings = core.settings()
            this.store = core.getStore()

            this.uuid = core.getUuid()

            this.client = core.getClient()
            this.cryptoHelper = core.getCryptoHelper()
            this.friendlyTimestamp = core.getFriendlyTimestamp()
            this.eventManager = core.getEventManager()
            this.queueWorker = core.getQueueWorker()
            this.baseClassHelper = core.getBaseClassHelper()
            this.sanitizers = core.getSanitizers()

            this.longSizePixels = 1920
            this.defaultQuality = 0.2

            this.audioRecorder = new AudioRecorder( core )
            Media.instance = this

            setTimeout( () =>
            {
                this.eventManager.dispatchAndRemove( 'on-mediahelper-available' )
            }, 500 )

            this.logger.cconstructed( 'Core::Media', 'media helper initialized' )

        }

        return Media.instance

    }

    getAudioRecorder()
    {
        return this.audioRecorder
    }

    _toGray( imageData )
    {

        for( let i = 0; i < imageData.data.length; i += 4 )
        {
            let count = imageData.data[ i ] + imageData.data[ i + 1 ] + imageData.data[ i + 2 ]
            let colour = 0;
            if( count > 510 ) colour = 255;
            else if( count > 255 ) colour = 127.5;

            imageData.data[ i ] = colour
            imageData.data[ i + 1 ] = colour
            imageData.data[ i + 2 ] = colour
            imageData.data[ i + 3 ] = 255
        }

        return imageData

    }

    compress( file, asGrayscale )
    {

        return new Promise( ( resolve, reject ) =>
        {

            let fileSize = ( file.size / 1024 / 1024 ).toFixed( 2 ),
                img      = new Image()

            this.logger.clog( 'Core::Images', 'compressing ' + file.name + ', total size ' + fileSize + 'MB' )

            img.src = URL.createObjectURL( file )
            img.onload = () =>
            {

                let canvas = document.createElement( 'canvas' )
                let width  = img.width,
                    height = img.height,
                    factor = width / height

                if( width > height )
                {
                    width = width > this.longSizePixels ? this.longSizePixels : width
                    height = width / factor
                }
                else
                {
                    height = height > this.longSizePixels ? this.longSizePixels : height
                    width = height * factor
                }

                canvas.width = width
                canvas.height = height
                let ctx = canvas.getContext( '2d' )
                ctx.drawImage( img, 0, 0, width, height )

                if( asGrayscale )
                {
                    let imageData = ctx.getImageData( 0, 0, width, height )
                    let grayscale = this._toGray( imageData )
                    ctx.putImageData( grayscale, 0, 0 )
                }

                let dataUrl   = canvas.toDataURL( 'image/jpeg', this.defaultQuality ),
                    container = {
                        dataUrl   : dataUrl,
                        fileName  : file.name,
                        size      : dataUrl.length,
                        format    : 'image/jpeg',
                        dimensions: {
                            width : width,
                            height: height
                        }
                    }

                let stringified    = JSON.stringify( container ),
                    zipped         = compressToBase64( stringified ),
                    compressedSize = ( zipped.length / 1024 ).toFixed( 2 )

                this.logger.clog( 'Core::Images', 'compressed image size:', zipped.length, compressedSize + 'KB' )
                return resolve( zipped )

            }

        } )

    }

    decompress( zipped )
    {

        return JSON.parse( decompressFromBase64( zipped ) )

    }

    mirrorImage( dataUrl, width, height, horizontal, vertical )
    {

        return new Promise( resolve =>
        {

            let img    = new Image(),
                canvas = document.createElement( 'canvas' )

            canvas.width = width
            canvas.height = height

            let context = canvas.getContext( '2d' )

            img.onload = () =>
            {

                context.save()

                context.setTransform(
                    ( horizontal ? -1 : 1 ),
                    0, 0,
                    ( vertical ? -1 : 1 ),
                    ( horizontal ? img.width : 0 ),
                    ( vertical ? img.height : 0 )
                )

                context.drawImage( img, 0, 0 )
                context.restore()

                return resolve( canvas.toDataURL( 'image/jpeg' ) )

            }

            img.src = dataUrl

        } )

    }

    handlePdf( file )
    {

        return new Promise( ( resolve, reject ) =>
        {

            let fileReader = new FileReader()
            fileReader.onload = ( event ) =>
            {

                let pdfLoader = pdf.createLoadingTask( fileReader.result )
                pdfLoader.then( pdfDocument =>
                {

                    pdfDocument.getPage( 1 )
                               .then( page =>
                               {

                                   let scale    = 2,
                                       viewport = page.getViewport( scale ),
                                       viewbox  = viewport.viewBox

                                   if( 0 < viewbox.length )
                                   {

                                       let width  = Math.floor( viewbox[ 2 ] - viewbox[ 0 ] ),
                                           height = Math.floor( viewbox[ 3 ] - viewbox[ 1 ] )

                                       let canvas = document.createElement( 'canvas' )
                                       canvas.width = width
                                       canvas.height = height

                                       let context    = canvas.getContext( '2d' ),
                                           renderTask = page.render( { canvasContext: context, viewport: viewport } )

                                       renderTask.promise.then( () =>
                                       {

                                           let dataUrl = canvas.toDataURL( 'image/jpeg' )

                                           this.mirrorImage( dataUrl, width, height, false, true )
                                               .then( previewImage =>
                                               {

                                                   return resolve( {
                                                       previewImage: previewImage,
                                                       document    : fileReader.result
                                                   } )

                                               } )

                                       } )

                                   }

                               } )

                } )

            }

            fileReader.readAsDataURL( file )

        } )

    }

    dataUriToFile( dataURI )
    {

        let byteString = atob( dataURI.split( ',' )[ 1 ] ),
            mimeString = dataURI.split( ',' )[ 0 ].split( ':' )[ 1 ].split( ';' )[ 0 ],
            ab = new ArrayBuffer( byteString.length ),
            ia = new Uint8Array( ab )

        for( let i = 0; i < byteString.length; i++ )
        {
            ia[ i ] = byteString.charCodeAt( i );
        }

        let blob = new Blob( [ ab ], { type: mimeString } ),
        file = new File( [blob], 'image', { type: mimeString, lastModified: Date.now() } )
        return file

    }

    storeMedia( inputId, raw )
    {

        return new Promise( ( resolve, reject ) =>
        {

            let file = document.querySelector( '#' + inputId ).files[ 0 ]

            if( raw || ( undefined !== file
                         && null !== file ) )
            {

                let temp, type, format
                if( undefined !== raw )
                {

                    let temp  = raw.split( /,/g ),
                        data  = temp[ 0 ].split( /;/g ),
                        temp2 = data[ 0 ].split( /:/g ),
                        temp3 = temp2[ 1 ].split( /\//g )

                    type = temp3[ 0 ]
                    format = temp3[ 1 ]

                    let fileElement = document.querySelector( '#'+inputId ),
                        dataTransfer = new DataTransfer()

                    file = this.dataUriToFile( raw )

                    dataTransfer.items.add( file )
                    fileElement.files = dataTransfer.files

                }
                else
                {
                    temp = file.type.split( /\//g )
                    type = temp[ 0 ]
                    format = temp[ 1 ]
                }

                switch( type )
                {
                    case 'audio':
                        switch( format )
                        {
                            case 'mp3':
                                let size      = raw.length,
                                    container = {
                                        dataUrl : raw,
                                        fileName: 'recording-' + Date.now() + '.mp3',
                                        size    : size,
                                        format  : 'audio/mp3',
                                    },
                                    plain     = compressToBase64( JSON.stringify( container ) )

                                this.cryptoHelper
                                    .encrypt( JSON.stringify( container ) )
                                    .then( cryptedContainer =>
                                    {

                                        this.client.request( {

                                                method  : 'media.storeMedia',
                                                mimeType: container.format,
                                                contents: cryptedContainer.object,
                                                keys    : cryptedContainer.keys

                                            } )
                                            .then( response =>
                                            {

                                                return resolve( {
                                                    response  : response,
                                                    plain     : raw,
                                                    label     : 'Aufzeichnung vom ' + this.friendlyTimestamp.formattedDateTime(),
                                                    compressed: plain
                                                } )

                                            } )


                                    } )

                                break
                        }
                        break
                    case 'application':
                        switch( format )
                        {
                            case 'pdf':
                                if( file.size < ( 1024 * 1024 * 10 ) )
                                {
                                    this.handlePdf( file )
                                        .then( pdfFile =>
                                        {

                                            let size      = pdfFile.previewImage.length + file.size,
                                                container = {
                                                    dataUrl : pdfFile.previewImage,
                                                    fileName: file.name,
                                                    size    : size,
                                                    format  : 'application/pdf',
                                                    document: pdfFile.document
                                                },
                                                plain     = compressToBase64( JSON.stringify( container ) )

                                            this.cryptoHelper
                                                .encrypt( JSON.stringify( container ) )
                                                .then( cryptedContainer =>
                                                {

                                                    this.client.request( {

                                                            method      : 'media.storeMedia',
                                                            mimeType    : file.type,
                                                            previewImage: pdfFile.previewImage,
                                                            contents    : cryptedContainer.object,
                                                            keys        : cryptedContainer.keys

                                                        } )
                                                        .then( response =>
                                                        {

                                                            return resolve( {
                                                                response  : response,
                                                                plain     : file,
                                                                compressed: plain
                                                            } )

                                                        } )


                                                } )

                                        } )
                                }
                                else
                                {
                                    return reject( 'E_PDF_SIZE' )
                                }
                                break
                        }
                        break
                    case 'image':
                        this.compress( file, false )
                            .then( compressed =>
                            {

                                let mediaContainer = {
                                    compressed: compressed
                                }

                                this.cryptoHelper
                                    .encrypt( JSON.stringify( mediaContainer ) )
                                    .then( cryptedContainer =>
                                    {

                                        this.client.request( {

                                                method  : 'media.storeMedia',
                                                mimeType: file.type,
                                                contents: cryptedContainer.object,
                                                keys    : cryptedContainer.keys

                                            } )
                                            .then( response =>
                                            {

                                                return resolve( {
                                                    response  : response,
                                                    plain     : file,
                                                    compressed: compressed
                                                } )

                                            } )


                                    } )

                            } )
                        break
                    default:
                        this.logger.cerror( 'Core::MediaHelper::storeMedia', 'unknown format', file.type )
                        return reject( 'E_UNKNOWN_FORMAT' )
                        break
                }

            }
            else
            {
                return reject( 'E_INVALID' )
            }

        } )

    }

    getFileById( id )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.client.request( {
                    method: 'media.getFileById',
                    id    : id
                } )
                .then( response =>
                {

                    let fileObject = {
                        keys  : [ {
                            uuid  : this.store.getters.uuid,
                            secret: response.key
                        } ],
                        object: response.fileContents
                    }

                    this.cryptoHelper
                        .decryptElement( response.key, response.fileContents )
                        .then( fileContents =>
                        {

                            if( false !== fileContents )
                            {

                                try
                                {
                                    let document = fileContents
                                    if( undefined !== fileContents.compressed )
                                    {
                                        document = this.decompress( fileContents.compressed )
                                    }
                                    return resolve( document )
                                }
                                catch( e )
                                {
                                    return reject( e )
                                }

                            }

                        } )

                } )
                .catch( error =>
                {
                    return reject( error )
                } )

        } )

    }

    deleteFileById( id )
    {

        return new Promise( ( resolve ) =>
        {

            let message = {
                    method: 'media.dropFileById',
                    id    : id
                },
                jobId   = this.uuid.generate()

            this.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )
            return resolve()

        } )

    }

    linkFileIdToMediaId( fileId, mediaId )
    {

        return new Promise( ( resolve ) =>
        {

            let message = {
                    method : 'media.linkFileIdToMediaId',
                    id     : fileId,
                    mediaId: mediaId
                },
                jobId   = this.uuid.generate()

            this.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )
            return resolve()

        } )

    }

    findMediaInLists( mediaId )
    {

        return new Promise( resolve =>
        {

            let mediaLists = []

            this.baseClassHelper
                .get( 'list' )
                .listAll()
                .then( lists =>
                {

                    for( let l in lists )
                    {

                        let listElm = lists[ l ]

                        if( undefined !== listElm
                            && undefined !== listElm.lists )
                        {
                            for( let ll in listElm.lists )
                            {
                                let list = listElm.lists[ ll ]
                                for( let c in list.columns )
                                {
                                    if( list.columns[ c ].type === 'image' )
                                    {

                                        let valueKey = this.sanitizers.cleanId( list.columns[ c ].caption ) + '___' + c
                                        for( let v in list.values )
                                        {

                                            if( -1 < v.indexOf( valueKey ) )
                                            {

                                                if( list.values[ v ] === mediaId
                                                    && -1 === mediaLists.indexOf( list.localId ) )
                                                {
                                                    mediaLists.push( list.localId )
                                                }

                                            }

                                        }
                                    }
                                }
                            }
                        }

                    }

                    return resolve( mediaLists )

                } )

        } )

    }

    removeMediaFromLists( mediaId )
    {

        return new Promise( resolve =>
        {

            let mediaLists = []

            this.baseClassHelper
                .get( 'list' )
                .listAll()
                .then( lists =>
                {

                    for( let l in lists )
                    {

                        let listElm = lists[ l ]

                        if( undefined !== listElm
                            && undefined !== listElm.lists )
                        {
                            for( let ll in listElm.lists )
                            {

                                let changes = false

                                let list = listElm.lists[ ll ]
                                for( let c in list.columns )
                                {
                                    if( list.columns[ c ].type === 'image' )
                                    {

                                        let valueKey = this.sanitizers.cleanId( list.columns[ c ].caption ) + '___' + c
                                        for( let v in list.values )
                                        {

                                            if( -1 < v.indexOf( valueKey ) )
                                            {

                                                if( list.values[ v ] === mediaId
                                                    && -1 === mediaLists.indexOf( list.localId ) )
                                                {
                                                    delete list.values[ v ]
                                                }

                                            }

                                        }
                                    }
                                }

                                if( changes )
                                {
                                    this.baseClassHelper
                                        .get( 'list' )
                                        .update( list, list.localId, list.remoteId, list.timestamp, list.localKey, undefined, true, undefined )
                                }

                            }
                        }

                    }

                    return resolve( mediaLists )

                } )

        } )

    }

}

