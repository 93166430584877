export default class QueueDeletionWorker
{

    constructor( core )
    {

        if( !QueueDeletionWorker.instance )
        {

            this.interval = 3000

            this.logger = core.getLogger()
            this.store = core.getStore()
            this.database = core.getDatabase()
            this.timer = core.getCoreTimer()
            this.client = core.getClient()
            this.eventManager = core.getEventManager()
            this.flags = core.getFlags()

            this.timer.addInterval( 'queueworker-deletions', this.interval, () =>
            {
                this.trigger()
            }, true )

            QueueDeletionWorker.instance = this

        }

        return QueueDeletionWorker.instance

    }

    destruct()
    {
        this.timer.removeInterval( 'queueworker-deletions' )
        delete QueueDeletionWorker.instance
    }

    unqueue( id )
    {
        this.database.delete( 'deletions', id )
            .then( () =>
            {
                this.eventManager.dispatch( 'after-deletion-done', id )
                this.logger.clog( 'QueueDeletionWorker:trigger', 'deletion done.' )
            } )
    }

    unqueueList( list )
    {
        this.database.deleteList( 'deletions', list )
            .then( () =>
            {
                this.logger.clog( 'QueueDeletionWorker:trigger', 'deletion done.' )
            } )
    }

    trigger()
    {

        if( !this.flags.ready || this.flags.is( 'demouser' ) )
        {
            return
        }

        if( true === this.store.getters.authorized
            && true === this.store.getters.online )
        {

            this.database.readAllObjects( 'deletions' )
                .then( entries =>
                {

                    let message = null

                    if( 1 < entries.length )
                    {
                        this.logger.clog( 'QueueDeletionWorker:trigger', 'working ' + entries.length + ' deletions(s) in queue' )
                        let list = []
                        while( 0 < entries.length )
                        {
                            let deletion = entries.shift()
                            list.push( deletion.key )
                            if( 50 === list.length )
                            {
                                break
                            }
                        }
                        message = {
                            method: 'objects.deleteObjectListByLocalId',
                            list  : list
                        }
                    }
                    else
                    {
                        if( 0 < entries.length )
                        {
                            this.logger.clog( 'QueueDeletionWorker:trigger', 'working single deletion in queue' )
                            let deletion = entries.shift()
                            message  = {
                                method  : 'objects.deleteObjectByLocalId',
                                id_local: deletion.key,
                            }
                        }
                    }

                    if( null !== message )
                    {
                        this.client.request( message )
                            .then( () =>
                            {
                            } )
                            .catch( error =>
                            {
                                switch( error )
                                {
                                    case 'DELETE_OBJECT_BY_LOCALID_ERROR_1001':
                                    case 'DELETE_OBJECT_BY_LOCALID_ERROR_1002':
                                    case 'DELETE_OBJECT_BY_LOCALID_ERROR_1003':
                                        break
                                    default:
                                        this.logger.cerror( 'QueueDeletionWorker:trigger', 'exception caught while deletion: ' + error )
                                        break
                                }
                            } )
                            .finally( () =>
                            {
                                if( undefined !== message.list )
                                {
                                    this.unqueueList( message.list )
                                }
                                else
                                {
                                    this.unqueue( message.id_local )
                                }
                            } )
                    }

                } )

        }
    }

}