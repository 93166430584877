<template>
    <div class="quickmark demo-content-indicator">
        demo
    </div>
</template>

<script>
export default {
    name: "DemoContentIndicator"
}
</script>