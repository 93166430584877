<template>
    <div :class="'classes-filter-2'+( '' !== filterValue  && false !== filterValue ? ' selection' : '')" id="classes-filter">
        <div :class="'select-arrow'+( '' !== filterValue  && false !== filterValue ? ' selection' : '')" id="classes-filter-inner" v-if="initialized">
            <div :class="'icon'+( '' !== filterValue  && false !== filterValue ? ' selection' : '')"></div>
            <select name="class-filter" id="classes-filter-select" @change="filter">
                <option value="">{{ title || 'Klassen & Gruppen' }}</option>
                <optgroup label="Klassen">
                    <option v-for="classItem in classes"
                            v-bind:key="'key_option_'+classItem.localId"
                            :selected="filterValue === classItem.localId"
                            :value="classItem.localId">{{ classItem.className || classItem.classname }}
                    </option>
                </optgroup>
                <optgroup label="Gruppen">
                    <option v-for="groupItem in groups"
                            v-bind:key="'key_option_'+groupItem.localId"
                            :selected="filterValue === groupItem.localId"
                            :value="groupItem.localId">{{ groupItem.groupName || classItem.groupname }}
                    </option>
                </optgroup>
                <optgroup label="Jahrgänge" v-if="$core.getLicense().isAllowed( 'page_yeargroups' )">
                    <option v-for="groupItem in yeargroups"
                            v-bind:key="'key_option_'+groupItem.localId"
                            :selected="filterValue === groupItem.localId"
                            :value="groupItem.localId">{{ groupItem.yeargroupname || classItem.yeargroupname }}
                    </option>
                </optgroup>
                <option v-if="'students' === $route.name" value="Z_FALSE">ohne Klassenzuordnung</option>
            </select>
        </div>
        <div class="classes-filter-select syncing" v-else>
            ...
        </div>
    </div>
</template>

<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";
import MixinFilterState    from "@/mixins/MixinFilterState";

export default {

    name  : 'ClassesFilter',
    mixins: [ MixinCachePreheater, MixinFilterState ],

    props: {
        title   : { type: String, required: false },
        archived: { type: Boolean, required: false },
        own     : { type: Boolean, required: false },
        list    : { type: Object, required: false }
    },

    emits: [ 'filter', 'detach', 'attach' ],

    data()
    {
        return {
            classes     : [],
            groups      : [],
            yeargroups  : [],
            filters     : [],
            filterValue : false,
            initialized : false,
            neededCaches: [ 'class', 'group', 'yeargroup' ]
        }
    },

    watch: {
        archived: {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && undefined === this.$props.list )
                {
                    this.classes = []
                    this.groups = []
                    this.yeargroups = []
                    this.filters = []
                    this.initialize()
                    this.emitValue( '' )
                }
            },
            deep: true
        },
        own     : {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && undefined === this.$props.list )
                {
                    this.classes = []
                    this.groups = []
                    this.yeargroups = []
                    this.filters = []
                    this.initialize()
                    this.emitValue( '' )
                }
            }
        }
    },

    created()
    {

        this.$nextTick()
            .then( () => {

                this.$core.getEventManager().add( 'on-classfilter-preselect', ( value ) =>
                {
                    this.emitValue( value )
                } )
                if( undefined === this.$props.list )
                {
                    this.initialize()
                }
                else
                {
                    this.classes = this.$props.list.classes || []
                    this.groups = this.$props.list.groups || []
                    this.yeargroups = this.$props.list.yeargroups || []
                    this.initialized = true
                }

            })

    },

    beforeUnmount()
    {
        this.$core.getEventManager().remove( 'on-classfilter-preselect' )
        this.$emit( 'detach' )
    },

    methods: {

        filterByOwner( element, own )
        {
            let isOwn = this.$core.f().isOwn( element )
            return own ? isOwn : true
        },

        initialize()
        {

            this.initialized = false

            let isArchived = this.$props.archived === true,
                own        = this.$props.own === undefined ? true : this.$props.own

            this.awaitNeededCaches()
                .then( () =>
                {

                    let classes    = this.$core
                                         .getBaseClassHelper()
                                         .get( 'class' )
                                         .readCache(),
                        groups     = this.$core
                                         .getBaseClassHelper()
                                         .get( 'group' )
                                         .readCache(),
                        yeargroups = this.$core
                                         .getBaseClassHelper()
                                         .get( 'yeargroup' )
                                         .readCache()

                    classes = this.$core.getSorter().sortObjects( classes, 'classname' )

                    for( let c in classes )
                    {
                        if( isArchived === classes[ c ].archived
                            && this.filterByOwner( classes[ c ], own ) )
                        {
                            this.classes.push( {
                                localId  : classes[ c ].localId,
                                className: classes[ c ].classname + ( isArchived ? ' ' + this.$core.getReformatter().timeForArchiveKey( classes[ c ] ) : '' )
                            } )
                        }
                    }

                    groups = this.$core.getSorter().sortObjects( groups, 'groupname' )

                    for( let g in groups )
                    {
                        if( isArchived === groups[ g ].archived
                            && this.filterByOwner( groups[ g ], own ) )
                        {
                            this.groups.push( {
                                localId  : groups[ g ].localId,
                                groupName: groups[ g ].groupname
                            } )
                        }
                    }

                    yeargroups = this.$core.getSorter().sortObjects( yeargroups, 'yeargroupname' )
                    for( let g in yeargroups )
                    {
                        if( isArchived === yeargroups[ g ].archived
                            && this.filterByOwner( yeargroups[ g ], own ) )
                        {
                            this.yeargroups.push( {
                                localId      : yeargroups[ g ].localId,
                                yeargroupname: yeargroups[ g ].yeargroupname
                            } )
                        }
                    }

                    this.$emit( 'attach', {
                        type  : 'class',
                        filter: this.filters
                    } )

                    this.initialized = true
                    this.restoreFilters()

                } )

        },

        filter( event )
        {

            this.filterValue = event.target.value
            this.emitValue()
            this.storeFilterState( this.filterValue )

        },

        emitValue()
        {

            let value = this.filterValue

            if( false === value || value === '' )
            {
                this.$emit( 'filter', 'classes', undefined )
                return
            }
            if( value === 'Z_FALSE' )
            {
                this.$emit( 'filter', 'classes', [ { 'attr': 'classname', value: 'Z_FALSE' } ] )
                return
            }

            for( let c in this.classes )
            {
                if( this.classes[ c ].localId === value )
                {
                    this.$emit( 'filter', 'classes', [ { 'attr': 'classId', value: this.classes[ c ].localId } ] )
                }
            }

            for( let g in this.groups )
            {
                if( this.groups[ g ].localId === value )
                {
                    this.$emit( 'filter', 'classes', [ { 'attr': 'groupId', value: this.groups[ g ].localId } ] )
                }
            }

            for( let g in this.yeargroups )
            {
                if( this.yeargroups[ g ].localId === value )
                {
                    this.$emit( 'filter', 'classes', [ {
                        'attr': 'yeargroupId',
                        value : this.yeargroups[ g ].localId
                    } ] )
                }
            }
        },


        resetFilters()
        {
            this.filterValue = false
            this.emitValue()
        },

        restoreFilters()
        {

            let filterValue = this.getStoredFilterState()

            if( ( Array.isArray( filterValue ) && 0 === filterValue.length )
                || false === filterValue )
            {
                return
            }

            this.filterValue = filterValue
            this.emitValue()
        }

    }
}
</script>