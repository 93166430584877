<template>
    <transition appear name="fade">
        <div class="fullscreen-overlay z-99000" id="fullscreen-overlay">
            <div class="overlay-box-wrapper center-y">
                <div class="headline">
                    <strong>Schülerinnen & Schüler auswählen</strong>
                    <Button type="close" @clicked="handleClose()"/>
                </div>
                <div class="body">
                    <div class="flexed">
                        <div v-if="undefined === onlyUnassigned" class="filter-functions">
                            <div :class="'openable-content'+( open ? ' open' : '' )" id="toggle-filters">
                                <Opener :inline="true"
                                        id="filters"
                                        caption="Filter"
                                        classname="toggleable"
                                        :state="openFilters"
                                        @clicked="openFilters = !openFilters"/>
                                <div v-if="openFilters" class="open-content relative">
                                    <div class="form-row">
                                        <div class="form-caption"><strong>Klasse</strong>, <strong>Gruppe</strong>,...</div>
                                        <div class="form-component">
                                            <StudentGroupSelector refName="filter-classId"
                                                                  id="filter-classId"
                                                                  :allowForeign="true"
                                                                  @change="handleFilter"/>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="form-row"
                                         v-if="'colors' === $core.settings().getSetting( 'studentColorModel' )">
                                        <div class="form-caption"><strong>Farbe</strong></div>
                                        <div class="form-component">
                                            <ColorSelector refName="filter-color"
                                                           id="filter-color"
                                                           @change="handleFilter"/>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-caption"><strong>Geschlecht</strong></div>
                                        <div class="form-component">
                                            <GenderSelector refName="filter-gender"
                                                            id="filter-gender"
                                                            @change="handleFilter"/>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-caption"><strong>Konfession</strong></div>
                                        <div class="form-component">
                                            <ConfessionSelector refName="filter-confession"
                                                                id="filter-confession"
                                                                @change="handleFilter"/>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-caption">
                                            <button class="delete" @click="selectFiltered( false )">Auswahl aufheben
                                            </button>
                                        </div>
                                        <div class="form-component">
                                            <button class="submit" @click="selectFiltered( true )">Alle auswählen
                                            </button>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="filter-elements">
                            <Touchable v-for="student in filtered"
                                       viewItem="Student"
                                       scope="own"
                                       :selectMode="true"
                                       :isSelected="( -1 < selected.indexOf( student.localId ) )"
                                       :key="'student-selector-'+student.localId"
                                       @select="handleSelect"
                                       :item="student"/>
                        </div>
                    </div>
                </div>
                <transition appear name="fade">
                    <div v-if="selected.length > 0" class="filter-counters">
                        <button class="submit" @click="handleSubmit()">ausgewählte Schüler:innen übernehmen</button>
                    </div>
                </transition>
                <div class="filter-counters">
                    <strong>{{ allStudents.length }}</strong> Schüler:innen insgesamt
                    <span v-if="filtered.length !== allStudents.length">, <strong>{{ filtered.length }}</strong> gefiltert</span>
                    <span v-if="selected.length > 0">, <strong>{{ selected.length }}</strong> ausgewählt</span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Button               from '@/components/elements/defaults/Button'
import Opener               from '@/components/elements/defaults/Opener'
import GenderSelector       from '@/components/form/elements/GenderSelector'
import ConfessionSelector   from '@/components/form/elements/ConfessionSelector'
import ColorSelector        from '@/components/form/elements/ColorSelector'
import Touchable            from "@/components/defaults/Touchable";
import MixinCachePreheater  from "@/mixins/MixinCachePreheater";
import StudentGroupSelector from "@/components/form/elements/StudentGroupSelector";

export default {

    name      : 'ExtendedStudentFilter',
    mixins    : [ MixinCachePreheater ],
    components: {
        StudentGroupSelector,
        Touchable,
        ColorSelector,
        ConfessionSelector,
        GenderSelector,
        Opener,
        Button
    },
    emits     : [ 'close' ],

    props: {
        onlyUnassigned: { type: String, required: false }
    },

    data()
    {
        return {
            neededCaches: [ 'student', 'group', 'class', 'yeargroup' ],
            changeKey   : false,
            openFilters : false,
            filters     : {},
            allStudents : [],
            filtered    : [],
            selected    : []
        }
    },

    created()
    {
        this.changeKey = this.$core.getUuid().generate()
        this.initList()
    },

    methods: {

        initList()
        {
            this.awaitNeededCaches()
                .then( () =>
                {

                    let studentList = this.$core.getBaseClassHelper()
                                          .get( 'student' )
                                          .readCache( 'cache' ),
                        sortRules   = this.$core.settings().getSetting( 'sortingDirections' ),
                        students    = [],
                        filter      = this.$props.onlyUnassigned

                    for( let s in studentList )
                    {
                        if( undefined === filter
                            || undefined === studentList[ s ][ filter ] )
                        {
                            students.push( studentList[ s ] )
                        }
                    }

                    this.allStudents = this.$core.sort().multiSortObjects( students, sortRules[ 'students' ] )
                    this.filtered = this.$core.sort().multiSortObjects( students, sortRules[ 'students' ] )

                } )
        },

        handleFilter( which, value )
        {
            let filterKey = which.replace( 'filter-', '' )
            if( undefined !== value
                && '' !== value.trim() )
            {
                this.filters[ filterKey ] = value
            }
            else
            {
                delete this.filters[ filterKey ]
            }
            this.filterMatch()
        },

        handleSelect( localId, selected )
        {

            if( selected )
            {
                if( -1 === this.selected.indexOf( localId ) )
                {
                    this.selected.push( localId )
                }
            }
            else
            {
                let index = this.selected.indexOf( localId )
                if( -1 < index )
                {
                    this.selected.splice( index, 1 )
                }
            }

        },

        filterMatch()
        {

            this.filtered = []

            let pushed      = [],
                filterCount = 0

            for( let s in this.allStudents )
            {

                let student = this.allStudents[ s ],
                    match   = true

                for( let f in this.filters )
                {
                    filterCount++
                    if( 'classId' === f )
                    {
                        let temp    = this.filters[ f ].split( /:/g ),
                            baseElm = this.$core.getBaseClassHelper()
                                          .getObjectById( temp[ 1 ] )

                        if( undefined !== baseElm
                            && undefined !== baseElm.students
                            && -1 === baseElm.students.indexOf( student.localId ) )
                        {
                            match = false
                        }
                    }
                    else
                    {
                        if( student[ f ] !== this.filters[ f ] )
                        {
                            match = false
                        }
                    }
                }

                if( match
                    && -1 === pushed.indexOf( student.localId ) )
                {
                    this.filtered.push( student )
                    pushed.push( student.localId )
                }

                if( filterCount === 0 )
                {
                    this.filtered = this.allStudents
                }

            }

        },

        selectFiltered( state )
        {
            for( let f in this.filtered )
            {
                this.handleSelect( this.filtered[ f ].localId, state )
                this.$core.getEventManager().dispatch( 'on-select-' + this.filtered[ f ].localId, state )
            }
            this.changeKey = this.$core.getUuid().generate()
        },

        handleClose()
        {
            let elm = document.querySelector( '#fullscreen-overlay' )
            if( null !== elm )
            {
                elm.style.opacity = 0
                setTimeout( () =>
                {
                    this.$emit( 'close' )
                }, 400 )
            }
        },

        handleSubmit()
        {
            this.$core.getEventManager()
                .dispatch( 'on-extended-filter', this.selected )
            this.$emit( 'close' )
        }
    }

}
</script>