export default class QueueUploadWorker
{

    constructor( core )
    {

        if( !QueueUploadWorker.instance )
        {

            this.interval = 2000

            this.logger = core.getLogger()
            this.store = core.getStore()
            this.database = core.getDatabase()
            this.timer = core.getCoreTimer()
            this.client = core.getClient()
            this.ui = core.getUi()
            this.sorter = core.getSorter()
            this.f = core.f()

            this.getState = ( state ) =>
            {
                return core.getState( state )
            }

            this.setState = ( key, value ) =>
            {
                return core.setState( key, value )
            }

            this.maxCombined = 5

            this.eventManager = core.getEventManager()
            this.cryptoHelper = core.getCryptoHelper()
            this.flags = core.getFlags()

            this.lastTrigger = 0
            this.triggering = false
            this.counter = 0

            this.setupTimer()

            QueueUploadWorker.instance = this

        }

        return QueueUploadWorker.instance

    }

    destruct()
    {
        this.timer.removeInterval( 'queueworker-uploads' )
        delete QueueUploadWorker.instance
    }

    /*eslint-disable*/
    appendRemoteId( key, type, remoteId )
    {

        this.database.readObject( key )
            .then( dbObject =>
            {

                let updateable = dbObject.object
                if( updateable.remoteId !== remoteId )
                {
                    updateable.remoteId = remoteId
                    this.database.writeObject( updateable, key, type )
                        .then( () =>
                        {

                            this.eventManager.dispatch( 'on-'+type+'-element-update', key )
                            this.logger.csuccess( 'QueueUploadWorker:appendRemoteId', 'stored remote id #' + remoteId + ' for object.' )

                        } )
                }
                else
                {
                    this.eventManager.dispatch( 'on-mxregistry-update', {
                        type   : type,
                        localId: key
                    } )
                }

            } )

    }

    handleRemove( object )
    {
        return new Promise( resolve =>
        {
            this.database.deleteObject( object.id )
                .then( () =>
                {
                    return resolve()
                } )
                .catch( () =>
                {
                    return resolve()
                } )
        } )
    }

    handleMassUpload( uploads )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.setState( 'massUpdate', true )

            let messages = [],
                promises = [],
                map = {}

            let types = []

            for( let u in uploads )
            {
                if( undefined === map[ uploads[ u ].localId ] )
                {
                    map[ uploads[ u ].localId ] = uploads[ u ].upload
                    promises.push( () =>
                    {
                        return this.processUpload( uploads[ u ].localId, uploads[ u ].upload, true )
                                   .then( message =>
                                   {
                                       this.eventManager.dispatch( 'after-upload-done' )
                                       this.eventManager.dispatchAndRemove( 'on-upload-done-' + uploads[ u ].localId )
                                       messages.push( message )
                                   } )
                    } )
                }
            }

            this.f.promiseRunner( promises )
                .then( () =>
                {

                    let messageList = []
                    for( let m in messages )
                    {
                        if( undefined !== messages[ m ]
                            && undefined !== messages[ m ].message )
                        {
                            messages[ m ].message.messageId = messages[ m ].message.id_local
                            messageList.push( messages[ m ].message )
                        }
                    }

                    let message = {
                        method  : 'objects.storeMass',
                        messages: JSON.stringify( messageList )
                    }

                    let cleanup = []

                    this.client.request( message, 30000 )
                        .then( result =>
                        {

                            for( let r in result.responses )
                            {
                                let response = result.responses[ r ]
                                if( 'success.' === response.result )
                                {
                                    if( -1 === types.indexOf( map[ response.messageId ].item ) )
                                    {
                                        types.push( map[ response.messageId ].item )
                                    }
                                    this.appendRemoteId( response.messageId, map[ response.messageId ].item, response.remoteId )
                                }

                                cleanup.push( () =>
                                {
                                    return this.database.delete( 'uploads', map[ response.messageId ].key )
                                               .then( () =>
                                               {
                                                   this.counter -= 1
                                               } )
                                } )
                            }

                            this.f.promiseRunner( cleanup )
                                .then( () =>
                                {

                                    for( let t in types )
                                    {
                                        this.eventManager.dispatch( 'on-mxregistry-full-refresh', types[ t ] + 's' )
                                    }
                                    this.setState( 'massUpdate', false )
                                    return resolve()
                                } )

                        } )
                        .catch( error =>
                        {
                            this.logger.clog( 'QueueUploadWorker:handleMassUpload', 'job failed: ' + error )
                            this.setState( 'massUpdate', false )
                            return reject( error )
                        } )

                } )

        } )
    }

    /*eslint-disable*/
    processUpload( localId, upload, returnMessage )
    {

        return new Promise( resolve =>
        {

            this.logger.clog( 'QueueUploadWorker:processUpload', 'job#' + upload.key, 'with id#', localId, 'is being processed...' )
            this.database.readObject( localId )
                .then( dbObject =>
                {

                    if( 'schoolHolidays' !== upload.item )
                    {

                        let message = {
                            method  : 'objects.storeObjectAndKeys',
                            id_local: localId,
                            keys    : dbObject.object.keys,
                            object  : dbObject.object.object,
                            type    : upload.item
                        }

                        this.cryptoHelper.decrypt( dbObject.object )
                            .then( test =>
                            {

                                if( false !== test
                                    && undefined !== test.idOwner )
                                {
                                    message.originalOwner = test.idOwner
                                }

                                if( !returnMessage )
                                {

                                    this.client.request( message, 3000000 )
                                        .then( result =>
                                        {

                                            this.logger.clog( 'QueueUploadWorker:processUpload', 'dispatching: on-upload-done-' + localId )
                                            this.eventManager.dispatchAndRemove( 'on-upload-done-' + localId )
                                            this.eventManager.dispatch( 'after-upload-done' )
                                            if( 'success.' === result.result )
                                            {
                                                this.appendRemoteId( localId, upload.item, result.remoteId )
                                            }

                                            this.database.delete( 'uploads', upload.key )
                                                .then( () =>
                                                {
                                                    this.counter -= 1
                                                    this.logger.csuccess( 'QueueUploadWorker:trigger', 'upload done.' )
                                                    return resolve( upload.item )
                                                } )
                                                .catch( e =>
                                                {
                                                    this.counter -= 1
                                                    this.logger.csuccess( 'QueueUploadWorker:trigger', 'upload done, but upload key not removed: ' + e )
                                                    return resolve( upload.item )
                                                } )

                                        } )
                                        .catch( e =>
                                        {

                                            if( 'OWNER_MISMATCH' === e
                                                && !isNaN( parseInt( this.store.getters.idUser ) )
                                                && 0 < parseInt( this.store.getters.idUser )
                                                && true === this.store.getters.authorized
                                                && true === this.store.getters.online )
                                            {
                                                this.handleRemove( dbObject )
                                                    .then( () =>
                                                    {
                                                        this.database.delete( 'uploads', upload.key )
                                                            .then( () =>
                                                            {

                                                                this.counter -= 1
                                                                this.logger.csuccess( 'QueueUploadWorker:trigger', 'upload done.' )
                                                                return resolve( upload.item )

                                                            } )
                                                    } )
                                            }
                                            else
                                            {
                                                this.logger.cerror( 'QueueUploadWorker:trigger', 'upload failed (' + e + ')' )
                                                return resolve()
                                            }

                                        } )

                                }
                                else
                                {
                                    return resolve( {
                                        dbObject: dbObject,
                                        message : message
                                    } )
                                }

                            } )
                            .catch( e =>
                            {
                                this.logger.cerror( 'QueueUploadWorker:trigger', 'upload failed after crypto error (' + e + ')' )
                                return resolve( false )
                            } )

                    }
                    else
                    {

                        this.database.delete( 'uploads', upload.key )
                            .then( () =>
                            {

                                this.eventManager.dispatchAndRemove( 'on-upload-done-' + localId )
                                this.counter -= 1
                                this.logger.csuccess( 'QueueUploadWorker:trigger', 'upload done.' )
                                return resolve( false )

                            } )
                            .catch( () =>
                            {
                                this.eventManager.dispatchAndRemove( 'on-upload-done-' + localId )
                                this.counter -= 1
                                return resolve( false )
                            } )
                    }

                } )
                .catch( () =>
                {

                    this.database.delete( 'uploads', upload.key )
                        .then( () =>
                        {
                            this.eventManager.dispatchAndRemove( 'on-upload-done-' + localId )
                            this.counter -= 1
                            this.logger.clog( 'QueueUploadWorker:trigger', 'upload disappeared: object deleted before upload' )
                            return resolve( false )

                        } )
                        .catch( () =>
                        {
                            this.eventManager.dispatchAndRemove( 'on-upload-done-' + localId )
                            this.counter -= 1
                            return resolve( false )
                        } )

                } )

        } )

    }

    setupTimer()
    {

        this.logger.log( 'QueueUploadWorker::setupTimer', 'setting up new timer, interval is ' + this.interval + 'ms' )
        this.timer.addInterval( 'queueworker-uploads', this.interval, () =>
        {
            this.trigger()
        }, true )

    }

    trigger()
    {

        this.lastTrigger = Date.now()
        if( !this.flags.ready || this.flags.is( 'demouser' ) )
        {
            return
        }

        if( this.triggering )
        {
            return
        }

        if( true === this.store.getters.authorized
            && true === this.store.getters.online )
        {

            this.triggering = true
            this.database.readAllObjects( 'uploads' )
                .then( dbEntries =>
                {

                    let entries  = this.sorter.sortObjects( dbEntries, 'key', 'ascending' ),
                        promises = [],
                        allIds   = [],
                        doubles  = []

                    this.counter = entries.length
                    if( 0 < this.counter )
                    {

                        this.logger.clog( 'QueueUploadWorker:trigger', 'working ' + this.counter + ' upload(s) in queue' )

                        if( 1 < this.counter )
                        {

                            if( true !== this.getState( 'massUpdate' ) )
                            {


                                this.logger.clog( 'QueueUploadWorker:trigger', 'running mass update for ' + this.counter + ' upload(s)...' )
                                let uploads = []

                                let c = 0
                                for( let e in entries )
                                {

                                    if( c < this.maxCombined )
                                    {

                                        let upload = entries[ e ]
                                        let localId = upload.key

                                        if( -1 === doubles.indexOf( localId ) )
                                        {
                                            doubles.push( localId )
                                            uploads.push( { localId: localId, upload: upload } )
                                        }
                                        this.ui.setOnlineStatus( 'Synchronizing', this.counter )
                                        c++

                                    }

                                }

                                this.handleMassUpload( uploads )
                                    .then( () =>
                                    {

                                        this.logger.clog( 'QueueUploadWorker:trigger', 'mass update success.' )
                                        this.ui.setOnlineStatus( 'Online' )
                                        this.triggering = false
                                        this.trigger()

                                    } )
                                    .catch( () =>
                                    {

                                        this.logger.clog( 'QueueUploadWorker:trigger', 'error running massupdate: CrudSync will fix that later...' )
                                        this.ui.setOnlineStatus( 'Online' )
                                        this.triggering = false
                                        this.trigger()

                                    } )

                            }

                        }
                        else
                        {

                            for( let e in entries )
                            {

                                let upload  = entries[ e ],
                                    localId = upload.key

                                promises.push( () =>
                                {

                                    return this.processUpload( localId, upload )
                                               .then( type =>
                                               {

                                                   this.ui.setOnlineStatus( 'Synchronizing', this.counter )

                                                   if( false !== type
                                                       && -1 === allIds.indexOf( localId ) )
                                                   {

                                                       allIds.push( localId )

                                                   }

                                               } )
                                } )

                            }

                            this.f.promiseRunner( promises )
                                .then( () =>
                                {

                                    this.eventManager.dispatch( 'on-element-database-update', allIds )
                                    this.timer.addTimeout( 'queueupload-after-trigger', 500, () =>
                                    {

                                        this.ui.setOnlineStatus( 'Online' )

                                    } )

                                    this.triggering = false
                                    this.trigger()

                                } )

                        }

                    }
                    else
                    {
                        this.triggering = false
                    }

                } )
                .catch( () =>
                {
                    this.triggering = false
                } )

        }
    }

}