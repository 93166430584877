<template>
    <div class="rateselector" :ref="'rateWrap_'+getElementRefName()">
        <input type="text" :id="getElementRefName()" :ref="getElementRefName()" :value="selectedValue"
               @change="handleChange" class="hidden"/>
        <span class="star" v-for="n in parseInt( $core.settings().getSetting( 'ratingStars' ) )"
              :key="'key_rateSel_'+getElementRefName+'_'+n" :class="getClassName(n)"
              @click="setValue(n)" @mouseover="setHover(n)" @mouseout="clearHover()" v-html="getStar(n)"></span>
    </div>
</template>

<script>

import MixinResettableComponent from '@/mixins/MixinResettableComponent'

export default {
    name: 'RateSelector',

    mixins: [ MixinResettableComponent ],

    props: {
        refName    : { Type: String, required: true },
        value      : { Type: String, required: true },
        disabled   : { Type: Boolean, required: false },
        highlighted: { Type: Object, required: false, default: [] },
        inline     : { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false }
    },

    emits: [ 'focussed', 'blurred', 'update' ],

    watch: {
        value: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( this.initialized
                    && newValue !== oldValue )
                {
                    this.selectedValue = newValue
                }
            }
        }
    },

    data()
    {
        return {
            maxValue     : 5,
            hovering     : false,
            hoverValue   : 0,
            selectedValue: 0,
            initialized  : false
        }
    },

    created: function()
    {
        this.$nextTick()
            .then( () =>
            {
                this.setValue( this.$props.value, true )
                this.initialized = true
            } )
    },

    methods: {

        getElementRefName()
        {
            return 'elm_' + this.$props.refName
        },

        getStar( rate )
        {
            if( 'selected' === this.getClassName( rate ) )
            {
                return '&#9733;'
            }
            return '&#9734;'
        },

        getClassName( rate )
        {

            let className = ''
            let compare = this.selectedValue
            if( this.hovering
                && !this.$core.getUi().isTouchDevice() )
            {
                compare = this.hoverValue
            }

            if( rate <= compare )
            {
                className = 'selected'
            }
            else
            {
                className = 'inactive'
            }

            if( -1 < this.highlighted.indexOf( this.$props.refName + '_' + rate ) )
            {
                className = className + ' highlighted'
            }

            return className

        },
        setValue( rate, initial )
        {
            if( ( undefined !== rate && initial )
                || ( true !== this.$props.disabled ) )
            {
                if( this.selectedValue === 1 && rate === 1 )
                {
                    this.selectedValue = 0
                    rate = undefined
                }
                else
                {
                    this.selectedValue = rate
                }
            }
            if( !initial )
            {
                if( true !== this.$props.disabled )
                {
                    this.$emit( 'update', this.$props.refName, 'rateSelector', 'update', undefined, rate )
                }
            }
        },
        handleChange( event )
        {
            if( true !== this.$props.disabled )
            {

                if( undefined !== event.target
                    && undefined !== event.target.value )
                {
                    let newValue = '' == event.target.value ? 0 : event.target.value
                    this.setValue( newValue, false )
                }

            }
        },
        setHover( rate )
        {
            if( true !== this.$props.disabled )
            {
                this.hovering = true
                this.hoverValue = rate
                this.$emit( 'focussed', this.$props.refName + '_' + rate )
            }
        },
        clearHover()
        {
            this.hovering = false
            this.$emit( 'blurred', 'all:' + this.$props.refName )
        }
    }
}
</script>