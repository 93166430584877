<template>
    <div>
        <div class="status-text initializing">entzettelt wird gestartet...</div>
        <div class="initializing-checkmark">&#8635;</div>
    </div>
</template>

<script>
export default {
    name : 'Initializing'
}
</script>