export default class SyncCrudTimeDiff
{
    
    constructor( parent )
    {
    
        this.p = parent
        this.parent = this.p.parent
        this.setBefore = false
        this.setState = parent.setState

        return this
        
    }
    
    shouldSync()
    {
        return !this.setBefore || this.parent.getSyncParameters().mode === 'full'
    }
    
    sync()
    {
        
        return new Promise( resolve => {
            
            this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudTimeDiff', 'performing sync run...' )
            let message = {
                method: 'objects.getTimestamp'
            }
    
            let reqTimeStart = Date.now()
            let timeLocal = Date.now()
            
            this.parent.client.request( message )
                .then( response =>
                {
    
                    let reqTimeEnd = Date.now()
                    let fuzz = reqTimeEnd - reqTimeStart
                    
                    if( this.parent.f.isset( response.result ) )
                    {
                        
                        let timeRemote = response.result
                        timeLocal+= fuzz
                        
                        let diff = timeRemote - timeLocal
                        this.p.diff = diff

                        this.setState( 'remoteTimeDiff', diff )

                        this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudTimeDiff', 'done (success: remote differs by',diff,'ms.)' )
                        this.setBefore = true
                        
                        return resolve()
                        
                    }
                    else
                    {
                        throw new Error( 'no valid response on timestamp-request' )
                    }
            
                } )
                .catch( error =>
                {
    
                    this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudTimeDiff', 'done (faulty:', error, ')' )
                    return resolve()
            
                } )
            
        })
        
    }
    
}