<template>
    <Button v-if="hasExtended"
            type="options"
            :addClass="'absolute top right'+( true === hasCloser ? ' has-closer' : '' )"
            @clicked="$emit( 'toggle' )"/>
</template>

<script>
export default {

    name: 'FormExtendedButton',

    props: {
        hasExtended: { type: Boolean, required: true },
        hasCloser: { type: Boolean, required: false, default: false },
    },

    emits: [ 'toggle' ]

}
</script>