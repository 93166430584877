<template>
    <transition appear :name="$core.settings().getTransition( 'fade' )">
        <div>
            <div class="preheating-wrapper">
                <div class="preheating-indicator">
                    <div class="preheating-logo"></div>
                    <div class="clearfix"></div>
                    <div class="preheating-caption-wrap">
                        <div class="preheating-caption">
                            <template v-if="caption === undefined">
                                <strong>Wusstest du schon?</strong><br/><br/><span v-html="quote"></span>
                            </template>
                            <template v-else>
                                <strong>{{ caption }}</strong>
                            </template>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'PreheatingIndicator',

    props: {
        caption: { type: String, required: false }
    },

    computed: {
        quote()
        {

            let vars = this.$core.t( 'preheating-captions' ),
                x    = Math.floor( Math.random() * ( vars.length ) )

            return vars[ x ]

        }
    }
}
</script>