import AbstractShadowCopyClass
    from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/abstract/AbstractShadowCopyClass";

export default class CopyStudent extends AbstractShadowCopyClass
{

    constructor( parent )
    {

        super( parent, true, 'student', 'CopyStudent' )
        return this

    }

    /*eslint-disable*/
    create( job )
    {

        return new Promise( ( resolve, reject ) =>
        {

            if( undefined === job.access )
            {
                return reject( 'INVALID' )
            }

            if( job.access.studentLocalId !== job.original.localId )
            {
                return reject( 'INVALID' )
            }

            this.prepareShadowCopyElement( job )
                .then( shadowCopy =>
                {

                    this.encryptShadowCopyElement( job, shadowCopy )
                        .then( result =>
                        {

                            return resolve( { plain: shadowCopy, db: result } )

                        } )
                        .catch( () =>
                        {
                            return reject()
                        } )

                } )

        } )

    }

}
