import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class UnsharesObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !UnsharesObjectClass.instance )
        {

            let refList = [ 'idReference',
                            'objectType',
                            'sharedWith',
                            'timestamp',
                            'update' ]

            let personalAttributesStatesList = []

            super( core, 'unshare', refList, personalAttributesStatesList )

            this.objectType = 'unshares'
            this.eventManager = core.getEventManager()

            UnsharesObjectClass.instance = this

        }

        return UnsharesObjectClass.instance

    }

    listAll( attach, filter, skipArchived, skipWait )
    {
        return new Promise( ( resolve, reject ) =>
        {
            super.listAll( attach, filter, skipArchived, skipWait )
                 .then( list =>
                 {

                     for( let l in list )
                     {
                         this.registry.byReference.set( list[ l ].idReference, list[ l ].localId )
                     }

                     this.eventManager
                         .dispatchIndexed( 'on-share-refresh-component' )

                     return resolve( list )

                 } )
                 .catch( e =>
                 {
                     return reject( e )
                 } )
        } )
    }

    create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
    {

        let localId = super.create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )

        this.eventManager.append( 'storable-after-update-' + localId, () =>
        {
            this.eventManager
                .dispatchIndexed( 'on-share-refresh-component' )
        } )

        return localId

    }

    update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )
    {

        this.eventManager.append( 'after-updated-'+localId, () => {
            this.eventManager
                .dispatchIndexed( 'on-share-refresh-component' )
        })
        return super.update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )

    }

}