<template>
    <div :class="'touchable'+( galleryMode ? ' gallery' : '' )"
         :key="'touchable-'+item.localId">
        <Quickmarks :item="item"
                    :noShareIndicator="noShareIndicator"
                    :scope="scope"/>
        <div :class="'touchable-left'+( swiped ? ' swiped' : '' )+' '+additionalCssClasses"
             :id="'touchable-'+viewItem+'-'+scope+'-'+item.localId"
             :key="'touchable-left-'+item.localId">
            <DebugInfo v-if="$core.settings().getSetting( 'debugInfo' )"
                       :item="item"/>
            <component :is="component"
                       :item="item"
                       :selected="selected"
                       :selectMode="selectMode"
                       :escapeNested="escapeNested"
                       :singleItems="singleItems"
                       @dragging="$emit( 'dragging', $event )"
                       @injectHandlers="handleInjectedHandlers"
                       @click="handleClick"
                       @dataUpdate="handleDataUpdate"
                       @contextmenu="handleSwipe"/>
        </div>
        <FunctionButtons :item="item"
                         :viewItem="viewItem"
                         :scope="scope"
                         :swipedRight="swipedRight"
                         :disappearing="functionsDisappearing"
                         @clicked="handleFunctionClick"/>
        <div class="clearfix"></div>
    </div>
</template>

<script>
import Quickmarks                from "@/components/defaults/defaultTouchable/Quickmarks";
import DebugInfo                 from "@/components/defaults/defaultTouchable/DebugInfo";
import FunctionButtons           from "@/components/defaults/defaultTouchable/FunctionButtons";
import MixinSwipeHandler         from "@/components/defaults/defaultTouchable/MixinSwipeHandler";
import MixinEvents               from "@/mixins/MixinEvents";
import MixinFunctionClickHandler from "@/components/defaults/defaultTouchable/MixinFunctionClickHandler";
import Date                      from "@/components/elements/viewItems/Date"
import Note                      from "@/components/elements/viewItems/Note"
import Todo                      from "@/components/elements/viewItems/Todo";
import Student                   from "@/components/elements/viewItems/Student";
import Class                     from "@/components/elements/viewItems/Class";
import Group                     from "@/components/elements/viewItems/Group";
import Team                      from "@/components/elements/viewItems/Team";
import Yeargroup                 from "@/components/elements/viewItems/Yeargroup";
import List                      from "@/components/elements/viewItems/List";
import Colleague                 from "@/components/elements/viewItems/Colleague";
import MixinSelectHandler        from "@/components/defaults/defaultTouchable/MixinSelectHandler";
import Message                   from "@/components/elements/viewItems/Message";
import MixinComponentCleanup     from "@/mixins/MixinComponentCleanup";
import Media                     from "@/components/elements/viewItems/Media";
import OrganizerContent          from "@/components/elements/viewItems/OrganizerContent";
import Organizer                 from "@/components/elements/viewItems/Organizer";

export default {

    name      : "Touchable",
    components: {
        Organizer,
        OrganizerContent,
        Media,
        Date,
        Message,
        Team,
        Colleague,
        List,
        Yeargroup,
        Group,
        Class,
        Student,
        Todo,
        FunctionButtons,
        DebugInfo,
        Quickmarks,
        Note
    },
    mixins    : [ MixinSwipeHandler, MixinFunctionClickHandler, MixinSelectHandler, MixinEvents, MixinComponentCleanup ],
    emits     : [ 'refresh', 'clicked', 'select', 'answer', 'forward', 'dragging' ],

    props: {
        item            : { Type: Object, required: true },
        viewItem        : { Type: String, required: true },
        scope           : { Type: String, required: true },
        clickDisabled   : { Type: Boolean, required: false, default: false },
        bare            : { Type: Boolean, required: false, default: false },
        escapeNested    : { Type: Boolean, required: false, default: false },
        selectMode      : { Type: Boolean, required: false, default: false },
        singleItems     : { Type: Boolean, required: false, default: false },
        startOpen       : { Type: Boolean, required: false, default: false },
        fakeElement     : { Type: Boolean, required: false, default: false },
        fakeCount       : { Type: Number, required: false, default: false },
        noShareIndicator: { Type: Boolean, required: false, default: false },
        galleryMode     : { Type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            injectedHandlers     : false,
            positionChanged      : false,
            swiped               : false,
            swipedRight          : false,
            functionsDisappearing: false,
            additionalCssClasses : '',
            lastClick            : 0,
            selected             : false
        }
    },

    computed: {
        component()
        {
            return this.$props.viewItem
        }
    },

    created()
    {

        this.appendNamedEvent( 'on-deselect-all', 'item-' + this.$props.item.localId, () =>
        {
            this.selected = false
        } )
        this.appendEvent( 'on-select-' + this.$props.item.localId, ( state ) =>
        {
            this.selected = state
        } )
    },

    methods: {

        reset()
        {
            this.handleSwipe( false, 'right' )
            this.swiped = false
            this.swipedRight = false
            this.resetSwipes()
        },

        handleDataUpdate()
        {

            this.additionalCssClasses = 'data-updated shine'
            this.$core.getUi()
                .delay( () =>
                {

                    this.additionalCssClasses = ''

                }, 4000 )

        },

        handleClick()
        {

            if( !this.$props.clickDisabled
                && this.$core.f().now() > this.lastClick + 500 )
            {

                this.lastClick = this.$core.f().now()
                if( false === this.$props.selectMode )
                {
                    this.$emit( 'clicked' )
                }
                else
                {
                    this.selected = !this.selected
                    this.$emit( 'select', this.$props.item.localId, this.selected )
                }

            }

        },

        handleInjectedHandlers( handlers )
        {
            this.injectedHandlers = handlers
        }

    }

}
</script>