<template>
    <TutorialMessage :caption="$core.t( 'tutorial-caption-notfound' )"
                     :text="$core.t( 'tutorial-text-notfound-'+step )"
                     :step="step"
                     :last="last"
                     @back="handleBack()"
                     @next="handleNext()"/>
</template>

<script>
import TutorialMessage from '@/components/elements/tutorial/TutorialMessage'
import TutorialBase    from '@/components/elements/tutorial/TutorialBase'

export default {

    name      : 'TutorialNotFound',
    components: { TutorialMessage },
    extends   : TutorialBase,

    data()
    {
        return {
            last: 1
        }
    },

    methods: {

        prepareHighlights()
        {
            this.highlights = []
            switch( this.step )
            {
                case 1:
                    break
            }

        }

    }

}
</script>