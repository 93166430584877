<template>
    <div :id="'element-'+element.localId+'___'+element.referenceKey" class="planner-element-boundaries">
        <div v-if="$core.f().allFilterMatch( element, filterList, true )"
             :class="'planner-element ' + draggableClassName + ' ' +
                                    ( selectMode === true && -1 < selected.indexOf( element.localId ) ? 'selected ' : '' ) +
                                    element.color+multiDayClasses( element )"
             :style="{ '--index': index }"
             @click="handleClick"
             @contextmenu="instantFullscreen">
            <template
                v-if="!$core.f().isset( element.isMultiDay ) || 0 === element.isMultiDay || element.isHoliday">
                <div v-if="element.type === 'date' && !element.isBirthday && !element.isSchoolHoliday"
                     :class="'planner-element-icon icon-date hours-'+startHour( element.start )"
                     v-html="$core.getUi().getClockIcon( startHour( element.start ) )"></div>
                <div v-else-if="element.type === 'date' && element.isBirthday"
                     class="planner-element-icon icon-date"
                     v-html="$core.getUi().getCakeIcon()"></div>
                <div v-else-if="element.type === 'date' && element.isSchoolHoliday"
                     class="planner-element-icon icon-date"
                     v-html="$core.getUi().getPalmIcon()"></div>
                <div v-else-if="element.type === 'todo'"
                     :class="'planner-element-icon icon-todo'+( 1 === element.done ? '-done' : '' )"></div>
                <div v-else :class="'planner-element-icon icon-'+element.type"></div>
            </template>
            <template v-else>
                <template v-if="element.isSchoolHoliday">
                    <div v-if="multiDayStart( element)"
                         :class="'planner-element-icon icon-date'"
                         v-html="$core.getUi().getPalmIcon()"></div>
                    <div v-else
                         :class="'planner-element-icon noborder icon-date'"
                         v-html="$core.getUi().getMultiIcon()"></div>
                </template>
                <template v-else>
                    <div v-if="multiDayStart( element)"
                         :class="'planner-element-icon icon-date'"
                         v-html="$core.getUi().getClockIcon( startHour( element.start ) )"></div>
                    <div v-else
                         :class="'planner-element-icon noborder icon-date'"
                         v-html="$core.getUi().getMultiIcon()"></div>
                </template>
            </template>
            <div v-if="element.type === 'date'" class="planner-element-body"
                 v-html="element.title"></div>
            <div v-if="element.type === 'todo'"
                 :class="'planner-element-body'+( 1 === element.done ? ' stroke' : '' )"
                 v-html="element.title"></div>
            <div v-if="element.type === 'note'" class="planner-element-body"
                 v-html="$core.f().htmlExcerpt( element.body, 50 )"></div>
            <div v-if="element.type === 'list'" class="planner-element-body"
                 v-html="element.listname"></div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
        <div v-if="undefined !== day" class="clearfix"></div>
    </div>
</template>

<script>
export default {

    name: 'PlannerElement',

    emits: [ 'clicked', 'mouseup', 'contextmenu' ],

    props: {
        element   : { Type: Object, required: true },
        index     : { Type: Number | String, required: true },
        filterList: { Type: Object, required: true },
        timestamp : { Type: Number, required: true },
        day       : { Type: Number, required: false },
        slotIndex : { Type: Number, required: false },
        selected  : { Type: Object, required: false },
        selectMode: { Type: Boolean, required: false, default: false }
    },

    computed:
        {
            draggableClassName()
            {
                if( this.$props.element.isSchoolHoliday
                    || this.$props.element.isHoliday
                    || this.$props.element.isBirthday )
                {
                    return 'non-draggable'
                }
                return 'draggable'
            }
        },

    mounted()
    {
        if( undefined !== this.$props.day )
        {
            let elm = document.querySelector( '#slot-' + this.$props.day + '-' + this.$props.slotIndex )
            if( null !== elm )
            {
                this.$core.getEventManager().dispatchIndexed( 'on-slot-' + this.$props.slotIndex + '-element-size',
                    {
                        day      : this.$props.day,
                        slotIndex: this.$props.slotIndex,
                        height   : elm.offsetHeight
                    } )
            }
        }
    },

    beforeUnmount()
    {
        this.$core.getEventManager().dispatchIndexed( 'on-slot-' + this.$props.slotIndex + '-element-size',
            {
                day      : this.$props.day,
                slotIndex: this.$props.slotIndex,
                height   : -1
            } )
    },

    methods: {

        multiDayClasses( element )
        {

            if( !this.$core.f().isset( element.isMultiDay )
                || 0 === element.isMultiDay )
            {
                return ''
            }

            return ' multiday' + ( this.multiDayStart( element ) ? ' multiday-start' : '' ) + ( this.multiDayEnd( element ) ? ' multiday-end' : '' )

        },

        multiDayStart( element )
        {

            if( true === element.isMultiDayStart )
            {
                return true
            }
            if( this.$core.f().isset( element.isMultiDay )
                && 0 < element.isMultiDay )
            {

                return this.$core.getFriendlyTimestamp().formattedDate( element.timestamp )
                       === this.$core.getFriendlyTimestamp().formattedDate( this.$props.timestamp )

            }

            return false

        },

        multiDayEnd( element )
        {

            if( true === element.isMultiDayEnd )
            {
                return true
            }
            if( this.$core.f().isset( element.isMultiDay )
                && 0 < element.isMultiDay )
            {

                return this.$core.getFriendlyTimestamp()
                           .formattedDate( this.$core.getFriendlyTimestamp()
                                               .timestampForDate( element.enddate ) )
                       === this.$core.getFriendlyTimestamp()
                               .formattedDate( this.$props.timestamp )

            }

            return false

        },

        startHour( time )
        {
            let temp = time.split( ':' )
            return parseInt( temp[ 0 ] )
        },

        handleClick( event )
        {
            this.$core.f().skip( event )
            this.$emit( 'clicked', { event: event, element: this.$props.element } )
        },

        instantFullscreen( event )
        {
            this.$emit( 'contextmenu', event )
        }

    }

}
</script>