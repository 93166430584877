<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-show="visible" class="default-form-overlay imageZoom">
            <div class="default-form-container">
                <div class="default-form-wrapper">
                    <Button type="close"
                            @clicked="handleClose()"
                            addClass="absolute top right inverted"/>
                    <ImageZoom v-if="'image' === type" :setup="setup" :readableFilesize="readableFilesize"/>
                    <PdfZoom v-if="'pdf' === format" :setup="setup" :readableFilesize="readableFilesize"/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import ImageZoom from "@/components/defaults/overlays/imageZoom/ImageZoom";
import PdfZoom   from "@/components/defaults/overlays/imageZoom/PdfZoom";

export default {

    name      : 'DefaultImageZoom',
    components: { PdfZoom, ImageZoom },
    emits     : [ 'close' ],
    props     : {
        readyKey: { type: String, required: true },
        setup   : { type: Object, required: true },
    },

    data()
    {
        return {
            visible         : true,
            readableFilesize: 0,
            type            : false,
            format          : false
        }
    },

    created()
    {
        let realSize = this.$props.setup.image.size * 1.725
        this.readableFilesize = this.$core.getReformatter().reformat( ( realSize / 1000 ), 'localizedFloat' )
        let temp   = this.setup.image.format.split( /\//g ),
            type   = temp[ 0 ],
            format = temp[ 1 ]

        this.type = type
        this.format = format
    },

    mounted()
    {

    },

    methods: {

        handleClose()
        {
            this.visible = false
            this.$core.getUi()
                .delay( () =>
                {
                    this.$emit( 'close' )
                }, 400 )
        }

    }
}
</script>