<script>
export default {

    name: "MixinCalendarViewFunctions",

    beforeUnmount()
    {
        this.hookKeys( false )
    },

    computed: {
        title()
        {

            if( !this.prepared || !this.date )
            {
                return '...'
            }
            let title = ''

            switch( this.mode )
            {
                case 'month':
                    title = '<strong>' + this.$core.getFriendlyTimestamp().friendlyMonth( this.date.getMonth() ) + '</strong> ' + this.year
                    break
                case 'week':
                    title = '<strong>Woche ' +
                            this.$core.getFriendlyTimestamp().getCalendarWeek( new Date( this.getMonday( this.date ) ) ) + '</strong>: ' +
                            this.rangeFrom + ' - ' + this.rangeUntil
                    break
                default:
                    title = this.date
                    break
            }

            return title

        }
    },

    methods: {

        hookKeys( state )
        {

            switch( state )
            {
                case undefined:
                    document.onkeydown = ( event ) =>
                    {

                        if( !event.shiftKey && 'ArrowLeft' === event.key )
                        {
                            this.changeDate( 'last', undefined, this.plannerView || 'calendar' )
                        }
                        if( !event.shiftKey && 'ArrowRight' === event.key )
                        {
                            this.changeDate( 'next', undefined, this.plannerView || 'calendar' )
                        }
                        if( '+' === event.key )
                        {
                            this.$emit( 'add' )
                        }

                    }
                    break
                case false:
                    document.onkeydown = null
                    break
            }

        },

        getMonday( d )
        {

            let day  = d.getDay(),
                diff = d.getDate() - day + ( day == 0 ? -6 : 1 )

            return new Date( d.setDate( diff ) ).getTime()

        },

/*        prepareDateGrid()
        {

            let fillersBefore = 0,
                fillersAfter  = 0,
                halfDay       = 86400000 / 2,
                scopes        = [ 'calendar', 'organizer' ],
                after,
                start,
                organizerStart,
                daysTotal,
                daysInMonth

            switch( this.mode )
            {
                case 'month':
                    fillersBefore = this.date.getDay() !== 0 ? ( this.date.getDay() - 1 ) : 6
                    after = ( this.changeDate( 'next', true ) - 86400000 )
                    fillersAfter = 7 - new Date( after ).getDay()
                    daysInMonth = new Date( this.year, ( this.month + 1 ), 0 ).getDate()
                    start = this.$core.getFriendlyTimestamp().timestampForDate( '1.' + ( 1 + this.month ) + '.' + this.year ) - ( fillersBefore * 86400000 )
                    organizerStart = this.$core.getFriendlyTimestamp().timestampForDate( '1.' + ( 1 + this.organizerMonth ) + '.' + this.organizerYear ) - ( fillersBefore * 86400000 )
                    daysTotal = fillersBefore + fillersAfter + daysInMonth
                    break
                case 'week':
                    daysInMonth = 7
                    start = this.$core.getFriendlyTimestamp().timestampForDateTime(
                        this.$core.getFriendlyTimestamp().formattedDate( this.getMonday( this.date ) ) + ' 12:00:00' )
                    organizerStart = this.$core.getFriendlyTimestamp().timestampForDateTime(
                        this.$core.getFriendlyTimestamp().formattedDate( this.getMonday( this.organizerDate ) ) + ' 12:00:00' )
                    daysTotal = 7
                    break
            }

            let daysAfter = 0,
                dayAfter  = new Date( after + 86400000 ),
                dayStart  = new Date( this.date.getTime() ),
                tsmpStart = false,
                tsmpEnd   = 0

            switch( dayAfter.getDay() )
            {
                case 1:
                case 3:
                case 5:
                    daysAfter = -1
                    break
                case 6:
                    daysAfter = 1
                    break
            }

            let firstVisBefore = fillersBefore
            switch( dayStart.getDay() )
            {
                case 0:
                    firstVisBefore = fillersBefore - 2
                    break
                case 2:
                case 4:
                case 6:
                    firstVisBefore = fillersBefore - 1
                    break
            }

            this.rangeFrom = this.$core.getFriendlyTimestamp().friendlyDate( start )
            this.rangeUntil = this.$core.getFriendlyTimestamp().friendlyDate( start + ( ( daysTotal - 1 ) * 86400000 ) )
            this.organizerRangeFrom = this.$core.getFriendlyTimestamp().friendlyDate( organizerStart )
            this.organizerRangeUntil = this.$core.getFriendlyTimestamp().friendlyDate( organizerStart + ( ( daysTotal - 1 ) * 86400000 ) )

            for( let i = 0; i < daysTotal; i++ )
            {

                for( let s in scopes )
                {

                    let scope           = scopes[ s ],
                        calcStart       = scope === 'calendar' ? start : organizerStart,
                        dateObject      = new Date( calcStart ),
                        isFiller        = i < fillersBefore || i >= ( fillersBefore + daysInMonth ),
                        twoRowVis       = false,
                        firstVisTwoRows = firstVisBefore,
                        lastVisTwoRows  = fillersBefore + daysInMonth + daysAfter

                    if( false === tsmpStart )
                    {
                        tsmpStart = dateObject.getTime()
                    }

                    if( isFiller )
                    {
                        twoRowVis = i >= firstVisTwoRows && i <= lastVisTwoRows
                    }

                    let date = {
                        dateObject: dateObject,
                        day       : dateObject.getDate(),
                        isToday   : ( this.today.getDate() === dateObject.getDate() && this.today.getMonth() === dateObject.getMonth() && this.today.getFullYear() === dateObject.getFullYear() ),
                        isWeekend : ( dateObject.getDay() > 5 || dateObject.getDay() === 0 ),
                        isFiller  : isFiller,
                        twoRowVis : twoRowVis ? ' filler-visible ' : ( isFiller ? ' filler-invisible ' : '' ),
                        fillerType: ' planner-filler' + ( i < fillersBefore ? ' fill-before' : '' ) + ( i >= ( fillersBefore + daysInMonth ) ? ' fill-after' : '' ) + ' fill-' + dateObject.getDay(),
                        dateRange : { from: ( calcStart - halfDay ), until: ( calcStart + halfDay ) },
                        elements  : []
                    }

                    if( false !== this.showDate
                        && this.showDate.dateRange.from === date.dateRange.from )
                    {
                        this.prepareForDate( date )
                        this.showDate = date
                    }

                    switch( scope )
                    {
                        case 'calendar':
                            this.dates.push( date )
                            start += 86400000
                            break
                        case 'organizer':
                            this.organizerDates.push( date )
                            if( !date.isWeekend
                                && organizerStart > tsmpEnd )
                            {
                                tsmpEnd = organizerStart
                            }
                            organizerStart += 86400000
                            break
                    }

                }

            }

            this.tsmpStart = tsmpStart
            this.tsmpEnd = tsmpEnd

        },*/

        prepareDateElements()
        {
            for( let d in this.dates )
            {

                let date = this.dates[ d ]
                this.prepareForDate( date )

            }
        },

        prepare()
        {

            let date      = new Date(),
                stored    = this.$core.getState( this.plannerView === 'organizer' ? 'organizer-date' : 'planner-date' ),
                timestamp = this.$core.getFriendlyTimestamp().timestampForDate( '1.' + ( 1 + ( date.getMonth() ) ) + '.' + date.getFullYear() )

            this.preparing = true
            this.today = date
            this.dates = []

            switch( this.mode )
            {
                case 'week':
                    if( undefined !== this.$props.startTsmp )
                    {
                        this.date = new Date( parseInt( this.$props.startTsmp ) )
                    }
                    else
                    {
                        this.date = stored || new Date()
                    }
                    break
                default:
                    this.date = new Date( timestamp )
                    break
            }

            this.organizerDate = this.date
            this.prepareHolidays()
                .then( () =>
                {

                    this.prepareDateGrid()
                    this.prepareDateElements()
                    this.preparing = false
                    this.prepared = true
                    this.$core.getEventManager().dispatch( 'on-planner-prepared' )
                    this.viewKey = this.$core.getUuid().generate()

                } )

        },

        prepareHolidays()
        {

            return new Promise( resolve =>
            {

                this.holidays = []
                this.schoolHolidays = []

                this.$core.getDatabase().readAllObjectsFiltered( 'holidays' )
                    .then( list =>
                    {

                        if( 0 < list.length )
                        {
                            let holidays = list.shift()
                            for( let h in holidays.object )
                            {
                                let hol = holidays.object[ h ]
                                if( 'object' === typeof hol )
                                {
                                    this.holidays.push( hol )
                                }
                            }

                        }

                        this.$core.getDatabase().readAllObjectsFiltered( 'schoolHolidays' )
                            .then( list =>
                            {
                                if( 0 < list.length )
                                {
                                    let holidays = list.shift()
                                    for( let h in holidays.object )
                                    {
                                        let hol = holidays.object[ h ]
                                        if( 'object' === typeof hol )
                                        {
                                            this.schoolHolidays.push( hol )
                                        }
                                    }
                                }

                                return resolve()

                            } )
                    } )

            } )

        },

        prepareForDate( date )
        {

            let dateElms = [],
                dayCheck = new Date( date.dateRange.from )

            for( let h in this.holidays )
            {

                let holi = this.holidays[ h ]
                let temp = holi.date.split( '-' )
                if( parseInt( temp[ 2 ] ) === parseInt( dayCheck.getDate() )
                    && parseInt( temp[ 1 ] ) === ( parseInt( dayCheck.getMonth() ) + 1 )
                    && parseInt( temp[ 0 ] ) === ( parseInt( dayCheck.getFullYear() ) ) )
                {

                    let date = {
                        title      : holi.fname,
                        start      : '-',
                        description: 'Feiertag',
                        color      : 'holidays',
                        isHoliday  : true,
                        isMultiDay : 200,
                        isBirthday : false,
                        editLocked : true,
                        type       : 'date'
                    }

                    dateElms.push( date )

                }

            }

            let checkTime = dayCheck.getTime()

            for( let h in this.schoolHolidays )
            {

                let holi = this.schoolHolidays[ h ]
                let name = this.$core.f().ucFirst( holi.name )

                let start = this.$core.getFriendlyTimestamp().timestampForDateTime(
                    this.$core.getFriendlyTimestamp().formattedDate(
                        this.$core.getFriendlyTimestamp().timestampFromMysql( this.$core.getFriendlyTimestamp().convertServerTimestamp( holi.start ) )
                    ) + ' 00:00:00'
                )
                let end = this.$core.getFriendlyTimestamp().timestampForDateTime(
                    this.$core.getFriendlyTimestamp().formattedDate(
                        this.$core.getFriendlyTimestamp().timestampFromMysql( this.$core.getFriendlyTimestamp().convertServerTimestamp( holi.end ) )
                    ) + ' 23:59:59'
                )

                if( checkTime >= start
                    && checkTime <= end )
                {

                    let date = {
                        title          : name,
                        start          : '-',
                        description    : 'Schulferien',
                        color          : 'school-holidays',
                        isHoliday      : false,
                        timestamp      : start,
                        enddate        : this.$core.getFriendlyTimestamp().formattedDate( end ),
                        isSchoolHoliday: true,
                        isBirthday     : false,
                        isMultiDay     : 200,
                        editLocked     : true,
                        type           : 'date'
                    }

                    dateElms.push( date )

                }

            }

            let multidays = 100
            let todo = [ 'dates' ] // 'notes', 'todos' ]
            if( this.$core.settings().getSetting( 'calendarShowNotes' ) )
            {
                todo.push( 'notes' )
            }
            if( this.$core.settings().getSetting( 'calendarShowTodos' ) )
            {
                todo.push( 'todos' )
            }

            for( let t in todo )
            {

                let step = todo[ t ],
                    elms = this.$core.getBaseClassHelper()
                               .get( this.translateType( step ) )
                               .readCache( 'cache' )

                for( let e in elms )
                {

                    let elm           = this.$core.getBaseClassHelper().getObjectById( elms[ e ].localId ),
                        checkStamp    = elm.timestamp,
                        checkStampEnd = elm.timestamp

                    if( 'todos' === step )
                    {
                        checkStamp = elm.duedate
                    }
                    if( 'dates' !== step )
                    {
                        elm.start = "99:99"
                    }

                    let isMultiDay = this.$core.f().isset( elm.enddate )
                                     && '' !== elm.enddate.trim()
                                     && this.$core.getValidator().validate( 'date', false, elm.enddate )

                    elm.isMultiDay = isMultiDay ? multidays : 0
                    let dayCount = 1

                    if( isMultiDay )
                    {

                        multidays++
                        let endTime = '23:59:59'
                        checkStamp = this.$core.getFriendlyTimestamp().timestampForDateTime(
                            this.$core.getFriendlyTimestamp().formattedDate( checkStamp ) + ' 00:00:00' )
                        checkStampEnd = this.$core.getFriendlyTimestamp().timestampForDateTime( elm.enddate + ' ' + endTime )
                        dayCount = Math.ceil( ( checkStampEnd - checkStamp ) / 86400000 )

                    }

                    elm.dayCount = dayCount

                    if( ( !isMultiDay && ( checkStamp >= date.dateRange.from
                          && checkStamp < date.dateRange.until ) )
                        || ( isMultiDay && ( checkStamp <= date.dateRange.from
                             && date.dateRange.until <= ( checkStampEnd + 1000 ) ) ) )
                    {

                        if( !this.$core.f().isset( this.$props.reference )
                            || this.referenceMatch( elm ) )
                        {
                            dateElms.push( elm )
                        }

                    }

                }
            }

            for( let b in this.birthdays )
            {
                let birthday = this.birthdays[ b ]
                let temp = birthday.birthday.split( '.' )
                if( parseInt( temp[ 0 ] ) === parseInt( dayCheck.getDate() )
                    && parseInt( temp[ 1 ] ) === ( parseInt( dayCheck.getMonth() ) + 1 ) )
                {

                    let age = parseInt( dayCheck.getFullYear() ) - parseInt( temp[ 2 ] )
                    let date = {
                        title      : birthday.student.lastname + ', ' + birthday.student.firstname,
                        start      : '-',
                        description: 'wird <strong>' + age + '</strong> Jahre alt' + ( undefined !== birthday.student.classId ? '<br/>Klasse: <strong>' + birthday.student.classname + '</strong>' : '' ),
                        color      : 'birthday-' + birthday.student.gender,
                        isBirthday : true,
                        editLocked : true,
                        timestamp  : this.$core.friendlyTimestamp.timestampForDate( birthday.birthday ),
                        type       : 'date'
                    }

                    dateElms.push( date )

                }
            }

            if( this.$core.settings().getSetting( 'calendarShowLists' ) )
            {

                let elms = this.$core.getBaseClassHelper()
                               .get( 'list' )
                               .readCache( 'cache' )

                for( let e in elms )
                {
                    let elm = this.$core.getBaseClassHelper()
                                  .get( 'list' ).registry[ 'cache' ].get( elms[ e ].referenceKey )

                    for( let l in elm.lists )
                    {
                        if( elm.lists[ l ].timestamp >= date.dateRange.from
                            && elm.lists[ l ].timestamp < date.dateRange.until )
                        {

                            if( !this.$core.f().isset( this.$props.reference )
                                || this.referenceMatch( elm.lists[ l ] ) )
                            {
                                dateElms.push( elm.lists[ l ] )
                            }

                        }
                    }
                }

            }

            let sortRules = [
                [ 'start', 'ascending' ],
                [ 'isMultiDay', 'descending' ],
                [ 'dayCount', 'descending' ],
                [ 'type', 'ascending' ],
                [ 'isHoliday', 'descending' ],
                [ 'isSchoolHoliday', 'descending' ]
            ]

            dateElms = this.$core.sort().multiSortObjects( dateElms, sortRules, true )
            this.$core.sort().multiSortObjects( dateElms, sortRules, true )
            date.elements = dateElms

        },

        changeDate( direction, returnValue, target, jumpTarget )
        {

            target = target || 'calendar'

            let newYear  = target === 'calendar' ? this.year : this.organizerYear,
                newMonth = target === 'calendar' ? this.month : this.organizerMonth,
                timestamp

            switch( this.mode )
            {
                case 'month':
                    switch( direction )
                    {
                        case 'last':
                            newMonth -= 1
                            break
                        case 'next':
                            newMonth++
                            break
                    }

                    timestamp = this.$core.getFriendlyTimestamp().timestampForDate( '1.' + ( 1 + newMonth ) + '.' + newYear )
                    break
                case 'week':
                    timestamp = target === 'calendar' ? this.date.getTime() : this.organizerDate.getTime()
                    switch( direction )
                    {
                        case 'last':
                            timestamp -= ( 86400000 * 7 )
                            break
                        case 'next':
                            timestamp += ( 86400000 * 7 )
                            break
                        case 'jump':
                            timestamp = jumpTarget
                            break
                    }
                    break
            }

            if( undefined === returnValue )
            {
                this.date = new Date( timestamp )
                this.organizerDate = new Date( timestamp )
                switch( target )
                {
                    case 'calendar':
                        this.$core.setState( 'planner-date', this.date )
                        break
                    case 'organizer':
                        this.$core.setState( 'organizer-date', this.organizerDate )
                        break
                }
                this.prepare()
            }
            else
            {
                return timestamp
            }

        }

    }

}
</script>