<template>
    <div class="list-element numberbox">
        <template v-if="$core.f().isset( value )">
            <span>
                {{ value }}
            </span>
        </template>
    </div>
</template>

<script>
export default {
    name : 'listElement-numberbox',
    props: {
        value   : { type: String, required: false, default: undefined }
    },
    data()
    {
        return {
            maxLength: 5
        }
    }
}
</script>
