<template>
    <div v-if="prepared">
        <!--{{ visibleList }}-->
        <DefaultViewItemList :itemList="visibleList"
                             :viewKey="controlKey"
                             viewItem="Organizer"
                             :filtered="[]"
                             :filterKey="filterKey"
                             :galleryMode="false"
                             :hiddenVisible="hiddenVisible"
                             :viewIsFiltered="viewIsFiltered"
                             :noShareIndicator="noShareIndicator"
                             :selectMode="selectMode"
                             scope="own"
                             @dragging="$emit( 'dragging', $event )"
                             @selected="handleSelect"/>
    </div>
    <PreheatingIndicator v-else/>
</template>

<script>
import PreheatingIndicator from "@/components/elements/indicators/PreheatingIndicator";
import DefaultViewItemList from "@/components/defaults/DefaultViewItemList";

export default {

    name      : "OrganizerSlotList",
    components: { DefaultViewItemList, PreheatingIndicator },

    emits: [ 'dragging' ],

    data()
    {
        return {
            visibleList     : [],
            prepared        : false,
            controlKey      : this.$core.getUuid().generate(),
            filterKey       : this.$core.getUuid().generate(),
            hiddenVisible   : false,
            viewIsFiltered  : false,
            noShareIndicator: false,
            selectMode      : false,
            baseClass       : this.$core.getBaseClassHelper().get( 'organizer' )
        }
    },

    created()
    {
        this.prepare()
    },

    computed:
        {
            viewKey: {
                cache: false,
                get()
                {
                    if( !this.$core.f().valid( this.baseClass )
                        || 'filled' !== this.baseClass.state
                        || false === this.baseClass.registry.cacheKey
                        || false === this.baseClass.registry.archiveKey )
                    {
                        return undefined
                    }
                    return this.baseClass.registry.cacheKey + '-' + this.baseClass.registry.archiveKey
                }
            }
        },

    watch: {
        viewKey: {
            immediate: true,
            handler( newKey, oldKey )
            {

                if( undefined !== oldKey
                    && undefined !== newKey
                    && newKey !== oldKey )
                {
                    this.prepare()
                }
            }
        }
    },

    methods: {

        prepare()
        {
            this.$core
                .getBaseClassHelper()
                .get( 'organizer' )
                .listAll()
                .then( list =>
                {

                    this.visibleList = list
                    this.prepared = true
                    this.controlKey = this.$core.getUuid().generate()

                } )
        },

        handleSelect()
        {

        }

    }
}
</script>