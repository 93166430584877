<template>
    <div class="ContentSlotEditorBoxAdder-left">
        <input type="hidden" :id="'form-element-'+id" :value="JSON.stringify( display )" />
        <TextBox :id="'title-'+id"
                 :placeholder="$core.t( 'organizer-content-slot-title' )"
                 :value="display.title"
                 @update="handleText"/>
    </div>
    <div class="ContentSlotEditorBoxAdder-right">
        <TextBox :id="'length-'+id"
                 :placeholder="$core.t( 'organizer-content-slot-length' )"
                 :value="display.length"
                 @update="handleSize"/>
    </div>
</template>

<script>
import TextBox from "@/components/form/elements/TextBox";

export default {

    name      : "ContentSlotEditorBoxAdder",
    components: { TextBox },
    props     : {
        id          : { type: String, required: true },
        value       : { type: [ Object, String ], required: false },
        showOptional: { type: Boolean, required: false, default: false }
    },

    emits: [ 'update' ],

    data()
    {
        return {
            display: {
                title    : '',
                length   : 1,
                slotIndex: undefined,
                date     : undefined
            }
        }
    },

    created()
    {
        if( undefined !== this.$props.value
            && null !== this.$props.value )
        {
            if( this.$core.f().isObject( this.$props.value ) )
            {
                this.display = this.$props.value
                if( undefined === this.display.length )
                {
                    this.display.length = 1
                }
            }
            else
            {
                try
                {
                    this.display = JSON.parse( this.$props.value )
                    if( undefined === this.display.length )
                    {
                        this.display.length = 1
                    }
                }
                catch( e )
                {
                    this.display = {
                        title    : this.$props.value,
                        length   : 1,
                        slotIndex: undefined,
                        date     : undefined
                    }
                }
            }
        }
    },

    methods: {

        emitUpdate()
        {
            this.$emit( 'update', this.$props.id, 'ContentSlotEditorBoxAdder', 'update', this.$props.id, JSON.stringify( this.display ) )
        },

        handleText( id, type, method, ref, value )
        {
            this.display.title = value ? value.trim() : ''
            this.emitUpdate()
        },

        handleSize( id, type, method, ref, value )
        {
            this.display.length = value ? value.trim() : ''
            this.emitUpdate()
        }

    }
}
</script>

<style scoped>

.ContentSlotEditorBoxAdder-left {
    float: left;
    width: 65%;
}

.ContentSlotEditorBoxAdder-right {
    margin-left: 5%;
    float: left;
    width: 25%;
}

</style>