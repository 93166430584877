<template>
    <transition appear name="slide-fade" v-if="showMessage">
        <div id="software-update" @click="handleClick()">
            <strong>Neue Softwareversion verfügbar</strong>: Auf dem Server steht eine neue Version von
            <strong>entzettelt</strong> bereit: Klicke einfach diese Nachricht an, um sie zu installieren.
        </div>
    </transition>
</template>

<script>

export default {

    name: 'SoftwareUpdate',

    data()
    {
        return {
            showMessage: false,
            timer      : false
        }
    },

    created()
    {
        this.checkVersion()
    },

    mounted()
    {
        this.timer = setInterval( () =>
        {

            this.checkVersion()

        }, 300000 )
    },

    beforeUnmount()
    {
        clearInterval( this.timer )
        this.timer = null
        this.showMessage = null
    },

    methods: {

        checkVersion()
        {
            let version = this.$store.getters.updateVersion
            this.showMessage = 'local' !== this.$core.getConfig().branch && 'build-240' !== this.$core.getConfig().branch
                && false !== version
                && this.$core.getConfig().build !== version
        },

        handleClick()
        {
            this.$core.getUi().performUpdate( true )
            setTimeout( () =>
            {
                this.showMessage = false
            }, 2000 )
        }
    }

}
</script>