export default class OrganizerHelper
{

    constructor( core )
    {
        if( !OrganizerHelper.instance )
        {

            this.logger = core.getLogger()
            this.ui = core.getUi()
            this.f = core.f()
            this.registry = false

            this.slottery = null
            this.timer = false

            OrganizerHelper.instance = this

        }

        return OrganizerHelper.instance

    }

    destruct()
    {
        this.registry = false
        delete OrganizerHelper.instance
    }

    injectRegistry( registry )
    {
        if( false === this.registry )
        {
            this.registry = registry
        }
    }

    _markSlotted( slotted, element )
    {
        if( 'create' !== element.organizerSlot
            && undefined !== element.organizerSlot )
        {

            let slot = JSON.parse( element.organizerSlot )
            if( slot instanceof Object )
            {

                if( undefined === slotted[ slot.organizerSlotId ] )
                {
                    slotted[ slot.organizerSlotId ] = []
                }

                let index = slotted[ slot.organizerSlotId ].map( lookup => lookup.localId ).indexOf( element.localId )
                if( -1 === index )
                {
                    slotted[ slot.organizerSlotId ].push(
                        {
                            localId: element.localId,
                            slot   : slot
                        }
                    )
                }

            }

        }
    }

    /*eslint-disable*/
    readAllSlottedElements()
    {

        if( null === this.slottery )
        {

            let slotted = {},
                all     = this.registry.byId

            for( let a in all )
            {

                let element = all[ a ]
                if( undefined !== element.lists )
                {
                    for( let l in element.lists )
                    {
                        let list = element.lists[ l ]
                        this._markSlotted( slotted, list )
                    }
                }
                else
                {
                    this._markSlotted( slotted, element )
                }
            }

            this.slottery = slotted

            setTimeout( () =>
            {
                this.slottery = null
            }, 1500 )

        }

        return JSON.parse( JSON.stringify( this.slottery ) )

    }

    getSlots( setup )
    {

        let heights     = {
                time    : 30,
                freetext: 20
            },
            totalHeight = 0,
            slots       = []

        for( let s in setup )
        {
            totalHeight += setup[ s ].height || heights[ setup[ s ].type ]
        }

        let percentage = 100 / totalHeight

        for( let s in setup )
        {
            slots.push( {
                type  : setup[ s ].type,
                value : setup[ s ].value,
                height: ( ( setup[ s ].height || heights[ setup[ s ].type ] ) * percentage )
            } )
        }

        return slots

    }

    getSlotConfiguration( organizerId )
    {

        let slots  = this.registry.cache.organizerslots,
            result = {}

        for( let s in slots )
        {

            if( slots[ s ].organizerId === organizerId )
            {
                if( undefined === result[ slots[ s ].day ] )
                {
                    result[ slots[ s ].day ] = {}
                }
                result[ slots[ s ].day ][ slots[ s ].slotIndex ] = slots[ s ]
            }

        }

        return result

    }

}