import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class YeargroupsObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !YeargroupsObjectClass.instance )
        {

            let refList = [ 'yeargroupname', 'color', 'students', 'historicStudents', 'timestamp', 'update' ]

            let personalAttributesStatesList = [
                {
                    storage: 'hiding',
                    state  : 'hidden',
                    list   : 'hideLists'
                }
            ]

            super( core, 'yeargroup', refList, personalAttributesStatesList )

            this.baseClassHelper = core.getBaseClassHelper()

            this.hasDetailView = true
            this.creationDependsOn = [ 'student' ]
            this.objectType = 'yeargroups'
            YeargroupsObjectClass.instance = this

        }

        return YeargroupsObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'yeargroupname',
                caption    : 'Name',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'freetext',
                checkExists: {
                    elements    : 'yeargroup',
                    checkForeign: true
                }
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                styleUpdate: true,
                reformatter: null,
                optional   : false,
                validator  : 'color'
            },
            {
                ref           : 'students',
                caption       : 'Schüler:innen',
                type          : 'RowAdder',
                component     : 'StudentSelector',
                values        : [],
                reformatter   : null,
                optional      : false,
                asPlainList   : true,
                validator     : 'select',
                ignoreClassId : true,
                extendedFilter: true,
                allowForeign  : true,
                dragBlocked   : true,
                unique        : true
            },
            {
                ref        : 'historicStudents',
                caption    : 'historische Schüler:innen',
                type       : 'Hidden',
                component  : 'Hidden',
                values     : [],
                reformatter: null,
                optional   : true,
                validator  : null
            }
        ]
    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return [ 'hide', 'edit', 'share', 'delete' ]
    }

    _updateStudents( allStudents )
    {

        for( let s in allStudents )
        {

            let studentLocalId = allStudents[ s ]
            this.baseClassHelper
                .get( 'student' )
                .refreshCache( studentLocalId )

        }

    }

    update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )
    {

        let yeargroupElement = this.getById( localId ),
            allStudents      = undefined !== yeargroupElement ? JSON.parse( JSON.stringify( yeargroupElement.students ) ) : [],
            id               = super.update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )

        this.eventManager.append( 'storable-after-update-' + id, () =>
        {

            this._checkLongClassnames()

            let yeargroupElement = this.getById( localId ),
                newStudents      = yeargroupElement.students

            for( let n in newStudents )
            {
                if( -1 === allStudents.indexOf( newStudents[ n ] ) )
                {
                    allStudents.push( newStudents[ n ] )
                }
            }

            this._updateStudents( allStudents )

        } )

        return id
    }

    listAll( attach, filter, skipArchived )
    {

        return new Promise( ( resolve, reject ) =>
        {

            super.listAll( attach, filter, skipArchived )
                 .then( result =>
                 {

                     for( let r in result )
                     {
                         result[ r ].displayName = 'Jahrgang: ' + result[ r ].yeargroupname
                     }

                     return resolve( result )

                 } )
                 .catch( error =>
                 {
                     return reject( error )
                 } )

        } )

    }

}