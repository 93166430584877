import MoveToClassHelper from "@/classes/Helpers/MultiEdit/MoveToClass";

export default class MultiEdit
{

    constructor( core )
    {

        if( !MultiEdit.instance )
        {

            this.logger = core.getLogger()
            this.ui = core.getUi()
            this.eventManager = core.getEventManager()
            this.f = core.f()

            this.t = ( key, params ) =>
            {
                return core.t( key, params )
            }

            this.getBaseClassHelper = () =>
            {
                return core.getBaseClassHelper()
            }

            this.baseClass = ( type, listType ) =>
            {
                return core.baseClass( type, listType )
            }

            this.helpers = {
                MoveToClass: new MoveToClassHelper( this )
            }

            MultiEdit.instance = this

        }

        return MultiEdit.instance

    }

    /*eslint-disable*/
    handleMultiEdit( baseClass, editFields, selection )
    {

        let formFields         = [],
            classFormFields    = {},
            originalFormFields = baseClass.getFormFields()

        for( let o in originalFormFields )
        {
            classFormFields[ originalFormFields[ o ].ref ] = originalFormFields[ o ]
        }

        for( let e in editFields )
        {

            let field = editFields[ e ]
            if( field instanceof Object )
            {
                formFields.push( {
                    caption  : this.t( field.element ),
                    optional : true,
                    ref      : field.attribute,
                    value    : selection[ 0 ][ field.attribute ],
                    type     : field.formElement,
                    validator: field.validator,
                    mapped   : editFields[ e ]
                } )
            }
            else
            {

                if( ':' === field.substr( 0, 1 ) )
                {
                    let attribute,
                        type
                    switch( field )
                    {
                        case ':moveToClass':
                            attribute = 'classId'
                            type = 'ClassSelector'
                            break

                    }
                    let formField = {
                        caption  : this.t( 'formField' + field ),
                        optional : true,
                        ref      : field.replace( ':', '____' ),
                        value    : selection[ 0 ][ attribute ],
                        type     : type,
                        validator: null,
                        mapped   : editFields[ e ]
                    }
                    formFields.push( formField )
                }
                else
                {
                    let formField = classFormFields[ field ]
                    formField.value = selection[ 0 ][ formField.ref ]
                    formFields.push( formField )
                }

            }

        }

        let readyKey = this.ui.showFreeForm( this.t( 'form-title-multiedit-' + baseClass.type, [ selection.length ] ), undefined, formFields, true )
        this.eventManager.add( 'on-freeform-submit-' + readyKey, ( values ) =>
        {
            this.handleSubmit( baseClass, formFields, editFields, selection, values )
        } )

    }

    getElementAndBaseClass( localId )
    {
        return new Promise( resolve =>
        {

            this.getBaseClassHelper()
                .getObjectById( localId )
                .then( element =>
                {

                    if( undefined === element )
                    {
                        return resolve()
                    }

                    this.baseClass( element.type, element.listType )
                        .getById( element.localId )
                        .then( element =>
                        {

                            return resolve(
                                {
                                    baseClass: this.baseClass( element.type, element.listType ),
                                    element  : element
                                }
                            )
                        } )
                        .catch( () =>
                        {
                            return resolve()
                        } )

                } )

        } )
    }

    collectNeededElements( selection, formfield, value )
    {
        return new Promise( resolve =>
        {

            let promises = [],
                result   = {
                    source: {},
                    remove: [],
                    target: {},
                    add   : []
                }

            for( let s in selection )
            {

                let sel = selection[ s ]
                promises.push( () =>
                {
                    return this.getElementAndBaseClass( sel[ formfield.ref ] )
                               .then( res =>
                               {
                                   if( undefined !== res
                                       && undefined === result.source[ res.element.localId ] )
                                   {
                                       result.source[ res.element.localId ] = res
                                   }

                                   result.remove.push( {
                                       target: formfield.mapped.targetAttribute,
                                       value : sel[ formfield.mapped.target ]
                                   } )

                               } )
                } )

            }

            promises.push( () =>
            {
                return this.getElementAndBaseClass( value )
                           .then( res =>
                           {
                               if( undefined !== res
                                   && undefined === result.target[ res.element.localId ] )
                               {
                                   result.target[ res.element.localId ] = res
                               }

                               for( let s in selection )
                               {

                                   let sel = selection[ s ]

                                   result.add.push( {
                                       target: formfield.mapped.targetAttribute,
                                       value : sel[ formfield.mapped.target ]
                                   } )

                               }

                           } )
            } )

            this.f.promiseRunner( promises )
                .then( () =>
                {
                    return resolve( result )
                } )

        } )
    }

    prepareUpdates( elements, promises )
    {

        return new Promise( resolve =>
        {

            for( let s in elements.source )
            {

                let element = elements.source[ s ].element
                for( let r in elements.remove )
                {
                    let removal = elements.remove[ r ]
                    let idx = element[ removal.target ].indexOf( removal.value )
                    if( -1 < idx )
                    {
                        element[ removal.target ].splice( idx, 1 )
                    }
                }

                promises.push( () =>
                {
                    return elements.source[ s ].baseClass.update( element, element.localId, element.remoteId, element.timestamp, element.localKey )
                } )

            }

            for( let t in elements.target )
            {

                let element = elements.target[ t ].element
                for( let a in elements.add )
                {
                    let addition = elements.add[ a ]
                    let idx = element[ addition.target ].indexOf( addition.value )
                    if( -1 === idx )
                    {
                        element[ addition.target ].push( addition.value )
                    }
                }

                promises.push( () =>
                {
                    return elements.target[ t ].baseClass.update( element, element.localId, element.remoteId, element.timestamp, element.localKey )
                } )

            }

            return resolve()

        } )
    }

    handleExternalChanges( updates )
    {
        return new Promise( resolve =>
        {

            let helperCalls = {},
                promises    = []

            for( let u in updates )
            {
                switch( updates[ u ].action )
                {
                    case '____moveToClass':
                        helperCalls.MoveToClass = helperCalls.MoveToClass || []
                        helperCalls.MoveToClass.push( updates[ u ] )
                        break
                }
            }

            for( let h in helperCalls )
            {
                promises.push( () => {
                    return this.helpers[h].execute( helperCalls[h] )
                })
            }

            this.f.promiseRunner( promises )
                .then( () => {
                    return resolve()
                })

        } )
    }

    /*eslint-disable*/
    handleSubmit( baseClass, formFields, editFields, selection, values )
    {

        let promises        = [],
            elements        = {},
            prepares        = [],
            externalChanges = [],
            baseUpdated     = false

        this.ui.showBlocker( this.t( 'generic-please-wait' ), this.t( 'generic-changes-processing' ) )

        for( let f in formFields )
        {
            if( undefined === formFields[ f ].mapped
                && undefined !== values[ formFields[ f ].ref ] )
            {
                for( let s in selection )
                {
                    selection[ s ][ formFields[ f ].ref ] = values[ formFields[ f ].ref ]
                    baseUpdated = true
                }
            }
            else
            {
                if( undefined !== formFields[ f ].mapped
                    && undefined !== values[ formFields[ f ].ref ] )
                {
                    if( '___' === formFields[ f ].ref.substr( 0, 3 ) )
                    {
                        /** > SPECIAL FORM FIELD: REFERENCED OBJECT NEEDS TO BE UPDATED < **/
                        for( let s in selection )
                        {
                            externalChanges.push( {
                                element: selection[ s ],
                                action : formFields[ f ].ref,
                                value  : formFields[ f ].value
                            } )
                        }
                    }
                    else
                    {
                        /** > DEFAULT < **/
                        prepares.push( () =>
                        {
                            return this.collectNeededElements( selection, formFields[ f ], values[ formFields[ f ].ref ] )
                                       .then( result =>
                                       {
                                           elements = { ...elements, ...result }
                                       } )
                        } )
                    }
                }
            }
        }

        if( 0 < externalChanges.length )
        {
            promises.push( () =>
            {
                return this.handleExternalChanges( externalChanges )
            } )
        }

        if( baseUpdated )
        {
            for( let s in selection )
            {
                promises.push( () =>
                {
                    return baseClass.update( selection[ s ], selection[ s ].localId, selection[ s ].remoteId, selection[ s ].timestamp, selection[ s ].localKey )
                } )
            }
        }

        this.f.promiseRunner( prepares )
            .then( () =>
            {

                this.prepareUpdates( elements, promises )
                    .then( () =>
                    {

                        this.f.promiseRunner( promises )
                            .then( () =>
                            {

                                this.eventManager.dispatch( 'on-element-handler-reset' )
                                setTimeout( () =>
                                {

                                    this.ui.hideBlocker()

                                }, 2000 )

                            } )

                    } )

            } )

    }

}