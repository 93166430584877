<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="out-in">
        <div v-show="visible && false !== setup" class="default-form-overlay">
            <div class="default-form-container">
                <div class="default-form-wrapper">
                    <Button type="close"
                            @clicked="handleClose()"
                            addClass="absolute top right inverted"/>
                    
                    <h2>{{ setup.title }}</h2>
                    <div class="form">
                        <div class="form-row">
                            <div class="form-caption">
                                <strong>Code</strong>
                            </div>
                            <div class="form-component">
                                <QrCodeReader @ondecode="handleCode"/>
                                <input type="text" placeholder="Code" id="code" @change="handleChange()"
                                       :value="false !== code ? code : ''"/>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="form-row">
                            <div class="form-caption buttons">
                                <button class="cancelbutton"
                                        value="abbrechen"
                                        @click="handleClose()">abbrechen
                                </button>
                            </div>
                            <div class="form-component buttons">
                                <button class="submit"
                                        value="Code validieren"
                                        @click="handleSubmit()">Code validieren
                                </button>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import QrCodeReader        from '@/components/form/elements/QrCodeReader'
export default {
    
    name      : 'DefaultQROverlay',
    components: { QrCodeReader },
    emits     : [ 'close', 'submit' ],
    
    props: {
        setup: { type: [ Object, Boolean ], required: true }
    },
    
    data()
    {
        return {
            visible: true,
            result : false,
            code   : false
        }
    },
    
    methods: {
        
        handleClose()
        {
            this.visible = false
            setTimeout( () =>
            {
                this.$emit( 'close' )
            }, 300 )
        },
        
        handleCode( code )
        {
            
            let parts = code.split( '/' )
            if( 4 < parts.length )
            {
                
                this.code = parts.pop()
                let type = parts.pop()
                this.result = {
                    type: type,
                    code: this.code
                }
                
            }
            
        },
        
        handleChange()
        {
            if( false === this.result )
            {
                let elm = document.querySelector( '#code' )
                if( null !== elm
                    && '' !== elm.value.trim() )
                {
                    this.result = {
                        type: 'undefined',
                        code: elm.value.trim()
                    }
                }
            }
        },
        
        handleSubmit()
        {
            if( false !== this.result )
            {
                this.visible = false
                setTimeout( () =>
                {
                    this.$emit( 'submit', this.result )
                }, 300 )
            }
        }
        
    }
}
</script>