<template>
    <div class="ratesplantselector" :ref="'rateWrap_'+getElementRefName()">
        <div v-for="rate in rates" :key="'plant-'+rate">
            <div @click="setValue( rate )"
                 v-bind:class="'rateplant rate-'+rate+( rate === parseInt( selectedValue ) ? ' active' : '' )">
                <img :src="imgs[rate][ rate === parseInt( selectedValue ) ? 'active' : 'inactive' ]"
                     style="width:70px; height:70px;"/>
            </div>
        </div>
    </div>
</template>

<script>

import MixinResettableComponent from '@/mixins/MixinResettableComponent'

export default {
    name: 'RatePlantSelector',

    mixins: [ MixinResettableComponent ],

    props: {
        refName    : { Type: String, required: true },
        value      : { Type: [ Number, String ], required: false, default: undefined },
        disabled   : { Type: Boolean, required: false },
        highlighted: { Type: Object, required: false, default: [] },
        inline     : { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false }
    },

    emits: [ 'focussed', 'blurred', 'update' ],

    data()
    {
        return {
            rates        : [ 0, 1, 2, 3 ],
            imgs         : [],
            hovering     : false,
            hoverValue   : 0,
            selectedValue: 0
        }
    },

    watch: {
        value: {
            immediate: true,
            handler( newValue, oldValue )
            {

                if( undefined !== oldValue
                    && newValue !== oldValue
                    && newValue !== this.selectedValue )
                {
                    if( '' === newValue )
                    {
                        newValue = 0
                    }
                    this.selectedValue = parseInt( newValue )
                }

            }
        }
    },

    created()
    {
        for( let r in this.rates )
        {
            let rate = this.rates[ r ]
            this.imgs.push( {
                inactive: require( '@/assets/img/icons/ui/ico-rate-plant-' + rate + '-inactive.png' ),
                active  : require( '@/assets/img/icons/ui/ico-rate-plant-' + rate + '.png' )
            } )
        }

        this.$nextTick()
            .then( () =>
            {
                this.setValue( this.$props.value, true )
            } )
    },
    methods: {

        getElementRefName()
        {
            return 'elm_' + this.$props.refName
        },

        getClassName( rate )
        {

            let className = ''
            let compare = this.selectedValue
            if( this.hovering
                && !this.$core.getUi().isTouchDevice() )
            {
                compare = this.hoverValue
            }

            if( rate <= compare )
            {
                className = 'selected'
            }
            else
            {
                className = 'inactive'
            }

            if( -1 < this.highlighted.indexOf( this.$props.refName + '_' + rate ) )
            {
                className = className + ' highlighted'
            }

            return className

        },
        setValue( rate, initial )
        {
            if( initial )
            {
                this.selectedValue = rate
            }

            if( ( undefined !== rate && !initial )
                || ( true !== this.$props.disabled ) )
            {
                if( this.selectedValue === 0 && rate === 0 && !initial )
                {
                    this.selectedValue = undefined
                    rate = undefined
                }
                else
                {
                    this.selectedValue = rate
                }
            }
            if( !initial )
            {
                if( true !== this.$props.disabled )
                {
                    this.$emit( 'update', this.$props.refName, 'rateSelector', 'update', undefined, rate )
                }
            }
        },
        handleChange( event )
        {
            if( true !== this.$props.disabled )
            {

                if( undefined !== event.target
                    && undefined !== event.target.value )
                {
                    let newValue = '' == event.target.value ? 0 : event.target.value
                    this.setValue( newValue, false )
                }

            }
        },
        setHover( rate )
        {
            if( true !== this.$props.disabled )
            {
                this.hovering = true
                this.hoverValue = rate
                this.$emit( 'focussed', this.$props.refName + '_' + rate )
            }
        },
        clearHover()
        {
            this.hovering = false
            this.$emit( 'blurred', 'all:' + this.$props.refName )
        }
    }
}
</script>