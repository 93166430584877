export default class QueueMessageWorker
{

    constructor( core )
    {

        if( !QueueMessageWorker.instance )
        {

            this.interval = 500

            this.logger = core.getLogger()
            this.store = core.getStore()
            this.database = core.getDatabase()
            this.timer = core.getCoreTimer()
            this.client = core.getClient()
            this.ui = core.getUi()
            this.eventManager = core.getEventManager()

            this.counter = 0
            this.triggering = false
            this.heap = []

            this.timer.addInterval( 'queueworker-messages', this.interval, () =>
            {
                this.trigger()
            }, true )

            QueueMessageWorker.instance = this

        }

        return QueueMessageWorker.instance

    }

    destruct()
    {
        this.timer.removeInterval( 'queueworker-messages' )
        delete QueueMessageWorker.instance
    }

    performTrigger( message )
    {
        return new Promise( ( resolve, reject ) =>
        {

            this.client.request( message, 180000 )
                .then( () =>
                {

                    return resolve()

                } )
                .catch( e =>
                {
                    return reject( e )
                } )

        } )
    }

    triggerWalk( retry )
    {

        return new Promise( resolve =>
        {

            if( 0 < this.heap.length )
            {

                let dbMessage = this.heap.shift(),
                    message   = JSON.parse( dbMessage.item.objectJson )

                retry = undefined === retry ? 0 : retry

                this.performTrigger( message )
                    .then( () =>
                    {
                        this.database.delete( 'socketmessages', dbMessage.key )
                            .then( () =>
                            {

                                this.eventManager.dispatch( 'debugger-out', 'socketmessage ' + dbMessage.item.objectJson + ', ' + dbMessage.key )
                                this.counter -= 1
                                if( 0 < this.heap.length )
                                {
                                    this.logger.clog( 'QueueMessageWorker:triggerWalk', dbMessage.key + ' done' )
                                    return resolve( this.triggerWalk() )
                                }
                                else
                                {
                                    return resolve()
                                }

                            } )

                    } )
                    .catch( e =>
                    {

                        this.logger.cerror( 'QueueMessageWorker:trigger', 'failed trigger for message: ' + dbMessage.key, e )
                        if( 3 > retry )
                        {
                            this.logger.clog( 'QueueMessageWorker:triggerWalk', 're-queueing for retry' )
                            this.heap.unshift( dbMessage )
                            retry++
                            return resolve( this.triggerWalk( retry ) )
                        }
                        else
                        {
                            this.database.delete( 'socketmessages', dbMessage.key )
                                .then( () =>
                                {
                                    this.logger.cerror( 'QueueMessageWorker:triggerWalk', 'error is permanent: skipping #' + dbMessage.key )
                                    return resolve( this.triggerWalk() )
                                } )
                        }

                    } )
            }
            else
            {
                return resolve()
            }

        } )
    }

    trigger()
    {

        if( true === this.store.getters.authorized
            && true === this.store.getters.online
            && !this.triggering )
        {

            this.triggering = true
            this.database.readAllObjects( 'socketmessages' )
                .then( entries =>
                {

                    this.heap = JSON.parse( JSON.stringify( entries ) )
                    this.counter = this.heap.length

                    if( 0 < this.counter )
                    {
                        this.logger.clog( 'QueueMessageWorker:trigger', 'working ' + this.counter + ' messages(s) in queue' )
                    }

                    this.triggerWalk()
                        .then( () =>
                        {

                            this.timer.addTimeout( 'queuemessage-after-trigger', 500, () =>
                            {

                                this.ui.setOnlineStatus( 'Online' )

                            } )

                            this.triggering = false
                            delete this.heap
                            this.heap = []

                        } )

                } )

        }

    }

}