import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class NotesObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !NotesObjectClass.instance )
        {

            let refList = [ 'body',
                            'color',
                            'studentReference',
                            'classReference',
                            'groupReference',
                            'yeargroupReference',
                            'labels',
                            'blurry',
                            'pinned',
                            'hidden',
                            'timestamp',
                            'update',
                            'organizerSlot' ]

            let personalAttributesStatesList = [
                {
                    storage: 'pinning',
                    state  : 'pinned',
                    list   : 'pinLists'
                },
                {
                    storage: 'hiding',
                    state  : 'hidden',
                    list   : 'hideLists'
                }
            ]

            super( core, 'note', refList, personalAttributesStatesList )
            this.objectType = 'notes'
            this.isStudentShareable = true

            this.multiEdit = [
                'studentReference',
                'classReference',
                'groupReference',
                'yeargroupReference',
                'color',
                'blurry',
                'labels'
            ]

            NotesObjectClass.instance = this
        }

        return NotesObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'color',
                caption    : 'color',
                translate  : true,
                type       : 'ColorSelector',
                reformatter: null,
                optional   : false,
                validator  : 'color',
                styleUpdate: true
            },
            {
                ref          : 'studentReference',
                caption      : 'zu Schüler:in',
                type         : 'RowAdder',
                component    : 'StudentSelector',
                values       : [],
                allowForeign : true,
                reformatter  : null,
                optional     : true,
                validator    : null,
                styleUpdate  : false,
                extended     : true,
                asPlainList  : true,
                dragBlocked  : true,
                unique       : true,
                ignoreClassId: true
            },
            {
                ref         : 'classReference',
                caption     : 'zu Klasse',
                type        : 'ClassSelector',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false,
                extended    : true
            },
            {
                ref         : 'groupReference',
                caption     : 'zu Gruppe',
                type        : 'GroupSelector',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false,
                extended    : true,
                acl         : 'page_groups'
            },
            {
                ref         : 'yeargroupReference',
                caption     : 'zu Jahrgang',
                type        : 'YeargroupSelector',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false,
                extended    : true,
                acl         : 'page_yeargroups'
            },
            {
                ref        : 'labels',
                caption    : 'Labels',
                type       : 'LabelSelector',
                styleUpdate: false,
                reformatter: null,
                optional   : true,
                extended   : true,
                validator  : 'text'
            },
            {
                ref        : 'blurry',
                caption    : 'geschützt',
                type       : 'Checkbox',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false,
                extended   : true
            },
            {
                ref        : 'body',
                caption    : 'Notiz',
                type       : 'TextArea',
                reformatter: null,
                optional   : false,
                validator  : 'freetext',
                styleUpdate: false
            },
            {
                ref        : 'forceTimestamp',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'pinned',
                caption    : null,
                type       : 'Hidden',
                reformatter: 'boolean',
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'hidden',
                caption    : null,
                type       : 'Hidden',
                reformatter: 'boolean',
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'organizerSlot',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            }
        ]
    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return [ 'pin', 'hide', 'edit', 'share', 'delete' ]
    }

}