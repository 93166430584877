function prepareTemplate( element )
{
    
    let template = {}
    template.type = element.type
    template.color = element.color
    
    switch( element.type )
    {
        case 'list':
            template.headline = element.listname
            template.listType = element.listType
            break
    }
    
    return template
    
}

export default prepareTemplate