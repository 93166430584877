<template>
    <div :class="'media hoverable '+ ( small ? 'small ' : '' ) + item.color+( selected ? ' selected-item' : '' )"
         :id="'printable-'+item.localId"
         :key="'media-'+item.localId+'-'+changeKey">
        <template v-if="true === $store.getters.online">
            <template v-if="false !== image && !processing">
                <div class="preview" :style="background"
                     @click="handleImageZoom">
                    <div v-if="blurred" class="blurred"></div>
                </div>
            </template>
            <template v-else-if="false !== audio && !processing">
                <AudioPlayer :item="audio" :fullSize="true" :emitClick="emitClick" @clicked="handleImageZoom"/>
            </template>
            <template v-else>
                <div class="file-preview-processing-align full">
                    <div class="file-preview-processing"></div>
                </div>
            </template>
        </template>
        <FileBoxOfflineMessage v-if="false === $store.getters.online"
                               :full="true"/>
        <div v-if="!small" class="info-space">
            <div class="markers gallery">
                <ReferenceMarkers :item="item" additionalClass="gallery"/>
            </div>
            <div class="media-caption">
                {{ item.label }}
            </div>
        </div>
        <!--        </div>-->
    </div>
</template>

<script>
import ViewItemHandler       from '@/components/elements/viewItems/viewHandler/ViewItemHandler'
import LastEditor            from '@/components/elements/markers/LastEditor'
import ReferenceMarkers      from '@/components/elements/markers/ReferenceMarkers'
import MixinWatchElementKey  from "@/mixins/MixinWatchElementKey";
import MixinComponentCleanup from "@/mixins/MixinComponentCleanup";
import FileBoxOfflineMessage from "@/components/form/elements/FileBoxOfflineMessage";
import AudioPlayer           from "@/components/elements/defaults/AudioPlayer";
/*eslint-disable*/
export default {

    name      : 'Media',
    components: { AudioPlayer, FileBoxOfflineMessage, ReferenceMarkers, LastEditor },
    extends   : ViewItemHandler,
    mixins    : [ MixinWatchElementKey, MixinComponentCleanup ],
    emits     : [ 'detailView', 'clicked', 'injectHandlers' ],

    props: {
        item      : { type: Object, required: true },
        changeKey : { type: String, required: false },
        elementKey: { type: String, required: false },
        selected  : { type: Boolean, required: false, default: false },
        selectMode: { Type: Boolean, required: false, default: false },
        small     : { Type: Boolean, required: false, default: false },
        emitClick : { Type: Boolean, required: false, default: false }
    },

    watch: {
        "item.elementKey": {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.prepare()
                }
            }
        }
    },

    data()
    {
        return {
            processing: false,
            image     : false,
            audio     : false,
            background: false,
            blurred   : false,
            blurSwap  : false
        }
    },

    beforeUnmount()
    {
        delete this.mediaHelper
    },

    created()
    {
        this.$emit( 'injectHandlers', {
            delete: () =>
            {
                return this.handleDelete()
            }
        } )
    },

    mounted()
    {

        this.prepare()

    },

    methods: {

        prepare()
        {
            this.mediaHelper = this.$core.getMediaHelper()
            this.blurred = this.$props.item.blurry === 1
            this.blurSwap = this.$props.item.blurry === 1

            if( this.$core.f().valid( this.$props.item.fileId ) )
            {
                this.processing = true
                this.mediaHelper.getFileById( this.$props.item.fileId )
                    .then( result =>
                    {
                        switch( result.format )
                        {
                            case 'audio/mp3':
                                this.audio = result.dataUrl
                                break
                            default:
                                this.image = result
                                this.background = 'background-image:url("' + this.image.dataUrl + '")'
                        }
                        this.processing = false
                    } )
            }
        },

        handleImageZoom( event )
        {
            if( !this.$props.selectMode
                && !this.$props.emitClick )
            {
                this.$core.f().skip( event )
                switch( this.blurSwap )
                {
                    case true:
                        if( !this.blurred )
                        {
                            this.$core.getUi()
                                .imageZoom( this.image )
                        }
                        this.blurred = !this.blurred
                        this.$core.getUi().delay( () =>
                        {
                            this.blurred = true
                        }, 2000 )
                        break
                    default:
                        this.$core.getUi()
                            .imageZoom( this.image )
                }
            }
            if( this.$props.emitClick )
            {
                this.$emit( 'clicked' )
            }
        },

        performDelete()
        {

            this.$core
                .getUi()
                .showBlocker( 'Augenblick bitte...', 'Das Dokument wird gelöscht...' )

            this.$core
                .getBaseClassHelper()
                .get( 'media' )
                .delete( this.$props.item.localId )

            setTimeout( () =>
            {
                this.$core
                    .getUi()
                    .hideBlocker()
            }, 500 )

        },

        handleDelete()
        {

            this.$core
                .getUi()
                .showBlocker( 'Augenblick bitte...', 'Es wird überprüft, ob das Dokument in Listen verwendet wird...' )

            setTimeout( () =>
            {

                this.$core.getMediaHelper()
                    .findMediaInLists( this.$props.item.localId )
                    .then( lists =>
                    {

                        this.$core.getUi()
                            .hideBlocker()

                        setTimeout( () =>
                        {

                            if( 0 < lists.length )
                            {
                                this.$core
                                    .getUi()
                                    .showModalDialog( 'delete',
                                        'Dokument in Liste gefunden',
                                        'Du möchtest ein Dokument löschen, dass du aktuell in <strong>' + lists.length + '</strong> Liste'
                                        + ( 1 < lists.length ? 'n' : '' ) + ' verwendest.<br/><br/>' +
                                        'Wenn du es jetzt löschst, wird es auch aus allen Listen entfernt, in denen es verwendet wird.',
                                        [
                                            'defaultCancel',
                                            {
                                                type    : 'delete',
                                                title   : this.$core.t( 'button-title-delete' ),
                                                callback: () =>
                                                {
                                                    this.performDelete()
                                                }
                                            }
                                        ] )
                            }
                            else
                            {

                                let title = this.$core.t( 'object-type-media' ) + ' ' + this.$core.t( 'delete' )

                                this.$core
                                    .getUi()
                                    .showModalDialog( 'delete',
                                        title,
                                        'Bist du dir sicher, dass du das Dokument löschen möchtest?',
                                        [
                                            'defaultCancel',
                                            {
                                                type    : 'delete',
                                                title   : this.$core.t( 'button-title-delete' ),
                                                callback: () =>
                                                {
                                                    this.performDelete()
                                                }
                                            }
                                        ] )
                            }

                        }, 500 )

                    } )

            }, 500 )

        }
    }

}
</script>