<template>
    <td v-for="( field, index ) in fields"
        :class="'td-'+role+' td-numberbox'"
        :key="'th-calc-'+index+'-'+viewKey">
        <span><strong>{{ field }}</strong></span>
    </td>
</template>

<script>
export default {

    name : "CalculationTableFields",
    props: {
        data   : { Type: Object, required: true },
        list   : { Type: Object, required: true },
        viewKey: { Type: String, required: true },
        role   : { Type: String, required: false, default: '' }
    },

    data()
    {
        return {
            fields: []
        }
    },

    watch: {
        viewKey: {
            immediate: true,
            handler()
            {
                this.prepare()
            }
        }
    },

    methods: {

        format( number )
        {

            let test = this.$core.getReformatter().localizedFloat( number, 2 )
            if( -1 < test.indexOf( ',' ) )
            {
                let temp = test.split( /,/g )
                {
                    if( 0 === parseInt( temp[ 1 ] ) )
                    {
                        return number
                    }
                }
                return test
            }
            return number

        },

        prepare()
        {

            this.fields = []
            let list = undefined !== this.$props.list.lists ? this.$props.list.lists[ 0 ] : this.$props.list
            if( this.$core.f().valid( list.calculatedColumns )
                && 'create' !== list.calculatedColumns )
            {

                let lookup = this.$props.data.summary

                for( let c in list.calculatedColumns )
                {
                    let set = {
                        count  : 0,
                        sum    : 0,
                        average: 0
                    }

                    for( let f in list.calculatedColumns[ c ].values )
                    {
                        let identifier = this.$core.getSanitizers().cleanId( list.calculatedColumns[ c ].values[ f ] ) + '___'

                        for( let key in lookup.counts.byColumn )
                        {
                            if( -1 < key.indexOf( identifier ) )
                            {
                                set.count += null !== lookup.counts.byColumn[ key ] ? lookup.counts.byColumn[ key ] : 0
                                set.sum += null !== lookup.summary.byColumn[ key ] ? lookup.summary.byColumn[ key ] : 0
                            }
                        }

                    }

                    set.average = 0 < set.count ? set.sum / set.count : 0

                    switch( list.calculatedColumns[ c ].calcType )
                    {
                        case 'count':
                            this.fields.push( set.count )
                            break
                        case 'sum':
                            this.fields.push( this.format( set.sum ) )
                            break
                        case 'average':
                            this.fields.push( this.format( set.average ) )
                            break
                    }

                }

            }

        }
    }

}
</script>