<template>
    <div class="snowflakes" aria-hidden="true">
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Snowflakes"
}
</script>