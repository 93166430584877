export default class AbstractShadowCopyClass
{

    constructor( parent, checkReference, type, logSign )
    {

        this.parent = parent
        this.type = type
        this.checkReference = checkReference
        this.logSign = 'SyncShadowCopies::_shadowHelper::' + logSign

        return this

    }

    _shadowLocalId( shadowCopy )
    {
        return 'shad-'
               + shadowCopy.referenceLocalId + '-'
               + this.parent.f.hashCyrB53( shadowCopy.studentLocalId )
    }

    check( shadowCopy )
    {

        return new Promise( resolve =>
        {

            this.parent
                .baseClassHelper
                .get( this.type )
                .listAll()
                .then( () =>
                {

                    let shadowLocalId = this._shadowLocalId( shadowCopy ),
                        copyElement = this.parent.baseClassHelper.get( this.type ).registry.shadowCopies.get( shadowLocalId ),
                        original    = this.parent.baseClassHelper.get( this.type ).registry.cache.get( shadowCopy.referenceLocalId ),
                        result      = {
                            shadowLocalId: shadowLocalId,
                            localId      : shadowCopy.localId,
                            type         : this.type,
                            action       : 'keep'
                        }

                    if( undefined !== copyElement
                        && undefined !== original )
                    {
                        if( ( original.update > 0 && undefined === copyElement.update )
                            || original.update > copyElement.update )
                        {
                            result.action = 'update'
                            result.copy = shadowCopy
                            result.original = original
                        }
                    }
                    else
                    {
                        if( undefined !== original )
                        {
                            result.action = 'create'
                            result.copy = null
                            result.original = original
                        }
                        else
                        {
                            result.action = 'drop'
                            result.copy = null
                            result.original = original
                        }
                    }

                    return resolve( result )

                } )


        } )

    }

    /*eslint-disable*/
    prepareShadowCopyElement( job )
    {

        return new Promise( ( resolve, reject ) => {

            this.parent.database.readObject( job.original.localId )
                .then( dbElement =>
                {

                    this.parent.cryptoHelper.decrypt( dbElement.object )
                        .then( element =>
                        {

                            if( false === element )
                            {
                                return reject()
                            }

                            let shadowCopy = JSON.parse( JSON.stringify( element ) )

                            delete shadowCopy.remoteId

                            shadowCopy.shadowedId = job.original.localId
                            shadowCopy.localId = 'shad-'
                                                 + job.original.localId + '-'
                                                 + this.parent.f.hashCyrB53( job.access.studentLocalId )
                            shadowCopy.isShadowCopy = true
                            shadowCopy.idOwner = job.access.idUser
                            shadowCopy.isStudentEditable = null !== job.copy && job.copy.studentEditable === true

                            return resolve( shadowCopy )

                        } )

                })

        })

    }

    encryptShadowCopyElement( job, shadowCopy )
    {

        return new Promise( ( resolve, reject ) => {

            this.parent.cryptoHelper.encryptObject(
                this.parent.store.getters.publicKey,
                JSON.stringify( shadowCopy ),
                undefined,
                undefined,
                undefined,
                [
                    {
                        uuid     : job.access.deviceUuid,
                        publicKey: job.access.keys[ 0 ].public
                    }
                ]
            ).then( encryptedObject =>
            {

                if( false !== encryptedObject )
                {

                    return resolve(
                        {
                            localId : shadowCopy.localId,
                            type    : this.type,
                            dbObject: {
                                idOwner: job.access.idUser,
                                keys   : encryptedObject.keys,
                                object : encryptedObject.object
                            }
                        } )

                }
                else
                {
                    return reject()
                }

            } )

        })

    }

    create( job )
    {

        return new Promise( ( resolve, reject ) => {

            if( undefined === job.access )
            {
                return reject()
            }

            this.prepareShadowCopyElement( job )
                .then( shadowCopy => {

                    this.encryptShadowCopyElement( job, shadowCopy )
                        .then( result => {

                            return resolve( { plain: shadowCopy, db: result } )

                        })
                        .catch( () => {
                            return reject()
                        })

                })

        })

    }

    update( job )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.create( job )
                .then( result =>
                {
                    return resolve( result )
                } )
                .catch( e => {
                    return reject( e )
                })

        } )
    }
}