<template>
    <div class="logo-wrapper" id="logo">
        <div class="logo" id="logo-inner" :class="$store.getters.authorized === true ? 'authorized' : 'unauthorized'">
            <div class="logo_clickable" id="logo_clickable" @click="navigateHome()">

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name    : 'Logo',
    methods : {
        navigateHome()
        {
            if( this.$route.name !== 'dashboard' )
            {
                if( this.$store.getters.authorized === true )
                {
                    this.$router.push( '/dashboard' )
                } else
                {
                    this.$router.push( '/' )
                }
            }
        }
    }
}
</script>