<template>
    <div>
        <div class="select-wrapper">
            <select :class="'color-selector '+( hasErrors ? 'error ' : '' )+selectedValue" :id="'form-element-'+refName"
                    @change="checkStyleUpdate()"
                    @prefill="checkStyleUpdate()" @click="skip">
                <option value="" :disabled="undefBlocked === true">{{ undefCaption || $core.t( 'generic-please-select' ) }}</option>
                <option v-if="useDefault"
                        :value="'default'"
                        :selected="value === 'default' || value === defaultColorCode"
                        :class="defaultColorCode">Standard nutzen
                </option>
                <option v-for="color in colors"
                        :class="color.value"
                        :key="'key_option_'+color.value"
                        :value="color.value">{{ color.caption }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>

export default {
    name : 'ColorSelector',
    props: {

        value           : { Type: String, required: false },
        refName         : { Type: String, required: true },
        validator       : { Type: String, required: false },
        reformatter     : { Type: String, required: false },
        hasErrors       : { Type: Boolean, required: false },
        display         : { Type: String, required: false },
        undefBlocked    : { Type: Boolean, required: false },
        configScope     : { Type: Boolean, required: false },
        eventKey        : { Type: String, required: false },
        scope           : { Type: String, required: false },
        useDefault      : { Type: Boolean, required: false, default: false },
        defaultColorCode: { Type: String, required: false },
        undefCaption    : { Type: String, required: false }

    },

    created()
    {

        this.prepareColors()
        this.selectedValue = '' + this.$props.value

    },
    mounted()
    {
        this.formElm = document.querySelector( '#form-element-' + this.$props.refName )
        this.allElms = document.querySelectorAll( '.color-filter' )
        this.$nextTick()
            .then( () =>
            {
                this.select()
            } )
    },
    data()
    {
        return {
            colors       : [],
            formElm      : false,
            allElms      : false,
            selectedValue: false
        }
    },
    watch  : {
        display: {
            deep: true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.$forceUpdate()
                }
            }
        }
    },
    methods: {

        prepareColors()
        {

            let colors       = [ ...this.$core.settings().getSetting( 'allColors' ) ],
                colorCount   = parseInt( this.$core.settings().getSetting( 'colorCount' ) ),
                translations = this.$core.settings().getDefaultSetting( 'colorTranslation' ),
                result       = []

            if( undefined !== this.$props.scope )
            {

                let test = this.$core.settings().getSetting( 'colorTranslation' )
                if( this.$core.f().isset( test[ this.$props.scope ] ) )
                {
                    let trans = {}
                    for( let t in translations )
                    {
                        let newString = translations[ t ]
                        let transl = test[ this.$props.scope ][ t ] || translations[ t ]
                        if( newString !== transl.trim() )
                        {
                            newString = transl.trim() + ' (' + translations[ t ] + ')'
                        }
                        trans[ t ] = newString
                    }
                    translations = trans
                }

            }

            if( this.$props.configScope === true )
            {
                for( let c in colors )
                {
                    result.push(
                        {
                            value  : colors[ c ],
                            color  : colors[ c ],
                            caption: translations[ colors[ c ] ]
                        } )
                }
            }
            else
            {
                let i = 0
                for( let c in colors )
                {
                    if( i < colorCount )
                    {
                        result.push(
                            {
                                value  : colors[ c ],
                                color  : colors[ c ],
                                caption: translations[ colors[ c ] ]
                            } )
                        i++
                    }

                }
            }

            this.colors = this.$core.sort().sortObjects( result, 'color', 'ascending' )

        },

        select()
        {
            if( null === this.formElm )
            {
                return
            }
            if( undefined !== this.$props.value )
            {
                if( true === this.$props.useDefault
                    && this.$props.value === this.$props.defaultColorCode )
                {
                    this.formElm.value = 'default'
                }
                else
                {
                    this.formElm.value = this.$props.value
                }
            }
            else
            {
                this.formElm.value = this.getFilterColor()
            }

            this.checkStyleUpdate()
        },

        getFilterColor()
        {

            let filter = false

            for( let e in this.allElms )
            {
                if( undefined !== this.allElms[ e ].classList
                    && this.allElms[ e ].classList.contains( 'active' ) )
                {
                    filter = this.allElms[ e ]
                }
            }

            for( let c in this.colors )
            {

                let color = this.colors[ c ].value
                if( false !== filter )
                {
                    if( filter.classList.contains( color ) )
                    {
                        return color
                    }
                }
            }
            return ''

        },

        skip( event )
        {
            event.stopPropagation()
        },

        checkStyleUpdate()
        {

            if( null !== this.formElm )
            {
                this.$emit( 'changed', this.formElm.value )
                this.$emit( 'update', this.$props.refName, 'colorSelector', 'update', undefined, this.formElm.value )
                this.selectedValue = this.formElm.value

                if( false !== this.$props.eventKey )
                {
                    this.$core.getEventManager()
                        .dispatch( 'on-color-selected-' + this.$props.eventKey, this.formElm.value )
                }

            }
        }
    }

}
</script>