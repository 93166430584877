/*eslint-disable*/
function _fromHtml( text )
{

    text = text.replace( /<!--([a-z- ]+)-->/g, "" )
    text = text.replace( /<!---->/g, "" )
    text = text.replace( /<span class="(bold|rotated)">/g, "" )
    text = text.replace( /<(u|span|strong|small|em|ul)>/g, "" )
    text = text.replace( /<\/(u|span|strong|small|ul|em)>/g, "" )
    text = text.replace( /<\/li>/g, "<br/>" )
    text = text.replace( /<li>/g, '• ' )
    text = text.replace( /&nbsp;/g, ' ' )
    text = text.replace( /<div class="button opener-button hoverable listeditor-show-edit-all" id="toggle-edit-all">(.*)<\/div>/g, '' )

    return text

}

function _isComment( text )
{
    return text === 'Kommentar / Notiz'
}

function _getSubheaderDimensions( doc, subheaders )
{

    let dimensions = [],
        width      = 0,
        height     = 0

    for( let s in subheaders )
    {
        if( null !== subheaders[ s ] )
        {
            let dims = doc.getTextDimensions( subheaders[ s ] )
            if( dims.w > width )
            {
                width = Math.ceil( dims.w )
            }
            if( dims.h > height )
            {
                height = Math.ceil( dims.h )
            }
        }
    }

    // add padding
    width = 0 < width ? width + 2 : width

    for( let s in subheaders )
    {
        dimensions.push( null !== subheaders[ s ] ? { w: width, h: height } : null )
    }

    return dimensions

}

function _getHeaders( element, doc, cutmarks )
{

    let headers         = [],
        subheaders      = [],
        hasCommentRow   = false,
        commentPosition = -1

    let thead = element.querySelectorAll( 'thead' )
    for( let thd in thead )
    {
        let theadElm = thead[ thd ]
        if( theadElm instanceof HTMLElement )
        {
            let thList = theadElm.querySelectorAll( 'th' )
            for( let t in thList )
            {
                let th = thList[ t ]
                if( th instanceof HTMLElement )
                {
                    if( 0 < t )
                    {

                        let caption = _fromHtml( th.innerHTML )

                        if( !_isComment( caption ) || !cutmarks )
                        {
                            let temp = caption.split( '<br><span class="score-heading">' )
                            if( 1 < temp.length )
                            {
                                subheaders.push( temp[ 1 ] )
                                caption = temp[ 0 ]
                                caption = caption.split( '<br>' ).reverse()
                                headers.push( caption )
                            }
                            else
                            {
                                subheaders.push( null )
                                caption = caption.split( '<br>' ).reverse()
                                headers.push( caption )
                            }
                        }
                        else
                        {
                            commentPosition = t
                            hasCommentRow = true
                        }
                    }
                    else
                    {
                        headers.push( th.innerText )
                        subheaders.push( null )
                    }
                }
            }

        }
    }

    let subheaderDimensions = _getSubheaderDimensions( doc, subheaders ),
        dimensions          = []

    for( let h in headers )
    {
        let dim = doc.getTextDimensions( headers[ h ] )
        dimensions.push( { w: dim.w, h: dim.h } )
    }

    return {
        headers        : headers,
        subheaders     : subheaders,
        dimensions     : dimensions,
        subdimensions  : subheaderDimensions,
        hasCommentRow  : hasCommentRow,
        commentPosition: commentPosition
    }

}

function _getTextboxCount( tdList )
{
    let count = 0
    for( let td in tdList )
    {
        if( tdList[ td ] instanceof HTMLElement )
        {
            if( tdList[ td ].classList.contains( 'td-textarea' ) )
            {
                count++
            }
            if( tdList[ td ].classList.contains( 'td-textbox' ) )
            {
                count++
            }
        }
    }

    return count
}

function _getDef( doc, td, text, maxTextWidth, isComment )
{

    if( undefined !== td.classList )
    {
        if( td.classList.contains( 'td-checkbox' ) )
        {

            let checkbox = td.querySelector( 'input' )
            let list = []
            if( null !== checkbox )
            {
                list.push( 'check_' + ( checkbox.checked ? '' : 'un' ) + 'checked.png' )
                return {
                    def      : 'checkbox',
                    type     : 'image',
                    images   : list,
                    imageSize: 3,
                    padding  : 0,
                    text     : undefined
                }
            }

            checkbox = td.querySelector( '.checkmark' )
            if( null !== checkbox )
            {
                let checked = checkbox.classList.contains( 'checked' )
                list.push( 'check_' + ( checked ? '' : 'un' ) + 'checked.png' )
            }

            return {
                def      : 'checkbox',
                type     : 'image',
                images   : list,
                imageSize: 3,
                padding  : 0,
                text     : undefined
            }

        }

        if( td.classList.contains( 'has-image' ) )
        {

            let list = [
                td.querySelector( 'img' ).src
            ]
            return {
                def      : 'image-src',
                type     : 'image',
                images   : list,
                imageSize: 60,
                padding  : 7,
                text     : undefined
            }
        }

        if( td.classList.contains( 'td-rateplantselector' ) )
        {
            let elems = td.querySelectorAll( '.rateplant' )
            if( 0 === elems.length )
            {
                elems = td.querySelectorAll( '.rateplant-display' )
            }
            let list = []
            for( let e in elems )
            {
                if( elems[ e ] instanceof HTMLElement )
                {
                    for( let i = 0; i < 4; i++ )
                    {
                        let className = 'rate-' + i
                        let image = 'ico-rate-plant-' + i
                        if( elems[ e ].classList.contains( className ) )
                        {
                            list.push( image + ( elems[ e ].classList.contains( 'active' ) ? '' : '-inactive' ) + '.png' )
                        }
                    }
                }
            }

            return {
                def      : 'rateplant',
                type     : 'image',
                images   : list,
                imageSize: 10,
                padding  : 7,
                text     : undefined
            }
        }

        if( td.classList.contains( 'td-ratesmileselector' )
            || td.classList.contains( 'td-ratesmileselectorreverse' ) )
        {
            let elems = td.querySelectorAll( '.ratesmile' )
            if( 0 === elems.length )
            {
                elems = td.querySelectorAll( '.ratesmile-display' )
            }
            let list = []
            for( let e in elems )
            {
                if( elems[ e ] instanceof HTMLElement )
                {
                    for( let i = 0; i < 5; i++ )
                    {
                        let className = 'rate-' + i
                        let image = 'smile-' + i + '-'
                        if( elems[ e ].classList.contains( className ) )
                        {
                            list.push( image + ( elems[ e ].classList.contains( 'active' ) ? '' : 'in' ) + 'active.png' )
                        }
                    }
                }
            }

            return {
                def      : 'ratesmile',
                type     : 'image',
                images   : list,
                imageSize: 10,
                padding  : 7,
                text     : undefined
            }
        }

        if( td.classList.contains( 'td-rateselector' ) )
        {


            let inactiveStars = td.querySelectorAll( '.inactive' ),
                activeStars   = td.querySelectorAll( '.selected' ),
                list          = []

            for( let i = 0; i < activeStars.length; i++ )
            {
                list.push( 'star_active.png' )
            }
            for( let i = 0; i < inactiveStars.length; i++ )
            {
                list.push( 'star_inactive.png' )
            }

            return {
                def      : 'rateselector',
                type     : 'image',
                images   : list,
                imageSize: 3,
                padding  : 0,
                text     : undefined
            }
        }
        if( td.classList.contains( 'td-datebox' ) )
        {
            let text = td.querySelector( 'input' )
            if( null !== text && undefined !== text.value && 'undefined' !== text.value )
            {
                let textOut = text.value
                return {
                    def   : 'text',
                    type  : 'text',
                    width : 20,
                    images: undefined,
                    text  : textOut
                }
            }
        }
        if( td.classList.contains( 'td-scorebox' )
            || td.classList.contains( 'td-numberbox' )
            || td.classList.contains( 'td-smallnumberbox' ) )
        {
            let text = td.querySelector( 'input' )
            if( null !== text && undefined !== text.value && 'undefined' !== text.value )
            {
                let textOut = text.value
                return {
                    isScore: true,
                    def    : 'text',
                    type   : 'text',
                    images : undefined,
                    text   : textOut
                }
            }
            let textOut = td.innerText
            if( undefined !== textOut && null !== textOut )
            {
                return {
                    isScore: true,
                    def    : 'text',
                    type   : 'text',
                    images : undefined,
                    text   : textOut
                }
            }
        }

        if( td.classList.contains( 'td-threewaytoggle' ) )
        {
            let text = td.querySelector( '.threeway_toggle_text' )
            if( null !== text && undefined !== text.innerText )
            {
                let textOut = text.innerText,
                    color,
                    style
                switch( textOut )
                {
                    case 'JA':
                        textOut = 'ja'
                        style = 'bold'
                        color = [ 0, 220, 0 ]
                        break
                    case 'NEIN':
                        textOut = 'nein'
                        style = 'bold'
                        color = [ 220, 0, 0 ]
                        break
                    default:
                        textOut = 'keine Angabe'
                        style = 'normal'
                        color = undefined
                        break
                }
                return {
                    def   : 'text',
                    type  : 'text',
                    images: undefined,
                    color : color,
                    style : style,
                    text  : [ textOut ]
                }
            }
        }
        if( td.classList.contains( 'td-vert-header' ) )
        {
            return {
                def   : 'header',
                type  : 'text',
                images: undefined,
                text  : text
            }
        }
        if( td.classList.contains( 'td-textarea' )
            || td.classList.contains( 'td-textbox' ) )
        {
            let text = td.querySelector( 'input' )
            if( null !== text && undefined !== text.value )
            {
                let textOut = _fromHtml( 'undefined' !== text.value ? text.value : '' )
                textOut = textOut.replace( /<br>/g, '<br/>' )
                let lines = textOut.split( '<br/>' )
                let toSplit = lines.join( "\n" )
                let splitText = doc.splitTextToSize( toSplit, maxTextWidth )

                return {
                    def   : 'text',
                    type  : 'text',
                    width : maxTextWidth,
                    images: undefined,
                    text  : splitText
                }
            }

            let span = td.querySelector( 'span' )
            if( null !== span && undefined !== span.innerHTML )
            {
                let textOut = _fromHtml( 'undefined' !== span.innerHTML ? span.innerHTML : '' )
                textOut = textOut.replace( /<br>/g, '<br/>' )
                let lines = textOut.split( '<br/>' )
                let toSplit = lines.join( "\n" )
                let splitText = doc.splitTextToSize( toSplit, maxTextWidth )

                return {
                    def   : 'text',
                    type  : 'text',
                    width : maxTextWidth,
                    images: undefined,
                    text  : splitText
                }
            }
        }
        if( isComment )
        {
            if( null !== text && undefined !== text )
            {
                let textOut = _fromHtml( text )
                textOut = textOut.replace( /<br>/g, '<br/>' )
                let lines = textOut.split( '<br/>' )
                let toSplit = lines.join( "\n" )
                let splitText = doc.splitTextToSize( toSplit, maxTextWidth )

                return {
                    def   : 'text',
                    type  : 'text',
                    width : maxTextWidth,
                    images: undefined,
                    text  : splitText
                }
            }
        }
    }

    let textOut = _fromHtml( text )
    textOut = textOut.replace( /<br>/g, '<br/>' )
    let lines = textOut.split( '<br/>' )
    let toSplit = lines.join( "\n" )
    let splitText = doc.splitTextToSize( toSplit, maxTextWidth )

    return {
        def   : 'text',
        type  : 'text',
        images: undefined,
        text  : splitText,
        pre   : td.classList.contains( 'font-courier' )
    }
}

function _getRows( element, doc, maxWidth, remain, commentPosition, firstW )
{

    let rows        = [],
        defs        = [],
        img         = [],
        tbody       = element.querySelectorAll( 'tbody' ),
        rowNumber   = 0,
        hasComments = -1 < commentPosition

    firstW = firstW || 50

    for( let tbd in tbody )
    {
        let tbodyElm = tbody[ tbd ]

        if( tbodyElm instanceof HTMLElement )
        {
            let trList = tbodyElm.querySelectorAll( 'tr' )
            for( let t in trList )
            {

                let commentAppendRows = [],
                    commentAppendDefs = []

                let tr = trList[ t ]
                if( tr instanceof HTMLElement
                    && !tr.classList.contains( 'editall' )
                    && !tr.classList.contains( 'hidden' ) )
                {
                    img[ rowNumber ] = []
                    let row          = [],
                        d            = [],
                        tdList       = tr.querySelectorAll( 'td' ),
                        maxTextWidth = 50

                    if( undefined !== remain )
                    {
                        let textBoxCount = _getTextboxCount( tdList )
                        maxTextWidth += ( ( remain / textBoxCount ) - 4 ) //padding
                    }

                    let fieldNumber = 0

                    for( let td in tdList )
                    {
                        if( tdList[ td ] instanceof HTMLElement )
                        {
                            if( td !== commentPosition )
                            {
                                img[ rowNumber ][ fieldNumber ] = []
                                let tw  = '0' !== td ? maxTextWidth : firstW,
                                    def = _getDef( doc, tdList[ td ], tdList[ td ].innerText, tw )

                                d.push( def )
                                if( undefined !== def.images )
                                {
                                    for( let i in def.images )
                                    {
                                        img[ rowNumber ][ fieldNumber ].push( {
                                            padding: def.padding,
                                            size   : def.imageSize,
                                            src    : def.def === 'image-src',
                                            img    : def.images[ i ]
                                        } )
                                    }
                                }
                                row.push( def.text )
                                fieldNumber++
                            }
                            else
                            {
                                let text = 'Kommentar / Notiz'
                                let defFirst = {
                                    def   : 'text',
                                    type  : 'text',
                                    images: undefined,
                                    text  : text
                                }

                                commentAppendDefs.push( defFirst )
                                commentAppendRows.push( text )

                                let width     = ( maxWidth - firstW - 10 ),
                                    defSecond = _getDef( doc, tdList[ td ], tdList[ td ].innerHTML, width, true )
                                commentAppendDefs.push( defSecond )
                                commentAppendRows.push( defSecond.text )
                            }

                        }
                    }

                    defs.push( d )
                    rows.push( row )
                    if( 0 < commentAppendRows.length )
                    {
                        rows.push( commentAppendRows )
                        defs.push( commentAppendDefs )
                    }
                    rowNumber++

                }
            }

        }
    }

    let dimensions = [],
        factor     = hasComments ? 2 : 1

    for( let r in rows )
    {
        let dim = []
        for( let f in rows[ r ] )
        {
            let dims = { w: 0, h: 0 }

            switch( defs[ r ][ f ].type )
            {
                case 'text':
                    let test = ( rows[ r ][ f ] instanceof Array ) ? rows[ r ][ f ] : ( '' + rows[ r ][ f ] ).split( /\n/g ),
                        d2   = doc.getTextDimensions( test ),
                        d1   = doc.getTextDimensions( rows[ r ][ f ] )

                    dims = {
                        w: d2.w > d1.w ? d2.w : d1.w,
                        h: d2.h > d1.h ? d2.h : d1.h
                    }
                    break
                case 'image':
                    dims = { w: ( img[ r ][ f ].length * defs[ r ][ f ].imageSize ), h: defs[ r ][ f ].imageSize }
                    break
            }
            dim.push( { w: dims.w, h: dims.h } )

        }
        dimensions.push( dim )
    }

    return {
        rows      : rows,
        defs      : defs,
        images    : img,
        dimensions: dimensions
    }

}

function _getMaxHeaderHeight( dimensions, subdimensions )
{

    let maxHeight = 10
    for( let d in dimensions )
    {
        if( d !== 0 && dimensions[ d ].w > maxHeight )
        {
            maxHeight = dimensions[ d ].w + 3
        }
    }

    if( subdimensions instanceof Array )
    {
        let maxSub = 0
        for( let s in subdimensions )
        {
            if( null !== subdimensions[ s ] )
            {
                maxSub = subdimensions[ s ].h + 2
                break
            }
        }
        if( 0 < maxSub )
        {
            maxHeight += maxSub
        }
    }

    return maxHeight

}

function _getMaxRowHeight( dimensions )
{

    let maxHeight = 7
    for( let d in dimensions )
    {
        if( d !== 0 && dimensions[ d ].h > maxHeight )
        {
            maxHeight = dimensions[ d ].h + 4
        }
    }
    return maxHeight

}

function _prepareHeader( list, doc, y, maxWidth, color, header, punchMarks, landscape )
{

    let lineX        = [],
        pmAddX       = ( punchMarks && !landscape ? 10 : 0 ),
        pmAddY       = ( punchMarks && landscape ? 10 : 0 ),
        headerHeight = _getMaxHeaderHeight( list.headers.dimensions, list.headers.subdimensions ) + pmAddY,
        headerSetup  = {
            headerHeight : headerHeight,
            color        : color,
            rect         : {
                x: ( 10 + pmAddX ), y: y, x2: ( maxWidth - ( pmAddX + 10 ) ), y2: headerHeight, type: 'F'
            },
            headerFields : [],
            subHeaders   : [],
            subDimensions: header.subdimensions,
            lines        : []
        },
        headY        = ( y + headerHeight - 1.5 ),
        firstX       = pmAddX,
        widths       = _getColumnWidths( doc, maxWidth, list.rows, header.commentPosition, headerSetup.subDimensions, punchMarks, landscape )

    if( null === widths )
    {
        return null
    }

    for( let h in list.headers.headers )
    {

        let x = 10 + pmAddX
        if( h > 0 )
        {
            let half = widths.w[ h ] / 2
            x = widths.x[ h ] + half - ( list.headers.dimensions[ h ].h / 2 ) + 3
            if( pmAddX === firstX )
            {
                firstX = widths.w[ h ] + 10 // first x offset
            }
        }

        headerSetup.headerFields.push( {
            x    : ( parseInt( h ) === 0 ? ( 12 + pmAddX ) : ( x + pmAddX ) ),
            y    : headY,
            text : list.headers.headers[ h ],
            angle: ( parseInt( h ) === 0 ? 0 : 90 )
        } )

    }

    for( let s in list.headers.subheaders )
    {

        let x = 10 + pmAddX
        if( null !== list.headers.subdimensions[ s ] )
        {

            if( parseInt( s ) > 0 )
            {
                let half = widths.w[ s ] / 2
                x = widths.x[ s ] + half - ( list.headers.subdimensions[ s ].w / 2 ) + pmAddX
            }
            headerSetup.subHeaders.push( {
                x    : x + 2,
                y    : headY,
                text : list.headers.subheaders[ s ],
                angle: 0
            } )

        }

    }

    for( let h in list.headers.headers )
    {

        if( 0 < parseInt( h ) )
        {

            let x = widths.x[ h ] + pmAddX

            lineX.push( x )
            headerSetup.lines.push( {
                x : x,
                y : y,
                x2: x,
                y2: ( y + headerHeight )
            } )

        }

    }

    return {
        headerY    : headerHeight,
        lineX      : lineX,
        headerSetup: headerSetup
    }

}

function _getFirstColWidth( doc, rows )
{

    let padding   = 2,
        firstColW = 5

    for( let r in rows.rows )
    {

        let test = rows.rows[ r ][ 0 ],
            d    = doc.getTextDimensions( test )

        if( d.w > firstColW )
        {
            firstColW = d.w
        }

    }

    firstColW = Math.ceil( firstColW + ( padding * 2 ) )
    return firstColW

}

function _getRemainingSpace( doc, rows, room, commentPosition, subheaders )
{

    let firstColW = _getFirstColWidth( doc, rows ),
        padding   = 2,
        minW      = 8,
        maxW      = [],
        factor    = -1 < commentPosition ? 2 : 1

    /* get field widths of remaining columns */
    for( let r in rows.rows )
    {
        let row = rows.rows[ r ]
        if( r % factor === 0 )
        {
            for( let f in row )
            {
                if( 0 === parseInt( f ) )
                {
                    maxW[ f ] = firstColW
                }
                else
                {
                    let fieldW = 0
                    switch( rows.defs[ r ][ f ].type )
                    {
                        case 'image':
                            for( let i in rows.images[ r ][ f ] )
                            {
                                fieldW += rows.images[ r ][ f ][ i ].size
                            }
                            fieldW += ( padding * 2 )
                            break
                        default:
                            let d = doc.getTextDimensions( rows.rows[ r ][ f ] )
                            fieldW = d.w + padding

                            if( undefined !== subheaders
                                && null !== subheaders[ f ] )
                            {
                                fieldW = null //subheaders[ f ].w + ( padding * 2 )
                                maxW[ f ] = null
                                break
                            }

                            if( undefined !== rows.defs[ r ][ f ].width )
                            {
                                fieldW = rows.defs[ r ][ f ].width + padding
                            }
                            break

                    }
                    if( ( undefined === maxW[ f ]
                          && null !== maxW[ f ] )
                        || ( fieldW > maxW[ f ] ) )
                    {
                        if( fieldW > minW )
                        {
                            maxW[ f ] = Math.ceil( fieldW )
                        }
                        else
                        {
                            maxW[ f ] = minW
                        }
                    }
                }
            }
        }
    }

    let usedSpace = 0
    for( let m in maxW )
    {
        if( 0 < parseInt( m ) )
        {
            usedSpace += maxW[ m ]
        }
    }

    room -= firstColW

    return {
        remain: maxW,
        used  : usedSpace,
        room  : room
    }

}

function _getSubHeaderTotalWidth( subheaders )
{

    let totalWidth = 0

    for( let s in subheaders )
    {
        if( null !== subheaders[ s ] )
        {
            totalWidth += subheaders[ s ].w
        }
    }

    return totalWidth

}

function _getColumnWidths( doc, maxWidth, rows, commentPosition, subheaders, punchMarks, landscape )
{

    let pmAddX         = ( punchMarks && !landscape ? 10 : 0 ),
        subHeaderWidth = _getSubHeaderTotalWidth( subheaders ),
        room           = maxWidth - pmAddX - subHeaderWidth,
        remainingSpace = _getRemainingSpace( doc, rows, room, commentPosition, subheaders )

    room = remainingSpace.room

    let maxW       = remainingSpace.remain,
        usedSpace  = remainingSpace.used,
        divisorKey = 0

    for( let r in rows.rows )
    {
        if( rows.rows[ r ].length > divisorKey )
        {
            divisorKey = rows.rows[ r ].length
        }
    }

    for( let s in subheaders )
    {
        if( null !== subheaders[ s ] )
        {
            divisorKey -= 1
        }
    }
    divisorKey -= 2

    if( usedSpace <= room )
    {
        let divisor = ( 0 < divisorKey ? divisorKey : 1 ),
            add     = ( room - usedSpace - 10 ) / divisor

        for( let m in maxW )
        {
            if( 0 < parseInt( m )
                && null !== maxW[ m ] )
            {
                if( undefined !== rows.defs[ 0 ][ parseInt( m ) ]
                    && rows.defs[ 0 ][ parseInt( m ) ].def === 'text'
                    && rows.defs[ 0 ][ parseInt( m ) ].isScore !== true )
                {
                    maxW[ m ] = maxW[ m ] + add
                }
                else
                {
                    maxW[ m ] = maxW[ m ]
                }
            }
            else
            {
                if( null !== subheaders[ m ] )
                {
                    maxW[ m ] = subheaders[ m ].w
                }
            }
        }

    }
    else
    {

        let textCount      = 0,
            totalUsedSpace = 0

        for( let w in maxW )
        {
            if( null !== maxW[ w ] )
            {

                if( 0 < parseInt( w ) )
                {
                    if( rows.defs[ 0 ][ parseInt( w ) ].def === 'text'
                        && rows.defs[ 0 ][ parseInt( w ) ].isScore !== true )
                    {
                        textCount++
                    }
                    else
                    {
                        totalUsedSpace += maxW[ w ]
                    }
                }
                else
                {
                    totalUsedSpace += maxW[ w ]
                }

            }
        }

        let fieldMax = ( maxWidth - totalUsedSpace - subHeaderWidth - 10 ) / textCount
        if( 40 < fieldMax )
        {
            let maxWnew = []
            for( let w in maxW )
            {
                if( null !== maxW[ w ] )
                {
                    if( 0 < parseInt( w ) )
                    {
                        if( rows.defs[ 0 ][ parseInt( w ) ].def === 'text'
                            && rows.defs[ 0 ][ parseInt( w ) ].isScore !== true )
                        {
                            maxWnew[ w ] = fieldMax
                        }
                        else
                        {
                            maxWnew[ w ] = maxW[ w ]
                        }
                    }
                    else
                    {
                        maxWnew[ w ] = maxW[ w ]
                    }
                }
                else
                {
                    maxWnew[ w ] = subheaders[ w ].w
                }
            }
            maxW = maxWnew
        }
        else
        {
            return null
        }

    }

    let xPos = [ 12 ],
        x    = maxW[ 0 ] + 10

    for( let m in maxW )
    {
        if( 0 < parseInt( m ) )
        {
            xPos.push( x )
            x += maxW[ m ]
        }
    }

    let widths = {
        w: maxW,
        x: xPos
    }

    return widths

}

function _fixTextDimensions( doc, text, width )
{

    let toFix = Array.isArray( text ) ? text.join( ' ' ) : text,
        temp  = toFix.split( /\ /g ),
        lines = [],
        line  = ''

    while( 0 < temp.length )
    {

        let word    = temp.shift(),
            lineDim = doc.getTextDimensions( line ),
            testDim = doc.getTextDimensions( word )

        if( testDim.w + lineDim.w < width )
        {
            line += word + ' '
        }
        else
        {
            lines.push( line )
            line = word + ' '
        }

    }

    return lines

}

function _prepareList( list, doc, maxWidth, y, lineX, align, headers, punchMarks, landscape )
{

    let listSetup = {
            rects      : [],
            lines      : [],
            cells      : [],
            rowHeights : [],
            hasComments: ( -1 < headers.commentPosition )
        },
        defs      = list.rows.defs,
        images    = list.rows.images,
        pmAddX    = ( punchMarks && !landscape ? 10 : 0 ),
        pmAddY    = ( punchMarks && landscape ? 10 : 0 ),
        widths    = _getColumnWidths( doc, maxWidth, list.rows, headers.commentPosition, headers.subdimensions )

    //y+= pmAddY

    if( null === widths )
    {
        return null
    }

    for( let r in list.rows.rows )
    {

        let row        = list.rows.rows[ r ],
            dimensions = list.rows.dimensions[ r ],
            rowHeight  = _getMaxRowHeight( dimensions )

        listSetup.rowHeights.push( rowHeight )

        listSetup.rects.push( {
            fillcolor: r % 2 === 1 ? '#f6f6f6' : '#fefefe',
            rect     : {
                x: ( 10 + pmAddX ), y: y, x2: ( maxWidth - 10 ), y2: rowHeight, type: 'F'
            }
        } )

        let room   = maxWidth - widths.w[ 0 ],
            headY  = y + 5,
            half   = ( room / row.length ) / 2,
            firstX = pmAddX,
            factor = -1 < headers.commentPosition ? 2 : 1,
            cells  = []

        for( let rr in row )
        {

            let height  = 0,
                x       = 12 + pmAddX,
                courier = false

            doc.setFont( 'quicksand', 'bold' )
            if( rr > 0 && r % factor === 0 )
            {

                if( true === defs[ r ][ rr ].pre )
                {
                    courier = true
                }

                let dim, halfWidth, boxWidth

                switch( defs[ r ][ rr ].type )
                {

                    case 'text':
                        dim = undefined !== row[ rr ] ? doc.getTextDimensions( row[ rr ] ) : { w: 0 }
                        halfWidth = dim.w / 2
                        if( undefined !== defs[ r ][ rr ].width )
                        {
                            halfWidth = defs[ r ][ rr ].width / 2
                        }

                        if( dim.w > widths.w[ parseInt( rr ) ] )
                        {
                            row[ rr ] = _fixTextDimensions( doc, row[ rr ], widths.w[ parseInt( rr ) ] )
                            dim = doc.getTextDimensions( row[ rr ] )
                            rowHeight = dim.h + 5
                            halfWidth = dim.w / 2
                        }

                        if( row[ rr ] instanceof Array
                            && 1 < row[ rr ].length )
                        {
                            halfWidth -= 2 // padding
                        }
                        height = dim.h
                        break
                    case 'image':
                        let w = 0
                        for( let i in images[ r ][ rr ] )
                        {
                            w += images[ r ][ rr ][ i ].size
                            height = images[ r ][ rr ][ i ].size
                        }
                        halfWidth = w / 2
                        break

                }
                boxWidth = widths.w[ rr ]

                switch( align )
                {
                    case 'center':
                        x = ( widths.x[ rr ] + ( boxWidth / 2 ) - halfWidth ) + pmAddX
                        break
                    case 'left':
                        x = ( widths.x[ rr ] + 2 ) + pmAddX
                        break
                    case 'right':
                        x = ( widths.x[ rr ] + boxWidth - ( halfWidth * 2 ) - 2 ) + pmAddX
                        break
                }

                if( 0 === firstX )
                {
                    firstX = x - half - ( dimensions[ rr ].w / 2 )
                }

            }
            else
            {
                if( r % factor !== 0 )
                {
                    if( 1 === parseInt( rr ) )
                    {
                        x = widths.x[ rr ] + 3
                    }
                }
            }

            cells.push( {
                x      : x,
                y      : headY,
                text   : row[ rr ],
                courier: courier
            } )

        }

        listSetup.cells.push( cells )

        let lines = []
        for( let lx in lineX )
        {

            let x = lineX[ lx ]
            lines.push( {
                x: x, y: y, x2: x, y2: ( y + rowHeight )
            } )

        }

        listSetup.lines.push( lines )
        y += rowHeight

    }

    return {
        y    : y,
        setup: listSetup
    }

}

function _form( type, x1, x2, y1, y2, color, height )
{
    return {
        type  : type,
        x1    : x1,
        x2    : x2,
        y1    : y1,
        y2    : y2,
        color : color,
        height: height
    }
}

function _getScores( element, doc, maxWidth )
{

    let scoreBox = document.querySelector( '#append-' + element.id )
    let height = 0

    let scores = {
        elements: null
    }

    if( null !== scoreBox )
    {
        scores.elements = []
        let headline = scoreBox.querySelector( 'h3' )
        let y = 15
        if( null !== headline )
        {

            doc.setFontSize( 12 )
            let dims = doc.getTextDimensions( headline.innerText )
            y += dims.h

            scores.elements.push( {
                type  : 'text',
                text  : headline.innerText,
                size  : 12,
                style : 'bold',
                x     : 10,
                y     : y,
                break : true,
                height: dims.h
            } )

            y += 1

            scores.elements.push( {
                type  : 'line',
                x1    : 10,
                x2    : maxWidth,
                y1    : ( y + 2 ),
                y2    : ( y + 2 ),
                break : true,
                height: 1
            } )

            y += 1

        }

        let box = scoreBox.querySelector( '.distribution-box' )
        let divisor = 2
        if( null !== box )
        {

            if( box.classList.contains( 'extended' )
                || box.classList.contains( 'basic' )
                || box.classList.contains( 'hamburgEG' ) )
            {
                divisor = 3
            }

            let boxWidth       = ( maxWidth - 10 ) / divisor,
                boxLeft        = 0,
                innerWidth     = null,
                headLineHeight = null

            let selectors = [ 'box-wrap', 'box-basic', 'box-hamburgEG' ]
            let scoreBoxes = []
            while( 0 === scoreBoxes.length
                   && 0 < selectors.length )
            {
                let selector = selectors.shift()
                scoreBoxes = box.querySelectorAll( 'div[class*="' + selector + '"]' )
            }

            let divCount = 0
            let divCounted = false
            let hasHeadlines = false

            for( let s in scoreBoxes )
            {
                let sBox = scoreBoxes[ s ]
                if( sBox instanceof HTMLElement )
                {
                    divCounted = true
                    divCount++
                    let header = sBox.querySelector( '.box-model-header' )
                    if( null !== header )
                    {
                        hasHeadlines = true
                        doc.setFontSize( 10 )
                        let dims = doc.getTextDimensions( header.innerText )
                        let boxX = 10 + ( boxLeft + ( ( boxWidth - dims.w ) / 2 ) )

                        scores.elements.push(
                            _form( 'rect', ( 10 + boxLeft + 0.125 ), ( boxWidth - 0.25 ), y, ( 1 + dims.h ), '#efefef', 0 )
                        )

                        scores.elements.push( {
                            type  : 'line',
                            x1    : ( 10 + boxLeft ),
                            x2    : ( 10 + boxLeft + boxWidth ),
                            y1    : ( y + 1 + dims.h ),
                            y2    : ( y + 1 + dims.h ),
                            height: 0
                        } )
                        if( divCount % divisor !== 0 )
                        {
                            scores.elements.push( {
                                type  : 'line',
                                x1    : ( 10 + boxLeft + boxWidth ),
                                x2    : ( 10 + boxLeft + boxWidth ),
                                y1    : y,
                                y2    : ( y + 1 + dims.h ),
                                height: 0
                            } )
                        }
                        scores.elements.push( {
                            type  : 'text',
                            text  : header.innerText,
                            size  : 10,
                            style : 'bold',
                            x     : boxX,
                            y     : y,
                            break : false,
                            height: dims.h
                        } )
                        headLineHeight = null === headLineHeight ? ( dims.h + 1 ) : headLineHeight
                    }

                    let innerHeads  = sBox.querySelectorAll( '.box-header' ),
                        innerScores = sBox.querySelectorAll( '.box-count' ),
                        startX      = boxLeft,
                        headAdd     = hasHeadlines ? headLineHeight : 0

                    for( let i in innerHeads )
                    {

                        if( innerHeads[ i ] instanceof HTMLElement )
                        {

                            if( !divCounted )
                            {
                                divCount++
                            }
                            innerWidth = null === innerWidth ? boxWidth / innerHeads.length : innerWidth
                            let dims = doc.getTextDimensions( innerHeads[ i ].innerText )
                            headLineHeight = null === headLineHeight ? ( dims.h + 1 ) : headLineHeight
                            let boxX = 10 + ( startX + ( ( innerWidth - dims.w ) / 2 ) )

                            scores.elements.push(
                                _form( 'rect', ( 10 + startX + 0.15 ), ( innerWidth - 0.3 ), ( y + headAdd + 0.2 ), ( 1 + dims.h ), '#f6f6f6', 0 )
                            )
                            if( parseInt( i ) !== ( innerHeads.length - 1 ) || divCount % divisor !== 0 )
                            {
                                scores.elements.push( {
                                    type  : 'line',
                                    x1    : ( 10 + startX + innerWidth ),
                                    x2    : ( 10 + startX + innerWidth ),
                                    y1    : ( y + headAdd ),
                                    y2    : ( y + headAdd + 1 + dims.h ),
                                    height: 0
                                } )
                            }
                            scores.elements.push( {
                                type  : 'text',
                                text  : innerHeads[ i ].innerText,
                                size  : 10,
                                style : 'bold',
                                x     : boxX,
                                y     : ( y + headAdd ),
                                break : false,
                                height: dims.h
                            } )

                            startX += innerWidth

                        }

                    }

                    headAdd = hasHeadlines ? ( 2 * headLineHeight ) : headLineHeight
                    startX = boxLeft
                    for( let i in innerScores )
                    {

                        if( innerScores[ i ] instanceof HTMLElement )
                        {

                            let dims = doc.getTextDimensions( innerScores[ i ].innerText )
                            let boxX = 10 + ( startX + ( ( innerWidth - dims.w ) / 2 ) )

                            if( parseInt( i ) !== ( innerHeads.length - 1 ) || divCount % divisor !== 0 )
                            {
                                scores.elements.push( {
                                    type  : 'line',
                                    x1    : ( 10 + startX + innerWidth ),
                                    x2    : ( 10 + startX + innerWidth ),
                                    y1    : ( y + headAdd ),
                                    y2    : ( y + headAdd + 1 + dims.h ),
                                    height: 0
                                } )
                            }
                            scores.elements.push( {
                                type  : 'text',
                                text  : innerScores[ i ].innerText,
                                size  : 10,
                                style : 'bold',
                                x     : boxX,
                                y     : ( y + headAdd ),
                                break : ( parseInt( i ) === ( innerHeads.length - 1 ) && divCount % divisor === 0 ),
                                height: dims.h
                            } )

                            startX += innerWidth

                        }

                    }

                    boxLeft += boxWidth
                    if( ( divCount % divisor === 0 ) )
                    {
                        boxLeft = 0
                        let multi = hasHeadlines ? 2 : 1
                        y += ( multi * headLineHeight ) + 0.1
                    }

                }
            }

            let scoreTotals = scoreBox.querySelectorAll( '.box-totals' )

            divisor = 3
            boxWidth = ( maxWidth - 10 ) / divisor
            boxLeft = 0
            divCount = 0
            hasHeadlines = false

            if( 0 < scoreTotals.length )
            {
                y += 5
            }

            for( let s in scoreTotals )
            {

                let sBox = scoreTotals[ s ]
                if( sBox instanceof HTMLElement )
                {

                    let header = sBox.querySelector( '.box-header-totals' )
                    let content = sBox.querySelector( '.box-count' )

                    if( null !== header )
                    {
                        hasHeadlines = true
                        doc.setFontSize( 10 )
                        let dims = doc.getTextDimensions( header.innerText )
                        let boxX = 10 + ( boxLeft + ( ( boxWidth - dims.w ) / 2 ) )

                        scores.elements.push(
                            _form( 'rect', ( 10 + boxLeft + 0.125 ), ( boxWidth - 0.25 ), y, ( 1 + dims.h ), '#efefef', 0 )
                        )
                        scores.elements.push(
                            _form( 'line', ( 10 + boxLeft ), ( 10 + boxLeft + boxWidth ), ( y + 1 + dims.h ), ( y + 1 + dims.h ), undefined, 0 )
                        )
                        if( divCount % divisor !== 0 )
                        {
                            _form( 'line', ( 10 + boxLeft + boxWidth ), ( 10 + boxLeft + boxWidth ), y, ( y + 1 + dims.h ), undefined, 0 )
                        }
                        scores.elements.push( {
                            type  : 'text',
                            text  : header.innerText,
                            size  : 10,
                            style : 'bold',
                            x     : boxX,
                            y     : y,
                            break : false,
                            height: dims.h
                        } )

                        headLineHeight = null === headLineHeight ? ( dims.h + 1 ) : headLineHeight

                    }

                    if( null !== content )
                    {

                        let offset = hasHeadlines ? headLineHeight : 0

                        doc.setFontSize( 10 )
                        let dims = doc.getTextDimensions( content.innerText )
                        let boxX = 10 + ( boxLeft + ( ( boxWidth - dims.w ) / 2 ) )

                        scores.elements.push(
                            _form( 'rect', ( 10 + boxLeft + 0.125 ), ( boxWidth - 0.25 ), ( y + offset ), ( 1 + offset + dims.h ), '#fefefe', 0 )
                        )
                        scores.elements.push(
                            _form( 'line', ( 10 + boxLeft ), ( 10 + boxLeft + boxWidth ), ( y + offset + 1 + dims.h ), ( y + offset + 1 + dims.h ), undefined, 0 )
                        )
                        scores.elements.push( {
                            type  : 'text',
                            text  : content.innerText,
                            size  : 10,
                            style : 'bold',
                            x     : boxX,
                            y     : ( y + offset ),
                            break : false,
                            height: dims.h
                        } )

                        boxLeft += boxWidth
                        divCount++
                        if( ( divCount % divisor === 0 ) )
                        {
                            boxLeft = 0
                            y += headLineHeight + offset + 0.1
                        }

                    }

                }
            }

            height = y

        }

    }

    return {
        scores: scores,
        height: height
    }

}

function prepareTest( element, doc, y, maxHeight, maxWidth, color, noHeader, landscape, settings, template )
{

    doc.setFont( 'quicksand', 'bold' )
    doc.setFontSize( 10 )

    let cutmarks       = settings.printingTestsAlwaysCutmarked === true && template.listType === 'test',
        headers        = _getHeaders( element, doc, cutmarks ),
        punchMarks     = settings.printingWithPunchMarks === true,
        list           = {
            headers: headers,
            rows   : _getRows( element, doc, maxWidth, undefined, headers.commentPosition, punchMarks, landscape )
        },
        remain         = _getRemainingSpace( doc, list.rows, maxWidth, headers.commentPosition, headers.subdimensions ),
        remainingSpace = remain.room - remain.used

    if( remainingSpace <= 0 )
    {
        return null
    }

    list.rows = _getRows( element, doc, maxWidth, remainingSpace, headers.commentPosition )
    let result = _prepareHeader( list, doc, y, maxWidth, color, headers, punchMarks, landscape )

    if( null === result )
    {
        return null
    }

    if( !noHeader )
    {
        y += result.headerY
    }

    let resultList = _prepareList( list, doc, maxWidth, y, result.lineX, settings.printingDefaultAlign, headers, punchMarks, landscape )
    if( null === resultList )
    {
        return null
    }

    y = resultList.y

    return {
        headers: result,
        rows   : resultList,
        raw    : list,
        scores : _getScores( element, doc, maxWidth )
    }

}

export default prepareTest