<template>
    <div class="select-wrapper" v-if="initialized">
        <select class="select_element" :id="'form-element-'+refName" @click="$core.f().skip" @change="checkSelect()">
            <option value="">bitte wählen...</option>
            <optgroup label="meine Gruppen" v-if="undefined !== groups && 0 < groups.length">
                <option v-for="item in groups"
                        :key="'key_option_'+item.localId"
                        :value="item.localId">{{ item.groupname }}
                </option>
            </optgroup>
            <template v-for="colleague in $core.cc().getAll()">
                <optgroup :label="'Gruppen von '+colleague.firstname+' '+colleague.lastname"
                          :key="'coll-'+colleague.localId"
                          v-if="undefined !== foreignGroups[ colleague.colleagueId ]
                                    && 0 < foreignGroups[ colleague.colleagueId ].length">
                    <option v-for="groupInfo in foreignGroups[ colleague.colleagueId ]"
                            :value="groupInfo.localId"
                            :selected="value === 'class:'+groupInfo.localId"
                            :key="'key_option_'+groupInfo.localId">
                        {{ groupInfo.groupname }}
                    </option>
                </optgroup>
            </template>
        </select>
    </div>
</template>

<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {
    name  : 'GroupSelector',
    mixins: [ MixinCachePreheater ],

    props: {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        id         : { Type: String, required: false },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        editor     : { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false },
        flags      : { Type: String, required: false },
        prefetch   : { Type: String, required: false }
    },

    created()
    {

    },

    updated()
    {
        if( this.initialized
            && null !== this.formElm
            && false !== this.formElm )
        {
            this.select()
        }
    },

    mounted()
    {

        this.awaitNeededCaches()
            .then( () =>
            {
                this.initialize()
                    .then( () =>
                    {
                        this.formElm = document.querySelector( '#form-element-' + this.$props.refName )
                        this.select()
                        this.$core.getEventManager().add( 'on-group-preselect', localId =>
                        {
                            this.$emit( 'update', this.$props.refName, 'groupSelector', 'update', undefined, localId )
                        } )
                    } )
            } )

    },

    beforeUnmount()
    {
        this.$core.getEventManager().remove( 'on-group-preselect' )
    },

    data()
    {
        return {
            neededCaches : [ 'group' ],
            GroupObject  : false,
            groups       : false,
            foreignGroups: {},
            data         : {},
            initialized  : false,
            formElm      : false
        }
    },

    watch: {
        prefetch: {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.initialize()
                    this.select()
                }
            },
            deep: true
        }
    },

    methods: {

        initialize()
        {
            return new Promise( resolve =>
            {

                let rules = this.$core.settings().getSetting( 'sortingDirections' )
                this.data = this.$props.prefetch
                if( undefined === this.data )
                {

                    let data = this.$core.getBaseClassHelper()
                                   .get( 'group' )
                                   .readCache( 'cache' )

                    data = this.$core.getSorter().multiSortObjects( data, rules[ 'classes' ] )
                    this.data = {}
                    this.data.all = data

                    let groups = []
                    for( let d in data )
                    {
                        if( this.$core.f().isOwn( data[ d ] ) )
                        {
                            groups.push( data[ d ] )
                        }
                        else
                        {
                            if( undefined === this.foreignGroups[ data[ d ].idOwner ] )
                            {
                                this.foreignGroups[ data[ d ].idOwner ] = []
                            }
                            this.foreignGroups[ data[ d ].idOwner ].push( data[ d ] )
                        }
                    }

                    this.groups = groups
                    this.initialized = true
                    return resolve()

                }
                else
                {
                    this.groups = this.$core.getSorter().multiSortObjects( this.$props.value, rules[ 'classes' ] )
                    this.initialized = true
                    return resolve()
                }

            } )
        },

        select()
        {
            if( null !== this.formElm )
            {
                if( undefined !== this.$props.value
                    && null !== this.$props.value )
                {
                    this.formElm.value = this.$props.value
                }
            }
        },

        checkSelect()
        {

            if( null !== this.formElm )
            {
                if( '' !== this.formElm.value.trim() )
                {
                    this.$emit( 'change', this.$props.id, this.formElm.value )
                    this.$emit( 'update', this.$props.refName, 'groupSelector', 'update', undefined, this.formElm.value )
                }
                else
                {
                    this.$emit( 'change', this.$props.id, undefined )
                    this.$emit( 'update', this.$props.refName, 'groupSelector', 'update', undefined, undefined )
                }
            }

        },
        isEditor()
        {
            return ( undefined === this.$props.display || this.$props.editor === true )
        }
    }

}
</script>