<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="out-in">
        <div :class="'pagination'+( noMargin ? ' no-margin' : '')">
            <strong>{{ $core.t( 'select-page' ) }}</strong>:
            <div class="button-wrap">
                <div v-for="( p, index ) in pages"
                     :key="'pagination-page-'+index"
                     @click="handleClick( index )"
                     :class="'pagebutton'+( page === ( index + 1 ) ? ' active' : '' )">
                    {{ ( index + 1 ) }}
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {

    name: 'Pagination',

    props: {
        page    : { Type: Number, required: true },
        pages   : { Type: Object, required: true },
        noMargin: { Type: Boolean, required: false, default: false }
    },

    emits: [ 'pageChange' ],

    methods: {
        handleClick( index )
        {
            this.$emit( 'pageChange', ( index + 1 ) )
        }
    }

}
</script>