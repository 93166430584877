<template v-if="initialized">
    <div :class="'organizer-element hoverable '+item.color+( selected ? ' selected-item' : '' )"
         :key="'organizer-'+item.localId">
        <div @click="handleOpen()">
            <div class="title">
                {{ item.title }}
            </div>
            <div class="markerspace absolute right">
                <ReferenceMarkers :item="item"/>
            </div>
            <table>
                <template v-if="!item.noTimeRange">
                    <tr>
                        <td class="half">{{ $core.t( 'organizerstart' ) }}: <strong>{{ item.dateStart }}</strong></td>
                        <td class="half">{{ $core.t( 'organizerend' ) }}: <strong>{{
                                item.dateEnd || $core.t( 'open-end' )
                            }}</strong></td>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td class="half">{{ $core.t( 'organizer-notimerange' ) }}</td>
                    </tr>
                </template>
                <tr>
                    <td class="half"><strong>{{
                            item.contents ? ( item.contents.length === 0 ? 'noch keine' : item.contents.length ) : 'noch keine'
                        }}</strong> Planungs- /
                        Unterrichtsreihen angelegt
                    </td>
                </tr>
                <tr>
                    <td class="half">Planungsraster: <strong>{{ item.slots ? item.slots.length : '0' }}</strong> Felder
                        /
                        Tag
                    </td>
                </tr>
            </table>
        </div>
        <div v-if="open"
             style="position:relative">
            <Button type="copy"
                    :addClass="'absolute right'+( copyMode ? ' active' : '' )"
                    @click="handleCopyMode()"/>
            <div class="spacer"></div>
            <h3>Beschrifte deine Planungsvorlage</h3>
            <p>Klicke in das Raster und füge im Anschluss eine Beschriftung und eine Farbe hinzu.<br/>
                Vorhandene Beschriftungen lassen sich einfach verschieben.<br/>
                Wenn du bereits vorhandene Beschriftungen duplizieren möchtest, aktiviere vor dem Verschieben den
                "Kopier-Modus".</p>
            <PlannerViewOrganizer :embedded="true"
                                  :labelMode="true"
                                  :slotSelection="[]"
                                  :copyMode="copyMode"
                                  :changeKey="changeKey"
                                  :filterList="filterList"
                                  :draggableLabels="true"
                                  :highlighted="[]"
                                  @dragging="handleDragging"
                                  @markSlot="handleSlotMark"/>
        </div>
    </div>
</template>

<script>
import ReferenceMarkers     from '@/components/elements/markers/ReferenceMarkers'
import PlannerViewOrganizer from "@/components/elements/viewItems/plannerView/modes/PlannerViewOrganizer";
import MixinCachePreheater  from "@/mixins/MixinCachePreheater";

export default {

    name      : 'Organizer',
    components: { PlannerViewOrganizer, ReferenceMarkers },
    props     : {
        item: { type: Object, required: true }
    },

    mixins: [ MixinCachePreheater ],

    data()
    {
        return {
            initialized    : false,
            open           : false,
            changeKey      : this.$core.getUuid().generate(),
            filterList     : { organizer: [ { 'attr': 'localId', value: this.$props.item.localId } ] },
            neededCache    : [ 'organizerlabel' ],
            baseClass      : this.$core.getBaseClassHelper().get( 'organizer' ),
            baseClassLabels: this.$core.getBaseClassHelper().get( 'organizerlabel' ),
            copyMode       : false,
            copiedElm      : false,
            dragging       : false,
            eventIndex     : false
        }
    },

    computed:
        {
            viewKey: {
                cache: false,
                get()
                {
                    if( !this.$core.f().valid( this.baseClass )
                        || 'filled' !== this.baseClass.state
                        || false === this.baseClass.registry.cacheKey
                        || false === this.baseClass.registry.archiveKey )
                    {
                        return undefined
                    }
                    if( !this.$core.f().valid( this.baseClassLabels )
                        || 'filled' !== this.baseClassLabels.state
                        || false === this.baseClassLabels.registry.cacheKey
                        || false === this.baseClassLabels.registry.archiveKey )
                    {
                        return undefined
                    }
                    return this.baseClassLabels.registry.cacheKey + '-' + this.baseClassLabels.registry.archiveKey
                           + '-' + this.baseClass.registry.cacheKey + '-' + this.baseClass.registry.archiveKey
                }
            }
        },

    watch: {
        viewKey: {
            immediate: true,
            handler( newKey, oldKey )
            {

                if( undefined !== oldKey
                    && undefined !== newKey
                    && newKey !== oldKey )
                {
                    this.changeKey = this.$core.getUuid().generate()
                }
            }
        }
    },

    created()
    {
        this.eventIndex = this.$core.getEventManager()
                              .addIndexed( 'on-open-organizer', ( localId ) =>
                              {
                                  if( localId !== this.$props.item.localId )
                                  {
                                      this.open = false
                                  }
                              } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .removeIndexedCallback( 'on-open-organizer', this.eventIndex )
    },

    mounted()
    {
        this.awaitNeededCaches()
            .then( () =>
            {
                this.initialized = true
            } )
    },

    methods: {

        handleOpen()
        {
            this.$core.getEventManager()
                .dispatchIndexed( 'on-open-organizer', this.$props.item.localId )

            this.$nextTick()
                .then( () =>
                {
                    this.open = !this.open
                    this.changeKey = this.$core.getUuid().generate()
                } )
        },

        dayIndex( date )
        {
            return date.getDay()
        },

        handleSlotEdit( dayIndex, slotIndex )
        {

            let slotItem = this.$core.getBaseClassHelper()
                               .get( 'organizerlabel' )
                               .getById( this.$props.item.labels[ dayIndex ][ slotIndex ] )

            this.$core.getUi().showForm( 'organizerlabel', 'organizerlabel', 'edit', slotItem )

        },

        storeOrganizerLabel( dayIndex, slotIndex, labelLocalId )
        {

            let labels = this.$core.f().isObject( this.$props.item.labels ) ? this.$props.item.labels : {}
            labels[ dayIndex ] = labels[ dayIndex ] || {}

            if( !this.$core.f().isObject( labels[ dayIndex ] ) )
            {
                labels[ dayIndex ] = {}
            }

            labels[ dayIndex ][ slotIndex ] = labelLocalId

            let clone = this.$core.f().deref( this.$props.item )
            clone.labels = labels

            this.$core.getBaseClassHelper()
                .get( 'organizer' )
                .update( clone,
                    clone.localId,
                    clone.remoteId,
                    clone.timestamp,
                    clone.localKey,
                    undefined,
                    false,
                    false
                )


        },

        handleSlotMark( event, slotIndex )
        {

            if( this.dragging )
            {
                return
            }

            let dayIndex = this.dayIndex( event.dateObject )//,

            if( 'create' !== this.$props.item.labels
                && undefined !== this.$props.item.labels
                && undefined !== this.$props.item.labels[ dayIndex ]
                && undefined !== this.$props.item.labels[ dayIndex ][ slotIndex ] )
            {
                this.handleSlotEdit( dayIndex, slotIndex )
                return
            }

            let formId = this.$core.getUi().showForm( 'organizerlabel', 'organizerlabel', 'add' )

            this.$core.getEventManager()
                .add( 'form-closed-' + formId, () =>
                {

                    this.$core.getUi()
                        .delay( () =>
                        {
                            this.$core.getEventManager()
                                .remove( 'created-object-' + formId )
                        }, 5000 )
                } )

            this.$core.getEventManager()
                .add( 'created-object-' + formId, ( localId ) =>
                {

                    this.storeOrganizerLabel( dayIndex, slotIndex, localId )

                } )

        },

        handleCopyMode()
        {
            this.copyMode = !this.copyMode
            if( false === this.copyMode )
            {
                this.copiedElm = false
            }
        },

        handleDragging( state )
        {
            this.dragging = state
            this.$emit( 'dragging', state )
        }

    }

}
</script>