export default class SyncBackgrounds
{
    
    constructor( parent )
    {
        
        this.parent = parent
        
        this.backgrounds = [
            'baby-blue.jpg',
            'black-beauty.jpg',
            'carrots.gif',
            'cat.gif',
            'chalkboard.jpg',
            'cut-n-draw.jpg',
            'fish.gif',
            'floral.gif',
            'greenish.jpg',
            'lose-papers.jpg',
            'lose-papers-wallpaper.jpg',
            'love.gif',
            'maths.jpg',
            'paperstack2.jpg',
            'pencils.jpg',
            'pink-lady.jpg',
            'rabbit.gif',
            'science.jpg',
            'summer.gif',
            'tapete.gif',
            'unicorns.svg',
            'white.jpg',
            'abstract.gif',
            'flowers.jpg',
            'flowers_ananas.svg',
            'flowers_dark.jpg',
            'smiles.gif',
            'newyork.jpg',
            'xmas.gif'
        ]

        this.allSynced = false
        
    }
    
    ready()
    {
        return this.allSynced === true
    }
    
    sync()
    {
    
        if( false !== this.parent.store.getters.idSession
            && true === this.parent.store.getters.online )
        {
    
            for( let i in this.backgrounds )
            {
        
                let id = this.backgrounds[ i ]
        
                this.parent.database.read( 'backgrounds', id )
                    .then( () =>
                    {
                    } )
                    .catch( () =>
                    {
                
                        let request = {
                            method      : 'objects.getBackground',
                            name        : id,
                            looseSession: true
                        }

                        let temp = id.split( /\./g )
                        let name = temp[0]

                        this.parent.client.request( request )
                            .then( result =>
                            {
                                this.parent.database.write( 'backgrounds', name, result.data )
                            } )
                
                    } )
        
            }

            this.parent.client.request({
                    method: 'objects.getPersonalBackground'
                })
                .then( result => {
                    if( undefined !== result.data )
                    {

                        this.parent.cryptoHelper.plainDecrypt( result.data )
                            .then( image => {
                                this.parent.database.write( 'backgrounds', 'personal', image )
                                this.parent.eventManager.dispatch( 'update-background-style' )
                            })

                    }
                })
            
        }
        else
        {
            setTimeout( () => {
                this.sync()
            }, 5000 )
        }
        
    }
    
}