<template>
    <div :key="'synced-'+localId+'-'+elementKey" class="quickmark" v-bind:class="state ? 'synced' : 'not-synced'"
         @click="handleSync"></div>
</template>

<script>
export default {
    name : 'SyncStateIndicator',
    props: {
        state     : { Type: Boolean, required: true },
        localId   : { Type: String, required: true },
        elementKey: { Type: String, required: true },
        syncable  : { Type: Object, required: true }
    },

    methods: {
        handleSync()
        {

            let type = this.$props.syncable.type,
                list = []

            if( 'list' === type
                && Array.isArray( this.$props.syncable.lists ) )
            {
                for( let l in this.$props.syncable.lists )
                {
                    list.push( { type: type, localId: this.$props.syncable.lists[ l ].localId } )
                }
            }
            else
            {
                list.push( { type: type, localId: this.$props.syncable.localId } )
            }

            this.$core.getDatabase()
                .writeUploadsList( list )

        }
    }
}
</script>