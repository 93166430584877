<script>
export default {
    name: "MixinFilterableView",

    data()
    {
        return {
            filterList    : {},
            filterKey     : false,
            filtered      : [],
            viewIsFiltered: false
        }
    },

    watch: {
        tab       : {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.filter( this.prepared[ this.tab ] )
                    this.filterKey = 'flt-' + this.$core.f().objectHash( this.filterList, true ) + '-forced'
                }
            }
        },
        controlKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.filter( this.prepared[ this.tab ] )
                    this.filterKey = 'flt-' + this.$core.f().objectHash( this.filterList, true ) + '-forced'
                }
            }
        }
    },

    methods: {

        setSearchFilter( keyword )
        {

            this.$core.getCoreTimer()
                .addTimeout( 'fulltext-search', 1000, () =>
                {

                    if( undefined !== keyword )
                    {

                        let lookup = keyword
                        this.setFilters( 'fulltext', [ { value: lookup, isFulltext: true } ] )

                    }
                    else
                    {
                        this.setFilters( 'fulltext', undefined )
                    }

                } )

        },

        setFilters( type, filters )
        {

            if( '_refresh' !== type )
            {

                if( undefined === filters )
                {
                    delete this.filterList[ type ]
                    this.filterList[ type ] = []
                }
                else
                {
                    this.filterList[ type ] = filters
                }

            }

            this.filter( this.prepared[ this.tab ] )
                .then( () =>
                {
                    this.filterKey = 'flt-' + this.$core.f().objectHash( this.filterList, true )
                } )

        },

        filter( items )
        {

            return new Promise( resolve =>
            {

                this.viewIsFiltered = false
                let filtered = []
                if( this.$core.f().filterEmpty( this.filterList ) )
                {
                    this.filtered = filtered
                    return resolve( items )
                }

                this.$core.getBaseClassHelper()
                    .get( 'group' )
                    .listAll()
                    .then( groups =>
                    {

                        for( let i in items )
                        {

                            let item  = items[ i ],
                                match = true

                            for( let type in this.filterList )
                            {
                                let filter = this.filterList[ type ]
                                if( !this.$core.f().filterMatch( filter, item )
                                    && !this.$core.f().groupFilter( filter, item, groups ) )
                                {
                                    match = false
                                }
                            }

                            if( match )
                            {
                                filtered.push( item.localId )
                            }
                        }

                        this.viewIsFiltered = true
                        this.filtered = filtered

                        return resolve()

                    } )

            } )

        },


    }
}
</script>