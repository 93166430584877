export default class SyncVersion
{
    
    constructor( parent )
    {
        
        this.parent = parent
        this.parent.logger.clog( 'SyncWorker::SyncVersion', 'initialized' )
        
        this.lastAction = 0
        this.minInterval = 60000
        
    }
    
    sync()
    {
        
        return new Promise( resolve =>
        {
            
            if( this.parent.f.isOnlineSyncableState() )
            {
                if( this.lastAction < Date.now() - this.minInterval )
                {
                    this.parent.client.ping()
                        .then( () => {
                            
                            this.lastAction = Date.now()
                            this.parent.logger.clog( 'SyncWorker::SyncVersion:sync', 'server reported software version: '+this.parent.store.getters.updateVersion )
                            return resolve()
                            
                        })
                        .catch( () => {
                            return resolve()
                        })
                }
                else
                {
                    return resolve()
                }

            } else
            {
                return resolve()
            }
            
        } )
        
    }
    
}