import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class ClassesObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !ClassesObjectClass.instance )
        {

            let refList = [ 'classname', 'color', 'students', 'historicStudents', 'timestamp', 'update' ]

            let personalAttributesStatesList = [
                {
                    storage: 'hiding',
                    state  : 'hidden',
                    list   : 'hideLists'
                }
            ]

            super( core, 'class', refList, personalAttributesStatesList )

            this.baseClassHelper = core.getBaseClassHelper()

            this.hasDetailView = true
            this.objectType = 'classes'
            this.creationDependsOn = [ 'student' ]
            this.isStudentShareable = true
            ClassesObjectClass.instance = this
        }

        return ClassesObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'classname',
                caption    : 'Name',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'classname',
                checkExists: {
                    elements    : 'class',
                    checkForeign: true
                }
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                styleUpdate: true,
                reformatter: null,
                optional   : false,
                validator  : 'color'
            },
            {
                ref           : 'students',
                caption       : 'Schüler:innen',
                type          : 'RowAdder',
                component     : 'StudentSelector',
                values        : [],
                reformatter   : null,
                optional      : false,
                asPlainList   : true,
                extendedFilter: true,
                onlyUnassigned: 'classId',
                validator     : 'select',
                allowForeign  : true,
                dragBlocked   : true,
                unique        : true
            },
            {
                ref        : 'historicStudents',
                caption    : 'historische Schüler:innen',
                type       : 'Hidden',
                component  : 'Hidden',
                values     : [],
                reformatter: null,
                optional   : true,
                validator  : null
            }
        ]
    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return [ 'hide', 'edit', 'share', 'delete' ]
    }

    _checkLongClassnames()
    {

        let maxLength = -1
        /* eslint-disable-next-line no-unused-vars */
        for( let [ key, classObject ] of this.registry.cache )
        {

            let classname = classObject.classname
            if( undefined !== classname )
            {

                if( 3 < classname.length )
                {
                    if( maxLength < classname.length )
                    {
                        maxLength = classname.length
                    }
                }
            }

        }

        this.eventManager.dispatch( 'on-max-classname-length', maxLength )

    }

    _updateStudents( allStudents )
    {

        for( let s in allStudents )
        {

            let studentLocalId = allStudents[ s ]
            this.baseClassHelper
                .get( 'student' )
                .refreshCache( studentLocalId )

        }

    }

    create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
    {
        let id = super.create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
        this.eventManager.append( 'storable-after-update-' + id, () =>
        {
            this._checkLongClassnames()
        } )
        return id
    }

    update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )
    {

        let classElement = this.getById( localId ),
            allStudents  = undefined !== classElement ? JSON.parse( JSON.stringify( classElement.students ) ) : [],
            id           = super.update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )

        this.eventManager.append( 'storable-after-update-' + id, () =>
        {

            this._checkLongClassnames()

            let classElement = this.getById( localId ),
                newStudents  = classElement.students

            for( let n in newStudents )
            {
                if( -1 === allStudents.indexOf( newStudents[n] ) )
                {
                    allStudents.push( newStudents[n] )
                }
            }

            this._updateStudents( allStudents )

        } )

        return id
    }

    listAll( attach, filter, skipArchived )
    {

        return new Promise( ( resolve, reject ) =>
        {

            super.listAll( attach, filter, skipArchived )
                 .then( result =>
                 {

                     for( let r in result )
                     {
                         result[ r ].displayName = 'Klasse: ' + result[ r ].classname
                     }

                     this._checkLongClassnames()
                     return resolve( result )

                 } )
                 .catch( error =>
                 {
                     return reject( error )
                 } )

        } )

    }

}