<script>
import MixinCachePreheater             from "@/mixins/MixinCachePreheater";
import MixinDefaultDetailViewFiltering from "@/components/defaults/defaultDetailView/MixinDefaultDetailViewFiltering";

export default {

    name  : "MixinDefaultDetailViewTabs",
    mixins: [ MixinCachePreheater, MixinDefaultDetailViewFiltering ],

    data()
    {
        return {
            tabs           : [],
            tabMap         : new Map(),
            tab            : false,
            neededCaches   : [ 'class', 'group', 'yeargroup' ],
            originalFilters: []
        }
    },

    created()
    {
        this.prepareTabs()
        this.visible = true
    },

    methods: {

        prepareTabs()
        {
            let firstTab
            switch( this.$props.object.type )
            {
                case 'student':
                    firstTab = ( this.$core.settings().getSetting( 'studentFileAddTests' ) === true ? 'lists' : 'lists_not_test' )
                    this.tabs = [
                        {
                            id        : firstTab,
                            caption   : 'pädagogische Akte',
                            objectType: 'lists',
                            viewItem  : 'List'
                        },
                        {
                            id        : 'lists_only_test',
                            caption   : 'Benotung',
                            objectType: 'lists',
                            viewItem  : 'List'
                        },
                        {
                            id     : 'quick',
                            caption: 'Schnellansicht'
                        },
                        {
                            id     : 'scorings',
                            caption: this.$core.t( 'object-type-scorings' ),
                        },
                        {
                            id        : 'notes',
                            caption   : 'Notizen',
                            objectType: 'notes',
                            viewItem  : 'Note'
                        },
                        {
                            id        : 'todos',
                            caption   : this.$core.t( 'object-type-todos' ),
                            objectType: 'todos',
                            viewItem  : 'Todo'
                        },
                        {
                            id        : 'media',
                            caption   : 'Dokumente',
                            objectType: 'media',
                            viewItem  : 'Media'
                        },
                        {
                            id     : 'planner',
                            caption: 'Kalender'
                        },
                        {
                            id     : 'data',
                            caption: 'Stammdaten'
                        } ]
                    this.availableFilters = [ 'LabelsFilter', 'ColorFilter' ]
                    this.tab = firstTab
                    break
                case 'class':
                    this.tabs = [
                        {
                            id        : 'lists_not_test',
                            caption   : 'Büro',
                            objectType: 'lists',
                            viewItem  : 'List'
                        },
                        {
                            id        : 'lists_only_test',
                            caption   : 'Benotung',
                            objectType: 'lists',
                            viewItem  : 'List'
                        },
                        {
                            id     : 'scorings',
                            caption: this.$core.t( 'object-type-scorings' ),
                        },
                        {
                            id        : 'notes',
                            caption   : 'Notizen',
                            objectType: 'notes',
                            viewItem  : 'Note'
                        },
                        {
                            id        : 'todos',
                            caption   : this.$core.t( 'object-type-todos' ),
                            objectType: 'todos',
                            viewItem  : 'Todo'
                        },
                        {
                            id        : 'media',
                            caption   : 'Dokumente',
                            objectType: 'media',
                            viewItem  : 'Media'
                        },
                        {
                            id     : 'planner',
                            caption: 'Kalender'
                        },
                        {
                            id              : 'students',
                            caption         : 'Schüler:innen',
                            objectType      : 'students',
                            viewItem        : 'Student',
                            availableFilters: 'gender' === this.$core.settings().getSetting( 'studentColorModel' ) ?
                                              [ 'StudentFilter' ] : [ 'StudentFilter', 'ColorFilter' ]
                        } ]
                    this.availableFilters = [ 'LabelsFilter', 'ColorFilter' ]
                    this.tab = 'lists_not_test'
                    break
                case 'group':
                case 'yeargroup':
                    this.tabs = [
                        {
                            id        : 'lists',
                            caption   : 'Büro',
                            objectType: 'lists',
                            viewItem  : 'List'
                        },
                        {
                            id        : 'notes',
                            caption   : 'Notizen',
                            objectType: 'notes',
                            viewItem  : 'Note'
                        },
                        {
                            id        : 'todos',
                            caption   : this.$core.t( 'object-type-todos' ),
                            objectType: 'todos',
                            viewItem  : 'Todo'
                        },
                        {
                            id        : 'media',
                            caption   : 'Dokumente',
                            objectType: 'media',
                            viewItem  : 'Media'
                        },
                        {
                            id     : 'planner',
                            caption: 'Kalender'
                        },
                        {
                            id        : 'students',
                            caption   : 'Schüler:innen',
                            objectType: 'students',
                            viewItem  : 'Student'
                        } ]
                    this.availableFilters = [ 'LabelsFilter', 'ColorFilter' ]
                    this.tab = 'lists'
                    break
                case 'colleague':
                    this.tabs = [
                        {
                            id       : 'coll_meta',
                            caption  : 'Info',
                            component: 'ColleagueMeta'
                        },
                        {
                            id       : 'coll_rights_general',
                            caption  : 'Generelle Berechtigungen',
                            component: 'ColleagueGeneralRights'
                        },
                        {
                            id       : 'coll_rights_objects',
                            caption  : 'Element-Berechtigungen',
                            component: 'ColleagueObjectRights'
                        },
                        {
                            id       : 'coll_sharedwithme',
                            caption  : 'mit mir geteilte Elemente',
                            component: 'ColleagueSharedWithMe'
                        }
                    ]
                    this.availableFilters = []
                    this.tab = 'coll_meta'
                    break
            }
            this.originalFilters = JSON.parse( JSON.stringify( this.availableFilters ) )
            this.cleanTabs()
        },

        cleanTabs()
        {
            let clean = []
            for( let t in this.tabs )
            {

                this.tabMap.set( this.tabs[ t ].id, this.tabs[ t ] )

                let temp = this.tabs[ t ].id.split( '_' ),
                    type = temp[ 0 ]

                if( ( 'planner' === this.tabs[ t ].id
                      || 'scorings' === this.tabs[ t ].id
                      || 'quick' === this.tabs[ t ].id ) && 'archive' === this.scope )
                {
                    continue
                }
                else if( 'data' === this.tabs[ t ].id
                         || this.$core.getLicense().isAllowed( 'page_' + type ) )
                {
                    clean.push( this.tabs[ t ] )
                }
                else
                {
                    if( this.$core.getLicense().isAllowed( undefined, this.tabs[ t ].id ) )
                    {
                        clean.push( this.tabs[ t ] )
                    }
                }
            }
            this.tabs = clean
        },

        getTabSetupById( tab )
        {
            for( let t in this.tabs )
            {
                if( this.tabs[ t ].id === tab )
                {
                    return this.tabs[ t ]
                }
            }
        },

        setTab( tab )
        {

            this.ready = false
            this.counts = {}
            this.tab = tab

            for( let t in this.tabs )
            {
                if( this.tabs[ t ].id === tab
                    && undefined !== this.tabs[ t ].availableFilters )
                {
                    this.availableFilters = this.tabs[ t ].availableFilters
                }
                else
                {
                    this.availableFilters = this.originalFilters
                }
            }

            this.prepareItemList()
                .then( () =>
                {
                    this.$nextTick()
                        .then( () =>
                        {
                            this.viewId = 'detail-view-' + this.$core.getUuid().generate()
                            this.changeKey = this.$core.getUuid().generate()
                            this.ready = true
                        } )
                } )

        },

        prepareItemList()
        {
            return new Promise( resolve =>
            {

                if( undefined !== this.tabMap.get( this.tab ).component )
                {
                    return resolve()
                }

                this.setupFilter()

                this.awaitNeededCaches()
                    .then( () =>
                    {

                        this.setupReferences()
                            .then( () =>
                            {

                                this._fetchAll( this.objectList.type )
                                    .then( allObjects =>
                                    {

                                        let list = []

                                        for( let o in allObjects )
                                        {
                                            if( this.filterMatch( allObjects[ o ] ) )
                                            {
                                                list.push( allObjects[ o ] )
                                            }
                                        }

                                        let sortingDirections = this.$core.settings().getSetting( 'sortingDirections' )
                                        list = this.$core.getSorter().multiSortObjects( list, sortingDirections[this.objectList.type] )

                                        this.objectType = this.objectList.type

                                        this.prepared[ this.tab ] = list

                                        this.counts = {
                                            visible  : undefined !== this.counts.visible ? this.counts.visible : list.length,
                                            invisible: 0
                                        }
                                        this.ready = true

                                        return resolve()

                                    } )

                            } )

                    } )

            } )
        }

    }
}
</script>