/*eslint-disable*/
import imagesHashes from '@/classes/Helpers/Printing/Modules/ImagesHashes'

function _fontReset( doc, defaultColor )
{
    doc.setFont( 'quicksand', 'normal' )
    doc.setFontSize( 10 )
    if( undefined !== defaultColor )
    {
        doc.setTextColor( defaultColor[ 0 ], defaultColor[ 1 ], defaultColor[ 2 ] )
    }
}

function _printHeadline( doc, docHeadline, y )
{

    y = undefined === y ? 0 : y

    doc.setFont( 'quicksand', 'bold' )
    doc.setFontSize( 14 )
    doc.setTextColor( docHeadline.headline.color[ 0 ], docHeadline.headline.color[ 1 ], docHeadline.headline.color[ 2 ] )
    doc.text( docHeadline.headline.x, ( y + docHeadline.headline.y ), docHeadline.headline.text )

    _fontReset( doc )
    doc.text( docHeadline.timestamp.x, ( y + docHeadline.timestamp.y ), ' | ' + docHeadline.timestamp.text )

    doc.setDrawColor( docHeadline.headline.color[ 0 ], docHeadline.headline.color[ 1 ], docHeadline.headline.color[ 2 ] )
    for( let l in docHeadline.lines )
    {
        let line = docHeadline.lines[ l ]
        doc.line( line.x, ( y + line.y ), line.x2, ( y + line.y2 ) )
    }

    _fontReset( doc )
    return y // ( y + docHeadline.y )

}

function _getSubheaderOffset( subdimensions, index )
{
    if( subdimensions instanceof Array )
    {
        if( null !== subdimensions[ index ] )
        {
            return subdimensions[ index ].h + 1
        }
    }
    return 0
}

function _printHeaders( doc, y, yOffset, headers )
{

    if( undefined !== headers )
    {

        let setup = headers.headerSetup
        if( undefined !== setup.rect )
        {
            doc.setFillColor( setup.color[ 0 ], setup.color[ 1 ], setup.color[ 2 ] )
            doc.rect( setup.rect.x, yOffset + setup.rect.y, setup.rect.x2, setup.rect.y2, setup.rect.type )
        }

        doc.setDrawColor( 220, 220, 220 )
        if( undefined !== setup.lines && 0 < setup.lines.length )
        {
            for( let l in setup.lines )
            {
                let line = setup.lines[ l ]
                doc.line( line.x, yOffset + line.y, line.x2, yOffset + line.y2 )
            }
        }

        doc.setFont( 'quicksand', 'bold' )
        if( undefined !== setup.headerFields )
        {
            for( let f in setup.headerFields )
            {
                let field = setup.headerFields[ f ]
                let yOffsetSub = _getSubheaderOffset( setup.subDimensions, f )
                doc.text( field.x, ( field.y - yOffsetSub + yOffset ), field.text, field.angle )
            }
        }

        doc.setFont( 'quicksand', 'normal' )
        if( setup.subHeaders instanceof Array )
        {
            for( let s in setup.subHeaders )
            {
                let sub = setup.subHeaders[ s ]
                let text = sub.text
                if( null !== text )
                {
                    let dims = doc.getTextDimensions( text )
                    doc.line( sub.x, ( sub.y + yOffset - dims.h ), ( sub.x + dims.w ), ( sub.y + yOffset - dims.h ) )
                    doc.text( sub.x, sub.y + yOffset, text, sub.angle )
                }
            }
        }

        _fontReset( doc )
        return headers.headerY

    }
    return y

}

function _printRow( doc, y, yOffset, row, pageSetup, rowNumber, defaultColor, noBorders )
{

    let rect   = pageSetup.rows.setup.rects[ rowNumber ],
        lines  = pageSetup.rows.setup.lines[ rowNumber ],
        defs   = pageSetup.raw.rows.defs,
        images = pageSetup.raw.rows.images

    if( rect instanceof Array )
    {
        for( let r in rect )
        {
            if( undefined !== rect[ r ] )
            {
                if( rect[ r ].fillcolor instanceof Array )
                {
                    doc.setFillColor( rect[ r ].fillcolor[ 0 ], rect[ r ].fillcolor[ 1 ], rect[ r ].fillcolor[ 2 ] )
                }
                else
                {
                    doc.setFillColor( rect[ r ].fillcolor )
                }
                doc.rect( rect[ r ].rect.x, ( rect[ r ].rect.y - yOffset ), rect[ r ].rect.x2, rect[ r ].rect.y2, rect[ r ].rect.type )
                if( rect[ r ].label !== undefined )
                {
                    doc.setFontSize( 10 )
                    doc.text( rect[ r ].rect.x + 1, ( rect[ r ].rect.y + rect[ r ].label.dims.h ), rect[ r ].label.text )
                    _fontReset( doc )
                }
            }
        }
    }
    else
    {
        if( undefined !== rect )
        {
            if( rect.fillcolor instanceof Array )
            {
                doc.setFillColor( rect.fillcolor[ 0 ], rect.fillcolor[ 1 ], rect.fillcolor[ 2 ] )
            }
            else
            {
                doc.setFillColor( rect.fillcolor )
            }
            doc.rect( rect.rect.x, ( rect.rect.y - yOffset ), rect.rect.x2, rect.rect.y2, rect.rect.type )
        }
    }

    if( undefined !== lines )
    {
        doc.setDrawColor( 220, 220, 220 )
        for( let l in lines )
        {
            if( !noBorders || parseInt( l ) < 1 )
            {
                let line = lines[ l ]
                doc.line( line.x, ( line.y - yOffset ), line.x2, ( line.y2 - yOffset ) )
            }
        }
    }

    if( undefined !== row && 0 < row.length )
    {
        for( let r in row )
        {
            _fontReset( doc, defaultColor )
            if( undefined !== defs[ rowNumber ][ r ] )
            {
                switch( defs[ rowNumber ][ r ].type )
                {
                    case 'text':
                        if( 0 === parseInt( r ) )
                        {
                            doc.setFont( 'quicksand', 'bold' )
                        }
                        else
                        {
                            doc.setFont( 'quicksand', 'normal' )
                        }
                        if( undefined !== defs[ rowNumber ][ r ].color )
                        {
                            doc.setTextColor( defs[ rowNumber ][ r ].color[ 0 ], defs[ rowNumber ][ r ].color[ 1 ], defs[ rowNumber ][ r ].color[ 2 ] )
                        }
                        if( undefined !== defs[ rowNumber ][ r ].style )
                        {
                            doc.setFont( 'quicksand', defs[ rowNumber ][ r ].style )
                        }
                        if( undefined !== row[ r ].style )
                        {
                            doc.setFont( 'quicksand', row[ r ].style )
                        }
                        doc.text( row[ r ].x, ( parseFloat( '' + row[ r ].y ) - yOffset ), row[ r ].text )
                        break
                    case 'image':
                        for( let i in images[ rowNumber ][ r ] )
                        {

                            let image = images[ rowNumber ][ r ][ i ]
                            let imgX = row[ r ].x + ( parseInt( i ) * image.size )
                            let imgY = ( row[ r ].y - yOffset ) - image.size + image.padding
                            let opacityChange = ( -1 < image.img.indexOf( 'inactive' ) )
                            if( opacityChange )
                            {
                                doc.saveGraphicsState()
                                doc.setGState( new doc.GState( { opacity: 0.3 } ) )
                            }
                            doc.addImage( imagesHashes[ image.img ], 'png', imgX, imgY, image.size, image.size, image.img, 'FAST' )
                            if( opacityChange )
                            {
                                doc.restoreGraphicsState()
                            }
                        }
                        break
                    default:
                        break
                }
            }
        }
    }

    _fontReset( doc )

}

function _printCutmark( doc, y )
{

    y = y + 10
    doc.setDrawColor( 150, 150, 150 )
    for( let x = 12.5; x < 200; x += 10 )
    {
        doc.line( x, y, ( x + 5 ), y )
    }

    doc.addImage( imagesHashes[ 'cutter.png' ], 'png', 16, ( y - 3.5 ), 7, 7, 'cutter.png', 'FAST' )

    return ( y + 10 )

}

function _printFooter( doc, footer, page, pageNumber )
{

    let color = footer.color

    doc.setDrawColor( color[ 0 ], color[ 1 ], color[ 2 ] )
    for( let l in footer.lines )
    {
        let line = footer.lines[ l ]
        doc.line( line.x, line.y, line.x2, line.y2 )
    }

    for( let t in footer.texts )
    {
        let text = footer.texts[ t ]
        let textOut = '' + text.text
        if( '~~' === textOut )
        {
            textOut = '' + page
        }
        if( '__' === textOut )
        {
            textOut = '' + pageNumber
        }
        doc.setFont( 'quicksand', text.style )
        doc.setFontSize( text.size )
        if( undefined === text.link )
        {
            doc.text( text.x, text.y, textOut, { align: text.align } )
        }
        else
        {
            doc.textWithLink( textOut, text.x, text.y, { align: text.align, url: text.link } )
        }

    }

    _fontReset( doc )

}

function _getStackLineHeight( rect, rowNumber )
{
    return rect[ rowNumber ].rect.y2
}

function _getStacks( rows, pageSetup, headerHeight, hasComments )
{
    let stacks = []
    let stackBreak = false
    let s = -1
    let rowNumber = 0
    let factor = hasComments ? 2 : 1

    while( rows.length > 0 )
    {
        let row = rows.shift()
        if( rowNumber % factor === 0 )
        {
            stackBreak = true
        }

        if( stackBreak )
        {
            stacks.push( { stack: [], height: headerHeight } )
            s++
            stackBreak = false
        }

        let height = _getStackLineHeight( pageSetup.rows.setup.rects, rowNumber )
        stacks[ s ].height += height
        stacks[ s ].stack.push( row )
        rowNumber++
    }

    return stacks

}


function printTestWithCutmarks( doc, maxHeight, template, docHeadline, pageSetup, docFooter, filename )
{

    let pageNumber = 1
    let y = 0
    let rowNumber = 0
    let yOffset = 0
    let cutmarkOffset = 0

    let pageTop = docHeadline.y
    let pageHeight = maxHeight
    let usedHeight = 0

    let headY = -1

    let footer = false
    let hasComments = pageSetup.rows.setup.hasComments
    let headerHeight = pageTop + pageSetup.headers.headerY

    let stacks = _getStacks( pageSetup.rows.setup.cells, pageSetup, headerHeight, hasComments )

    for( let s in stacks )
    {

        footer = false

        y = _printHeadline( doc, docHeadline, y )
        y += _printHeaders( doc, y, y, pageSetup.headers )

        let stack = stacks[ s ]
        for( let r in stack.stack )
        {
            let noBorders = hasComments && parseInt( r ) > 0
            _printRow( doc, y, ( yOffset - cutmarkOffset ), stack.stack[ r ], pageSetup, rowNumber, docHeadline.headline.color, noBorders )
            rowNumber++
        }

        y += ( stack.height - pageSetup.headers.headerY )
        y = _printCutmark( doc, y ) - 10
        cutmarkOffset += 10 + headerHeight

        if( undefined !== stacks[ parseInt( s ) + 1 ] )
        {
            let checkHeight = ( y + stacks[ parseInt( s ) + 1 ].height )
            if( checkHeight > pageHeight )
            {
                footer = true
                yOffset += y
                y = 0
                pageNumber++
                doc.addPage()
            }
        }

    }

    for( let i = 0; i < pageNumber; i++ )
    {
        let p = i + 1
        doc.setPage( p )
        //_printFooter( doc, docFooter, pageNumber, p )
    }

}

export default printTestWithCutmarks