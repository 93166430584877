/*eslint-disable*/
import imagesHashes from '@/classes/Helpers/Printing/Modules/ImagesHashes'

function _fontReset( doc, defaultColor )
{
    doc.setFont( 'quicksand', 'normal' )
    doc.setFontSize( 10 )
    if( undefined !== defaultColor )
    {
        doc.setTextColor( defaultColor[ 0 ], defaultColor[ 1 ], defaultColor[ 2 ] )
    }
}

function _printHeadline( doc, docHeadline, y )
{

    y = undefined === y ? 0 : y

    doc.setFont( 'quicksand', 'bold' )
    doc.setFontSize( 14 )
    doc.setTextColor( docHeadline.headline.color[ 0 ], docHeadline.headline.color[ 1 ], docHeadline.headline.color[ 2 ] )
    doc.text( docHeadline.headline.x, ( y + docHeadline.headline.y ), docHeadline.headline.text )

    _fontReset( doc )
    doc.text( docHeadline.timestamp.x, ( y + docHeadline.timestamp.y ), ' | ' + docHeadline.timestamp.text )

    doc.setDrawColor( docHeadline.headline.color[ 0 ], docHeadline.headline.color[ 1 ], docHeadline.headline.color[ 2 ] )
    for( let l in docHeadline.lines )
    {
        let line = docHeadline.lines[ l ]
        doc.line( line.x, ( y + line.y ), line.x2, ( y + line.y2 ) )
    }

    _fontReset( doc )
    return y // ( y + docHeadline.y )

}

function _printRow( doc, y, yOffset, row, pageSetup, rowNumber, defaultColor )
{

    let rect   = pageSetup.rows.setup.rects[ rowNumber ],
        lines  = pageSetup.rows.setup.lines[ rowNumber ],
        defs   = pageSetup.raw.rows.defs,
        images = pageSetup.raw.rows.images

    if( undefined !== rect )
    {
        doc.setFillColor( rect.fillcolor )
        doc.rect( rect.rect.x, ( rect.rect.y - yOffset ), rect.rect.x2, rect.rect.y2, rect.rect.type )
    }

    if( undefined !== lines )
    {
        doc.setDrawColor( 220, 220, 220 )
        for( let l in lines )
        {
            let line = lines[ l ]
            doc.line( line.x, ( line.y - yOffset ), line.x2, ( line.y2 - yOffset ) )
        }
    }

    if( undefined !== row && 0 < row.length )
    {
        for( let r in row )
        {
            _fontReset( doc, defaultColor )
            switch( defs[ rowNumber ][ r ].type )
            {
                case 'text':
                    if( 0 === parseInt( r ) )
                    {
                        doc.setFont( 'quicksand', 'bold' )
                    }
                    else
                    {
                        doc.setFont( 'quicksand', 'normal' )
                    }
                    if( undefined !== defs[ rowNumber ][ r ].color )
                    {
                        doc.setTextColor( defs[ rowNumber ][ r ].color[ 0 ], defs[ rowNumber ][ r ].color[ 1 ], defs[ rowNumber ][ r ].color[ 2 ] )
                    }
                    if( undefined !== defs[ rowNumber ][ r ].style )
                    {
                        doc.setFont( 'quicksand', defs[ rowNumber ][ r ].style )
                    }
                    doc.text( row[ r ].x, ( parseFloat( '' + row[ r ].y ) - yOffset ), row[ r ].text )
                    break
                case 'image':
                    for( let i in images[ rowNumber ][ r ] )
                    {
                        let image = images[ rowNumber ][ r ][ i ]
                        let imgX = row[ r ].x + ( i * image.size )
                        let imgY = ( row[ r ].y - yOffset ) - image.size + image.padding
                        let opacityChange = ( -1 < image.img.indexOf( 'inactive' ) )
                        if( opacityChange )
                        {
                            doc.saveGraphicsState()
                            doc.setGState( new doc.GState( { opacity: 0.3 } ) )
                        }
                        doc.addImage( imagesHashes[ image.img ], 'png', imgX, imgY, image.size, image.size, image.img, 'FAST' )
                        if( opacityChange )
                        {
                            doc.restoreGraphicsState()
                        }
                    }
                    break
                default:
                    break
            }

        }
    }

    _fontReset( doc )

}

function _printFooter( doc, footer, page, pageNumber )
{

    let color = footer.color

    doc.setDrawColor( color[ 0 ], color[ 1 ], color[ 2 ] )
    for( let l in footer.lines )
    {
        let line = footer.lines[ l ]
        doc.line( line.x, line.y, line.x2, line.y2 )
    }

    for( let t in footer.texts )
    {
        let text = footer.texts[ t ]
        let textOut = '' + text.text
        if( '~~' === textOut )
        {
            textOut = '' + page
        }
        if( '__' === textOut )
        {
            textOut = '' + pageNumber
        }
        doc.setFont( 'quicksand', text.style )
        doc.setFontSize( text.size )
        if( undefined === text.link )
        {
            doc.text( text.x, text.y, textOut, { align: text.align } )
        }
        else
        {
            doc.textWithLink( textOut, text.x, text.y, { align: text.align, url: text.link } )
        }

    }

    _fontReset( doc )

}

function _printCutmark( doc, y )
{

    y = y + 10
    doc.setDrawColor( 150, 150, 150 )
    for( let x = 12.5; x < 200; x += 10 )
    {
        doc.line( x, y, ( x + 5 ), y )
    }

    doc.addImage( imagesHashes[ 'cutter.png' ], 'png', 16, ( y - 3.5 ), 7, 7, 'cutter.png', 'FAST' )

    return ( y + 10 )

}

function _getStackLineHeight( rect, rowNumber )
{
    return rect[ rowNumber ].rect.y2
}

function _getStacks( rows, pageSetup, headerHeight )
{
    let stacks     = [],
        stackBreak = false,
        s          = -1,
        rowNumber  = 0,
        y          = null

    while( rows.length > 0 )
    {

        let row = rows.shift()

        if( null === y )
        {
            y = row[rowNumber].y
        }

        if( 'header' === pageSetup.raw.rows.defs[ rowNumber ][ 0 ].def )
        {
            stackBreak = true
        }

        if( stackBreak )
        {
            stacks.push( { stack: [], height: headerHeight } )
            s++
            stackBreak = false
        }

        let height = _getStackLineHeight( pageSetup.rows.setup.rects, rowNumber )
        stacks[ s ].height += height

        for( let r in row )
        {
            row[r].y = y
        }

        stacks[ s ].stack.push( row )
        y+= height
        rowNumber++

    }

    return stacks

}

function countCutables( maxHeight, pageSetup, docHeadline )
{

    let usedHeight = 0

    let rows = JSON.parse( JSON.stringify( pageSetup.rows.setup.cells ) )
    let stacks = _getStacks( rows, pageSetup, docHeadline )
    let pages = 1
    for( let s in stacks )
    {

        let stack = stacks[ s ]
        usedHeight += ( stack.height - 10 )

        if( usedHeight + stack.height > maxHeight )
        {
            usedHeight = stack.height
            pages++
        }

    }

    return pages

}

function _fixRects( pageSetup )
{

    let y = null

    for( let r in pageSetup.rows.setup.rects )
    {

        if( null === y )
        {
            y = pageSetup.rows.setup.rects[0].rect.y
        }

        pageSetup.rows.setup.rects[r].rect.y = y
        y+= pageSetup.rows.setup.rects[r].rect.y2

    }

}


function _fixLines( pageSetup )
{


    let y = null

    for( let l in pageSetup.rows.setup.lines )
    {

        if( null === y )
        {
            y = pageSetup.rows.setup.rects[0].rect.y
        }

        let height = pageSetup.rows.setup.rects[l].rect.y2

        for( let ll in pageSetup.rows.setup.lines[l] )
        {
            pageSetup.rows.setup.lines[l][ll].y = y
            pageSetup.rows.setup.lines[l][ll].y2 = ( y + height )
        }

        y+= height

    }

}

function printCutables( doc, maxHeight, template, docHeadline, pageSetup, docFooter, filename )
{

    let pageNumber    = 1,
        y             = 0,
        rowNumber     = 0,
        yOffset       = 0,
        cutmarkOffset = 0,
        pageTop       = docHeadline.y,
        headerHeight  = pageTop + pageSetup.headers.headerY,
        stacks        = _getStacks( pageSetup.rows.setup.cells, pageSetup, headerHeight ),
        footer        = false

    _fixRects( pageSetup )
    _fixLines( pageSetup )

    for( let s in stacks )
    {

        footer = false

        y = _printHeadline( doc, docHeadline, y )

        let stack = stacks[ s ]
        for( let r in stack.stack )
        {

            _printRow( doc, y, ( yOffset - cutmarkOffset ), stack.stack[ r ], pageSetup, rowNumber, docHeadline.headline.color )
            rowNumber++
        }

        y += stack.height
        y = _printCutmark( doc, y - 10 )
        cutmarkOffset += headerHeight + 10

        if( undefined !== stacks[ parseInt( s ) + 1 ] )
        {
            let checkHeight = ( y + stacks[ parseInt( s ) + 1 ].height ) - 10
            if( checkHeight > maxHeight )
            {
                footer = true
                yOffset += y
                y = 0
                pageNumber++
                doc.addPage()
            }
        }

    }

    for( let i = 0; i < pageNumber; i++ )
    {
        let p = i + 1
        doc.setPage( p )
        //_printFooter( doc, docFooter, pageNumber, p )
    }

}

export default printCutables