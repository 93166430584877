<template>
    <div>
        <div class="select-wrapper" v-if="isEditor()" @click="openSelectBox()">
            <select :id="'form-element-'+refName" @change="handleChange()" @click="$core.f().skip">
                <option value="">bitte wählen...</option>
                <option v-for="gender in genders"
                        :key="'key_select_'+gender.value"
                        :value="gender.value">{{ gender.caption }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>

export default {

    name : 'GenderSelector',
    emits: [ 'update', 'change' ],
    props: {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        editor     : { Type: String, required: false },
        styleUpdate: { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false }
    },

    data()
    {
        return {
            genders: [
                {
                    value  : 'male',
                    caption: this.$core.getTranslation().translate( 'male' )
                },
                {
                    value  : 'female',
                    caption: this.$core.getTranslation().translate( 'female' )
                },
                {
                    value  : 'divers',
                    caption: this.$core.getTranslation().translate( 'divers' )
                }
            ],
            formElm: false
        }
    },

    updated()
    {
        this.select()
    },

    mounted()
    {
        this.formElm = document.querySelector( '#form-element-' + this.$props.refName )
        this.select()
    },

    methods: {
        select()
        {
            if( null === this.formElm )
            {
                return
            }
            if( undefined !== this.$props.value )
            {
                this.formElm.value = this.$props.value
                this.handleChange()
            }
            else
            {
                this.formElm.value = this.getFilterColor()
            }
        },

        handleChange()
        {

            if( null !== this.formElm )
            {
                this.$emit( 'change', this.$props.refName, this.formElm.value )
                this.$emit( 'update', this.$props.refName, 'genderSelector', 'update', undefined, this.formElm.value )
                this.$core.getEventManager()
                    .dispatch( 'on-color-selected', this.formElm.value )
            }

        },

        getFilterColor()
        {
            for( let g in this.genders )
            {
                let gender = this.genders[ g ].value
                let element = document.querySelector( '#gender-filter-gender-' + gender )
                if( null !== element )
                {
                    if( element.classList.contains( 'active' ) )
                    {
                        return gender
                    }
                }
            }
            return ''
        },

        isEditor()
        {
            return ( undefined === this.$props.display || this.$props.editor === true )
        },

        openSelectBox()
        {
            let event = new MouseEvent( 'click' )
            let element = document.querySelector( '#' + this.$props.refName )
            if( null !== element )
            {
                element.dispatchEvent( event )
            }
        }

    }

}
</script>