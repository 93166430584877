<template>
    <template v-if="plain">
        <input type="password"
               :name="id"
               :id="id"
               :v-model="vModel"
               :ref="refName"
               :autocomplete="autocomplete"
               @blur="update()"
               @keyup="checkKey"/>
    </template>
    <template v-else>
        <template v-if="true === showComplexity || 'new-password' === autocomplete">
            <div class="password">
                <div class="pw-left">
                    <input type="password"
                           :id="'form-element-bare-'+id"
                           :autocomplete="autocomplete"
                           @change="checkPasswordComplexity()"
                           @keyup="checkPasswordComplexity()"/>
                    <input v-if="true === showComplexity"
                           type="hidden"
                           :id="'password-strength-'+id"
                           :value="complexState"/>
                </div>
                <div v-if="true === showComplexity" v-show="active" class="pw-right">
                    <span :class="'complexity '+complexity">{{ message }}</span>
                </div>
                <div class="clearfix"></div>
            </div>
            <div v-if="showHint" class="pw-hint">
                <strong>Hinweis</strong>: Wähle bitte ein möglichst komplexes Passwort, das sich nicht einfach erraten
                lässt.<br/>
                Optimalerweise enthält dein Passwort neben großen und kleinen Buchstaben auch Zahlen & Sonderzeichen.
            </div>
        </template>
        <div v-else>
            <input type="password"
                   :name="id"
                   :id="( noAddFormElement ? '' : 'form-element-' )+id"
                   :ref="refName"
                   autocomplete="current-password"
                   @blur="update()"
                   @change="update()"
                   @keyup="checkKey"/>
        </div>
    </template>
</template>

<script>
export default {

    name: 'PasswordBox',

    props: {
        id              : { Type: String, required: true },
        refName         : { Type: String, required: false },
        vModel          : { Type: String, required: false },
        autocomplete    : { Type: String, required: false },
        eventKey        : { Type: String, required: false },
        showHint        : { Type: Boolean, required: false, default: false },
        showComplexity  : { Type: Boolean, required: false, default: false },
        noAddFormElement: { Type: Boolean, required: false, default: false },
        plain           : { Type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            active      : false,
            message     : 'bad',
            complexity  : 'weak',
            complexState: ''
        }
    },

    emits: [ 'enterPressed', 'update' ],

    methods: {
        checkKey( key )
        {
            if( 13 === key.keyCode
                || 13 === key.which
                || 'Enter' === key.key )
            {
                event.preventDefault()
                this.$emit( 'enterPressed' )
            }
            else
            {
                this.update()
            }
        },

        update()
        {
            let elm = document.querySelector( '#' + ( this.$props.noAddFormElement ? '' : 'form-element-' ) + this.$props.id )
            if( null !== elm )
            {
                this.$emit( 'update', this.$props.refName, 'passwordBox', 'update', undefined, elm.value )
            }
        },

        checkPasswordComplexity()
        {

            let elm = document.querySelector( '#form-element-bare-' + this.$props.id )
            if( null !== elm )
            {
                let value = elm.value
                if( value.length < 6 && value.length > 0 )
                {
                    this.active = true
                    this.message = 'zu kurz'
                    this.complexity = 'weak'
                    this.complexState = 'tooshort'
                }
                else
                {
                    if( value.length >= 24 )
                    {
                        this.active = true
                        this.message = 'stark'
                        this.complexity = 'good'
                        this.complexState = 'pass'
                    }
                    else
                    {
                        if( value.length >= 6 )
                        {

                            let veryStrongPassword = new RegExp( '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{12,})' )
                            let strongPassword = new RegExp( '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})' )
                            let mediumPassword = new RegExp( '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))' )

                            if( veryStrongPassword.test( value ) )
                            {
                                this.active = true
                                this.message = 'sehr stark'
                                this.complexity = 'excellent'
                                this.complexState = 'pass'
                            }
                            else if( strongPassword.test( value ) )
                            {
                                this.active = true
                                this.message = 'stark'
                                this.complexity = 'good'
                                this.complexState = 'pass'
                            }
                            else if( mediumPassword.test( value ) )
                            {
                                this.active = true
                                this.message = 'mittelmäßig'
                                this.complexity = 'medium'
                                this.complexState = 'pass'
                            }
                            else
                            {
                                this.active = true
                                this.message = 'schwach'
                                this.complexity = 'weak'
                                this.complexState = 'weak'
                            }
                        }
                        else
                        {
                            if( value.length === 0 )
                            {
                                this.complexState = 'empty'
                                this.active = false
                            }
                        }
                    }
                }
            }
        }
    }
}
</script>