/*eslint-disable*/
const MicRecorder = require( 'mic-recorder-to-mp3' )

export default class AudioRecorder
{

    constructor( core )
    {

        if( !AudioRecorder.instance )
        {

            this.logger = core.getLogger()

            this.audioBlobs = []
            this.mediaRecorder = null
            this.streamBeingCaptured = null

            this.cryptoHelper = core.getCryptoHelper()

            this.featureAvailable = !( !( navigator.mediaDevices && navigator.mediaDevices.getUserMedia ) )
            this.logSign = 'Core::Helpers::Media::AudioRecorder'

            AudioRecorder.instance = this

        }

        return AudioRecorder.instance

    }

    start()
    {
        return new Promise( ( resolve, reject ) =>
        {

            this.recorder = new MicRecorder( { bitRate: 64 } )
            this.recorder.start()
                .then( () =>
                {
                    return resolve()
                } )
                .catch( e =>
                {
                    return reject( e )
                } )

        } )
    }

    stop()
    {
        return new Promise( ( resolve, reject ) =>
        {

            this.recorder.stop()
                .getMp3()
                .then( ( [ buffer, blob ] ) =>
                {

                    let reader    = new FileReader()
                    reader.onloadend = () =>
                    {

                        return resolve( reader.result )

                    }
                    reader.readAsDataURL( blob )

                } )
                .catch( e =>
                {
                    return reject( e )
                } )

        } )
    }

    stopStream()
    {
        this.streamBeingCaptured
            .getTracks() //get all tracks from the stream
            .forEach( track /*of type MediaStreamTrack*/ => track.stop() );
    }

    resetRecordingProperties()
    {

        this.mediaRecorder = null;
        this.streamBeingCaptured = null;

        /*No need to remove event listeners attached to mediaRecorder as
        If a DOM element which is removed is reference-free (no references pointing to it), the element itself is picked
        up by the garbage collector as well as any event handlers/listeners associated with it.
        getEventListeners(audioRecorder.mediaRecorder) will return an empty array of events.*/

    }

}