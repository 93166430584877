<template>
    <div class="list-element rateflexselector">
        <div v-for="rate in rates" :key="'flex-'+rate">
            <div v-if="rate === value"
                 v-bind:class="'rateflex-element fixed value-'+rate+' rate-'+rate+( rate === value ? ' selected' : '' )"
                 :title="translate[ rate ].full">
                <span><strong>{{ translate[ rate ].full }}</strong></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'listElement-rateflexselector',
    props: {
        value   : { type: [Number,String], required: false, default: 0 }
    },

    data()
    {
        return {
            rates        : [ 0, 1, 2, 3 ],
            translate    : {
                0: {
                    rate: 'A',
                    full: 'Anfänge'
                },
                1: {
                    rate: 'B',
                    full: 'Basiskenntnisse'
                },
                2: {
                    rate: 'G',
                    full: 'Gesicherte Kenntnisse'
                },
                3: {
                    rate: 'V',
                    full: 'Vertiefte Kenntnisse'
                },
            }
        }
    },
}
</script>
