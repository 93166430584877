<template>
    <div>
        <div :class="'select-wrapper'">
            <select :disabled="disabledSelect" class="select_element" v-bind:id="'form-element-'+refName"
                    @click="$core.f().skip"
                    @change="handleSelect()">
                <option value="">bitte wählen...</option>
                <option v-for="option in optionValues"
                        :key="'key_option_'+option.key"
                        :selected="value === option.key"
                        :value="option.key">{{ option.caption }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {

    name : 'ElementTypeSelector',
    props: {
        refName       : { type: String, required: false, default: 'elementTypeSelector' },
        value         : { type: String, required: false, default: undefined },
        prefillParams : { type: Object, required: false, default: undefined },
        disabledSelect: { type: Boolean, required: false, default: false }
    },

    emits: [ 'nestedForm' ],

    data()
    {
        return {
            optionValues: [
            ]
        }
    },

    mounted()
    {

        let filter = this.getFilterElement()

        if( '' !== filter )
        {
            this.$nextTick()
                .then( () =>
                {
                    let selectElm = document.querySelector( '#form-element-' + this.$props.refName )
                    selectElm.value = filter
                    this.emitNestedForm( filter )
                } )
        }

        if( this.$core.getLicense().isAllowed( 'page_dates' ) )
        {
            this.optionValues.push( { key: 'date', caption: 'Termin' } )
        }
        if( this.$core.getLicense().isAllowed( 'page_notes' ) )
        {
            this.optionValues.push( { key: 'note', caption: 'Notiz' } )
        }
        if( this.$core.getLicense().isAllowed( 'page_lists' ) )
        {
            if( this.$core.getLicense().isAllowed( 'listtype_test' ) )
            {
                this.optionValues.push( { key: 'list', caption: 'Liste / Klassenarbeit' } )
            }
            else
            {
                this.optionValues.push( { key: 'list', caption: 'Liste' } )
            }
        }
        if( this.$core.getLicense().isAllowed( 'page_todos' ) )
        {
            this.optionValues.push( { key: 'todo', caption: 'Todo' } )
        }
    },

    methods: {

        handleSelect()
        {
            let selectElm = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== selectElm )
            {
                if( '' !== selectElm.value )
                {
                    this.emitNestedForm( selectElm.value )
                } else
                {
                    this.$emit( 'nestedForm', false )
                }
            }
        },

        emitNestedForm( value )
        {
            let baseClass = this.$core.baseClass( value )
            this.$emit( 'nestedForm', {
                objectType: value,
                fields    : baseClass.getFormFields(),
                baseClass : baseClass,
                editItem  : this.$props.prefillParams,
                eventKey  : this.$core.getUuid().generate()
            } )
        },

        getFilterElement()
        {
            let filter = false
            let elements = document.querySelectorAll( '.element-filter' )
            for( let e in elements )
            {
                if( undefined !== elements[ e ].classList
                    && elements[ e ].classList.contains( 'active' ) )
                {
                    filter = elements[ e ]
                }
            }

            for( let o in this.optionValues )
            {

                let option = this.optionValues[ o ].key
                if( false !== filter )
                {
                    if( filter.classList.contains( 'filter-' + option ) )
                    {
                        return option
                    }
                }
            }
            return ''
        }

    }

}
</script>