<template>
    <transition appear :name="$core.settings().getTransition( 'fade' )">
        <div v-show="visible" class="detail-view-wrapper"
             :key="'ddv-'+object.localId">
            <div :class="'detail-view'+( wobble ? ' wobble' : '' )">
                <div class="headline">
                    <strong>{{ object.displayName }}<span
                        v-if="object.archived"> {{ $core.t( 'archive-marker' ) }}</span></strong>
                    <Button type="close" @clicked="handleClose"/>
                </div>
                <div class="body" :id="viewId">
                    <ScrollToTop :base="false" :attach="viewId"/>
                    <div class="default-page-head">
                        <div class="sub-headline">

                            <ElementCounter :visibleCounter="counts.visible"
                                            :invisibleCounter="counts.invisible"
                                            :filterCounter="filtered.length"
                                            :viewIsFiltered="viewIsFiltered"
                                            position="top"/>

                        </div>
                        <HeadFunctions v-if="'data' !== tab"
                                       :has="{ select: false, add:( tab !== 'data' && tab !== 'planner' && tab !== 'scorings' && !isExternalTab ), print: tab === 'scorings', search: false }"
                                       :availableFilters="availableFilters"
                                       :hideFilters="tab === 'data' || tab === 'planner'"
                                       :isArchivedScope="object.archived"
                                       :isOwnScope="tab !== 'foreign'"
                                       :id="'head-'+viewId"
                                       :attach="viewId"
                                       @click="handleAdd( $event )"
                                       @print="handlePrint()"
                                       @excel="handleExcel()"
                                       @search="searchVisible = true"
                                       @filter="setFilters"/>
                    </div>

                    <TabbedContent :tabs="tabs" :active="tab" additionalClass="marge-top" @switched="setTab"/>

                    <template v-if="ready && !isSpecialTab">
                        <DefaultViewItemList :itemList="prepared[tab]"
                                             :viewKey="viewId"
                                             :viewItem="viewItem"
                                             :filtered="filtered"
                                             :filterKey="filterKey"
                                             :hiddenVisible="hiddenVisible"
                                             :viewIsFiltered="viewIsFiltered"
                                             :scope="tab"
                                             :viewScope="object.archived === true ? 'archive' : 'cache'"
                                             :galleryMode="'media' === tab"
                                             @requestUpdate="prepare()"/>
                    </template>
                    <template v-else-if="ready && isSpecialTab">
                        <StudentDetailQuickview v-if="'quick' === tab"
                                                :reference="object"
                                                :viewId="viewId"
                                                :filterKey="filterKey"
                                                :filterList="filterList"
                                                @count="setCount"/>
                        <NestedPlanner v-if="'planner' === tab"
                                       :reference="object"
                                       :viewId="viewId"/>
                        <TabScorings v-if="'scorings' === tab"
                                     :objectType="object.type"
                                     :objectReference="object"
                                     :fixedfilter="{ attr: 'filterById', value: object.localId }"
                                     :filterList="filterList"
                                     @count="setCount"/>
                        <StudentMeta v-if="'data' === tab"
                                     :reference="object"
                                     :viewId="viewId"/>
                        <Component v-if="isExternalTab"
                                   :is="tabMap.get( tab ).component"
                                   :item="object"/>
                    </template>
                    <PreheatingIndicator v-else/>

                    <ElementCounter :visibleCounter="counts.visible"
                                    :invisibleCounter="counts.invisible"
                                    :filterCounter="filtered.length"
                                    :viewIsFiltered="viewIsFiltered"
                                    position="bottom"/>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import ScrollToTop                from "@/components/elements/defaults/ScrollToTop";
import MixinDefaultDetailViewTabs from "@/components/defaults/defaultDetailView/MixinDefaultDetailViewTabs";
import TabbedContent              from "@/components/layout/tabs/TabbedContent";
import HeadFunctions              from "@/components/layout/HeadFunctions";
import MixinFilterableView        from "@/components/defaults/defaultListPage/MixinFilterableView";
import DefaultViewItemList        from "@/components/defaults/DefaultViewItemList";
import PreheatingIndicator        from "@/components/elements/indicators/PreheatingIndicator";
import StudentDetailQuickview     from "@/components/elements/viewItems/detailViews/StudentDetailQuickview";
import NestedPlanner              from "@/components/elements/viewItems/plannerView/NestedPlanner";
import TabScorings                from "@/components/routes/office/tabs/TabScorings";
import StudentMeta                from "@/components/elements/viewItems/StudentMeta";
import ColleagueMeta              from "@/components/routes/colleagues/detailViews/ColleagueMeta";
import ColleagueGeneralRights     from "@/components/routes/colleagues/detailViews/ColleagueGeneralRights";
import ColleagueObjectRights      from "@/components/routes/colleagues/detailViews/ColleagueObjectRights";
import ColleagueSharedWithMe      from "@/components/routes/colleagues/detailViews/ColleagueSharedWithMe";
import ElementCounter             from "@/components/elements/defaults/ElementCounter";

export default {

    name      : "DefaultDetailView",
    components: {
        ElementCounter,
        ColleagueSharedWithMe,
        ColleagueObjectRights,
        ColleagueGeneralRights,
        ColleagueMeta,
        StudentMeta,
        TabScorings,
        NestedPlanner,
        StudentDetailQuickview,
        PreheatingIndicator,
        DefaultViewItemList,
        HeadFunctions,
        TabbedContent,
        ScrollToTop
    },
    mixins    : [ MixinDefaultDetailViewTabs, MixinFilterableView ],
    emits     : [ 'close' ],

    props: {
        object       : { Type: Object, required: true },
        hiddenVisible: { Type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            ready           : false,
            visible         : false,
            isDetailView    : true,
            viewId          : 'detail-view-' + this.$core.getUuid().generate(),
            wobble          : false,
            counts          : {},
            availableFilters: [],
            specialTabs     : [ 'scorings', 'planner', 'data', 'quick' ],
            externalTabs    : [ 'coll_meta', 'coll_rights_general', 'coll_rights_objects', 'coll_sharedwithme' ],
            prepared        : {},
            searchVisible   : false
        }
    },

    computed: {
        isSpecialTab()
        {
            return -1 < this.specialTabs.indexOf( this.tab ) || -1 < this.externalTabs.indexOf( this.tab )
        },
        isExternalTab()
        {
            return -1 < this.externalTabs.indexOf( this.tab )
        }
    },

    created()
    {
        this.$core.setState( 'detailViewFor', this.$props.object.type + ':' + this.$props.object.localId )
    },

    mounted()
    {
        this.prepare()
    },

    beforeUnmount()
    {
        this.$core.deleteState( 'detailViewFor', this.$props.object.type + ':' + this.$props.object.localId )
    },

    methods: {

        prepare()
        {
            this.prepareItemList()
                .then( () =>
                {
                    this.filter( this.prepared[ this.tab ] )
                        .then( () =>
                        {
                            this.viewId = 'detail-view-' + this.$core.getUuid().generate()
                            this.ready = true
                        } )
                } )
        },

        handleClose()
        {
            this.visible = false
            this.$core.getUi()
                .delay( () =>
                {
                    this.$emit( 'close' )
                }, 300 )
        },

        setCount( values )
        {
            if( !this.$core.f().isObject( values ) )
            {
                this.counts.visible = values
                this.counts.invisible = 0
            }
            else
            {
                this.counts = values
            }
        },

        /*eslint-disable*/
        appendTo( preselected, localId )
        {

            let obj = preselected.object
            obj.students.push( localId )

            this.$core.baseClass( preselected.type )
                .update( obj, obj.localId, obj.remoteId, obj.timestamp, obj.localKey )

        },

        handleAdd()
        {

            let params = {}
            switch( this.$props.object.type )
            {
                case 'student':
                    params.studentReference = [ this.$props.object.localId ]
                    params.selectedClass = 'class:' + this.$props.object.classId
                    break
                case 'class':
                    params.classReference = this.$props.object.localId
                    params.selectedClass = 'class:' + this.$props.object.localId
                    break
                case 'group':
                    params.groupReference = this.$props.object.localId
                    params.selectedClass = 'group:' + this.$props.object.localId
                    break
                case 'yeargroup':
                    params.yeargroupReference = this.$props.object.localId
                    params.selectedClass = 'yeargroup:' + this.$props.object.localId
                    break
            }

            params.forceCreate = true
            params.inDetailView = true

            let tabSetup = this.getTabSetupById( this.tab ),
                readyKey = this.$core.getUi().showForm( tabSetup.viewItem, tabSetup.objectType, 'add', params )

            if( 'class' === this.$props.object.type
                && 'student' === tabSetup.viewItem.toLowerCase() )
            {

                let object = this.$core.getBaseClassHelper()
                                 .getObjectById( this.$props.object.localId )
                let preselected = {
                    type   : 'class',
                    localId: this.$props.object.localId,
                    object : object
                }

                this.$core.getEventManager()
                    .add( 'created-object-' + readyKey, ( localId ) =>
                    {
                        this.appendTo( preselected, localId )
                    } )

            }


        },

        handlePrint()
        {
            this.$core.getEventManager().dispatch( 'print-view' )
        },

        handleExcel()
        {
            this.$core.getEventManager().dispatch( 'excel-view' )
        }

    }

}
</script>