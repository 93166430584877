<template>
    <div class="charts-container" id="stats-bar-container" :key="'bars-container-'+changeKey">
        <vue3-chart-js id="stats-bar"
                       type="bar"
                       :key="'bars-'+changeKey"
                       :data="chartdata"
                       :options="options"/>
    </div>
</template>
<script>

import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
    
    name      : "BarDiagram",
    components: { Vue3ChartJs },
    props     : {
        changeKey   : {
            type    : String,
            required: true
        },
        chartdata   : {
            type   : Object,
            default: null
        },
        options     : {
            type   : Object,
            default: null
        },
        colorset    : {
            type   : Object,
            default: null
        },
        bordercolors: {
            type   : Object,
            default: null
        }
    }
    
}

</script>