export default class Mapper
{

    constructor( parent )
    {

        if( !Mapper.instance )
        {

            this.parent = parent
            this.logger = parent.logger
            this.maps = {}

            this.parent.readAllObjects( 'maps' )
                .then( maps => {

                    for( let m in maps )
                    {

                        let map = maps[m]
                        this.maps[map.key] = map.item

                    }

                    this.precheck()

                } )

            Mapper.instance = this

        }

        return Mapper.instance

    }

    precheck()
    {

        this.parent.readAllObjects( 'types' )
            .then( types => {

                let check = {}

                for( let t in types )
                {

                    let type = types[t]
                    if( undefined === check[type.item] )
                    {
                        check[type.item] = []
                    }

                    if( -1 === check[type.item].indexOf( type.key ) )
                    {
                        check[type.item].push( type.key )
                    }

                }

                for( let c in check )
                {

                    if( undefined === this.maps[c]
                        || check[c].length !== this.maps[c].length )
                    {
                        this.maps[c] = check[c]
                        this._storeMap( c )
                    }

                }

            })

    }

    _storeMap( key )
    {

        return new Promise( resolve => {
            this.parent.writeMap( key, this.maps[key] )
                .then( () => {
                    return resolve()
                })
                .catch( () => {
                    return resolve()
                })
        })


    }

    add( key, value )
    {

        return new Promise( resolve => {

            if( undefined === this.maps[key]
                || -1 === this.maps[key].indexOf( value ) )
            {
                if( undefined === this.maps[key] )
                {
                    this.maps[key] = []
                }
                this.maps[key].push( value )
                this._storeMap( key )
                    .then( () => {
                        return resolve()
                    })
            }
            else
            {
                return resolve()
            }

        })

    }


}