import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class MessagesObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !MessagesObjectClass.instance )
        {

            let refList = [ 'title',
                            'body',
                            'color',
                            'id_sender',
                            'id_recipient',
                            'timestamp',
                            'timestamp_read',
                            'timestamp_deleted',
                            'threadId',
                            'recipientNotified' ]


            let personalAttributesStatesList = [
                {
                    storage: 'pinning',
                    state  : 'pinned',
                    list   : 'pinLists'
                },
                {
                    storage: 'hiding',
                    state  : 'hidden',
                    list   : 'hideLists'
                }
            ]

            super( core, 'message', refList, personalAttributesStatesList )
            this.allowAddInForeignTab = true
            this.ignoreCombinedShares = true

            this.objectType = 'messages'

            MessagesObjectClass.instance = this

        }

        return MessagesObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'id_recipient',
                caption    : 'Empfänger',
                type       : 'ColleagueSelector',
                idType     : 'uuid',
                reformatter: null,
                optional   : false,
                validator  : 'freetext',
                styleUpdate: false
            },
            {
                ref        : 'title',
                caption    : 'Betreff',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'freetext',
                styleUpdate: true
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                reformatter: null,
                optional   : false,
                validator  : 'color',
                styleUpdate: true
            },
            {
                ref        : 'body',
                caption    : 'Text',
                type       : 'TextArea',
                reformatter: null,
                optional   : true,
                validator  : 'freetext',
                styleUpdate: false
            },
            {
                ref        : 'threadId',
                caption    : null,
                type       : 'Hidden',
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'recipientNotified',
                caption    : null,
                type       : 'Hidden',
                optional   : true,
                validator  : null,
                styleUpdate: false
            }
        ]
    }

    getTabs()
    {
        return [ 'inbox', 'outbox', 'archive' ]
    }

    tabTranslation()
    {
        return {
            inbox : 'foreign',
            outbox: 'own'
        }
    }

    getFunctions()
    {
        return [ 'pin', 'hide', 'answer', 'forward', 'delete' ]
    }

    create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
    {

        values.id_sender = this.ownUuid
        values.threadId = 'create' !== values.threadId && undefined !== values.threadId ? values.threadId : undefined

        let localId = super.create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
        this.database.write( 'messagespooler', localId, 0 )

        return localId

    }

}