<script>
export default {

    name: "MixinCachePreheater",

    data()
    {
        return {
            preheatReady       : false,
            mixinCacheEventName: false
        }
    },

    created()
    {

        let baseClassHelper = this.$core.getBaseClassHelper()
        this.mixinCacheEventName = 'cache-preheater-' + this.$core.getUuid().generate()

        if( !baseClassHelper.ready )
        {
            this.$core.getEventManager().append( 'on-baseclasses-available', () =>
            {
                this.baseClassHelper = baseClassHelper
                this.prepareCaches()
            } )
        }
        else
        {
            this.baseClassHelper = baseClassHelper
            this.prepareCaches()
        }

    },

    methods: {

        prepareCaches()
        {
            return new Promise( resolve =>
            {

                let allDone = true

                for( let s in this.neededCaches )
                {

                    let neededClassState = this.baseClassHelper.get( this.neededCaches[ s ] ).state
                    if( 'filled' !== neededClassState )
                    {
                        if( 'filling' !== neededClassState )
                        {
                            this.baseClassHelper
                                .get( this.neededCaches[ s ] )
                                .cacheHeatup()
                        }

                        allDone = false
                    }

                }

                if( allDone )
                {
                    this.preheatReady = true
                    this.cachesReady = true
                    this.$core.getEventManager().dispatchAndRemove( this.mixinCacheEventName )
                    return resolve()
                }
                else
                {
                    setTimeout( () =>
                    {
                        return resolve( this.prepareCaches() )
                    }, 300 )
                }

            } )

        },

        awaitNeededCaches()
        {

            return new Promise( resolve =>
            {

                if( this.cachesReady )
                {
                    return resolve()
                }

                this.$core.getEventManager()
                    .add( this.mixinCacheEventName, () =>
                    {
                        return resolve()
                    } )
            } )
        }

    }

}
</script>