<template>
    <div>
        <div class="select-wrapper">
            <select :id="'form-element-'+refName" @change="checkStyleUpdate()"
                    @prefill="checkStyleUpdate()" @click="skip">
                <option value="" :disabled="undefBlocked === true">bitte wählen...</option>
                <option v-for="value in values"
                        :key="'key_option_'+value.key"
                        :value="value.key">{{ value.caption }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>

export default {
    name : 'ScopeSelector',
    props: {
        
        value       : { Type: String, required: false },
        refName     : { Type: String, required: true },
        validator   : { Type: String, required: false },
        reformatter : { Type: String, required: false },
        display     : { Type: String, required: false },
        undefBlocked: { Type: Boolean, required: false },
        configScope : { Type: Boolean, required: false },
        eventKey    : { Type: String, required: false },
        scope       : { Type: String, required: false }
        
    },
    
    created()
    {
        for( let r in this.$router.options.routes )
        {
            let route = this.$router.options.routes[ r ]
            if( 3 < route.path.length
                && -1 === route.path.indexOf( ':' ) )
            {
                let caption = this.$core.t( 'route-'+route.name )
                if( -1 === caption.indexOf( 'route-') )
                {
                    this.values.push( {
                        key    : route.name,
                        caption: caption
                    } )
                }
            }
        }
    },
    updated()
    {
        this.select()
    },
    mounted()
    {
        this.select()
    },
    data()
    {
        return {
            values: [
                {
                    key    : 'general',
                    caption: 'entzettelt generell'
                }
            ],
            scopes: [],
        }
    },
    watch  : {
        display: {
            deep: true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.$forceUpdate()
                }
            }
        }
    },
    methods: {
        
        select()
        {
            let element = document.querySelector( '#form-element-' + this.$props.refName )
            if( null === element )
            {
                return
            }
            if( undefined !== this.$props.value )
            {
                element.value = this.$props.value
            }
        },
        
        skip( event )
        {
            event.stopPropagation()
        },
        checkStyleUpdate()
        {
            
            let element = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== element )
            {
                this.$emit( 'changed', element.value )
                this.$emit( 'update', this.$props.refName, 'feedbackSelector', 'update', undefined, element.value )
                
            }
        }
    }
    
}
</script>