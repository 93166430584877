export default class SyncCrudHandleQueue
{

    constructor( parent )
    {

        this.p = parent
        this.parent = this.p.parent
        this.fieldHistoryHelper = this.parent.fieldHistoryHelper

        this.setState = ( key, value ) =>
        {
            this.parent.setState( key, value )
        }

        this.getState = ( key ) =>
        {
            return this.parent.getState( key )
        }
        this.queue = this.p.queue

        this.mergedListValuesFor = []
        this.handled = 0
        this.totalTime = 0
        this.firstSync = true

        return this

    }

    shouldSync()
    {
        return true
    }

    markSyncedType( type )
    {
        if( -1 === this.p.allTypes.indexOf( type ) )
        {
            this.p.allTypes.push( type )
        }
    }

    isFlaggedOrHelpdeskUser()
    {
        if( 184 === this.parent.store.getters.idUser
            || this.parent.flags.is( 'demouser' ) )
        {
            return true
        }
        return false
    }

    _mergeFieldHistories( localValues, localFieldHistory, remoteFieldHistory )
    {

        let todo            = [],
            mergedHistories = localFieldHistory || {}

        if( undefined !== remoteFieldHistory
            && 'create' !== remoteFieldHistory )
        {

            for( let k in remoteFieldHistory )
            {
                if( 'create' !== localFieldHistory
                    && undefined === localFieldHistory
                    || undefined === localFieldHistory[ k ] )
                {
                    mergedHistories[ k ] = remoteFieldHistory[ k ]
                }
            }

            mergedHistories = this.fieldHistoryHelper.reduce( mergedHistories )

            for( let m in mergedHistories )
            {
                todo.push( mergedHistories[ m ] )
            }

            todo = this.p.sorter.sortObjects( todo, 'timestamp', 'ascending' )

            for( let t in todo )
            {
                for( let h in todo[ t ].history )
                {
                    switch( todo[ t ].history[ h ].action )
                    {
                        case 'del':
                            delete localValues[ h ]
                            break
                        case 'set':
                            localValues[ h ] = todo[ t ].history[ h ].value
                            break

                    }
                }
            }

        }

        return {
            values         : localValues,
            mergedHistories: mergedHistories
        }

    }

    _historyMatch( remote, local )
    {

        if( ( undefined === remote && undefined !== local ) || ( undefined === local && undefined !== remote ) )
        {
            return false
        }
        if( undefined === remote && undefined === local )
        {
            return true
        }

        let found = []
        for( let k in remote )
        {
            found.push( k )
        }
        for( let k in local )
        {
            let idx = found.indexOf( k )
            if( -1 < idx )
            {
                found.splice( idx, 1 )
            }
        }

        return 0 === found.length

    }

    _mergeListValues( localId, local, remote, remoteRaw, keys )
    {

        return new Promise( ( resolve, reject ) =>
        {

            let ownElementKey    = this.parent.cryptoHelper.getOwnElementKey( local ),
                remoteElementKey = this.parent.cryptoHelper.getLocalKey( remoteRaw )

            this.parent.cryptoHelper.decryptElement( ownElementKey, local.object )
                .then( localObject =>
                {

                    if( null !== localObject
                        && false !== localObject )
                    {

                        let localValues  = localObject.values || {},
                            remoteValues = remote.values || {},
                            changes      = Object.keys( localValues ).length !== Object.keys( remoteValues ).length

                        for( let r in remoteValues )
                        {
                            if( !changes && localValues[ r ] !== remoteValues[ r ] )
                            {
                                changes = true
                            }
                            localValues[ r ] = remoteValues[ r ]
                        }

                        let historyMatch = this._historyMatch( remote.fieldHistory, localObject.fieldHistory )
                        if( !historyMatch )
                        {

                            changes = true
                            let mergeResult = this._mergeFieldHistories( localValues, localObject.fieldHistory, remote.fieldHistory )
                            localValues = mergeResult.values
                            remote.fieldHistory = mergeResult.mergedHistories
                        }

                        remote.values = localValues

                        this.parent.cryptoHelper.encryptElement( remote, remoteElementKey, keys )
                            .then( result =>
                            {
                                if( changes )
                                {
                                    this.mergedListValuesFor.push( localId )
                                }
                                return resolve( result )
                            } )

                    }
                    else
                    {
                        return reject()
                    }

                } )

        } )

    }

    _prepareSyncObject( job, object, keys )
    {

        return new Promise( resolve =>
        {

            if( undefined !== object.listType )
            {

                let remoteObject

                this._mergeListValues( job.db.id, job.db.object, object, job.raw, job.raw.keys )
                    .then( result =>
                    {
                        remoteObject = {
                            remoteUpdateTimestamp: null !== job.raw.datetime_updated ? new Date( job.raw.datetime_updated ).getTime() : 0,
                            keys                 : keys || result.keys,
                            idOwner              : job.raw.id_owner,
                            object               : result.object,
                            remoteId             : job.raw.id
                        }
                    } )
                    .catch( () =>
                    {
                        remoteObject = {
                            remoteUpdateTimestamp: null !== job.raw.datetime_updated ? new Date( job.raw.datetime_updated ).getTime() : 0,
                            keys                 : keys || this.parent._prepareKeys( job.raw.keys ),
                            idOwner              : job.raw.id_owner,
                            object               : job.raw.object,
                            remoteId             : job.raw.id
                        }
                    } )
                    .finally( () =>
                    {

                        return resolve( {
                            localId     : job.raw.id_local,
                            type        : job.raw.type,
                            object      : remoteObject,
                            referenceKey: object.referenceKey
                        } )

                    } )
            }
            else
            {

                let remoteObject = {
                    remoteUpdateTimestamp: null !== job.raw.datetime_updated ? new Date( job.raw.datetime_updated ).getTime() : 0,
                    keys                 : keys || this.parent._prepareKeys( job.raw.keys ),
                    idOwner              : job.raw.id_owner,
                    object               : job.raw.object,
                    remoteId             : job.raw.id
                }

                return resolve( {
                    localId     : job.raw.id_local,
                    type        : job.raw.type,
                    object      : remoteObject,
                    referenceKey: object.referenceKey
                } )

            }

        } )

    }

    prepareJob( job, keys )
    {

        return new Promise( resolve =>
        {

            let localKey = this.parent._getLocalKey( job.raw.keys )

            if( false !== localKey )
            {

                this.parent.cryptoHelper.decryptElement( localKey, job.raw.object )
                    .then( object =>
                    {

                        if( false !== object
                            && null !== object )
                        {
                            this.markSyncedType( job.raw.type )
                            return resolve( this._prepareSyncObject( job, object, keys ) )
                        }
                        else
                        {
                            return resolve( false )
                        }

                    } )

            }
            else
            {
                return resolve( false )
            }

        } )

    }

    countUpdatedTypes( type )
    {
        if( 'full' !== this.parent.getSyncParameters().mode )
        {
            if( -1 === this.p.updatedTypes.indexOf( type ) )
            {
                this.p.updatedTypes.push( type )
            }
        }
    }

    _findKey( keyset, uuid )
    {
        for( let k in keyset )
        {
            if( uuid === keyset[ k ].uuid )
            {
                return keyset[ k ]
            }
        }
        return false
    }

    _keyMerge( localKeys, remoteKeys )
    {

        let merged      = [],
            differences = localKeys.length !== remoteKeys.length

        for( let r in remoteKeys )
        {

            let remote = remoteKeys[ r ],
                local  = this._findKey( localKeys, remote.uuid )

            if( false !== local )
            {
                if( local.key !== remote.secret
                    && ( remote.uuid !== this.parent.cryptoHelper.getOwnUuid() || this.firstSync === true ) )
                {
                    differences = true
                    merged.push( {
                        uuid: remote.uuid,
                        key : remote.secret
                    } )
                }
                else
                {
                    merged.push( {
                        uuid: remote.uuid,
                        key : remote.secret
                    } )
                }
            }
            else
            {
                differences = true
                merged.push( {
                    uuid: remote.uuid,
                    key : remote.secret
                } )
            }

        }

        return {
            changed       : differences,
            keyset        : merged
        }

    }

    /* eslint-disable */
    handleSyncable( syncJob )
    {
        return new Promise( resolve =>
        {

            let ds = Date.now()
            this.handled++
            if( undefined === syncJob.db.object )
            {

                this.prepareJob( syncJob, undefined )
                    .then( result =>
                    {

                        if( false !== result )
                        {
                            this.countUpdatedTypes( syncJob.raw.type )
                            this.p.mxTriggers.push( {
                                type   : syncJob.raw.type,
                                localId: syncJob.raw.id_local
                            } )

                            this.totalTime += ( Date.now() - ds )
                            this.setState( 'sync-had-'+syncJob.raw.type, true )

                            return resolve( {
                                type      : 'create',
                                result    : result,
                                objectType: syncJob.raw.type
                            } )
                        }
                        else
                        {
                            return resolve( {
                                type      : 'nochange',
                                result    : syncJob.db,
                                objectType: syncJob.raw.type
                            } )
                        }

                    } )
            }
            else
            {

                let dbObjectValid = undefined !== syncJob.db.object,
                    objectMatch,
                    keyset,
                    tsLocal

                if( dbObjectValid )
                {
                    objectMatch = syncJob.db.object.object === syncJob.raw.object
                    keyset = this._keyMerge( syncJob.db.object.keys, syncJob.raw.keys )
                    tsLocal = syncJob.db.object.remoteUpdateTimestamp
                }

                let tsRemote = null !== syncJob.raw.datetime_updated ? new Date( syncJob.raw.datetime_updated ).getTime() : 0

                if( dbObjectValid
                    && ( undefined === tsLocal || tsLocal < tsRemote
                         || true === keyset.changed )
                    && !this.isFlaggedOrHelpdeskUser() )
                {

                    if( undefined === tsLocal || tsLocal < tsRemote )
                    {
                        syncJob.raw.datetime_updated = tsRemote
                    }

                    this.prepareJob( syncJob, keyset.keyset )
                        .then( result =>
                        {

                            this.countUpdatedTypes( syncJob.raw.type )

                            this.totalTime += ( Date.now() - ds )
                            this.setState( 'sync-had-'+syncJob.raw.type, true )

                            return resolve( {
                                type      : 'update',
                                result    : result,
                                objectType: syncJob.raw.type
                            } )

                        } )

                }
                else
                {

                    if( !objectMatch
                        && dbObjectValid
                        && !this.isFlaggedOrHelpdeskUser() )
                    {

                        return resolve( {
                            type      : 'nochange',
                            result    : syncJob.db,
                            objectType: syncJob.raw.type
                        } )

                    }
                    else
                    {
                        return resolve( {
                            type      : 'nochange',
                            result    : syncJob.db,
                            objectType: syncJob.raw.type
                        } )
                    }
                }

            }

        } )

    }

    _sortIndex( type )
    {

        let index = {
            list   : 5,
            student: 3,
            class  : 1,
            group  : 2,
            note   : 4
        }

        return index[ type ] || 99

    }

    _fireMxTriggers( triggers )
    {

        triggers = this.parent.sorter.multiSortObjects( triggers, [ [ 'sortIndex',
                                                                      'ascending' ] ], true )

        for( let t in triggers )
        {

            if( undefined !== triggers[ t ].type )
            {

                this.parent.getBaseClassHelper()
                    .get( triggers[ t ].type )
                    .refreshCache( triggers[ t ].localId )

            }

        }

        this.parent.logger.clog( 'SyncWorker::SyncCrud::_fireMxTriggers', 'fired ' + triggers.length + ' cache update triggers' )

    }

    afterSync( results )
    {

        return new Promise( resolve =>
        {

            this.p.showProgress( '<strong>Synchronisierung</strong> wird finalisiert...' )

            let updateCount = 0,
                done        = 0,
                creations   = [],
                updates     = [],
                promises    = [],
                triggers    = [],
                maps        = {}

            this.p.total = 100
            this.p.step = 0

            for( let r in results )
            {

                updateCount++

                if( 'nochange' !== results[ r ].type
                    && !this.firstSync )
                {
                    triggers.push( {
                        type     : results[ r ].result.type,
                        sortIndex: this._sortIndex( results[ r ].result.type ),
                        localId  : results[ r ].result.localId
                    } )
                }

                if( this.firstSync
                    && 'nochange' !== results[ r ].type )
                {
                    if( undefined === maps[ results[ r ].objectType ] )
                    {
                        maps[ results[ r ].objectType ] = []
                    }
                    maps[ results[ r ].objectType ].push( results[ r ].result.id || results[ r ].result.localId )
                }

                if( false !== results[ r ].result )
                {
                    switch( results[ r ].type )
                    {
                        case 'create':
                            creations.push( results[ r ].result )
                            break
                        case 'update':
                            updates.push( results[ r ].result )
                            break
                    }
                }

            }

            this.p.total = updateCount

            if( this.firstSync )
            {
                for( let t in maps )
                {
                    promises.push( () =>
                    {
                        return new Promise( resolve =>
                        {
                            this.parent.database.writeMap( t, maps[ t ] )
                                .then( () =>
                                {
                                    done++
                                    this.p.step = done
                                    return resolve()
                                } )
                        } )
                    } )
                }
            }
            else
            {
                for( let t in maps )
                {
                    promises.push( () =>
                    {
                        return new Promise( resolve =>
                        {
                            this.parent.database.appendMap( t, maps[ t ] )
                                .then( () =>
                                {
                                    done++
                                    this.p.step = done
                                    return resolve()
                                } )
                        } )
                    } )
                }
            }

            this.parent.logger.clog( 'SyncWorker::SyncCrud::afterSync', 'got ' + creations.length + ' creations and ' + updates.length + ' updates' )

            if( 0 < creations.length )
            {
                promises.push( () =>
                {
                    return new Promise( resolve =>
                    {
                        this.parent.database.writeSyncObjectList( creations )
                            .then( () =>
                            {
                                return resolve()
                            } )
                    } )
                } )
            }

            if( 0 < updates.length )
            {
                promises.push( () =>
                {
                    return new Promise( resolve =>
                    {

                        this.parent.database.writeSyncObjectList( updates )
                            .then( () =>
                            {
                                return resolve()
                            } )
                    } )
                } )
            }

            this.parent.f.promiseRunner( promises )
                .then( () =>
                {

                    let dbList = []

                    while( 0 < this.mergedListValuesFor.length )
                    {
                        dbList.push( {
                            type   : 'list',
                            localId: this.mergedListValuesFor.shift()
                        } )
                    }

                    this.p.showProgress( '<strong>Uploads</strong> werden eingereiht...' )
                    this.parent.database.writeUploadsList( dbList )
                        .then( () =>
                        {


                            this.p.showProgress( '<strong>Synchronisierung</strong> abgeschlossen...' )
                            this._fireMxTriggers( triggers )
                            this.parent.setState( 'crudSyncQueue', 0 )

                            if( false === this.firstSync )
                            {

                                this.parent.logger.clog( 'SyncWorker::SyncCrud::afterSync', 'finalized first sync' )
                                return resolve( updateCount )

                            }
                            else
                            {

                                this.firstSync = false
                                this.parent.logger.clog( 'SyncWorker::SyncCrud::afterSync', 'finalized' )
                                return resolve( updateCount )

                            }

                        } )
                } )
        } )

    }

    _getUploads()
    {
        return new Promise( resolve =>
        {

            let uploads = []

            this.parent.database.readAllObjects( 'uploads' )
                .then( list =>
                {

                    for( let l in list )
                    {
                        uploads.push( list[ l ].key )
                    }

                    return resolve( uploads )

                } )
                .catch( () =>
                {
                    return resolve( uploads )
                } )

        } )
    }

    queueAddDbObjects()
    {

        return new Promise( resolve =>
        {

            let list = []

            for( let q in this.queue )
            {
                list.push( this.queue[ q ].raw.id_local )
            }

            this.parent.database.readObjectList( list )
                .then( result =>
                {
                    for( let r in result )
                    {

                        for( let q in this.queue )
                        {
                            if( this.queue[ q ].raw.id_local === result[ r ].id )
                            {
                                this.queue[ q ].db = result[ r ]
                            }
                        }
                    }

                    return resolve()

                } )

        } )
    }

    sync()
    {

        return new Promise( resolve =>
        {

            if( this.getState( 'listEditorOpen' ) )
            {

                this.parent.logger.cdebug( 'SyncWorker::SyncCrud::SyncCrudHandleQueue', 'list editor open: avoiding conflicts and awaiting next run...' )
                return resolve()

            }

            this.parent.logger.cdebug( 'SyncWorker::SyncCrud::SyncCrudHandleQueue', 'performing sync run...' )

            if( false === this.parent.silent )
            {
                let title = this.parent.byReset === true ? 'Wache aus Standby auf' : 'Synchronisiere'
                this.parent.ui.showBlocker( title, '<strong>Elemente</strong> werden synchronisiert...', 'progress' )
            }

            let promises = [],
                results  = [],
                start    = Date.now()

            this.p.step = 0
            this.p.total = this.queue.length

            this.parent.setState( 'crudSyncQueue', this.queue.length )

            this._getUploads()
                .then( blocklist =>
                {

                    this.queueAddDbObjects()
                        .then( () =>
                        {

                            while( 0 < this.queue.length )
                            {

                                let job = this.queue.shift()

                                if( -1 === blocklist.indexOf( job.raw.id_local ) )
                                {
                                    if( -1 === this.p.noSync.indexOf( job.raw.id_local ) )
                                    {

                                        promises.push( () =>
                                        {
                                            return this.handleSyncable( job )
                                                       .then( result =>
                                                       {
                                                           this.p.step++
                                                           this.p.showProgress()
                                                           results.push( result )
                                                       } )
                                        } )

                                    }
                                    else
                                    {
                                        this.p.step++
                                        this.p.showProgress()
                                        this.parent.logger.cdebug( 'SyncWorker::SyncCrud::SyncCrudHandleQueue', 'skipping sync of #' + job.raw.id_local + ': actually awaiting local update.' )
                                    }
                                }
                                else
                                {
                                    this.p.step++
                                    this.p.showProgress()
                                    this.parent.logger.cdebug( 'SyncWorker::SyncCrud::SyncCrudHandleQueue', 'skipping sync of #' + job.raw.id_local + ': actually awaiting upload.' )
                                }

                            }

                            this.parent.f.promiseRunner( promises )
                                .then( () =>
                                {

                                    this.p.step = this.p.total
                                    this.parent.logger.cdebug( 'SyncWorker::SyncCrud::SyncCrudHandleQueue', 'done (success.) after', Date.now() - start, 'ms' )
                                    return resolve( this.afterSync( results ) )

                                } )
                                .catch( error =>
                                {

                                    this.parent.logger.cerror( 'SyncWorker::SyncCrud::SyncCrudHandleQueue', 'done (faulty:', error, ')' )
                                    return resolve()

                                } )

                        } )

                } )

        } )

    }

}