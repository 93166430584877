<!--eslint-disable-->
<template>
    <div :key="'day-planner-title-'+key"
         class="planner-organizer-titles">
        <template v-for="visible in visibleOrganizers"
                  :key="visible.localId">
            <div v-for="( item, index ) in visible.items"
                 :key="visible.localId+'-'+index"
                 :class="'planner-organizer-title '+( item.color || visible.color)+( item.class ? ' '+item.class : '')"
                 :style="item.style"
                 @click="$emit( 'clicked', visible.localId )">
                <span v-html="item.displayTitle || visible.displayTitle"></span>
                <template v-for="content in visible.contents"
                          :key="'title-content-key-'+content+'-localId'">
                    <PlannerViewOrganizerTitleContents :dates="dates"
                                                       :organizer="visible"
                                                       :content="content"
                                                       :start="start"
                                                       :end="end"/>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
/*eslint-disable*/
import PlannerViewOrganizerTitleContents
    from "@/components/elements/viewItems/plannerView/modes/organizer/PlannerViewOrganizerTitleContents";

export default {

    name      : "PlannerViewOrganizerTitles",
    components: { PlannerViewOrganizerTitleContents },
    props     : {
        viewKey   : { Type: String, required: true },
        changeKey : { type: String, required: false, default: undefined },
        dates     : { Type: Object, required: true },
        filterList: { type: Object, required: false },
        organizers: { Type: Object, required: true },
        start     : { Type: Number, required: true },
        end       : { Type: Number, required: true }
    },

    emits: [ 'changeRange', 'clicked' ],

    data()
    {
        return {
            tsmpStart        : 0,
            tsmpEnd          : 0,
            visibleOrganizers: [],
            key              : false
        }
    },

    mounted()
    {
        this.update()
    },

    watch: {
        viewKey  : {
            immediate: true,
            handler( newKey, oldKey )
            {
                if( undefined !== oldKey
                    && newKey !== oldKey )
                {
                    this.update()
                }
            }
        },
        changeKey: {
            immediate: true,
            handler( newKey, oldKey )
            {
                if( undefined !== oldKey
                    && newKey !== oldKey )
                {
                    this.update( true )
                }
            }
        }
    },

    methods: {

        update( filterUpdate )
        {
            this.setVisibleOrganizers( filterUpdate )
            this.key = this.$core.getUuid().generate()
        },

        setTimeRange()
        {

            this.tsmpStart = 0
            this.tsmpEnd = 0

            for( let d in this.$props.dates )
            {
                let dateElement = this.$props.dates[ d ]
                if( !dateElement.isWeekend )
                {
                    if( 0 === this.tsmpStart || dateElement.dateObject.getTime() < this.tsmpStart )
                    {
                        this.tsmpStart = dateElement.dateObject.getTime()
                    }
                    if( dateElement.dateObject.getTime() > this.tsmpEnd )
                    {
                        this.tsmpEnd = dateElement.dateObject.getTime() + ( 12 * 60000 ) //86399999
                    }
                }
            }
        },

        /*eslint-disable*/
        appendVisibleContents( organizer )
        {

            let contents = this.$core.getBaseClassHelper()
                               .get( 'organizercontent' )
                               .readCache(),
                visible  = []

            for( let c in contents )
            {
                if( organizer.localId === contents[ c ].organizerReference )
                {
                    visible.push( contents[ c ] )
                }
            }

            organizer.contents = visible

        },

        setVisibleOrganizersLayout()
        {
            for( let v in this.visibleOrganizers )
            {

                let items   = [],
                    visible = this.visibleOrganizers[ v ]

                if( visible.noTimeRange )
                {
                    items.push( { style: 'grid-column: 1 / span 5;' } )
                }
                else
                {

                    let tsmpStart = this.$core.getFriendlyTimestamp()
                                        .timestampForDate( visible.dateStart, 0 ),
                        tsmpEnd   = this.$core.getFriendlyTimestamp()
                                        .timestampForDate( visible.dateEnd, 24 ),
                        before    = [],
                        after     = [],
                        span      = 0,
                        oneAdded  = false,
                        classes   = []

                    for( let d in this.$props.dates )
                    {
                        if( !this.$props.dates[ d ].isWeekend )
                        {

                            let checkFirst = this.tsmpStart - 86400000,
                                checkLast  = this.tsmpEnd + 86400000,
                                tsmpCheck  = this.$props.dates[ d ].dateObject.getTime()

                            if( tsmpStart > checkFirst )
                            {
                                classes.push( 'is-first' )
                            }
                            if( tsmpEnd < checkLast )
                            {
                                classes.push( 'is-last' )
                            }

                            if( ( tsmpCheck >= tsmpStart
                                  && tsmpCheck <= tsmpEnd ) )
                            {
                                oneAdded = true
                                span++
                            }
                            else
                            {
                                if( !oneAdded )
                                {
                                    before.push( { displayTitle: '&nbsp;', color: 'transparent' } )
                                }
                                else
                                {
                                    after.push( { displayTitle: '&nbsp;', color: 'transparent' } )
                                }
                            }
                        }
                    }

                    for( let b in before )
                    {
                        items.push( before[ b ] )
                    }

                    items.push( {
                        style: 'grid-column: ' + ( items.length + 1 ) + ' / span ' + span + ';',
                        class: classes.join( ' ' )
                    } )

                    for( let a in after )
                    {
                        items.push( after[ a ] )
                    }

                }

                this.visibleOrganizers[ v ].items = items
                this.appendVisibleContents( this.visibleOrganizers[ v ] )

            }
        },

        inRange( tsmpStart, tsmpEnd )
        {

            if( this.tsmpEnd < tsmpStart
                || this.tsmpStart > tsmpEnd )
            {
                return false
            }

            return true

        },

        resolveOrganizerTitle( organizer )
        {

            let resolvers = [ 'class', 'group', 'yeargroup' ]
            organizer.displayTitle = '<strong>' + organizer.title + '</strong>'

            for( let r in resolvers )
            {
                if( undefined !== organizer[ resolvers[ r ] + 'Reference' ] )
                {
                    if( this.$core
                            .getBaseClassHelper()
                            .get( resolvers[ r ] )
                            .state === 'filled' )
                    {

                        let element = this.$core
                                          .getBaseClassHelper()
                                          .getObjectById( organizer[ resolvers[ r ] + 'Reference' ] ),
                            append  = undefined !== element[ resolvers[ r ] + 'name' ] ? ' (' + element[ resolvers[ r ] + 'name' ] + ')' : ''

                        organizer.displayTitle = '<strong>' + organizer.title + '</strong>' + append

                    }
                    else
                    {
                        this.$core
                            .getBaseClassHelper()
                            .get( resolvers[ r ] )
                            .listAll()
                            .then( () =>
                            {
                                this.resolveOrganizerTitle( organizer )
                            } )
                    }
                }
            }
        },

        filterMatch( organizer )
        {

            return this.$core.f().allFilterMatch( organizer, this.$props.filterList )

        },

        checkTimeRangeUpdate()
        {
            if( Array.isArray( this.$props.filterList.organizer )
                && 1 === this.$props.filterList.organizer.length )
            {

                let organizer = this.$core.getBaseClassHelper()
                                    .getObjectById( this.$props.filterList.organizer[ 0 ].value ),
                    tsmpStart = this.$core.getFriendlyTimestamp()
                                    .timestampForDate( organizer.dateStart, 0 ),
                    tsmpEnd   = this.$core.getFriendlyTimestamp()
                                    .timestampForDate( organizer.dateEnd, 24 )

                if( !organizer.noTimeRange === true
                    && !this.inRange( tsmpStart, tsmpEnd ) )
                {
                    this.$emit( 'changeRange', tsmpStart )
                    return true
                }

            }

            return false

        },

        setVisibleOrganizers( filterUpdate )
        {

            if( filterUpdate )
            {
                if( this.checkTimeRangeUpdate() )
                {
                    return
                }
            }

            this.setTimeRange()
            this.visibleOrganizers = []

            for( let o in this.$props.organizers )
            {

                let organizer = this.$props.organizers[ o ],
                    tsmpStart = this.$core.getFriendlyTimestamp()
                                    .timestampForDate( organizer.dateStart, 0 ),
                    tsmpEnd   = this.$core.getFriendlyTimestamp()
                                    .timestampForDate( organizer.dateEnd, 24 )

                if( this.filterMatch( organizer )
                    && ( organizer.noTimeRange || this.inRange( tsmpStart, tsmpEnd ) ) )
                {
                    this.resolveOrganizerTitle( organizer )
                    this.visibleOrganizers.push( organizer )
                }

            }

            this.setVisibleOrganizersLayout()

        }

    }

}
</script>