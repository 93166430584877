<template>
    <div :id="'touchable-functions-'+viewItem+'-'+scope+'-'+item.localId"
         :key="'touchable-functions-'+item.localId"
         v-show="swipedRight && !item.editLocked"
         class="touchable-right swiped">
        <div v-if="swipedRight && !item.editLocked" class="touchable-active"></div>
        <transition-group appear :name="$core.settings().getTransition( 'functionbuttons' )">
            <template v-for="func in functions"
                      :key="'functionbutton-'+func+'-'+item.localId+'-'+item.elementKey">
                <FunctionButton v-if="$core.getLicense().isUnrestricted( 'elementFunctions', func )
                                        && !disappearing"
                                addClass="touchable-func"
                                :item="item"
                                :type="func"
                                @clicked="handleFunctionClick( func )"/>
            </template>
        </transition-group>
    </div>
</template>

<script>
import FunctionButton      from "@/components/elements/defaults/FunctionButton";
import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {
    name      : "FunctionButtons",
    components: { FunctionButton },
    mixins    : [ MixinCachePreheater ],
    props     : {
        item        : { Type: Object, required: true },
        viewItem    : { Type: String, required: true },
        scope       : { Type: String, required: true },
        swipedRight : { Type: Boolean, required: false, default: false },
        disappearing: { Type: Boolean, required: false, default: false }
    },

    emits: [ 'clicked' ],

    data()
    {
        return {
            neededCaches    : [ 'studentAccess', 'colleague' ],
            blockedFunctions: [],
            functions       : [],
            eventIndex      : false,
        }
    },

    created()
    {
        this.eventIndex = this.$core.getEventManager().addIndexed( 'on-rights-ready', () =>
        {
            this.awaitNeededCaches()
                .then( () =>
                {
                    this.prepareFunctions()
                } )
        } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager().removeIndexedCallback( 'on-rights-ready', this.eventIndex )
    },

    mounted()
    {
        this.awaitNeededCaches()
            .then( () =>
            {
                this.prepareFunctions()
            } )
    },

    methods: {
        prepareFunctions()
        {

            this.functions = this.$core.getBaseClassHelper()
                                 .get( this.$props.viewItem.toLowerCase() )
                                 .getFunctions( undefined !== this.$props.item.listType ? this.$props.item.listType : undefined )

            if( false === this.$props.item.archived )
            {
                this.functions.push( 'archive' )
            }

            if( true === this.$props.item.archived )
            {
                this.functions.push( 'unarchive' )
            }

            if( !this.$core.r().isAllowed( this.$props.item, 'delete' )
                && 'message' !== this.$props.item.type )
            {
                this.$core.f().removeFromArray( this.functions, 'delete' )
            }

            let colleagues      = this.$core.getBaseClassHelper()
                                      .get( 'colleague' )
                                      .readCache( 'cache' ),
                studentAccesses = this.$core.getBaseClassHelper()
                                      .get( 'studentAccess' )
                                      .readCache( 'cache' )

            if( 0 === colleagues.length
                || !this.$core.f().isOwn( this.$props.item ) )
            {
                let index = this.functions.indexOf( 'share' )
                if( -1 < index )
                {
                    this.functions.splice( index, 1 )
                }
            }

            if( 0 < studentAccesses.length
                && this.$core.getLicense().isAllowed( 'share_with_students' )
                && this.$core.getBaseClassHelper()
                       .get( this.$props.viewItem.toLowerCase() ).isStudentShareable )
            {
                this.$core.f().insertIntoArray( this.functions, 'share-with-students', 'share' )
            }

            if( ( !this.$core.f().isOwn( this.$props.item )
                  && !this.$core.r().isAllowed( this.$props.item, 'change' )
                  && 'message' !== this.$props.item.type )
                || this.$props.item.archived === true )
            {
                this.$core.f().removeFromArray( this.functions, 'pin' )
                this.$core.f().removeFromArray( this.functions, 'edit' )
            }

            if( 'message' === this.$props.item.type
                && true === this.$props.item.archived )
            {
                this.$core.f().removeFromArray( this.functions, 'answer' )
                this.$core.f().removeFromArray( this.functions, 'forward' )
            }

            if( this.$props.item.type === 'colleague' )
            {
                if( 1 === parseInt( this.$props.item.state )
                    && ( undefined !== this.$props.item.initiator && this.$store.getters.idUser !== this.$props.item.initiator ) )
                {
                    this.functions.push( 'accept' )
                }
            }

            if( this.isPrintable() )
            {
                if( this.$core.getLicense().isAllowed( 'print' ) )
                {
                    this.functions.push( 'print' )
                }
            }

        },

        isPrintable()
        {
            let printables = [ 'Note', 'Todo' ]
            if( this.$core.getLicense().isAllowed( 'print' )
                && -1 < printables.indexOf( this.$props.viewItem ) )
            {
                return true
            }
        },

        handleFunctionClick( which )
        {
            this.$emit( 'clicked', which )
        }

    }

}
</script>