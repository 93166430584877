<template>
    <div>
        <QrcodeVue :value="qrLogin" :size=500 class="qrlogin"/>
    </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
    name: "StudentAccessQr",

    props: {
        localAccess: { type: Object, required: true }
    },

    components: {
        QrcodeVue
    },

    computed: {
        qrLogin()
        {
            return this.$core.getCryptoCore().encryptWithPublicKey( this.$core.getCryptoCore().basicAuthSetup().public, JSON.stringify( {
                username  : this.$props.localAccess.username,
                passphrase: this.$props.localAccess.passphrase
            } ) )
        }
    }

}
</script>