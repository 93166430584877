<template>
    <div class="pin-reader">
        <div class="center stream" v-if="!noCamera">
            <QrCodeReader @ondecode="onDecodeQr"
                          @nocamera="noCamera = true"/>
        </div>
        <div :class="noCamera ? '' : 'top-margin'">
            <input type="text"
                   :id="'form-element-'+id"
                   :value="qrOutput"
                   :placeholder="placeholder || 'Vernetzungs-PIN hier manuell eingeben'"
                   :class="highlighted"
                   @change="update"
                   @keyup="checkKey"/>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
import QrCodeReader from './QrCodeReader'

export default {

    name : 'PinBox',
    props: {
        id         : { Type: String, required: true },
        refName    : { Type: String, required: false },
        value      : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        eventKey   : { Type: String, required: false },
        placeholder: { Type: String, required: false }
    },

    emits: [ 'update', 'enterPressed', 'backspacePressed' ],

    data()
    {
        return {
            timer      : false,
            camera     : 'front',
            noCamera   : false,
            highlighted: '',
            qrPaused   : true,
            qrOutput   : '',
            qrDecoded  : ''
        }
    },

    mounted()
    {
        if( this.$props.value !== undefined )
        {
            this.qrOutput = this.$props.value
            this.$nextTick()
                .then( () =>
                {
                    this.update()
                } )
        }
        this.qrPaused = false
    },

    components: {
        QrCodeReader
    },

    methods: {

        checkKey( key )
        {
            if( 13 === key.keyCode )
            {
                this.$emit( 'enterPressed', this.$props.id )
            }
            else
            {
                let elm = document.querySelector( '#form-element-' + this.$props.id )
                if( 8 === key.keyCode )
                {
                    if( '' === elm.value.trim() )
                    {
                        this.$emit( 'backspacePressed', this.$props.id )
                    }
                }
                else
                {
                    this.update()
                }
            }
        },

        update()
        {

            let elm = document.querySelector( '#form-element-' + this.$props.refName )
            let test = elm.value
            this.qrOutput = test

            if( this.$core.getValidator().validate( 'pin', false, test ) )
            {
                this.$core.getUi().vibrate()
                this.highlighted = 'form-success'
                this.$emit( 'update', this.$props.refName, 'textBox', 'update', undefined, ( test.trim() !== '' ? test : undefined ) )
                this.$core.getEventManager()
                    .dispatch( 'pin-update', test )
            }
            else
            {
                this.highlighted = 'form-error'
            }

        },

        onDecodeQr( data )
        {
            this.qrDecoded = data.trim()
            if( '' !== this.qrDecoded )
            {
                this.qrOutput = '' + this.qrDecoded

                this.$nextTick()
                    .then( () =>
                    {
                        this.update()
                    } )

            }
        }

    }

}
</script>