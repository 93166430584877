/*eslint-disable*/
export default class afterFetchList
{

    constructor( core )
    {
        this.baseClassHelper = core.getBaseClassHelper()
    }

    _createContainer( list )
    {

        let container = {}

        for( let key in list )
        {
            if( key !== 'values'
                && key !== '_keys' )
            {
                container[ key ] = list[ key ]
            }
        }

        container.isDemoContent = list.isDemoContent
        container.tsmpEnd = list.timestamp
        container.tsmpStart = list.timestamp
        container.labels = list.labels
        container.lasteditor = list.lasteditor
        container.lists = []
        container.lists.push( list )

        return container

    }

    fixMeta( container, list )
    {
        if( container.labels !== list.labels )
        {
            container.labels = list.labels
        }
        if( container.tsmpEnd < list.timestamp )
        {
            container.tsmpEnd = list.timestamp
        }
        if( container.tsmpStart > list.timestamp )
        {
            container.tsmpStart = list.timestamp
        }
        if( container.update < list.update )
        {
            container.update = list.update
            container.lasteditor = list.lasteditor
        }
    }

    isListForToday( timestamp )
    {

        let now  = new Date(),
            then = new Date( timestamp )

        return ( now.getFullYear() === then.getFullYear()
                 && now.getMonth() === then.getMonth()
                 && now.getDate() === then.getDate() )

    }

    afterFetch( lists, skipArchived )
    {
        return new Promise( resolve =>
        {

            let containers = {},
                result     = []

            for( let l in lists )
            {
                if( undefined === containers[ lists[ l ].referenceKey ] )
                {
                    containers[ lists[ l ].referenceKey ] = this._createContainer( lists[ l ] )
                }
                else
                {
                    let found = false

                    for( let ll in containers[ lists[ l ].referenceKey ].lists )
                    {
                        if( containers[ lists[ l ].referenceKey ].lists[ ll ].localId === lists[ l ].localId )
                        {
                            found = true
                            containers[ lists[ l ].referenceKey ].lists[ ll ] = lists[ l ]
                            this.fixMeta( containers[ lists[ l ].referenceKey ], lists[ l ] )
                        }
                    }
                    if( !found )
                    {
                        containers[ lists[ l ].referenceKey ].lists.push( lists[ l ] )
                        this.fixMeta( containers[ lists[ l ].referenceKey ], lists[ l ] )
                        if( this.isListForToday( lists[ l ].timestamp ) )
                        {
                            containers[ lists[ l ].referenceKey ].listForToday = lists[ l ]
                        }
                    }
                }
            }

            for( let key in containers )
            {
                result.push( containers[ key ] )
            }

            return resolve( result )

        } )
    }

}