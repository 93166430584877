<template>
    <DistinctElementThreewaytoggle v-if="type === 'threewaytoggle'"
                                   :distinct="distinct"
                                   :totalCount="totalCount"/>
</template>

<script>
import DistinctElementThreewaytoggle
    from '@/components/elements/viewItems/listViews/listElement-distincts/DistinctElement-threewaytoggle'

export default {

    name      : 'ListElementDistincts',
    components: { DistinctElementThreewaytoggle },
    props     : {
        type      : { type: String, required: true },
        distinct  : { type: Object, required: false, default: undefined },
        totalCount: { type: Number, required: false, default: undefined },
    }

}
</script>