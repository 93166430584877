<template>
    <TutorialMessage :caption="$core.t( 'tutorial-caption-office' )"
                     :text="$core.t( 'tutorial-text-office-'+step )"
                     :step="step"
                     :last="last"
                     :backBlocked="backBlocked"
                     @back="handleBack()"
                     @next="handleNext()"/>
    <TutorialElement v-if="fakeElement" :fakeElement="fakeElement"/>
</template>

<script>
import TutorialMessage from '@/components/elements/tutorial/TutorialMessage'
import TutorialBase    from '@/components/elements/tutorial/TutorialBase'
import TutorialElement from '@/components/elements/tutorial/TutorialElement'

export default {

    name      : 'TutorialOffice',
    components: { TutorialElement, TutorialMessage },
    extends   : TutorialBase,

    data()
    {
        return {
            last: 21,
            fake: {
                type      : 'list',
                listname  : 'Anwesenheitsliste',
                color     : 'green',
                fakeCount : 7,
                idOwner   : this.$store.getters.idUser,
                archived  : false,
                lasteditor: this.$store.getters.idUser,
                listType  : 'checklist',
                columns   : [
                    {
                        type    : 'fixed',
                        values  : 'students',
                        caption : 'Schüler:in',
                        filter  : 'class',
                        filterBy: 'demo-class-object'
                    },
                    {
                        type   : 'checkbox',
                        id     : '0_anwesend',
                        caption: 'anwesend'
                    },
                    {
                        type   : 'checkbox',
                        id     : '1_verspätet',
                        caption: 'verspätet'
                    },
                    {
                        type   : 'checkbox',
                        id     : '2_fehlt_entschuldigt',
                        caption: 'fehlt entschuldigt'
                    },
                    {
                        type   : 'checkbox',
                        id     : '3_fehlt_unentschuldigt',
                        caption: 'fehlt unentschuldigt'
                    },
                    {
                        type   : 'checkbox',
                        id     : '4_krank',
                        caption: 'krank'
                    }
                ],
                lists     : []
            }
        }
    },

    created()
    {

        this.$core.getEventManager()
            .dispatch( 'on-prepare-demo-objects' )

        let tsmpStart = Date.now() - ( 18 * 86400000 )
        for( let l = 0; l < 12; l++ )
        {

            let day = new Date( tsmpStart )
            switch( day.getDay() )
            {
                case 0:
                    tsmpStart += 86400000
                    break
                case 6:
                    tsmpStart += ( 86400000 * 2 )
                    break
                case 5:
                    tsmpStart += ( 86400000 * 3 )
                    break
            }

            if( undefined === this.fake.timestamp )
            {
                this.fake.timestamp = tsmpStart
            }

            let list = {
                type         : 'list',
                listname     : 'Anwesenheitsliste',
                localId      : 'demo-list-item-' + l,
                color        : 'green',
                fakeCount    : 7,
                idOwner      : this.$store.getters.idUser,
                lasteditor   : this.$store.getters.idUser,
                archived     : false,
                listType     : 'checklist',
                selectedClass: 'class:demo-class-object',
                columns      : this.fake.columns,
                timestamp    : tsmpStart,
                update       : tsmpStart,
                values       : this.randomValues( this.fake.columns )
            }
            this.fake.lists.push( list )

            tsmpStart += 86400000

        }
    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .dispatch( 'on-remove-demo-objects' )
    },

    methods: {

        /*eslint-disable*/
        randomValues( columns )
        {

            let students = this.$core
                               .getBaseClassHelper()
                               .get( 'class' )
                               .getById( 'demo-class-object' ).students,
                values   = {}

            for( let s in students )
            {

                let rand      = Math.floor( Math.random() * ( columns.length - 1 ) ) + 1,
                    colIdBare = this.$core.getSanitizers().cleanId( columns[ rand ].id ),
                    temp      = colIdBare.split( /_/g ),
                    count     = parseInt( temp.shift() ),
                    colId     = temp.join( '_' ) + '___' + ( count + 1 )

                values[ students[ s ] + '___' + colId ] = 1

            }

            return values

        },

        prepareHighlights()
        {
            this.highlights = []
            switch( this.step )
            {
                case 1:
                    break
                case 2:
                    this.highlights = [
                        {
                            prepare    : {
                                action : 'click',
                                root   : '.tab-space',
                                element: '#own'
                            },
                            root       : '.head-functions',
                            elements   : [ '.button.round.add.large' ],
                            waitForNext: '.default-form-container'
                        }
                    ]
                    break
                case 3:
                    this.highlights = [
                        {
                            root        : '.default-form-wrapper',
                            elements    : [ '#listType' ],
                            unblurred   : true,
                            backdisabled: true
                        }
                    ]
                    break
                case 4:
                    this.highlights = [
                        {
                            root     : '.default-form-wrapper',
                            unblurred: true
                        }
                    ]
                    break
                case 5:
                    this.highlights = [
                        {
                            root     : '.default-form-wrapper',
                            showFill : {
                                initialDelay: 3000,
                                fill        : [
                                    {
                                        delay : 1000,
                                        root  : '.default-form-wrapper',
                                        type  : 'static',
                                        fields: [ 'f:#form-element-listType:checklist' ]
                                    },
                                    {
                                        delay : 1000,
                                        root  : '.default-form-wrapper',
                                        type  : 'static',
                                        fields: [ 'f:#form-element-listType:ratinglist' ]
                                    },
                                    {
                                        delay : 1000,
                                        root  : '.default-form-wrapper',
                                        type  : 'static',
                                        fields: [ 'f:#form-element-listType:recallList' ]
                                    },
                                    {
                                        delay : 1000,
                                        root  : '.default-form-wrapper',
                                        type  : 'static',
                                        fields: [ 'f:#form-element-listType:test' ]
                                    }
                                ]
                            },
                            unblurred: true
                        }
                    ]
                    break
                case 6:
                    this.highlights = [
                        {
                            root     : '.default-form-wrapper',
                            unblurred: true
                        }
                    ]
                    break
                case 7:
                    this.highlights = [
                        {
                            root     : '.default-form-wrapper',
                            prepare  : {
                                action : 'click',
                                root   : '.tab-space',
                                element: '#template'
                            },
                            showFill : {
                                initialDelay: 1000,
                                fill        : [
                                    {
                                        delay : 1500,
                                        root  : '.default-form-wrapper',
                                        type  : 'static',
                                        fields: [ 'f:#select-element-listType:template_vendor_2' ]
                                    },
                                    {
                                        delay : 1500,
                                        root  : '.default-form-wrapper',
                                        type  : 'static',
                                        fields: [ 'f:#select-element-listType:template_vendor_5' ]
                                    },
                                    {
                                        delay : 1500,
                                        root  : '.default-form-wrapper',
                                        type  : 'static',
                                        fields: [ 'f:#select-element-listType:template_vendor_10' ]
                                    }
                                ]
                            },
                            unblurred: true
                        }
                    ]
                    break
                case 8:
                    this.highlights = [
                        {
                            prepare    : {
                                action : 'click',
                                root   : '.default-form-wrapper',
                                element: '.button.round.close.absolute.top.right.inverted'
                            },
                            prepareDemo: true
                        },
                        {
                            root       : '.default-list-page',
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 9:
                    this.highlights = [
                        {
                            root       : '.list-functions',
                            elements   : [
                                '.list-opener'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 10:
                    this.highlights = [
                        {
                            root       : '.list-functions',
                            elements   : [
                                '.show-for-today'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 11:
                    this.highlights = [
                        {
                            root       : '.list-functions',
                            elements   : [
                                '.sums'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 12:
                    this.highlights = [
                        {
                            root       : '.list-functions',
                            elements   : [
                                '.calendar'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 13:
                    this.highlights = [
                        {
                            root       : '.list-functions',
                            elements   : [],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 14:
                    this.highlights = [
                        {
                            root       : '.default-list-page',
                            elements   : [],
                            fakeElement: this.fake,
                            waitForNext: '.touchable-active'
                        }
                    ]
                    break
                case 15:
                    this.highlights = [
                        {
                            root        : '.default-list-page',
                            elements    : [],
                            backdisabled: true,
                            fakeElement : this.fake
                        }
                    ]
                    break
                case 16:
                    this.highlights = [
                        {
                            root       : '.touchable-right',
                            elements   : [
                                '.button.pin'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 17:
                    this.highlights = [
                        {
                            root       : '.touchable-right',
                            elements   : [
                                '.button.edit'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 18:
                    this.highlights = [
                        {
                            root       : '.touchable-right',
                            elements   : [
                                '.button.stats'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 19:
                    this.highlights = [
                        {
                            root       : '.touchable-right',
                            elements   : [
                                '.button.delete'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break
                case 20:
                    this.highlights = [
                        {
                            root       : '.touchable-right',
                            elements   : [
                                '.button.archive'
                            ],
                            fakeElement: this.fake
                        }
                    ]
                    break

            }

        }

    }

}
</script>