<template>
    <InlineMessage title="Du hast noch keine Planungsvorlage angelegt"
                   message="Lege neue Planungsvorlagen und Inhalte an, indem du oben auf das Zahnrad-Symbol klickst."
                   classname="warning empty"
                   :active="true"/>
</template>

<script>
import InlineMessage from "@/components/messages/InlineMessage";
export default {
    name: "OrganizerEmptyDisplay",
    components: { InlineMessage }
}
</script>