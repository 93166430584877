function plainHeadline( doc, element, headline, formattedTimestamp, color, maxWidth, punchMarks, landscape )
{

    let pmAddX = ( punchMarks && !landscape ? 10 : 0 ),
        pmAddY = ( punchMarks && landscape ? 10 : 0 ),
        y      = ( 15 + pmAddY ),
        text

    doc.setFont( 'quicksand', 'bold' )
    doc.setFontSize( 14 )
    doc.setTextColor( color[ 0 ], color[ 1 ], color[ 2 ] )
    doc.setDrawColor( color[ 0 ], color[ 1 ], color[ 2 ] )

    switch( element.type )
    {
        case 'note':
            text = 'Notiz'
            break
        case 'todo':
            text = 'Todo'
            break
        case 'scoreOverview':
        case 'access':
            text = element.listname
            break
    }

    let dimensions = doc.getTextDimensions( text ),
        timestampX = ( 10 + pmAddX ) + dimensions.w,
        textY      = y

    y += ( Math.ceil( dimensions.h ) - 2 )

    let result = {
        headline : {
            x: ( 10 + pmAddX ), y: textY, color: color, text: text
        },
        timestamp: {
            x: timestampX, y: textY, color: color, text: formattedTimestamp
        },
        lines    : [
            { x: ( 10 + pmAddX ), y: y, x2: ( maxWidth + pmAddX ), y2: y }
        ],
        y        : ( y + 3 )
    }

    return result

}

export default plainHeadline