<template>
    <div>
        <template v-if="!allFiltered && 1 < pages.length">
            <Pagination :page="displayedPage" :pages="pages"
                        :key="filterKey"
                        @pageChange="handlePageChange"/>
            <div class="clearfix"></div>
        </template>
        <div :id="viewKey" :class="'default-view-itemlist'+( galleryMode ? ' gallery' : '' )">
            <transition-group appear :name="$core.settings().getTransition( 'listmove' )">
                <template v-for="item in itemList"
                          :key="'template-dvil-'+item.localId">

                    <Touchable v-if="( item.hidden !== true || true === hiddenVisible )
                             && -1 < pages[displayedPage - 1].indexOf( item.localId )
                             && ( -1 < filtered.indexOf( item.localId ) || !viewIsFiltered )"
                               :item="item"
                               :viewItem="viewItem"
                               :selectMode="selectMode"
                               :noShareIndicator="noShareIndicator"
                               :scope="scope"
                               :singleItems="singleItems"
                               :galleryMode="galleryMode"
                               @clicked="handleClick( item )"
                               @dragging="$emit( 'dragging', $event )"
                               @selected="$emit( 'selected', $event )"/>

                </template>
                <template v-if="itemList.length > 0">
                    <div class="clearfix"></div>
                </template>
            </transition-group>
            <template v-if="!allFiltered && 1 < pages.length">
                <Pagination :page="displayedPage" :pages="pages"
                            :key="filterKey"
                            @pageChange="handlePageChange"/>
                <div class="clearfix"></div>
            </template>
            <InlineMessage :active="allFiltered"
                           :key="'message-inline-'+scope+'-'+viewItem"
                           classname="nothing"
                           :title="$core.t( 'caption-all-filtered' )"
                           :message="$core.t( 'message-all-filtered' )"/>
            <InlineMessage :active="!allFiltered && 0 === itemList.length"
                           :key="'message-inline-nothing-'+scope+'-'+viewItem"
                           classname="empty"
                           :title="$core.t( 'default-caption-empty' )"
                           :message="$core.t( 'message-empty-'+scope )"/>
        </div>
    </div>
</template>
<script>
import InlineMessage         from "@/components/messages/InlineMessage";
import Pagination            from "@/components/elements/defaults/Pagination";
import MixinPagination       from "@/components/defaults/defaultViewItemList/MixinPagination";
import MixinDetailViews      from "@/mixins/MixinDetailViews";
import MixinComponentCleanup from "@/mixins/MixinComponentCleanup";

export default {

    name      : 'DefaultViewItemList',
    components: { Pagination, InlineMessage },
    mixins    : [
        MixinPagination,
        MixinDetailViews,
        MixinComponentCleanup
    ],

    emits: [ 'clicked', 'selected', 'dragging' ],

    props: {
        itemList        : { Type: Array, required: true },
        viewKey         : { Type: String, required: true },
        filtered        : { Type: Array, required: false, default: [] },
        filterKey       : { Type: String, required: true },
        viewIsFiltered  : { Type: Boolean, required: false, default: false },
        viewItem        : { Type: String, required: true },
        scope           : { Type: String, required: false, default: '' },
        viewScope       : { Type: String, required: false, default: 'cache' },
        singleItems     : { Type: Boolean, required: false, default: false },
        hiddenVisible   : { Type: Boolean, required: false, default: false },
        selectMode      : { Type: Boolean, required: false, default: false },
        noShareIndicator: { Type: Boolean, required: false, default: false },
        emitClick       : { Type: Boolean, required: false, default: false },
        galleryMode     : { Type: Boolean, required: false, default: false },
    },

    computed: {
        allFiltered()
        {
            return this.$props.viewIsFiltered && 0 === this.$props.filtered.length
        }
    },

    created()
    {
        this.preparePagination()
    },

    methods: {

        handleClick( item )
        {

            if( true === this.$props.emitClick )
            {
                this.$emit( 'clicked', item )
            }

            this.handleDetailView( item )

        }

    }

}
</script>