<script>
export default {

    name: "MixinSwipeHandler",

    data()
    {
        return {
            touchDistance : 0,
            touchDistanceY: 0,
            originalHeight: 0
        }
    },

    created()
    {
        this.resetSwipes()
    },

    mounted()
    {
        this.$nextTick()
            .then( () =>
            {
                this.setTouchEventHandler()
            } )
    },

    methods: {

        resetSwipes()
        {
            this.touchStartX = 0
            this.touchStartY = 0
            this.touchEndX = 0
            this.touchEndY = 0
            this.originalHeight = 0
            this.rightOnce = false
        },

        setTouchEventHandler( retries )
        {

            retries = ( undefined === retries ? 0 : retries )
            let elm = document.querySelector(
                '#' + 'touchable-' + this.$props.viewItem + '-' + this.$props.scope + '-' + this.$props.item.localId
            )

            if( null !== elm )
            {
                elm.addEventListener( 'touchstart', ( event ) =>
                {
                    this.touchStartX = event.changedTouches[ 0 ].screenX
                    this.touchStartY = event.changedTouches[ 0 ].screenY
                }, { passive: true } )

                elm.addEventListener( 'touchend', ( event ) =>
                {
                    this.touchEndX = event.changedTouches[ 0 ].screenX
                    this.touchEndY = event.changedTouches[ 0 ].screenY
                    this.handleGesture()
                }, { passive: true } )

            }
            else
            {
                retries++
                if( 10 > retries )
                {
                    setTimeout( () =>
                    {
                        this.setTouchEventHandler( retries )
                    }, 250 )
                }
            }

        },

        handleSwipe( bareEvent, direction )
        {

            if( this.$core.getLicense().forceReadOnly()
                || true === this.$core.getState( 'listEditorOpen' )
                || true === this.$props.bare
                || true === this.scrolling
                || true === this.functionsDisappearing )
            {
                return
            }

            if( false !== bareEvent
                && undefined !== bareEvent.button
                && 2 === bareEvent.button )
            {
                bareEvent.preventDefault()
            }

            if( this.$props.clickDisabled
                || this.$props.item.editLocked === true )
            {
                return
            }

            if( undefined === direction && !this.rightOnce )
            {
                direction = 'left'
                this.rightOnce = true
            }
            if( undefined === direction && this.rightOnce )
            {
                direction = 'right'
                this.rightOnce = false
            }

            let idPart = this.$props.viewItem + '-' + this.$props.scope + '-' + this.$props.item.localId
            if( 'left' === direction )
            {
                this.swiped = true
                this.functionsDisappearing = true
                this.originalHeight = this.$core.getUi()
                                          .resizeTouchable( 'touchable-' + idPart, 'touchable-functions-' + idPart, true )
                setTimeout( () =>
                {
                    this.swipedRight = true
                    this.$nextTick()
                        .then( () => {
                            this.functionsDisappearing = false
                        })
                }, 350 )
            }
            else
            {
                this.functionsDisappearing = true
                this.$core.getUi().resizeTouchable( 'touchable-' + idPart, 'touchable-functions-' + idPart, false )
                setTimeout( () =>
                {
                    this.swipedRight = false
                    this.functionsDisappearing = false
                    this.swiped = false
                }, 400 )
            }

        },

        handleGesture()
        {

            let touchDistance = this.touchStartX - this.touchEndX
            if( 0 > touchDistance )
            {
                touchDistance = touchDistance * -1
            }

            let touchDistanceY = this.touchStartY - this.touchEndY
            if( 0 > touchDistanceY )
            {
                touchDistanceY = touchDistanceY * -1
            }

            this.touchDistance = touchDistance
            this.touchDistanceY = touchDistanceY

            if( touchDistanceY > 50 )
            {
                this.$core.getLogger().clog( 'DefaultTouchable:handleGesture', 'assuming scroll event...' )
                return
            }

            if( touchDistance < 60 )
            {

                this.$core.getLogger().clog( 'DefaultTouchable:handleGesture', 'distance too short: assuming click event...' )
                this.handleClick()

            }
            else
            {

                if( this.touchEndX < this.touchStartX )
                {
                    this.handleSwipe( false, 'left' )
                }

                if( this.touchEndX > this.touchStartX )
                {
                    this.handleSwipe( false, 'right' )
                }

            }

        }

    }

}
</script>