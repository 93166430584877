<template>
    <div class="form" v-if="prepared">
        <FormExtendedButton :hasCloser="true" :hasExtended="hasExtended" @toggle="showExtended = !showExtended"/>
        <FormMessages :elevated="elevated" :errors="errors" :messages="messages"/>
        <div class="clearfix"></div>
        <form @keydown="noSubmit" method="post" v-on:submit.prevent="onSubmit">
            <transition-group appear :name="$core.settings().getTransition( 'slide-fade' )">
                <div v-for="field in preparedFields"
                     v-show="(!$core.f().isset( field.extended ) || showExtended === true)
                             && ( !field.hiddenOnEdit || editItem === false )
                             && ( !field.hideIf || ( true !== values[field.hideIf] && 1 !== values[field.hideIf] ) )
                             && ( field.type !== 'ScoreModelSelector'
                             || ( field.type === 'ScoreModelSelector' && hasScoreBox ) )"
                     class="form-row"
                     :key="'row-'+fieldId( field )">
                    <template v-if="undefined === field.acl || $core.getLicense().isAllowed( field.acl )">
                        <div v-if="undefined === field.colSpan || 1 === field.colSpan"
                             :class="'form-caption'+( field.type === 'Hidden' ? ' hidden' : '' )"
                             v-html="( field.translate ? $core.t( field.caption ) : field.caption )"></div>
                        <div :class="'form-component'+( field.type === 'Hidden' ? ' hidden' : '' )
                                 +( 2 === field.colSpan ? ' colspan-2' : '' )">
                            <component :key="'field-'+field.type+'-'+fieldId( field )"
                                       :is="field.type"
                                       :id="fieldId( field )"
                                       :value="( field.type !== 'PasswordBox' ? values[ field.ref ] : undefined )"
                                       :changeKey="changeKey"
                                       :scoreModel="field.scoreModel !== undefined ? field.scoreModel : undefined"
                                       :refName="fieldId( field )"
                                       :idType="field.idType !== undefined ? field.idType : undefined"
                                       :eventKey="eventKey"
                                       :scope="scope"
                                       :placeholder="field.placeholder !== undefined ? field.placeholder : undefined"
                                       :prefillParams="editItem"
                                       :reformatter="field.reformatter"
                                       :dragBlocked="field.dragBlocked === true ? true : undefined"
                                       :unique="field.unique === true ? true : undefined"
                                       :asPlainList="field.asPlainList === true ? true : undefined"
                                       :ignoreClassId="field.ignoreClassId === true ? true : undefined"
                                       :extendedFilter="field.extendedFilter === true ? true : undefined"
                                       :allowForeign="field.allowForeign === true ? true : undefined"
                                       :onlyUnassigned="field.onlyUnassigned !== undefined ? field.onlyUnassigned : undefined"
                                       :component="field.component !== undefined ? field.component : undefined"
                                       :editItem="editItem ? editItem : undefined"
                                       :resultingType="fixedResultingType"
                                       @nestedForm="$emit( 'nestedForm', $event )"
                                       @resortAdder="handleResort"
                                       @update="handleUpdate"
                                       @scoreBox="handleScoreBox"
                                       @formUpdate="handleFormupdate"
                                       @enterPressed="handleEnter( fieldId( field ) )"/>
                        </div>
                        <div class="clearfix"></div>
                    </template>
                </div>
            </transition-group>
            <FormButtons :buttons="buttons"
                         :eventKey="eventKey"
                         :submitVisible="submitVisible"
                         @validate="( ...args ) => { validate( ...args ) }"/>
        </form>
    </div>
</template>

<script>
/*components*/
import Checkbox                  from '@/components/form/elements/Checkbox'
import ClassSelector             from '@/components/form/elements/ClassSelector'
import ColorSelector             from '@/components/form/elements/ColorSelector'
import ConfessionSelector        from '@/components/form/elements/ConfessionSelector'
import CountrySelector           from '@/components/form/elements/CountrySelector'
import GenderSelector            from '@/components/form/elements/GenderSelector'
import GroupSelector             from '@/components/form/elements/GroupSelector'
import YeargroupSelector         from '@/components/form/elements/YeargroupSelector'
import Hidden                    from '@/components/form/elements/Hidden'
import StudentSelector           from '@/components/form/elements/StudentSelector'
import TextBox                   from '@/components/form/elements/TextBox'
import TextArea                  from '@/components/form/elements/TextArea'
import PasswordBox               from '@/components/form/elements/PasswordBox'
import ZipCity                   from '@/components/form/elements/ZipCity'
import InlineMessage             from '@/components/messages/InlineMessage'
import ListTypeSelector          from '@/components/form/elements/ListTypeSelector'
import ListSetupSelector         from '@/components/form/elements/ListSetupSelector'
import RowAdder                  from '@/components/form/elements/RowAdder'
import StudentGroupSelector      from '@/components/form/elements/StudentGroupSelector'
import ScoreModelSelector        from '@/components/form/elements/ScoreModelSelector'
import TestDistributionKey       from '@/components/form/elements/tests/TestDistributionKey'
import DateBox                   from '@/components/form/elements/DateBox'
import ElementTypeSelector       from '@/components/form/elements/ElementTypeSelector'
import PinBox                    from '@/components/form/elements/PinBox'
import ColleagueSelector         from '@/components/form/elements/ColleagueSelector'
import RatingStarsSelector       from '@/components/form/elements/RatingStarsSelector'
import RateFlexSelector          from '@/components/form/elements/RateFlexSelector';
import ProcessingIndicator       from '@/components/elements/indicators/ProcessingIndicator'
import FeedbackSelector          from '@/components/form/elements/FeedbackSelector'
import ScopeSelector             from '@/components/form/elements/ScopeSelector'
import PhoneBox                  from '@/components/form/elements/PhoneBox'
import LinkedColumnsSelector     from "@/components/form/elements/LinkedColumnsSelector";
import CalculatedColumnsSelector from "@/components/form/elements/CalculatedColumnsSelector";
import FileBox                   from "@/components/form/elements/FileBox";
import SlotEditorBox             from "@/components/form/elements/SlotEditorBox";
import OrganizerSelector         from "@/components/form/elements/OrganizerSelector";
import ContentSlotEditorBox      from "@/components/form/elements/ContentSlotEditorBox";
/*parts*/
import FormExtendedButton        from '@/components/form/parts/FormExtendedButton'
import FormMessages              from '@/components/form/parts/FormMessages'
import FormButtons               from '@/components/form/parts/FormButtons'

/*mixins*/
import MixinBasicPrepares        from '@/components/form/formbase/MixinBasicPrepares'
import MixinEvents               from '@/mixins/MixinEvents'
import MixinCheckExists          from '@/components/form/formbase/MixinCheckExists'
import LabelSelector             from "@/components/form/elements/LabelSelector";

/*eslint-disable*/
export default {

    name: 'Form',

    mixins: [
        MixinBasicPrepares,
        MixinEvents,
        MixinCheckExists
    ],

    props: {

        elevated         : { type: Boolean, required: false },
        fields           : { type: Array, required: true },
        buttons          : { type: Object, required: false },
        editItem         : { type: [ Boolean, Object ], required: false },
        eventKey         : { type: String, required: false },
        scope            : { type: String, required: false },
        validateId       : { type: String, required: false },
        noErrorMessage   : { type: Boolean, required: false, default: false },
        organizerSlot    : { type: Object, required: false },
        injectedChangeKey: { type: String, required: false }

    },

    emits: [ 'nestedForm', 'appendClassReference', 'formUpdated', 'formReset' ],

    components: {
        LabelSelector,
        CalculatedColumnsSelector,
        LinkedColumnsSelector,
        FormButtons,
        FormMessages,
        FormExtendedButton,
        PhoneBox,
        ProcessingIndicator,
        ColleagueSelector,
        PinBox,
        ElementTypeSelector,
        DateBox,
        TestDistributionKey,
        ScoreModelSelector,
        StudentGroupSelector,
        RowAdder,
        ListTypeSelector,
        ListSetupSelector,
        InlineMessage,
        Checkbox,
        ClassSelector,
        ColorSelector,
        CountrySelector,
        ConfessionSelector,
        GenderSelector,
        GroupSelector,
        YeargroupSelector,
        Hidden,
        StudentSelector,
        TextBox,
        TextArea,
        PasswordBox,
        RatingStarsSelector,
        RateFlexSelector,
        ZipCity,
        FeedbackSelector,
        ScopeSelector,
        FileBox,
        SlotEditorBox,
        OrganizerSelector,
        ContentSlotEditorBox
    },

    data()
    {
        return {
            initialListType   : false,
            listType          : false,
            hasScoreBox       : false,
            prepared          : false,
            messages          : false,
            preparedFields    : [],
            dynamicFields     : [],
            nextField         : {},
            showExtended      : false,
            hasExtended       : false,
            classFilter       : false,
            className         : false,
            errors            : false,
            values            : {},
            changeKey         : false,
            fixedResultingType: undefined,
            originalListType  : false,
            submitVisible     : true,
            lastUpdate        : 0,
            fromTemplate      : false
        }
    },

    watch: {

        changeKey: {
            immediate: true,
            handler( newKey, oldKey )
            {
                if( this.lastUpdate > 0
                    && oldKey !== newKey
                    && undefined !== oldKey )
                {
                    this.$nextTick()
                        .then( () =>
                        {
                            this.prepareForm( true )
                        } )
                }
            }
        },

        injectedChangeKey: {
            immediate: true,
            handler( newKey, oldKey )
            {
                if( this.lastUpdate > 0
                    && oldKey !== newKey
                    && undefined !== oldKey )
                {
                    this.changeKey = this.$props.injectedChangeKey
                }
            }
        }

    },

    created()
    {
        this.changeKey = this.$props.injectedChangeKey || this.$core.getUuid().generate()
        this.lastUpdate = Date.now()
        this.$core.getEventManager()
            .add( 'on-form-reset', () =>
            {
                this.formReset()
                this.$nextTick()
                    .then( () =>
                    {
                        this.formInit()
                    } )
            } )
    },

    mounted()
    {
        this.formInit()
    },

    beforeUnmount()
    {
        clearInterval( this.scoreBoxTimer )
        this.$core.getEventManager()
            .remove( 'on-form-reset' )
    },

    methods: {

        formReset()
        {
            this.initialListType = false
            this.listType = false
            this.hasScoreBox = false
            this.prepared = false
            this.messages = false
            this.preparedFields = []
            this.dynamicFields = []
            this.nextField = {}
            this.showExtended = false
            this.hasExtended = false
            this.classFilter = false
            this.className = false
            this.errors = false
            this.values = {}
            this.changeKey = false
            this.fixedResultingType = undefined
            this.originalListType = false
            this.submitVisible = true
            this.lastUpdate = 0
            this.fromTemplate = false
        },

        formInit()
        {
            this.prepareClassFilter()
            this.addEvent( 'formUseTemplate', ( setup ) =>
            {
                this.templatize( setup )
            } )

            if( undefined !== this.$props.validateId )
            {
                this.addEvent( 'validate-form-' + this.$props.validateId, () =>
                {
                    this.validate( undefined, () =>
                    {
                        this.$core.getEventManager().dispatch( 'validation-success-' + this.$props.validateId )
                    } )
                } )
            }

            this.prepareForm()
            this.scoreBoxTimer = setInterval( () =>
            {

                this.scanScoreBoxes()

            }, 300 )
        },

        handleUpdate( id, type, method, elmId, values, removed )
        {

            this.lastUpdate = Date.now()

            let newSet  = [],
                field   = undefined,
                fieldId = undefined

            this.values[ id ] = values

            for( let f in this.preparedFields )
            {
                let fld = this.preparedFields[ f ]
                if( fld.ref === id
                    || ( fld.type === 'RowAdder' && fld.id === id ) )
                {
                    fieldId = f
                    field = fld
                }
            }

            if( !this.$props.editItem )
            {
                this._checkExists( field, values )
            }

            if( 'combilistSetup' === this.fixedResultingType
                && 'columns' === id )
            {
                field.value = values
            }

            if( 'rowAdder' !== type
                && undefined !== fieldId )
            {
                this.preparedFields[ fieldId ].value = values
            }

            if( 'rowAdder' === type )
            {

                if( ( field !== undefined
                      && ( ( 'remove' === method || 'update' === method ) || 'add' === method ) ) )
                {

                    if( 'string' === typeof field.value )
                    {
                        let nuval = []
                        nuval.push( field.value )
                        field.value = nuval
                    }

                    for( let v in field.value )
                    {
                        if( parseInt( v ) !== parseInt( elmId )
                            && 'update' !== method )
                        {
                            newSet.push( field.value[ v ] )
                        }
                    }

                    /**
                     * if a student is _actively_ removed from a students row adder (by changing a group, class
                     *   or yeargroup), the historic values have to be changed. historic is only needed for archived
                     *   students, that _cannot_ be removed actively.
                     */
                    if( 'remove' === method
                        && 'students' === id
                        && undefined !== removed
                        && undefined !== this.values[ 'historicStudents' ] )
                    {

                        if( 'object' === typeof removed
                            && undefined !== removed.value
                            && -1 < this.values[ 'historicStudents' ].indexOf( removed.value ) )
                        {

                            let set = this.values[ 'historicStudents' ].split( ',' )
                            this.$core.f().removeFromArray( set, removed.value )
                            this.values[ 'historicStudents' ] = set.join( ',' )
                            for( let p in this.preparedFields )
                            {
                                if( 'historicStudents' === this.preparedFields[ p ].ref )
                                {
                                    this.preparedFields[ p ].value = set.join( ',' )
                                }
                            }
                        }

                    }

                    for( let v in values )
                    {

                        if( undefined !== values[ v ].value )
                        {

                            let mode = values[ v ].mode
                            let push = values[ v ].value

                            if( undefined !== newSet[ v ] )
                            {
                                newSet[ v ] = {
                                    value: push,
                                    mode : mode
                                }
                            }
                            else
                            {
                                newSet.push( {
                                    value: push,
                                    mode : mode
                                } )
                            }

                        }

                    }

                }

                if( undefined !== fieldId )
                {
                    this.preparedFields[ fieldId ].value = newSet
                }

            }

            this.$emit( 'formUpdated' )

        },

        fieldId( field )
        {

            return ( undefined === field.id ? field.ref : field.id )

        },

        noSubmit( event )
        {
            if( event.keyCode === 13
                && !event.target.classList.contains( 'textarea' ) )
            {
                event.preventDefault()
            }
        },

        handleScoreBox( value )
        {
            this.hasScoreBox = value
        },

        handleEnter( id )
        {
            if( undefined !== this.nextField[ id ] )
            {
                let elm = document.querySelector( '#form-element-' + this.nextField[ id ] )
                if( null !== elm )
                {
                    elm.focus()
                }
            }
        },

        clearGoodFields( goods )
        {
            for( let e in goods )
            {
                let elm = document.querySelector( '#form-element-' + goods[ e ] )
                if( null !== elm )
                {
                    elm.classList.remove( 'error' )
                }
            }
        },

        highlightErrors( errors )
        {
            for( let e in errors )
            {
                let elm = document.querySelector( '#form-element-' + errors[ e ] )
                if( null !== elm )
                {
                    elm.classList.add( 'error' )
                }
                else
                {
                    elm = document.querySelector( '#field-type-' + errors[ e ] )
                    if( null !== elm )
                    {
                        elm.classList.add( 'error' )
                    }
                }
            }

            if( false === this.$props.elevated
                && false === this.$props.noErrorMessage )
            {
                this.$core.getUi().setPageMessage(
                    this.$core.getTranslation().translate( 'form-error-generic-heading' ),
                    this.$core.getTranslation().translate( 'form-error-generic-message' ),
                    'error' )
            }
            else
            {
                this.errors = errors
            }

        },

        submitButtonState( state )
        {
            this.submitVisible = state
        },

        _prevalidate( event )
        {

            if( event )
            {
                event.preventDefault()
            }

            this.submitButtonState( false )

            if( false === this.$props.elevated )
            {
                this.$core.getUi().hidePageMessage()
            }
            else
            {
                this.errors = false
            }

        },

        _validateAdder( validator, element, field, column, plainValue )
        {

            let validateElement = undefined
            let validateValue = undefined

            switch( field.validator )
            {
                case 'select':
                    switch( field.component )
                    {
                        case 'FieldSelector':
                            validateElement = document.querySelector( '#' + element.id.replace( 'form-element', 'field-type' ) )
                            break
                    }
                    break
                case 'column_caption':
                    break
            }

            validateElement = validateElement || element

            if( undefined !== validateElement )
            {
                validateValue = validateElement.value
                if( undefined !== validateValue )
                {
                    return validator.validate( field.validator, field.optional, validateValue )
                }
            }

            return false

        },

        _nextValidateElement( field, column )
        {

            let id = 'form-element-adder-' + field.ref + '-' + column
            if( -1 < field.ref.indexOf( ',' ) )
            {
                id = 'form-element-adder-combined-' + column
            }

            return document.querySelector( '#' + id )

        },

        _plainValue( field, column )
        {

            switch( field.component )
            {
                case 'TestPart':
                    return field.value[ column ].value.value
                default:
                    return field.value[ column ].value
            }
        },

        validateRowAdder( validator, field, errors )
        {

            let values = [],
                column = 0,
                elm    = this._nextValidateElement( field, column )

            while( null !== elm
                   && this.$core.f().isset( field.value )
                   && this.$core.f().isset( field.value[ column ] ) )
            {

                let gotError = false
                let value = field.value[ column ]
                let plainValue = this._plainValue( field, column )
                if( this.$core.f().isset( plainValue )
                    && this.$core.f().isset( plainValue.value ) )
                {
                    value = field.value[ column ].value
                    plainValue = plainValue.value
                }

                if( !this._validateAdder( validator, elm, field, column, plainValue ) )
                {
                    gotError = 'adder-' + field.ref + '-' + column
                    errors.push( 'adder-' + field.ref + '-' + column )
                }
                else
                {
                    values.push( value )
                }

                column++
                elm = this._nextValidateElement( field, column )

                if( null === elm
                    && gotError !== false
                    && 0 < values.length )
                {
                    let idx = errors.indexOf( gotError )
                    if( -1 < idx )
                    {
                        errors.splice( idx, 1 )
                    }
                }

            }

            if( 0 === column
                && true !== field.optional )
            {
                errors.push( 'adder-' + field.ref + '-0' )
            }

            return values

        },

        validateOneOfElement( fieldId, validator, field )
        {

            let elm = document.querySelector( '#form-element-' + fieldId )

            if( null !== field.validator
                && !validator.validate( field.validator, field.optional, elm.value ) )
            {
                return false
            }
            else if( null === elm )
            {
                return false
            }

            return true

        },

        validateOneOf( validator, field, fieldId )
        {

            for( let o in field.oneOf )
            {
                if( this.validateOneOfElement( field.oneOf[ o ], validator, field ) )
                {
                    return true
                }
            }

            return false

        },

        validateSimpleField( validator, field, fieldId, values, errors, goods )
        {

            if( 'listSetup' === fieldId )
            {
                return
            }

            if( ( undefined === field.acl
                  || this.$core.getLicense().isAllowed( field.acl ) ) )
            {

                let elm     = document.querySelector( '#form-element-' + fieldId ),
                    fileElm = document.querySelector( '#filebox-valid-' + fieldId )

                if( null !== field.validator
                    && !validator.validate( field.validator, field.optional, elm.value ) )
                {

                    let validateHideIf = true,
                        validateOneOf  = true

                    if( undefined === field.hideIf && undefined === field.oneOf )
                    {
                        validateHideIf = false
                        validateOneOf = false
                    }

                    if( undefined !== field.hideIf )
                    {
                        if( 1 !== values[ field.hideIf ] && true !== values[ field.hideIf ] )
                        {
                            validateHideIf = false
                        }
                    }
                    if( undefined !== field.oneOf )
                    {
                        if( !this.validateOneOf( validator, field, fieldId ) )
                        {
                            validateOneOf = false
                        }
                    }

                    if( !validateHideIf && !validateOneOf )
                    {
                        errors.push( fieldId )
                    }

                }
                else if( null !== fileElm )
                {
                    if( 'valid' !== fileElm.value )
                    {
                        errors.push( 'filebox-' + fieldId )
                    }
                }
                else
                {
                    goods.push( fieldId )
                    if( 'Checkbox' === field.type )
                    {
                        values[ fieldId ] = elm.checked === true ? 1 : 0
                    }
                    else
                    {
                        if( 'boolean' === field.reformatter )
                        {
                            values[ fieldId ] = ( 'true' === elm.value || true === elm.value )
                        }
                        else
                        {
                            values[ fieldId ] = field.value || ( null !== elm ? elm.value : undefined )/*( undefined !== field.value ? field.value : elm.value )*/
                        }
                    }
                }
            }

        },

        validateConcatField( validator, field, fields, values, errors, goods )
        {
            let todo = []
            for( let f in fields )
            {
                let id = fields[ f ]
                let elm = document.querySelector( '#form-element-' + id )
                todo.push( elm.value )
            }
            if( !validator.validate( field.validator, field.optional, todo ) )
            {
                for( let f in fields )
                {
                    errors.push( fields[ f ] )
                }
            }
            else
            {
                for( let f in fields )
                {
                    goods.push( fields[ f ] )
                    values[ fields[ f ] ] = todo.shift()
                }
            }
        },

        getHistoricFieldsDefinition()
        {

            let historic = []
            let hist = document.querySelectorAll( '.historic-ref' )

            for( let h in hist )
            {
                if( hist[ h ] instanceof HTMLElement )
                {
                    if( undefined !== hist[ h ].value
                        && 'undefined' !== hist[ h ].value
                        && '' !== hist[ h ].value.trim() )
                    {
                        let history = JSON.parse( hist[ h ].value )
                        for( let i in history )
                        {
                            historic.push( JSON.parse( history[ i ] ) )
                        }
                    }

                }
            }

            return historic

        },

        asPlainList( values )
        {

            let plain = []
            for( let v in values )
            {
                if( this.$core.f().valid( values[ v ].value ) )
                {
                    plain.push( values[ v ].value )
                }
                else if( this.$core.f().valid( values[ v ] ) )
                {
                    plain.push( values[ v ] )
                }
            }
            return plain

        },

        validate( event, callbackOnSuccess )
        {

            this._prevalidate( event )

            let errors    = [],
                goods     = [],
                values    = {},
                validator = this.$core.getValidator()

            for( let f in this.preparedFields )
            {

                let field   = this.preparedFields[ f ],
                    fieldId = this.fieldId( field )

                if( false !== field.validator )
                {

                    let fields = fieldId.split( ',' )
                    if( 1 === fields.length )
                    {

                        if( 'RowAdder' === field.type
                            || true === field.isRowAdder )
                        {

                            let rowValues = this.validateRowAdder( validator, field, errors )
                            if( !rowValues )
                            {
                                errors.push( fieldId )
                            }
                            else
                            {
                                if( true !== field.asPlainList )
                                {
                                    values[ fieldId ] = rowValues
                                }
                                else
                                {
                                    values[ fieldId ] = this.asPlainList( rowValues )
                                }
                                goods.push( fieldId )
                            }
                        }
                        else
                        {
                            this.validateSimpleField( validator, field, fieldId, values, errors, goods )
                        }

                    }
                    else
                    {
                        this.validateConcatField( validator, field, fields, values, errors, goods )
                    }
                }

            }

            this.clearGoodFields( goods )

            if( 0 === errors.length )
            {

                let historic = this.getHistoricFieldsDefinition()

                /* not sure if noch gebraucht oder kann weg */
                if( false !== this.originalListType )
                {
                    values.originalListType = this.originalListType
                }

                if( undefined !== values.listType
                    && false !== this.initialListType
                    && false !== this.listType
                    && this.listType !== this.initialListType )
                {
                    values.listType = this.listType
                }

                callbackOnSuccess( values, historic )

            }
            else
            {
                if( undefined !== this.$props.validateId )
                {
                    this.$core.getEventManager().dispatch( 'validation-errors-' + this.$props.validateId )
                }

                this.submitButtonState( true )
                this.highlightErrors( errors )
            }

        },

        handleResort( newRows )
        {

            for( let p in this.preparedFields )
            {
                let field = this.preparedFields[ p ]
                if( undefined !== field.ref && undefined !== field.id )
                {
                    if( field.ref === id || field.id === id )
                    {
                        this.preparedFields[ p ].value = newRows
                    }
                }
            }

        },

        handleFormupdate( values, changedType )
        {

            if( undefined !== changedType )
            {
                this.listType = changedType
            }

            for( let v in values )
            {

                if( 'item,score' === values[ v ].ref )
                {
                    this.hasScoreBox = true
                }

                if( this.$core.f().isset( this.$props.editItem[ values[ v ].ref ] ) )
                {

                    values[ v ].value = this.$props.editItem[ values[ v ].ref ]

                }

            }

            this.dynamicFields = values
            this.changeKey = this.$core.getUuid().generate()

        },

        nicerTemplatename( name )
        {
            let temp = name.split( / /g )
            if( 1 < temp.length )
            {
                temp.shift()
                return temp.join( ' ' )
            }
            return name
        },

        resetColumns()
        {
            this.$core.getEventManager()
                .dispatch( 'columnReset' )
            return
        },

        templatize( template )
        {

            this.fromTemplate = true

            if( false === template )
            {
                this.resetColumns()
                return
            }

            let setup         = JSON.parse( template ),
                dynamicFields = this.dynamicFields,
                hasCommentRow = false

            for( let d in dynamicFields )
            {
                switch( dynamicFields[ d ].ref )
                {
                    case 'listname':
                        if( setup.isOwn !== true )
                        {
                            dynamicFields[ d ].value = this.nicerTemplatename( setup.listname )
                            this.values[ d ] = this.nicerTemplatename( setup.listname )
                        }
                        else
                        {
                            dynamicFields[ d ].value = setup.listname
                            this.values[ d ] = setup.listname
                        }
                        break
                    case 'columns':
                    case 'item,score':
                        dynamicFields[ d ].value = []
                        for( let f in setup.columns )
                        {
                            let col = setup.columns[ f ]
                            if( 'fixed' !== col.type )
                            {
                                if( ( 'item,score' === dynamicFields[ d ].ref
                                      && -1 === col.caption.indexOf( 'Kommentar' ) )
                                    || 'item,score' !== dynamicFields[ d ].ref )
                                {
                                    col.value = col.caption
                                    dynamicFields[ d ].value.push( col )
                                }
                                else
                                {
                                    if( ( 'item,score' === dynamicFields[ d ].ref
                                          && -1 < col.caption.indexOf( 'Kommentar' ) ) )
                                    {
                                        hasCommentRow = true
                                    }
                                }
                            }
                        }
                        break
                    case 'scoreModel':
                    case 'distribution':
                        dynamicFields[ d ].value = setup[ dynamicFields[ d ].ref ]
                        break
                    case 'comment':
                        dynamicFields[ d ].value = hasCommentRow ? 'on' : undefined
                        break
                }
                this.values[ dynamicFields[ d ].ref ] = dynamicFields[ d ].value
            }

            this.originalListType = setup.listType
            this.dynamicFields = dynamicFields
            this.prepareForm( true )
            this.changeKey = this.$core.getUuid().generate()

        },

        scanScoreBoxes()
        {

            let fieldCheck = undefined

            if( Array.isArray( this.dynamicFields )
                && 0 < this.dynamicFields.length )
            {
                fieldCheck = this.dynamicFields
            }

            if( Array.isArray( this.preparedFields )
                && 0 < this.preparedFields.length )
            {
                fieldCheck = this.preparedFields
            }

            if( undefined !== fieldCheck )
            {
                for( let d in fieldCheck )
                {

                    if( 'columns' === fieldCheck[ d ].ref )
                    {

                        let cols        = fieldCheck[ d ].value,
                            hasScoreBox = false

                        for( let c in cols )
                        {
                            if( undefined !== cols[ c ].value
                                && 'scoreBox' === cols[ c ].value.type )
                            {
                                hasScoreBox = true
                                break
                            }
                        }

                        this.hasScoreBox = hasScoreBox
                        break

                    }

                }
            }

        }

    }

}
</script>