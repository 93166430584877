<template>
    <div>
        <div class="status-text synchronizing">synchronisiere {{ counter }} Element{{ 1 !== counter ? 'e' : '' }}</div>
        <div class="sync-checkmark">&#8635;</div>
    </div>
</template>

<script>
export default {
    name : 'Synchronizing',
    props: [ 'counter' ]
}
</script>