<script>
export default {
    
    name: "MixinResettableComponent",

    mounted()
    {

        this.eventIndex = this.$core.getEventManager().addIndexed( 'on-reset-input-for', ( id ) => {

            if( -1 < this.$props.refName.indexOf( id ) )
            {
                if( undefined !== this.localValue )
                {
                    this.localValue = false
                }
                if( undefined !== this.display )
                {
                    this.display = undefined
                }
                if( undefined !== this.selectedValue )
                {
                    this.selectedValue = undefined
                }
                if( undefined !== this.displayValue )
                {
                    this.displayValue = undefined
                }
                if( undefined !== this.emittedValue )
                {
                    this.emittedValue = undefined
                }
                if( undefined !== this.elementValue )
                {
                    this.initializing = true
                    this.elementValue = ''

                    this.$core.addTimeout( 'reset-input-'+this.$props.refName, 300, () => {
                        this.initializing = false
                    })
                }
                if( undefined !== this.showValue )
                {
                    this.showValue = undefined
                }

            }
        })
    },

    beforeUnmount()
    {
        this.$core.getEventManager().removeIndexedCallback( 'on-reset-input-for', this.eventIndex )
    }
    
}
</script>