export default class CollectorClass
{

    constructor( core )
    {

        if( !CollectorClass.instance )
        {

            this.f = core.f()
            this.ftsmp = core.getFriendlyTimestamp()
            this.sanitizers = core.getSanitizers()
            this.logger = core.getLogger()

            CollectorClass.instance = this

        }

        return CollectorClass.instance

    }

    /*eslint-disable*/
    _addColumns( idx, set, containerId, columns, totalcols )
    {

        for( let c in columns )
        {

            let col = columns[ c ]
            if( -1 < set.columns.indexOf( col.id ) )
            {
                totalcols.push( {
                    type      : col.type,
                    id        : idx + '_' + col.id,
                    originalId: col.id,
                    caption   : col.caption,
                    container : containerId
                } )
                idx++
            }
        }

        return idx

    }

    _getZeroTimeDates( dates )
    {

        let flattenedDates = []
        for( let d in dates )
        {

            let flatTime = this.ftsmp.mysqlTimestamp( dates[ d ] ).split( / /g )
            let zeroed = flatTime[ 0 ] + ' 00:00:00'
            let flattened = this.ftsmp.timestampFromMysql( zeroed )
            if( -1 === flattenedDates.indexOf( flattened ) )
            {
                flattenedDates.push( flattened )
            }
        }
        return flattenedDates

    }

    _collectFromLists( original, dates, collection, totalcols )
    {

        let listObjects = []
        let zeroTimeDates = this._getZeroTimeDates( dates )
        let idx = 0
        let nomerge = [ 'columns', 'lists' ]

        let listObject = {}
        for( let k in Object.keys( original ) )
        {
            let key = Object.keys( original )[ k ]
            if( -1 === nomerge.indexOf( key ) )
            {
                listObject[ key ] = original[ key ]
            }
        }

        let timestamp = 0,
            update    = 0

        listObject.columns = []
        listObject.type = 'list'
        listObject.referenceKey = original.referenceKey
        listObject.masterListId = listObject.localId

        if( timestamp < listObject.timestamp )
        {
            timestamp = listObject.timestamp
        }
        if( undefined !== listObject.update
            && update < listObject.update )
        {
            update = listObject.update
        }

        for( let c in totalcols )
        {
            listObject.columns.push( totalcols[ c ] )
        }

        for( let d in zeroTimeDates )
        {

            let list = JSON.parse( JSON.stringify( listObject ) )

            list.localId = idx + '-' + listObject.localId
            list.masterListId = listObject.localId
            list.referenceKey = listObject.referenceKey

            let tsmpMin = zeroTimeDates[ d ]
            let tsmpMax = tsmpMin + ( 86400 * 1000 )
            let values = {}

            list.timestamp = tsmpMin

            for( let c in collection )
            {

                let l = collection[ c ]
                if( l.list.timestamp >= tsmpMin && l.list.timestamp < tsmpMax )
                {

                    if( timestamp < l.list.timestamp )
                    {
                        timestamp = l.list.timestamp
                    }
                    if( undefined !== l.list.update
                        && update < l.list.update )
                    {
                        update = l.list.update
                    }

                    if( undefined !== l.list.scoreModel )
                    {
                        list.scoreModel = l.list.scoreModel
                        listObject.scoreModel = l.list.scoreModel
                    }

                    for( let cidx in totalcols )
                    {

                        if( undefined !== l.list.values && l.container === totalcols[ cidx ].container )
                        {

                            let original = totalcols[ cidx ].originalId
                            let newId = totalcols[ cidx ].id

                            let temp = original.split( /_/g )
                            let nuIdx = parseInt( temp.shift() ) + 1
                            let vals = temp.join( '_' )

                            let temp2 = newId.split( /_/g )
                            let nuIdx2 = parseInt( temp2.shift() ) + 1
                            temp2.shift()
                            let vals2 = temp2.join( '_' )

                            let lookupKey = this.sanitizers.cleanId( vals + '___' + nuIdx )
                            let newKey = this.sanitizers.cleanId( vals2 + '___' + nuIdx2 )

                            let keys = Object.keys( l.list.values )
                            for( let k in keys )
                            {
                                let key = keys[ k ]

                                if( -1 < key.indexOf( lookupKey ) )
                                {

                                    let sub = key.split( /___/g )
                                    let studentId = sub.shift()
                                    values[ studentId + '___' + newKey ] = l.list.values[ key ]

                                }

                            }

                        }

                    }

                }

            }

            list.values = values
            listObjects.push( JSON.parse( JSON.stringify( list ) ) )

            idx++

        }

        listObject.timestamp = timestamp
        listObject.update = ( 0 < update ? update : undefined )
        listObject.lists = listObjects
        return listObject

    }

    _referencedList( localId, collection )
    {

        for( let c in collection )
        {
            if( collection[ c ].referenceKey === localId )
            {
                return collection[ c ]
            }
        }

        for( let c in collection )
        {
            if( undefined !== collection[ c ].lists
                && collection[ c ].lists.length > 0 )
            {
                for( let l in collection[ c ].lists )
                {
                    let list = collection[ c ].lists[ l ]
                    if( list.referenceKey === localId )
                    {
                        return collection[ c ]
                    }
                }
            }
        }

    }

    fromCollection( original, setup, collection )
    {

        let idx = 0
        let totalcols = []
        let dates = []
        let matches = []

        totalcols.push( JSON.parse( JSON.stringify( original.columns[ 0 ] ) ) )

        for( let s in setup )
        {

            let set = setup[ s ]

            let test = this._referencedList( set.referenceKey, collection )
            if( undefined !== test
                && 0 < test.lists.length )
            {
                idx = this._addColumns( idx, set, test.referenceKey, test.lists[ 0 ].columns, totalcols )
                for( let l in test.lists )
                {

                    let list = test.lists[ l ]
                    matches.push( {
                        container: test.referenceKey,
                        /*                        referenceKey: test.referenceKey,*/
                        list: list
                    } )
                    dates.push( list.timestamp )

                }
            }

        }

        let lists = this._collectFromLists( original, dates, matches, totalcols )
        return lists

    }

    collect( localId, list, collection )
    {

        return new Promise( resolve =>
        {

            let setup = []

            for( let c in list.columns )
            {
                let col = list.columns[ c ]
                if( 'setup' === col.type )
                {
                    setup.push( {
                        localId     : col.setup.value.listId,
                        referenceKey: col.setup.value.listId,
                        columns     : col.setup.value.columns
                    } )
                }
            }

            let listObject = this.fromCollection( list, setup, collection )
            return resolve( listObject )

        } )

    }

}