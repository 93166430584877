<template>
    <transition appear mode="out-in" :name="$core.settings().getTransition( 'crossfade' )">
        <div v-if="ready">
            <template
                v-if="'student' === filterBy && data.length > 1 && true === $core.settings().getSetting( 'studentFileShowOnlyFilled' )">
                <button type="button" :class="'auto right '+(showAll ? 'cancel' : 'submit')" @click="toggleShowAll()">
                    {{ showAll ? 'andere ausblenden' : 'alle einblenden' }}
                </button>
            </template>
            <table class="list" :id="'printable-'+item.localId">
                <thead>
                <tr class="list-header">
                    <th>&nbsp;</th>
                    <TableHeadRotated v-for="( col, index ) in data[0].columns"
                                      :key="'th-col-'+col.columnId+'-'+index"
                                      :keyV="index"
                                      :score="col.score"
                                      :caption="(col.type !== 'studentData' ? col.caption : $core.getTranslation().translate( 'caption_'+col.caption ))"/>
                    <CalculationTableHeaders v-if="undefined === filterId"
                                             :list="item"
                                             :viewKey="elementKey"
                                             @count="expandHeaders = $event"/>
                </tr>
                </thead>
                <tbody>
                <template v-for="( row, index ) in data"
                          :key="'tr-'+row.timestamp+'-'+row.elementKey">
                    <ListElementMonthSeparator
                        v-if="multiMonths === true
                          && true !== skipSeparators
                          && ( true === stats[rowMonths[index].sortable].hasData || showAll )
                          && ( undefined === rowMonths[(index-1)] || rowMonths[index].sortable !== rowMonths[(index-1)].sortable )"
                        :initialState="-1 < openMonths.indexOf( rowMonths[index].sortable )"
                        :shortCaption="rowMonths[index].sortable"
                        :caption="rowMonths[index].friendly"
                        :colspan="( data[0].columns.length + expandHeaders + 1 )"
                        :count="( showAll ? countMonths[ rowMonths[index].sortable ] : stats[rowMonths[index].sortable].count )"
                        @toggle="handleOpenMonth( rowMonths[index].sortable, $event )"/>
                    <tr :class="true === $core.settings().getSetting( 'studentFileShowOnlyFilled' ) ? data[ index ].marker : ''"
                        v-if="( !multiMonths || -1 < openMonths.indexOf( rowMonths[index].sortable ) || true === skipSeparators )
                        && ( data[ index ].marker !== 'empty' || showAll || false === $core.settings().getSetting( 'studentFileShowOnlyFilled' ) )"
                        @click="handleRowClick( row.listItem )"
                        :key="'tr-'+item.localId+'-'+row.timestamp"
                        :id="'tr-'+row.listItem.localId+'-'+row.timestamp">
                        <td>{{ firstcolumn[ index ] }}
                            <div v-if="$core.settings().getSetting( 'debugInfo' )" class="debug-info">
                                #{{ row.listItem.localId }}, REMOTE: {{ row.listItem.remoteId }}, REF:
                                {{ row.listItem.referenceKey }}
                            </div>
                        </td>
                        <td v-for="( col, idx ) in data[0].columns"
                            v-bind:class="'td-summary td-'+col.type"
                            :key="'td-'+row.timestamp+'-'+col.columnId">
                            <template v-if="item.listType === 'test'">
                                {{
                                    $core.getReformatter().reformat( row.summary.averages.byColumn[ col.columnId + '___' + ( idx + 1 ) ], 'localizedFloat' )
                                }}
                            </template>
                            <template v-else-if="headTypes[col.columnId] === 'distinct'">
                                <template v-if="undefined !== filterId">
                                    <ListElement :type="col.type"
                                                 :value="undefined !== row.listItem.values ? row.listItem.values[ filterId + '___' + col.columnId + '___' + ( 1 + idx ) ] : ''"/>
                                </template>
                                <template v-else>
                                    <ListElementDistincts
                                        :distinct="row.summary.distinct.byColumn[ col.columnId + '___' + ( idx + 1 ) ]"
                                        :totalCount="possibleRowCounts[ row.listItem.localId ]"
                                        :type="col.type"/>
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="undefined !== filterId && undefined !== row.listItem.values">
                                    <ListElement :type="col.type"
                                                 :value="undefined !== row.listItem.values ? row.listItem.values[ filterId + '___' + col.columnId + '___' + ( 1 + idx ) ] : ''"/>
                                </template>
                                <template v-else>
                                    <span>{{
                                            row.summary.summary.byColumn[ col.columnId + '___' + ( idx + 1 ) ]
                                        }}</span>
                                </template>
                            </template>
                        </td>
                        <CalculationTableFields v-if="undefined === filterId"
                                                :role="'summary'"
                                                :data="row"
                                                :viewKey="calculationKey"
                                                :list="item"/>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
    </transition>
</template>

<script>
import ListElementDistincts        from '@/components/elements/viewItems/listViews/listElement-distincts/ListElementDistincts'
import ListElement                 from '@/components/elements/viewItems/listViews/ListElement'
import ListElementMonthSeparator
                                   from '@/components/elements/viewItems/listViews/listElements/listElement-monthSeparator'
import TableHeadRotated            from '@/components/elements/viewItems/listViews/tableElements/TableHeadRotated'
import ListPreparationHelper       from '@/components/elements/viewItems/listViews/helpers/ListPreparationHelper'
import CalculationTableHeaders     from "@/components/elements/viewItems/listViews/CalculationTableHeaders";
import CalculationTableFields      from "@/components/elements/viewItems/listViews/CalculationTableFields";
import MixinCachePreheater         from "@/mixins/MixinCachePreheater";
import MixinContainerUpdateTracker from "@/components/elements/viewItems/listViews/mixins/MixinContainerUpdateTracker";
import MixinStructureKey           from "@/components/elements/viewItems/listViews/mixins/MixinStructureKey";

export default {

    name      : 'ListSummary',
    extends   : ListPreparationHelper,
    mixins    : [ MixinCachePreheater, MixinContainerUpdateTracker, MixinStructureKey ],
    components: {
        CalculationTableFields,
        CalculationTableHeaders,
        TableHeadRotated,
        ListElementMonthSeparator,
        ListElement,
        ListElementDistincts
    },
    emits     : [ 'rowClick', 'dataUpdate' ],
    props     : {
        item          : { type: Object, required: true },
        elementKey    : { Type: String, required: false },
        changeKey     : { Type: String, required: false },
        listFilter    : { type: [ Object, Boolean ], required: true },
        skipSeparators: { Type: Boolean, required: false, default: false }
    },

    watch: {

        elementKey: {
            immediate: true,
            handler( newValue, oldValue )
            {

                if( true !== this.$core.getState( 'listEditorOpen' )
                    && newValue !== oldValue
                    && this.ready )
                {
                    this.prepare()
                        .then( () =>
                        {
                            this.$emit( 'dataUpdate' )
                            this.calculationKey = this.$core.getUuid().generate()
                        } )
                }
            }
        },

        listFilter: {
            immediate: true,
            deep     : true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && oldValue !== undefined
                    && this.ready )
                {

                    this.listFilterList = newValue
                    this.prepare()

                }
            }
        }
    },

    data()
    {
        return {
            neededCaches     : [ 'class', 'group', 'yeargroup', 'student' ],
            calc             : this.$core.getTableCalculation(),
            calculationKey   : false,
            data             : false,
            expandHeaders    : 0,
            totals           : false,
            ready            : false,
            headTypes        : false,
            filterBy         : undefined,
            filterId         : undefined,
            listFilterList   : false,
            showAll          : false,
            elementComponent : false,
            firstcolumn      : [],
            columns          : false,
            studentCount     : 0,
            totalScore       : 0,
            prepared         : false,
            rowMonths        : [],
            countMonths      : {},
            stats            : {},
            possibleRowCounts: {},
            openMonths       : [],
            multiMonths      : false
        }
    },

    created()
    {
        this.awaitNeededCaches()
            .then( () =>
            {
                this.prepare()
            } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'on-changekey-' + this.changeKey + '-resolve' )
    },

    methods: {

        reset( ready )
        {
            this.data = false
            this.totals = false
            this.ready = ready
            this.filterId = undefined
            this.filterBy = undefined
            this.showAll = false
            this.elementComponent = false
            this.headTypes = false
            this.firstcolumn = []
            this.columns = false
            this.studentCount = 0
            this.totalScore = 0
            this.possibleRowCounts = {}
            this.rowMonths = []
            this.countMonths = {}
            this.stats = {}
            this.multiMonths = false

        },

        prepare()
        {

            return new Promise( resolve =>
            {

                this.totalScore = 0
                this.calc = this.$core.getTableCalculation()
                let sortRules = this.$core.settings().getSetting( 'sortingDirections' ),
                    result    = this.$core.sort().multiSortObjects( this.$props.item.lists, sortRules[ 'lists' ], true )

                this.calc.getSummary( result, true, this.listFilterList )
                    .then( calculation =>
                    {

                        this.studentCount = 0
                        this.firstcolumn = []
                        this.rowMonths = []
                        this.countMonths = {}
                        this.stats = {}
                        this.totals = false
                        this.possibleRowCounts = calculation.possibleRowCounts

                        this.totals = calculation.totals
                        this.filterBy = this.prepareFilterBy()
                        this.filterId = calculation.filterId
                        this.headTypes = calculation.headTypes
                        this.elementComponent = calculation.displayComponent

                        this.data = []
                        this.prepareData( 'summary', calculation )

                        this.prepared = true
                        this.ready = true

                        return resolve()

                    } )

            } )

        },

        handleOpenMonth( which, state )
        {
            if( state === true )
            {
                if( -1 === this.openMonths.indexOf( which ) )
                {
                    this.openMonths.push( which )
                }
            }
            else
            {
                let idx = this.openMonths.indexOf( which )
                if( -1 < idx )
                {
                    this.openMonths.splice( idx, 1 )
                }
            }
        },

        handleRowClick( item )
        {
            this.$emit( 'rowClick', item )

        },

        toggleShowAll()
        {
            this.showAll = !this.showAll
        }
    }

}
</script>