export default class FloatingFunctionBox
{

    constructor( core )
    {
        if( !FloatingFunctionBox.instance )
        {

            this.eventManager = core.getEventManager()
            this.settings = core.settings()
            this.timer = core.getCoreTimer()
            this.setState = ( key, state ) => core.setState( key, state )
            this.eventManager.append( 'on-settingsworker-ready', () =>
            {

                this.showToTop = this.settings.getSetting( 'scrollToTop' ) === true
                this.setup()

            } )

            FloatingFunctionBox.instance = this

        }

        return FloatingFunctionBox.instance
    }

    destruct()
    {
        this.eventManager.removeIndexedCallback( 'on-redraw', this.idxRedraw )
        delete FloatingFunctionBox.instance
    }

    handleRedraw()
    {
        if( this.floating )
        {
            this.unfloat()
            setTimeout( () =>
            {
                this.float()
            }, 500 )
        } else
        {
            this.unfloat()
        }
    }

    setup()
    {

        this.floating = false
        this.elm = false
        this.boundaries = false
        this.scrollToTop = false
        this.scrollFading = false

        this.initialized = false

        this.idxRedraw = this.eventManager.addIndexed( 'on-redraw', () =>
        {
            this.handleRedraw()
        } )

        this.observer = new MutationObserver( () =>
        {
            if( !this.initialized )
            {
                this.initialized = true
                document.querySelector( '#app' )
                    .addEventListener( 'scroll', ( event ) =>
                    {
                        this.handleScroll( event )
                    } )
            }
        } )

        this.observer.observe( document.body, {
            characterData: true,
            childList    : true,
            subtree      : true
        } )

        this.set = {}

    }

    /*eslint-disable*/
    handleScroll( scrollEvent )
    {

        let monitorElm = document.querySelector( '.headline' ),
            functionsElm = document.querySelector( '.head-functions' )

        if( null !== functionsElm
            && null !== monitorElm )
        {
            this.elm = functionsElm
            this.boundaries = document.querySelector( '.default-page-head' )
            let rect = monitorElm.getBoundingClientRect()
            if( 20 > rect.top
                && !this.floating )
            {
                this.setState( 'floatingFunctions', true )
                this.showScrollToTop()
                this.float()
            } else if( 20 < rect.top
                && this.floating )
            {
                this.setState( 'floatingFunctions', false )
                this.hideScrollToTop()
                this.unfloat()
            }
        }

    }

    showScrollToTop()
    {
        if( this.showToTop )
        {
            let scroller = document.querySelector( '#scrolltotop' )
            if( null !== scroller )
            {
                this.timer.removeTimeout( 'hide-scroll-to-top' )
                scroller.classList.remove( 'hidden' )
                scroller.style.opacity = 1
            }
        }
    }

    hideScrollToTop()
    {
        if( this.showToTop )
        {
            let scroller = document.querySelector( '#scrolltotop' )
            if( null !== scroller )
            {

                scroller.style.opacity = 0
                this.timer.addTimeout( 'hide-scroll-to-top', 350, () => {
                    scroller.classList.add( 'hidden' )
                })
            }
        }
    }

    float()
    {

        if( null !== this.boundaries )
        {
            this.boundaries.style.minHeight = this.boundaries.offsetHeight + 'px'
        }
        this.floating = true
        this.elm.classList.add( 'fixed' )

    }

    unfloat()
    {
        this.floating = false
        this.elm.classList.remove( 'fixed' )
    }

    handleViewScroll( id )
    {

        if( this.set[ id ] !== undefined )
        {
            if( !this.set[ id ].floating
                && 50 < this.set[ id ].monitorElm.scrollTop )
            {
                if( this.showToTop )
                {
                    this.set[ id ].scrollToTop.classList.remove( 'hidden' )
                    this.set[ id ].scrollToTop.style.opacity = 1
                }
                this.set[ id ].floating = true
                this.set[ id ].functionsElm.classList.add( 'fixed' )

            } else if( 50 > this.set[ id ].monitorElm.scrollTop
                && this.set[ id ].floating )
            {
                if( this.showToTop )
                {
                    this.set[ id ].scrollToTop.style.opacity = 0
                    setTimeout( () =>
                    {
                        this.set[ id ].scrollToTop.classList.add( 'hidden' )
                    }, 350 )
                }
                this.set[ id ].floating = false
                this.set[ id ].functionsElm.classList.remove( 'fixed' )

            }

            this.set[ id ].attached = true

        }
    }

    attachToView( id )
    {

        let elm = document.querySelector( '#' + id )
        if( null !== elm )
        {

            this.set[ id ] = {
                floating        : false,
                headerBoundaries: elm.querySelector( '.default-page-head' ),
                functionsElm    : elm.querySelector( '.head-functions' ),
                monitorElm      : elm,
                scrollToTop     : elm.querySelector( '.scrolltotop' )
            }

            if( elm.getAttribute( 'hasEvent' ) !== true )
            {
                elm.setAttribute( 'hasEvent', true )
                elm.addEventListener( 'scroll', () =>
                {

                    this.handleViewScroll( id )

                } )
            }
        }

    }

    detachFromView( id )
    {
        delete this.set[ id ]
    }

}