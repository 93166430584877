<template>
    <div>
        <div class="status-text online">online</div>
        <div class="online-checkmark"/>
    </div>
</template>

<script>
export default {
    name: 'Online'
}
</script>