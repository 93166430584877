<template>
    <div>
        <input type="hidden"
               :id="'historic-'+refName"
               class="historic-ref"
               :value="JSON.stringify( historic )">
        <draggable v-model="rows" @change="move" :move="checkMove" :itemKey="refName" ghost-class="drag-ghost"
                   animation="200"
                   :disabled="(dragBlocked || component === 'TestPart' || component === 'ListAndColumnSelector' )">
            <template #item="{element, index}">
                <div>
                    <div class="row-adder-wrapper">
                        <div class="dragwrap"
                             :id="'draggable-container-'+id+'-'+index">
                            <div class="dragbutton"
                                 v-if="valid[index] === true && rows.length > 2
                               && (!dragBlocked && component !== 'TestPart' && component !== 'ListAndColumnSelector' )">
                            </div>
                            <div class="dragspacer"
                                 v-else>
                            </div>
                            <div class="left">
                                <component :is="component"
                                           :ruleSet="ruleSet"
                                           :refName="'adder-'+refName+'-'+index"
                                           :id="'adder-'+refName+'-'+index"
                                           :idType="idType !== undefined ? idType : undefined"
                                           :value="element.value !== undefined ? elementValue( element.value ) : undefined"
                                           :allValues="rows"
                                           :set="set"
                                           :unique="unique"
                                           :showTeams="showTeams"
                                           :changeKey="changeKey"
                                           :ignoreClassId="ignoreClassId"
                                           :extendedFilter="extendedFilter"
                                           :allowForeign="allowForeign"
                                           :editItem="editItem"
                                           @extendedFilterSelection="handleExtendedFilterSelect"
                                           @enterPressed="handleEnterPress"
                                           @backspacePressed="handleBackspace"
                                           @update="updateValue"
                                           @selected="( ...args ) => handleSelected( index, ...args )"
                                           @adderValid="valid[ index ] = true"
                                           @adderInvalid="valid[ index ] = false"/>
                            </div>
                            <div class="right">
                                <transition appear :name="$core.settings().getTransition( 'zoom' )" mode="out-in">
                                    <Button v-if="valid[ index ]"
                                            :type="'row-'+element.mode"
                                            @clicked="handleClick( element.mode, index )"/>
                                </transition>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </template>
        </draggable>
    </div>

</template>
<script>
/*eslint-disable*/
import draggable             from 'vuedraggable'
import SortRulesSelector     from '@/components/form/elements/SortRulesSelector'
import Button                from '@/components/elements/defaults/Button'
import TextBox               from '@/components/form/elements/TextBox'
import FieldSelector         from '@/components/form/elements/FieldSelector'
import TestPart              from '@/components/form/elements/tests/TestPart'
import StudentSelector       from '@/components/form/elements/StudentSelector'
import ColleagueSelector     from '@/components/form/elements/ColleagueSelector'
import ListAndColumnSelector from '@/components/form/elements/ListAndColumnSelector'
import ColumnSelector        from '@/components/form/elements/ColumnSelector'

export default {

    name      : 'SubRowAdder',
    components: {
        draggable,
        ColumnSelector,
        ColleagueSelector,
        StudentSelector,
        TestPart,
        FieldSelector,
        TextBox,
        Button,
        SortRulesSelector,
        ListAndColumnSelector
    },

    emits: [ 'update', 'formUpdate', 'enterPressed', 'scoreBox', 'resortAdder' ],

    props: {
        id            : { Type: String, required: true },
        component     : { Type: String, required: true },
        changeKey     : { Type: String, required: false, default: '' },
        ruleSet       : { Type: String, required: false },
        set           : { Type: Object, required: false },
        allValues     : { Type: Object, required: false },
        asPlainList   : { Type: Boolean, required: false },
        refName       : { Type: String, required: false },
        value         : { Type: Array, required: false },
        reformatter   : { Type: String, required: false },
        eventKey      : { Type: String, required: false },
        dragBlocked   : { Type: Boolean, required: false },
        allowForeign  : { Type: Boolean, required: false },
        unique        : { Type: Boolean, required: false },
        showTeams     : { Type: Boolean, required: false },
        ignoreClassId : { Type: Boolean, required: false, default: false },
        extendedFilter: { Type: Boolean, required: false },
        editItem      : { Type: Object, required: false },
        resultingType : { Type: String, required: false },
        idType        : { Type: String, required: false }
    },

    data()
    {
        return {
            historic  : [],
            types     : [],
            valid     : [],
            rows      : [],
            dropzone  : [],
            adderMap  : {},
            dragging  : false,
            marked    : false,
            clone     : false,
            interleave: false,
            startX    : 0,
            startY    : 0,
            currentX  : 0,
            currentY  : 0,
            offsetX   : 0,
            offsetY   : 0,
            idx       : 0
        }
    },

    mounted()
    {

        let pushed = this.initialFieldWalk()
        if( !pushed )
        {
            this.addRow( true )
        }

        this.redefineRows()
        if( pushed )
        {
            this.initialUpdate()
        }

    },

    watch: {
        value    : {
            handler()
            {
                this.redefineRows()
            }
        },
        changeKey: {
            immediate: true,
            handler( newVal, oldVal )
            {
                if( newVal !== oldVal
                    && newVal !== false
                    && oldVal !== undefined )
                {

                    this.$nextTick()
                        .then( () =>
                        {

                            this.reset()
                            this.$nextTick()
                                .then( () =>
                                {

                                    this.initialFieldWalk()
                                    this.addRow( true )
                                    this.redefineRows()

                                } )

                        } )
                }
            }
        }
    },

    methods: {

        reset()
        {
            this.historic = []
            this.types = []
            this.valid = []
            this.rows = []
        },

        elementValue( value )
        {
            if( undefined === this.resultingType
                || true === this.$props.asPlainList )
            {
                return value
            }
            else
            {
                if( this.$core.f().isObject( value ) )
                {
                    return this.$core.f().valid( value.value ) ? value.value : ''
                }
            }
        },

        _plainFields()
        {
            let fields = []
            for( let v in this.$props.value )
            {
                if( this.$core.f().valid( this.$props.value[ v ].value )
                    && '' !== this.$props.value[ v ].value )
                {
                    fields.push( this.$props.value[ v ].value )
                }
                else if( 'string' === typeof this.$props.value[ v ] )
                {
                    fields.push( this.$props.value[ v ] )
                }
            }
            return fields
        },

        initialFieldWalk()
        {

            let pushed = false
            let fields = []

            if( this.$props.asPlainList )
            {
                fields = this._plainFields()
            }
            else
            {
                if( 'object' === typeof this.$props.value )
                {
                    for( let v in this.$props.value )
                    {
                        if( this.$core.f().valid( this.$props.value[ v ] )
                            && '' !== this.$props.value[ v ] )
                        {
                            fields.push( this.$props.value[ v ] )
                        }
                    }
                }
                else
                {
                    if( this.$core.f().valid( this.$props.value )
                        && '' !== this.$props.value )
                    {
                        fields.push( this.$props.value )
                    }
                }
            }

            for( let v in fields )
            {

                this.historic.push( JSON.stringify( { id: v, set: this.$props.value[ v ] } ) )

                this.rows.push( {
                    mode : 'remove',
                    value: fields[ v ]
                } )

                this.valid.push( true )
                pushed = true

            }

            return pushed

        },

        addRow( nofocus )
        {

            if( !this.$props.asPlainList )
            {
                this.rows.push( {
                    mode : 'add',
                    value: {
                        id   : undefined,
                        type : ( this.$props.resultingType ? this.$props.resultingType : undefined ),
                        value: ''
                    }
                } )
            }
            else
            {
                this.rows.push( {
                    mode : 'add',
                    value: ''
                } )
            }

            this.$nextTick()
                .then( () =>
                {

                    if( true !== nofocus )
                    {

                        this.redefineRows()
                        let elm = document.querySelector( '#form-element-adder-' + this.$props.refName + '-' + ( this.rows.length - 1 ) )
                        if( null === elm )
                        {
                            if( 'item___score' === this.$props.refName )
                            {
                                elm = document.querySelector( '#form-element-caption-adder-item-' + ( this.rows.length - 1 ) )
                            }
                            else
                            {
                                elm = document.querySelector( '#field-type-adder-' + this.$props.refName + '-' + ( this.rows.length - 1 ) )
                            }
                        }

                        this.$emit( 'update', this.$props.refName, 'rowAdder', 'add', ( this.rows.length - 1 ), this.rows )
                        if( null !== elm )
                        {
                            this.$nextTick()
                                .then( () =>
                                {
                                    elm.focus()
                                } )
                        }

                    }

                } )

        },

        redefineRows()
        {
            for( let r in this.rows )
            {
                this.rows[ r ].mode = ( parseInt( r ) === this.rows.length - 1 ) ? 'add' : 'remove'
                if( undefined === this.historic[ r ] )
                {
                    this.historic[ r ] = JSON.stringify( { id: r, set: 'NEW_ROW' } )
                }
            }
            this.refineTypes()
        },

        handleSelected( index, id, type )
        {
            this.rows[ index ].value.type = type
            this.refineTypes()
            this.$emit( 'scoreBox', -1 < this.types.indexOf( 'scoreBox' ) )
            this.$emit( 'update', this.$props.refName, 'rowAdder', 'update', null, this.rows )
        },

        refineTypes()
        {

            this.types = []
            this.valid = []

            for( let r in this.rows )
            {
                this.types[ r ] = this.rows[ r ].type
                this.valid[ r ] = this.$core.f().valid( this.rows[ r ].value )
            }

        },

        removeRow( id )
        {

            return new Promise( resolve =>
            {
                this.rows = this.rows.filter( ( value, index ) =>
                {
                    return index !== id
                } )

                this.redefineRows()

                this.$nextTick()
                    .then( () =>
                    {
                        this.$emit( 'update', this.$props.refName, 'rowAdder', 'remove', id, this.rows )
                        return resolve()
                    } )

            } )

        },

        _toRowValue( value, id, fromPlain )
        {

            if( this.$props.asPlainList )
            {
                return value
            }

            if( fromPlain )
            {
                return {
                    _historic: ( undefined !== this.rows[ id ].value
                                 && undefined !== this.rows[ id ].value._historic ? this.rows[ id ].value._historic : undefined ),
                    type     : ( undefined === value || undefined === value.type ? this.$props.resultingType : value.type ),
                    value    : value,
                    id       : id + '_' + this.$core.getSanitizers().cleanId( value )
                }
            }
            else
            {
                if( 'testItem' === value.type )
                {
                    if( value.caption.trim() !== '' && !isNaN( parseFloat( value.score ) ) )
                    {
                        return {
                            _historic: ( undefined !== this.rows[ id ].value
                                         && undefined !== this.rows[ id ].value._historic ? this.rows[ id ].value._historic : undefined ),
                            type     : ( undefined === value.type ? this.$props.resultingType : value.type ),
                            caption  : value.caption,
                            score    : value.score,
                            id       : value.id
                        }
                    }
                }
                else
                {
                    return {
                        _historic: ( undefined !== this.rows[ id ].value
                                     && undefined !== this.rows[ id ].value._historic ? this.rows[ id ].value._historic : undefined ),
                        type     : ( undefined === value.type ? this.$props.resultingType : value.type ),
                        value    : undefined !== value && undefined !== value.value ? value.value : '',
                        id       : value.id || undefined
                    }
                }
            }

        },

        updateValue( id, type, method, elmId, value )
        {

            let temp = id.split( /-/g )
            let idx = parseInt( temp.pop() )

            id = idx
            if( undefined === this.rows[ id ] )
            {
                return
            }

            this.valid[ id ] = false

            if( 'object' === typeof ( value )
                && !this.$props.asPlainList )
            {

                let rowValue = this._toRowValue( value, id )
                this.rows[ id ].value = rowValue

                if( false !== rowValue )
                {
                    this.valid[ id ] = true
                }

            }
            else
            {
                let rowValue = this._toRowValue( value, id, true )
                this.rows[ id ].value = rowValue

                if( undefined !== value
                    && '' !== value.trim() )
                {
                    this.valid[ id ] = true
                }

            }

            this.$emit( 'update', this.$props.refName, 'rowAdder', 'update', null, this.rows )

        },

        handleClick( mode, id )
        {

            switch( mode )
            {
                case 'add':
                    this.addRow()
                    break
                case 'remove':
                    this.removeRow( id )
                    break
            }

            this.redefineRows()

        },

        allValid()
        {
            for( let i = 0; i < this.rows.length; i++ )
            {
                if( this.valid[ i ] !== true )
                {
                    return false
                }
            }

            return true
        },

        handleEnterPress( id )
        {

            if( 'adder-columns-' === id.substr( 0, 14 ) )
            {
                id = parseInt( id.replace( 'adder-columns-', '' ) )
            }
            else if( 'adder-item___score-' === id.substr( 0, 19 ) )
            {
                id = parseInt( id.replace( 'adder-item___score-', '' ) )
            }

            if( this.allValid() === true )
            {
                this.addRow()
            }

        },

        handleBackspace( id )
        {

            if( 'adder-columns-' === id.substr( 0, 14 )
                || 'adder-score-' === id.substr( 0, 12 ) )
            {
                let oId = id
                if( 'adder-score-' === id.substr( 0, 12 ) )
                {
                    id = parseInt( id.replace( 'adder-score-', '' ) )
                }
                else
                {
                    id = parseInt( id.replace( 'adder-columns-', '' ) )
                }

                if( 1 < this.rows.length )
                {
                    this.removeRow( id )
                        .then( () =>
                        {
                            let nId = id - 1
                            let elm = document.querySelector( '#form-element-adder-' + this.$props.refName + '-' + nId )
                            if( 'adder-score-' === oId.substr( 0, 12 ) )
                            {
                                elm = document.querySelector( '#form-element-score-adder-score-' + nId )
                            }
                            if( null !== elm )
                            {
                                elm.focus()
                            }

                        } )
                }
                else
                {
                    this.updateValue( oId, '' )
                }
            }

        },

        initialUpdate()
        {
            for( let r in this.rows )
            {
                if( '' !== this.rows[ r ].value )
                {

                    let id = 'adder-' + this.$props.refName + '-' + r
                    let method = 'update'
                    let component = this.$core.f().lcFirst( this.$props.component )

                    this.updateValue( id, component, method, r, this.rows[ r ].value )

                }

            }
        },

        resort()
        {

            this.redefineRows()

            this.$nextTick()
                .then( () =>
                {

                    this.initialUpdate()
                    this.$emit( 'resortAdder', JSON.parse( JSON.stringify( this.rows ) ) )/* id, index, marked )*/
                    this.ready = true

                } )

        },

        handleExtendedFilterSelect( refName, list )
        {

            let toAdd = []
            for( let r in this.rows )
            {
                if( '' !== this.rows[ r ].value.trim() )
                {
                    toAdd.push( this.rows[ r ].value )
                }
            }

            for( let l in list )
            {
                if( -1 === toAdd.indexOf( list[ l ] ) )
                {
                    toAdd.push( list[ l ] )
                }
            }

            this.rows = []
            this.valid = []
            for( let t in toAdd )
            {
                this.rows.push( { mode: 'remove', value: toAdd[ t ] } )
                this.valid[ t ] = true
            }

            this.$emit( 'update', this.$props.refName, 'rowAdder', 'update', null, this.rows )
            this.rows.push( { mode: 'add', value: '' } )

        },

        move( event )
        {

            let moved = event.moved

            this.$nextTick()
                .then( () =>
                {
                    this.resort( this.$props.refName, moved.newIndex, moved.oldIndex )
                } )

        },

        checkMove( event )
        {
            return this.valid[ event.draggedContext.futureIndex ] === true
        }

    }

}
</script>