/* eslint-disable */
import CryptoCache from '@/classes/Helpers/CryptoHelper/CryptoCache'

export default class CryptoHelper
{
    constructor( core )
    {

        if( !CryptoHelper.instance )
        {

            this.store = core.getStore()
            this.logger = core.getLogger()
            this.crypto = core.getCryptoCore()
            this.timer = core.getCoreTimer()
            this.eventManager = core.getEventManager()
            this.cc = core.cc()
            this.webWorkers = core.ww()
            this.s = core.s()

            this.cache = new CryptoCache( core )

            this.decryptCount = {}

            this.logger.clog( 'CryptoHelper', 'initializing...' )

            this.dec = {}

            this.privateUINT8 = false
            this.signature = null
            this.ready = false

            this.cryptCount = 0
            this.cryptTime = 0

            CryptoHelper.instance = this

            this.timer
                .addInterval( 'crypto-stats', 30000, () =>
                {
                    this.reportStatistics()
                }, false )

            this.eventManager.append( 'on-store-ready', () =>
            {
                this.initializeLocalKey()
            } )
            this.eventManager.append( 'on-login-state-change', () =>
            {
                this.initializeLocalKey()
            } )
            this.eventManager.append( 'on-user-change', () =>
            {
                this.initializeLocalKey()
            } )

            this.eventManager.append( 'crypto-reset', () =>
            {

                this.ready = false
                this.privateUINT8 = false

            } )

        }

        return CryptoHelper.instance

    }

    destruct()
    {

        this.store = null
        this.logger = null
        this.crypto = null
        this.eventManager = null

        delete CryptoHelper.instance

    }

    reportStatistics()
    {

        let median = 0 < this.cryptCount ? ( this.cryptTime / this.cryptCount ) : 0

        this.logger.cdebug( 'CryptoHelper::printStats',
            'time spent on',
            this.cryptCount,
            'crypto operations:',
            this.cryptTime + 'ms',
            median.toPrecision( 2 ) + 'ms',
            'median' )

        this.s.register( '_CryptoStatisticsMean', parseInt( Math.ceil( 1000 * median ) ) )
        this.s.register( '_CryptoStatisticsLastTotalCount', this.cryptCount )
        this.s.register( '_CryptoStatisticsLastTotalTime', this.cryptTime )

    }

    initializeLocalKey( reinit )
    {

        if( this.store.getters.privateKey )
        {
            let local = atob( this.store.getters.privateKey )
            this.privateUINT8 = new Uint8Array( local.split( ',' ) )

            this.logger.clog( 'CryptoHelper:initializeLocalKey', 'provisioning WebWorker...' )

            this.webWorkers.call( 'Crypto', '_provision', {
                publicKey   : this.store.getters.publicKey,
                privateKey  : this.store.getters.privateKey,
                privateUint8: this.privateUINT8,
                uuid        : this.store.getters.uuid
            } ).then( () =>
            {

                this.logger.clog( 'CryptoHelper:initializeLocalKey', 'done provisioning WebWorker.' )
                this.logger.clog( 'CryptoHelper:initializeLocalKey', ( 1 < this.privateUINT8.length ? 'complete' : 'initial' ) )
                this.ready = true

                this.eventManager.dispatch( 'cryptohelper-ready' )
                this.eventManager.dispatch( 'on-corecomponent-initialized' )

                if( undefined === reinit )
                {
                    this.emitState()
                }


            } )

        }

    }

    emitState()
    {
        this.eventManager.dispatch( 'cryptoHelperState' )
    }

    isReady()
    {
        return this.ready
    }

    waitReady()
    {
        return new Promise( ( resolve ) =>
        {
            if( true === this.ready )
            {
                return resolve( true )
            }
            else
            {
                this.eventManager.addOnce( 'cryptohelper-ready', () =>
                {
                    return resolve( true )
                } )
            }
        } )

    }

    _count( start )
    {
        this.cryptCount++
        this.cryptTime += ( Date.now() - start )
    }

    decryptElement( localKey, object, _key )
    {

        return new Promise( resolve =>
        {

            let args  = {
                    localKey: localKey,
                    object  : object,
                    _key    : _key
                },
                start = Date.now()

            this.webWorkers.call( 'Crypto', 'decryptElement', args )
                .then( result =>
                {

                    this._count( start )
                    return resolve( result )

                } )

        } )

    }

    decryptObject( localKey, object, _key )
    {

        try
        {

            let plain = this.cache.get( object )
            if( false !== plain )
            {
                return plain
            }

            let start = Date.now()

            this.cryptCount++
            let decryptedMessage = this.decryptObjectDirect( localKey, object, _key )
            let diff = Date.now() - start
            this.cryptTime += diff

            this.cache.store( object, decryptedMessage )

            return decryptedMessage

        }
        catch( error )
        {
            return false
        }

    }

    getKey( keyset )
    {
        for( let k in keyset )
        {
            if( keyset[ k ].uuid === this.store.getters.uuid )
            {
                return keyset[ k ]
            }
        }
        return false
    }

    getLocalKey( object )
    {
        for( let k in object.keys )
        {

            let key = object.keys[ k ]
            if( key.uuid === this.store.getters.uuid )
            {

                return key.secret

            }
        }
        return false
    }

    getOwnElementKey( object )
    {
        if( undefined !== object
            && undefined !== object.keys )
        {
            for( let k in object.keys )
            {

                let key = object.keys[ k ]
                if( key.uuid === this.store.getters.uuid )
                {

                    return key.key

                }
            }
        }
        return false
    }

    getOwnUuid()
    {
        return this.store.getters.uuid
    }

    decrypt( object, direct, _key )
    {

        return new Promise( resolve =>
        {

            if( undefined === object
                || undefined === object.keys )
            {
                return resolve( false )
            }

            let key   = this.getKey( object.keys ),
                start = Date.now()

            if( false !== key )
            {

                let args = {
                    localKey: key.key,
                    direct  : direct,
                    object  : object.object,
                    _key    : _key
                }

                this.webWorkers.call( 'Crypto', 'decrypt', args )
                    .then( result =>
                    {

                        this._count( start )
                        return resolve( result )

                    } )
                    .catch( error =>
                    {

                        this.logger.cerror( 'CryptoHelper::decrypt', 'failed decryption via webworker:', error )
                        this._count( start )
                        return resolve( false )

                    } )

            }
            else
            {
                this._count( start )
                return resolve( false )
            }

        } )

    }

    plainDecrypt( crypttext )
    {

        return new Promise( resolve =>
        {

            let start = Date.now()
            this.webWorkers.call( 'Crypto', 'plainDecrypt', crypttext )
                .then( result =>
                {

                    this._count( start )
                    return resolve( result )

                } )

        } )

    }

    plainEncrypt( publicKey, crypttext )
    {

        return new Promise( resolve =>
        {

            let start = Date.now()
            this.webWorkers.call( 'Crypto', 'plainEncrypt', crypttext )
                .then( result =>
                {

                    this._count( start )
                    return resolve( result )

                } )

        } )

    }

    rekey( localKey, publicKey )
    {

        let decryptedKey = this.crypto.decryptWithPrivateKey( this.privateUINT8, localKey )
        if( false !== decryptedKey )
        {
            return this.crypto.encryptWithPublicKey( publicKey, decryptedKey )
        }
        return false

    }

    encryptObject( publicKey, objectJson, localKey, uuid, additionalKeys, studentKeys )
    {

        return new Promise( resolve =>
        {

            let args  = {
                    publicKey     : publicKey,
                    objectJson    : objectJson,
                    localKey      : localKey,
                    uuid          : uuid,
                    additionalKeys: additionalKeys,
                    studentKeys   : studentKeys
                },
                start = Date.now()

            this.webWorkers.call( 'Crypto', 'encryptObject', args )
                .then( result =>
                {

                    this._count( start )
                    return resolve( result )

                } )

        } )

    }

    encryptElement( object, localKey, additionalKeys )
    {
        return new Promise( resolve =>
        {

            let add = []
            for( let k in additionalKeys )
            {
                if( additionalKeys[ k ].uuid !== this.store.getters.uuid )
                {
                    add.push( additionalKeys[ k ].uuid )
                }
            }

            let args  = {
                    publicKey     : this.store.getters.publicKey,
                    objectJson    : JSON.stringify( object ),
                    localKey      : localKey,
                    uuid          : this.store.getters.uuid,
                    additionalKeys: add
                },
                start = Date.now()

            this.webWorkers.call( 'Crypto', 'encryptObject', args )
                .then( result =>
                {

                    this._count( start )
                    return resolve( result )

                } )

        } )

    }


    encrypt( objectJson )
    {
        return new Promise( resolve =>
        {

            let start = Date.now()
            this.webWorkers.call( 'Crypto', 'encrypt', objectJson )
                .then( result =>
                {

                    this._count( start )
                    return resolve( result )

                } )
        } )

    }

    encryptForServer( plain )
    {

        return this.crypto.encryptForServer( plain )

    }

    setupSignature()
    {

        return new Promise( resolve =>
        {

            let signature = this.store.getters.signature

            if( false !== signature
                && null !== signature )
            {
                if( null !== this.signature )
                {
                    return resolve( this.signature )
                }
                else
                {
                    this.plainDecrypt( this.store.getters.signature )
                        .then( storable =>
                        {

                            let signature       = JSON.parse( storable ),
                                publicKeyArray  = [],
                                privateKeyArray = []

                            for( let i in signature.publicKey )
                            {
                                publicKeyArray.push( signature.publicKey[ i ] )
                            }
                            for( let i in signature.secretKey )
                            {
                                privateKeyArray.push( signature.secretKey[ i ] )
                            }

                            let signatureObject = {
                                publicKey : Uint8Array.from( publicKeyArray ),
                                privateKey: Uint8Array.from( privateKeyArray )
                            }

                            this.signature = signatureObject

                            return resolve( this.signature )

                        } )
                }
            }
            else
            {
                if( false !== this.store.getters.publicKey )
                {

                    signature = this.crypto.generateSignature()

                    this.plainEncrypt( this.store.getters.publicKey, JSON.stringify( signature ) )
                        .then( storable =>
                        {

                            if( false !== storable )
                            {
                                this.store.commit( 'setSignature', storable )
                                return resolve( signature )
                            }

                        } )

                }
                else
                {
                    return resolve( false )
                }

            }

        } )

    }

    getSignaturePublicKey()
    {
        return this.signature.publicKey
    }

    sign( plain )
    {
        this.setupSignature()
            .then( signature =>
            {

                if( false !== signature )
                {
                    let signed     = this.crypto.sign( plain, signature.privateKey ),
                        b64signed  = btoa( signed ),
                        strfSigned = JSON.stringify( signed )
                    console.log( 'SIGN', 'b64:', b64signed.length, 'str:', strfSigned.length, 'pl:', plain.length )
                }

            } )

    }

}